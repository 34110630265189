import DefaultComponent from './DefaultComponent';

export default function FavoriteComponent({ clickedData, setClickedData, type, lang }) {
  return (
    <DefaultComponent
      picture
      clickedData={clickedData}
      setClickedData={setClickedData}
      type={type}
      lang={lang}
    ></DefaultComponent>
  );
}
