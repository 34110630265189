import { useContext } from 'react';
import { ApplicationContext } from '../../Context/ApplicationContext';

const LanguageSelectorIcon = () => {
  const { language, setLanguage, setLangKey, allLanguage } = useContext(ApplicationContext);

  if (allLanguage === null || language === null) return <></>;

  let currentLanguage = language.id;
  const changeLanguage = (lang) => {
    const langID = lang.target.value;
    const langObj = allLanguage.find((e) => {
      return e.id === langID;
    });

    if (langObj === undefined || langObj === null) return;
    setLanguage(langObj);
    setLangKey('_' + langID);
    currentLanguage = lang;
  };

  return (
    <div className="m-4">
      <select
        value={currentLanguage}
        className="z-10 bg-white divide-y px-2 py-1 divide-gray-100 border border-gray-300 rounded "
        id="limit"
        onChange={(value) => {
          changeLanguage(value);
        }}
        style={{
          color: '#555555',
        }}
      >
        {allLanguage.map((languages, index) => {
          return (
            <option key={index} value={languages.id} className="py-1 text-sm text-gray-800">
              {languages.id.toUpperCase()}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default LanguageSelectorIcon;
