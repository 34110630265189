import SelectDesignComponent from './SelectDesignComponent'

export default function NewPageDesginComponent() {

    return (
        <>
            <div className='p-2'>
                <SelectDesignComponent />
            </div>
        </>
    )
}