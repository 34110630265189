import { useState } from 'react';
import { Input, Modal } from 'react-rainbow-components';
import AddButton from '../../../../Common/Button/AddButton';

export default function CreatePageModalComponent({ isOpen, onClose, onSubmit }) {
  const [inputValue, setInputValue] = useState('');

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="flex justify-center items-center">
          <p className="text-lg">Nom de la page</p>
        </div>
        <input
          className="custominputbox w-full mt-2"
          type="text"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <div className="flex items-end justify-end mt-2">
          <AddButton
            title={'Créer'}
            onClick={() => {
              onSubmit(inputValue);
              setInputValue('');
            }}
          />
        </div>
      </Modal>
    </>
  );
}
