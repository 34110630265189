import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../../Context/UserContext';
import { UpdateApplication } from '../../../../../../Services/Application';
import PictureUpdate from '../../../PictureUpdate';
import GridView from './GridView';
import IphonePreview from './IphonePreview';
import PreviewView from './PreviewView';
import TabbarModule from "../TabBarConfiguration/tabbar.module";

export default function PositionDesign({currentPage, page, setPage, handleSelectedModule, moduleSelected}) {
  const {application, reloadApplication} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [editMode, setEditMode] = useState(true);

  if (!page) {
    return <p>Chargement ...</p>;
  }
  const updateHeader = async (url) => {
    await UpdateApplication(application.apiKey, user.authToken, application._id, {pictureUrl: url});
    await reloadApplication(window.location.hostname, true);
  };

  return (
    <div className="w-max">
      <IphonePreview>
        <div
          key={'header'}
          style={{
            aspectRatio: '16/9',
          }}
          className="overflow-hidden w-full bg-red-300 object-contain relative"
        >
          <PictureUpdate
            customParentClassNames={'w-full h-full bg-blue-400'}
            classnames={'h-full w-full bg-green-400'}
            url={application.informations.pictureUrl}
            name={'headerpicture'}
            onUpdate={updateHeader}
            size={'100%'}
            rounded="true"
          />
        </div>
        {editMode && (
          <div style={{paddingBottom: "15%"}}>
            <GridView
              currentPage={currentPage}
              page={page}
              setPage={setPage}
              handleSelectedModule={handleSelectedModule}
              moduleSelected={moduleSelected}
            />
          </div>
        )}
        {!editMode && <PreviewView data={page} handleSelectedModule={handleSelectedModule} moduleSelected={moduleSelected} />}
        <TabbarModule editMode={editMode} handleSelectedModule={handleSelectedModule} />
      </IphonePreview>
      <div className="px-8 mt-2">
        <p
          className={
            'px-3 border p-1 rounded border-gray-600 text-gray-600 cursor-pointer transition-all hover:bg-opacity-50 ' +
            (!editMode ? 'border-blue-500 bg-blue-100 text-blue-500 ' : 'border-green-500 bg-green-100 text-green-500')
          }
          onClick={() => {
            setEditMode(!editMode);
          }}
        >
          {editMode ? 'Mode prévisualisation' : 'Mode édition'}
          <FontAwesomeIcon className="ml-2" icon={editMode ? faEye : faPen} />
        </p>
      </div>
    </div>
  );
}
