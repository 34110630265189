
const URL = "https://usekey-objects.herokuapp.com"
//const URL = "http://localhost:3000"


export const FindLinks = async (id, type, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/links?id=${id}&type=${type}`,
            {
                headers: {
                    'ApiKey': apiKey,
                    'AuthToken': authToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
        if (response.status !== 200) {
            return null
        }
        const json = await response.json()
        return json
    } catch (e) {
        return null
    }
}

export const FindLinkUser = async (id, apiKey, authToken, populate = "0") => {
    try {
        const response = await fetch(`${URL}/objects/uklink/user/${id}?populate=${populate}`,
            {
                headers: {
                    'ApiKey': apiKey,
                    'AuthToken': authToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
        if (response.status !== 200) {
            return null
        }
        const json = await response.json()
        return json
    } catch (e) {
        return null
    }
}


export const FindLinkObject = async (id, type, apiKey, authToken, populate = "0") => {
    try {
        const response = await fetch(`${URL}/objects/uklink/object/${type}/${id}?populate=${populate}`,
            {
                headers: {
                    'ApiKey': apiKey,
                    'AuthToken': authToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
        if (response.status !== 200) {
            return null
        }
        const json = await response.json()
        return json
    } catch (e) {
        return null
    }
}



export const GetAllLink = async (apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/ukLink`,
            {
                headers: {
                    'ApiKey': apiKey,
                    'AuthToken': authToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
        )
        if (response.status !== 200) {
            return null
        }
        const json = await response.json()
        return json
    } catch (e) {
        return null
    }
}