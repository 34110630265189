import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import ModuleConfigurationStyleObject from './ModuleConfigurationStyleObject';

export default function ModuleConfigurationSelectObject({module, setModule, className = ''}) {
  const {config, application} = useContext(ApplicationContext);
  const [modalUpload, setModalUpload] = useState(false);
  const [objectList, setObjectList] = useState(null);
  var typeValid = ['CUSTOMOBJECTLIST', 'USERSLIST'];

  const [filterList, setFilterList] = useState(null);

  useEffect(() => {
    if (!config) return;
    let select = config.customObject.find((e) => e.id === module.id);
    if (select) setFilterList(select.custom);
    else setFilterList(null);
  }, [config, module]);

  useEffect(() => {
    if (!config) return;
    let objects = config.customObject.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setObjectList(objects);
  }, [config]);
  if (typeValid.indexOf(module.type) == -1) {
    return <></>;
  }
  return (
    <div className={className}>
      {module.type !== 'USERSLIST' && (
        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Quelle type d'objet souhaitez-vous afficher ?</p>
          {objectList && (
            <div className="" style={{width: 300}}>
              <Select
                options={objectList}
                onChange={(e) => {
                  setModule({...module, id: e.value});
                }}
                value={objectList.find((e) => e.value == module.id) || null}
              />
            </div>
          )}
        </div>
      )}

      {filterList && filterList.length > 0 && (
        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Par quelle valeur souhaitez-vous trier cette liste?</p>
          {filterList && filterList.length > 0 && (
            <div className="" style={{width: 300}}>
              <Select
                options={filterList.map((e) => {
                  return {
                    value: e.id,
                    label: e.tint,
                  };
                })}
                onChange={(e) => {
                  setModule({...module, sorter: `informations.${e.value}`});
                }}
                value={{
                  value: (
                    filterList.find((e) => {
                      let sorter = (module.sorter || '').split('.');
                      if (sorter.length === 0 || module.sorter === '') return false;
                      return sorter[1] === e.id;
                    }) || {}
                  ).id,
                  label: (
                    filterList.find((e) => {
                      let sorter = (module.sorter || '').split('.');
                      if (sorter.length === 0 || module.sorter === '') return false;
                      return sorter[1] === e.id;
                    }) || {}
                  ).tint,
                }}
              />
            </div>
          )}
        </div>
      )}

      <ModuleConfigurationStyleObject clickedData={module} setClickedData={setModule} />
    </div>
  );
}
