import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UpdateJsonConfig } from '../../../../Services/Application';
import ObjectSettingsList from "../../Objects/Settings/ObjectSettingsList";
import ObjectSettingsEdit from "../../Objects/Settings/ObjectSettingsEdit";
import AddButton from "../../../Common/Button/AddButton";
import { v4 } from "uuid";

const UsersSettingView = ({initConfig, customs, setCustoms}) => {
  const {config, application, reloadConfig} = useContext(ApplicationContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const params = useParams();

  useEffect(() => {
    initConfig();
  }, [params]);

  const createLink = (id, placeholder, tint, url) => {
    const isObject = config.inscription.customs.findIndex(custom => custom.id === url) >= 0;

    return {
      id: id,
      identifier: 'CustomObjectDetailCell',
      important: false,
      isSubTitle: false,
      isSubTitleSubEvent: false,
      isTitle: false,
      placeholder: placeholder,
      style: 'line',
      tint: tint,
      type: 'link',
      url: url,
      linkType: isObject ? 'user' : 'object',
    };
  }

  return (
    <div className="grid grid-cols-3 gap-3">
      {customs.length === 0 &&
        <AddButton
          onClick={() => {
            const items = [...customs];
            items.push({
              id: v4(),
              important: false,
              style: 'line',
              identifier: 'CustomObjectDetailCell',
              isSubTitle: false,
              isTitle: false,
              url: '',
              type: 'text',
              placeholder: '',
              tint: '',
              isSubTitleSubEvent: false,
            });
            setCustoms(items);
          }}
        />
      }
      {customs.length > 0 && <ObjectSettingsList
        selectedItem={selectedItem}
        customs={customs}
        setCustoms={async (customs) => {
          setCustoms(customs);

          const newConfig = {...config};
          const type = params.iduser;
          const indexConfig = newConfig.inscription.customs.findIndex((e) => e.id === type);
          if (indexConfig >= 0) {
            newConfig.inscription.customs[indexConfig].custom = customs;
            await UpdateJsonConfig(application.apiKey, newConfig);
            await reloadConfig();
          }
          toast.success('Modifications sauvegardées !', {autoClose: 2000, position: 'bottom-center'});
        }}
        onSelectItem={setSelectedItem}
      />}
      {
        <ObjectSettingsEdit
          item={customs[selectedItem]}
          onUpdate={(item) => {
            const arr = [...customs];
            arr[selectedItem] = item;
            setCustoms(arr);
          }}
          onDelete={async () => {
            let arr = [...customs];
            arr = arr.filter((e, index) => index !== selectedItem);
            setCustoms(arr);
            const newConfig = {...config};
            const currentCustomObject = customs[selectedItem];
            if (currentCustomObject.type === 'link') {
              if (currentCustomObject.linkType === "user") {
                let indexLinkedConfigUser = newConfig.inscription.customs?.findIndex(obj => obj.id === currentCustomObject.url);
                let indexCustomLinkedUser = newConfig.inscription.customs[indexLinkedConfigUser].custom?.findIndex(elem => elem.id === currentCustomObject.id)
                if (indexCustomLinkedUser >= 0) {
                  newConfig.inscription.customs[indexLinkedConfigUser].custom.splice(indexCustomLinkedUser, 1);
                }
              } else if (currentCustomObject.linkType === 'object') {
                let indexLinkedConfigObject = newConfig.customObject?.findIndex(obj => obj.id === currentCustomObject.url);
                let indexCustomLinkedObject = newConfig.customObject[indexLinkedConfigObject].custom?.findIndex(elem => elem.id === currentCustomObject.id)
                if (indexCustomLinkedObject >= 0) {
                  newConfig.customObject[indexLinkedConfigObject].custom.splice(indexCustomLinkedObject, 1);
                } else {
                  let indexCustomLinkedEvent = newConfig.eventObject.custom?.findIndex(elem => elem.id === currentCustomObject.id)
                  if (indexCustomLinkedEvent >= 0) {
                    newConfig.eventObject[indexCustomLinkedEvent].splice(indexCustomLinkedEvent, 1);
                  }
                }
              }
            }
            const type = params.iduser;
            const indexConfig = newConfig.inscription.customs.findIndex((e) => e.id === type);
            if (indexConfig >= 0) {
              newConfig.inscription.customs[indexConfig].custom = arr;
              await UpdateJsonConfig(application.apiKey, newConfig);
              await reloadConfig();
            }
          }}
          onSave={async () => {
            const newConfig = {...config};
            const currentCustomObject = customs[selectedItem];
            if (currentCustomObject.type === 'link') {
              if (currentCustomObject.linkType === "user") {
                let indexLinkedConfigUser = newConfig.inscription.customs?.findIndex(obj => obj.id === currentCustomObject.url);
                let indexCustomLinkedUser = newConfig.inscription.customs[indexLinkedConfigUser].custom?.findIndex(elem => elem.id === currentCustomObject.id)
                if (indexCustomLinkedUser === -1) {
                  newConfig.inscription.customs[indexLinkedConfigUser].custom.push(createLink(currentCustomObject.id, `link-${params.iduser}`, `link-${params.iduser}`, params.iduser));
                }
              } else if (currentCustomObject.linkType === 'object') {
                let indexLinkedConfigObject = newConfig.customObject?.findIndex(obj => obj.id === currentCustomObject.url);
                let indexCustomLinkedObject = newConfig.customObject[indexLinkedConfigObject].custom?.findIndex(elem => elem.id === currentCustomObject.id)
                if (indexCustomLinkedObject === -1) {
                  newConfig.customObject[indexLinkedConfigObject].custom.push(createLink(currentCustomObject.id, `link-${params.iduser}`, `link-${params.iduser}`, params.iduser));
                }
              } else {
                  newConfig.eventObject.custom.push(createLink(currentCustomObject.id, `link-${params.iduser}`, `link-${params.iduser}`, params.iduser));
              }
            }
            const type = params.iduser;
            const indexConfig = newConfig.inscription.customs.findIndex((e) => e.id === type);
            if (indexConfig >= 0) {
              newConfig.inscription.customs[indexConfig].custom = customs;
              await UpdateJsonConfig(application.apiKey, newConfig);
              await reloadConfig();
            }
            toast.success('Modifications sauvegardées !', {autoClose: 2000, position: 'bottom-center'});
          }}
        />
      }
    </div>
  );
};
export default UsersSettingView;
