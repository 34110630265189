import { useCallback, useContext, useEffect, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { Pagination } from 'react-rainbow-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import { GetUsersAll, GetUsersAllCount } from '../../../Services/User';
import CustomUserCellVertical from './CustomUsersCellVertical';
import FilterComponent from './FilterComponent';
import UsersExport from '../Users/users.export';
import ModalComponent from './UserModalComponent';
import CommonButton from "../../Common/Button/CommonButton";

const BYPAGE = 20;
export default function UsersComponent({ onClick }) {
  const history = useHistory();
  const { application, config } = useContext(ApplicationContext);
  const { user, isAdmin } = useContext(UserContext);
  let { params } = useRouteMatch();

  const [maxPage, setMaxPage] = useState(1);
  const [param, setParam] = useState(params);
  const [page, setPage] = useState(1);
  const [objects, setObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setfilters] = useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const getTotal = useCallback(async () => {
    try {
      if (!user) return;
      setIsLoading(true);
      const newObjs = await GetUsersAllCount(page - 1, application.apiKey, (user || {}).authToken, filters, params.iduser);
      var calcule = Math.ceil(newObjs.count / BYPAGE);
      setMaxPage(calcule);
    } catch (e) {}
  }, [objects, filters, isLoading, page, user, maxPage]);

  const getPage = useCallback(async () => {
    if (!user) return;
    await getTotal();

    setIsLoading(true);

    const newObjs = await GetUsersAll(page - 1, application.apiKey, (user || {}).authToken, filters, params.iduser);

    setObjects(newObjs);
    /*
    const objs = newObjs.filter((e) => e.informations.user_custom_type === params.iduser);
    var allObject = [...(objects || []), ...objs];
    allObject = allObject.filter((value, index, self) => index === self.findIndex((t) => t._id === value._id));
    setObjects(allObject);*/
    if (newObjs.length > 0) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [objects, filters, isLoading, page, user]);

  useEffect(() => {
    getPage();
    setPage(1);
  }, [user]);

  useEffect(() => {
    if (page >= 0) {
      getPage();
    }
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (page === 1) getPage();
    else setPage(1);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (params.iduser !== param.iduser) {
      setPage(1);
      setfilters([]);
      setObjects([]);
      setParam(params);
    }
    // eslint-disable-next-line
  }, [params]);

  const updateFilter = useCallback(
    (newFilters) => {
      setPage(1);
      setfilters(newFilters);
      setObjects([]);
      // eslint-disable-next-line
    },
    [filters, page, objects]
  );

  return (
    <div>
      {isAdmin() && (
        <div className="px-2 sm:px-10  mt-3 text-right m-auto mb-0 items-center flex justify-end gap-3">
          {/*<UserExportBillet filters={filters} userType={param.iduser} /> */}
          <UsersExport filters={filters} userType={param.iduser} />
          <CommonButton title="Configuration" useAppTheme={true} icon={<FiSettings/>} onClick={() => setmodalIsOpen(true)} customClassName="h-8"></CommonButton>
        </div>
      )}
      <div className="p-2 sm:p-10 grid grid-cols-1 gap-3 md:grid-cols-4">
        <div className="">
          <FilterComponent filters={filters} setFilters={updateFilter} page={page} setObjects={setObjects} iduser={params.iduser} />
        </div>
        <div className="col-span-3">
          <div className="">
            <div className="p-6 gap-3  rounded border border-gray-300 bg-white grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3  ">
              {(!objects || objects.length === 0) && <p>Aucun élément trouvé</p>}
              {objects &&
                objects.map((elem) => {
                  return (
                    <CustomUserCellVertical
                      onClick={() => {
                        if (onClick) onClick(elem);
                        else history.push(`/v/user/${elem._id}`, { back: true });
                      }}
                      userParam={elem}
                    />
                  );
                })}
            </div>
          </div>
          <Pagination className="mt-3" activePage={page} onChange={(e, p) => setPage(p)} pages={maxPage} />
        </div>
      </div>
      <ModalComponent object={objects} modalIsOpen={modalIsOpen} setmodalIsOpen={setmodalIsOpen} />
    </div>
  );
}
