import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetObjectByIds, GetObjectsAll, GetUKQuestion } from "../../../Services/UKObject";

function QuestionPublicView({}) {
    const [questions, setQuestions] = useState([]);
    const { application, langKey, reloadApplication } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [changedStateIds, setChangedStateIds] = useState([]);
    const [filter, setFilter] = useState(null);
    const [formattedQuestions, setFormattedQuestions] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        console.log("GET QUESTION USE EFFECT");
        if (!application) {
            reloadApplication(window.location.hostname);
            return;
        } else {
            let interval = setInterval(() => {
                getQuestions();
            }, 1000);
            return () => clearInterval(interval);
        }
        // const interval = setInterval(() => {
        //  setIsSaving(true)
        //  getQuestions();
        //  setIsSaving(false)
        // }, 20000);
        // return () => clearInterval(interval);
    }, [application]);

    const getQuestions = useCallback(async () => {
        if (!application) return;
        let questions = await GetUKQuestion((application || {}).apiKey, (user || {}).authToken);
        const globalQuestions = await GetObjectsAll("ukQuestionGlob", application.apiKey, (user || {}).authToken);
        if (!questions) return;
        const eventIds = [...new Set(questions.map((e) => e.type.split("_")[1]))];
        const events = await GetObjectByIds(application.apiKey, "ukEvent", eventIds);
        questions = [...questions, ...globalQuestions];

        setEvents(events);
        setQuestions(questions.reverse());
    }, [application]);

    return (
        <div className="bg-gray-100">
            <div className="flex justify-center">
                <div className="w-11/12 flex flex-col gap-4 py-4">
                    {questions.map((e) => {
                        if (e.informations.isVisible == true) {
                            return (
                                <div className="flex items-center justify-center flex-col">
                                    <p className="bg-blue-500 px-3 text-2xl py-2 rounded-lg text-white 00 w-fit">{e.informations.question}</p>
                                    <p className="text-sm">{moment(e.mongoose_created_at).format("DD/MM hh:mm")}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
}

export default QuestionPublicView;
