import './SwitchCustom.css';
const SwitchCustom = ({ checked = false, setChecked = () => {} }) => {
  return (
    <div className={'switch ' + (checked ? 'checked' : '')} onClick={(e) => setChecked(!checked)}>
      <div className={'pin ' + (checked ? 'pinchecked' : '')}></div>
    </div>
  );
};

export default SwitchCustom;
