const tracePath = (table, start, end) => {
  const path = [];
  let next = end;
  while (true) {
    path.unshift(next);
    if (next === start) {
      break;
    }
    next = table[next].vertex;
  }

  return path;
};

const formatGraph = g => {
  const tmp = {};
  Object.keys(g).forEach(k => {
    const obj = g[k];
    const arr = [];
    Object.keys(obj).forEach(v => arr.push({vertex: v, cost: obj[v]}));
    tmp[k] = arr;
  });
  return tmp;
};

export const dijkstra = (graph, start, end) => {
  const map = formatGraph(graph);

  const visited = [];
  const unvisited = [start];
  const shortestDistances = {[start]: {vertex: start, cost: 0}};

  let vertex;
  while ((vertex = unvisited.shift())) {
    // Explore unvisited neighbors
    const neighbors = map[vertex].filter(n => !visited.includes(n.vertex));

    // Add neighbors to the unvisited list
    unvisited.push(...neighbors.map(n => n.vertex));

    const costToVertex = shortestDistances[vertex].cost;

    for (let {vertex: to, cost} of neighbors) {
      const currCostToNeighbor =
        shortestDistances[to] && shortestDistances[to].cost;
      const newCostToNeighbor = costToVertex + cost;
      if (
        currCostToNeighbor === undefined ||
        newCostToNeighbor < currCostToNeighbor
      ) {
        // Update the table
        shortestDistances[to] = {vertex, cost: newCostToNeighbor};
      }
    }

    visited.push(vertex);
  }

  return tracePath(shortestDistances, start, end);
};
