import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import PictureUpdate from "../../Account/PictureUpdate";
import DocsSettingsDescription from "./docs.settings.description";
import DocsSettingsImage from "./docs.settings.image";
import DocsSettingsName from "./docs.settings.name";
import DocsSettingsPDFUrl from "./docs.settings.urlpdf";

const DocsSettingsView = ({ doc, setDocSelected, updateDoc, onDelete }) => {
    const { langKey, menuOpen } = useContext(ApplicationContext);
    const infos = doc.informations;

    return (
        <div className="flex flex-col gap-2 w-full h-full px-2 py-4 bg-white m-4 space-y-3">
            <p className="font-medium text-lg text-left mb-2">Document: {infos["title" + langKey]}</p>
            <DocsSettingsName doc={doc} setDocSelected={setDocSelected} />
            <DocsSettingsImage doc={doc} setDocSelected={setDocSelected} menuOpen={menuOpen} />

            <div>
                <p className="font-medium text-sm text-left mb-2 mt-3">Ajouter une image en header ?</p>
                <PictureUpdate
                    url={doc.informations.imageHeader}
                    size={50}
                    onUpdate={(url) => {
                        const img = new Image();
                        img.onload = () => {
                            setDocSelected({
                                ...doc,
                                informations: {
                                    ...doc.informations,
                                    imageHeader: url,
                                },
                            });
                        };
                        img.src = url;
                    }}
                    customParentClassNames="w-max relative"
                />
            </div>

            {doc.informations.type === "obj" && <DocsSettingsDescription doc={doc} setDocSelected={setDocSelected} />}
            {doc.informations.type === "pdf" && <DocsSettingsPDFUrl doc={doc} setDocSelected={setDocSelected} />}

            <div className="flex justify-center gap-x-3 px-3 mb-5 " style={{ marginTop: 40 }}>
                <div
                    className="border border-gray-500 text-gray-500 transition-all hover:border-red-400 hover:text-red-400 hover:bg-red-200 rounded px-8 py-2 cursor-pointer"
                    onClick={() => {
                        if (window.confirm("Voulez-vous supprimer ce document ?")) {
                            onDelete(doc);
                        }
                    }}
                >
                    <FontAwesomeIcon icon="trash" />
                </div>
                <div
                    className="border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
                    onClick={() => {
                        updateDoc(doc);
                    }}
                >
                    <FontAwesomeIcon icon={"check"} />
                </div>
            </div>
        </div>
    );
};

export default DocsSettingsView;
