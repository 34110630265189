import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import EventMoreInfoList from './EventMoreInfoList';
import { UpdateJsonConfig } from '../../../../Services/Application';
import { toast } from 'react-toastify';
import ObjectSettingsEdit from "../../Objects/Settings/ObjectSettingsEdit";

const EventMoreInfoView = ({customs}) => {
  const {config, application, reloadConfig} = useContext(ApplicationContext);
  const [configEvent, setConfigEvent] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!config) return <></>;

    setConfigEvent(config.eventObject.custom);
  }, []);

  const createLink = (id, placeholder, tint, url) => {
    return {
      id: id,
      identifier: 'CustomObjectDetailCell',
      important: false,
      isSubTitle: false,
      isSubTitleSubEvent: false,
      isTitle: false,
      placeholder: placeholder,
      style: 'line',
      tint: tint,
      type: 'link',
      url: url,
      linkType: 'ukEvent',
    };
  }

  return (
    <div className="grid grid-cols-3 gap-3">
      <EventMoreInfoList
        selectedItem={selectedItem}
        configEvent={configEvent}
        setConfigEvent={async (newConfigEvent) => {
          setConfigEvent(newConfigEvent);
          const newConfig = {...config};
          newConfig.eventObject.custom = newConfigEvent;

          await UpdateJsonConfig(application.apiKey, newConfig);
          await reloadConfig();
          toast.success('Modifications sauvegardées !', {autoClose: 2000, position: 'bottom-center'});
        }}
        onSelectItem={setSelectedItem}
      />
      <ObjectSettingsEdit
        item={configEvent[selectedItem]}
        onUpdate={(item) => {
          const arr = [...configEvent];
          arr[selectedItem] = item;
          setConfigEvent(arr);
        }}
        onDelete={async () => {
          const currentCustomObject = customs[selectedItem] || null;
          if (customs && customs[selectedItem]?.locked) return;
          let arr = [...configEvent];
          arr = arr.filter((e, index) => index !== selectedItem);
          setConfigEvent(arr);
          const newConfig = {...config};
          newConfig.eventObject.custom = arr;
          if (currentCustomObject.type === 'link') {
            if (currentCustomObject.linkType === "user") {
              let indexLinkedConfigUser = newConfig.inscription.customs?.findIndex(obj => obj.id === currentCustomObject.url);
              let indexCustomLinkedUser = newConfig.inscription.customs[indexLinkedConfigUser].custom?.findIndex(elem => elem.id === currentCustomObject.id)
              if (indexCustomLinkedUser >= 0) {
                newConfig.inscription.customs[indexLinkedConfigUser].custom.splice(indexCustomLinkedUser, 1);
              }
            } else {
              let indexLinkedConfigObject = newConfig.customObject?.findIndex(obj => obj.id === currentCustomObject.url);
              let indexCustomLinkedObject = newConfig.customObject[indexLinkedConfigObject].custom?.findIndex(elem => elem.id === currentCustomObject.id)
              if (indexCustomLinkedObject >= 0) {
                newConfig.customObject[indexLinkedConfigObject].custom.splice(indexCustomLinkedObject, 1);
              }
            }
          }
        }}
        onSave={async () => {
          const newConfig = {...config};
          newConfig.eventObject.custom = configEvent;
          const currentCustomObject = configEvent[selectedItem];
          if (currentCustomObject.type === 'link') {
            if (currentCustomObject.linkType === "user") {
              let indexLinkedConfigUser = newConfig.inscription.customs?.findIndex(obj => obj.id === currentCustomObject.url);
              let indexCustomLinkedUser = newConfig.inscription.customs[indexLinkedConfigUser].custom?.findIndex(elem => elem.id === currentCustomObject.id)
              if (indexCustomLinkedUser === -1) {
                newConfig.inscription.customs[indexLinkedConfigUser].custom.push(createLink(currentCustomObject.id, `link-ukEvent`, `link-ukEvent`, 'ukEvent'));
              }
            } else {
              let indexLinkedConfigObject = newConfig.customObject?.findIndex(obj => obj.id === currentCustomObject.url);
              let indexCustomLinkedObject = newConfig.customObject[indexLinkedConfigObject].custom?.findIndex(elem => elem.id === currentCustomObject.id)
              if (indexCustomLinkedObject === -1) {
                newConfig.customObject[indexLinkedConfigObject].custom.push(createLink(currentCustomObject.id, `link-ukEvent`, `link-ukEvent`, 'ukEvent'));
              }
            }
          }
          toast.success('Modifications sauvegardées !', {autoClose: 2000, position: 'bottom-center'});
        }}
      />
    </div>
  );
};
export default EventMoreInfoView;
