export const layersLine = [
  {
    'id': 'gl-draw-itinary',
    'type': 'line',
    'filter': ['all',
      ['==', '$type', 'LineString'],
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#00FF00',
      'line-width': 5
    }
  },
]

export const layerStyle3d = [
  {
    'id': 'gl-draw-polygon-fill',
    'type': 'fill',
    'filter': ['all',
      ['==', '$type', 'Polygon'],
    ],
    'paint': {
      'fill-color': [
        'case',
        ['has', 'objectColor'], ['get', 'objectColor'],
        ['has', 'categoryColor'], ['get', 'categoryColor'],
        '#000000',
      ],
      'fill-opacity': 0.2,
    }
  },
  {
    id: "room-extrusion",
    type: "fill-extrusion",
    filter: ["all", ["==", "$type", "Polygon"]],
    paint: {
      "fill-extrusion-color": [
        'case',
        ['has', 'objectColor'], ['get', 'objectColor'],
        ['has', 'categoryColor'], ['get', 'categoryColor'],
        '#000000',
      ],

      "fill-extrusion-height": [
        "+",
        ['case',
          ['has', 'objectHeight'], ["to-number", ['get', 'objectHeight']],
          ['has', 'categoryHeight'], ["to-number", ['get', 'categoryHeight']],
          0,
        ],
      ],

      // "fill-extrusion-base": ["case", ["has", "floorNumber"], ["*", ["get", "floorNumber"], 4], 0],
      "fill-extrusion-opacity": 1,
    },
  },
  // Routes
  //{
  //  'id': 'gl-draw-line',
  //  'type': 'line',
  //  'filter': ['all',
  //    ['==', '$type', 'LineString'],
  //    ['!=', 'mode', 'static']
  //  ],
  //  'layout': {
  //    'line-cap': 'round',
  //    'line-join': 'round'
  //  },
  //  'paint': {
  //    'line-color': '#d03b3b',
  //    'line-width': 2
  //  }
  //},
  // Point text
  {
    'id': 'gl-draw-text-inactive',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
      ['==', 'type', 'text'],
    ],
    'layout': {
      'text-font': ['Open Sans Light'],
      'text-field': ['get', 'text'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto',
    }
  },
  // Point-image Style
  {
    'id': 'gl-draw-image-inactive',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
      ['any', ['==', 'type', 'image'], ['==', 'type', 'pattern']]
    ],
    'paint': {
      'icon-opacity': ['case',
        ['==', ['get', 'type'], 'image'], 1,
        0.2,
      ],
    },
    'layout': {
      'symbol-sort-key': ['case',
        ['==', ['get', 'type'], 'pattern'], 1,
        2,
      ],
      'icon-image': ['get', 'image'],
      'icon-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 0.0015, 23, ['get', 'size']],
      'icon-allow-overlap': true,
      'icon-rotation-alignment': 'auto',
      // 'icon-rotation-alignment': ['case',
      //   ['all', ['has', 'canRotate'], ['==', ['get', 'canRotate'], 'true']], 'auto',
      //   ['all', ['has', 'canRotate'], ['==', ['get', 'canRotate'], 'false']], 'map',
      //   'map',
      // ]
    }
  },
];