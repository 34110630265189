import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { toast } from "react-toastify";
import "moment/locale/fr";
import LinkCell from "./LinkCell";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UKLink } from "../../../../Class/UKLink";

function LinksListComponent({ link, module }) {
  const { application, config } =
    useContext(ApplicationContext);
  const { user, findAllLinksWithUser, allLinks, findLinkObjectType } = useContext(UserContext);
  const [linksFound, setLinksFound] = useState([])

  const reloadLink = () => {
    var links = link.split("/")
    var oldLink = "user"
    var all = findAllLinksWithUser(links[0])
    for (var i = 1; i < links.length; i++) {
      var objs = []
      for (var j = 0; j < all.length; j++) {
        var otherID = all[j].informations.type1 === oldLink ? all[j].informations.id2 : all[j].informations.id1
        var otherTYPE = all[j].informations.type1 === oldLink ? all[j].informations.type2 : all[j].informations.type1
        objs = objs.concat(findLinkObjectType(otherID, otherTYPE, links[i]))
      }
      all = objs
    }
    setLinksFound(all)
  }

  useEffect(() => {
    reloadLink()
    // eslint-disable-next-line
  }, [link, allLinks])

  const exportAction = async () => {
    toast.warning("Fichier en cours de création ...", { autoClose: 2000, position: "bottom-center" })

    var result = []
    for (var i = 0; i < linksFound.length; i++) {
      var link = new UKLink(linksFound[i])
      var json = await link.getJson(application.apiKey, user.authToken, config)
      result.push(json)
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(result)
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "export" + fileExtension);

  }
  return (
    <div >
      <h2 className="text-3xl font-semibold text-left px-4 text-gray-600 mb-10">{module.title}</h2>
      <div className="items-right text-right px-4">
        <button onClick={exportAction}

          className="px-2 rounded-md font-normal text-xs py-1 border"
          style={{
            borderColor: (config || {}).mainColor,
            color: (config || {}).mainColor
          }}>Exporter</button>
      </div>
      {linksFound.map(elem => {
        return (<LinkCell link={elem} configLink={link.split("/")} />)
      })}
    </div>
  );
}

export default LinksListComponent;
