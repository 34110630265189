import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import { useParams } from 'react-router-dom';
import ObjectSettingsList from './ObjectSettingsList';
import { toast } from 'react-toastify';
import { UpdateJsonConfig } from '../../../../Services/Application';
import ObjectSettingsEdit from './ObjectSettingsEdit';

const ObjectSettingsView = ({}) => {
  const { config, application, reloadConfig } = useContext(ApplicationContext);
  const params = useParams();
  const [customs, setCustoms] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const initConfig = async () => {
    try {
      var type = params.typeobject;
      var objConfig = config.customObject.find((e) => e.id == type);
      setCustoms(objConfig.custom);
    } catch (e) {}
  };
  useEffect(() => {
    initConfig();
  }, [params]);
  return (
    <div className="grid grid-cols-3 gap-3">
      <ObjectSettingsList
        selectedItem={selectedItem}
        customs={customs}
        setCustoms={async (customs) => {
          setCustoms(customs);

          const newConfig = { ...config };
          var type = params.typeobject;
          var indexConfig = newConfig.customObject.findIndex((e) => e.id == type);
          if (indexConfig >= 0) {
            newConfig.customObject[indexConfig].custom = customs;
            await UpdateJsonConfig(application.apiKey, newConfig);
            await reloadConfig();
          }
          toast.success('Modifications sauvegardées !', { autoClose: 2000, position: 'bottom-center' });
        }}
        onSelectItem={setSelectedItem}
      />
      {
        <ObjectSettingsEdit
          item={customs[selectedItem]}
          onUpdate={(item) => {
            var arr = [...customs];
            arr[selectedItem] = item;
            setCustoms(arr);
          }}
          onDelete={async () => {
            var arr = [...customs];
            arr = arr.filter((e, index) => index != selectedItem);
            setCustoms(arr);
            const newConfig = { ...config };
            var type = params.typeobject;
            var indexConfig = newConfig.customObject.findIndex((e) => e.id == type);
            if (indexConfig >= 0) {
              newConfig.customObject[indexConfig].custom = arr;
              await UpdateJsonConfig(application.apiKey, newConfig);
              await reloadConfig();
            }
          }}
          onSave={async () => {
            const newConfig = { ...config };
            var type = params.typeobject;
            var indexConfig = newConfig.customObject.findIndex((e) => e.id == type);
            if (indexConfig >= 0) {
              newConfig.customObject[indexConfig].custom = customs;
              await UpdateJsonConfig(application.apiKey, newConfig);
              await reloadConfig();
            }
            toast.success('Modifications sauvegardées !', { autoClose: 2000, position: 'bottom-center' });
          }}
        />
      }
    </div>
  );
};
export default ObjectSettingsView;
