import React, { useContext, useCallback, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FileSelector } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../Context/ApplicationContext';
import { UploadFileAll } from '../../Services/Utils';
import { UserContext } from '../../Context/UserContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CreateObject, GetObjectsAll } from '../../Services/UKObject';
import CloseButton from '../Common/Button/CloseButton';

export default function UploadFileModal({ modalIsOpen, setModalIsOpen, onSelectFile = () => {} }) {
  const { config, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxHeight: '90vh',
      overflow: 'scroll',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 40,
      minWidth: '60vw',
      maxWidth: '90vw',
    },
  };

  const reloadFiles = async () => {
    var files = await GetObjectsAll('ukDocumentWeb', application.apiKey, (user || {}).authToken);
    setFiles(files);
  };
  useEffect(() => {
    reloadFiles();
  }, []);
  const uploadFileDocument = useCallback(
    async (fileName, id, index) => {
      if (fileName == null) {
        return;
      }
      console.log(fileName);
      try {
        setLoading(true);
        const file = await UploadFileAll(fileName, application.apiKey, (user || {}).authToken);
        console.log(file);
        setLoading(false);
        var obj = await CreateObject(
          'ukDocumentWeb',
          { url: file.url, name: fileName.name },
          application.apiKey,
          (user || {}).authToken
        );
        onSelectFile(file.url);
        console.log(obj);
      } catch (e) {
        console.log(e);
      }
    },
    // eslint-disable-next-line
    []
  );

  const truncate = (text, size) => {
    if (text.length < size) {
      return text;
    }
    var s = size / 2 - 1;
    if (s <= 0) {
      return text.slice(0, size);
    }

    var begin = text.slice(0, s);
    var end = text.slice(text.length - s, text.length);
    return begin + '...' + end;
  };

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Example Modal">
      <p className="font-bold text-xl ">Fichiers</p>
      <CloseButton
        onClick={() => {
          setModalIsOpen(false);
        }}
        className={'absolute top-2 right-2 '}
      />
      <FileSelector
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        placeholder="Glissez déposez ou cliquez pour parcourir"
        onChange={(value) => {
          uploadFileDocument(value[0]);
        }}
      />
      <div className="grid grid-cols-3 py-2 gap-2">
        {files && files.map((e) => {
          return (
            <div
              onClick={(event) => {
                if (event.defaultPrevented) {
                  return;
                }
                console.log('PREVENT DEFAULT MARCHE PAS');
                onSelectFile(e.informations.url);
              }}
              className="border justify-between border-gray-300 rounded flex px-2 py-2 hover:bg-blue-50 hover:text-blue-400 hover:border-blue-400 cursor-pointer flex-col items-center justify-center gap-2"
            >
              <img
                src={e.informations.url}
                style={{ maxWidth: 100, maxHeight: 100 }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = 'https://bitsofco.de/content/images/2018/12/broken-1.png';
                }}
              />
              <div className="text-center flex items-center gap-2 ">
                <p className="text-sm font-light">{truncate(e.informations.name, 15)}</p>
                <div
                  className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(e.informations.url, '_blank');
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {loading && (
        <div
          className="absolute top-0 left-0 h-full w-full bg-white bg-opacity-80 flex items-center gap-2 justify-center "
          style={{ zIndex: 10000 }}
        >
          <FontAwesomeIcon icon={faSpinner} className="spin" />
          <p className="font-medium">Chargement ...</p>
        </div>
      )}
    </Modal>
  );
}
