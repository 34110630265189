import CustomModal from "../../Common/CustomModal.component";
import CustomInputBox from "../../Common/Inputs/customInputBox.component";
import {useContext, useEffect, useState} from "react";
import CommonButton from "../../Common/Button/CommonButton";
import {faFloppyDisk, faTrash} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import {UpdateJsonConfig} from "../../../Services/Application";
import {useHistory} from "react-router-dom";

const CategoryModifyModal = ({isOpen, setIsOpen, infos}) => {
  const history = useHistory();
  const {config, application, reloadConfig} = useContext(ApplicationContext);
  const [name, setName] = useState("");

  useEffect(() => {
    if (!infos) return;
    setName(infos.name);
  }, [infos])

  const jsonPath = [
    {
      type: "objects",
      path: "customObject",
    },
    {
      type: "users",
      path: "inscription.customs",
    },
    {
      type: "docs",
      path: "docs",
    },
  ]

  const onSave = async () => {
    const jsonCurrentPath = jsonPath.find(e => e.type === infos.type);
    if (!jsonCurrentPath) return;
    let newConfig = {...config};
    let tmpConfig = newConfig
    jsonCurrentPath.path.split('.').forEach((subPath) => {
      tmpConfig = tmpConfig[subPath];
    })
    if (tmpConfig === undefined) return;
    tmpConfig.find(e => e.id === infos.id).name = name;
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
    setIsOpen(null);
  }

  const onDelete = async () => {
    const jsonCurrentPath = jsonPath.find(e => e.type === infos.type);
    if (!jsonCurrentPath) return;
    let newConfig = {...config};
    let tmpConfig = newConfig
    jsonCurrentPath.path.split('.').forEach((subPath) => {
      tmpConfig = tmpConfig[subPath];
    })
    if (tmpConfig === undefined) return;
    const index = tmpConfig.findIndex(e => e.id === infos.id);
    tmpConfig.splice(index, 1);
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
    history.push("/v/")
    setIsOpen(null);
  }

  return (
    <CustomModal title="Modifier la catégorie" isOpen={isOpen} onClose={() => {
      setIsOpen(null);
    }}>
      <CustomInputBox title="Quel est le nom de la catégorie ?" onChange={(e) => {
        setName(e);
      }} value={name}/>

      <div className="flex gap-5">
        <CommonButton customClassName="w-max mt-5" title="Supprimer la catégorie" useAppTheme={false}
                      customColor={"#F87171"} icon={<FontAwesomeIcon icon={faTrash}/>} onClick={onDelete}/>
        <CommonButton customClassName="w-max mt-5" title="Sauvegarder" useAppTheme={false}
                      customColor={"#22C55E"} icon={<FontAwesomeIcon icon={faFloppyDisk}/>} onClick={onSave}/>
      </div>
    </CustomModal>
  );
}

export default CategoryModifyModal;
