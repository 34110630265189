import { useContext } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { ApplicationContext } from '../../Context/ApplicationContext';
function SelectLangInline({ small = true, inline = false }) {
  const { language, setLanguage, allLanguage } = useContext(ApplicationContext);
  if (!allLanguage) {
    return <></>;
  }

  /* setLanguage(allLanguage.find((el) => e.value === el.id));*/
  return (
    <div className={'flex gap-2 ' + (inline ? '' : 'flex-col')}>
      {allLanguage.map((e) => {
        return (
          <ReactCountryFlag
            onClick={() => {
              // console.log('click');
              setLanguage(e);
            }}
            countryCode={e.countryCode}
            svg
            className={e.id == language.id ? '' : 'grayscale'}
            style={{
              width: e.id == language.id ? '2em' : '1.2em',
              height: e.id == language.id ? '2em' : '1.2em',
            }}
          />
        );
      })}
    </div>
  );
}

export default SelectLangInline;
