import moment from "moment/moment";
import {useContext} from "react";
import {ApplicationContext} from "../../../Context/ApplicationContext";

function DrawEventCard({elem, setModalOpen}) {
  const {langKey} = useContext(ApplicationContext);
  const valueDebut = elem.getInformations('dateDebut', langKey);
  const valueFin = elem.getInformations('dateFin', langKey);
  const dateDebut = `${moment(valueDebut).format("ddd")} ${moment(valueDebut).format('D')}`;
  const heureDebut = moment(valueDebut).format('LT');
  const heureFin = moment(valueFin).format('LT');

  return (
    <div className={'bg-white border-2 border-stone-200 rounded-lg flex flex-row items-center px-2 m-2 w-1/2 h-full hover:bg-opacity-50 cursor-pointer'}
    onClick={() => setModalOpen({open: true, info: elem})}>
      <div className={'flex flex-col m-2 w-max'}>
        <p className={'font-bold w-max'}>{dateDebut}</p>
        <p className={'font-semibold text-stone-500'}>{heureDebut}</p>
        <p className={'font-light'}>{heureFin}</p>
      </div>
      <div className={'flex flex-col m-2 w-full break-all'}>
        <p className={'text-left font-bold'}>{elem.getInformations('title', langKey)}</p>
        <p className={'text-left font-semibold text-stone-500'}>{elem.getInformations('subtitle', langKey)}</p>
        <p className={'text-left font-light'}>{elem.getInformations('descript', langKey)}</p>
      </div>
    </div>
  );
}

export default function DrawListEventCard({list, setModalOpen}) {
  return (
    <div className={'flex flex-row flex-wrap w-full m-8 h-max items-center justify-center'}>
      {list.map((elem, index) => <DrawEventCard elem={elem} key={index} setModalOpen={setModalOpen}/>)}
    </div>
  )
}