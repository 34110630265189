import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../Context/UserContext";
import TrashButton from "../../../Common/Button/TrashButton";
import CustomObjectCellVertical from "../../../Common/CustomObjectCellVertical";
import CustomUsersCellVertical from "../../User_old/CustomUsersCellVertical";

const ObjectsExistingLinks = ({ linkType, linkID, form, setForm, displayedLinks, setDisplayedLinks }) => {
    const history = useHistory();
    const { isAdmin } = useContext(UserContext);

    return (
        <div className="flex w-full grid grid-cols-4 gap-2">
            {displayedLinks &&
                displayedLinks.constructor === Array &&
                displayedLinks.length > 0 &&
                displayedLinks.map((link, index) => {
                    return (
                        <div key={index} className="relative p-0">
                            <div className="absolute right-0 top-0">
                                {isAdmin() && (
                                    <TrashButton
                                        className="px-2 py-1"
                                        onClick={() => {
                                            if (window.confirm("Voulez vous supprimer ?")) {
                                                setForm({
                                                    ...form,
                                                    [linkID]: form[linkID].filter((formLink) => formLink !== link),
                                                });
                                                setDisplayedLinks((prevState) => prevState.filter((e) => e !== link));
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            {linkType === "user" ? (
                                <CustomUsersCellVertical
                                    onClick={() => {
                                        history.push(`/v/user/${link}`);
                                    }}
                                    id={link}
                                />
                            ) : (
                                <CustomObjectCellVertical
                                    onClick={() => {
                                        history.push(`/v/object/${link}`);
                                    }}
                                    id={link}
                                    type={linkType == "ukEvent" ? "event" : "object"}
                                />
                            )}
                        </div>
                    );
                })}
            {!displayedLinks || (displayedLinks.length === 0 && <p className="text-sm italic text-gray-800 ml-2">Aucun lien</p>)}
        </div>
    );
};

export default ObjectsExistingLinks;
