import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetObjectsSearchV2, GetUKObjectById } from "../../../Services/UKObject";
import { GetUserById } from "../../../Services/User";
import Pagination from "../../Common/Pagination";
import IframeObjectModal from "../../Iframe/Object/modal.iframe.object";
import ObjectsActionbar from "./objects.actionbar";
import ObjectsTable from "./objects.table";
import ModalComponent from "./Settings/ObjectModalComponent";
import TableLimiter from "./table.limiter";

const ObjectsView = ({ typeobject, linksOnly, onlySelectObject, onPress }) => {
    const { application, applicationConfig, config } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    let { params } = useRouteMatch();
    const history = useHistory();
    const [info, setInfo] = useState({ page: 0, objects: [], filters: [], maxPage: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState("20");
    const location = useLocation();
    const [modalIsOpen, setModalIsOpen] = useState(new URLSearchParams(location.search).get("isnew") === "1");
    const [iframeModal, setIframeModal] = useState(false);

    const getPage = async (page) => {
        setIsLoading(true);
        let newObjs;
        if (linksOnly) {
            newObjs = await getPageFromLinks();
        } else {
            newObjs = await GetObjectsSearchV2(typeobject, Number.isInteger(page) ? page : info.page, application.apiKey, (user || {}).authToken, info.filters, limit);
        }
        let newInfos = {
            ...info,
            objects: newObjs.data,
            maxPage: newObjs.totalPage,
        };
        if (Number.isInteger(page) && page !== info.page) {
            newInfos = {
                ...newInfos,
                page: Number.isInteger(page) ? page : info.page,
            };
        }
        setInfo(newInfos);
        setIsLoading(false);
    };

    const findObjLink = (linksArr, knownLinks) => {
        let objLinks = [];
        for (const link of linksArr) {
            if (link.linkType === "object") {
                objLinks.push(link);
                const foundObjLinks = config.customObject
                    .find((c) => c.id === link.url)
                    .custom.filter((c) => c.type === "link" && c.linkType === "object" && knownLinks.findIndex((kl) => kl === link.id) === -1);
                objLinks.push(...findObjLink(foundObjLinks, [...knownLinks, link.id]));
            }
        }
        return objLinks;
    };

    const getLinkedObj = async (id, type, links, typeNeeded, knownLinks) => {
        const obj = await GetUKObjectById(id, application.apiKey, user.authToken);
        for (const link of links) {
            if (!obj) continue;
            const linksArr = obj.informations[link.id];
            if (linksArr) {
                if (link.url === typeNeeded) return linksArr;
                for (const objLink of linksArr) {
                    if (knownLinks.findIndex((kl) => kl === objLink) === -1) {
                        knownLinks.push(objLink);
                        const result = await getLinkedObj(objLink, link.url, links, typeNeeded, knownLinks);
                        if (result) return result;
                    }
                }
            }
        }
        return null;
    };

    const getPageFromLinks = async () => {
        if (!config || !user) return {};
        let linksConf = config.inscription?.customs?.find((custom) => custom.id === user.informations.user_custom_type)?.custom?.filter((cat) => cat.type === "link");

        if (!linksConf) return {};
        let recursiveLinks = findObjLink(linksConf, []);

        let linksContent = [];
        let updatedUser = await GetUserById(user._id, application.apiKey, user.authToken);
        for (const link of recursiveLinks) {
            if (link.linkType !== "object" || link.url !== typeobject) continue;

            if (updatedUser.informations[link.id]) {
                updatedUser.informations[link.id]?.map((userLinks) => {
                    linksContent.push(userLinks);
                });
            }
        }
        if (!linksContent.length) {
            for (const link of recursiveLinks) {
                const userLinks = updatedUser.informations[link.id];
                if (userLinks) {
                    for (const id of userLinks) {
                        const result = await getLinkedObj(id, link.url, recursiveLinks, typeobject, [updatedUser._id]);
                        if (result) linksContent.push(...result);
                    }
                }
            }
        }
        let newObjs = [];
        for (const id of linksContent) {
            let obj = await GetUKObjectById(id, application.apiKey, user.authToken);
            if (obj) newObjs.push(obj);
        }
        return { data: newObjs, totalPage: 0 };
    };

    useEffect(() => {
        getPage();
    }, [info.page, info.filters, limit]);

    useEffect(() => {
        getPage(0);
    }, [params.typeobject]);

    if (applicationConfig === null) return <div></div>;

    const configObject = applicationConfig.getCustomObjectByID(typeobject);

    return (
        <div className={!onlySelectObject ? "bg-gray-100" : ""}>
            {!onlySelectObject && <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">{configObject.name}</p>}
            <div className="flex w-full justify-center">
                <ObjectsActionbar
                    info={info}
                    setInfo={setInfo}
                    typeobject={typeobject}
                    modalSetter={setModalIsOpen}
                    objConfig={configObject}
                    iframeModal={setIframeModal}
                    objectCount={info.objects?.length}
                    onlySelectObject={onlySelectObject}
                />
            </div>
            <div className="flex w-11/12 m-auto">
                <ObjectsTable
                    typeobject={typeobject}
                    info={info}
                    loading={isLoading}
                    link={onlySelectObject ? false : true}
                    onCellClick={(elem, event) => {
                        if (onlySelectObject) {
                            onPress(elem);
                            return;
                        }
                        if (event.metaKey || event.ctrlKey) {
                            const win = window.open(`/v/object/${elem._id}`, "_blank");
                            win?.focus();
                        } else {
                            history.push(`/v/object/${elem._id}`, { back: true });
                        }
                    }}
                />
            </div>
            <ModalComponent object={info.objects} modalIsOpen={modalIsOpen} setmodalIsOpen={setModalIsOpen} typeobject={typeobject} />
            <IframeObjectModal modalIsOpen={iframeModal} setmodalIsOpen={setIframeModal} />
            <div className="flex text-center bottom-4 pb-8 w-11/12 m-auto">
                <Pagination
                    nbPages={info.maxPage}
                    page={info.page}
                    total={info.maxPage}
                    fetch={(value) => {
                        setInfo({
                            ...info,
                            page: value,
                        });
                    }}
                />
                <TableLimiter limit={limit} setLimit={setLimit} />
            </div>
        </div>
    );
};

export default ObjectsView;
