import {eventkeyFormJson} from "./eventkey.formJson";
import React, {useContext, useState} from "react";
import CommonButton from "../../Common/Button/CommonButton";
import CustomInputBox from "../../Common/Inputs/customInputBox.component";
import CustomColorInput from "../../Common/Inputs/customColorInput.component";
import PictureUpdate from "../Account/PictureUpdate";
import {useHistory} from "react-router-dom";
import EventkeyPhonePreview from "./eventkey.phonePreview";
import {CreateNewApp, GetAppDemo, UpdateApplication, UpdateJsonConfig} from "../../../Services/Application";
import {toast} from "react-toastify";
import {CopyAdmin} from "../../../Services/User";
import {UserContext} from "../../../Context/UserContext";
import {ApplicationContext} from "../../../Context/ApplicationContext";

function EventkeyForm({}) {
  const {user, setUser} = useContext(UserContext);
  const {setApplication} = useContext(ApplicationContext)
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [modules, setModules] = useState(null);
  const [json, setJson] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const updateSelectedAnswer = (step, questionStep, answer) => {
    let newSelectedAnswer = {...selectedAnswer};

    if (!newSelectedAnswer[step]) newSelectedAnswer[step] = {};
    for (const key of Object.keys(newSelectedAnswer[step])) {
      if (parseInt(key) > questionStep) delete newSelectedAnswer[step][key];
    }
    newSelectedAnswer[step][questionStep] = answer;
    setSelectedAnswer(newSelectedAnswer);
  }

  const getDemo = async () => {
    const json = await GetAppDemo({
      name: selectedAnswer['2'].name,
      desc: selectedAnswer['2'].desc,
      logo: selectedAnswer['2'].logo,
      category: selectedAnswer['2'].category,
      color: selectedAnswer['2'].color,
      eventType: selectedAnswer['1']['1']
    })
    setJson(json);
    const home = json.home;
    const homeModules = Object.fromEntries(home.map(e => [e._id, {
      name: e.text,
      fullSize: e.style === 'FULLSQUARE',
      image: e.image
    }]))

    // console.log({modules: homeModules, tabBar: json.tabBarV2})
    setModules({modules: homeModules, tabBar: json.tabBarV2});
  }

  const createNewApp = async () => {
    const name = selectedAnswer['2'].name;
    // console.log(json, name, user);
    if (!json || !name || !user) return;

    setSubmitting(true);
    let app = await CreateNewApp(selectedAnswer['2'].name);
    if (app === 409) {
      toast.error("Ce nom d'apllication est déjà utilisé !", {autoClose: 2000});
      setSubmitting(false);
      return;
    }
    const apiKey = app.apiKey;

    app = await UpdateApplication(apiKey, {}, app._id, {
      name: name,
      logo: selectedAnswer['2'].logo,
      category: selectedAnswer['2'].category,
      description: selectedAnswer['2'].desc,
      invitecode: name.toLowerCase().trim().replace(/\s\s+/g, " ").replace(/ /g, "_"),
      "onesignal": {
        "apiKey": "",
        "appId": ""
      },
      "xconfig": {
        "twitterconsumer": "",
        "twittersecret": "",
        "linkedinclient": "",
        "linkedinappid": "",
        "linkedinclientsecret": "",
        "branchlive": "",
        "branchtest": ""
      },
    })
    setApplication(app);

    await UpdateJsonConfig(apiKey, json);
    const userObject = await CopyAdmin(user.token, app._id);

    setUser(userObject);
    history.push('/v/home');
  }

  if (currentStep === undefined) return <></>
  return (
    <div className="">
      <div className="flex items-center justify-between h-[60px] w-full border-b bg-white">
        <img className="w-[180px] ml-5" src="/icons/usekey.png" alt="logo"/>
        <p className="text-blue-500 mr-4 cursor-pointer hover:opacity-70" onClick={() => {
          history.push('/eventkey/home');
        }}>Mes applications</p>
      </div>
      <div className="flex items-start justify-center mr-20">
        <div className="flex flex-col items-center justify-center w-full">

          <div className="bg-white w-3/5 mt-10 flex flex-col border rounded">
            <p className="text-left text-gray-700 text-lg p-4">{eventkeyFormJson[`step${currentStep}`].title}</p>

            <div className="w-full">
              {eventkeyFormJson[`step${currentStep}`]?.questions?.map(question => {
                if (!eventkeyFormJson[`step${currentStep}`].noFilter && !selectedAnswer[currentStep] && question.step !== 0) return null;
                if (!eventkeyFormJson[`step${currentStep}`].noFilter && selectedAnswer[currentStep] && selectedAnswer[currentStep][question.step - 1] !== question.id) return null;

                return (
                  <div>
                    <p className="text-left text-gray-700 text-sm pl-2">{question.question}</p>
                    <div className="w-full grid grid-cols-2">
                      {question.answer.map((answer, index) => {
                        return (<div key={index} className="p-2 w-full flex flex-col justify-start">
                          {answer.type === 'button' && <div className={``}>
                            <CommonButton customClassName="p-2 justify-center" title={answer.title} onClick={() => {
                              updateSelectedAnswer(currentStep, question.step, answer.id);
                            }}/>
                          </div>}

                          {answer.type === 'text' && <CustomInputBox title={answer.title} value={selectedAnswer?.[currentStep]?.[question.step] || ''} onChange={(value) => {
                            updateSelectedAnswer(currentStep, question.step, value);
                          }}/>}

                          {answer.type === 'selector' && <select
                            className="bg-gray-100 h-10 rounded"
                            value={selectedAnswer?.[currentStep]?.[question.step] || ''}
                            onChange={(e) => {
                              updateSelectedAnswer(currentStep, question.step, e.target.value);
                            }}>
                            <option value=''>-- Aucune catégorie selectionné --</option>
                            {answer.list && answer.list.map((e, index) => {
                              return (
                                <option key={index} value={e.value}>{e.name}</option>
                              )
                            })}
                          </select>}

                          {answer.type === 'image' && <div>
                            <p>{answer.title}</p>
                            <PictureUpdate url={selectedAnswer?.[currentStep]?.[question.step] || ''} size={100} onUpdate={(url) => {
                              updateSelectedAnswer(currentStep, question.step, url);
                            }} customParentClassNames="w-max relative"/>
                          </div>}

                          {answer.type === 'color' && <CustomColorInput title={answer.title} value={selectedAnswer?.[currentStep]?.[question.step] || undefined} onChange={(value) => {
                            updateSelectedAnswer(currentStep, question.step, value);
                          }}/>}
                        </div>)
                      })}
                    </div>
                  </div>)
              })}

              <div className="flex justify-end mt-4">
                {selectedAnswer[currentStep] && Object.keys(selectedAnswer[currentStep]).length === eventkeyFormJson[`step${currentStep}`].minAnswers &&
                  <CommonButton customClassName="w-[200px] m-2 p-2 justify-center" title='Continuer' onClick={async () => {
                    setCurrentStep(prevState => prevState + 1);
                    if (eventkeyFormJson[`step${currentStep + 1}`].done) await getDemo();
                  }}/>}
              </div>

              {/*Debug*/}
              {/*{eventkeyFormJson[`step${currentStep}`].done && <pre className='text-gray-700'>*/}
              {/*  /!*{JSON.stringify(selectedAnswer, null, 2)}*!/*/}
              {/*  {JSON.stringify(*/}
              {/*    {*/}
              {/*      name: selectedAnswer['2'].name,*/}
              {/*      desc: selectedAnswer['2'].desc,*/}
              {/*      logo: selectedAnswer['2'].logo,*/}
              {/*      category: selectedAnswer['2'].category,*/}
              {/*      color: selectedAnswer['2'].color,*/}
              {/*      eventType: selectedAnswer['1']['1']*/}
              {/*    }, null, 2)}*/}
              {/*</pre>}*/}
              {eventkeyFormJson[`step${currentStep}`].done && <CommonButton customClassName="w-max p-2 justify-center m-auto mt-8 mb-6" title="Créer mon application" onClick={() => {
                if (submitting) return;
                createNewApp()
              }}/>}
            </div>
          </div>
        </div>
        {eventkeyFormJson[`step${currentStep}`].done && <EventkeyPhonePreview modules={modules}/>}
      </div>
    </div>);
}

export default EventkeyForm;
