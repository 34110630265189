import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { GetObjectsAll } from "../../../../Services/UKObject";
import { UserContext } from "../../../../Context/UserContext";

const EventLinkTable = ({loading, onCellClick, selectedList, selectedKey}) => {
  const {application, config, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [configObject, setConfigObject] = useState();
  const [content, setContent] = useState();

  useEffect(() => {
    getEvents();
  }, [application, user]);

  const isSelected = (row) => {
    try {
      return selectedList.findIndex(e => {
        return e === row
      }) >= 0
    } catch (e) {
      return false;
    }
  }

  const getEvents = async () => {
    if (!application || !user) return;
    const newEvents = await GetObjectsAll('ukEvent', application.apiKey, (user || {}).authToken);
    getEventsConfig();
    getEventContent(newEvents);
  }

  const getEventsConfig = () => {
    if (!config) return;
    let tableConfig = config.eventObject.custom;
    tableConfig = tableConfig
      .filter(custom => custom.type !== 'link' && custom.type !== 'mapLink')
      .map(obj => {
        return ({title: obj.tint, id: obj.id});
      });
    const defaultConfig = [{title: 'Titre', id: `title${langKey || ''}`},
      {title: 'Sous-titre', id: `subtitle${langKey || ''}`},
      {title: 'Description', id: `descript${langKey || ''}`},
      {title: 'Salle', id: `salle${langKey || ''}`},
      {title: 'Du - Au', id: 'dateDebut'},
      {title: 'Horraire', id: 'dateFin'}
    ];
    for (const conf of defaultConfig) {
      if (tableConfig.find(c => c.id === conf.id) === -1) {
        tableConfig.unshift(conf);
      }
    }
    setConfigObject(tableConfig);
    return tableConfig;
  }

  const getEventContent = (currentEvents) => {
    if (!currentEvents) return;
    const config = getEventsConfig();

    setContent(currentEvents.map(event => {
      let contentRow = [event._id];
      for (const conf of config) {
        contentRow.push(event.informations[conf.id] || event.informations[`${conf.id}${langKey}` || ''])
      }
      return contentRow;
    }));
  }

  return (
    <div className="bg-gray-200 w-full text-left border border-gray-300 border-separate rounded-md" style={{
      overflowX: "auto",
      display: "block",
    }}>
      {configObject &&
        <table style={{minWidth: "100%"}}>
          <thead className="bg-white border-none text-base rounded-md">
          <tr>
            {configObject.map((row) => {
              return (
                <th className="text-xs text-gray-500 font-semibold" style={{
                  padding: "3px 10px"
                }}>{row.title}</th>
              )
            })}
          </tr>
          </thead>
          <tbody className="text-xs text-left bg-white">
          {content && content.length > 0 && content.map((elem, index) => {
            return (
              <tr className="h-10 hover:opacity-60">
                {elem.map((cell, cellIndex) => {
                  if (typeof cell === "object") return <td></td>;
                  if (cellIndex === 0) return;
                  let cellColor = index % 2 === 0 ? "#F6F6F6" : "";
                  if (isSelected(elem[0]))
                    cellColor = '#BBF7D0';
                  return (
                    <td onClick={() => onCellClick({_id: elem[0]})} className="text-xs cursor-pointer"
                        style={{
                          color: "#555555",
                          padding: "3px 10px",
                          background: cellColor,
                          whiteSpace: "nowrap",
                          maxWidth: "50ch",
                          overflow: "hidden"
                        }}>{cell}</td>
                  )
                })}
              </tr>
            )
          })}
          </tbody>
        </table>}
      {!loading && (!content || content.length === 0) && <p className="text-gray-800 font-bold text-center">Aucun élément trouvé</p>}
      {loading && <p className="text-gray-800 font-bold text-center">Chargement...</p>}
    </div>
  );
}
export default EventLinkTable;
