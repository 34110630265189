const EventDetailTabbar = ({config, setPage, page, isSubEvent}) => {
  return (
    <div>
      <div className="flex gap-5">
        <p className={`${!page ? 'font-bold' : ''} text-xs cursor-pointer hover:opacity-70`} onClick={() => {
          setPage(null);
        }}> Détails</p>
        {!isSubEvent && <p className={`${page === 'subevent' ? 'font-bold' : ''} text-xs cursor-pointer hover:opacity-70`} onClick={() => {
          setPage('subevent');
        }}> Interventions</p>}
        {config && config.map(e => {
          if (e.type !== 'link') return null;
          return (
            <p className={`${page === e.id ? 'font-bold' : ''} text-xs cursor-pointer hover:opacity-70`} onClick={() => {
              setPage(e.id);
            }}>{e.tint}</p>
          );
        })}
      </div>
      <div className="bg-gray-200 h-0.5 w-full mt-2 mb-5">&nbsp;</div>
    </div>
  );
}

export default EventDetailTabbar;
