import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import chroma from 'chroma-js';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import TrashButton from '../../Common/Button/TrashButton';
import CustomObjectCellVertical from '../../Common/CustomObjectCellVertical';
import ObjectListComponent from '../Object_old/List/ObjectListComponent';
import CustomUsersCellVertical from '../User_old/CustomUsersCellVertical';
import UsersListComponent from '../User_old/UsersListComponent';

export default function CustomEventComponent({ config, index, form, setform, lang }) {
  let history = useHistory();

  const [modalOpen, setmodalOpen] = useState(false);

  var button = null;
  var input = (
    <input
      className="block  text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
      type="text"
      name={config.id}
      id={config.id}
      placeholder={config.placeholder}
      value={form[lang ? `${config.id}_${lang.id}` : config.id]}
      onChange={(e) => setform({ ...form, [lang ? `${config.id}_${lang.id}` : config.id]: e.target.value })}
    />
  );

  if (config.type === 'cv' || config.type === 'keywords') {
    return null;
  }
  if (config.type === 'bigtext') {
    input = (
      <textarea
        rows={10}
        className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline   "
        type="text"
        name={config.id}
        id={config.id}
        placeholder={config.placeholder}
        value={form[lang ? `${config.id}_${lang.id}` : config.id]}
        onChange={(e) => setform({ ...form, [lang ? `${config.id}_${lang.id}` : config.id]: e.target.value })}
      ></textarea>
    );
  }
  if (config.type === 'email')
    input = (
      <input
        className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
        type="email"
        name={config.id}
        id={config.id}
        placeholder={config.placeholder}
        value={form[lang ? `${config.id}_${lang.id}` : config.id]}
        onChange={(e) => setform({ ...form, [lang ? `${config.id}_${lang.id}` : config.id]: e.target.value })}
      />
    );
  if (config.type === 'web')
    input = (
      <input
        className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
        type="url"
        name={config.id}
        id={config.id}
        placeholder={config.placeholder}
        value={form[lang ? `${config.id}_${lang.id}` : config.id]}
        onChange={(e) => setform({ ...form, [lang ? `${config.id}_${lang.id}` : config.id]: e.target.value })}
      />
    );
  if (config.type === 'selector' || config.type === 'multipleSelector' || config.type === 'category') {
    var options =
      config.type === 'category'
        ? config.values.map((e, index) => {
            return { value: e, label: e, color: config.color[index] };
          })
        : config.values.map((e) => {
            return { value: e, label: e };
          });
    const dot = (color = '#ccc') => ({
      alignItems: 'center',
      display: 'flex',

      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    var style = {
      container: (provided) => ({
        ...provided,
        width: '100%',
      }),
      control: (provided, state) => ({
        ...provided,
        backgroundColor: 'rgba(243, 244, 246, 1)',
        borderWidth: 0,
        color: 'red',

        fontSize: '0.875rem',
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: 'rgba(55, 65, 81, 0.6)',
        };
      },
      option: (defaultStyles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color || '#000000');
        if (config.type === 'category')
          return {
            ...defaultStyles,
            backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
            color: isDisabled ? '#ccc' : isSelected ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : data.color,
          };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      singleValue: (defaultStyles, { data }) => {
        if (config.type === 'category') return { ...defaultStyles, ...dot(data.color) };
      },
    };
    var defaultValue = null;
    if (form[lang ? `${config.id}_${lang.id}` : config.id]) {
      if (config.type === 'multipleSelector') {
        var arr = form[lang ? `${config.id}_${lang.id}` : config.id].split('$$//$$');
        defaultValue = [];
        arr.forEach((config) => {
          var i = options.findIndex((e) => e.value === config);
          defaultValue.push(options[i]);
        });
      } else {
        defaultValue = options[options.findIndex((e) => e.value === form[lang ? `${config.id}_${lang.id}` : config.id])];
      }
    }
    if (!defaultValue) defaultValue = '';
    input = (
      <Select
        placeholder="Selectionner"
        styles={style}
        value={defaultValue}
        options={options}
        name={config.id}
        isMulti={config.type === 'multipleSelector'}
        onChange={(a, b) => {
          if (config.type === 'multipleSelector') {
            setform({
              ...form,
              [lang ? `${config.id}_${lang.id}` : config.id]: a.map((e) => e.value).join('$$//$$'),
            });
          } else if (config.type === 'category') {
            setform({ ...form, [lang ? `${config.id}_${lang.id}` : config.id]: a.value, color: a.color });
          } else {
            setform({ ...form, [lang ? `${config.id}_${lang.id}` : config.id]: a.value });
          }
        }}
      />
    );
  }
  if (config.type === 'links' || config.type === 'link') {
    button = (
      <div
        onClick={() => setmodalOpen(true)}
        className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
      >
        Ajouter <FontAwesomeIcon icon={faPlus} />
      </div>
    );

    input = (
      <div className="text-sm appearance-none rounded w-full mb-2 text-gray-700 leading-tight  flex  items-center flex-wrap gap-3 ">
        {(!form[config.id] || form[config.id].length == 0) && <p className="italic opacity-50 font-medium">Aucun element</p>}
        {form[config.id] &&
          form[config.id].map((elem) => {
            if (elem == null) {
              return null;
            }
            return (
              <div className="relative p-0 w-1/4 ">
                <div className="absolute right-0 top-0">
                  <TrashButton
                    className="px-2 py-1 "
                    onClick={() => {
                      if (window.confirm('Voulez vous supprimer ?')) {
                        setform({
                          ...form,
                          [config.id]: form[config.id].filter((e) => e !== elem),
                        });
                      }
                    }}
                  />
                </div>

                {config.url === 'users' ? (
                  <CustomUsersCellVertical
                    onClick={() => {
                      history.push(`/v/user/${elem}`);
                    }}
                    id={elem}
                  />
                ) : (
                  <CustomObjectCellVertical
                    onClick={() => {
                      history.push(`/v/object/${elem}`);
                    }}
                    id={elem}
                    type='object'
                  />
                )}
              </div>
            );
          })}
        <Modal
          isOpen={modalOpen}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: 40,
              minWidth: '50%',
              maxWidth: '90%',
              maxHeight: '90%',
            },
          }}
        >
          <div className="text-right">
            <button
              onClick={() => {
                setmodalOpen(false);
              }}
              className="text-gray-400 font-light text-xl"
              style={{ fontFamily: 'Arial' }}
            >
              x
            </button>
          </div>
          <div className="overflow-y-scroll" style={{ height: '80vh' }}>
            {config.url === 'users' ? (
              <UsersListComponent
                onClick={(e) => {
                  setmodalOpen(false);
                  if (form[config.id] && form[config.id].find((elem) => elem === e._id))
                    return toast.error('Élément déjà ajouté !', {
                      autoClose: 2000,
                      position: 'bottom-center',
                    });
                  setform({
                    ...form,
                    [config.id]: [...(form[config.id] || []), e._id],
                  });
                }}
              />
            ) : (
              <ObjectListComponent
                typeobject={config.url}
                onClick={(e) => {

                  setmodalOpen(false);
                  if (form[config.id] && form[config.id].find((elem) => elem === e._id))
                    return toast.error('Élément déjà ajouté !', {
                      autoClose: 2000,
                      position: 'bottom-center',
                    });
                  setform({
                    ...form,
                    [config.id]: [...(form[ config.id] || []), e._id],
                  });
                }}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  }
  return (
    <div
      key={'objectupdate' + index}
      className={(config.type === 'bigtext' || config.type === 'link' ? 'items-start ' : 'items-center ') + 'text-left flex  '}
    >
      <div className=" w-1/3">
        <label className="block text-gray-700 text-sm font-semibold flex  flex-col pt-4 items-end text-right mr-3">
          {config.tint}
          {button && <br />}
          {button}
        </label>
      </div>

      <div className="w-full border-t-2 border-gray-400 border-dotted pt-4  " style={{ minHeight: 50 }}>
        {input}
      </div>
    </div>
  );
}
