import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetObjectsAll } from "../../../Services/UKObject";
import { GetUserByIds } from "../../../Services/User";
import TableComponent from "../../Common/Table/table.component";

function DisplayUserSubscribeEvent({ idEvent }) {
  const { application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [userList, setUserList] = useState(null);
  const history = useHistory();

  async function getAllUserOfEvent() {
    const result = await GetObjectsAll(`ukEvent_inscription_${idEvent}`, application.apiKey, user.authToken);
    const connected_user = result.filter(elem => !!elem.informations.connected);
    const disconnect_user = result.filter(elem => !elem.informations.connected);
    let ids, users = [];
    if (connected_user.length > 0) {
      ids = connected_user.map(elem => elem.informations.connected);
      users = await GetUserByIds(ids, application.apiKey, user.authToken);
      users = users.map(elem => [elem._id, elem.informations.firstName, elem.informations.lastName, elem.informations.email])
    }
    setUserList({ connected_user: connected_user, disconnect: disconnect_user, users: users });
  }

  useEffect(() => {
    getAllUserOfEvent();
  }, [])

  if (!userList) {
    return null;
  }

  return (
    <div className={'w-full'}>
      <div className="flex justify-evenly text-gray-700 font-semibold mb-3">
        <p
          className={'text-left '}>{userList.connected_user.length} {userList.connected_user.length > 1 ? 'Utilisateurs connectés' : 'Utilisateur connecté'}</p>
        <p className="h-[20px] w-[3px] bg-gray-700"></p>
        <p
          className={'text-left'}>{userList.disconnect.length} {userList.disconnect.length > 1 ? 'Utilisateurs déconnectés' : 'Utilisateur déconnecté'}</p>
      </div>
      <div className={'flex flex-wrap flex-row w-full'}>
        {userList.connected_user.length > 0 && (
          <TableComponent config={[{ id: 1, title: 'Prénom' }, { id: 1, title: 'Nom' }, { id: 1, title: 'Email' }]}
            content={userList.users} onRowClick={(props) => {
              history.push(`/v/user/${props[0]}`)
            }} startAt={1} hasSearchbarCol={false} />
        )}
      </div>
    </div>
  )
}

export default DisplayUserSubscribeEvent;