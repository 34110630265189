import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { HelpText } from 'react-rainbow-components';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import TrashButton from '../../../../Common/Button/TrashButton';

const InputFromEvent = ({ form, setForm, type, link }) => {
  // console.log(link);
  // console.log(form.eventObject.custom);
  var configIndex = form.eventObject.custom.findIndex((e) => e.idLink == link.id);
  if (configIndex >= 0) {
    var config = form.eventObject.custom[configIndex];
    return (
      <div className=" flex  gap-2 items-center">
        <input
          className="px-1"
          value={config.tint}
          className="border "
          onChange={(e) => {
            var arr = [...form.eventObject.custom];
            arr[configIndex].tint = e.target.value;
            setForm({
              ...form,
              eventObject: {
                ...form.eventObject,
                custom: arr,
              },
            });
          }}
        />
        <HelpText text={"Le texte affiché sur la fiche d'un événement"} />
      </div>
    );
  }
  return <p className="text-sm italic text-left">Aucun lien dans les événements trouvé avec {type}</p>;
};
export default function LinkCell({ link, index, form, setForm }) {
  const { application, config } = useContext(ApplicationContext);

  const getName = (type) => {
    // console.log('TYPE ' + type);
    if (type == 'ukEvent') {
      return 'Événement';
    }
    var name = config.customObject.find((e) => e.id == type);
    if (name) {
      return name.name;
    }
    return 'objet';
  };

  return (
    <div>
      <div className="relative bg-white grid grid-cols-3 items-center gap-2 m-auto w-full justify-between border border-gray-300 px-3 rounded py-2 ">
        <div className="flex flex-col items-start h-full gap-2">
          <p>{getName(link.type1)}</p>
          <span className="absolute top-0 left-0 right-0 italic text-gray-200" style={{ fontSize: 10 }}>
            {link.id}
          </span>
          {link.type2 != 'ukEvent' && (
            <div className=" flex  gap-2 items-center">
              <input
                className="px-1"
                value={link.labelType2}
                className="border "
                onChange={(e) => {
                  var arr = [...form.links];
                  arr[index].labelType2 = e.target.value;
                  setForm({
                    ...form,
                    links: arr,
                  });
                }}
              />
              <HelpText text={"Le texte affiché sur la fiche d'un " + getName(link.type2)} />
            </div>
          )}
          {link.type2 == 'ukEvent' && <InputFromEvent form={form} setForm={setForm} type={link.type1} link={link} />}
        </div>
        <div className="text-center">
          <FontAwesomeIcon icon={faArrowsAltH} />
        </div>
        <div className="flex flex-col items-start gap-2 h-full">
          <p>{getName(link.type2)}</p>
          {link.type1 != 'ukEvent' && (
            <div className=" flex  gap-2 items-center">
              <input
                className="px-1"
                value={link.labelType1}
                className="border "
                onChange={(e) => {
                  var arr = [...form.links];
                  arr[index].labelType1 = e.target.value;
                  setForm({
                    ...form,
                    links: arr,
                  });
                }}
              />
              <HelpText text={"Le texte affiché sur la fiche d'un " + getName(link.type1)} />
            </div>
          )}
          {link.type1 == 'ukEvent' && <InputFromEvent form={form} setForm={setForm} type={link.type2} link={link} />}
        </div>
        <TrashButton
          className="absolute top-0 right-0"
          onClick={() => {
            if (window.confirm('Ëtes vous sur de vouloir supprimer le lien ? Cette operation est irreversible')) {
              var arr = [...form.links];
              arr.splice(index, 1);
              setForm({
                ...form,
                links: arr,
              });
            }
          }}
        ></TrashButton>
      </div>
    </div>
  );
}
