import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../Context/UserContext";
import {GetApplicationInfoById} from "../../../Services/Application";
import {useHistory} from "react-router-dom";
import {ApplicationContext, CLIENT_USEKEY_APP} from "../../../Context/ApplicationContext";

const EventkeyHome = ({}) => {
  const {setApplication} = useContext(ApplicationContext);
  const {user, setUser} = useContext(UserContext);
  const [apps, setApps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (!user) return;
    getAppsInfo();
  }, [user])

  const getAppsInfo = async () => {
    const appsObject = user.users;
    const appsInfo = [];

    if (!appsObject || appsObject?.length === 0) return;
    setIsLoading(true);
    for (const app of appsObject) {
      const appInfos = await GetApplicationInfoById(app.appId);
      appsInfo.push(appInfos);
    }
    setApps(appsInfo);
    setIsLoading(false);
  }

  const accessApp = (e) => {
    if (!e.informations.sousdomaine || e.informations.sousdomaine.length === 0) {
      setApplication(e);
      setUser(user.users.find(u => u.appId === e._id));
      return history.push('/v/home');
    }
    window.open(`https://${e.informations.sousdomaine}`, '_blank');
  }

  const createNewApp = () => {
    history.push('/eventkey/newapp');
  }

  return (
    <div className={"overflow-x-hidden w-screen"}>
      <div className="flex items-center justify-between h-[60px] border-b">
        <img className="w-[180px] ml-5" src="/icons/usekey.png" alt="logo"/>
        <p className="text-gray-700 mr-4 cursor-pointer hover:opacity-70" onClick={createNewApp}>Créer une application +</p>
      </div>


      <div className="p-10 bg-gray-50 w-full md:w-[80%] lg:w-[50%] m-auto">
        <p className="text-2xl text-left mb-8">Quelle application voulez-vous administrer ?</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {isLoading && <p className="text-gray-700 text-sm">Chargement ...</p>}
          {apps && apps.map((e, i) => {
            if (!e || e._id === CLIENT_USEKEY_APP) return <></>
            return (
              <div key={i} className="group cursor-pointer bg-white rounded relative overflow-hidden" onClick={() => accessApp(e)}>
                <img className="group-hover:scale-110 transition-all h-full object-cover object-center" src={e.informations?.pictureUrl} alt="APP LOGO"/>
                <div className="absolute w-full bg-white bottom-0 left-0 text-left px-2 py-1">
                  <p className="text-sm font-semibold text-gray-600">{e.informations.name}</p>
                </div>
              </div>
            );
          })}
          {(!apps || (apps.length === 1 && apps[0]._id === CLIENT_USEKEY_APP)) && !isLoading && <p className="text-gray-700 text-sm">Aucune application</p>}
        </div>
      </div>
    </div>
  );
}

export default EventkeyHome;
