import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const PreviewCustomerApp = ({appinfo}) => {
  return (
    <div className="flex-col h-full w-full absolute top-auto">
      <div className="flex items-center rounded m-auto mt-4 bg-white" style={{width: "96%", height: "15%"}}>
        <div className="text-left ml-3">
          <p className="text-md">Titre</p>
          <p className="text-sm">Sous-titre</p>
          <p className="text-sm">Sous sous-titre</p>
        </div>
      </div>
      <div className="flex items-center justify-center rounded mt-2 m-auto bg-white" style={{width: "70%", height: "5%", backgroundColor: appinfo.mainColor}}>
          <p className="text-md" style={{color: appinfo.colorOverMainColor}}>Valider</p>
      </div>
      <div className="absolute bottom-16 right-4 m-auto flex gap-2 transform scale-75">
        <FontAwesomeIcon icon={faCircle} color="black" />
        <FontAwesomeIcon icon={faCircleChevronUp} color="black" />
      </div>
    </div>
  );
}

export default PreviewCustomerApp
