import { useEffect } from 'react';

function VisioGlobe() {
  useEffect(() => {
    console.log(window);
    var essential = new window.VisioWebEssential({
      element: document.getElementById('container'),
      imagePath: '../media',
    });

    essential.setParameters({ parameters: { baseURL: 'https://mapserver.visioglobe.com/', hash: 'dev-k0d49218e698d0c0e18aa46e80d254ac376f84931' } });

    // loading your own map using a web hash. NB: default baseURL is 'https://mapserver.visioglobe.com/' so no need to set it unless your map is hosted somewhere else
    // essential.setParameters({parameters: {hash: 'your_map_hash'}});

    // or you could refer to your own domain if you want to host your own maps
    // essential.setParameters({parameters: {baseURL: 'https://your.domain.com/', hash: 'your_map_hash'}});
    // VisioWeb will then try to download your map's bundle from https://your.domain.com/your_map_hash/

    essential.setParameters({ parameters: { locale: { language: navigator.language } } });

    essential
      .createMapviewer()
      .then(() => {
        // YOUR CODE HERE
      })
      .catch((e) => {
        // eslint-disable-line
        console.log(e);
        console.log('Could not load map. Check your config or get in touch with Visioglobe team.'); // eslint-disable-line
      });
  }, []);
  return (
    <div className="bg-gray-50 min-h-screen text-gray-700">
      <div
        style={{
          width: '100vw',
          height: '100vh',
        }}
        id="container"
      ></div>
    </div>
  );
}

export default VisioGlobe;
