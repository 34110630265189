export const eventkeyFormJson = {
  step1: {
    title: "Création d'une nouvelle application (1/3)",
    minAnswers: 2,

    questions: [
      {
        step: 0,
        question: "Quel type d'application souhaitez-vous créer ?",
        answer: [
          {type: 'button', title: "Une application événementielle", id: 'event'},
          {type: 'button', title: "Une application communautaire", id: 'commu'}
        ]
      },
      {
        id: 'event',
        step: 1,
        question: "Et plus précisement ?",
        answer: [
          {type: 'button', title: "Congrès / Colloques", id: 'congres'},
          {type: 'button', title: "Salon", id: 'salon'},
          {type: 'button', title: "Job dating", id: 'jobdating'},
          {type: 'button', title: "Conventions d'affaires", id: 'affaires'},
          {type: 'button', title: "Autre", id: 'otherEvent'}
        ]
      },
      {
        id: 'commu',
        step: 1,
        question: "Et plus précisement ?",
        answer: [
          {type: 'button', title: "Communauté de client", id: 'client'},
          {type: 'button', title: "Entreprise", id: 'entreprise'},
          {type: 'button', title: "Association", id: 'association'},
          {type: 'button', title: "Club sportif", id: 'sport'},
          {type: 'button', title: "Autre", id: 'otherCommu'}
        ]
      }
    ]
  },

  step2: {
    title: "Création d'une nouvelle application (2/3)",
    minAnswers: 5,
    noFilter: true,

    questions: [
      {
        step: 'name',
        question: "Quel est le nom de votre application ?",
        answer: [
          {type: 'text', id: 'appName'},
        ]
      },
      {
        step: 'desc',
        question: "Comment pourriez vous décrire votre application ?",
        answer: [
          {type: 'text', id: 'description'},
        ]
      },
      {
        step: 'logo',
        question: "Quel est le logo de votre application ?",
        answer: [
          {type: 'image', id: 'appLogo'},
        ]
      },
      {
        step: 'category',
        question: "Dans quelle categorie votre application se trouve t-elle ?",
        answer: [
          {type: 'selector', id: 'appCategory', list: [
              {value: 'event', name: 'Événementiel'},
              {value: 'commu', name: 'Communautaire'},
              {value: 'company', name: 'Entreprise'},
              {value: 'other', name: 'Autre'},
            ]},
        ]
      },
      {
        step: 'color',
        question: "Quelle est la couleur principale ?",
        answer: [
          {type: 'color', id: 'appColor'},
        ]
      },
    ]
  },

  step3: {
    title: "Création d'une nouvelle application (3/3)",
    minAnswers: 0,
    noFilter: true,
    done: true,

    questions: [
      {
        step: 0,
        question: "Voici une première version de votre application. Vous aurez par la suite la possibilité de modifier le design et l'interface de celle-ci dans la backoffice",
        answer: []
      }
    ]
  }
}
