import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { GetUserById } from "../../../../Services/User";
import moment from "moment";
import "moment/locale/fr";
import { UpdateObject } from "../../../../Services/UKObject";
import { UKUser } from "../../../../Class/UKUser";

//2 -> Annulé
//0 wait
// 1 accepté

function RDVCell({ appointment, refresh }) {
  const history = useHistory();
  const { application, config } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [otherUser, setOtherUser] = useState(null);
  const [dates, setDates] = useState(null);
  const [iAsk, setIAsk] = useState(false);

  const reloadDate = () => {
    var start = moment(appointment.informations.dateDebut).format("HH:mm");
    var end = moment(appointment.informations.dateFin).format("HH:mm");
    setDates({ start: start, end: end });
  };

  const reloadUser = async () => {
    const userId = appointment.informations.users.find(
      (elem) => elem !== user.id
    );
    const otherUser = await GetUserById(
      userId,
      application.apiKey,
      user.authToken
    );
    setOtherUser(new UKUser(otherUser, config));
  };

  const reloadStep = async () => {
    setIAsk(appointment.informations.userAsk === user._id);
  };

  useEffect(() => {
    reloadDate();
    reloadUser();
    reloadStep();
    // eslint-disable-next-line
  }, [appointment]);

  const accept = async () => {
    const result = window.confirm("Voulez-vous accepter le rendez-vous ?");
    if (result) {
      await UpdateObject(
        appointment._id,
        appointment.type,
        { step: 1 },
        application.apiKey,
        user.authToken
      );
      refresh();
    }
  };

  const decline = async () => {
    const result = window.confirm("Voulez-vous refuser le rendez-vous ?");
    if (result) {
      await UpdateObject(
        appointment._id,
        appointment.type,
        { step: 2 },
        application.apiKey,
        user.authToken
      );
      refresh();
    }
  };
  return (
    <div className="grid grid-cols-4 my-2">
      <div>
        {dates && <p className="font-normal">{dates.start}</p>}
        {dates && <p className="font-thin text-sm">{dates.end}</p>}
      </div>
      <div
        className="col-span-2 border-b-2 pb-5 cursor-pointer"
        onClick={() => {
          history.push(`/v/user/${otherUser.json._id}`);
        }}
      >
        <div className="flex flex-col">
          {otherUser && (
            <p className="text-left font-semibold text-sm mb-2">
              Rendez-vous avec {otherUser.getFirstName()}
            </p>
          )}
          {otherUser && (
            <div className="flex">
              <img
                alt="user face"
                src={otherUser.getPicture()}
                className="rounded-full"
                style={{ width: 50, height: 50 }}
              />
              <div className="flex flex-col ml-2">
                <p className="font-medium">{otherUser.getFullName()}</p>
                <p>{otherUser.getSubtitle()}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="border-b-2">
        {appointment && appointment.informations.step === 1 && (
          <p className="bg-green-500 w-max px-2 rounded text-white font-bold text-xs py-1">
            CONFIRMÉ
          </p>
        )}
        {appointment && appointment.informations.step === 0 && iAsk === true && (
          <p className="bg-gray-500 w-max px-2 rounded text-white font-bold text-xs py-1">
            EN ATTENTE
          </p>
        )}
        {appointment && appointment.informations.step === 0 && iAsk === false && (
          <div className="flex flex-col">
            <p
              onClick={accept}
              className="cursor-pointer bg-yellow-500 w-max px-2 rounded text-white font-bold text-xs py-1 mb-2"
            >
              ACCEPTER
            </p>
            <p
              onClick={decline}
              className="cursor-pointer bg-red-500 w-max px-2 rounded text-white font-bold text-xs py-1"
            >
              REFUSER
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default RDVCell;
