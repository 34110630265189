const URL = 'https://usekey-objects.herokuapp.com';

export class UKMapConfigService {
  constructor(apiKey: '', authToken: '') {
    this.apiKey = apiKey
    this.authToken = authToken
  }


  async _putMapConfig(object) {
    try {
      const response = await fetch(`${URL}/objects/UKMapConfig`, {
        method: 'PUT',
        headers: {
          ApiKey: this.apiKey,
          AuthToken: this.authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(object),
      });
      if (response.status !== 200) {
        return null;
      }
      return await response.json();
    } catch (e) {
      return null;
    }
  }


  async _postMapConfig(id, object) {
    try {
      const response = await fetch(`${URL}/objects/UKMapConfig/${id || this._id}`, {
        method: 'POST',
        headers: {
          ApiKey: this.apiKey,
          AuthToken: this.authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(object),
      });
      if (response.status !== 200) {
        return null;
      }
      return await response.json();
    } catch (e) {
      return null;
    }
  }


  async _fetchMapConfig() {
    try {
      const response = await fetch(`${URL}/objects/UKMapConfig`, {
        method: 'GET',
        headers: {
          ApiKey: this.apiKey,
          AuthToken: this.authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (response.status !== 200) {
        return null;
      }
      const json = await response.json();
      this._id = json[0]._id;
      return json;
    } catch (e) {
      return null;
    }
  }


  async get(key = null) {
    let mapConfig = await this._fetchMapConfig();
    if (!mapConfig) return null;
    mapConfig = mapConfig[0].informations;

    return key ? mapConfig[key] : mapConfig;
  }


  async update(object, id = this._id) {
    if (!id && !this._id) {
      await this._fetchMapConfig();
    }
    let response = await this._postMapConfig(id, object);
    if (!response) {
      response = await this._putMapConfig(object);
    }
    return response
  }
}