import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContext"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginFormComponent from "./LoginFormComponent";
import RegisterFormComponent from "./RegisterFormComponent";

function LoginComponent() {
	const { application } = useContext(ApplicationContext)
	const [isRegister, setIsRegister] = useState(false)
	const queryParams = new URLSearchParams(window.location.search);
	const defaultBackground = "https://images.unsplash.com/photo-1517457373958-b7bdd4587205?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80";

	useEffect(() => {
		const register = queryParams.get("register");

		if (register) {
			setIsRegister(true)
		}
	}, [window.location.search])

	if (!application) { return <p>Pas d app</p> }
	return (
		<div>
			<section className="min-h-screen flex items-stretch">
				<div className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
					style={{
						backgroundImage: `url(${(((application || {}).informations || {}).login || {}).background ?? defaultBackground})`,

					}}
				>
					<div className="absolute bg-black opacity-60 inset-0 z-0">

					</div>
					<div className="z-0 text-center w-full text-white">
						{application?.informations?.logo && <img alt="" src={application?.informations?.logo} className="w-auto h-20 sm:h-40 inline-flex" />}
						<p className="text-3xl mt-5 font-bold">{application?.informations?.name}</p>
					</div>
				</div>

				{!isRegister &&
					<LoginFormComponent registerAction={() => setIsRegister(true)} />
				}
				{isRegister && <RegisterFormComponent backAction={() => setIsRegister(false)} />}
			</section>
			<ToastContainer></ToastContainer>

		</div>
	);
}

export default LoginComponent;
