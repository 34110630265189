import PictureUpdate from '../../../PictureUpdate';

export default function PreviewApp({ urlPhoto, name, seturlPhoto, data, isDragging, setClickedData }) {
  return (
    <div className="grid grid-cols-2 bg-gray-100 " style={{ maxHeight: '70vh' }}>
      <div className="col-span-2">
        <PictureUpdate
          url={urlPhoto}
          customParentClassNames={'w-full relative m-auto'}
          name={name}
          onUpdate={(newUrl) => {
            seturlPhoto(newUrl);
          }}
          rounded="true"
          classnames="w-full block ml-auto mr-auto bg-transparent "
        />
      </div>
      {data.map((e, index) => {
        if (e.type == 'TITLE') {
          return (
            <p
              onClick={() => {
                if (!isDragging.current) {
                  setClickedData({ ...e, index: index });
                  window.scrollTo(0, 0);
                }
              }}
              className={
                (e.style == 'CLASSIC' ? 'col-span-2' : 'col-span-1') +
                ' cursor-pointer hover:opacity-70 font-medium px-2 text-md text-left py-2 w-full'
              }
            >
              {e.text}
            </p>
          );
        }
        if (e.style == 'CLASSIC') {
          // console.log(e);
          return (
            <div className="col-span-2 ">
              <div className="flex justify-between px-2">
                <p className="text-left  font-medium">{e.text}</p>
                <p className="text-sm">Voir plus</p>
              </div>
              <div className="p-2 flex gap-2">
                <div className="h-20 w-20 border-2 shadow rounded flex-col flex justify-center gap-2">
                  <p className="font-bold">Titre</p>
                  <p className="text-sm">Sous-titre</p>
                </div>
                <div className="h-20 w-20 border-2 shadow rounded flex-col flex justify-center gap-2">
                  <p className="font-bold">Titre</p>
                  <p className="text-sm">Sous-titre</p>
                </div>
                <div className="h-20 w-20 border-2 shadow rounded flex-col flex justify-center gap-2">
                  <p className="font-bold">Titre</p>
                  <p className="text-sm">Sous-titre</p>
                </div>
              </div>
            </div>
          );
        }
        return (
          <div
            onClick={() => {
              if (!isDragging.current) {
                setClickedData({ ...e, index: index });
                window.scrollTo(0, 0);
              }
            }}
            className={
              (e.style == 'FULLSQUARE' ? 'col-span-2' : 'col-span-1') + ' cursor-pointer hover:opacity-70 p-1 relative'
            }
          >
            <img
              src={e.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = 'https://bitsofco.de/content/images/2018/12/broken-1.png';
              }}
            />

            {e.textHidden == 'false' && e.text && e.text.length > 0 && (
              <div
                className="absolute bottom-0 text-white  flex items-end px-2 pb-2 text-xs text-left sm:text-sm md:text-md lg:text-lg "
                style={{
                  left: '0.25rem',
                  right: '0.25rem',
                  top: '50%',
                  bottom: 0,
                  background: 'rgb(0,0,0)',
                  background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
                }}
              >
                {e.text}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
