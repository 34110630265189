import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { CookiesProvider } from "react-cookie";
import MainApplication from "./Components/Applications/MainApplication";
import NotFoundComponent from "./Components/Other/NotFoundComponent";
import { ApplicationProvider } from "./Context/ApplicationContext";
import { UserProvider } from "./Context/UserContext";

import { Quill } from "react-quill";

import ImageResize from "quill-image-resize-module-react";
import { ToastContainer } from "react-toastify";
import IframeComponent from "./Components/Iframe/i.frame.component";
import { MapView } from "./Components/Map3D/map.view";
import EventkeyView from "./Components/Visitor/EventKey/eventkey.view";
import LockWrapper from "./Components/Visitor/EventKey/lock.wrapper.component";
import QuestionsModule from "./Components/Visitor/Events/Questions/questions.module";
import QuestionPublicView from "./Components/Visitor/QuestionLive/question.public.view";
import { QuestionResultView } from "./Components/Visitor/QuestionLive/question.result.view";
import QuestionsView from "./Components/Visitor/QuestionLive/questions.view";
import { Map2dView } from "./Components/map2d/map2d.view";

Quill.register("modules/imageResize", ImageResize);
function App() {
    return (
        <CookiesProvider>
            <ApplicationProvider>
                <UserProvider>
                    <div className="App">
                        <Router forceRefresh={false}>
                            <Switch>
                                <Route exact path="/map2d" component={Map2dView} />
                                <Route exact path="/map3d" component={MapView} />
                                <Route exact path="/404" component={NotFoundComponent} />
                                <Route path={`/q`} children={<QuestionPublicView />} />
                                <Route
                                    path={`/question/:idevent`}
                                    children={
                                        <LockWrapper id="questions">
                                            <QuestionsModule />
                                        </LockWrapper>
                                    }
                                />
                                <Route path={`/iframe/`} children={<IframeComponent />} />
                                <Route
                                    exact
                                    path={`/questionLive/:idevent`}
                                    children={
                                        <LockWrapper id="livequestion">
                                            <QuestionsView />
                                        </LockWrapper>
                                    }
                                />
                                <Route exact path={`/questionResult/:idquestion`} children={<QuestionResultView />} />
                                <Route path={`/eventkey`}>
                                    <EventkeyView />
                                </Route>
                                <Route path="/" children={<MainApplication />} />
                            </Switch>
                        </Router>
                        <ToastContainer></ToastContainer>
                    </div>
                </UserProvider>
            </ApplicationProvider>
        </CookiesProvider>
    );
}

export default App;
