import {useContext} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import NotFoundComponent from '../../Components/Other/NotFoundComponent';
import {UserContext} from '../../Context/UserContext';
import HeaderComponent from '../Common/HeaderComponent';
import AppDesign from './Account/Admin/AppDesign/AppDesign';
import NewPageDesginComponent from './Account/Admin/Design/NewPageDesginComponent';
import LinkList from './Account/Admin/Links/LinksList';
import ProfilComponent from './Account/ProfilComponent';
import ObjectDetailView from './Objects/Form/objects.details.view';
import NotificationView from './Account/Admin/Notification/notification.view';
import AppInfoView from './AppInfo/appinfo.view';
import DocsView from './Docs/docs.view';
import LocalizationView from './Localization/localization.view';
import ObjectsviewRouter from './Objects/objectsview.router';
import SideBarView from './Sidebar/sidebar.view';
import UsersviewRouter from './Users/usersview.router';
import VotesView from './Votes/votes.view';
import EventsTableView from "./Events/events.table.view";
import MapEditorView from "./MapEditor/mapeditor.view";
import TicketsComponent from "./Account/Admin/Tickets/TicketsComponent";
import GroupManagerComponent from "./Account/Admin/GroupManager/GroupManagerComponent";
import ScanViewComponent from "./Account/Admin/Scan/scan.view";
import {StatViewAdmin} from "./Account/Admin/Stat/stat.view.admin";
import HomeView from "./Home/home.view";
import QuestionSelectionView from "./QuestionLive/question.selection.view";
import LockWrapper from "./EventKey/lock.wrapper.component";

function VisitorComponent() {
  let {path} = useRouteMatch();
  const {isAdmin} = useContext(UserContext);

  return (
    <div className="bg-gray-100 min-hscreen-withoutheader min-w-max">
      <HeaderComponent/>

      <div className="flex relative min-hscreen-withoutheader">
        <SideBarView/>
        <div
          className="w-full relative contentvisitor"
          style={{
            zIndex: 0,
          }}
        >
          <Switch>
            <Route exact path={path}>
              <div>__</div>
            </Route>
            <Route exact path={`${path}/menu`}>
              {isAdmin() && <NewPageDesginComponent/>}
            </Route>
            <Route exact path={`${path}/links`}>
              {isAdmin() && <LinkList/>}
            </Route>
            <Route exact path={`${path}/account`}>
              <ProfilComponent/>
            </Route>
            <Route exact path={`${path}/home`}>
              <HomeView/>
            </Route>
            <Route exact path={`${path}/events`}>
              <EventsTableView linksOnly={!isAdmin()}/>
            </Route>
            <Route exact path={`${path}/event/:idobject`}>
              <ObjectDetailView/>
            </Route>
            <Route exact path={`${path}/event/:idobject/:typeobject`}>
              <ObjectDetailView/>
            </Route>
            <Route exact path={`${path}/objects/:typeobject`}>
              <ObjectsviewRouter/>
            </Route>
            <Route exact path={`${path}/object/:idobject`}>
              <ObjectDetailView/>
            </Route>
            <Route exact path={`${path}/object/:idobject/:typeobject`}>
              <ObjectDetailView/>
            </Route>
            <Route exact path={`${path}/users/:iduser`}>
              <UsersviewRouter/>
            </Route>
            <Route exact path={`${path}/user/:iduser`}>
              <ObjectDetailView/>
            </Route>
            <Route exact path={`${path}/user/:iduser/:typeuser`}>
              <ObjectDetailView/>
            </Route>
            <Route exact path={`${path}/docs/:iddoc`}>
              {isAdmin() && <DocsView/>}
            </Route>
            <Route exact path={`${path}/eventquestions`}>
              {isAdmin() && <LockWrapper id="livequestion">
                <QuestionSelectionView/>
              </LockWrapper>}
            </Route>
            <Route exact path={`${path}/designv2`}>
              {isAdmin() && <AppDesign/>}
            </Route>
            <Route exact path={`${path}/appinfo`}>
              {isAdmin() && <AppInfoView/>}
            </Route>
            <Route exact path={`${path}/notification`}>
              {isAdmin() && <NotificationView/>}
            </Route>
            <Route exact path={`${path}/localization`}>
              {isAdmin() && <LockWrapper id="traduction">
                <LocalizationView/>
              </LockWrapper>}
            </Route>
            <Route exact path={`${path}/vote`}>
              {isAdmin() && <LockWrapper id="vote">
                <VotesView/>
              </LockWrapper>}
            </Route>
            <Route exact path={`${path}/mapeditor`}>
              {isAdmin() && <LockWrapper id="plan">
                <MapEditorView/>
              </LockWrapper>}
            </Route>
            <Route exact path={`${path}/tickets`}>
              {isAdmin() && <LockWrapper id="billeterie">
                <TicketsComponent/>
              </LockWrapper>
              }
            </Route>
            <Route exact path={`${path}/scan`}>
              {isAdmin() && <ScanViewComponent/>}
            </Route>
            <Route exact path={`${path}/stat`}>
              {isAdmin() && <StatViewAdmin/>}
            </Route>
            <Route exact path={`${path}/groups`}>
              {isAdmin() && <GroupManagerComponent/>}
            </Route>
            {isAdmin() && (
              <Route>
                <NewPageDesginComponent/>
              </Route>
            )}
            <Route component={NotFoundComponent}></Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default VisitorComponent;
