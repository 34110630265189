import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { ApplicationContext } from "../../Context/ApplicationContext";

const TableSearchBar = ({ info, setInfo, config }) => {
  const {langKey} = useContext(ApplicationContext);
  const [searchFilter, setSearch] = useState('');
  const [timeout, setTimeoutValue] = useState(null);

  const getResults = async (value) => {
    if (value === '') clearFilter();
    else {
      let newFilters = [];
      config.map((infos) => {
        newFilters = [
          ...newFilters,
          {
            name: infos.id,
            type: 'reg',
            value: value,
          },
          {
            name: `${infos.id}${langKey}`,
            type: 'reg',
            value: value,
          }
        ];
      });
      setInfo({
        ...info,
        filters: newFilters,
        page: 0,
      });
    }
  };

  const clearFilter = () => {
    setSearch(() => '');
    setInfo({
      ...info,
      filters: [],
      page: 0,
    });
  };

  return (
    <div className="flex items-center justify-center h-max py-2 rounded  bg-white border gap-2 px-2">
      <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
      <input
        className=" rounded rounded-l  "
        placeholder="Rechercher"
        type="text"
        value={searchFilter}
        onChange={(e) => {
          setSearch(e.target.value);
          if (timeout) clearTimeout(timeout);
          setTimeoutValue(
            setTimeout(() => {
              getResults(e.target.value);
            }, 500)
          );
        }}
      ></input>

      <FontAwesomeIcon onClick={() => clearFilter()} className="text-red-400" icon={faClose} />
    </div>
  );
};

export default TableSearchBar;
