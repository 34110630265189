export default function getStandDetailStyle(config, currId, infoEvent, langKey, index) {
  if (!config || !config.custom) {
    return;
  }

  const newConf = config.custom.filter(
    elem => !(elem.isTitle || elem.type === 'picture' || elem.type === 'date' || elem.type === 'mapLink'),
  );
  const length = newConf.length;

  function isOneOfLinkType(elem, act) {
    return (
      elem.type === 'link' ||
      elem.type === 'subEvent' ||
      elem.type === 'mapLink' ||
      elem.type === 'banniere' ||
      elem.type === 'files' ||
      !elem.type ||
      elem === act
    );
  }

  function findLastWithInfo(index, increm) {
    for (let tmp = index + increm; tmp > -1 && tmp < length; tmp += increm) {
      if (newConf[tmp].hidden) {
        continue;
      }
      const value = newConf[tmp].id;
      const inside = infoEvent.informations;
      const isAns =
        inside[`${value}${langKey}`] ?? inside[value];
      if (isAns) {
        return tmp;
      }
    }
    return index;
  }

  function isInformations(id) {
    return (
      infoEvent.informations[`${id}${langKey}`] ??
      infoEvent.informations[id]
    );
  }

  function getStyle(index, id, infoEv) {
    const withLang = `${id}${langKey}`;
    const exist = infoEv.informations[withLang] ?? infoEv.informations[id];
    if (!exist) {
      return {};
    }
    if (typeof exist === 'object' && exist.length === 0) {
      return {};
    }
    const previous = newConf[findLastWithInfo(index, -1)] ?? {};
    const act = newConf[index];
    const next = newConf[findLastWithInfo(index, 1)] ?? {};
    let marginTop = isOneOfLinkType(previous, act) ? 10 : 0;
    marginTop = isInformations(act.id) ? marginTop : 0;
    if (isOneOfLinkType(act, null)) {
      return {
        borderRadius: 10,
        marginTop: isInformations(act.id) ? 10 : 0,
        backgroundColor: 'white',
        width: '95%',
      };
      //return `rounded-lg mt-[${isInformations(act.id) ? 10 : 0}px] bg-white w-full`;
    }
    const roundedTop = isOneOfLinkType(previous, act) ? 10 : 0;
    const roundedBottom = isOneOfLinkType(next, act) ? 10 : 0;

    return {
      borderTopLeftRadius: roundedTop,
      borderTopRightRadius: roundedTop,
      marginTop: marginTop,
      borderBottomLeftRadius: roundedBottom,
      borderBottomRightRadius: roundedBottom,
      paddingBottom: isInformations(act.id) ? 10 : 0,
      backgroundColor: 'white',
      width: '95%',
    }
    // return `rounded-t-${roundedTop ? 'lg' : 'none'} mt-[${marginTop}px] rounded-b-${roundedBottom ? 'lg' : 'none'} pb-[${isInformations(act.id) ? 10 : 0}px] bg-white w-full`
  };

  const style = getStyle(index, currId, infoEvent);

  return style
}
