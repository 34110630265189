import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import { UserContext } from '../../../../Context/UserContext';
import { GetObjectsAll } from '../../../../Services/UKObject';
import AddButton from '../../Button/AddButton';

export default function ImportVerifyLink({ linkData, selectType, data }) {
  const { config, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [state, setState] = useState([]);

  const verifyLink = useCallback(
    async (link) => {
      const linkObj = config.links.find((e) => e.id == link.linkId);
      var type = linkObj.type1 == selectType ? linkObj.type2 : linkObj.type1;
      // setState('Récupération des objets du type ' + type);
      var files = await GetObjectsAll(type, application.apiKey, (user || {}).authToken);
      //setState('Vérification des objets du type ' + type);

      var totalFound = 0;
      var totalNotFound = [];
      for (var i = 0; i < data.length; i++) {
        var arr = data[i][link.index].split(link.separator).map((e) => e.trim());
        for (var j = 0; j < arr.length; j++) {
          const fileFound = files.find((e) => {
            return e.informations[link.linkCustomId] == arr[j];
          });
          if (fileFound) {
            totalFound += 1;
          } else {
            totalNotFound.push(link.link);
          }
        }
      }
      var s = state;
      var i = s.findIndex((e) => e.type == type);
      if (i >= 0) {
        s[i].state = 1;
        s[i].totalFound = totalFound;
        s[i].totalNotFound = totalNotFound.length;
        setState(s);
      }

      // setState('Resultat pour le type ' + type + ' Lien trouvé : ' + totalFound + ' Lien non trouvé ' + totalNotFound);
    },
    [state]
  );
  const startVerify = useCallback(async () => {
    var links = [];
    for (const [key, value] of Object.entries(linkData)) {
      if (value.type == 'link') {
        links.push(value);
      }
    }

    setState(
      links.map((link) => {
        const linkObj = config.links.find((e) => e.id == link.linkId);
        var type = linkObj.type1 == selectType ? linkObj.type2 : linkObj.type1;
        return {
          type: type,
          state: 0,
        };
      })
    );
    for (var i = 0; i < links.length; i++) {
      var link = links[i];
      await verifyLink(link);
    }
  }, [state]);

  useEffect(() => {
    if (state.length == 0) {
      var links = [];
      for (const [key, value] of Object.entries(linkData)) {
        if (value.type == 'link') {
          links.push(value);
        }
      }
      setState(
        links.map((link) => {
          const linkObj = config.links.find((e) => e.id == link.linkId);
          var type = linkObj.type1 == selectType ? linkObj.type2 : linkObj.type1;
          return {
            type: type,
            state: -1,
          };
        })
      );
    }
  }, [linkData]);
  return (
    <div>
      <AddButton title="Verifier les liens" icon={faPlay} onClick={startVerify} />
      <div>
        {state.map((e) => {
          return (
            <div className="flex gap-1">
              <p className="capitalize font-bold">
                {e.type} : {e.state == 0 ? 'Récuperation ...' : e.state == 1 ? '' : ''}
              </p>
              {e.state == 1 && (
                <p>
                  <span className="text-green-400">{e.totalFound} lien(s) confirmé(s)</span> /{' '}
                  <span className="text-red-400">{e.totalNotFound} lien(s) introuvable(s)</span>
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
