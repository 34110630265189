import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import { FindLinkObject } from '../../../Services/UKLink';
import { CreateObject, DeleteObject } from '../../../Services/UKObject';
import CustomObjectCell from '../../Common/CustomObjectCell';
import LinkModal from '../../Common/LinkModal';
import SelectObjectLinkModel from '../../Common/SelectObjectLinkModal';
import CustomUsersCellVertical from '../User_old/CustomUsersCellVertical';

const SwitchTypeCell = ({ e, reloadCurrentLinks }) => {
  const { application, config } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  return (
    <div>
      {e.informations._obj.type && e.informations._obj.type != 'ukEvent' && (
        <CustomObjectCell
          onClick={() => {
            history.push(`/v/object/${e.informations._obj._id}`, {
              back: true,
            });
          }}
          className="w-full block text-gray-700 text-sm font-semibold text-left cursor-pointer"
          object={e.informations._obj}
          onDelete={async () => {
            await DeleteObject(e._id, 'ukLink', application.apiKey, user.authToken);
            reloadCurrentLinks();
          }}
        />
      )}
      {e.informations._obj.type && e.informations._obj.type == 'ukEvent' && (
        <div
          className="flex gap-4 cursor-pointer w-full"
          onClick={async () => {
            /*const event = await GetEventById(e.informations._obj._id, application.apiKey);
                      console.log(event);*/
            history.push('/v/events/' + e.informations._obj._id);
          }}
        >
          <div className="text-right">
            <p className="text-lg font-medium" style={{ color: config.mainColor }}>{`${moment(
              e.informations._obj.informations.dateDebut || ''
            ).format('HH:mm')}`}</p>
            <p className="text-sm">{`${moment(e.informations._obj.informations.dateFin || '').format('HH:mm')}`}</p>
          </div>
          <div className="text-left border-b border-gray-400 w-full">
            <p className="font-medium">{e.informations._obj.informations.title || ''}</p>
            <p className="text-sm">{e.informations._obj.informations.subtitle || ''}</p>
          </div>
        </div>
      )}
      {!e.informations._obj.type && (
        <CustomUsersCellVertical
          onClick={() => {
            history.push(`/v/user/${e}`);
          }}
          id={e.informations._obj._id}
        />
      )}
    </div>
  );
};
function ObjectLinksUpdate({ objectClass, hasLink, editMode }) {
  const history = useHistory();
  const { application, config } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [links, setLinks] = useState([]);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [link, setLink] = useState(null);
  const [modalSelectObject, setModalSelectObject] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const reloadCurrentLinks = async () => {
    if (!user || links.length === 0 || !objectClass) {
      return;
    }
    var json = await FindLinkObject(
      objectClass.json._id,
      objectClass.json.type,
      application.apiKey,
      user.authToken,
      '1'
    );
    if (!json) {
      return;
    }
    json = json.filter((elem) => elem.informations._obj != null);
    var newLinks = [...links];
    for (var i = 0; i < newLinks.length; i++) {
      var elem = newLinks[i];


      var type = elem.type1 === objectClass.json.type ? elem.type2 : elem.type1;
      var id = elem.id;
      // eslint-disable-next-line
      var allType = json.filter((elem) => {
        return (elem.informations._obj.type === type || type == 'user') && elem.informations.idconfig == id;
      });
      newLinks[i].objs = allType;
    }
    setCurrentLinks(newLinks);
  };

  const reloadConfigLinks = () => {

    setCurrentLinks([]);
    if (!objectClass) {
      return;
    }
    var group = ((user || {}).informations || {}).groups || [];
    var links = (config || {}).links || [];
    links = links.filter((elem) => {
      var isType = elem.type1 === objectClass.json.type || elem.type2 === objectClass.json.type;
      var eGroup = (elem.group || '').split(',').filter((e) => e !== '');
      var isInGroup = false;
      if (eGroup.length > 0) {
        for (var i = 0; i < group.length; i++) {
          for (var j = 0; j < eGroup.length; j++) {
            if (group[i] === eGroup[j]) {
              isInGroup = true;
            }
          }
        }
      } else {
        isInGroup = true;
      }
      //TODO je sais pas pk
      isInGroup = true;
      return isInGroup && isType;
    });
    setLinks(links);
  };

  useEffect(() => {
    reloadCurrentLinks();
    // eslint-disable-next-line
  }, [user, links, objectClass]);
  useEffect(() => {
    reloadConfigLinks();
    // eslint-disable-next-line
  }, [user, config, objectClass]);

  return (
    <div className="col-span-6 py-5 px-4">
      <div className="grid grid-cols-1 gap-4 gap-y-6">
        {links.map((e, index) => {
          var title = '';
          var id = '';
          if (objectClass && objectClass.json) {
            title = e.type1 === objectClass.json.type ? e.labelType1 : e.labelType2;
            id = e.type1 === objectClass.json.type ? e.type2 : e.type1;
          }

          if (e.objs && e.objs.length == 0 && id == 'ukEvent') {
            return null;
          }
          return (
            <div key={'objectupdate' + index} className={'text-left flex flex-col items-start'}>
              <div className="flex flex gap-1 items-center mb-1 mt-4">
                <label className="block text-left text-gray-700 text-md font-semibold   ">{title}</label>
                {id != 'ukEvent' && (
                  <div
                    onClick={() => {
                      setModalSelectObject(id);
                      setSelectedLink(e);
                    }}
                    className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
                  >
                    Ajouter <FontAwesomeIcon icon={faPlus} />
                  </div>
                )}
              </div>
              <div className={id != 'ukEvent' ? 'grid grid-cols-3 gap-3' : ''}>
                {e.objs &&
                  e.objs
                    .sort((a, b) => {
                      if (a.informations && a.informations._obj && a.informations._obj.type == 'ukEvent') {
                        try {
                          var dateA = moment(a.informations._obj.informations.dateDebut);
                          var dateB = moment(b.informations._obj.informations.dateDebut);
                          return dateA - dateB;
                        } catch {
                          return 0;
                        }
                      }
                      return 0;
                    })
                    .map((o) => {
                      return <SwitchTypeCell e={o} reloadCurrentLinks={reloadCurrentLinks} />;
                    })}
              </div>
            </div>
          );
        })}{' '}
      </div>

      <LinkModal
        modalIsOpen={link != null}
        setmodalIsOpen={(e) => setLink(null)}
        link={link}
        title={"Invitez l'utilisateur à s'inscire sur ce lien"}
      />

      <SelectObjectLinkModel
        type={modalSelectObject}
        onClose={async (e, c) => {
          setModalSelectObject(null);

          var type1 = selectedLink.type1;
          var type2 = selectedLink.type2;
          var id1 = e.type == selectedLink.type1 ? e._id : objectClass.json._id;
          var id2 = e.type == selectedLink.type1 ? objectClass.json._id : e._id;

          await CreateObject(
            'ukLink',
            {
              idconfig: selectedLink.id,
              type2: type2,
              id1: id1,
              type1: type1,
              state: '1',
              id2: id2,
            },
            application.apiKey,
            user.authToken
          );

          reloadCurrentLinks();
        }}
      />
    </div>
  );
}

export default ObjectLinksUpdate;

/*
  var emptyState = null;
  if (links.length > 0 && objectClass) {
    var elem = links[0];
    var title = elem.type1 === objectClass.json.type ? elem.labelType1 : elem.labelType2;

    console.log('TILE ' + title);
    console.log(links);
    emptyState = (
      <div className="grid grid-cols-1 gap-4 gap-y-6 px-4 pb-5">
        <div key={'link0_0_emptu'} className="text-left flex items-start">
          <div className="w-2/6">
            <label className="block text-gray-700 text-sm font-semibold text-right  mr-3">
              <p>{title}-4</p>

              <Button
                label={
                  <span className="flex items-center justify-center text-sm font-normal gap-2 py-1">
                    Ajouter <AiOutlinePlus className="inline" />
                  </span>
                }
                className=""
                variant="neutral"
                onClick={() => {
                  try {
                    console.log(links);
                    //console.log('ICICIIC');
                    //var idconfig = links[0].id;
                    //var id = objectClass.json._id;
                    //setLink(window.location.hostname + '?idconfig=' + idconfig + '&id=' + id);
                  } catch (e) {}
                }}
              />
            </label>
          </div>
        </div>
        <LinkModal
          modalIsOpen={link != null}
          setmodalIsOpen={(e) => setLink(null)}
          link={link}
          title={"Invitez l'utilisateur à s'inscire sur ce lien"}
        />
      </div>
    );
  }
  if (currentLinks.length === 0 && links.length > 0 && editMode) {
    return emptyState;
  }
  return (
    <div className="grid grid-cols-1 gap-4 gap-y-6 px-4 pb-5">
      {objectClass &&
        currentLinks.map((elem, index) => {
          var title = elem.type1 === objectClass.json.type ? elem.labelType1 : elem.labelType2;
          console.log('TITLE 2 ' + title);
          var id = elem.type1 === objectClass.json.type ? elem.type2 : elem.type1;
          var result = elem.objs.map((e, index2) => {
            return (
              <div key={'link' + index + '-' + index2} className="text-left flex items-start">
                <div className="w-2/6">
                  {index2 === 0 && (
                    <label className="block text-gray-700 text-sm font-semibold text-right  mr-3">
                      {index2 === 0 ? title : ''}-1
                      {e.informations._obj.type && editMode && (
                        <Button
                          label={
                            <span className="flex items-center justify-center text-sm font-normal gap-2 py-1">
                              Ajouter <AiOutlinePlus className="inline" />
                            </span>
                          }
                          className=""
                          variant="neutral"
                          onClick={() => {
                            console.log('ELEMMMM');
                            console.log(elem);
                            console.log(id);
                            setModalSelectObject(id);
                            setSelectedLink(elem);

                            
                          }}
                        />
                      )}
                    </label>
                  )}
                </div>
                {e.informations._obj.type && e.informations._obj.type != 'ukEvent' && (
                  <CustomObjectCell
                    onClick={() => {
                      history.push(`/v/object/${e.informations._obj._id}`, {
                        back: true,
                      });
                    }}
                    className="w-full block text-gray-700 text-sm font-semibold text-left cursor-pointer"
                    object={e.informations._obj}
                    onDelete={async () => {
                      console.log('DELETE');
                      console.log(e);
                      await DeleteObject(e._id, 'ukLink', application.apiKey, user.authToken);
                      reloadCurrentLinks();
                    }}
                  />
                )}
                {e.informations._obj.type && e.informations._obj.type == 'ukEvent' && (
                  <div
                    className="flex gap-4 cursor-pointer w-full"
                    onClick={async () => {
                     
                      history.push('/v/events/' + e.informations._obj._id);
                    }}
                  >
                    <div className="text-right">
                      <p className="text-lg font-medium" style={{ color: config.mainColor }}>{`${moment(
                        e.informations._obj.informations.dateDebut || ''
                      ).format('HH:mm')}`}</p>
                      <p className="text-sm">{`${moment(e.informations._obj.informations.dateFin || '').format(
                        'HH:mm'
                      )}`}</p>
                    </div>
                    <div className="text-left border-b border-gray-400 w-full">
                      <p className="font-medium">{e.informations._obj.informations.title || ''}</p>
                      <p className="text-sm">{e.informations._obj.informations.subtitle || ''}</p>
                    </div>
                  </div>
                )}
                {!e.informations._obj.type && (
                  <CustomUsersCellVertical
                    onClick={() => {
                      history.push(`/v/user/${elem}`);
                    }}
                    id={e.informations._obj._id}
                  />
                )}
              </div>
            );
          });

          if (result.length > 0) {
            var multiple = elem.type2 === objectClass.json.type ? elem.type2Multiple : elem.type1Multiple;
            if (multiple === false) {
              return result;
            }
          }
          if (result.length === 0) {
            return emptyState;
          }

          if (id !== 'user' && hasLink) {
            result.push(
              <div key={'link' + index} className="text-left flex items-center">
                <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
                  {elem.objs.length === 0 ? title : ''}-3
                </label>
                <div className="w-full block text-gray-700 text-sm font-semibold text-left cursor-pointer">
                  <button
                    className=" bg-gray-800 hover:bg-grey-900 text-white text-sm py-1 px-2 font-semibold rounded focus:outline-none focus:shadow-outline"
                    onClick={() => {
                      var type = elem.type1 === objectClass.json.type ? elem.type2 : elem.type1;
                      history.push(`/v/object/new/${type}?type=${objectClass.json.type}&id=${objectClass.json._id}`, {
                        back: true,
                      });
                    }}
                    style={{
                      backgroundColor: (config || {}).colorOverMainColor ?? '#FFFFFF',
                      borderColor: (config || {}).mainColor ?? '#000000',
                      borderWidth: 1,
                      color: (config || {}).mainColor ?? '#000000',
                    }}
                  >
                    Créer
                  </button>
                </div>
              </div>
            );
          }
          return result;
        })}
    </div>
  );*/
