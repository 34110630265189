import {GetUserById, GetUserByIds, GetUsersAllV2} from "../../../../Services/User";
import {UKUser} from "../../../../Class/UKUser";
import {GetObjectByIds} from "../../../../Services/UKObject";
import {UKObjectClass} from "../../../../Class/UKObjectClass";

export class UserLinkService {
  link;
  apiKey;
  authToken;
  inscription;
  langKey;
  config;

  constructor(link, apiKey, authToken, inscription, langKey, config) {
    this.link = link;
    this.apiKey = apiKey;
    this.authToken = authToken;
    this.inscription = inscription;
    this.langKey = langKey;
    this.config = config
  }

  async search(query) {
    let data = await GetUsersAllV2(0, this.apiKey, this.authToken, this.getFilters(query), this.link.url, 5);
    data = data.map(e => {
      return new UKUser(e, this.config);
    })
    return data;
  }

  async getByIds(ids) {
    let data = await GetUserByIds(ids, this.apiKey, this.authToken);
    data = data.data.map(e => {
      return new UKObjectClass(e, this.config);
    })
    return data;
  }

  getFilters(query) {
    let newFilters = [];
    this.inscription.customs.find(e => e.id === this.link.url).custom.map((infos) => {
      newFilters = [
        ...newFilters,
        {name: "email", type: 'reg', value: query},
        {name: "lastName", type: 'reg', value: query},
        {name: "firstName", type: 'reg', value: query},
        {name: infos.id, type: 'reg', value: query},
      ];
    })
    return newFilters;
  }
}