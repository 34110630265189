import IphonePreview from "../Account/Admin/AppDesign/Iphone/IphonePreview";
import AppInfoSettings from "./appinfo.settings";
import { useContext, useEffect, useState } from "react";
import PreviewAppsmenu from "./Preview/preview.appsmenu";
import PreviewCustomerApp from "./Preview/preview.customerapp";
import { UpdateApplication, UpdateJsonConfig } from "../../../Services/Application";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import PreviewAppTabbar from "./Preview/preview.apptabbar";

const AppInfoView = ({}) => {
  const {application, config, reloadConfig, reloadApplication} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [appinfo, setAppinfo] = useState({});
  const [currentView, setCurrentView] = useState("menu");

  const getAppInfos = async () => {
    if (!application || !config) return;
    const newAppInfo = {
      name: application.informations.name,
      description: application.informations.description,
      mainColor: config.mainColor,
      colorOverMainColor: config.colorOverMainColor,
      navbarColor: config.navbarColor,
      colorOverNavbar: config.colorOverNavbar,
      logo: application.informations.logo,
      icon: application.informations.icon,
      header: application.informations.header,
    }
    setAppinfo(newAppInfo);
  }

  const reloadApp = async () => {
    await reloadApplication(window.location.hostname, true);
  }

  useEffect(() => {
    getAppInfos();
  }, [application, config]);

  useEffect(() => {
    reloadApp();
  }, [])

  const updateAppInfos = async (key, value) => {
    let newInfos = {...appinfo}
    newInfos[key] = value;
    setAppinfo(newInfos);
    if (key === "name" || key === "description" || key === "icon" || key === "logo" || key === "header") {
      await UpdateApplication(application.apiKey, user.authToken, application._id, {[key]: value});
    }
    if (key === "mainColor" || key === "colorOverMainColor" || key === "mainColor2" || key === "navbarColor" || key === "colorOverNavbar") {
      let newConfig = {...config};
      newConfig[key] = value;
      await UpdateJsonConfig(application.apiKey, newConfig);
      await reloadConfig();
    }
  }

  return (
    <div>
      <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Informations de l'application</p>
      <div className="flex relative w-11/12 m-auto">
        <div className="w-max gap-3 py-5 ">
          <IphonePreview>
            <div className="transition-all delay-150" style={{
              opacity: currentView === "app" ? "100%" : "0%",
            }}>
              <PreviewAppTabbar appinfo={appinfo} />
            </div>
            <div className="flex bg-gray-200 w-full h-full absolute">
              <div className="mt-14 w-full">
                <div className="transition-all delay-150" style={{
                  opacity: currentView === "app" ? "100%" : "0%",
                }}>
                  <PreviewCustomerApp appinfo={appinfo} />
                </div>
                <div className="transition-all delay-150" style={{
                  opacity: currentView === "menu" ? "100%" : "0%",
                }}>
                  <PreviewAppsmenu appinfo={appinfo} setCurrentView={setCurrentView} />
                </div>
              </div>
            </div>
          </IphonePreview>
        </div>
        <AppInfoSettings appinfo={appinfo} updateAppInfos={updateAppInfos} setCurrentView={setCurrentView} />
      </div>
    </div>
  );
}

export default AppInfoView
