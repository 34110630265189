import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { UKUser } from "../Class/UKUser";
import { FindLinkUser, GetAllLink } from "../Services/UKLink";
import { AdminLoginUser, LoginUser, LoginUserByToken } from "../Services/User";
import { ApplicationContext } from "./ApplicationContext";

export const UserContext = createContext(null);

export const UserProvider = (props) => {
    const { application, config } = useContext(ApplicationContext);

    const [user, setUser] = useState(null);
    const [userClass, setUserClass] = useState(null);
    const [cookies, setCookie] = useCookies(["user_authtoken_" + ((application || {}).name || "")]);
    const [allLinks, setLinks] = useState([]);
    const [allApplicationLinks, setAllApplicationLinks] = useState([]);

    const reloadLinks = async () => {
        var json = await FindLinkUser(user._id, application.apiKey, user.authToken, "1");
        var allLink = await GetAllLink(application.apiKey, user.authToken);
        setAllApplicationLinks([...allLink]);
        setLinks(json);
    };

    useEffect(() => {
        if (!user || !config) return;
        setUserClass(new UKUser(user, config));
    }, [user, config]);

    useEffect(() => {
        if (user != null && user.authToken && application) {
            setCookie("user_authtoken_" + application.name, user.authToken, { path: "/" });
            setCookie("app_apikey", application.apiKey, { path: "/" });
            reloadLinks();
        }
        // eslint-disable-next-line
    }, [user, application]);

    useEffect(() => {
        reloadByToken();
        // eslint-disable-next-line
    }, [application]);

    const updateUser = async (user) => {
        setUser(user);
    };

    const loginUser = async (email, password) => {
        const user = await LoginUser(email, password, application.apiKey);
        setUser(user);
        return user;
    };

    const adminLoginUser = async (email, password) => {
        const user = await AdminLoginUser(email, password, application?.apiKey);
        if (!user) return null;

        let paramsValue = window.location.hostname;
        if (paramsValue === "localhost" || paramsValue === "192.168.0.134") {
            paramsValue = "mlct.usekey.fr";
        }

        // console.log(paramsValue);
        if (paramsValue.includes("client")) {
            // console.log("eventkey");

            setUser(user);
            return { eventkey: true, apps: user };
        } else if (!paramsValue.includes("client")) {
            // console.log("normal");
            const appProfile = user.users.find((u) => u.appId === application._id);
            if (appProfile) {
                setUser(appProfile);
                return appProfile;
            } else {
                // console.log("pas de compte");
                return { eventkey: false };
            }
        }
        return null;

        // return appProfile || {eventkey: true, apps: user};
    };

    const hasState = (state) => {
        if (state && user && state === user.state) {
            return true;
        }
        return false;
    };

    const isSuperAdmin = () => {
        if (user && user.superAdmin === true) {
            return true;
        }
        return false;
    };

    const isAdmin = () => {
        if (isSuperAdmin() || hasState("3")) {
            return true;
        }
        return false;
    };

    const reloadByToken = async () => {
        if (user === null && application != null) {
            const authtoken = cookies["user_authtoken_" + application.name];
            if (authtoken != null) {
                const user = await LoginUserByToken(authtoken, application.apiKey);
                setUser(user);
            }
        }
    };

    const findExactLink = (id1, type1, id2, type2) => {
        return allApplicationLinks.filter((e) => {
            return (
                (e.informations.id1 === id1 && e.informations.id2 === id2 && e.informations.type1 === type1 && e.informations.type2 === type2) ||
                (e.informations.id1 === id2 && e.informations.id2 === id1 && e.informations.type1 === type2 && e.informations.type2 === type1)
            );
        });
    };

    const findExactLinkOne = (id1, type1) => {
        return allApplicationLinks.filter((e) => {
            return (e.informations.id1 === id1 && e.informations.type1 === type1) || (e.informations.id2 === id1 && e.informations.type2 === type1);
        });
    };

    const findLinkObject = (id1, type1) => {
        if (!allApplicationLinks) {
            return;
        }
        return allApplicationLinks.filter((e) => {
            return (e.informations.id1 === id1 && e.informations.type1 === type1) || (e.informations.id2 === id1 && e.informations.type2 === type1);
        });
    };

    const findLinkObjectType = (id1, type1, type2) => {
        if (!allApplicationLinks) {
            return;
        }
        return allApplicationLinks.filter((e) => {
            return (
                (e.informations.id1 === id1 && e.informations.type1 === type1 && e.informations.type2 === type2) ||
                (e.informations.id2 === id1 && e.informations.type2 === type1 && e.informations.type1 === type2)
            );
        });
    };

    const findLink = (id, type, alreadyType = []) => {
        if (!allLinks) {
            return null;
        }
        for (let i = 0; i < allLinks.length; i++) {
            var link = allLinks[i];
            var typeE = link.informations.type1 === "user" ? link.informations.type2 : link.informations.type1;
            var idE = link.informations.type1 === "user" ? link.informations.id2 : link.informations.id1;
            if (typeE === type && idE === id) {
                return link;
            }
        }

        var allObjectLink = findLinkObject(id, type);
        if (!allObjectLink) return null;
        for (let i = 0; i < allObjectLink.length; i++) {
            var l = allObjectLink[i];
            var otherType = l.informations.type1 === type ? l.informations.type2 : l.informations.type1;
            var otherId = l.informations.type1 === type ? l.informations.id2 : l.informations.id1;
            if (alreadyType.indexOf(otherType) === -1) {
                var newLink = findLink(otherId, otherType, [...alreadyType, otherType]);
                if (newLink) {
                    return newLink;
                }
            }
        }
        return null;
    };

    //Get all type has link with user
    const findAllLinksWithUser = (type) => {
        return allLinks.filter((e) => {
            return e.informations.type1 === type || e.informations.type2 === type;
        });
    };

    var value = {
        user,
        setUser,
        allApplicationLinks,
        userClass,
        loginUser,
        adminLoginUser,
        updateUser,
        findLink,
        findAllLinksWithUser,
        allLinks,
        reloadLinks,
        findLinkObject,
        findLinkObjectType,
        hasState,
        isAdmin,
        isSuperAdmin,
        findExactLink,
        findExactLinkOne,
    };

    return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};
