import DefaultComponent from './DefaultComponent';

export default function MyDayComponent({ clickedData, setClickedData, type, lang }) {
  return (
    <DefaultComponent
      clickedData={clickedData}
      setClickedData={setClickedData}
      type={type}
      picture
      lang={lang}
    ></DefaultComponent>
  );
}
