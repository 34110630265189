import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createElement, useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../Context/UserContext';
import { UpdateApplication, UpdateJsonConfig } from '../../../../../Services/Application';
import TrashButton from '../../../../Common/Button/TrashButton';
import CustomObjectComponent from './Templates/CustomObjectComponent';
import CustomPageComponent from './Templates/CustomPageComponent';
import DefaultComponent from './Templates/DefaultComponent';
import DocComponent from './Templates/DocComponent';
import EventComponent from './Templates/EventComponent';
import FavoriteComponent from './Templates/FavoriteComponent';
import MyDayComponent from './Templates/MyDayComponent';
import PubComponent from './Templates/PubComponent';
import WebViewComponent from './Templates/WebViewComponent';

const typeMap = [
  {
    key: 'TITLE',
    value: 'Titre',
    component: DefaultComponent,
  },
  {
    key: 'EVENT',
    value: 'Evenement',
    component: EventComponent,
  },
  {
    key: 'CUSTOMOBJECTLIST',
    value: "Liste d'objet",
    component: CustomObjectComponent,
  },
  {
    key: 'MYNOTES',
    value: 'Notes',
    component: DefaultComponent,
    props: {
      picture: true,
    },
  },
  {
    key: 'WEBVIEW',
    value: 'Vue web',
    component: WebViewComponent,
  },
  {
    key: 'DOC',
    value: 'Document',
    component: DocComponent,
  },
  {
    key: 'FAVORIS',
    value: 'Favoris',
    component: FavoriteComponent,
  },
  {
    key: 'MYDAY',
    value: 'MyDay',
    component: MyDayComponent,
  },
  {
    key: 'PUB',
    value: 'Publicité',
    component: PubComponent,
  },
  {
    key: 'CUSTOMPAGE',
    value: 'Page personnalisée',
    component: CustomPageComponent,
  },
];

export default function EditDesignComponent({
  clickedData,
  setClickedData,
  allData,
  setAllData,
  tabBarData,
  lang,
  design,
  urlPhoto,
  saveHome,
}) {
  const { config, reloadConfig, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);

  if (!clickedData) return null;

  const saveConfig = async (allData, clickedData, deleteTab = false) => {
    let data = [...allData];
    if (!clickedData);
    else if (clickedData.tabBar) {
      let tabData = tabBarData.map((e) => {
        let obj = { ...e };
        delete obj.x;
        delete obj.y;
        delete obj.width;
        delete obj.index;
        delete obj.tabBar;
        return obj;
      });
      if (clickedData._id) {
        if (deleteTab) {
          tabData = tabData.filter((e) => e._id !== clickedData._id);
        } else tabData[tabData.findIndex((e) => e._id === clickedData._id)] = clickedData;
      } else {
        tabData.push({ ...clickedData, _id: v4() });
      }
      await UpdateJsonConfig(application.apiKey, { ...config, tabBarV2: tabData });
      await reloadConfig();
      return toast.success('Modifications sauvegardées !', { autoClose: 2000, position: 'bottom-center' });
    } else if (data.find((e) => e._id === clickedData._id))
      data[data.findIndex((e) => e._id === clickedData._id)] = clickedData;
    else data.push(clickedData);
    const newHome = saveHome(data).pages.map((e) => {
      let obj = { ...e };
      if (obj.width === 1) obj.style = 'HALFSQUARE';
      else if (obj.width === 2 && obj.image) obj.style = 'FULLSQUARE';
      else obj.style = 'CLASSIC';
      delete obj.x;
      delete obj.y;
      delete obj.width;
      delete obj.index;
      delete obj.tabBar;
      return obj;
    });
    if (design.name === 'Home') await UpdateJsonConfig(application.apiKey, { ...config, home: newHome });
    else {
      let newConfig = { ...config };
      if (config.customPage.find((e) => e.name === design.name))
        newConfig.customPage[config.customPage.findIndex((e) => e.name === design.name)].pages = newHome;
      else newConfig.customPage.push({ name: design.name, title: design.title, pages: newHome });
      await UpdateJsonConfig(application.apiKey, newConfig);
    }
    await UpdateApplication(application.apiKey, user.authToken, application._id, { pictureUrl: urlPhoto });
    await reloadConfig();

    return toast.success('Modifications sauvegardées !', { autoClose: 2000, position: 'bottom-center' });
  };

  return (
    <>
      {createElement((typeMap.find((e) => e.key === clickedData.type) || {}).component || DefaultComponent, {
        clickedData: clickedData,
        setClickedData: setClickedData,
        type: (typeMap.find((e) => e.key === clickedData.type) || {}).value,
        ...(typeMap.find((e) => e.key === clickedData.type) || {}).props,
        lang: lang,
      })}
      <div className="flex justify-center gap-x-3 px-3 mb-5 " style={{ marginTop: 40 }}>
        <TrashButton
          className="border border-gray-500 transition-all hover:border-red-400 rounded px-8 py-2 "
          onClick={() => {
            if (window.confirm('Voulez-vous supprimer ce module ? ')) {
              let newData = allData.filter((e) => e._id !== clickedData._id);
              setAllData(newData);
              saveConfig(newData, clickedData.tabBar ? clickedData : null, true);
              setClickedData(null);
            }
          }}
        />

        <div
          className="border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
          onClick={() => {
            let obj = { ...clickedData };
            delete obj.index;
            let newData = [...allData];
            if (clickedData.index || clickedData.index === 0) newData[clickedData.index] = obj;
            else {
              let newClickedData = {
                ...obj,
                style: 'HALFSQUARE',
                _id: v4(),
                width: 1,
                x: 0,
                y: (allData[allData.length - 1] || {}).y + 1 || 0,
              };
              newData.push(newClickedData);
              newClickedData.index = allData.length;
              setClickedData(newClickedData);
            }
            if (!clickedData.tabBar) setAllData(newData);
            saveConfig(newData, clickedData);
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </div>
    </>
  );
}
