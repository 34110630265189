import {GetUKObjectById} from "../Services/UKObject";

export class UKUser {
  config = [];
  type = null;
  json = {};

  constructor(json, config) {
    this.type = (json || {}).type || '';

    this.json = {
      informations: {
        r: '_',
      },
      ...json,
    };
    if (config && json?.informations?.user_custom_type) {
      this.config = config.inscription.customs.find((elem) => elem.id === json.informations.user_custom_type);
    }
  }

  getInformations(id) {
    var value = this.json.informations[id];
    return value || null;
  }

  getFirstName(lang) {
    return this.getInformations(lang ? `firstName_${lang.id}` : 'firstName') || this.getInformations('firstName');
  }

  getLastName(lang) {
    return this.getInformations(lang ? `lastName_${lang.id}` : 'lastName') || this.getInformations('lastName');
  }

  getEmail() {
    return this.json.informations['email'] || '';
  }

  getFullName(lang) {
    return this.getFirstName(lang) + ' ' + this.getLastName(lang);
  }

  getSubtitle(lang) {
    if (!this.config) return '';
    var subtitle = this.config.custom.find((elem) => elem.isSubTitle == true);

    if (subtitle != null) {
      return this.getInformations(lang ? `${subtitle.id}_${lang.id}` : subtitle.id) || this.getInformations(subtitle.id);
    }
    return '';
  }

  getPicture(lang) {
    var picture = (this.json.informations || {})['profilPicture'] || '';
    if (picture === '') {
      picture = 'https://eu.ui-avatars.com/api/?name=' + this.getFullName(lang) + '&size=512&rounded=true';
    }
    return picture;
  }

  getJson(apiKey, authToken, config) {
    var result = {};
    var _this = this;
    result['firstName'] = _this.getFirstName();
    result['lastName'] = _this.getLastName();
    result['email'] = _this.json.informations['email'] || '';
    this.config.custom.forEach((elem) => {
      result[elem.id] = _this.getInformations(elem.id);
    });
    return result;
  }


  async getLinks(config, apiKey) {
    const linksConf = config.inscription.customs?.find(custom => custom.id === this.json.informations.user_custom_type)?.custom?.filter(cat => cat.type === "link")


    const findObjLink = (linksArr, knownLinks) => {
      let objLinks = [];
      if (!linksArr || linksArr?.length === 0) return [];
      for (const link of linksArr) {
        if (link.linkType === "object") {
          objLinks.push(link);
          const foundObjLinks = config.customObject.find(c => c.id === link.url).custom.filter(c => c.type === "link" && c.linkType === "object" && knownLinks.findIndex(kl => kl === link.id) === -1)
          objLinks.push(...findObjLink(foundObjLinks, [...knownLinks, link.id]));
        }
      }
      return objLinks;
    }

    // const getLinkedObj = async (id, links, knownLinks) => {
    //   console.log("infos", id, links, knownLinks)
    //
    //   // const obj = await GetUKObjectById(id, apiKey, this.json.authToken);
    //   // for (const link of links) {
    //   //   if (!obj) continue;
    //   //   const linksArr = obj.informations[link.id];
    //   //
    //   //   let newLinks = [];
    //   //   if (linksArr) {
    //   //     for (const objLink of linksArr) {
    //   //       if (knownLinks.findIndex(kl => kl === objLink) === -1) {
    //   //         newLinks.push(objLink);
    //   //         knownLinks.push(objLink);
    //   //         const result = await getLinkedObj(objLink, link.url, links, knownLinks);
    //   //         if (result) links = [...newLinks, ...result];
    //   //       }
    //   //     }
    //   //   }
    //   //   return newLinks
    //   // }
    //   // return null;
    // }

    const recursiveLinks = findObjLink(linksConf, []);
    const urls = recursiveLinks.map(l => l.url)
    const ids = recursiveLinks.map(l => l.id)
    const filteredLinks = recursiveLinks.filter(({url, id}, index) => !urls.includes(url, index + 1) && ids.includes(id, index + 1))


    // Récupère les liens directs
    let linksContent = [];
    for (const link of linksConf) {
      if (link.linkType !== "object") return;

      if (this.json.informations[link.id]) {
        linksContent.push({links: this.json.informations[link.id], conf: link});
      }
    }
    return {direct: linksContent, indirect: [...filteredLinks]}
    // return await Promise.all(recursiveLinks.map(async (link) => {
    //   let linksContent = [];
    //

    //
    //
    //   // Récupère les liens indirects
    //   //   const userLinks = this.json.informations[link.id];
    //   //   if (userLinks) {
    //   //     for (const id of userLinks) {
    //   //       const result = await getLinkedObj(id, recursiveLinks, [this.json._id]);
    //   //       if (result) linksContent.push(...result);
    //   //     }
    //   //   }
    //
    //   let newObjs = [];
    //
    //   for (const id of linksContent) {
    //     let obj = await GetUKObjectById(id, apiKey, this.json.authToken);
    //     if (obj) newObjs.push(obj);
    //   }
    //   return ({
    //     url: link.url,
    //     direct: linksConf.map(({url}) => link.url === url)[0],
    //     // config: config.customObject.find(custom => custom.id === link.url).custom,
    //     objects: newObjs
    //   });
    // }));
  }
}
