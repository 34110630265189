import { useContext, useEffect, useState } from "react";
import Select from 'react-select'
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { UKObjectClass } from "../../Class/UKObjectClass";
import { ApplicationContext } from "../../Context/ApplicationContext"
import { GetExternalLinkById, GetUKObjectById } from "../../Services/UKObject";
import ObjectDetailComponent from "../Visitor/Object_old/ObjectDetailComponent";
import ApplicationLink from "../Visitor/Account/Admin/ApplicationLink";
function InvitationComponent() {
	const { application, config } = useContext(ApplicationContext)
	let { params } = useRouteMatch();
	const [object, setObject] = useState(null)
	const [lang, setLang] = useState(null)

	useEffect(() => {
		if (!config)
			return
		if (config.language) {
			let language = config.language.find((e) => e.isDefault === true)
			setLang(language)
		}
	}, [config])

	const reloadLink = async () => {
		const link = await GetExternalLinkById(params.id, application.apiKey)
		const object = await GetUKObjectById(link.informations.id, "", application.apiKey)
		setObject(new UKObjectClass(object, config))
	}
	useEffect(() => {
		if (!params) return null;

		reloadLink()
		// eslint-disable-next-line
	}, [params]);

	if (!application) { return null }
	return (
		<div>
			<p className="text-xl font-bold text-gray-700 text-left w-1/2 m-auto my-4">Invitation</p>

			<ApplicationLink />

			<div className="m-auto w-1/2">
				{lang && <Select
					className="w-1/3"
					options={config.language.map((e) => { return { value: e.id, label: e.name } })}
					value={{ value: lang.id, label: lang.name }}
					onChange={(e) => {
						setLang(config.language.find((el) => e.value === el.id))
					}}
				/>}
			</div>
			{object && <ObjectDetailComponent lang={lang} objectClass={object} setObjectClass={setObject} isInvit={true} idobject={object.json._id} typeobject={null} />}

		</div>
	);
}

export default InvitationComponent;
