import Switch from "react-router-dom/es/Switch";
import {Route, useRouteMatch} from "react-router-dom";
import EventkeyHome from "./eventkey.home";
import LoginComponent from "../../Login/LoginComponent";
import EventkeyForm from "./eventkey.form";

const EventkeyView = () => {
  let {path} = useRouteMatch();

  return (
    <div className="w-full h-screen bg-gray-100">
      <Switch>
        <Route exact path={`${path}/login`}>
          <LoginComponent/>
        </Route>
        <Route exact path={`${path}/home`}>
          <EventkeyHome/>
        </Route>
        <Route exact path={`${path}/newapp`}>
          <EventkeyForm/>
        </Route>
      </Switch>
    </div>
  );
}

export default EventkeyView;