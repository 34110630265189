import { useContext } from "react";
import { Chart } from "react-google-charts";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis } from "recharts";

import { ApplicationContext } from "../../../Context/ApplicationContext";

export const MyBarChart = ({ question }) => {
    const { config } = useContext(ApplicationContext);
    const baseColor = config.mainColor;

    const ans = question.answer;
    if (!ans || ans.length === 0) {
        return <></>;
    }

    let res = [];

    let choices = question.informations.choices || [];
    if (choices.length === 0) {
        choices = question.informations.choices_en || [];
    }
    if (choices.length === 0) {
        choices = question.informations.choices_fr || [];
    }
    const numOfColors = choices.length;
    const colorScale = [
        "#2F4B67",
        "#D2AAAA",
        "#F1BC61",
        "#F2B484",
        "#6C1A4A",
        "#D04D9A",
        "#FF0000",
        "#FF7F00",
        "#FFFF00",
        "#00FF00",
        "#0000FF",
        "#4B0082",
        "#8F00FF",
        "#FF00FF",
        "#00FFFF",
        "#000000",
        "#FFFFFF",
    ];
    const options = {
        legend: { position: "none" },
        colors: ["red", "green"], // colorScale.slice(0, numOfColors),
    };
    console.log(question);

    let index = 0;
    console.log("CHOICES", choices);
    for (let choice of choices) {
        res.push({
            name: choice,
            uv: 0, //Math.floor(Math.random() * 100),
        });
        // res.push([choice, Math.random() * 100, colorScale[index]]);
        index++;
    }

    const addResult = (answer) => {
        const index = res.findIndex((e) => e.name === answer);
        if (index !== -1) {
            res[index].uv += 1;
        }
    };

    for (const el of ans) {
        const tmp = el.informations.answer || "";
        if (tmp.constructor === Array) {
            for (const answer of tmp) {
                addResult(answer);
            }
        } else {
            addResult(tmp);
        }
    }

    const totalAnswer = res.reduce((acc, el) => acc + el.uv, 0);
    for (let r of res) {
        r.uv = Math.round((r.uv / totalAnswer) * 100);
    }

    console.log("DATA", res);
    console.log("Options", options);
    return (
        <div className={"flex flex-col justify-center w-full h-full"} style={{ height: "85vh", position: "absolute", bottom: 0 }}>
            <div className="px-16 mb-4">
                <table className="w-full text-left text-2xl">
                    <thead className="">
                        <tr className="bg-gray-500 border-b-2 border-l-2 border-r-2 border-gray-500 text-white">
                            <th
                                style={{
                                    width: "30px",
                                }}
                            ></th>
                            <th>Options</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {res.map((el, index) => (
                            <tr key={index} className="border-b-2 border-l-2 border-r-2 border-gray-500">
                                <td
                                    style={{
                                        position: "relative",
                                        width: "30px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            width: "30px",
                                            height: "100px",
                                            top: 0,
                                            left: 0,
                                            backgroundColor: colorScale[index % colorScale.length],
                                        }}
                                    ></div>
                                </td>
                                <td className="py-2"> {el.name}</td>

                                <td>{el.uv}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ResponsiveContainer width="96%" height="70%">
                <BarChart width={150} height={100} data={res}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    {/* <YAxis /> */}
                    {/* <Tooltip /> */}
                    {/* <Legend /> */}
                    <Bar dataKey="uv" fill="#8884d8">
                        {res.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colorScale[index % colorScale.length]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
    return (
        <div className={"flex justify-center w-full h-full"}>
            <Chart chartType={"Bar"} data={res} width={"100%"} height={"800px"} options={options}></Chart>
        </div>
    );
};
