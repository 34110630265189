import DocsCell from "./docs.cell";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const DocsList = ({isLoading, setFolderStack, handleOnDocEdit, docs, setDocs, updateDocsIndex}) => {
  let isEmpty = true;

  const handleOnDocMoves = (dragIndex, hoverIndex, isDropped) => {
    if (isDropped) {
      let index = 0;
      let newDocs = [...docs];
      newDocs.forEach((doc) => {
          doc.informations.index = index;
          index++;
        }
      );
      setDocs(newDocs);
      updateDocsIndex(newDocs);
      return;
    }
    setDocs((prevDocs) => {
      let newDocs = [...prevDocs];
      newDocs.splice(dragIndex, 1);
      newDocs.splice(hoverIndex, 0, prevDocs[dragIndex]);
      return newDocs;
    })
  }

  const renderCell = (doc, index) => {
    if (doc === undefined)
      return <></>
    return (
      <DocsCell
        doc={doc}
        key={doc._id}
        index={index}
        id={doc._id}
        setFolderStack={setFolderStack}
        handleOnDocEdit={handleOnDocEdit}
        handleOnDocMoves={handleOnDocMoves}
      />
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {docs && <div onClick={(event) => {
        if (event.target === event.currentTarget) handleOnDocEdit(null)
      }}>
        {docs && docs.map((e, index) => {
          isEmpty = false;
          return (
            renderCell(e, index)
          )
        })}
        {!isLoading && isEmpty && <div className="mt-3 text-sm">Aucun document</div>}
        {isLoading && <div className="mt-3 text-sm">Chargement...</div>}
      </div>}
    </DndProvider>
  );
}

export default DocsList
