import React, { useContext } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import CustomModal from "../../../Common/CustomModal.component";
import ObjectsForm from "../../Objects/Form/objects.form";
import DisplayUserSubscribeEvent from "../display.user.subscribe.event";
import DisplayNoteEvent from "../display.note.event";

const SubEventDetailModal = ({ subEvent, isOpen, onClose, onSubmit }) => {
  const { langKey, config } = useContext(ApplicationContext);

  return [
    <CustomModal title='Sous-évènement' isOpen={isOpen} onClose={onClose}>
      <ObjectsForm config={config.eventObject} lang={langKey} defaultValue={subEvent.informations} onSubmit={onSubmit} question={true} subeventId={subEvent._id} />
      <DisplayUserSubscribeEvent idEvent={subEvent.id} />
      <DisplayNoteEvent isSubEvent={true} idEvent={subEvent.id} />
    </CustomModal>,
  ];
}

export default SubEventDetailModal
