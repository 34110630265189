import { faQuestion, faSealQuestion, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { DeleteEventObject } from "../../../Services/UKObject";
import CommonButton from "../../Common/Button/CommonButton";
import DisplayNoteEvent from "./display.note.event";
import DisplayUserSubscribeEvent from "./display.user.subscribe.event";
import EventDetailLinks from "./event.detail.links";
import EventDetailSubevent from "./event.detail.subevent";
import EventDetailTabbar from "./event.detail.tabbar";
import EventFormInputs from "./event.form.inputs";
// import ObjectsGroupfilterInput from "../Objects/form/objects.groupfilter.input";
import SwitchCustom from "../../Common/SwitchCustom";
import ObjectsGroupfilterInput from "../Objects/Form/objects.groupfilter.input";
const typeFields = {
    infos: ["text", "bigtext", "date", "email", "web", "selector", "multipleSelector", "banniere", "files", "picture", "icon"],
    links: ["link", "maplink"],
};

const EventDetailForm = ({ defaultValues, onSubmit }) => {
    const { config, langKey, application, reloadConfig } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const params = useParams();
    const history = useHistory();
    const [form, setForm] = useState({});
    const [page, setPage] = useState(null);

    useEffect(() => {
        if (defaultValues) {
            // console.log("OBJECT", defaultValues);
            setForm(defaultValues);
        }
    }, [defaultValues]);

    const onSave = () => {
        const important = config.eventObject.custom.filter((e) => e.important);
        for (let i = 0; i < important.length; i++) {
            const value = getValue(important[i].id);
            // console.log(important[i]);
            if (!value) {
                toast.error(`${important[i].tint} est obligatoire`, { autoClose: 2000 });
                return;
            }
        }
        console.log("ON SUB1")
        onSubmit(form);
    };

    const getValue = (id, type) => {
        // const value = form[`${id}${langKey}`];
        // if (value) return {value: value, id: `${id}${langKey}`};
        if (type === "date" || type === "files") return { value: form[`${id}`], id: `${id}` };
        return { value: form[`${id}${langKey}`], id: `${id}${langKey}` };
    };

    const openInNewTab = (path) => {
        const value = params.idobject;
        const url = `/${path}/${value}`;
        window.open(url, "_blank");
    };

    const handleClickDelete = async () => {
        if (!application || !user) return;
        if (window.confirm("Voulez-vous vraiment supprimer cet événement ?"))
            await DeleteEventObject(params.idevent || params.idobject, "ukEvent", application.apiKey, (user || {}).authToken);
        history.goBack();
        reloadConfig();
    };

    const isSubEvent = () => {
        const id = params.idobject;
        let bool = false;
        if (id.length === 24) bool = /[a-f]+/.test(id);
        return !bool;
    };

    if (!config) return;
    
    return (
        <div className="ml-10 mr-10">
            {params.idobject !== "new" && <EventDetailTabbar config={config.eventObject.custom} page={page} setPage={setPage} isSubEvent={isSubEvent()} />}
            {!page && (
                <>
                    <p className="text-left font-bold text-2xl pb-3 text-gray-700">Informations principales</p>

                    <ObjectsGroupfilterInput form={form} setForm={setForm} />
                    <div className="mt-4 flex flex-col">
                        <p className="text-sm text-left flex">Est important ?</p>
                        <div className="scale-150  w-fit ml-2 mt-2">
                            <SwitchCustom checked={form.important} setChecked={(value) => setForm((old) => ({ ...old, important: value }))} />
                        </div>
                    </div>
                    <div className="space-y-4 mt-2">
                        {config.eventObject &&
                            config.eventObject.custom.map((e) => {
                                if (typeFields.infos.findIndex((type) => type === e.type) === -1) return null;
                                const value = getValue(e.id, e.type);
                                return <EventFormInputs value={value.value} config={e} setForm={setForm} id={value.id} form={form} />;
                            })}
                    </div>
                    <div className="flex gap-4">
                        {params.idobject !== "new" && (
                            <CommonButton
                                customClassName="w-max mt-5"
                                useAppTheme={false}
                                customColor={"#F87171"}
                                title="Supprimer"
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                onClick={handleClickDelete}
                            />
                        )}
                        <CommonButton customClassName="w-max mt-5" title="Sauvegarder" onClick={onSave} />
                        {params.idobject !== "new" && (
                            <CommonButton
                                customClassName="w-max mt-5"
                                title={"Voir les questions"}
                                onClick={() => openInNewTab("question")}
                                icon={<FontAwesomeIcon icon={faSealQuestion} />}
                            />
                        )}
                        {params.idobject !== "new" && (
                            <CommonButton
                                customClassName="w-max mt-5"
                                title={"Questions Live"}
                                onClick={() => openInNewTab("questionLive")}
                                icon={<FontAwesomeIcon icon={faQuestion} />}
                            />
                        )}
                    </div>
                </>
            )}

            {page === "subevent" && <EventDetailSubevent subEvent={form.subEvent} form={form} setForm={setForm} onSubmit={onSubmit} />}
            {page &&
                page !== "subevent" &&
                config.eventObject &&
                config.eventObject.custom.map((e) => {
                    if (page !== e.id) return null;
                    return <EventDetailLinks linkConfig={e} setForm={setForm} form={form} onSave={onSave} />;
                })}
            {params.idobject !== "new" && !page && (
                <div>
                    <p className="text-left font-bold text-2xl pb-3 text-gray-700 pt-5">Incrits à l'événement</p>
                    <div className="py-5 bg-white border border-gray-300 rounded p-2 m-auto mb-5">
                        <DisplayUserSubscribeEvent idEvent={params.idobject} />
                    </div>

                    <p className="text-left font-bold text-2xl pb-3 text-gray-700 pt-5">Note de l'événement</p>
                    <DisplayNoteEvent idEvent={params.idobject} />
                </div>
            )}
        </div>
    );
};

export default EventDetailForm;
