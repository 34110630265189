import React, { useRef } from "react";
import CommonButton from "../Button/CommonButton";

const CustomInputFile = ({title, accept, onChange, icon}) => {
  const hiddenFileInput = useRef();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <CommonButton customClassName='w-max' title={title} useAppTheme={false} customColor={'#3B82F6'} icon={icon} onClick={handleClick}>
        Upload a file
      </CommonButton>
      <input type="file"
             accept={accept}
             ref={hiddenFileInput}
             onChange={onChange}
             style={{display: 'none'}}
      />
    </>
  );
}

export default CustomInputFile
