import { useState, useEffect, useContext } from 'react'
import { ApplicationContext } from '../../../../Context/ApplicationContext'

export default function ApplicationLink() {
    const [appLinks, setAppLinks] = useState({})
    const { application } = useContext(ApplicationContext)
    const [inputValues, setInputValues] = useState()

    useEffect(() => {
        if (application)
            setInputValues(application.informations)
    }, [application])

    useEffect(() => {
		if (inputValues)
        	setAppLinks({ android: inputValues.linkAndroid, ios: inputValues.linkIOS })
    }, [inputValues])

    if (!inputValues || (!inputValues.linkAndroid && !inputValues.linkIOS))
        return null

    return (
        <>
            <div className="bg-white w-full sm:w-3/4 md:w-3/4 xl:w-2/4 m-auto rounded border border-gray-300 flex items-center py-4 px-3 my-3">
                <p className='w-1/3 font-bold'>Téléchargez l'application mobile</p>
                <div className='flex gap-4 items-center justify-center w-2/3'>
                    {inputValues.linkIOS && <img src='/downloadios.png' alt='' className='h-10 cursor-pointer' onClick={() => {
                        window.open(appLinks.ios, '_blank')
                    }}></img>}
                    {inputValues.linkAndroid && <img src="/google-play-badge.png" alt="" className='h-10 cursor-pointer' onClick={() => {
                        window.open(appLinks.android, '_blank')
                    }}/>}
                </div>
            </div>
        </>
    )
}