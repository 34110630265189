import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import SideBarContent from './sidebar.content';

const SideBarView = ({}) => {
  const {isAdmin} = useContext(UserContext);
  const {menuOpen, setMenuOpen} = useContext(ApplicationContext);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(menuOpen);
  }, [menuOpen]);

  return (
    <div
      className="min-hscreen-withoutheader"
      style={{
        minWidth: isOpen ? '250px' : '1%',
        maxWidth: isOpen ? '250px' : '1%',
        zIndex: 1,
      }}>
      <div
        className="flex w-full bottom-0 top-0 transition-all min-hscreen-withoutheader "
        style={{
          minWidth: isOpen ? '250px' : '1%',
          maxWidth: isOpen ? '250px' : '1%',
        }}>
        <div className="flex-col w-full bg-white space-y-3 overflow-y-scroll no-scrollbar min-hscreen-withoutheader">
          <div className="flex justify-end">
            <FontAwesomeIcon
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              className="m-2"
              icon={faClose} />
          </div>
          <SideBarContent />
        </div>
      </div>
    </div>
  );
};

export default SideBarView;
