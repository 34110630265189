import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import TrashButton from "../../../Common/Button/TrashButton";
import YesNoInputV2 from "../../Account/Admin/Design/Component/YesNoInputV2";
import SelectValueSelector from "../../Events/Settings/SelectValueSelector";

const ObjectSettingsEdit = ({ item, onUpdate, onSave, onDelete }) => {
    const { config } = useContext(ApplicationContext);
    const params = useParams();

    if (!item) {
        return <div></div>;
    }
    const types = [
        { title: "Texte", type: "text" },
        { title: "Long texte", type: "bigtext" },
        { title: "Email", type: "email" },
        { title: "Lien web", type: "web" },
        { title: "Sélecteur", type: "selector" },
        { title: "Sélecteur Multiple", type: "multipleSelector" },
        { title: "Image", type: "picture" },
        { title: "Icone", type: "icon" },
        { title: "Lien objet", type: "link" },
        { title: "Banniere", type: "banniere" },
        { title: "Fichiers", type: "files" },
        { title: "Partage de data", type: "shareData" },
        //{ title: 'Catégorie', type: 'category' },
    ];

    return (
        <div className="col-span-2 border border-gray-400 bg-white px-4 py-2 rounded">
            <div className="py-2">
                <p className="font-medium text-sm text-left mb-2">Quelle est le nom de l'information ?</p>
                <input
                    className="custominputbox w-full"
                    type="text"
                    value={item.tint}
                    onChange={(e) => {
                        onUpdate({
                            ...item,
                            tint: e.target.value,
                            placeholder: e.target.value,
                        });
                    }}
                />

                {!item.protect && <p className="font-medium text-sm text-left mb-2 mt-2">Quelle est le type de l'information ?</p>}
                {!item.protect && (
                    <select
                        className="bg-gray-100 text-gray-700 px-3 w-max py-2"
                        value={item.type}
                        onChange={(e) => {
                            onUpdate({
                                ...item,
                                type: e.target.value,
                            });
                        }}
                    >
                        {types.map((e, index) => {
                            return (
                                <option key={index} value={e.type}>
                                    {e.title}
                                </option>
                            );
                        })}
                    </select>
                )}

                {item.type !== "banniere" && (
                    <YesNoInputV2
                        title={"Cette information est-elle le titre de l'objet ?"}
                        yesSelected={item.isTitle}
                        onClick={(index) => {
                            onUpdate({
                                ...item,
                                isTitle: index === 1,
                            });
                        }}
                    />
                )}
                {item.type !== "banniere" && (
                    <YesNoInputV2
                        title={"Cette information est-elle le sous-titre le l'objet ?"}
                        yesSelected={item.isSubTitle}
                        onClick={(index) => {
                            onUpdate({
                                ...item,
                                isSubTitle: index === 1,
                            });
                        }}
                    />
                )}
                {item.type !== "banniere" && (
                    <YesNoInputV2
                        title={"Cette information est-elle le deuxieme sous-titre de l'objet ?"}
                        yesSelected={item.isSubSubTitle}
                        onClick={(index) => {
                            onUpdate({
                                ...item,
                                isSubSubTitle: index === 1,
                            });
                        }}
                    />
                )}

                {(item.type === "selector" || item.type === "multipleSelector") && (
                    <div>
                        <SelectValueSelector
                            values={item.values || []}
                            setValues={(values) => {
                                onUpdate({
                                    ...item,
                                    values: values,
                                });
                            }}
                        />
                    </div>
                )}
                {item.type === "link" && (
                    <div>
                        <p className="font-medium text-sm text-left mb-2 mt-2">À quel type d'objet est-il lié ?</p>
                        <select
                            className="bg-gray-100 text-gray-700 px-3 w-max py-2"
                            value={item.url || ""}
                            onChange={async (e) => {
                                const isObject = config.customObject.findIndex((custom) => custom.id === e.target.value) >= 0;
                                onUpdate({
                                    ...item,
                                    url: e.target.value,
                                    linkType: e.target.value === "ukEvent" ? "ukEvent" : isObject ? "object" : "user",
                                    style: "line",
                                    identifier: "CustomObjectDetailCell",
                                });
                            }}
                        >
                            <option value="">-- Aucune selection --</option>
                            {config.customObject.map((e, index) => {
                                return (
                                    <option key={index} value={e.id}>
                                        {e.name}
                                    </option>
                                );
                            })}
                            {config.inscription.customs.map((e, index) => {
                                return (
                                    <option key={index} value={e.id}>
                                        {e.name}
                                    </option>
                                );
                            })}
                            <option value="ukEvent">Evénement</option>
                        </select>
                    </div>
                )}
                <div className="flex gap-5">
                    {item.type === "link" && item.linkType === "object" && (
                        <YesNoInputV2
                            title={"Les utilisateurs peuvent ils créer un lien avec ce type objet ?"}
                            yesSelected={item.canCreateLink}
                            onClick={(index) => {
                                onUpdate({
                                    ...item,
                                    canCreateLink: index === 1,
                                });
                            }}
                        />
                    )}
                    {item.type === "link" && item.canCreateLink && item.linkType === "object" && (
                        <YesNoInputV2
                            title={"Les utilisateurs peuvent ils créer plusieurs liens"}
                            yesSelected={item.multiLink}
                            onClick={(index) => {
                                onUpdate({
                                    ...item,
                                    multiLink: index === 1,
                                });
                            }}
                        />
                    )}
                </div>
                {item.type !== "picture" && (
                    <YesNoInputV2
                        title={"Cette information est-elle obligatoire ?"}
                        yesSelected={item.important}
                        onClick={(index) => {
                            onUpdate({
                                ...item,
                                important: index === 1,
                            });
                        }}
                    />
                )}
                <YesNoInputV2
                    title={"Cette information est-elle cachée sur l'application"}
                    yesSelected={item.hidden}
                    onClick={(index) => {
                        onUpdate({
                            ...item,
                            hidden: index === 1,
                        });
                    }}
                />
            </div>
            <div className="flex justify-center gap-x-3 px-3 mb-5 " style={{ marginTop: 40 }}>
                {!item.protect && (
                    <TrashButton
                        border
                        className="border border-gray-500 transition-all hover:border-red-400 rounded px-8 py-2 "
                        onClick={() => {
                            onDelete();
                        }}
                    />
                )}

                <div
                    className="border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8  cursor-pointer"
                    onClick={() => {
                        onSave();
                    }}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            </div>
        </div>
    );
};
export default ObjectSettingsEdit;
