import CustomInputBox from "../../Common/Inputs/customInputBox.component";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import CommonButton from "../../Common/Button/CommonButton";
import { faAbacus, faFloppyDisk, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from "react-quill";
import { formats, modules } from "../Docs/Settings/quill.toolbar";
import Select from "react-select";
import { GetObjectsAll } from "../../../Services/UKObject";
import { UserContext } from "../../../Context/UserContext";
import ModuleConfigurationStyleObject from "../Account/Admin/AppDesign/ModuleConfiguration/ModuleConfigurationStyleObject";
import YesNoInput from "../Account/Admin/Design/Component/YesNoInput";

const VoteSetting = ({voteInfo, updateVoteInfo, pageState, setPageState, saveVoteInfo}) => {
  const {application, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const {config} = useContext(ApplicationContext);
  const [editorValue, setEditorValue] = useState('');
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, [application, user]);

  useEffect(() => {
    setPageState(0);
    if (voteInfo) {
      if (!voteInfo.informations["description" + langKey] && !voteInfo.informations.description) return setEditorValue('');
      setEditorValue(voteInfo.informations["description" + langKey] || voteInfo.informations.description);
    }
  }, [voteInfo._id]);

  const fetchGroups = async () => {
    if (!application || !user) return null;
    const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
    setGroups(groups);
  }

  return (
    <div className={`flex flex-col gap-2 space-y-3 overflow-hidden ml-2 ${pageState === 0 ? "w-full px-2 py-4" : "w-0 h-0 transition-all duration-200"}`}>

      <CommonButton customClassName='w-max self-end' useAppTheme={false} title="Sauvegarder" icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                    onClick={saveVoteInfo} />

      {/*Title*/}
      <CustomInputBox title="Quelle est le titre de ce vote ?" value={voteInfo.informations["title" + langKey] || voteInfo.informations.title || ""} onChange={(e) => {
        updateVoteInfo("title" + (langKey || ""), e)
      }} />

      {/*Description*/}
      <div>
        <p className="font-medium text-sm text-left">Quelle est la description du vote ?</p>
        <ReactQuill
          theme="snow"
          value={editorValue}
          onChange={(value) => {
            setEditorValue(value);
            updateVoteInfo("description" + (langKey || ""), value)
          }}
          formats={formats}
          modules={modules}
          style={{
            backgroundColor: 'white',
          }}
        />
      </div>

      {/*Format*/}
      <ModuleConfigurationStyleObject clickedData={voteInfo.informations} setClickedData={(e) => {
        updateVoteInfo("style_view", e.style_view);
      }} />

      {/*Group*/}
      <div>
        <p className="font-medium text-sm text-left mb-2">Voulez-vous autoriser uniquement certains utilisateurs à voter ?</p>
        <Select
          placeholder="Tout le monde si aucun n'est sélectionné"
          isMulti={true}
          value={groups && voteInfo.informations.group
            ? groups.filter((e) => {
              let values = voteInfo.informations.group.split(',');
              return values.find((el) => el === e.informations.index);
            })
              .map((e) => {
                return {value: e.informations.index, label: e.informations.name};
              })
            : null
          }
          options={groups.map((e) => {
            return {value: e.informations.index, label: e.informations.name};
          })}
          onChange={(a) => {
            updateVoteInfo("group", a.map((e) => e.value.toString()).join(','));
          }}
        />
      </div>

      {/*Visible by*/}
      <div>
        <p className="font-medium text-sm text-left mb-2">Voulez-vous afficher ce vote uniquement à certains d'utilisateurs ?</p>
        <Select
          placeholder="Tout le monde si aucun n'est sélectionné"
          isMulti={true}
          value={groups && voteInfo.informations.visibleBy
            ? groups.filter((e) => {
              let values = voteInfo.informations.visibleBy.split(',');
              return values.find((el) => el === e.informations.index);
            })
              .map((e) => {
                return {value: e.informations.index, label: e.informations.name};
              })
            : null
          }
          options={groups.map((e) => {
            return {value: e.informations.index, label: e.informations.name};
          })}
          onChange={(a) => {
            updateVoteInfo("visibleBy", a.map((e) => e.value.toString()).join(','));
          }}
        />
      </div>

      {/*Show results ?*/}
      <YesNoInput yesSelected={voteInfo.informations.showResults} title="Afficher les résultats ?" onClick={(e) => {
        updateVoteInfo("showResults", e === 1);
      }} ></YesNoInput>

      {/*Date*/}
      <div className="flex justify-left gap-5">
        <div>
          <p className="font-medium text-sm text-left mb-2">Votre vote se déroulera du: </p>
          <input className="custominputbox" type="datetime-local" value={voteInfo.informations?.startDate || ""} onChange={(e) => {
            updateVoteInfo("startDate", e.target.value);
          }} />
        </div>
        <div>
          <p className="font-medium text-sm text-left mb-2">au: </p>
          <input className="custominputbox" type="datetime-local" value={voteInfo.informations?.endDate || ""} onChange={(e) => {
            updateVoteInfo("endDate", e.target.value);
          }} />
        </div>
      </div>

      {/*Answer type*/}
      <div className="text-left">
        <p className="font-medium text-sm text-left mb-2">Quel est le type de réponse attendue ?</p>
        <select
          value={voteInfo.informations?.type}
          className="bg-white divide-y px-2 py-1 divide-gray-100 border border-gray-300 rounded"
          onChange={(e) => {
            updateVoteInfo("type", e.target.value);
          }}>
          <option className="py-1 text-sm text-gray-800" value="custom">Choix personnalisé</option>
          <option className="py-1 text-sm text-gray-800" value="object">Un Objet</option>
        </select>
      </div>

      {/*Custom answers*/}
      {voteInfo.informations?.type === "custom" && <div className="space-y-3">
        {(voteInfo.informations["options" + langKey] || voteInfo.informations?.options) && (voteInfo.informations["options" + langKey] || voteInfo.informations?.options).map((option, index) => {
          return (
            <div className="flex justify-start items-center">
              <p className="text-sm text-left ml-4 w-[10%]">Choix n°{index + 1}</p>
              <input className="custominputbox" value={option} onChange={(e) => {
                let options = voteInfo.informations["options" + (langKey || "")] ? [...(voteInfo.informations["options" + langKey] || voteInfo.informations?.options)] : [];
                let index = (voteInfo.informations["options" + langKey] || voteInfo.informations?.options).findIndex((e) => e === option);
                options[index] = e.target.value;
                updateVoteInfo("options" + (langKey || ""), options);
              }} />
              <CommonButton customClassName="m-1" useAppTheme={false} customColor={"#F87171"} icon={<FontAwesomeIcon className="m-1" icon={faTrash} />} onClick={() => {
                let index = (voteInfo.informations["options" + langKey] || voteInfo.informations?.options).findIndex((e) => e === option);
                let newOptions = (voteInfo.informations["options" + langKey] || voteInfo.informations?.options);
                newOptions.splice(index, 1);
                updateVoteInfo("options" + (langKey || ""), newOptions);
              }} />
            </div>
          );
        })}
        <CommonButton customClassName="w-max" title="Ajouter un choix" useAppTheme={false} onClick={() => {
          let options = voteInfo.informations["options" + (langKey || "")] ? [...(voteInfo.informations["options" + langKey] || voteInfo.informations?.options)] : [];
          options.push("");
          updateVoteInfo("options" + (langKey || ""), options);
        }} />
      </div>}

      {/*Answers from existing objects*/}
      {voteInfo.informations?.type === "object" && <div className="text-left">
        <p className="font-medium text-sm text-left mb-2">Parmis quelle liste d'objet pouvez-vous voter ?</p>
        <select
          value={voteInfo.informations?.optionType}
          className="bg-white divide-y px-2 py-1 divide-gray-100 border border-gray-300 rounded h-max"
          onChange={(e) => {
            updateVoteInfo("optionType", e.target.value);
          }}>
          <option className="py-1 text-sm text-gray-800" value={null}>-- Aucune selection --</option>
          {config && config.customObject.map((e, index) => {
            return (
              <option key={index} className="py-1 text-sm text-gray-800" value={e.id}>{e.name}</option>
            );
          })}
        </select>
        {voteInfo.informations?.optionType && voteInfo.informations?.optionType !== "-- Aucune selection --" &&
          <CommonButton customClassName="h-8 mb-1 mt-4 w-max" useAppTheme={false} title="Personnalisé la selection" icon={<FontAwesomeIcon icon={faAbacus} />} onClick={() => {
            setPageState(1);
          }} />}
      </div>}
    </div>
  );
}

export default VoteSetting
