import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetObjectsAll, GetUKObjectById } from "../../../Services/UKObject";
import { MyBarChart } from "./MyBarChart";
import { MyStarRating } from "./MyStarRating";
import { TextResult } from "./TextResult";

export function QuestionResultView({}) {
    const { application, reloadApplication, langKey, config } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const params = useParams();
    const [result, setResult] = useState(null);
    const [question, setQuestion] = useState(null);

    useEffect(() => {
        reloadApplication(window.location.hostname);
    }, [params]);

    useEffect(() => {
        getResults();
        const interval = setInterval(async () => {
            await getResults();
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [application, params.idquestion]);

    const getResults = async () => {
        if (!application) return;

        const answerType = `UKAnswerLive_${new URLSearchParams(window.location.search).get("answer")}`;

        if (!answerType || !params.idquestion) return;
        const question = await GetUKObjectById(params.idquestion, application.apiKey, (user || {}).authToken);
        const result = (await GetObjectsAll(answerType, application.apiKey, (user || {}).authToken)).filter((e) => e.informations.questionId === params.idquestion);
        setQuestion(question);
        setResult(result);
    };

    return (
        <div className="w-full h-full">
            {question && <p className="text-2xl text-gray-700 font-medium mt-16">{question.informations[`question${langKey}`]}</p>}
            <img
                style={{
                    position: "absolute",
                    top: "2%",
                    left: "5%",
                    zIndex: 99999,
                    height: "15vh",
                }}
                src={config?.questionLiveLogo || ""}
            />
            {!result && <p className="text-left text-sm text-gray-700">Aucun résultat trouvé</p>}

            {result && question && question.informations?.type === "mark" && <MyStarRating ans={result} showTotal={false} />}
            {result && question && (question.informations?.type === "choice" || question.informations?.type === "multipleChoice") && (
                <MyBarChart question={{ ...question, answer: result }} />
            )}
            {result && question && question.informations?.type === "text" && <TextResult ans={result} />}
        </div>
    );
}
