import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../../../Context/ApplicationContext";

const ModuleConfigurationGroupBy = ({className, module, setModule}) => {
  const {config} = useContext(ApplicationContext);

  const [groups, setGroups] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  useEffect(() => {
    if (!config) return;
    const newGroups = config.customObject
      ?.find(obj => obj.id === module.id)?.custom
      ?.filter(custom => custom.type === 'selector')
      ?.map(groups => ({value: groups.id, label: groups.tint})) || [];
    setCurrentGroup(module.group_by ? newGroups?.find((e) => e.value === module.group_by) : null);
    newGroups.unshift({value: null, label: "-- Aucun --"});
    setGroups(newGroups);
  }, [config, module._id]);

  const typeValid = ["CUSTOMOBJECTLIST"];
  if (typeValid.indexOf(module.type) === -1) {
    return <></>
  }

  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Voulez-vous grouper les objet par leurs type ? </p>
      <Select
        placeholder="Selectionner"
        value={currentGroup || null}
        options={groups || []}
        onChange={(group) => {
          if (!group.value) {
            setCurrentGroup(null);
            setModule({ ...module, group_by: null});
          }
          setCurrentGroup(group);
          setModule({ ...module, group_by: group.value});
        }}
      />
    </div>
  );
}

export default ModuleConfigurationGroupBy
