import { useContext, useEffect, useState } from 'react';
import { DateTimePicker, Input } from 'react-rainbow-components';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../../Context/UserContext';
import { GetObjectsAll } from '../../../../../../Services/UKObject';
import TrashButton from '../../../../../Common/Button/TrashButton';
import CustomFormInputComponent from '../../../../../Other/CustomFormInputComponent';
import PictureUpdate from '../../../PictureUpdate';
import YesNoInput from '../Component/YesNoInput';
import BanniereComponent from './banniere.components';
import icons from './icons.json';

export default function DefaultComponent({ clickedData, setClickedData, type, picture = false, children, lang }) {
  const { config, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    async function initGroups() {
      if (!application || !user) return null;
      const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
      setGroups(groups);
    }
    initGroups();
  }, [application, user]);

  return (
    <div>
      <div className="py-2 text-center mb-4 bg-gray-50 px-2">
        <p className="font-light text-sm" style={{ marginBottom: -5 }}>
          module
        </p>
        <p className="font-bold text-2xl" style={{ color: config.mainColor }}>
          {type}
        </p>
      </div>

      <div className="px-2">
        {picture && !clickedData.tabBar && (
          <div>
            <p className="font-medium text-sm text-left mb-2">Quel est l'image de votre module ?</p>
            <div className=" text-right rounded mt-0 w-max flex items-start mb-4">
              <PictureUpdate
                url={clickedData[lang ? `image_${lang.id}` : 'image']}
                size={100}
                onUpdate={(url) => {
                  var img = new Image();
                  img.onload = function () {
                    setClickedData({
                      ...clickedData,
                      ratio: (this.width / this.height).toString(),
                      [lang ? `image_${lang.id}` : 'image']: url,
                    });
                  };
                  img.src = url;
                }}
                customParentClassNames={'relative m-auto w-max'}
              />
              <TrashButton
                onClick={() => {
                  let obj = { ...clickedData };
                  delete obj.image;
                  setClickedData(obj);
                }}
              />
            </div>
            {/* 
          <div className="py-2">
            <p className="font-medium text-sm text-left mb-2">Ratio de l'image</p>
            <Input
              value={clickedData.ratio || ''}
              onChange={(e) => {
                let obj = { ...clickedData };
                obj.ratio = e.target.value;
                setClickedData(obj);
              }}
            />
          </div>
		  */}
          </div>
        )}
        {clickedData.tabBar && (
          <>
            <CustomFormInputComponent text={'Icône :'}>
              <Select
                value={{
                  value: clickedData[lang ? `image_${lang.id}` : 'image'],
                  label: (
                    <div className="flex items-center gap-x-2">
                      <img
                        src={`/icons/${(icons.find((e) => e.value === clickedData[lang ? `image_${lang.id}` : 'image']) || {}).value || ''}.png`}
                        alt=""
                        className="h-9"
                      />
                      <p>{(icons.find((e) => e.value === clickedData[lang ? `image_${lang.id}` : 'image']) || {}).name || ''}</p>
                    </div>
                  ),
                }}
                onChange={(e) => {
                  setClickedData({ ...clickedData, [lang ? `image_${lang.id}` : 'image']: e.value });
                }}
                options={icons.map((e) => {
                  return {
                    value: e.value,
                    label: (
                      <div className="flex items-center gap-x-2">
                        <img src={`/icons/${e.value}.png`} alt="" className="h-9" />
                        <p>{e.name}</p>
                      </div>
                    ),
                  };
                })}
              />
            </CustomFormInputComponent>
          </>
        )}

        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-8">
            <div className="py-2 w-full">
              <p className="font-medium text-sm text-left mb-2">Quel est le titre de votre module ?</p>
              <input
                className="custominputbox"
                type="text"
                value={clickedData[lang ? `text_${lang.id}` : 'text'] || ''}
                onChange={(e) => {
                  let obj = { ...clickedData };
                  obj[lang ? `text_${lang.id}` : 'text'] = e.target.value;
                  setClickedData(obj);
                }}
              />
            </div>
            {/*}
      <CustomFormInputComponent text={'Style :'}>
        <Input
          readOnly
          value={
            clickedData.style === 'HALFSQUARE'
              ? "Moitié de l'écran"
              : clickedData.style === 'FULLSQUARE'
              ? "Taille de l'écran"
              : 'Classique'
          }
        />
      </CustomFormInputComponent>*/}

            {!clickedData.tabBar && (
              <YesNoInput
                title={'Voulez-vous afficher le titre sur la page principale ?'}
                yesSelected={clickedData.textHidden == 'false'}
                onClick={(index) => {
                  setClickedData({ ...clickedData, textHidden: index == 1 ? 'false' : 'true' });
                }}
              />
            )}
          </div>
          {children}

          <YesNoInput
            title={'Voulez-vous afficher votre module uniquement à une date precise ?'}
            yesSelected={clickedData.visibleFrom !== undefined}
            onClick={(e) => {
              if (e == 1) {
                setClickedData({ ...clickedData, visibleFrom: new Date(), visibleTo: new Date() });
              } else {
                let newData = { ...clickedData };
                delete newData.visibleFrom;
                delete newData.visibleTo;
                setClickedData(newData);
              }
            }}
          />
          {clickedData.visibleTo && (
            <div className="py-2">
              <p className="font-medium text-sm text-left mb-2">Date d'apparition du module</p>
              <div className="flex gap-3 ">
                <DateTimePicker
                  disabled={clickedData.visibleFrom === undefined}
                  hour24
                  className="w-full"
                  locale={'fr-Fr'}
                  value={clickedData.visibleFrom || Date.now()}
                  onChange={(date) => {
                    setClickedData({ ...clickedData, visibleFrom: date });
                  }}
                  cancelLabel={'Annuler'}
                />
                <p>-</p>
                <DateTimePicker
                  disabled={clickedData.visibleTo === undefined}
                  hour24
                  className="w-full"
                  locale={'fr-Fr'}
                  value={clickedData.visibleTo || Date.now()}
                  onChange={(date) => {
                    setClickedData({ ...clickedData, visibleTo: date });
                  }}
                  cancelLabel={'Annuler'}
                />
              </div>
            </div>
          )}

          <div className="py-2">
            <p className="font-medium text-sm text-left mb-2">Voulez-vous afficher votre module uniquement à certaine typologie d'utilisateur ?</p>
            <Select
              placeholder="Selectionner"
              isMulti={true}
              value={
                groups && clickedData.groupRestricted
                  ? groups
                      .filter((e) => {
                        let values = clickedData.groupRestricted.split(',');
                        return values.find((el) => el === e.informations.index);
                      })
                      .map((e) => {
                        return { value: e.informations.index, label: e.informations.name };
                      })
                  : null
              }
              options={groups.map((e) => {
                return { value: e.informations.index, label: e.informations.name };
              })}
              onChange={(a, b) => {
                setClickedData({ ...clickedData, groupRestricted: a.map((e) => e.value.toString()).join(',') });
              }}
            />
          </div>

          <YesNoInput
            title={'Voulez-vous afficher le module aux utilisateurs non-connectés ?'}
            yesSelected={clickedData.state === '0,1,2,3'}
            onClick={(index) => {
              setClickedData({ ...clickedData, state: index == 1 ? '0,1,2,3' : '1,2,3' });
            }}
          />

        </div>
      </div>
    </div>
  );
}
