import { useContext } from 'react';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import PictureUpdate from '../../../PictureUpdate';

export default function ModuleConfigurationPicture({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);

  var typeInvalid = ['TITLE'];
  if (typeInvalid.indexOf(module.type) >= 0) {
    return <></>;
  }
  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Quel est l'image de votre module ?</p>
      <div className=" text-right rounded mt-0 w-max flex items-start mb-4">
        <PictureUpdate
          url={module.image}
          size={100}
          onUpdate={(url) => {
            var img = new Image();
            img.onload = function () {
              setModule({
                ...module,
                ratio: (this.width / this.height).toString(),
                image: url,
              });
            };
            img.src = url;

            setModule({
              ...module,
              image: url,
            });
          }}
          customParentClassNames={'relative m-auto w-max'}
        />
      </div>
    </div>
  );
}
