import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import CloseButton from '../../../../Common/Button/CloseButton';
import config from './modalConfig.json';
import LockWrapper from "../../../EventKey/lock.wrapper.component";

const ModuleModal = ({isOpen, setIsOpen, onUpdate = (newData = {}) => null, modalType = 'module'}) => {
  const [page, setPage] = useState(1);
  const [maxItems, setMaxItems] = useState();
  const [homeData, setHomeData] = useState();
  const [moduleSelected, setModuleSelected] = useState(null);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 20,
      height: '70%',
      width: '90%',
      zIndex: 100,
      overflow: "hidden",
    },
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) setMaxItems(2);
      else if (768 <= window.innerWidth && window.innerWidth < 992) setMaxItems(4);
      else if (992 <= window.innerWidth && window.innerWidth < 1200) setMaxItems(6);
      else setMaxItems(8);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!maxItems) return;
    let data = [];
    for (let i = maxItems * (page - 1); i < maxItems * page && i < config.length; i++) {
      const e = config[i];
      data.push(e);
    }
    setHomeData(data);
  }, [page, maxItems, isOpen]);

  const handleOnClose = () => {
    if (modalType === 'module') {
      setPage(1);
      onUpdate(null);
    }
    setIsOpen(false)
  }

  return (
    <div>
      <Modal style={customStyles} isOpen={isOpen}>
        <CloseButton
          className="float-right"
          onClick={() => {
            handleOnClose();
          }}
        />
        <div className="flex justify-center items-center">
          <p className="text-lg font-medium">Quel type de module voulez-vous ajouter ?</p>
        </div>
        <div className="grid grid-cols-3 gap-3 py-2 h-full">
          <div className="flex flex-col overflow-scroll gap-3">
            {config.map((e, index) => {
              const lockedModules = ['MYNOTES', 'FAVORIS', 'MYDAY', 'PHOTOWALL', 'VOTE', 'PLAN', 'QRCODE', 'SCANQRCODE', 'SCANQRCODEADMIN', 'LIVEQUESTION', 'GLOBALQUESTION']

              return ((modalType === "tabbar" && e.tabbar === true || modalType === "module") &&
                <LockWrapper id={e.id} customStyle='LOCK' forceUnlock={lockedModules.findIndex(m => m === e.id) === -1}>
                  <div
                    key={index}
                    onClick={() => {
                      setModuleSelected(e);
                    }}
                    className="bg-gray-100 border border-gray-400 text-gray-500 font-bold rounded px-2 hover:bg-gray-200 cursor-pointer">
                    <p>{e.name}</p>
                  </div>
                </LockWrapper>
              );
            })}
          </div>
          {moduleSelected && (
            <div className="col-span-2 text-center px-5 flex flex-col items-center gap-4">
              <p className="text-2xl font-bold ">{moduleSelected.name}</p>
              <p className="text-lg font-light text-left ">{moduleSelected.description}</p>
              <div
                className=" gap-2 flex items-center justify-center w-max border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
                onClick={() => {
                  setPage(1);
                  onUpdate({type: moduleSelected.id});
                }}>
                Ajouter
                <FontAwesomeIcon icon={faPlus}/>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ModuleModal