const copyPasteMode = {
  onSetup: function (opts) {
    const selectedFeatures = this.getSelected();
    if (!selectedFeatures.length) return;

    const coords = selectedFeatures[0].coordinates[0];
    const copy = this.newFeature({
      type: "Feature",
      properties: {...selectedFeatures[0].properties},
      geometry: {
        type: "Polygon",
        coordinates: [[...coords, [coords[0][0], coords[0][1]]]]
      }
    });

    this.addFeature(copy);

    let feature = copy.toGeoJSON();
    feature.type = selectedFeatures[0].type;
    if (copy.isValid()) {
      this.map.fire("draw.create", {
        features: [feature]
      });
    }

    this.changeMode("simple_select");
  },

  toDisplayFeatures: function (state, geojson, display) {
    return display(geojson);
  },
}

export default copyPasteMode;
