import {faFileExcel} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {FcCheckmark, FcHighPriority} from 'react-icons/fc';
import {FileSelector} from 'react-rainbow-components';
import ClipLoader from 'react-spinners/ClipLoader';
import {toast} from 'react-toastify';
import {ApplicationContext} from '../../../../../Context/ApplicationContext';
import {UserContext} from '../../../../../Context/UserContext';
import {GetImports, UploadExcel} from '../../../../../Services/UKObject';
import ImportExcel from '../../../../Common/ImportExcel/ImportExcel';
import CommonButton from "../../../../Common/Button/CommonButton";
import {FiSettings} from "react-icons/fi";

export default function ImportPictureComponent() {
  const {config, application} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [titles, setTitles] = useState([[]]);
  const [files, setFiles] = useState([[]]);
  const [loading, setLoading] = useState(false);
  const [imports, setImports] = useState([]);
  const [modalImport, setModalImport] = useState(false);
  useEffect(() => {
    if (!config || !config.uploadFile) return;
    setTitles(config.uploadFile.map((e) => e));
    setFiles(config.uploadFile.map((e) => e.files.map((elem) => null)));
  }, [config]);

  const getImports = async () => {
    let imports = await GetImports(application.apiKey, user.authToken);
    setImports(imports);
    if (!imports || imports.find((e) => e.state === 0 || e.state === '0')) {
      setTimeout(() => {
        getImports();
      }, 5000);
    }
  };

  useEffect(() => {
    if (!user || !application) return;
    getImports();
    // eslint-disable-next-line
  }, [application, user]);

  if (!config) return null;

  return (
    <>
      <div className="flex justify-end px-2 py-2 grid grid-rows-2 text-right gap-y-3">
        <CommonButton title="Importer (beta)" onClick={() => {
          setModalImport(true);
        }} icon={<FontAwesomeIcon icon={faFileExcel}/>}></CommonButton>
        <CommonButton title="Rafraîchir" onClick={async () => {
          await getImports();
        }}></CommonButton>
      </div>
      <div className="text-center mb-8">
        <p className="text-lg mt-4 font-bold underline">Historique</p>
        {imports &&
          imports.map((e, i) => {
            if (i > 3) return null;

            return (
              <div className="m-2 grid grid-cols-4 items-center border border-gray-300">
                <p className="text-sm col-span-1">{moment(e.mongoose_created_at).format('DD/MM hh:mm')}</p>
                <p className="text-base col-span-2 font-bold">{e.name}</p>
                <div style={{textAlign: '-webkit-center'}}>
                  {e.state === 1 && <FcCheckmark className="col-span-1" size={'1.2em'}/>}
                  {e.state === 0 && <ClipLoader className="col-span-1" color="orange" loading={true} size={20}/>}
                  {e.state === -1 && <FcHighPriority className="col-span-1" size={'1.2em'}/>}
                </div>
              </div>
            );
          })}
      </div>
      {titles.map((e, i) => {
        return (
          <div className="border border-gray-300 p-3 m-3">
            <div className={`grid grid-cols-${e.tint && e.tint.length}`}>
              {e.tint &&
                e.tint.map((elem, index) => {
                  return (
                    <>
                      <FileSelector
                        style={{maxWidth: 120}}
                        className="rainbow-m_auto"
                        placeholder=""
                        label={elem}
                        variant="multiline"
                        value={files[i][index]}
                        onChange={(value) => {
                          const newFiles = [...files];
                          newFiles[i][index] = value;
                          setFiles(newFiles);
                        }}
                      />
                    </>
                  );
                })}
            </div>
            <div className="flex justify-end px-2 py-2 grid grid-rows-1 text-right gap-y-3">
              <CommonButton title={loading === i ? 'Chargement ...' : 'Sauvegarder'} onClick={async () => {
                let error = false;
                files[i].forEach((elem) => (error = elem ? error : true));
                if (error)
                  return toast.error('Tous les fichiers ne sont pas importés !', {
                    autoClose: 2000,
                    position: 'bottom-center',
                  });
                setLoading(i);
                if ((await UploadExcel(e.url, files[i], e, application.apiKey, user.authToken)) === null)
                  return toast.error('Une erreur est survenue', {autoClose: 2000, position: 'bottom-center'});
                setLoading(null);
                await getImports();
                return toast.success('Mise à jour en cours', {autoClose: 2000, position: 'bottom-center'});
              }}></CommonButton>
            </div>
          </div>
        );
      })}

      <ImportExcel modalIsOpen={modalImport}/>
    </>
  );
}
