import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DocsIcon = ({doc}) => {
  const infos = doc.informations

  console.log("ici doc icon")

  return (
    <div className="h-full" style={{
      position: "relative",
      aspectRatio: '1/1',
      height: "50px",
    }}>
      {infos.image && <img style={{
        aspectRatio: '1/1',
        height: "100%",
      }} src={infos.image} alt="img" />}
      {infos.icon && <FontAwesomeIcon className="absolute top-0 left-0 right-0 bottom-0 m-auto"
                                      style={{
                                        height: "80%",
                                      }}
                                      icon={infos.icon}
                                      color={infos.iconColor} />}
    </div>
  );
}

export default DocsIcon
