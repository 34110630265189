import React, {useEffect, useState} from 'react';
import CommonButton from "../../../../Common/Button/CommonButton";
import CustomInputBox from "../../../../Common/Inputs/customInputBox.component";
import CustomColorInput from "../../../../Common/Inputs/customColorInput.component";
import CustomNumberInput from "../../../../Common/Inputs/customNumberInput.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/pro-light-svg-icons";
import {toast} from "react-toastify";
import YesNoInput from "../../../Account/Admin/Design/Component/YesNoInput";
import PictureUpdate from "../../../Account/PictureUpdate";
import {v4} from "uuid";
import {faMagnifyingGlassMinus, faMagnifyingGlassPlus} from "@fortawesome/pro-regular-svg-icons";
import {MapBuildingSelector, MapFloorSelector} from "./map.selection";
import FeatureLinksModal from "./feature.links.modal";

const FeaturePropertiesView = ({feature, categories, onSave, getZoom, mapConfig, mapDraw}) => {
  const [properties, setProperties] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [mapSelected, setMapSelected] = useState(null);
  const [mapLinkSelected, setMapLinkSelected] = useState(null);
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);

  useEffect(() => {
    if (!feature) return;
    fetchMapConfig();
  }, [feature])

  const fetchMapConfig = async () => {
    const newProperties = feature.properties || null;

    setSelectedCategory((newProperties && categories.find(c => c.id === newProperties.category)) || null)
    setProperties(newProperties);

    setMapSelected(() => {
      let newMapSelected = null;
      if (newProperties?.building && newProperties?.floor) newMapSelected = {map: newProperties?.building, floor: newProperties?.floor};
      return newMapSelected;
    });
    setMapLinkSelected(() => {
      let newMapLinkSelected = null;
      if (newProperties?.buildingLink && newProperties?.floorLink) newMapLinkSelected = {map: newProperties?.buildingLink, floor: newProperties?.floorLink};
      return newMapLinkSelected;
    });
  }

  const onCategoryChange = (value) => {
    if (value === '') {
      let newProperties = {...properties};
      delete properties.category;
      setSelectedCategory('');
      setProperties(newProperties);
      return;
    }
    const newSelectedCategory = categories.find(c => c.id === value);
    setSelectedCategory(newSelectedCategory);
    setProperties({...properties, category: newSelectedCategory.id});
  }

  const onBuildingChange = (key, value) => {
    if (key === 'building') {
      setMapSelected({map: value, floor: ''});
    }
    if (key === 'floor') {
      const newMapSelected = {...mapSelected, floor: value};
      setMapSelected(newMapSelected);
      setProperties({...properties, building: newMapSelected.map, floor: newMapSelected.floor});
    }
  }

  const onBuildingLinkChange = (key, value) => {
    if (key === 'buildingLink') {
      setMapLinkSelected({map: value, floor: ''});
    }
    if (key === 'floorLink') {
      const newMapLinkSelected = {...mapLinkSelected, floor: value};
      setMapLinkSelected(newMapLinkSelected);
      setProperties({...properties, buildingLink: newMapLinkSelected.map, floorLink: newMapLinkSelected.floor});
    }
  }

  if (!feature) return <></>
  return (
    <div className={`absolute z-10 top-2 left-0 bg-neutral-50 rounded transition-all duration-200 max-h-[500px] overflow-y-scroll overflow-x-hidden ${feature ? 'w-[300px]' : 'w-0'}`}>
      <div className="m-2">
        <p className="text-lg text-gray-700 text-left mt-1 ml-1 mb-3">Propriétés de l'objet</p>

        {/*Category*/}
        {['polygon', 'square'].findIndex(type => type === feature.properties.type) !== -1 && categories && categories.length > 0 &&
          <div className="flex gap-5 items-center justify-between mt-2 mb-2">
            <p className="ml-2 text-md text-gray-700">Catégorie:</p>
            <select
              className="h-7 ml-2 border rounded"
              value={selectedCategory ? selectedCategory.id : ''}
              onChange={(e) => {
                onCategoryChange(e.target.value)
              }}>
              <option value=''>-- Aucune catégorie --</option>
              {categories && categories.map((category, index) => {
                  return (
                    <option key={index} value={category.id}>{category.name}</option>
                  );
                }
              )}
            </select>
          </div>
        }

        {/* Building */}
        {['pattern'].findIndex(type => type === feature.properties.type) === -1 &&
          <div className="flex gap-5 items-center justify-between mt-2 mb-2">
            <p className="ml-2 text-md text-gray-700">Batiment:</p>
            <MapBuildingSelector mapSelected={mapSelected || ''} mapConfig={mapConfig} onChange={(e) => {
              onBuildingChange('building', e.target.value);
            }}/>
          </div>}

        {/* Floor */}
        {['pattern'].findIndex(type => type === feature.properties.type) === -1 &&
          <div className="flex gap-5 items-center justify-between mt-2 mb-2">
            <p className="ml-2 text-md text-gray-700">Étage:</p>
            <MapFloorSelector mapSelected={mapSelected || ''} mapConfig={mapConfig} onChange={(e) => {
              onBuildingChange('floor', e.target.value);
            }}/>
          </div>
        }

        {/* Link Selection */}
        {feature.properties.type === 'link' &&
          <div className="flex gap-5 items-center justify-between mt-2 mb-2">
            <p className="ml-2 text-md text-gray-700">Lien vers un batiment:</p>
            <select
              className="h-7 ml-2 border rounded"
              value={properties?.buildingLink || ''}
              onChange={(e) => {
                setProperties({...properties, buildingLink: e.target.value});
                const linkedFeature = mapDraw.allMap.find(f => f.id === e.target.value);
                if (!linkedFeature) return;

                linkedFeature.properties.buildingLink = feature.id;
                // mapDraw.setFeatureProperty(linkedFeature.id, 'buildingLink', feature.id);
              }}>
              <option value=''>-- Aucun lien selectionné --</option>
              {mapDraw.allMap && mapDraw.allMap.map((e, index) => {
                if (e.properties.type !== 'link' || e.id === feature.id) return null;
                return (
                  <option key={index} value={e.id}>{e.properties.name || 'Sans Nom'}</option>
                );
              })}
            </select>
          </div>}

        {/*/!* Building Link *!/*/}
        {/*{feature.properties.type === 'link' &&*/}
        {/*  <div className="flex gap-5 items-center justify-between mt-2 mb-2">*/}
        {/*    <p className="ml-2 text-md text-gray-700">Lien vers un batiment:</p>*/}
        {/*    <MapBuildingSelector mapSelected={mapLinkSelected || ''} mapConfig={mapConfig} onChange={(e) => {*/}
        {/*      onBuildingLinkChange('buildingLink', e.target.value);*/}
        {/*    }}/>*/}
        {/*  </div>}*/}

        {/*/!* Floor Link *!/*/}
        {/*{feature.properties.type === 'link' &&*/}
        {/*  <div className="flex gap-5 items-center justify-between mt-2 mb-2">*/}
        {/*    <p className="ml-2 text-md text-gray-700">lien vers un étage:</p>*/}
        {/*    <MapFloorSelector mapSelected={mapLinkSelected || ''} mapConfig={mapConfig} onChange={(e) => {*/}
        {/*      onBuildingLinkChange('floorLink', e.target.value);*/}
        {/*    }}/>*/}
        {/*  </div>*/}
        {/*}*/}


        {/*Name*/}
        {['polygon', 'square', 'poi', 'link'].findIndex(type => type === feature.properties.type) !== -1 && <CustomInputBox title="Quel est le nom de l'objet" value={properties?.name || ''} onChange={(value) => setProperties({...properties, name: value})}/>}

        {/*Color*/}
        {['polygon', 'square'].findIndex(type => type === feature.properties.type) !== -1 &&
          <CustomColorInput title="Quelle est la couleur de l'objet ?" value={properties?.objectColor || selectedCategory?.categoryColor || ''} onChange={(value) => setProperties({...properties, objectColor: value})}/>}

        {/*Height*/}
        {['polygon', 'square'].findIndex(type => type === feature.properties.type) !== -1 &&
          <CustomNumberInput title="Quelle est la hauteur de l'objet ? (En mètres)" value={properties?.objectHeight || selectedCategory?.categoryHeight || ''} onChange={(value) => setProperties({...properties, objectHeight: value})}/>}

        {/*Has interaction*/}
        {['polygon', 'square'].findIndex(type => type === feature.properties.type) !== -1 &&
          <YesNoInput title="Intéraction possible ?" yesSelected={properties?.hasInteraction !== null && properties?.hasInteraction !== undefined ? properties?.hasInteraction : selectedCategory?.hasInteraction !== null ? selectedCategory?.hasInteraction : false} onClick={(e) => {
            setProperties({...properties, hasInteraction: e === 1})
          }}/>}

        {/*Image*/}
        {['image', 'pattern'].findIndex(type => type === feature.properties.type) !== -1 && <PictureUpdate classnames="mb-2" url={properties?.url || ''} size={100} onUpdate={(url) => {
          const img = new Image();
          let image = {name: v4(), url: url};
          img.onload = () => {
            setProperties({...properties, image: image.name, url: image.url});
          };
          img.src = url;
        }} customParentClassNames="ml-2 w-max relative"
        />}

        {/*Image size*/}
        {['image', 'pattern'].findIndex(type => type === feature.properties.type) !== -1 && <CustomNumberInput title="Quelle est la taille de l'image (supérieur à 0)" value={properties?.size || ''} onChange={(value) => setProperties({...properties, size: parseFloat(value)})}/>}

        {/*Image follow camera rotation ?*/}
        {'image' === feature.properties.type &&
          <YesNoInput title="L'image tourne avec la caméra ?" yesSelected={properties?.canRotate !== null && properties?.canRotate !== undefined ? properties?.canRotate : selectedCategory?.canRotate !== null ? selectedCategory?.canRotate : false} onClick={(e) => {
            setProperties({...properties, canRotate: e === 1})
          }}/>}

        {/*Text*/}
        {'text' === feature.properties.type && <CustomInputBox title="Quel texte sera affiché ?" value={properties?.text || ''} onChange={(value) => setProperties({...properties, text: value})}/>}

        {/*Minimum zoom*/}
        {['polygon', 'square', 'text', 'image', 'poi'].findIndex(type => type === feature.properties.type) !== -1 && <div className="flex gap-2 items-end">
          <CustomNumberInput title="Zoom minimum auquel l'objet est visible" min="0" value={properties?.objectMinZoom || selectedCategory?.minZoom || ''} onChange={(value) => setProperties({...properties, objectMinZoom: value})}/>
          <CommonButton customClassName="h-10 w-8 justify-center" icon={<FontAwesomeIcon icon={faMagnifyingGlassMinus}/>} onClick={() => {
            setProperties({...properties, objectMinZoom: getZoom()})
          }}/>
        </div>}

        {/*Maximum zoom*/}
        {['polygon', 'square', 'text', 'image', 'poi'].findIndex(type => type === feature.properties.type) !== -1 && <div className="flex gap-2 items-end">
          <CustomNumberInput title="Zoom maximum auquel l'objet est visible" min="0" value={properties?.objectMaxZoom || selectedCategory?.maxZoom || ''} onChange={(value) => setProperties({...properties, objectMaxZoom: value})}/>
          <CommonButton customClassName="h-10 w-8 justify-center" icon={<FontAwesomeIcon icon={faMagnifyingGlassPlus}/>} onClick={() => {
            setProperties({...properties, objectMaxZoom: getZoom()})
          }}/>
        </div>}

        {/*Links*/}
        {['polygon', 'square'].findIndex(type => type === feature.properties.type) !== -1 && <CommonButton customClassName="w-max ml-2 mt-2" title="Gérer les liens" useAppTheme={false} customColor={'#3B82F6'} icon={<FontAwesomeIcon icon={faLink}/>} onClick={() => {
          if (!properties.name || properties.name.length === 0) return toast.error("Veuillez entrer un nom !", {autoClose: 2000});
          setLinkModalIsOpen(true)
        }}/>}
      </div>
      <CommonButton customClassName='w-max m-auto mt-2' title='Sauvegarder' useAppTheme={false} customColor={'#3B82F6'} onClick={() => {
        onSave(properties)
      }}
      />
      <span>&nbsp;</span>
      <FeatureLinksModal isOpen={linkModalIsOpen} setIsOpen={setLinkModalIsOpen} feature={feature} properties={properties} setProperties={setProperties} onSave={(properties) => {
        onSave(properties)
      }}/>
    </div>
  );
}

export default FeaturePropertiesView
