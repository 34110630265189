import { faArrowsAlt, faArrowsAltH, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { HelpText } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import AddButton from '../../../../Common/Button/AddButton';
import TrashButton from '../../../../Common/Button/TrashButton';
import { UpdateJsonConfig } from '../../../../../Services/Application';
import LinkCell from './LinksCell';

const ObjectOption = ({ defaultValue, value, setValue }) => {
  const { application, config } = useContext(ApplicationContext);

  return (
    <select
      className="border px-2 py-1 w-full bg-blue-100 text-blue-400 rounded border-blue-400"
      value={defaultValue || value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      disabled={defaultValue != null}
    >
      <option value="">Choisir</option>
      {config.customObject.map((e) => {
        return <option value={e.id}>{e.name}</option>;
      })}
      <option value="ukEvent">Événement</option>
    </select>
  );
};

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function LinkTable({ type }) {
  const { application, config, reloadConfig } = useContext(ApplicationContext);

  const [form, setForm] = useState({});

  const [newLink, setNewLink] = useState({ type1: type });

  useEffect(() => {
    setForm(config);
  }, [config]);

  const getName = (type) => {
    if (type == 'ukEvent') {
      return 'Événement';
    }
    var name = config.customObject.find((e) => e.id == type);
    if (name) {
      return name.name;
    }
    return 'objet';
  };

  const getId = () => {
    var id = randomIntFromInterval(10000, 99999) + '';
    while (config.links.find((e) => e.id == id) != null) {
      id = randomIntFromInterval(10000, 99999) + '';
    }
    return id;
  };
  const addNewLink = () => {
    if (!newLink.type1 || !newLink.type2) {
      return toast.error('Merci de remplir toutes les informations');
    }
    if (newLink.type1.length == 0 || newLink.type2.length == 0) {
      return toast.error('Merci de remplir toutes les informations');
    }
    if (newLink.type1 == newLink.type2) {
      return toast.error('Impossible de lié deux objets identiques');
    }
    var link = {
      ...newLink,
      display: 'line',
      labelType1Add: 'ADDLABEL1',
      labelType2Add: 'ADDLABEL2',
      tintWait: ' TINTWAIT ',
      type2Multiple: true,
      type1Multiple: true,
      editable: false,
      state: '0,1,2,3',
      id: getId(),
    };
    var arr = [...form.links];
    arr.push(link);
    setForm({
      ...form,
      links: arr,
    });
    
    setNewLink({ type1: type });
  };

  if (!form || !form.links) {
    return null;
  }

  return (
    <div className=" rounded mt-2 flex flex-col gap-2">
      {form.links.map((e, index) => {
        if (!type || e.type1 === type || e.type2 === type) {
          return <LinkCell link={e} index={index} form={form} setForm={setForm} />;
        }
        return null;
      })}

      <p className="text-left font-medium mt-2">Ajouter un lien</p>
      <div className="relative bg-blue-50 grid grid-cols-3 items-center gap-2 m-auto w-full justify-between border border-blue-300 px-3 rounded py-2 ">
        <div className="flex flex-col items-start h-full gap-2">
          <ObjectOption
            defaultValue={type}
            value={newLink.type1 || ''}
            setValue={(e) => {
              setNewLink({
                ...newLink,
                type1: e,
              });
            }}
          ></ObjectOption>

          {newLink.type2 != 'ukEvent' && (
            <div className=" flex  gap-2 items-center">
              <input
                className="px-1"
                value={newLink.labelType2 || ''}
                className="border bg-blue-100 border-blue-400 border rounded"
                onChange={(e) => {
                  setNewLink({
                    ...newLink,
                    labelType2: e.target.value,
                  });
                }}
              />
              <HelpText text={"Le texte affiché sur la fiche d'un " + getName(newLink.type2 || '')} />
            </div>
          )}
        </div>
        <div className="text-center">
          <FontAwesomeIcon icon={faArrowsAltH} />
        </div>
        <div className="flex flex-col items-start gap-2 h-full">
          <ObjectOption
            value={newLink.type2 || ''}
            setValue={(e) => {
              setNewLink({
                ...newLink,
                type2: e,
              });
            }}
          ></ObjectOption>
          {newLink.type1 != 'ukEvent' && (
            <div className=" flex  gap-2 items-center">
              <input
                className="px-1 bg-blue-100 border-blue-400 border rounded"
                value={newLink.labelType1 || ''}
                onChange={(e) => {
                  setNewLink({
                    ...newLink,
                    labelType1: e.target.value,
                  });
                }}
              />
              <HelpText text={"Le texte affiché sur la fiche d'un " + getName(newLink.type1 || '')} />
            </div>
          )}
        </div>
        <div className="text-center col-span-3 flex justify-end">
          <AddButton onClick={addNewLink} />
        </div>
      </div>
      <div className="text-center flex items-center justify-center mt-2 ">
        <div
          className="w-max border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
          onClick={async () => {
            await UpdateJsonConfig(application.apiKey, {
              ...config,
              links: form.links.map((e) => {
                return {
                  ...e,
                  objs: null,
                };
              }),
            });

            await reloadConfig();
            toast.success('Modification sauvegardée');
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </div>
    </div>
  );
}
