import { useContext } from 'react';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';

export default function ModuleConfigurationTextInput({ module, setModule, title, name }) {
  const { config, application } = useContext(ApplicationContext);

  if (module.type && module.type.endsWith('DETAIL')) {
    return <></>;
  }

  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">{title}</p>
      <input
        className="custominputbox"
        type="text"
        value={module[name] || ''}
        onChange={(e) => {
          let obj = { ...module };
          obj[name] = e.target.value;
          setModule(obj);
        }}
      />
    </div>
  );
}
