import DrawListEventCard from "./draw.event.card";

export default function GroupByEvent({listSorted, setModalOpen}) {
  return (
    listSorted.map((elem, index) =>
      <div key={index} className={'ml-8'}>
        <p className={'text-xl font-bold'}>{elem.value}</p>
        <DrawListEventCard list={elem.list} setModalOpen={setModalOpen} />
      </div>
    )
  )
}