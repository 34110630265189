import bbox from "@turf/bbox";
import * as turf from "@turf/turf";
import * as _ from "lodash";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import * as React from "react";

import maplibregl from "maplibre-gl";

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Map, { Layer, Source } from "react-map-gl";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { ApplicationContext } from "../../Context/ApplicationContext";
import { UserContext } from "../../Context/UserContext";
import { UKMapConfigService } from "../../Services/UKMapConfig";
import { defaultConfig } from "../Visitor/MapEditor/Map/map.const";
import { findClosestPoint, pathFinding } from "./ItinaryMap3D/best.itinary.function";
import ControlPanel from "./control.panel";
import { getHasInteraction } from "./itinerary.search";
import { layerStyle3d, layersLine } from "./layer.style3d";
import MAP_STYLE from "./map.default.style.json";
import { fillLayer, lineLayer, sfNeighborhoods } from "./mapbox.style";
import StandPopupView from "./stand.popup.view";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = "pk.eyJ1IjoicmVtaXRlbGVuY3phayIsImEiOiJjbGNhbHoyN3o2NHRiM3BrZXA5ZGR2d3VpIn0.x4UkMpsKPxM-kSDrnIn37Q"; //"pk.eyJ1Ijoibm9hLXRyYWNoZXoiLCJhIjoiY2w2bmw2eDBpMDF6MzNkcGRjaDZtNGZwMiJ9.xXrE8xnOrezkB3e_eDb_7g";
const TOKEN = "pk.eyJ1IjoicmVtaXRlbGVuY3phayIsImEiOiJjbGNhbHoyN3o2NHRiM3BrZXA5ZGR2d3VpIn0.x4UkMpsKPxM-kSDrnIn37Q"; // "pk.eyJ1Ijoibm9hLXRyYWNoZXoiLCJhIjoiY2w2bmw2eDBpMDF6MzNkcGRjaDZtNGZwMiJ9.xXrE8xnOrezkB3e_eDb_7g";

const maplibreglWithSupported = { ...maplibregl, supported: () => true };

let value = {
    ...MAP_STYLE,
    sources: {
        ...MAP_STYLE.sources,
        ["sf-neighborhoods"]: sfNeighborhoods,
    },
    layers: [
        ...MAP_STYLE.layers,
        fillLayer,
        lineLayer,
        {
            id: "background",
            type: "background",
            paint: {
                "background-color": "#000000",
            },
        },
    ],
};

function getPositionFromLongLat(center_, object_) {
    var centerCoords = mapboxgl.MercatorCoordinate.fromLngLat(center_.LngLat, 0);
    var objectCoords = mapboxgl.MercatorCoordinate.fromLngLat(object_.LngLat, 0);

    var dx = centerCoords.x - objectCoords.x;
    var dy = centerCoords.y - objectCoords.y;

    dx /= center_.scale;
    dy /= center_.scale;
    return new THREE.Vector3(-dx, 0, -dy);
}
function runByFeatures(features) {
    if (features.type !== "Feature") return undefined;
    let polygon = turf.polygon(features.geometry.coordinates);

    const unitsOption = { units: "kilometers" };
    let polyline, length, bbox, percentage1, percentage2, fraction, point1, point2, bearing;
    let point1a, point1b, point2a, point2b, line1, line2;
    let largest, diagonalLength;

    polyline = turf.polygonToLineString(polygon);
    length = turf.length(polyline, unitsOption);
    bbox = turf.bbox(polygon);
    diagonalLength = turf.length(
        turf.lineString([
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]],
        ])
    );

    const SEARCH_PARTS = 20;
    for (let i = 0; i < SEARCH_PARTS; i++) {
        percentage1 = i / SEARCH_PARTS;
        point1 = turf.along(polyline, percentage1 * length, unitsOption);

        for (let j = 1; j < SEARCH_PARTS; j++) {
            fraction = j / SEARCH_PARTS;
            percentage2 = percentage1 + fraction;

            if (percentage2 > 1) {
                percentage2 -= 1;
            }

            let distance = percentage2 * length;
            point2 = turf.along(polyline, distance, unitsOption);

            //process as side
            //get bearing between points
            bearing = turf.bearing(point1, point2);

            //create perpendicual lines at start and end
            point1a = turf.destination(point1, diagonalLength, bearing + 90, unitsOption);
            point1b = turf.destination(point1, diagonalLength, bearing - 90, unitsOption);
            line1 = turf.lineString([turf.getCoord(point1a), turf.getCoord(point1b)]);

            point2a = turf.destination(point2, diagonalLength, bearing + 90, unitsOption);
            point2b = turf.destination(point2, diagonalLength, bearing - 90, unitsOption);
            line2 = turf.lineString([turf.getCoord(point2a), turf.getCoord(point2b)]);

            //intersect by polygon (assume single parts)
            let intersect1 = turf.lineIntersect(line1, polygon);
            let intersect2 = turf.lineIntersect(line2, polygon);

            let intersectObjArray = [];
            turf.coordAll(intersect1)
                .filter((coord) => {
                    return turf.distance(point1, turf.point(coord), unitsOption) > 0.001;
                })
                .forEach((coord) => {
                    intersectObjArray.push({
                        coord: coord,
                        origin: turf.getCoord(point1),
                        opp: turf.getCoord(point2),
                        oppLine: line2,
                        p: point1,
                        op: point2,
                    });
                });

            turf.coordAll(intersect2)
                .filter((coord) => {
                    return turf.distance(point2, turf.point(coord), unitsOption) > 0.001;
                })
                .forEach((coord) => {
                    intersectObjArray.push({
                        coord: coord,
                        origin: turf.getCoord(point2),
                        opp: turf.getCoord(point1),
                        oppLine: line1,
                        p: point2,
                        op: point1,
                    });
                });

            if (intersectObjArray.length === 0) continue;

            intersectObjArray.forEach((obj, k) => {
                const { origin, coord, opp, oppLine, p, op } = obj;
                let dest = turf.destination(turf.point(coord), turf.distance(p, op, unitsOption), turf.bearing(p, op), unitsOption);

                //form rectangle and add to dest
                let coords = [origin, opp, turf.getCoord(dest), coord, origin];

                let rectangle = turf.polygon([[...coords]]);

                //all 4 coordinates must be within the polygon
                //what has been sliced by polygon should be empty
                if (!turf.booleanContains(polygon, rectangle) || turf.flatten(turf.difference(rectangle, polygon) || turf.featureCollection([])).features.length > 0) {
                    return;
                }

                if (largest === undefined || turf.area(rectangle) > turf.area(largest)) {
                    largest = rectangle;
                }
            });
        }
    }

    return largest;
}
export const MapView = ({}) => {
    const { application, reloadApplication } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const mapRef = useRef(null);
    const service = useMemo(() => new UKMapConfigService((application || {}).apiKey, (user || {}).authToken), [application, user]);
    const [mapConfig, setMapConfig] = useState(null);
    const color = useRef("#000000");
    const [featuresAll, setFeaturesAll] = useState([]);
    const [features, setFeatures] = useState([]);
    const [currentFloor, setCurrentFloor] = useState({ building: null, floor: null });
    const [path, setPath] = useState({});
    const [step, setStep] = useState(0);
    const location = useLocation();
    const [selectedFeature, setSelectedFeature] = useState(null);
    const markerRef = useRef([]);

    const createMarker = (isStart, point) => {
        const el = document.createElement("div");
        const width = 20;
        const height = 20;
        el.className = "marker";
        el.style.backgroundImage = isStart ? `url(/icons/start.png)` : `url(/icons/end.png)`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = "100%";

        const marker = new mapboxgl.Marker({ element: el, offset: [isStart ? 0 : 6, -10] }).setLngLat(point).addTo(mapRef.current.getMap());
        markerRef.current.push(marker);
    };

    const lines = { type: "FeatureCollection", features: [path[step]?.path].filter((e) => e) };
    if (path[step]?.path) {
        for (const el of markerRef.current) {
            el.remove();
        }
        const tmp = path[step].path.geometry.coordinates;
        const fp = tmp[0];
        const sp = tmp[tmp.length - 1];
        createMarker(true, fp);
        createMarker(false, sp);
    } else {
        for (const el of markerRef.current) {
            el.remove();
        }
    }
    const [buildingsInfos, setBuildingsInfos] = useState({
        collections: null,
        batCoordAndId: null,
    });
    const [filter, setFilter] = useState("");
    const [filteredBuilding, setFilterBuilding] = useState(null);
    const [interactivity, setInteractivity] = useState({ isInteract: new URLSearchParams(location.search).get("interact"), done: false });

    function zoomToTarget(feature) {
        console.log("ZOOM TO TARGET");
        if (!mapRef.current) return;
        const w = window.innerWidth;
        let pad = 300;
        if (w < 600) {
            pad = 70;
        }
        const [minLng, minLat, maxLng, maxLat] = bbox(feature);
        mapRef.current.fitBounds(
            [
                [minLng, minLat],
                [maxLng, maxLat],
            ],
            { padding: pad, duration: 1000 }
        );
    }

    useEffect(() => {
        if (path[step]?.path) {
            const feature = path[step].path;
            setCurrentFloor({ building: feature.properties?.building, floor: feature.properties?.floor });
            zoomToTarget(path[step].path);
        }
    }, [path, step]);

    useEffect(() => {
        if (!featuresAll?.features) return;
        setBatInfos();
    }, [featuresAll]);

    useEffect(() => {
        if (!filter || !filter.trim().length) return setFilterBuilding(null);
        const filteredFeatures = featuresAll?.features
            ?.filter((e) => {
                console.log(e);
                console.log(mapConfig.geoJSON);
                return getHasInteraction(e, mapConfig);
            })
            .filter((e) => {
                var j = JSON.stringify(e);
                console.log(e);
                return e.geometry.type === "Polygon" && (e.properties?.name?.toLowerCase().includes(filter.toLowerCase()) || j.toLowerCase().includes(filter.toLowerCase()));
            });
        setFilterBuilding(filteredFeatures);
    }, [filter]);

    const cancelPathfinding = () => {
        setPath({});
        setStep(0);
    };

    const nextStep = () => {
        let newStep = step + 1;
        if (newStep >= path.length) {
            newStep = 0;
        }
        setStep(newStep);
    };

    const previousStep = () => {
        let newStep = step - 1;
        if (newStep < 0) {
            newStep = path.length - 1;
        }
        setStep(newStep);
    };

    const startPathfinder = (itinerary) => {
        if (!itinerary.first || !itinerary.second) return false;
        itinerary.first = featuresAll.features.find((e) => e.id === itinerary.first.properties.id);
        if (!itinerary.first) {
            toast.error("Objet introuvable", { autoClose: 2000 });
            return false;
        }

        try {
            const sp = findClosestPoint(itinerary.first, mapConfig.geoJSON);
            const fp = findClosestPoint(itinerary.second, mapConfig.geoJSON);
            return pathFinding([fp, sp], JSON.parse(JSON.stringify(mapConfig.geoJSON)), (e) => {
                let newValue = e;
                for (const el of newValue) {
                    el.path.properties.type = "route";
                }
                setPath(e);
            });
        } catch {
            toast.error("Aucun chemin trouvé");
        }
    };

    const onClick = (event) => {
        const feature = event.features[0];

        if (!feature) {
            setSelectedFeature({});
            return;
        }

        //zoomToTarget(feature)
        if (!getHasInteraction(feature, mapConfig)) {
            setSelectedFeature({});
            return;
        }
        zoomToTarget(feature);
        setSelectedFeature(feature);
        //window.ReactNativeWebView.postMessage(JSON.stringify({success: "messagesend"}));
    };

    useEffect(() => {
        if (!application) {
            reloadApplication(window.location.hostname);
        } else {
            service.get().then((config) => {
                setMapConfig(config);
                value.layers[value.layers.length - 1].paint["background-color"] = config.config.backgroundColor || defaultConfig.backgroundColor;

                setFeaturesAll(config.geoJSON);
            });
        }
    }, [application]);

    useEffect(() => {
        if (!featuresAll) return;

        setFeatures({
            type: "FeatureCollection",
            features: (
                featuresAll?.features?.filter((f) => {
                    if (currentFloor && currentFloor.building && f.properties.building === currentFloor.building)
                        return f.properties.floor == currentFloor.floor && f.properties.building == currentFloor.building;
                    return f.properties.floor == "ext" || !f.properties.floor;
                }) || []
            ).map((e) => {
                return {
                    ...e,
                    type: "Feature",
                    properties: {
                        ...e.properties,
                        id: e.id,
                        floorNumber: e.properties.floor ? parseInt(e.properties.floor === "ext" ? 0 : e.properties.floor) : undefined,
                        objectHeight: parseInt(e.properties.objectHeight) || parseInt(getCategoryProperty(e?.properties?.category || null, "categoryHeight")) || undefined,
                    },
                };
            }),
        });
    }, [featuresAll, currentFloor]);

    useEffect(() => {
        if (!featuresAll || interactivity.done) return;
        if (featuresAll.length <= 0) return;
        const isInteractivity = interactivity.isInteract;
        if (!isInteractivity) return;
        const feature = featuresAll.features.find((e) => e.id === interactivity.isInteract);
        if (!feature) return;

        if (mapRef.current) {
            setSelectedFeature(feature);
            zoomToTarget(feature);

            setCurrentFloor({ floor: feature.properties.floor, building: feature.properties.building });
            setInteractivity({ isInteract: true, done: true });
        }
    }, [mapRef.current, featuresAll]);

    if (!mapConfig) return null;

    function setBatInfos() {
        const batCoord = featuresAll?.features?.filter((e) => e.properties.floor === "ext" && e.geometry.type === "Polygon");
        const batCoordAndId = [];
        let collections;

        for (const el of batCoord) {
            if (!el.properties.building) {
                continue;
            }
            const exist = batCoordAndId.find((e) => e.id === el.properties.building);
            if (!exist) {
                batCoordAndId.push({
                    id: el.properties.building,
                    coordinates: el.geometry.coordinates,
                });
            } else {
                const tab = exist.coordinates[0];
                tab.splice(tab.length - 2, 0, ...el.geometry.coordinates[0]);
            }
        }

        for (const el of batCoordAndId) {
            const pol = turf.polygon(el.coordinates);
            const center = turf.centerOfMass(pol);
            el.coordinates = center.geometry.coordinates;
        }

        collections = turf.featureCollection(batCoordAndId.map((e) => turf.point(e.coordinates)));

        setBuildingsInfos({ collections, batCoordAndId });
    }

    const focusBulding = (lat, long, zoom) => {
        console.log("FOCUS BULDING");
        if (path.length) return;
        if (selectedFeature?.properties && !interactivity.done && interactivity.isInteract) return;
        if (zoom <= 16.8) {
            if (currentFloor.building) {
                setCurrentFloor({ building: null, floor: null });
            }
            return;
        }
        const target = turf.point([long, lat]);
        if (buildingsInfos.collections.features.length <= 0) return;
        const nearest = turf.nearestPoint(target, buildingsInfos.collections);
        const nearestBuilding = buildingsInfos.batCoordAndId.find(
            (e) => e.coordinates[0] === nearest.geometry.coordinates[0] && e.coordinates[1] === nearest.geometry.coordinates[1]
        );

        if (selectedFeature?.properties && (selectedFeature?.properties?.floor ?? "0") !== currentFloor.floor && currentFloor.building === nearestBuilding.id) {
            setCurrentFloor({ building: nearestBuilding.id, floor: selectedFeature?.properties?.floor ?? "0" });
            return;
        }

        if (currentFloor.building !== nearestBuilding.id) {
            setCurrentFloor({ building: nearestBuilding.id, floor: selectedFeature?.properties?.floor ?? "0" });
        }
    };

    const modelOrigin = mapConfig.config.viewport.center;
    const modelAltitude = 0;
    const modelRotate = [Math.PI / 2, 0, 0];

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

    // transformation parameters to position, rotate and scale the 3D model onto the map
    const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since the 3D model is in real world meters, a scale transform needs to be
         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
         */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };

    const getCategoryProperty = (category, property) => {
        if (!category || !property) return null;
        if (!mapConfig.categories) return null;
        //console.log(mapConfig.categories.find(cat => cat.id === category)[property])
        return mapConfig.categories.find((cat) => cat.id === category)[property];
    };

    const customLayer = {
        id: "3d-model",
        type: "custom",
        renderingMode: "3d",
        onAdd: function (map, gl) {
            this.camera = new THREE.Camera();
            this.scene = new THREE.Scene();

            const directionalLight = new THREE.DirectionalLight(0xffffff);
            directionalLight.position.set(0, -70, 100).normalize();
            this.scene.add(directionalLight);
            const directionalLight2 = new THREE.DirectionalLight(0xffffff);
            directionalLight2.position.set(0, 70, 100).normalize();
            this.scene.add(directionalLight2);

            const _this = this;
            new FontLoader().load("https://threejs.org/examples/fonts/helvetiker_regular.typeface.json", function (font) {
                const tmp = {
                    type: "FeatureCollection",
                    features: featuresAll?.features?.map((e) => {
                        return {
                            ...e,
                            properties: {
                                ...e.properties,
                                id: e.id,
                                floorNumber: parseInt(e.properties.floor == "ext" ? 0 : e.properties.floor || 0),
                                objectHeight: parseInt(e.properties.objectHeight) || undefined,
                            },
                        };
                    }),
                };

                const filteredFeatures = tmp.features.filter((f) => f.geometry.type === "Polygon");

                for (const f of filteredFeatures) {
                    try {
                        let coordinate = f.geometry.coordinates[0];

                        const testtt = runByFeatures(f);

                        const envelop = turf.envelope(testtt.geometry);
                        coordinate = envelop.geometry.coordinates[0];
                        coordinate = coordinate.map((c) => getPositionFromLongLat({ LngLat: modelOrigin, scale: modelTransform.scale }, { LngLat: c }));

                        const box = new THREE.Box3();
                        const name = _.deburr(f?.properties?.name || "");

                        box.setFromPoints(coordinate);

                        const sizeBox = { x: box.max.x - box.min.x, y: box.max.y - box.min.y, z: box.max.z - box.min.z };
                        const pos = getPositionFromLongLat({ LngLat: modelOrigin, scale: modelTransform.scale }, { LngLat: envelop.geometry.coordinates[0][0] });
                        const text = new TextGeometry(_.deburr(f?.properties?.name || ""), {
                            font: font,
                            size: 20,
                            height: 0,
                            curveSegments: 12,
                            bevelEnabled: false,
                            bevelThickness: 1,
                            bevelSize: 1,
                            bevelOffset: 0,
                            bevelSegments: 1,
                        });
                        const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
                        const cubeText = new THREE.Mesh(text, material);

                        const box3Helper = new THREE.Box3Helper(box, 0xff0000);
                        box3Helper.material.linewidth = 10;
                        cubeText.position.set(
                            pos.x,
                            (f?.properties?.objectHeight || parseInt(getCategoryProperty(f?.properties?.category || null, "categoryHeight") || 0)) + 0.01,
                            pos.z
                        );
                        cubeText.rotateX(Math.PI / -2);

                        box3Helper.position.set(
                            pos.x,
                            (f?.properties?.objectHeight || parseInt(getCategoryProperty(f?.properties?.category || null, "categoryHeight") || 0)) + 0.01,
                            pos.z
                        );
                        box3Helper.rotateX(Math.PI / -2);

                        const boundingBox = new THREE.Box3().setFromObject(cubeText);
                        const sizeFont = { x: boundingBox.max.x - boundingBox.min.x, y: boundingBox.max.y - boundingBox.min.y, z: boundingBox.max.z - boundingBox.min.z };
                        const ration = { x: sizeFont.x / sizeBox.x, y: 0, z: sizeFont.z / sizeBox.z };
                        const ratioMax = (1 / Math.max(ration.x, ration.z)) * 0.9;
                        cubeText.scale.set(ratioMax, ratioMax, 1);

                        const height = sizeFont.z * ratioMax;
                        cubeText.translateY(sizeBox.z / 2 - height / 2);
                        cubeText.translateX(sizeBox.x * 0.05);

                        cubeText.properties = f?.properties;

                        _this.scene.add(cubeText);
                    } catch (e) {
                        console.log(e);
                    }
                }
            });

            this.map = map;
            this.renderer = new THREE.WebGLRenderer({
                canvas: map.getCanvas(),
                context: gl,
                antialias: true,
            });
            this.renderer.autoClear = false;
        },

        render: function (gl, matrix) {
            const children = [...this.scene.children];
            let value = null;
            setCurrentFloor((prevState) => {
                value = prevState;
                return prevState;
            });

            this.scene.children = this.scene.children.filter((e) => {
                if (!value) return;
                if (!e.properties) return e;
                if (!e.properties.floor || !e.properties.building) return e;
                if (e.properties.floor == "ext" && e.properties.building != value.building) return e;
                if (value.floor == e.properties.floor && value.building == e.properties.building) return e;
            });

            const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelTransform.rotateX);
            const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransform.rotateY);
            const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransform.rotateZ);

            const m = new THREE.Matrix4().fromArray(matrix);
            const l = new THREE.Matrix4()
                .makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ)
                .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
                .multiply(rotationX)
                .multiply(rotationY)
                .multiply(rotationZ);

            this.camera.projectionMatrix = m.multiply(l);
            this.renderer.resetState();
            this.renderer.render(this.scene, this.camera);
            this.map.triggerRepaint();
            this.scene.children = children;
        },
    };
    const onLoad = (map) => {
        mapRef.current.loadImage("https://docs.mapbox.com/mapbox-gl-js/assets/colorado_flag.png", (err, image) => {
            // Throw an error if something goes wrong.
            if (err) throw err;

            // Add the image to the map style.
            mapRef.current.addImage("pattern", image);
        });

        //mapRef.current.addLayer({ id: "coconutz", type: "symbol", layout: { "icon-elevation": "100.00" } });

        mapRef.current.getMap().addLayer(customLayer);

        if (features && features.features) {
            features.features.map((feature) => {
                if (!feature.properties.image) return;
                mapRef.current.loadImage(feature.properties.url, (error, image) => {
                    if (error) throw error;
                    mapRef.current.addImage(feature.properties.image, image);
                });
            });
        }
    };

    return (
        <div className="relative mobile-height">
            <Map
                attributionControl={false}
                minZoom={16}
                maxZoom={22}
                onLoad={onLoad}
                antialias={true}
                onMove={(evt) => {
                    focusBulding(evt.viewState.latitude, evt.viewState.longitude, evt.viewState.zoom);
                }}
                ref={mapRef}
                initialViewState={{
                    latitude: mapConfig.config.viewport.center[1],
                    longitude: mapConfig.config.viewport.center[0],
                    zoom: mapConfig.config.viewport.zoom,
                    pitch: 45,
                }}
                mapStyle={value}
                interactiveLayerIds={["room-extrusion"]}
                // mapLib={maplibreglWithSupported}
                className={"h-screen w-screen"}
                onClick={onClick}
                mapboxAccessToken={TOKEN}
            >
                <Source id="my-data" type="geojson" data={features}>
                    {layerStyle3d.map((layer, index) => (
                        <Layer key={index} {...layer} />
                    ))}
                </Source>
                <Source id="my-itinary" type="geojson" data={lines}>
                    {layersLine.map((layer, index) => (
                        <Layer key={index} {...layer} />
                    ))}
                </Source>
            </Map>
            <ControlPanel
                zoomToTarget={zoomToTarget}
                setSelectedFeature={setSelectedFeature}
                filteredBuilding={filteredBuilding}
                filter={filter}
                setFilter={setFilter}
                mapConfig={mapConfig}
                setCurrentFloor={setCurrentFloor}
                currentFloor={currentFloor}
                cancelPathfinding={cancelPathfinding}
                nextStep={nextStep}
                previousStep={previousStep}
                path={path}
            />
            <StandPopupView feature={selectedFeature} mapConfig={mapConfig} startPathfinder={startPathfinder} setSelectedFeature={setSelectedFeature} />
        </div>
    );
};
