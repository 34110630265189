import * as turf from "@turf/turf";
import * as d3 from "d3-geo";
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContext";
import { UserContext } from "../../Context/UserContext";
import { UKMapConfigService } from "../../Services/UKMapConfig";

let sizeFeature = {};
export const Map2dView = () => {
    const { application, reloadApplication } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const canvasRef = useRef(null);
    const service = useMemo(() => new UKMapConfigService(application?.apiKey, user?.authToken), [application, user]);
    const [mapConfig, setMapConfig] = useState(null);
    const [geoJSON, setGeoJSON] = useState(null);
    const [zoom, setZoom] = useState(18); // Définissez votre état de zoom initial
    const [isDragging, setIsDragging] = useState(false);
    const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
    const [canvasSize, setCanvasSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    // Détecter les changements de taille du canevas et mettre à jour l'état
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            // Vous pouvez également ajouter un event listener pour le redimensionnement si nécessaire
            setCanvasSize({ width: canvas.width, height: canvas.height });
        }
    }, [canvasRef]);

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setLastPosition({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleMouseMove = (event) => {
        // console.log(event.clientX, event.clientY);
        if (isDragging) {
            const scaleAdjustment = Math.sqrt(zoom) / zoom; // Cette formule peut être ajustée selon le comportement souhaité
            const dx = (event.clientX - lastPosition.x) * scaleAdjustment;
            const dy = (event.clientY - lastPosition.y) * scaleAdjustment;

            setLastPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const currentTranslate = projection.translate();
            const newTranslate = [currentTranslate[0] + dx, currentTranslate[1] + dy];
            projection.translate(newTranslate);

            // Redessiner la carte avec la nouvelle projection
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            drawGeoJSON(context, geoJSON, projection);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleWheel = (event) => {
        event.preventDefault(); // Empêcher le scroll de la page
        const { deltaY } = event;
        // Déterminer la direction du scroll et ajuster le niveau de zoom
        // deltaY < 0 signifie scroll vers le haut, deltaY > 0 signifie scroll vers le bas
        setZoom((zoom) => (deltaY < 0 ? zoom + 0.25 : zoom - 0.25));
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.addEventListener("wheel", handleWheel);
            canvas.addEventListener("mousedown", handleMouseDown);
            canvas.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp); // Attachez à window pour gérer le mouseup même en dehors du canevas
        }
        return () => {
            if (canvas) {
                canvas.removeEventListener("wheel", handleWheel);
                canvas.removeEventListener("mousedown", handleMouseDown);
                canvas.removeEventListener("mousemove", handleMouseMove);
            }
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [lastPosition, isDragging]); // Assurez-vous d'inclure les bonnes dépendances

    const projection = useMemo(() => {
        if (!mapConfig) return null;

        const scale = (256 / Math.PI) * Math.pow(2, zoom); // 'zoomLevel' est votre niveau de zoom désiré, similaire à Mapbox

        const { width, height } = canvasSize;
        const mapCenter = mapConfig.config.viewport.center;

        return (
            d3
                .geoStereographic()
                .rotate([-mapCenter[0], -mapCenter[1]])
                // .scale((width / 2 / Math.PI) * zoom)
                .scale(scale)
                .translate([width / 2, height / 2])
        );
    }, [mapConfig, zoom, canvasSize]);

    // const [projection, setProjection] = useState(null);

    useEffect(() => {
        if (!application) {
            reloadApplication(window.location.hostname);
        } else {
            service.get().then((config) => {
                setMapConfig(config);
                setGeoJSON(config.geoJSON);
            });
        }
    }, [application, service]);

    const calculatePolygonWidth = (polygon, projection) => {
        // On suppose que 'polygon' est un tableau de coordonnées [longitude, latitude].
        const xs = polygon.map(([lng, lat]) => {
            const [x, y] = projection([lng, lat]);
            return x;
        });

        const xMin = Math.min(...xs);
        const xMax = Math.max(...xs);

        return xMax - xMin;
    };

    const calculatePolygonArea = (polygon) => {
        // On suppose que 'polygon' est un tableau de coordonnées [longitude, latitude].
        // On suppose que le dernier point est le même que le premier point.
        // On suppose que les points sont dans le sens des aiguilles d'une montre.
        const numPoints = polygon.length;
        let area = 0; // Accumulateur

        for (let i = 0; i < numPoints - 1; i++) {
            const [lng1, lat1] = polygon[i];
            const [lng2, lat2] = polygon[i + 1];
            area += (lng2 - lng1) * (lat2 + lat1) * 0.5;
        }
        return Math.abs(area);
    };

    function runByFeatures(features) {
        if (features.type !== "Feature") return undefined;
        let polygon = turf.polygon(features.geometry.coordinates);

        const unitsOption = { units: "kilometers" };
        let polyline, length, bbox, percentage1, percentage2, fraction, point1, point2, bearing;
        let point1a, point1b, point2a, point2b, line1, line2;
        let largest, diagonalLength;

        polyline = turf.polygonToLineString(polygon);
        length = turf.length(polyline, unitsOption);
        bbox = turf.bbox(polygon);
        diagonalLength = turf.length(
            turf.lineString([
                [bbox[0], bbox[1]],
                [bbox[2], bbox[3]],
            ])
        );

        const SEARCH_PARTS = 20;
        for (let i = 0; i < SEARCH_PARTS; i++) {
            percentage1 = i / SEARCH_PARTS;
            point1 = turf.along(polyline, percentage1 * length, unitsOption);

            for (let j = 1; j < SEARCH_PARTS; j++) {
                fraction = j / SEARCH_PARTS;
                percentage2 = percentage1 + fraction;

                if (percentage2 > 1) {
                    percentage2 -= 1;
                }

                let distance = percentage2 * length;
                point2 = turf.along(polyline, distance, unitsOption);

                //process as side
                //get bearing between points
                bearing = turf.bearing(point1, point2);

                //create perpendicual lines at start and end
                point1a = turf.destination(point1, diagonalLength, bearing + 90, unitsOption);
                point1b = turf.destination(point1, diagonalLength, bearing - 90, unitsOption);
                line1 = turf.lineString([turf.getCoord(point1a), turf.getCoord(point1b)]);

                point2a = turf.destination(point2, diagonalLength, bearing + 90, unitsOption);
                point2b = turf.destination(point2, diagonalLength, bearing - 90, unitsOption);
                line2 = turf.lineString([turf.getCoord(point2a), turf.getCoord(point2b)]);

                //intersect by polygon (assume single parts)
                let intersect1 = turf.lineIntersect(line1, polygon);
                let intersect2 = turf.lineIntersect(line2, polygon);

                let intersectObjArray = [];
                turf.coordAll(intersect1)
                    .filter((coord) => {
                        return turf.distance(point1, turf.point(coord), unitsOption) > 0.001;
                    })
                    .forEach((coord) => {
                        intersectObjArray.push({
                            coord: coord,
                            origin: turf.getCoord(point1),
                            opp: turf.getCoord(point2),
                            oppLine: line2,
                            p: point1,
                            op: point2,
                        });
                    });

                turf.coordAll(intersect2)
                    .filter((coord) => {
                        return turf.distance(point2, turf.point(coord), unitsOption) > 0.001;
                    })
                    .forEach((coord) => {
                        intersectObjArray.push({
                            coord: coord,
                            origin: turf.getCoord(point2),
                            opp: turf.getCoord(point1),
                            oppLine: line1,
                            p: point2,
                            op: point1,
                        });
                    });

                if (intersectObjArray.length === 0) continue;

                intersectObjArray.forEach((obj, k) => {
                    const { origin, coord, opp, oppLine, p, op } = obj;
                    let dest = turf.destination(turf.point(coord), turf.distance(p, op, unitsOption), turf.bearing(p, op), unitsOption);

                    //form rectangle and add to dest
                    let coords = [origin, opp, turf.getCoord(dest), coord, origin];

                    let rectangle = turf.polygon([[...coords]]);

                    //all 4 coordinates must be within the polygon
                    //what has been sliced by polygon should be empty
                    if (!turf.booleanContains(polygon, rectangle) || turf.flatten(turf.difference(rectangle, polygon) || turf.featureCollection([])).features.length > 0) {
                        return;
                    }

                    if (largest === undefined || turf.area(rectangle) > turf.area(largest)) {
                        largest = rectangle;
                    }
                });
            }
        }

        return largest;
    }

    const drawCenteredTextInPolygon = (ctx, feature, polygonn, text) => {
        // console.log(feature);

        // if (sizeFeature[feature.id]) {
        //     ctx.font = `${sizeFeature[feature.id].w}px sans-serif`;
        //     ctx.fillStyle = "black";
        //     ctx.fillText(text, sizeFeature[feature.id].x, sizeFeature[feature.id].y);
        //     return;
        // }
        const testtt = sizeFeature[feature.id]?.rbf || runByFeatures(feature);
        let polygon = testtt.geometry.coordinates[0];
        polygon.pop();

        let sumX = 0,
            sumY = 0,
            numPoints = 0;
        polygon.forEach(([lng, lat]) => {
            const [x, y] = projection([lng, lat]);
            sumX += x;
            sumY += y;
            numPoints++;
        });

        const centerX = sumX / numPoints;
        const centerY = sumY / numPoints;

        var w = sizeFeature[feature.id]?.w || 40;


        ctx.font = `${w}px sans-serif`; // Commencez avec une taille de police de base
        ctx.fillStyle = "black";
        let textSize = ctx.measureText(text);

        const polygonWidth = calculatePolygonWidth(polygon, projection); // Pour les Polygones simples

        // Ajuster la taille de la police si nécessaire
        let i = 0;
        while (textSize.width > polygonWidth) {
            // console.log(i++);
            // Vous devez calculer polygonWidth en fonction de votre polygone
            // Réduire la taille de la police
            // ctx.font = ...;
            w -= 1;
            ctx.font = `${w}px sans-serif`; // Commencez avec une taille de police de base
            textSize = ctx.measureText(text);
        }

        ctx.fillStyle = "black";
        ctx.fillText(text, centerX - textSize.width / 2, centerY);
        sizeFeature[feature.id] = { w: w, text: text, x: centerX - textSize.width / 2, y: centerY, rbf: testtt };
    };

    const drawGeoJSON = (ctx, geoJSON) => {
        const newProjection = projection;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        var allCat = [];

        var allText = [];

        geoJSON.features.forEach((feature) => {
            if (feature.properties.name == "Vestiaire") {
            }
            if (feature.geometry.type == "Point") {
                return;
            }
            ctx.beginPath();
            var keys = Object.keys(feature.properties);
            // console.log("Keys,", keys);
            for (let k of keys) {
                if (allCat.indexOf(k) == -1) {
                    allCat.push(k);
                }
            }
            var points = [];
            feature.geometry.coordinates.forEach((polygon) => {
                polygon.forEach((coordinate, index) => {
                    const [x, y] = newProjection(coordinate);
                    points.push([x, y]);
                    if (index === 0) ctx.moveTo(x, y);
                    else ctx.lineTo(x, y);
                });
            });
            if (feature.properties.name) {
                allText.push({ name: feature.properties.name, points: points });
            }
            ctx.closePath();
            ctx.fillStyle = feature.properties.objectColor || feature.properties.categoryColor;
            ctx.fill();
        });

        geoJSON.features.forEach((feature) => {
            if (feature.properties.name) {
                drawCenteredTextInPolygon(ctx, feature, feature.geometry.coordinates[0], feature.properties.name);
            }
        });

        // geoJSON.features.forEach((feature) => {
        //     // Parcourir chaque point des coordonnées de la feature
        //     // console.log("------");
        //     if (feature.properties.name == "Vestiaire") {
        //     }
        //     if (feature.geometry.type == "Point") {
        //         return;
        //     }
        //     if (!feature.properties.name) {
        //         return;
        //     }
        //     console.log("--------");
        //     console.log("--------");
        //     console.log("--------");
        //     console.log("--------");
        //     console.log("FEATURE", feature.properties.name);
        //     console.log("FEATURE", feature);
        //     const centroid = d3.geoCentroid(feature);
        //     console.log(centroid);
        //     const [x, y] = projection(centroid);
        //     const name = feature.properties.name;
        //     var w = 100;
        //     ctx.font = `${w}px sans-serif`; // Commencez avec une taille de police de base
        //     ctx.fillStyle = "black";
        //     let textSize = ctx.measureText(name);
        //     const polygonWidth = calculatePolygonWidth(feature.geometry.coordinates[0], projection); // Pour les Polygones simples

        //     // Ajuster la taille de la police si nécessaire
        //     while (textSize.width > polygonWidth) {
        //         // Vous devez calculer polygonWidth en fonction de votre polygone
        //         // Réduire la taille de la police
        //         // ctx.font = ...;
        //         w--;
        //         ctx.font = `${w}px sans-serif`; // Commencez avec une taille de police de base
        //         textSize = ctx.measureText(name);
        //     }

        //     console.log("TExT SIZE", textSize, w, x, y);
        //     // Dessiner le nom au centroid du polygone
        //     ctx.fillText(name, x - textSize.width / 2, y + textSize.actualBoundingBoxAscent / 2);
        // });

        // console.log("ALL CATS", allCat);
    };

    const redrawAll = () => {
        const canvas = canvasRef.current;

        if (canvas && geoJSON && projection) {
            const context = canvas.getContext("2d");
            drawGeoJSON(context, geoJSON);
        } else {
            console.log("NOT HERE", canvas, geoJSON, projection);
        }
    };
    useEffect(() => {
        redrawAll();
    }, [geoJSON, mapConfig, projection]);

    // if (!mapConfig) return null;

    return (
        <div className="relative mobile-height">
            <canvas
                ref={canvasRef}
                width={window.innerWidth}
                height={window.innerHeight}
                className="w-screen h-screen "
                style={{
                    backgroundColor: mapConfig?.mapConfig?.backgroundColor,
                }}
            />
        </div>
    );
};
