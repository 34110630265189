import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../Context/UserContext";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import {GetObjectsAll} from "../../../Services/UKObject";
import {ClipLoader} from "react-spinners";
import {UKObjectClass} from "../../../Class/UKObjectClass";
import moment from "moment";
import DrawListEventCard from "./draw.event.card";
import GroupByEvent from "./group_by_event_iframe";
import TableIframeObject from "../Object/table.iframe.object";
import ModalIframe from "../modal.iframe";
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import CalendarDrawEvent from "./Calendar/calendar.draw.event";
import SearchFilterIframe from "../Common/search.filter.iframe";

function EventIframeComponent({params}) {
  const objectType = 'ukEvent';
  const {application, config, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [objectList, setObjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchField, setSearchField] = useState('');
  const [newObjectList, setNewObjectList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const needSearch = params.get('search') === 'true';
  const needFilter = params.get('filter') === 'true';
  const needGroup = params.get('split_by_date') === 'true';
  const styleToDisplay = params.get('display');
  const listToDisplay = (searchField !== '' || filterList.length > 0) ? newObjectList : objectList;
  const [modalOpen, setModalOpen] = useState({open: false, info: null})

  const getObject = async () => {
    const newObjs = await GetObjectsAll(objectType, application.apiKey, (user || {}).authToken);
    let result = newObjs.map(elem => {
      return (new UKObjectClass(elem, config))
    });
    result = result.sort((a, b) => a.getInformations('dateDebut', langKey) > b.getInformations('dateDebut', langKey))
    setObjectList(result);
    getFilter();
    setIsLoading(false);
  };

  const getFilter = () => {
    const objectConfig = config.eventObject || [];
    const resp = objectConfig.custom.filter(elem => elem.filter === true && elem.values?.length > 0);
    setFilter(resp);
  }

  const getSplitByDate = (listToSplit) => {
    if (!needGroup) {
      return;
    }
    const list = [...listToSplit];
    let value = list.map(elem => moment(elem.getInformations('dateDebut', langKey)).format('LL'));
    value = [...new Set(value)];

    const result = value.map(elem => {
      const list = objectList.filter(e => {
        const values = moment(e.getInformations('dateDebut', langKey)).format('LL');
        return values === elem;
      });
      if (list.length === 0)
        return;
      return ({value: elem, list: list});
    }).filter(elem => elem !== undefined);

    return result;
  }

  const reloadWithFilter = () => {
    let result = [...objectList];
    if (searchField !== '') {
      result = objectList.filter(elem => elem.getInformations('title', langKey).toLowerCase().includes(searchField.toLowerCase()));
    }
    if (filterList.length > 0) {
      for (const i of filterList) {
        const withLang = `${i.name}${langKey}`
        result = (result.filter(elem => {
          const compare = elem.json.informations[i.name];
          return (i.value === compare);
        }))
      }
    }
    setNewObjectList(result);
  }

  const newFilter = (bool, cat, value) => {
    let list = [...filterList];
    if (bool) {
      list.push({name: cat, type: 'reg', value: value});
    } else {
      list = list.filter(elem => {
        return !((cat === elem.name) && (value === elem.value));
      });
    }
    setFilterList(list);
  }

  useEffect(() => {
    getObject();
  }, []);

  useEffect(() => {
    reloadWithFilter();
  }, [searchField, filterList]);

  if (isLoading) {
    return (
      <div className={'m-5'}>
        <ClipLoader loading={true}/>
      </div>
    )
  }

  if ((!objectList && !newObjectList) || (objectList.length === 0 && newObjectList.length === 0)) {
    return (
      <div className={'bg-gray-100 min-h-screen pt-3 flex justify-center'}>
        <p className={'text-2xl mt-5 font-semibold'}>Il n'y a aucun objet</p>
      </div>
    )
  }

  if (styleToDisplay === 'calendar') {
    return (
      <div className={'bg-gray-100 min-h-screen pt-3 flex justify-center'}>
        <ModalIframe modalIsOpen={modalOpen} setmodalIsOpen={setModalOpen} />
        <CalendarDrawEvent config={config} objectList={objectList} setmodalIsOpen={setModalOpen}/>
      </div>
    )
  }
  return (
    <div className={'bg-gray-100 min-h-screen pt-3 flex justify-center'}>
      <ModalIframe modalIsOpen={modalOpen} setmodalIsOpen={setModalOpen}/>
      <SearchFilterIframe newFilter={newFilter} searchField={searchField} needFilter={needFilter}
                          needSearch={needSearch} filter={filter} setSearchField={setSearchField} />
      <div className={'mt-8 w-2/3'}>
        {styleToDisplay === 'table' ?
          <TableIframeObject list={listToDisplay} objectType={objectType} setModalOpen={setModalOpen}/>
          :
          needGroup ?
            <div className={'flex flex-col flex-wrap'}>
              <GroupByEvent listSorted={getSplitByDate(listToDisplay)} setModalOpen={setModalOpen}/>
            </div>
            :
            <DrawListEventCard list={listToDisplay} setModalOpen={setModalOpen}/>
        }
      </div>
    </div>
  )
}

export default EventIframeComponent;