import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import SwitchCustom from '../SwitchCustom';
import SetDataLink from './SetData/SetDataLinks';

export default function ImportExcelSetData({ allData, selectType, setLinkData }) {
  const { config } = useContext(ApplicationContext);
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);
  const [object, setObject] = useState([]);
  const [links, setLinks] = useState([]);
  const [tmpLinkData, setTmpLinkData] = useState({});

  useEffect(() => {
    setHeader(allData[0]);
    var arr = [...allData];
    arr.splice(0, 1);
    setData(arr);
  }, [allData]);

  useEffect(() => {
    var configTMP = config.customObject.find((e) => e.id == selectType);
    if (configTMP) {
      setObject(configTMP);
    }
    var links = config.links.filter((e) => e.type1 == selectType || e.type2 == selectType);
    setLinks(links);
  }, [selectType]);

  useEffect(() => {
    /* Auto sync name / excel */
    if (object && object.custom && header) {
      // console.log(object.custom);
      // console.log(header);
      var tmpLinkData = {};
      for (var i = 0; i < object.custom.length; i++) {
        var id = object.custom[i].id;
        var name = object.custom[i].tint;
        // console.log(id);
        // console.log(name);
        var index = header.indexOf(name);
        // console.log('INDEX ' + index);
        if (index >= 0) {
          // console.log('ici ?');
          tmpLinkData = {
            ...tmpLinkData,
            [id]: {
              identifier: false,
              index: index,
              separator: '',
            },
          };
        }
      }
      setTmpLinkData(tmpLinkData);
    }
  }, [object, header]);
  // console.log(tmpLinkData);

  if (!object || !object.custom) {
    return null;
  }
  return (
    <div className="text-gray-700">
      <div className="grid grid-cols-1 gap-2 mt-3 ">
        <div>
          <p className="bg-blue-100 border border-blue-300 text-blue-400 px-2 rounded-t">{object.name}</p>
          {object.custom.map((e, index) => {
            return (
              <div
                className={
                  'border-l border-r border-b border-gray-300 px-2 py-1 flex justify-between items-center  ' +
                  (index == object.custom.length - 1 ? ' rounded-b' : '')
                }
              >
                <p className="w-1/3">{e.tint}</p>
                <FontAwesomeIcon icon={faArrowRight} />
                <div className="flex gap-2 items-center">
                  <div className="flex flex-col">
                    <select
                      value={(tmpLinkData[e.id] || { index: '' }).index || ''}
                      onChange={(t) => {
                        setTmpLinkData({
                          ...tmpLinkData,
                          [e.id]: {
                            identifier: (tmpLinkData[e.id] || {}).identifier || false,
                            index: t.target.value,
                            separator: (tmpLinkData[e.id] || {}).separator || '',
                          },
                        });
                      }}
                      className="border-gray-300 rounded border px-2 py-1 bg-gray-100"
                    >
                      <option value={''}>Choisir</option>
                      {header.map((h, index) => {
                        return <option value={index}>{h}</option>;
                      })}
                    </select>
                    {e.type == 'multipleSelector' && (
                      <div className="flex mt-2">
                        <p>Séparateur : </p>
                        <input
                          className="px-2 w-16 bg-gray-100 border-gray-300 border rounded"
                          value={(tmpLinkData[e.id] || {}).separator || ''}
                          onChange={(t) => {
                            setTmpLinkData({
                              ...tmpLinkData,
                              [e.id]: {
                                identifier: (tmpLinkData[e.id] || {}).identifier || false,
                                index: (tmpLinkData[e.id] || {}).index,
                                separator: t.target.value,
                              },
                            });
                          }}
                        ></input>
                      </div>
                    )}
                  </div>
                  <p className="flex items-center gap-2 text-sm mt-2">
                    Identifiant
                    <SwitchCustom
                      checked={(tmpLinkData[e.id] || { identifier: false }).identifier || false}
                      setChecked={() => {
                        var newData = {
                          ...tmpLinkData,
                        };
                        for (const [key, value] of Object.entries(newData)) {
                          newData[key].identifier = false;
                        }
                        newData[e.id] = {
                          ...newData[e.id],
                          identifier: true,
                        };
                        setTmpLinkData(newData);
                      }}
                    ></SwitchCustom>
                  </p>
                </div>
              </div>
            );
          })}

          {links &&
            links.map((e, index) => {
              return (
                <SetDataLink
                  link={e}
                  selectType={selectType}
                  index={index}
                  object={object}
                  tmpLinkData={tmpLinkData}
                  setTmpLinkData={setTmpLinkData}
                  header={header}
                />
              );
            })}
        </div>
        <div
          className={
            'bg-blue-100 text-center rounded border border-blue-300 text-blue-400  hover:bg-blue-50 cursor-pointer '
          }
          onClick={() => {
            setLinkData(tmpLinkData);
          }}
        >
          Suivant <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}
