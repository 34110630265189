import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FileSelector } from 'react-rainbow-components';
import * as XLSX from 'xlsx';
import { ApplicationContext } from '../../../Context/ApplicationContext';

export default function ImportExcelStepOne({ setData }) {
  const [files, setFiles] = useState([]);
  const handleChange = (e) => {
    setFiles(e);

    // console.log(e);

    var files = e,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      // console.log(e);
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setData(dataParse);
      // console.log(dataParse);
    };
    reader.readAsBinaryString(f);
  };

  return (
    <div className="my-3">
      <FileSelector
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        label="Selectionner un fichier XLXS"
        placeholder="Glissez déposez ou cliquez pour parcourir"
        bottomHelpText="Selectionnez uniquement un fichier"
        onChange={handleChange}
      />
    </div>
  );
}
