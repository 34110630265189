import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { ApplicationContext } from "../../../../../../Context/ApplicationContext";
import { UserContext } from "../../../../../../Context/UserContext";
import { GetObjectsAll } from "../../../../../../Services/UKObject";

export default function ModuleConfigurationFilterGroup({ module, setModule, className = "" }) {
    const { application } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        async function initGroups() {
            if (!application || !user) return null;
            const groups = await GetObjectsAll("ukGroup", application.apiKey, user.authToken);
            setGroups(groups);
        }
        initGroups();
    }, [application, user]);

    const typeValid = ["CUSTOMPAGE", "CUSTOMOBJECTLIST", "USERSLIST", "EVENT"];
    if (typeValid.indexOf(module.type) === -1) {
        return <></>;
    }

    return (
        <div className={className}>
            <p className="font-medium text-sm text-left mb-2">Voulez-vous filtrer votre module par groupes ?</p>
            <Select
                placeholder="Selectionner"
                isMulti={true}
                value={
                    groups && module.groupFilter
                        ? groups
                              .filter((e) => {
                                  let values = module.groupFilter.split(",");
                                  return values.find((el) => el === e.informations.index);
                              })
                              .map((e) => {
                                  return { value: e.informations.index, label: e.informations.name };
                              })
                        : null
                }
                options={groups?.map((e) => {
                    return { value: e.informations.index, label: e.informations.name };
                })}
                onChange={(a, b) => {
                    setModule({ ...module, groupFilter: a.map((e) => e.value.toString()).join(",") });
                }}
            />
        </div>
    );
}
