import moment from "moment";
import {Button} from "react-rainbow-components";
import React from "react";

export default function DayButtonDraw({eventDays, calendarRef}) {
  if (!eventDays || eventDays.length <= 1) {
    return null;
  }
  return (
    <div className="rainbow-p-vertical_large rainbow-align-content_center rainbow-flex_wrap">
      {eventDays
        .sort((a, b) => {
          const dateA = moment(a, "MMM Do YY");
          const dateB = moment(b, "MMM Do YY");
          return dateA - dateB;
        })
        .map((e, i) => {
          let date = moment(e, "MMM Do YY").format('dddd DD MMMM').toString();
          date = date.charAt(0).toUpperCase() + date.slice(1);
          return (
            <Button
              key={i}
              label={date}
              variant="border-filled"
              style={{
                color: '#413751',
              }}
              className={'rainbow-m-around_medium mx-2 mb-3'}
              onClick={() => {
                calendarRef.current.getInstance().setDate(new Date(moment(e, "MMM Do YY")));
              }}
            />
          );
        })}
    </div>
  )
}