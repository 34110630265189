import { faCloudUploadAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Input } from 'react-rainbow-components';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import UploadFileModal from '../../../../../UploadFileDocument/UploadFileModal';
import YesNoInput from '../../Design/Component/YesNoInput';

export default function ModuleConfigurationUrl({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);
  const [modalUpload, setModalUpload] = useState(false);

  var typeValid = ['WEBVIEW', 'PUB'];
  if (typeValid.indexOf(module.type) == -1) {
    return <></>;
  }
  return (
    <div className={className}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quelle est l'URL ou vous souhaitez rediriger l'utilisateur ?</p>
        <div className="flex items-center gap-2">
          <input
            className="custominputbox w-full"
            type="text"
            value={module.url}
            onChange={(e) => {
              setModule({ ...module, url: e.target.value });
            }}
          ></input>
          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              setModalUpload(true);
            }}
          >
            <FontAwesomeIcon icon={faCloudUploadAlt} />
          </div>

          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              window.open(module.url, '_blank');
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </div>
        </div>
      </div>

      <YesNoInput
        title={"Voulez vous rediriger l'utilisation dans l'application ou sur son navigateur ?"}
        yesSelected={module.inApp === 'true'}
        onClick={(e) => setModule({ ...module, inApp: e == 1 ? 'true' : 'false' })}
        yesTitle="Dans l'application"
        noTitle="Sur son navigateur"
      />

      <UploadFileModal
        modalIsOpen={modalUpload}
        setModalIsOpen={setModalUpload}
        onSelectFile={(url) => {
          setModalUpload(false);
          setModule({ ...module, url: url });
        }}
      />
    </div>
  );
}
