import React, { useEffect, useState } from "react";
import ImageStyleSelector from "./ImageStyles/imagestyle.selector";

const DocsSettingsImage = ({ doc, setDocSelected, menuOpen }) => {
  const [icon, setIcon] = useState(doc.informations.icon || null);
  const [image, setImage] = useState(doc.informations.image || null);

  useEffect(() => {
    setIcon(doc.informations.icon || null);
    setImage(doc.informations.image || null);
  }, [doc._id]);

  const changeIcon = (newIcon) => {
    setIcon(() => {
      return newIcon;
    });
    setDocSelected((prevDoc) => {
      let newDoc = { ...prevDoc };
      newDoc.informations.icon = newIcon;
      return newDoc;
    });
  };

  const changeImage = (url, style) => {
    setImage(() => {
      return url;
    });
    setDocSelected((prevDoc) => {
      let newDoc = { ...prevDoc };
      newDoc.informations.image = url;
      newDoc.informations.imageStyle = style;
      return newDoc;
    });
  };

  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">Quel format souhaitez-vous pour l'image de votre document ?</p>
      <ImageStyleSelector
        doc={doc}
        icon={icon}
        image={image}
        menuOpen={menuOpen}
        setIcon={changeIcon}
        setImage={changeImage}
        setDocSelected={setDocSelected}
      />
    </div>
  );
};

export default DocsSettingsImage
