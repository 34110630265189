import { useContext } from 'react';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import YesNoInput from '../../Design/Component/YesNoInput';
import StyleViewComponent from '../../Design/Templates/StyleViewComponent';

export default function ModuleConfigurationEvent({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);

  var typeValid = ['EVENT'];
  if (typeValid.indexOf(module.type) == -1) {
    return <></>;
  }
  return (
    <div className={className}>
      <YesNoInput
        title={'Voulez-vous dissocier vos évènements par date ?'}
        yesSelected={module.splitByDate == 'true'}
        onClick={(index) => {
          setModule({ ...module, splitByDate: index == 1 ? 'true' : 'false' });
        }}
      />
      <StyleViewComponent clickedData={module} setClickedData={setModule} />
    </div>
  );
}
