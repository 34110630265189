import Modal from "react-modal";
import CloseButton from "./Button/CloseButton";
import React from "react";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 20,
    height: '70%',
    width: '90%',
    zIndex: 100,
  },
};

const secondStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 20,
    width: '90%',
    zIndex: 100,
  },
};

const CustomModal = ({title, isOpen, onClose, children, withoutHeight = true}) => {
  return (
    <Modal style={withoutHeight ? customStyles : secondStyle} isOpen={isOpen} ariaHideApp={false}>
      <div className="flex flex-col items-center">
        <div className="text-left w-full">
          <p className="font-bold text-xl">{title}</p>
        </div>
        <CloseButton
          className="absolute top-2 right-2"
          onClick={onClose}
        />
        {children}
      </div>
    </Modal>
  );
}

export default CustomModal
