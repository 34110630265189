import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import CommonButton from "../../Common/Button/CommonButton";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { useContext, useEffect, useState } from "react";
import VoteCellInterface from "./votes.cell.interface.view";

const VotesCell = ({voteInfo, onClick, isSelected, updateVoteInfo, deleteVote, saveVoteInfo}) => {
  const {langKey} = useContext(ApplicationContext);
  const [pageState, setPageState] = useState(0)

  useEffect(() => {
    if (!isSelected)
      setPageState(0);
  }, [isSelected]);

  return (
    <div className={`flex w-full overflow-hidden ${!isSelected ? "border-2 rounded-md" : ""}`}>
      <div className={`bg-gray-100 flex justify-between transition-all duration-200 ${isSelected ? "w-full min-w-[200px] max-w-[25vw] border-t-2 border-l-2 border-b-2 rounded-tl-md rounded-bl-md h-max" : "w-full"}`}>
        <div className={`bg-blue-500 rounded-l transition-all duration-300 ${isSelected ? "w-[10px]" : "w-[0px]"}`}>&nbsp;</div>
        <div className="w-full h-full cursor-pointer">
          <div onClick={() => {
            onClick()
          }}>
            <div className="bg-neutral-50 flex w-full justify-between overflow-hidden">
              <p className={`text-left ml-5 m-2 text-lg max-w-[100ch] overflow-hidden break-words ${!voteInfo.informations["title" + langKey] || !voteInfo.informations.title ? "italic" : ""}`}>
                {voteInfo.informations["title" + langKey] || voteInfo.informations.title || "Sans titre"}
              </p>
              <FontAwesomeIcon className={`m-3 transition-all duration-300 ${isSelected ? "transform rotate-180" : ""}`} icon={faChevronLeft} />
            </div>

            <div>
              <p className="text-left ml-5 text-sm">Du: {voteInfo.informations?.startDate || "-"}</p>
              <p className="text-left ml-5 text-sm">Au: {voteInfo.informations?.endDate || "-"}</p>
            </div>
          </div>

          <div className={`flex justify-around overflow-hidden ${isSelected ? "h-full mt-5 mb-2" : "h-[0px]"}`}>
            <CommonButton customClassName="" useAppTheme={false} customColor={"#F87171"} title="Supprimer" onClick={() => {
              deleteVote()
            }} />
            <CommonButton customClassName="" useAppTheme={false} title="Résulats" onClick={() => {
              setPageState(pageState !== 2 ? 2 : 0);
            }} />
          </div>
        </div>
      </div>
      <div className={`transition-all duration-100 overflow-hidden ${isSelected ? "border-2 rounded-tr-md rounded-br-md rounded-bl-md w-full" : "w-[0px]"}`}>
        <div className={` ${isSelected ? "h-full" : "h-[0px]"}`}>
          <VoteCellInterface voteInfo={voteInfo} updateVoteInfo={updateVoteInfo} pageState={pageState} setPageState={setPageState} saveVoteInfo={saveVoteInfo} />
        </div>
      </div>
    </div>
  );
}

export default VotesCell
