import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import { GetObjectsAll } from "../../../../../../Services/UKObject";
import { UserContext } from "../../../../../../Context/UserContext";

export default function ModuleConfigurationDocDetail({module, setModule, className = ''}) {
  const {config, application, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [docList, setDocList] = useState(null);

  useEffect(() => {
    getDocs();
  }, [config, module]);

  const getDocs = async () => {
    if (!config) return;
    const docsID = config.docs.map(doc => doc.id);
    let docs = [];
    for (const id of docsID) {
      let newDocs = await GetObjectsAll(id, application.apiKey, (user || {}).authToken);
      if (newDocs && newDocs.length > 0) {
        newDocs = newDocs?.filter(newDoc => newDoc.informations.type === 'obj' || newDoc.informations.type === "pdf");
        if (newDocs && newDocs.length > 0) {
          docs.push(...newDocs.map(doc => {
            return {label: doc.informations[`title${langKey}`] || doc.informations.title || "", value: {url: id, id: doc._id}}
          }));
        }
      }
    }
    docs.unshift({value: null, label: "-- Aucun --"});
    setDocList(docs);
  }

  const typeValid = ['DOCDETAIL'];
  if (typeValid.indexOf(module.type) === -1) {
    return <></>;
  }

  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Quel document souhaitez-vous afficher ?</p>
      <div style={{maxWidth: 300}}>
        <Select
          className="w-full"
          value={docList?.find((e) => e.value?.id === module.docDetail?.id) || null}
          options={docList || []}
          onChange={(e) => {
            setModule({...module, docDetail: e.value});
          }}
        />
      </div>
    </div>
  );
}
