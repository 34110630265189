const URL = 'https://usekey-localization.herokuapp.com';

export const GetLocalization = async (apiKey, authToken, id) => {
  try {
    const response = await fetch(`${URL}/application/${id}`, {
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};

export const UpdateLocalization = async (apiKey, authToken, id, object) => {
  try {
    const response = await fetch(`${URL}/application/${id}`, {
      method: 'POST',
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({arr: object}),
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    // console.log(json)
    return json;
  } catch (e) {
    return null;
  }
};
