import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFoundComponent from '../../Components/Other/NotFoundComponent';
import { ApplicationContext } from '../../Context/ApplicationContext';
import BilletterieView from '../Billetterie/BilletterieView';
import InvitationComponent from '../Invitation/Invitation';
import LoginComponent from '../Login/LoginComponent';
import VisioGlobe from '../VisioGlobe/VisioGlobe';
import VisitorComponent from '../Visitor/VisitorComponent';
import EventkeyView from "../Visitor/EventKey/eventkey.view";

function MainApplicationComponent() {
  let {path, params} = useRouteMatch();
  const {application, reloadApplication} = useContext(ApplicationContext);

  useEffect(() => {
    reloadApplication(window.location.hostname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (application == null) {
    return <p></p>;
  }

  return (
    <div>
      <Helmet>
        <title>{application.name}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <LoginComponent />
        </Route>
        <Route path={`${path}billetterie`}>
          <BilletterieView />
        </Route>
        <Route path={`${path}visioglobe`}>
          <VisioGlobe />
        </Route>
        <Route path={`${path}invitation/:id`}>
          <InvitationComponent />
        </Route>
        <Route path={`${path}v`}>
          <VisitorComponent />
        </Route>
        <Route component={NotFoundComponent}></Route>
      </Switch>
    </div>
  );
}

export default MainApplicationComponent;
