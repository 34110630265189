import React, { useEffect } from 'react'
import { useContext, useState } from "react"
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import _ from "lodash"
import { toast } from 'react-toastify';
import { UpdateApplication } from '../../../../../Services/Application';
import { UserContext } from '../../../../../Context/UserContext';
import CommonButton from "../../../../Common/Button/CommonButton";

function InputsGrid({ name, placeholder, inputValues, id, size = "1", setfct }) {
    return (
        <>
            <p className="text-left ml-3 w-full col-span-3">{name}</p>
            <input
                type="text"
                placeholder={placeholder}
                value={_.get(inputValues, id)}
                onChange={(elem) => {
                    setfct({ ..._.set(inputValues, id, elem.target.value) });
                }}
                className={`col-span-${size} text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline`}>
            </input>
        </>
    );
}

export default function AdminComponent() {
    const { application, config, reloadConfig } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [inputValues, setInputValues] = useState({});

    useEffect(
        () => {
            setInputValues(application.informations);
        }
        , [application]);

    const saveAdminSettings = async () => {
        await UpdateApplication(application.apiKey, user.authToken, application._id, inputValues);
        await reloadConfig();
        toast.success("Modifications sauvegardées !", { autoClose: 2000, position: "bottom-center" });
    };

    return (
        <div className="overflow-y-auto">
            <p>apiKey : {application.apiKey}</p>
            <form className="grid-cols-8 inline-grid w-full gap-3 p-3">
                <InputsGrid
                    name="Sous domaine :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"sousdomaine"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="onesignal (apiKey) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"onesignal.apiKey"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="onesignal (appID) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"onesignal.appId"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="xconfig (twitter_consumer) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"xconfig.twitterconsumer"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="xconfig (twitter_secret) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"xconfig.twittersecret"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="xconfig (branch_live) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"xconfig.branchlive"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="xconfig (branch_test) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"xconfig.branchtest"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="xconfig (branch url default) :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"xconfig.branchurldefault"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="Lien Android :"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"linkAndroid"}
                    setfct={setInputValues}
                />
                <InputsGrid
                    name="Lien iOS"
                    placeholder="Exemple"
                    size="5"
                    inputValues={inputValues}
                    id={"linkIOS"}
                    setfct={setInputValues}
                />
            </form>
            <div className="float-right px-4">
              <CommonButton title="Sauvegarder" onClick={saveAdminSettings}></CommonButton>
            </div>
        </div>
    )
}
