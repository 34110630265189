import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import Modal from "react-modal";
import CloseButton from "../../Common/Button/CloseButton";
import YesNoInput from "../../Visitor/Account/Admin/Design/Component/YesNoInput";
import CommonButton from "../../Common/Button/CommonButton";

export default function IframeObjectModal({modalIsOpen, setmodalIsOpen}) {
  const params = useParams();
  const {config, application} = useContext(ApplicationContext);
  const [customs, setCustoms] = useState([]);
  const [iframeConfig, setIframeConfig] = useState({
    display: 'card',
    searchBar: false,
    filter: false,
    sort_by: undefined,
    group_by: undefined
  })
  const canGroupBy = customs?.filter(elem => (elem.type === 'selector') && !!elem.values);
  const canFilter = customs?.filter(elem => (elem.type !== 'link' && elem.type !== 'mapLink')); //TODO: mapLink

  useEffect(() => {
    if (!params || !config) return;
    const type = params.typeobject;
    const objConfig = config.customObject.find((e) => e.id === type);
    if (!objConfig) {
      setCustoms([]);
      return;
    }
    setCustoms(objConfig.custom);
  }, [params, config]);


  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const chooseSort = (props, elem) => {
    if (props.target.checked) {
      setIframeConfig({...iframeConfig, sort_by: elem.id});
    } else {
      setIframeConfig({...iframeConfig, sort_by: undefined});
    }
  }

  const chooseGroup = (props, elem) => {
    if (props.target.checked) {
      setIframeConfig({...iframeConfig, group_by: elem.id});
    } else {
      setIframeConfig({...iframeConfig, group_by: undefined});
    }
  }

  const pushIframe = () => {
    window.open(window.location.protocol + "//" +
      window.location.host + "/iframe/object/" + params.typeobject + "?apikey=" + application.apiKey + "&search=" +
      iframeConfig.searchBar + "&filter=" + iframeConfig.filter + "&sort_by=" + iframeConfig.sort_by
      + "&group_by=" + iframeConfig.group_by + "&display=" + iframeConfig.display, '_blank');
  }

  //ariaHideApp={false} supress react warning when open modal
  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <div className="h-full w-full">
        <CloseButton
          className="float-right"
          onClick={() => {
            setmodalIsOpen(false);
          }}
        />
        <div>
          <div className="flex-col">
            <p className="font-bold text-xl text-gray-800 mb-2">Paramètre de la page</p>
            <div className={'flex w-full'}>
              <div>
                <YesNoInput
                  title={"Style d'affichage :"}
                  yesSelected={iframeConfig.display === 'table'}
                  yesTitle={'Tableau'}
                  noTitle={'Card'}
                  onClick={(index) => {
                    setIframeConfig({...iframeConfig, display: index === 1 ? 'table' : 'card'});
                  }}
                />
                <YesNoInput
                  title={"Afficher la barre de recherche ?"}
                  yesSelected={iframeConfig.searchBar}
                  onClick={(index) => {
                    setIframeConfig({...iframeConfig, searchBar: index === 1});
                  }}
                />
                <YesNoInput
                  title={"Afficher les filtres ?"}
                  yesSelected={iframeConfig.filter}
                  onClick={(index) => {
                    setIframeConfig({...iframeConfig, filter: index === 1});
                  }}
                />
                <p>Trier par : </p>
                {canFilter.map((elem, index) =>
                  <div key={index} className="flex items-center mr-4">
                    <input type="checkbox" className="accent-blue-500" checked={iframeConfig.sort_by === elem.id}
                           onChange={(props) => chooseSort(props, elem)} />
                    <label className="ml-2 font-light">{elem.tint}</label>
                  </div>)}
                {canGroupBy.length > 0 &&
                  <div>
                    <p>Grouper par : </p>
                    {canGroupBy.map((elem, index) =>
                      <div key={index} className="flex items-center mr-4">
                        <input type="checkbox" className="accent-blue-500" checked={iframeConfig.group_by === elem.id}
                               onChange={(props) => chooseGroup(props, elem)} />
                        <label className="ml-2 font-light">{elem.tint}</label>
                      </div>)}
                  </div>
                }
              </div>
              <div className={'w-full'}>
                <iframe className="ml-2 w-full h-[50vh]"
                        src={window.location.protocol + "//" + window.location.host + "/iframe/object/" + params.typeobject
                          + "?apikey=" + application.apiKey + "&search=" + iframeConfig.searchBar + "&filter=" +
                          iframeConfig.filter + "&sort_by=" + iframeConfig.sort_by + "&group_by="
                          + iframeConfig.group_by + "&display=" + iframeConfig.display} />
              </div>
            </div>
          </div>
        </div>
        <div className={'flex justify-end mt-2'}>
          <CommonButton title={'Créer'} customClassName="h-8 w-max"
                        onClick={() => pushIframe()} />
        </div>
      </div>
    </Modal>
  );
}
