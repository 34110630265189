import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseButton from '../../../../Common/Button/CloseButton';
import home from './CreateModule.json';

export default function CreateModuleModalComponent({ isOpen, onUpdate = (newData = {}) => null }) {
  const [page, setPage] = useState(1);
  const [maxItems, setMaxItems] = useState();
  const [homeData, setHomeData] = useState();
  const [homeSelected, setHomeSelected] = useState(null);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) setMaxItems(2);
      else if (768 <= window.innerWidth && window.innerWidth < 992) setMaxItems(4);
      else if (992 <= window.innerWidth && window.innerWidth < 1200) setMaxItems(6);
      else setMaxItems(8);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!maxItems) return;
    let data = [];
    for (let i = maxItems * (page - 1); i < maxItems * page && i < home.length; i++) {
      const e = home[i];
      data.push(e);
    }
    setHomeData(data);
  }, [page, maxItems, isOpen]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 20,
      height: '70%',
      width: '90%',
    },
  };

  return (
    <>
      <Modal style={customStyles} isOpen={isOpen}>
        <CloseButton
          className="float-right"
          onClick={() => {
            setPage(1);
            onUpdate(null);
          }}
        />
        <div className="flex justify-center items-center">
          <p className="text-lg font-medium">Quel type de module voulez-vous ajouter ?</p>
        </div>
        <div className="grid grid-cols-3 gap-3 py-2">
          <div className="flex flex-col overflow-scroll gap-3">
            {home.map((e) => {
              return (
                <div
                  onClick={() => {
                    setHomeSelected(e);
                  }}
                  className="bg-gray-100 border border-gray-400 text-gray-500 font-bold rounded px-2 hover:bg-gray-200 cursor-pointer"
                >
                  <p>{e.name}</p>
                </div>
              );
            })}
          </div>
          {homeSelected && (
            <div className="col-span-2 text-center px-5 flex flex-col items-center gap-4">
              <p className="text-2xl font-bold ">{homeSelected.name}</p>
              <p className="text-lg font-light text-left ">{homeSelected.description}</p>
              <div
                className=" gap-2 flex items-center justify-center w-max border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
                onClick={() => {
                  setPage(1);
                  onUpdate({ type: homeSelected.id });
                }}
              >
                Ajouter
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

/*
        <div style={{ height: '90%' }} className="items-center justify-center">
          <div>
            <div className={`grid grid-cols-${maxItems / 2 || 1} gap-x-2 gap-y-4 mt-3`}>
              {homeData &&
                homeData.map((e) => {
                  return (
                    <ModuleCardComponent
                      element={e}
                      onSelect={() => {
                        setPage(1);
                        onUpdate({ type: e.id });
                      }}
                    />
                  );
                })}
            </div>
            <Pagination className="mt-3" activePage={page} onChange={(e, p) => setPage(p)} pages={Math.ceil(home.length / maxItems || 1)} />
          </div>
        </div>*/
