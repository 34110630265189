export default function CustomFormInputComponent({ children, text }) {

	return (
		<div className="text-left flex items-center justify-center mt-3">
			<label className="block text-gray-700 text-sm font-semibold w-2/3  text-right mr-3">{text}</label>
			<div className="w-full">
				{children}
			</div>
		</div>
	)
}