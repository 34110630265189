import { useContext, useEffect, useState } from "react";
import { GetLocalization, UpdateLocalization } from "../../../Services/Localization";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { useRouteMatch } from "react-router-dom";
import LocalizationSearch from "./localization.search";
import CommonButton from "../../Common/Button/CommonButton";
import { faCloudArrowUp, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocalizationTable from "./localization.table";
import Pagination from "../../Common/Pagination";

const maxPerPage = 20;
const LocalizationView = ({}) => {
  const {application, config} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const params = useRouteMatch();
  const [languages, setLanguages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [localizations, setLocalizations] = useState([]);
  const [info, setInfo] = useState({page: 0, filteredObjects: [], filters: "", maxPage: 0});

  const getLocalization = async () => {
    if (!user || !application) return;
    setIsLoading(true);
    const translations = await GetLocalization(application.apiKey, user.authToken, application._id);
    setLocalizations(translations);
    setIsLoading(false);
  }

  const getAvailableLanguages = () => {
    if (!config) return;
    let languages = [];
    config.language.forEach((e) => {
      return (
        languages.push({name: e.name, langKey: e.id})
      );
    })
    setLanguages(languages);
  }

  const getFilteredObjects = (objects) => {
    if (!info.filters && info.filters.length === 0) return objects;
    return objects.filter(e => {
      for (const languagesKey in languages)
        if (e.informations[languages[languagesKey].langKey].toLowerCase().includes(info.filters.toLowerCase()) ||
          e.key.toLowerCase().includes(info.filters.toLowerCase()))
          return true
      return false
    })
  }

  const updateLocalizations = async () => {
    setIsSaving(true);
    await UpdateLocalization(application.apiKey, user.authToken, application._id, localizations);
    setIsSaving(false);
  }

  useEffect(() => {
    const newFilteredObjects = getFilteredObjects(localizations);
    setInfo({
      ...info,
      filteredObjects: newFilteredObjects,
      maxPage: Math.ceil(newFilteredObjects?.length / maxPerPage),
    })
  }, [info.filters, localizations]);

  useEffect(() => {
    getAvailableLanguages();
    getLocalization();
  }, [params]);

  return (
    <div>
      <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Traductions</p>
      <div className="w-11/12 m-auto">
        <div className="flex justify-between items-center">
          <LocalizationSearch info={info} setInfo={setInfo} />
          <CommonButton customClassName="h-8 ml-3"
                        icon={isSaving ? <FontAwesomeIcon icon={faCloudArrowUp} size="1x" bounce /> : <FontAwesomeIcon icon={faFloppyDisk} />}
                        title={isSaving ? "Sauvegarde en cours" : "Sauvegarder"}
                        onClick={() => {
                          if (isSaving) return;
                          updateLocalizations()
                        }} />
        </div>
        <div className="flex justify-center mt-3">
          <LocalizationTable languages={languages}
                             localizations={info.filteredObjects.slice(info.page * maxPerPage, info.page * maxPerPage + maxPerPage)}
                             setLocalizations={setLocalizations}
                             updateLocalizations={updateLocalizations}
                             loading={isLoading}
                             isSaving={isSaving} />
        </div>
        <Pagination
          nbPages={info.maxPage}
          page={info.page}
          total={info.maxPage}
          fetch={(value) => {
            setInfo({
              ...info,
              page: value,
            });
          }} />
      </div>
    </div>
  )

}

export default LocalizationView
