import React from "react";
import IconPicker from "../../../../Common/iconpicker.component";
import PictureUpdate from "../../../Account/PictureUpdate";
import ImageStyleFakeCell from "./imagestyle.fakecell";
import DocsSettingsIconColor from "./imagestyle.iconcolor";

const DocsCustomImage1 = (props = {}) => {
    return {
        id: "custom-image-1",
        imageStyle: "small",
        placeholder: (
            <div className="">
                <p className="text-md">Format n° 1</p>
                <span className="text-xs">Petite Image</span>
            </div>
        ),
        style: (
            <div className="space-y-3">
                <p className="font-medium text-sm text-left mb-2 mt-3">Quelle image souhaitez vous 1?</p>
                <PictureUpdate
                    url={props.image}
                    size={100}
                    onUpdate={(url) => {
                        const img = new Image();
                        img.onload = () => {
                            props.setImage(url, "small");
                            props.setIcon(null);
                        };
                        img.src = url;
                    }}
                    customParentClassNames="w-max relative"
                />
            </div>
        ),
        hovered: (
            <div className="justify-center items-center w-full h-full space-y-2">
                <ImageStyleFakeCell hasIcon={false} hasImage={true} />
                <ImageStyleFakeCell hasIcon={false} hasImage={true} />
            </div>
        ),
    };
};

const DocsCustomImage2 = (props = {}) => {
    return {
        id: "custom-image-2",
        imageStyle: "small_icon",
        placeholder: (
            <div>
                <p className="text-md">Format n° 2</p>
                <span className="text-xs">Image de fond + Icone</span>
            </div>
        ),
        style: (
            <div className="space-y-3 flex justify-between">
                <div>
                    <p className="font-medium text-sm text-left mb-2 mt-3">Quelle image souhaitez vous 2?</p>
                    <PictureUpdate
                        url={props.image}
                        size={100}
                        onUpdate={(url) => {
                            const img = new Image();
                            img.onload = () => {
                                props.setImage(url, "small");
                            };
                            img.src = url;
                        }}
                        customParentClassNames="w-max relative"
                    />
                </div>
                <div>
                    <p className="font-medium text-sm text-left mb-2">Quelle icone souhaitez vous ?</p>
                    <IconPicker
                        currentIcon={props.icon}
                        setIcon={(icon) => {
                            props.setIcon(icon);
                            props.setImage(props.image, "small");
                        }}
                        width={200}
                        height={250}
                        columns={7}
                    />
                </div>
                <DocsSettingsIconColor doc={props.doc} setDocSelected={props.setDocSelected} />
            </div>
        ),
        hovered: (
            <div className="justify-center items-center w-full h-full space-y-2">
                <ImageStyleFakeCell hasIcon={true} hasImage={true} />
                <ImageStyleFakeCell hasIcon={true} hasImage={true} />
            </div>
        ),
    };
};

const DocsCustomImage3 = (props = {}) => {
    return {
        id: "custom-image-3",
        imageStyle: "large",
        placeholder: (
            <div>
                <p className="text-md">Format n° 3</p>
                <span className="text-xs">Grande image</span>
            </div>
        ),
        style: (
            <div className="space-y-3">
                <p className="font-medium text-sm text-left mb-2 mt-3">Quelle image souhaitez vous 3?</p>
                <PictureUpdate
                    url={props.image}
                    size={100}
                    onUpdate={(url) => {
                        const img = new Image();
                        img.onload = () => {
                            props.setImage(url, "large");
                            props.setIcon(null);
                        };
                        img.src = url;
                    }}
                    customParentClassNames="w-max relative"
                />
            </div>
        ),
        hovered: (
            <div
                className="justify-center items-center h-full space-y-2"
                style={{
                    padding: "0.2em",
                }}
            >
                <img
                    src="https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        backgroundColor: "blue",
                    }}
                />
                <img
                    src="https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
                    style={{
                        objectFit: "contain",
                        width: "100%",
                    }}
                />
            </div>
        ),
    };
};

const DocsCustoms = [DocsCustomImage1, DocsCustomImage2, DocsCustomImage3];

export default DocsCustoms;
