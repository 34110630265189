import { faEye } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/fr';
import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../Context/UserContext';
import { CreateBillet, UpdateBillet } from '../../../../../Services/Billetterie';
import AddButton from '../../../../Common/Button/AddButton';
import CloseButton from '../../../../Common/Button/CloseButton';
import SelectLangInline from '../../../../Common/SelectLangInline';
import TicketStyle from './TicketStyle';
import CustomModal from "../../../../Common/CustomModal.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faFrame} from "@fortawesome/pro-light-svg-icons";
import {toast} from "react-toastify";
export default function TicketDetailComponent({ ticket, onReload }) {
  const { application, config, langKey } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [form, setForm] = useState({});
  const [canUpdate, setCanUpdate] = useState(true);
  const [configType, setConfigType] = useState(null);
  const [ticketStyleOpen, setTicketStyleOpen] = useState(false);
  const [iFrameModalIsOpen, setIFrameModalIsOpen] = useState(false);

  useEffect(() => {
    if (ticket) {
      setForm(ticket);
    } else {
      setForm({});
    }
  }, [ticket]);

  useEffect(() => {
    if (form.customId) {
      setConfigType(
        config.inscription.customs.find((e) => {
          return e.id == form.customId;
        })
      );
    } else {
      setConfigType(null);
    }
  }, [form]);

  const createTicket = async () => {
    console.log(form);
    if (form._id) {
      await UpdateBillet(form._id, form, application.apiKey, user.autToken);
      onReload();
    } else {
      await CreateBillet(form, application.apiKey, user.autToken);
      onReload();
    }
  };
  return (
    <div>
      <div className="grid grid-cols-1  px-2 mt-4">
        <p className="font-bold text-xl text-gray-700 mb-2">
          {ticket && ticket._id && "Modification d'un billet"}
          {(!ticket || !ticket._id) && "Création d'un billet"}
        </p>
        <div className='flex gap-2'>
          <AddButton title="Modifier le visuel" icon={faEye} onClick={() => setTicketStyleOpen(true)} />
          <AddButton title="Ouvrir" icon={faEye} onClick={() => {
            window.open("/billetterie?ticket=" + ticket._id)
          }} />
          <AddButton title="IFrame" icon={faFrame} onClick={() => {
            setIFrameModalIsOpen(true)
          }}/>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-2">
            <div className="flex flex-col  w-full">
              <label className="block text-gray-700 text-sm font-semibold">Nom :</label>
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Titre"
                className="w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
              />
            </div>

            <div className="flex flex-col  w-full">
              <label className="block text-gray-700 text-sm font-semibold">Vente maximum :</label>
              <input
                type="number"
                name="title"
                id="title"
                placeholder="Max - Vide pour illimité"
                className="w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                value={form.max}
                onChange={(e) => setForm({ ...form, max: parseInt(e.target.value) })}
              />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="block text-gray-700 text-sm font-semibold">Description :</label>
            <div className="flex gap-2">
              <textarea
                type="text"
                name="description"
                id="description"
                placeholder="Description"
                className="w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline   "
                value={(form.informations || {})['description' + langKey] || ''}
                onChange={(e) => setForm({ ...form, informations: { ...form.informations, ['description' + langKey]: e.target.value } })}
              />
              <SelectLangInline small={true}></SelectLangInline>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <label className="block text-gray-700 text-sm font-semibold">Type d'utilisateur :</label>
            <select
              value={form.customId || ''}
              onChange={(e) => setForm({ ...form, customId: e.target.value })}
              className="w-full text-sm  rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10 "
            >
              {!form.customId && <option value={''}>{'Selectionner'}</option>}
              {config.inscription.customs.map((e) => {
                return <option value={e.id}>{e.name}</option>;
              })}
            </select>
          </div>
          {form.customId && (
            <div>
              <div className="flex flex-col  w-full">
                <label className="block text-gray-700 text-sm font-semibold">Nom du billet :</label>
                <input
                  type="text"
                  name="typebillet"
                  id="typebillet"
                  placeholder="Titre"
                  className="w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                  value={form.typeUserLabel}
                  onChange={(e) => setForm({ ...form, typeUserLabel: e.target.value })}
                />
              </div>
            </div>
          )}

          {configType && (
            <div className="flex gap-2">
              <div className="flex flex-col  w-full">
                <label className="block text-gray-700 text-sm font-semibold">Titre :</label>
                <select
                  value={form.titleLabel}
                  onChange={(e) => setForm({ ...form, titleLabel: e.target.value })}
                  className="w-full text-sm  rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10 "
                >
                  {!form.titleLabel && <option value={''}>{'Selectionner'}</option>}
                  <option value="name">Nom - Prénom</option>
                  {configType.custom.map((e) => {
                    return <option value={e.id}>{e.tint}</option>;
                  })}
                </select>
              </div>
              <div className="flex flex-col  w-full">
                <label className="block text-gray-700 text-sm font-semibold">Sous-titre :</label>
                <select
                  value={form.subtitleLabel}
                  onChange={(e) => setForm({ ...form, subtitleLabel: e.target.value })}
                  className="w-full text-sm  rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10 "
                >
                  {!form.subtitleLabel && <option value={''}>{'Selectionner'}</option>}
                  <option value="name">Nom - Prénom</option>
                  {configType.custom.map((e) => {
                    return <option value={e.id}>{e.tint}</option>;
                  })}
                </select>
              </div>
            </div>
          )}

          {/*<TicketDetailInformationsComplementaire form={form} setForm={setForm} />*/}

          <div className="">
            <button
              disabled={!canUpdate}
              className={
                (!canUpdate ? 'opacity-20' : '') +
                '  w-full h-10 bg-gray-800 hover:bg-grey-900 text-white text-lg py-1  font-semibold rounded focus:outline-none focus:shadow-outline'
              }
              style={{
                color: (config || {}).colorOverMainColor ?? '#FFFFFF',
                backgroundColor: (config || {}).mainColor ?? '#000000',
              }}
              onClick={createTicket}
            >
              {ticket && ticket._id ? 'Modifier' : 'Créer'}
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={ticketStyleOpen}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 20,
            minWidth: '80%',
            maxWidth: '90%',
            maxHeight: '90%',
          },
        }}
      >
        <CloseButton
          className={'float-right'}
          onClick={() => {
            setTicketStyleOpen(false);
          }}
        />

        <TicketStyle ticket={ticket} />
      </Modal>

      <CustomModal title="Iframe billeterie" onClose={() => setIFrameModalIsOpen(false)} isOpen={iFrameModalIsOpen}>
        <p className="text-sm text-gray-700">Intégrer ce code à votre site</p>
        <pre className="text-left bg-gray-700 text-white w-full rounded p-2 relative">
          {"<iframe id=\"iframeUsekey\"\n" +
            "    title=\"Billetterie\"\n" +
            "    width=\"100%\"\n" +
            "    height=\"500\"\n" +
            `    src="https://${window.location.host}/billetterie?ticket=${ticket._id}">` +
            "</iframe>"}
          <FontAwesomeIcon icon={faCopy}
                           size="2x"
                           className="text-whites absolute right-2 bottom-2 hover:opacity-50 cursor-pointer"
                           onClick={() => {
                             navigator.clipboard.writeText("<iframe id=\"iframeUsekey\"\n" +
                               "    title=\"Billetterie\"\n" +
                               "    width=\"100%\"\n" +
                               "    height=\"500\"\n" +
                               `    src="https://${window.location.host}/billetterie?ticket=${ticket._id}">` +
                               "</iframe>").then(function () {
                               toast.success("Copié dans le presse-papier", {autoClose: 2000})
                             }, function () {
                               toast.error("Impossible de copier dans le presse-papier", {autoClose: 2000})
                             });
                           }}/>
        </pre>
      </CustomModal>
    </div>
  );
}
