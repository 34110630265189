import {RadioButtonGroup} from "react-rainbow-components";
import React from "react";

export default function RadioButton({views, view, setView}) {
  if (!view || view.length <= 1) {
    return null;
  }
  return (
    <div className="rainbow-p-around_x-large mb-5">
      <RadioButtonGroup
        options={views.map((e) => {
          switch (e) {
            case 'day':
              return {label: 'Jour', value: e};
            case 'week':
              return {label: 'Semaine', value: e};
            case 'month':
              return {label: 'Mois', value: e};
            default:
              return {label: 'Erreur', value: 'Erreur'};
          }
        })}
        value={view}
        onChange={(e) => {
          setView(e.target.value);
        }}
      />
    </div>
  )
}