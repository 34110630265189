import Modal from 'react-modal';
import CloseButton from '../../Common/Button/CloseButton';
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { v4 } from "uuid";
import { UpdateJsonConfig } from "../../../Services/Application";
import CommonButton from "../../Common/Button/CommonButton";
import {toast} from "react-toastify";

const SidebarModal = ({modalIsOpen, setModalIsOpen, type}) => {
  const {application, config, reloadConfig} = useContext(ApplicationContext);
  const history = useHistory();
  const [name, setName] = useState("");

  useEffect(() => {
    setName("")
  }, [modalIsOpen]);

  const defaultCustoms = [
    {
      id: "firstName",
      important: true,
      style: "line",
      identifier: "CustomObjectDetailCell",
      isSubTitle: false,
      isTitle: false,
      type: "text",
      placeholder: "Prénom",
      tint: "Prénom",
      protect: true
    },
    {
      id: "lastName",
      important: true,
      style: "line",
      identifier: "CustomObjectDetailCell",
      isSubTitle: false,
      isTitle: false,
      type: "text",
      placeholder: "Nom",
      tint: "Nom",
      protect: true
    },
    {
      id: "email",
      important: true,
      style: "line",
      identifier: "CustomObjectDetailCell",
      isSubTitle: false,
      isTitle: false,
      type: "text",
      placeholder: "Email",
      tint: "Email",
      protect: true
    }
  ]

  const jsonPath = [
    {
      type: "objects",
      message: "Quel type d'objet voulez-vous ajouter ?",
      path: "customObject",
      defaultObject: {icon: "mdi-human-handsdown", name: name, id: name + v4().substring(0, 6), custom: []}
    },
    {
      type: "users",
      message: "Quel type d'utilisateur voulez-vous ajouter ?",
      path: "inscription.customs",
      defaultObject: {name: name, id: name + v4().substring(0, 6), ask: false, custom: defaultCustoms}
    },
    {
      type: "docs",
      message: "Quel type de document voulez-vous ajouter ?",
      path: "docs",
      defaultObject: {icon: "mdi-folder-outline", name: name, id: v4().substring(0, 6)}
    },
  ]

  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const getMessage = () => {
    const jsonCurrentPath = jsonPath.find(e => e.type === type);
    if (!jsonCurrentPath) return "";
    return jsonCurrentPath.message;
  }

  const handleOnClose = () => {
    setModalIsOpen(false);
  }

  const handleOnCreate = async () => {
    setModalIsOpen(false);
    const jsonCurrentPath = jsonPath.find(e => e.type === type);
    if (!jsonCurrentPath) return;
    let newConfig = {...config};
    let tmpConfig = newConfig
    jsonCurrentPath.path.split('.').forEach((subPath) => {
      tmpConfig = tmpConfig[subPath];
    })
    if (tmpConfig === undefined) return;
    const newObj = jsonCurrentPath.defaultObject;

    if (!config.unlocked && tmpConfig.length >= (type === 'docs' ? 2: 3)) return toast.error("Nombre limite d'objet atteint", {autoClose: 3000});
    const found = config?.unlocked?.findIndex(e => e.toLowerCase() === type.toLowerCase());
    if (found === -1 && tmpConfig.length >= (type === 'docs' ? 2: 3)) return toast.error("Nombre limite d'objet atteint", {autoClose: 3000})

    tmpConfig.push(newObj)
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
    history.push(`/v/${type}/${newObj.id}?isnew=1`)
  }

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <div className="h-full w-full">
        <div className="flex justify-between mb-5">
          <p className="text-md text-gray-600">{`Type de catégorie: ${type}`}</p>
          <CloseButton
            className="float-right"
            onClick={handleOnClose}
          />
        </div>
        <p className="font-medium text-sm text-left mb-2">{getMessage()}</p>
        <input className="custominputbox mb-5" value={name} type="text" onChange={(e) => {
          setName(e.target.value)
        }}></input>
        <CommonButton customClassName="w-max" title="Créer" onClick={handleOnCreate}
        />
      </div>
    </Modal>
  );
}

export default SidebarModal;
