import { useContext } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";


function RegisterSelectType({ userTypes, onSelectUserType }) {

	const { config } = useContext(ApplicationContext)
	return (
		<div>
			<h1 className="text-center mb-10">Quel type de personne êtes-vous?</h1>
			<div className="flex items-stretch justify-around pt-10">
				{
					userTypes.map((element, index) => {
						var style = {
							padding: 10,
							paddingLeft: 40,
							paddingRight: 40,
							fontWeight: "bold",
							cursor: "pointer",
							width: "100%",
							color: (config || {}).mainColor ?? "#000000",
							fontSize: 30,
						}
						if (index > 0) {
							style = {
								...style,
								borderLeft: "4px solid " + (config || {}).mainColor ?? "#000000",
							}
						}
						return (<p
							key={"registertype" + index}
							onClick={() => {
								onSelectUserType(element)
							}}
							style={style} className="">{element.name}</p>)
					})
				}
			</div>

		</div>


	);
}

export default RegisterSelectType;
