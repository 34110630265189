import SearchBarInput from "../../Common/SearchBar/SearchBarInput";
import {useContext, useEffect, useState} from "react";
import {UserLinkService} from "./LinkServices/user.link.service";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import {UserContext} from "../../../Context/UserContext";
import {ObjectLinkService} from "./LinkServices/object.link.service";
import {EventLinkService} from "./LinkServices/event.link.service";

const SearchbarListComponent = ({linkConfig, onSelect}) => {
  const {application, langKey, config, language} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [service, setService] = useState(null);
  const [objects, setObjects] = useState(null);

  useEffect(() => {
    if (linkConfig.linkType === 'user') {
      setService(new UserLinkService(linkConfig, application.apiKey, (user || {}).authToken, config.inscription, langKey, config));
    } else if (linkConfig.linkType === 'object') {
      setService(new ObjectLinkService(linkConfig, application.apiKey, (user || {}).authToken, config.customObject, langKey, config));
    } else {
      setService(new EventLinkService(linkConfig, application.apiKey, (user || {}).authToken, config.eventObject.custom, langKey, config));
    }
  }, []);

  const onSearch = async (e) => {
    if (!service) return;
    setObjects(await service.search(e));
  }

  return (
    <div className="w-full">
      <SearchBarInput onSearch={onSearch} />

      {objects && objects.map((e, i) => {
        return (
          <div key={i} className="w-full overflow-hidden border rounded bg-gray-50 cursor-pointer hover:opacity-70" onClick={() => {
            onSelect(e);
          }}>{e.getTitle(language)}</div>
        );
      })}
    </div>
  );
}

export default SearchbarListComponent;
