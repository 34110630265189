import { faCalendarPlus, faGear } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetEventById, GetObjectsAll } from "../../../Services/UKObject";
import { GetUserById } from "../../../Services/User";
import CommonButton from "../../Common/Button/CommonButton";
import CustomTableComponent from "../../Common/Table/table.component";
import IframeEventModal from "../../Iframe/Event/modal.iframe.event";
import ObjectsGroupfilterInput from "../Objects/Form/objects.groupfilter.input";
import EventsSettingsModal from "./Settings/events.settings.modal";
const EventsTableView = ({ linksOnly, onlySelectEvent, onPress }) => {
    const history = useHistory();
    const { application, config, langKey } = useContext(ApplicationContext);
    const { user, isAdmin } = useContext(UserContext);
    const [events, setEvents] = useState(null);
    const [filters, setFilters] = useState({});
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [iframeModal, setIframeModal] = useState(false);
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        if (linksOnly) {
            getPageFromLinks();
        } else {
            getEvents();
        }
    }, [application, user]);

    useEffect(() => {
        if (linksOnly) {
            getPageFromLinks();
        } else {
            getFilterEvents();
        }
    }, [filters, events, groups]);

    const getFilterEvents = () => {
        if (!events) return;
        let newEvents = [...events];
        for (const [key, value] of Object.entries(filters)) {
            if (value.length === 0) {
                delete filters[key];
            }
        }
        newEvents = newEvents?.filter((e) => {
            if (!filters || Object.keys(filters).length === 0) return true;
            for (const [key, value] of Object.entries(filters)) {
                if (value.length === 0) continue;
                const data = e.informations[key] || e.informations[`${key}${langKey}`];
                if (!data) return false;
                if (!data.toLowerCase()?.includes(value.toLowerCase())) return false;
            }
            return true;
        });
        console.log("GROUPS", groups);
        newEvents = newEvents?.filter((e) => {
            if (!groups || !groups.groups || groups.groups.length === 0) return true;
            const needGroups = groups.groups;
            const currentGroups = e.informations.groups;
            if (!currentGroups || currentGroups.length === 0) return false;
            for (const group of needGroups) {
                if (!currentGroups.includes(group)) return false;
            }

            console.log(e.informations.groups, groups.groups);
            return true;
        });
        setFilteredEvents(newEvents);
    };

    const getEvents = async () => {
        if (!application || !user) return;

        const newEvents = await GetObjectsAll("ukEvent", application.apiKey, (user || {}).authToken);
        setEvents(newEvents.sort((a, b) => new Date(a.informations.dateDebut) - new Date(b.informations.dateDebut)));
    };

    const getPageFromLinks = async () => {
        if (!config || !user) return;
        const linksConf = config.inscription.customs.find((custom) => custom.id === user.informations.user_custom_type).custom.filter((cat) => cat.type === "link");

        let linksContent = [];
        let updatedUser = await GetUserById(user._id, application.apiKey, user.authToken);
        for (const link of linksConf) {
            if (link.linkType !== "ukEvent") continue;
            updatedUser.informations[link.id]?.map((userLinks) => {
                linksContent.push(userLinks);
            });
        }
        let newObjs = [];
        for (const id of linksContent) {
            let obj = await GetEventById(id, application.apiKey);
            newObjs.push(obj);
        }
        setEvents(newObjs);
        setFilteredEvents(newObjs);
    };

    const getEventsConfig = () => {
        if (!config) return;
        let tableConfig = config.eventObject.custom;
        tableConfig = tableConfig
            .filter((custom) => custom.type !== "link" && custom.type !== "mapLink")
            .map((obj) => {
                return { title: obj.tint, id: obj.id, type: obj.type };
            });
        const defaultConfig = [
            { title: "Titre", id: `title${langKey || ""}` },
            { title: "Sous-titre", id: `subtitle${langKey || ""}` },
            { title: "Description", id: `descript${langKey || ""}` },
            { title: "Salle", id: `salle${langKey || ""}` },
            { title: "Du - Au", id: "dateDebut", type: "date" },
            { title: "Horraire", id: "dateFin", type: "date" },
        ];
        for (const conf of defaultConfig) {
            if (tableConfig.find((c) => c.id === conf.id) === -1) {
                tableConfig.unshift(conf);
            }
        }
        tableConfig = tableConfig.sort((a, b) => {
            if (a.id.indexOf("dateDebut") == 0) return -1;
            if (b.id.indexOf("dateDebut") == 0) return 1;
            if (a.id.indexOf("date") == 0) return -1;
            if (b.id.indexOf("date") == 0) return 1;
            return 0;
        });
        return tableConfig;
    };

    const getEventContent = (currentEvents) => {
        if (!currentEvents) return;
        const config = getEventsConfig();

        return currentEvents.map((event) => {
            let contentRow = [event._id];
            for (const conf of config) {
                let value = event.informations[`${conf.id}${langKey}`];
                if (!value) value = event.informations[conf.id];
                contentRow.push(value);
            }
            return contentRow;
        });
    };

    return (
        <div className={!onlySelectEvent ? "bg-gray-100" : ""}>
            {!onlySelectEvent && <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Evénements </p>}
            <IframeEventModal modalIsOpen={iframeModal} setmodalIsOpen={setIframeModal} />

            <div className="flex justify-center">
                <div className="w-11/12">
                    {!onlySelectEvent && (
                        <>
                            {isAdmin() && (
                                <div className="flex justify-end py-3">
                                    <CommonButton
                                        customClassName="h-8 ml-3 w-max"
                                        title="Créer une iframe"
                                        onClick={() => {
                                            setIframeModal(true);
                                        }}
                                    />
                                    <CommonButton
                                        customClassName="h-8 ml-3 w-max"
                                        title="Créer un événement"
                                        icon={<FontAwesomeIcon icon={faCalendarPlus} />}
                                        onClick={() => {
                                            history.push(`/v/event/new/event`);
                                        }}
                                    />
                                    <CommonButton
                                        customClassName="h-8 ml-3 w-max"
                                        title="Configuration"
                                        icon={<FontAwesomeIcon icon={faGear} />}
                                        onClick={() => {
                                            setSettingsModalOpen(true);
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <div className="my-2">
                        <ObjectsGroupfilterInput form={groups} setForm={setGroups} />
                    </div>
                    <CustomTableComponent
                        startAt={1}
                        config={getEventsConfig()}
                        content={getEventContent(filteredEvents?.length !== events?.length ? filteredEvents : events)}
                        isLoading={false}
                        onRowClick={(e, event) => {
                            if (!isAdmin()) return;
                            if (onlySelectEvent) {
                                onPress(e);
                                return;
                            }
                            if (event.ctrlKey || event.metaKey) {
                                window.open(`/v/event/${e[0]}`, "_blank");
                            } else {
                                history.push(`/v/event/${e[0]}`);
                            }
                        }}
                        hasSearchbarCol={true}
                        filterObj={filters}
                        onSearch={(key, value) => {
                            setFilters({ ...filters, [key]: value });
                        }}
                        onClear={(key) => {
                            setFilters(() => {
                                let newFilters = { ...filters };
                                delete newFilters[key];
                                return newFilters;
                            });
                        }}
                    />
                </div>
            </div>

            <EventsSettingsModal isOpen={settingsModalOpen} setIsOpen={setSettingsModalOpen} />
        </div>
    );
};

export default EventsTableView;
