import 'moment/locale/fr';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../Context/UserContext';
import { UpdateApplication } from '../../../../../Services/Application';
import { UploadFile } from '../../../../../Services/Utils';

const DEFAULT_IMG_HEADER =
  'https://firebasestorage.googleapis.com/v0/b/usekey-ceb62.appspot.com/o/rxhpkzlho1?alt=media&token=58afc7de-e0dc-49f8-b736-1e8e26781c95';
const DEFAULT_IMG =
  'https://firebasestorage.googleapis.com/v0/b/usekey-ceb62.appspot.com/o/i7e9ksgzpkl?alt=media&token=a4bffd92-4b6e-414c-9f92-f885ad2e1c60';
export default function TicketStyle({ ticket }) {
  const inputFile = useRef(null);
  const { application, reloadApplication } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  useEffect(() => {
    console.log('RELOAD STEP');
    setStep(step + 1);
  }, [application]);
  const onChangeHandler = async (event) => {
    uploadFile(event.target.files[0]);
  };

  const uploadFile = async (fileName, number) => {
    if (fileName == null) {
      return;
    }

    const file = await UploadFile(fileName, application.apiKey, user.authToken);

    if (file == null) {
      toast.error("Impossible de charger l'image");
      return;
    }
    var url = file.url;
    var obj = { ...application.informations };
    var billetterie = obj.billetterie || {};
    var ticketObj = billetterie[ticket._id] || {}
    ticketObj[selectedImage] = url;
    billetterie[ticket._id] = ticketObj;
    obj.billetterie = billetterie;
    await UpdateApplication(application.apiKey, user.authToken, application._id, obj);
    await reloadApplication(window.location.hostname);
    setStep((e) => e + 1);
    console.log(url);
    console.log(application);
  };

  return (
    <div>
      <div className="grid grid-cols-2 w-1/2 m-auto">
        <div className="flex flex-col">
          <img
            onClick={() => {
              setSelectedImage('topLeft');
              inputFile.current.click();
            }}
            className="hover:opacity-80 cursor-pointer"
            src={((application.informations.billetterie || {})[ticket._id] || {}).topLeft || DEFAULT_IMG_HEADER}
            alt="imgheader"
          />
          <div className="flex justify-center bg-gray-800 text-white font-bold py-1">
            <p>TYPE</p>
          </div>
          <div className="flex flex-col items-center justify-center h-full font-bold">
            <p>TITRE</p>
            <p>SOUS-TITRE</p>
          </div>
          <div className="flex items-center justify-center">
            <img className="w-1/3" src={'https://api.applications.pitchr.fr/qrcode/thisisatest'} alt="qrcodetest" />
          </div>
        </div>
        <img
          onClick={() => {
            setSelectedImage('topRight');
            inputFile.current.click();
          }}
          className="hover:opacity-70 cursor-pointer"
          src={((application.informations.billetterie || {})[ticket._id] || {}).topRight || DEFAULT_IMG}
          alt="topRight"
        />
        <img
          onClick={() => {
            setSelectedImage('botLeft');
            inputFile.current.click();
          }}
          className="hover:opacity-70 cursor-pointer"
          src={((application.informations.billetterie || {})[ticket._id] || {}).botLeft || DEFAULT_IMG}
          alt="botLeft"
        />
        <img
          onClick={() => {
            setSelectedImage('botRight');
            inputFile.current.click();
          }}
          className="hover:opacity-70 cursor-pointer"
          src={((application.informations.billetterie || {})[ticket._id] || {}).botRight || DEFAULT_IMG}
          alt="botRight"
        />
      </div>
      <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} accept="image/*" onChange={onChangeHandler} />
    </div>
  );
}
