import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../../../Context/ApplicationContext";
import ReactQuill from "react-quill";
import { formats, modules } from "../../../../Docs/Settings/quill.toolbar";

const ModuleConfigurationFormattedText = ({className, module, setModule}) => {
  const {langKey} = useContext(ApplicationContext);
  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    if (module && module.type === 'FORMATTEDTEXT') {
      if (!module['formattedText' + langKey]) return setEditorValue('');
      setEditorValue(module['formattedText' + langKey]);
    }
  }, [module._id]);

  const handleOnChange = (value) => {
    setEditorValue(value);
    setModule({...module, ['formattedText' + langKey]: value})
  };

  const typeValid = ["FORMATTEDTEXT"];
  if (typeValid.indexOf(module.type) === -1) {
    return <></>
  }

  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Quel text sera affiché ?</p>
      <ReactQuill
        theme="snow"
        value={editorValue}
        onChange={(value) => handleOnChange(value)}
        formats={formats}
        modules={modules}
        style={{
          backgroundColor: 'white',
        }}
      />
    </div>
  );
}

export default ModuleConfigurationFormattedText
