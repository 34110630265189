import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import DefaultComponent from './DefaultComponent';

export default function CustomPageComponent({ clickedData, setClickedData, type, lang }) {
  const { config } = useContext(ApplicationContext);
  const [customPageList, setCustomPageList] = useState();

  useEffect(() => {
    if (!config) return;
    let customPage = config.customPage.map((e) => {
      return {
        value: e.name,
        label: e.name,
      };
    });
    setCustomPageList(customPage);
  }, [config, clickedData]);

  return (
    <DefaultComponent clickedData={clickedData} setClickedData={setClickedData} type={type} picture lang={lang}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quelle page souhaitez-vous afficher ?</p>
        {customPageList && (
          <div style={{ maxWidth: 300 }}>
            <Select
              value={customPageList.find((e) => e.value === clickedData.id)}
              className="w-full"
              options={customPageList}
              onChange={(e) => {
                setClickedData({ ...clickedData, id: e.value, url: e.value });
              }}
            />
          </div>
        )}
      </div>
    </DefaultComponent>
  );
}
