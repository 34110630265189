import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import { formats, modules } from './quill.toolbar';

const DocsSettingsDescription = ({ doc, setDocSelected }) => {
  const { langKey } = useContext(ApplicationContext);
  const [editorValue, setEditorValue] = useState('');

  useEffect(() => {
    if (doc && doc.informations.type === 'obj') {
      if (!doc.informations['content' + langKey]) return setEditorValue('');
      setEditorValue(doc.informations['content' + langKey]);
    }
  }, [doc._id]);

  const handleOnChange = (value) => {
    setEditorValue(value);
    let newDoc = { ...doc };
    newDoc.informations['content' + langKey] = value;
    setDocSelected(newDoc);
  };

  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">Quelle est la description de votre document ?</p>
      <div className="block col-span-3 border border-gray-200 z-0">
        <div>
          <ReactQuill
            theme="snow"
            value={editorValue}
            onChange={(value) => handleOnChange(value)}
            formats={formats}
            modules={modules}
            style={{
              backgroundColor: 'white',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DocsSettingsDescription;
