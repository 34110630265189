import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import StyleDetailView from './PreviewStyle/StyleDetailView';
import StyleImageDetailSquare from './PreviewStyle/StyleImageDetailSquare';
import StyleImageTopRectangleSquare from './PreviewStyle/StyleImageTopRectangleSquare';
import StyleLineView from './PreviewStyle/StyleLineView';

const Style2 = ({}) => {
  return (
    <div className="border-b border-gray-400 p-2">
      <div
        className="flex gap-4"
        style={{
          backgroundImage: 'url(https://www.corpo-events.fr/wp-content/uploads/2013/09/organisation-seminaire-incentive-840x334.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          style={{
            background: 'linear-gradient(180deg, black, transparent)',
            backgroundSize: 'cover',
          }}
          className="h-full w-full text-white content-end items-end justify-start"
        >
          <p className="ml-2 mb-2 font-bold">Titre</p>
          <p className="ml-2 mb-2 pr-1">{moment(new Date()).format('[le] dddd DD MMMM[, de 8:00 à 12:00]')}</p>
        </div>
      </div>
    </div>
  );
};

const Style3 = ({}) => {
  return (
    <div className="border-b border-gray-400 p-2">
      <div
        className="flex gap-4"
        style={{
          backgroundImage: 'url(https://www.corpo-events.fr/wp-content/uploads/2013/09/organisation-seminaire-incentive-840x334.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="h-full w-full text-white content-end items-center justify-start text-center" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="flex items-center justify-center mt-2">
            <p className="font-bold bg-black px-2 py-1">Titre</p>
          </div>
          <p className="mb-2 font-bold">8:00 - 12:00</p>
        </div>
      </div>
    </div>
  );
};

const styleViewArray = [
  { value: 'CLASSIC', label: 'Style 1' },
  { value: 'TWO', label: 'Style 2' },
  { value: 'DETAIL', label: 'Style 3' },
];

export default function ModuleConfigurationStyleObject({ clickedData, setClickedData }) {
  const { config, menuOpen } = useContext(ApplicationContext);
  const [styleHover, setStyleHover] = useState(null);
  const [x, setX] = useState();
  const [y, setY] = useState();

  useEffect(() => {
    const update = (e) => {
      setX(e.clientX); //+ window.pageXOffset);
      setY(e.clientY + window.pageYOffset);
    };
    window.addEventListener('mousemove', update);
    window.addEventListener('touchmove', update);
    return () => {
      window.removeEventListener('mousemove', update);
      window.removeEventListener('touchmove', update);
    };
  }, [setX, setY]);

  /*<Select
          className="w-full"
          options={styleViewArray}
          value={styleViewArray.find((e) => e.value === clickedData.style_view)}
          onChange={(e) => setClickedData({ ...clickedData, style_view: e.value })}
        />*/

  const styleCell = (style, type, number) => {
    return (
      <p
        className={
          'border p-3 rounded border-gray-600 text-gray-600 cursor-pointer ' +
          (clickedData.style_view == type ? 'border-blue-500 bg-blue-100 text-blue-500' : '')
        }
        onMouseEnter={() => setStyleHover(style)}
        onMouseLeave={() => setStyleHover(null)}
        onClick={() => {
          setClickedData({ ...clickedData, style_view: type });
        }}
      >
        Format n°{number}
      </p>
    );
  };
  return (
    <div className="py-2">
      <p className="font-medium text-sm text-left mb-2">Sous quel format voulez-vous afficher vos objets ?</p>
      <div className="grid grid-cols-4 gap-3">
        {styleCell(<StyleImageDetailSquare config={config} />, 'IMAGE_DETAIL_SQUARE', 1)}
        {styleCell(<StyleImageTopRectangleSquare config={config} />, 'IMAGE_TOP_RECTANGLE_SQUARE', 2)}
        {styleCell(<StyleDetailView config={config} />, 'DETAIL', 3)}
        {styleCell(<StyleLineView config={config} />, 'LINE', 4)}
      </div>

      {styleHover && (
        <div
          className="border rounded shadow"
          style={{
            position: 'absolute',

            width: 300,
            top: y,
            left: x - 150 - (menuOpen ? 288 : 0),
            backgroundColor: 'white',

            zIndex: 10000,
          }}
        >
          {styleHover}
        </div>
      )}
    </div>
  );
}
