import { useState, useEffect, useContext } from 'react'
import { ApplicationContext } from '../../../Context/ApplicationContext'
import ObjectTableComponent from '../Objects/Settings/ObjectTableComponent'
import {
	useGlobalFilter,
	usePagination,
	useResizeColumns,
	useTable,
} from "react-table";
import { Button } from 'react-rainbow-components';
import json from '../Objects/Settings/ObjectTable.json'
import Modal from 'react-modal'
import { UpdateJsonConfig } from '../../../Services/Application';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineClose } from 'react-icons/ai';

export default function UserModalComponent({ object, modalIsOpen, setmodalIsOpen }) {
	const { config, application, reloadConfig } = useContext(ApplicationContext)
	const [customObjects, setCustomObjects] = useState([])
	const [columns, setColumns] = useState([])

	const customStyles = {
		content: {
			borderRadius: '4px',
			bottom: 'auto',
			minHeight: '10rem',
			left: '50%',
			position: 'fixed',
			right: 'auto',
			top: '50%',
			transform: 'translate(-50%,-50%)',
			minWidth: '80%',
			width: '80%',
			maxWidth: '60rem',
			padding: 40,
			backgroundColor: 'rgb(255, 255, 255)',
			borderWidth: '1px',
			borderColor: '#CDCDCD',
			border : "solid"
		},
		// content: {
		// 	top: '50%',
		// 	left: '50%',
		// 	right: '50%',
		// 	bottom: '50%',
		// 	transform: 'translate(-50%, -50%)',
		// 	padding: 40,
		// 	overflow: 'scroll',
		// 	height: '90%',
		// 	width: '90%',
		// },
	};

	const getObjectToMap = (config) => {
		const obj = config.inscription.customs.find((e) => {
			return e.id === object[0].informations.user_custom_type;
		})
		return obj;
	}

	useEffect(() => {
		if (customObjects?.length > 0)
			setColumns(json)
		else
			setColumns([])
	}, [customObjects])

	useEffect(() => {
		if (!config || !object || object.length === 0)
			return null;
		let obj = getObjectToMap(config).custom.map((e) => {
			let obj = { placeholder: e.placeholder || "", type: e.type, tint: e.tint, id: e.id, important: e.important || false, isSubTitle: e.isSubTitle || false, isTitle: e.isTitle || false }
			if (e.values) {
				obj = { ...obj, values: e.values }
			}
			return obj;
		})
		setCustomObjects(obj)
		// eslint-disable-next-line
	}, [config, object, modalIsOpen])

	const updateData = (rowIndex, columnId, value) => {
		let newCustom = [...customObjects]
		if (columnId === "type") {
			if (value === "selector" || value === "multipleSelector" || value === "category") {
				newCustom[rowIndex].values = [""]
			} else {
				delete newCustom[rowIndex].values
			}
		}
		newCustom[rowIndex][columnId] = value
		setCustomObjects(newCustom)
	};

	const saveData = async (rowIndex, custom = null) => {
		const newConfig = { ...config }
		getObjectToMap(newConfig).custom = custom || customObjects;
		await UpdateJsonConfig(application.apiKey, newConfig)
		await reloadConfig();
	};

	const defaultColumn = {
		Cell: ObjectTableComponent,
		minWidth: 200,
	};

	const {
		getTableProps,
		getTableBodyProps,
		page,
		headerGroups,
		prepareRow,
		state,
	} = useTable(
		{ columns, data: customObjects, defaultColumn, updateData, saveData },
		useResizeColumns,
		useGlobalFilter,
		usePagination
	);

	return (
		<Modal isOpen={modalIsOpen} style={customStyles} className="">
			<div className='h-full w-full'>
				<Button label="Ajouter" className='mt-2 mb-2' style={{ color: "#413751" }} variant='border-filled' onClick={() => {
					let newArr = [...customObjects]
					newArr.push({})
					setCustomObjects(newArr)
				}} />
				<Button label="Fermer" className='float-right' style={{ color: "#413751" }} variant='border-filled' onClick={() => {
					setmodalIsOpen(false)
				}} />
				<div className="reverse-scrollbar overflow-x-auto flex items-center justify-center">
					<div className="reverse-scrollbar pt-4">
						<table {...getTableProps()} className="border border-gray-300">
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => {
											return (
												<th
													{...column.getHeaderProps()}
													className="border-b border-gray-300"
													style={{

														color: "black",
														position: "relative",
														width:
															state.columnResizing.columnWidths[column.id] ||
															"150px",
													}}
												>
													{column.render("Header")}

													<div
														{...column.getResizerProps()}
														className="resizer"
													>
														<div className="h-full w-full bg-gray-400" />
													</div>
												</th>
											);
										})}
									</tr>
								))}
							</thead>
							<tbody className="overflow-x-auto" {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row);
									return (
										<>
											<tr {...row.getRowProps()}>
												{row.cells.map((cell) => {
													return (
														<td
															{...cell.getCellProps()}
															className="border border-gray-300"
															style={{
																padding: "10px",
																width: "150px",
															}}
														>
															{cell.render("Cell")}
														</td>

													);
												})}
												<td className="p-2 border">
													{row.index > 0 && <Button
														className="h-10 mr-1"
														onClick={() => {
															let obj = [...customObjects]
															let tmp = obj[row.index - 1]
															obj[row.index - 1] = obj[row.index]
															obj[row.index] = tmp
															setCustomObjects(obj)
															saveData(null, obj)
														}}
														label={<AiOutlineArrowUp />}
														style={{ color: "#413751" }}
														variant="border-filled"
													/>}
													{row.index < customObjects.length - 1 && <Button
														className="h-10"
														onClick={() => {
															let obj = [...customObjects]
															let tmp = obj[row.index + 1]
															obj[row.index + 1] = obj[row.index]
															obj[row.index] = tmp
															setCustomObjects(obj)
															saveData(null, obj)
														}}
														label={<AiOutlineArrowDown />}
														style={{ color: "#413751" }}
														variant="border-filled"
													/>}
												</td>
												<td className='p-2 border'>
													<Button
														className='h-10'
														label={<AiOutlineClose />}
														style={{ color: "#413751" }}
														variant='border-filled'
														onClick={() => {
															let arr = customObjects.filter((e, i) => i !== row.index)
															setCustomObjects(arr)
															saveData(null, arr)
														}}
													/>
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Modal>
	)
}