import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import { GetUserById, GetUsersAllV2 } from '../../../Services/User';
import Pagination from '../../Common/Pagination';
import TableLimiter from '../Objects/table.limiter';
import UserModalComponent from './Settings/usersmodal.component';
import UsersActionbar from './users.actionbar';
import UsersTable from './users.table';

const UsersView = ({linksOnly, onlySelectUser, typeObject, onPress}) => {
  const {application, applicationConfig, config} = useContext(ApplicationContext);
  const {user, isAdmin} = useContext(UserContext);
  let {params} = useRouteMatch();
  if (onlySelectUser) {
    params.typeobject = typeObject;
    params.iduser = typeObject;
  }
  const history = useHistory();
  const [param, setParam] = useState(params);
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({page: 0, objects: [], filters: [], maxPage: 0});
  const [limit, setLimit] = useState('20');
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState(new URLSearchParams(location.search).get('isnew') === '1');

  const getPage = async () => {
    if (!user || !application) return;
    let newObjs;
    setIsLoading(true);
    if (linksOnly) {
      newObjs = await getPageFromLinks();
    } else {
      newObjs = await GetUsersAllV2(info.page, application.apiKey, (user || {}).authToken, info.filters, params.iduser, limit);
    }
    if (!newObjs) {
      setIsLoading(false);
      return;
    }
    setInfo({
      ...info,
      objects: newObjs.data,
      maxPage: newObjs.totalPage,
    });
    setIsLoading(false);
  };

  const getPageFromLinks = async () => {
    if (!config || !user) return {};
    const linksConf = config.inscription.customs.find(custom => custom.id === user.informations.user_custom_type).custom.filter(cat => cat.type === "link")

    let linksContent = [];
    let updatedUser = await GetUserById(user._id, application.apiKey, user.authToken);
    for (const link of linksConf) {
      if (link.linkType !== "user") continue;
      updatedUser.informations[link.id]?.map(userLinks => {
        linksContent.push(userLinks);
      })
    }
    let newObjs = [];
    for (const id of linksContent) {
      let obj = await GetUserById(id, application.apiKey, user.authToken);
      newObjs.push(obj);
    }
    return {data: newObjs, totalPage: 0};
  }

  useEffect(() => {
    getPage();
  }, [params.typeobject]);

  useEffect(() => {
    getPage();
  }, [info.filters, info.page, limit, user]);

  useEffect(() => {
    if (params.iduser !== param.iduser) {
      setInfo({
        ...info,
        page: 0,
        filters: [],
        objects: [],
      });
      setParam(params);
    }
  }, [params]);

  if (applicationConfig === null) return <></>;

  const userConfig = applicationConfig.getCustomUserByID(params.iduser);

  return (
    <div className={!onlySelectUser ? "bg-gray-100" : ''}>
      {!onlySelectUser && (
        <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">{userConfig.name}</p>
      )}
      <div className="flex w-full justify-center">
        <UsersActionbar info={info} setInfo={setInfo} typeobject={params.iduser} config={userConfig} modalSetter={setModalIsOpen} onlySelectUser={onlySelectUser}/>
      </div>

      <div className="flex w-11/12 m-auto">
        <UsersTable usertype={params.iduser} info={info} loading={isLoading} userConfig={userConfig} onCellClick={(elem) => {
          if (onlySelectUser) {
            onPress(elem);
            return;
          }
          if (!isAdmin()) return;
          history.push(`/v/user/${elem._id}`, {back: true});
        }} />
      </div>
      <UserModalComponent modalIsOpen={modalIsOpen} modalSetter={setModalIsOpen} />
      <div className="flex text-center bottom-4 pb-8 w-11/12 m-auto">
        <Pagination
          nbPages={info.maxPage}
          page={info.page}
          total={info.maxPage}
          fetch={(value) => {
            setInfo({
              ...info,
              page: value,
            });
          }}
        ></Pagination>
        <TableLimiter limit={limit} setLimit={setLimit} />
      </div>
    </div>
  );
};

export default UsersView;
