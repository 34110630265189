import { faBallotCheck, faCalendar, faChevronDown, faChevronUp, faDatabase, faFolderOpen, faHammer, faMap, faPlus, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from "../../../Context/UserContext";
import SidebarModal from './sidebar.modal';
import {faPencil} from "@fortawesome/pro-light-svg-icons";
import CategoryModifyModal from "./category.modify.modal";

const categories = [
  {name: 'Evénements', icon: faCalendar, link: '/v/events', admin: true},
  {name: 'Objets', icon: faDatabase, children: [], newType: 'objects', expandable: true, admin: true, modif: true},
  {name: 'Utilisateurs', icon: faUser, children: [], newType: 'users', expandable: true, admin: true, modif: true},
  {name: 'Documents', icon: faFolderOpen, children: [], newType: 'docs', expandable: true, admin: true, modif: true},
  {name: 'Votes', icon: faBallotCheck, link: '/v/vote', admin: true},
  {name: 'Editeur de carte', icon: faMap, link: '/v/mapeditor', admin: true},
  {
    name: 'Administration',
    icon: faHammer,
    children: [
      {name: "Structure de l'application", link: '/v/designv2'},
      {name: "Informations de l'application", link: '/v/appinfo'},
      {name: 'Notifications', link: '/v/notification'},
      {name: 'Gestion des questions', link: '/v/eventquestions'},
      {name: 'Billetterie', link: '/v/tickets'},
      {name: 'Scan', link: '/v/scan'},
      {name: 'Groupes', link: '/v/groups'},
      {name: 'Traductions', link: '/v/localization'},
      {name: 'Paramètres', link: '/v/account'},
      {name: 'Statistiques', link: '/v/stat'},
    ],
    expandable: true,
    admin: true
  },
];

const SideBarContent = ({}) => {
  const {config, applicationConfig} = useContext(ApplicationContext);
  const {isAdmin, hasState, user} = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newCategoryType, setNewCategoryType] = useState(null);
  const [_, setCategoryOpened] = useState(null);
  const [categoriesList, setCategoriesList] = useState([])
  const [categoryModify, setCategoryModify] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!config) return;

    if (hasState("3")) {
      let newCategories = categories

      newCategories[1].children = [];
      newCategories[2].children = [];
      newCategories[3].children = [];

      config?.customObject?.forEach((e) => {
        newCategories[1].children.push({name: e.name, link: `/v/objects/${e.id}`, type: "objects", id: e.id});
      });
      config?.inscription?.customs?.forEach((e) => {
        newCategories[2].children.push({name: e.name, link: `/v/users/${e.id}`, type: "users", id: e.id});
      });
      config?.docs?.forEach((e) => {
        newCategories[3].children.push({name: e.name, link: `/v/docs/${e.id}`, type: "docs", id: e.id});
      });
      setCategoriesList(newCategories);
    } else {
      if (!user) return;
      getNonAdminContent();
    }
  }, [config, user]);

  const findObjLink = (linksArr, knownLinks) => {
    let objLinks = [];
    for (const link of linksArr) {
      if (link.linkType === "object") {
        objLinks.push(link);
        const foundObjLinks = config.customObject.find(c => c.id === link.url).custom.filter(c => c.type === "link" && c.linkType === "object" && knownLinks.findIndex(kl => kl === link.id) === -1)
        objLinks.push(...findObjLink(foundObjLinks, [...knownLinks, link.id]));
      }
    }
    return objLinks;
  }

  const getNonAdminContent = async () => {
    // console.log(config.settingsV2.filter(menu => {
    //   if (menu.state === null || menu.state === undefined) return true;
    //   return hasState(menu.state);
    // }))
    setCategoriesList([{name: 'Aucun contenu', link: '/v/account'}])
    const linksConf = config.inscription?.customs?.find(custom => custom.id === user.informations.user_custom_type)?.custom?.filter(cat => cat.type === "link")

    let categories = [
      {name: 'Objets', icon: faDatabase, children: [], expandable: true, isOpen: true},
    ]

    if (linksConf) {
      let recursiveLinks = findObjLink(linksConf, []);

      for (const link of recursiveLinks) {
        if (link.linkType === "object") {
          if (categories[0].children.findIndex(child => child.url === link.url) !== -1) continue;
          categories[0].children.push({name: applicationConfig.getCustomObjectByID(link.url).name, link: `/v/objects/${link.url}`, url: link.url});
        }
        // else if (link.linkType === "user") {
        //   if (categories[2].children.findIndex(child => child.url === link.url) !== -1) continue;
        //   categories[2].children.push({name: applicationConfig.getCustomUserByID(link.url).name, link: `/v/users/${link.url}`, url: link.url});
        // }
      }
    }
    // config.customObject.map(obj => {
    //   if (obj.canCreateObject === true)
    //     categories[0].children.push({ name: applicationConfig.getCustomObjectByID(obj.id).name, link: `/v/objects/${obj.id}`, url: obj.id });
    // });
    setCategoriesList(categories);
  }

  const newCategory = (type) => {
    setModalIsOpen(true);
    setNewCategoryType(type);
  };

  const expandCat = (category) => {
    if (category.link) {
      history.push(category.link);
    }
    if (category.expandable) {
      category.isOpen = !category.isOpen;
      setCategoryOpened([category, category.isOpen]);
    }
  }

  return (
    <div>
      {categoriesList.map((category, index) => {
        if (!isAdmin() && category.admin) return;
        return (
          <div key={index} className="flex-col w-11/12 mt-4 m-auto justify-start gap-2">
            <div className="flex justify-between mb-3">
              <div
                className={`flex ml-2 justify-start items-center gap-2 font-medium text-gray-700 text-md w-full ${category.link || category.expandable ? 'hover:text-black' : ''
                }`}
                onClick={() => expandCat(category)}
                style={{
                  cursor: category.link || category.expandable ? 'pointer' : 'default',
                }}
              >
                {category.icon && <FontAwesomeIcon icon={category.icon} />}
                <p>{category.name}</p>
              </div>
              {category.expandable && (
                <div className="mr-2 transition-all">
                  <div className="w-full"
                       onClick={() => expandCat(category)}
                       style={{
                         cursor: category.link || category.expandable ? 'pointer' : 'default',
                       }}>
                    <FontAwesomeIcon icon={category.isOpen ? faChevronUp : faChevronDown} />
                  </div>
                </div>
              )}
            </div>
            {category.isOpen &&
              category.children &&
              category.children.map((child, index) => {
                return (
                  <div
                    key={index}
                    className="group flex pl-[11%] py-1 justify-between items-center mt-1 w-full text-left text-gray-700 cursor-pointer hover:bg-gray-100 rounded  transition-all"
                    onClick={() => {
                      history.push(child.link);
                    }}
                  >
                    {child.icon && <FontAwesomeIcon style={{width: '10%'}} icon={child.icon} />}
                    <p className="ml-2">{child.name}</p>
                    {isAdmin() && category.modif && <div className="opacity-0 group-hover:opacity-100 mr-2">
                      <FontAwesomeIcon className="transform hover:scale-125" icon={faPencil} onClick={() => {
                        setCategoryModify(child);
                      }}/>
                    </div>}
                  </div>
                );
              })}
            {category.isOpen && category.newType && (
              <div
                onClick={() => {
                  newCategory(category.newType, category.link);
                }}
              >
                {isAdmin() && <div className="flex pl-[11%] py-1 text-sm justify-start items-center mt-1 text-left font-light hover:bg-custom-mainColor border border-transparent  hover:border-custom-mainColor px-2 rounded w-full hover:bg-opacity-10 text-gray-700 cursor-pointer transition-all">
                  <FontAwesomeIcon icon={faPlus} />
                  <p className="ml-1 font-semibold">Ajouter</p>
                </div>}
              </div>
            )}
          </div>
        );
      })}
      <SidebarModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} type={newCategoryType} />
      <CategoryModifyModal isOpen={!!categoryModify} setIsOpen={setCategoryModify} infos={categoryModify} />
    </div>
  );
};

export default SideBarContent;
