import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../Context/ApplicationContext';
import AddButton from '../Common/Button/AddButton';
import CustomStylesLangSelect from '../Other/CustomStylesLangSelect';
import BilletterieConfirmation from './BilletterieConfirmation';
import BilletterieFormularies from './BilletterieFormularies';
import "./BilletterieView.css";
import ChoiceTicket from './ChoiceTicket';
// the query string for you.

function BilletterieView() {
  const { application, config, language, setLanguage, allLanguage, TRAD } = useContext(ApplicationContext);
  const [tickets, setTickets] = useState([]);
  const [finalTicket, setFinalTicket] = useState([]);
  const [step, setStep] = useState(1);


  if (!application || !config) {
    return null;
  }


  //https://usekey-billetterie.herokuapp.com/ticket/5e4c53f2880f8e00169c4b8a/generate
  /*{"firstName":"remi","lastName":"telenczak","email":"remitelen@gogo.com","user_custom_type":"9876ZZ","civilite":"Madame","pays":"France - FR","code_postal":"59890","city":"LILLE","age":"18 - 24 ans","status":"À la recherche d'un emploi","sector":"","publicite":"Publicité dans la presse","need_job":"Ouvrier / Employé","need_sector":["Bâtiment, travaux publics et énergie"],"groups":["1"],"wantinfo":[]}*/
  return (
    <div className="bg-gray-50 min-h-screen text-gray-700">
      {application?.informations?.billetterie?.banniere &&
        <img
          src={application.informations.billetterie.banniere}
          alt="banniere"
          className="object-cover"
          style={{
            height: '20vh',
            width: '100vw',
          }}
        />
      }
      <div className="w-full  md:w-2/3 lg:w-1/2  md:m-auto md:mt-4  flex justify-end px-1">
        {allLanguage && (
          <Select
            className="w-36"
            value={{ value: language.id, label: <CustomStylesLangSelect lang={language} /> }}
            options={allLanguage.map((e) => {
              return { value: e.id, label: <CustomStylesLangSelect lang={e} /> };
            })}
            onChange={(e) => {
              setLanguage(config.language.find((el) => e.value === el.id));
            }}
          />
        )}
      </div>
      <div className="px-2">
        <div className="bg-white rounded-lg w-full  md:w-2/3 lg:w-1/2  md:m-auto md:mt-4 border border-gray-200 p-4">
          <div className="flex items-center justify-between text-gray-700">
            <div className={'h-10 w-10 border border-blue-300 rounded-full flex items-center justify-center text-2xl bg-blue-200'}>1</div>
            <div
              className=" bg-blue-200"
              style={{
                height: 1,
                width: '37%',
              }}
            ></div>
            <div
              className={
                'h-10 w-10 border border-blue-200 rounded-full flex items-center justify-center text-2xl ' +
                (step > 1 ? 'bg-blue-200 border-blue-300' : '')
              }
            >
              2
            </div>
            <div
              className=" bg-blue-200"
              style={{
                height: 1,
                width: '37%',
              }}
            ></div>
            <div
              className={
                'h-10 w-10 border border-blue-200 rounded-full flex items-center justify-center text-2xl ' +
                (step > 1 ? 'bg-blue-200 border-blue-300' : '')
              }
            >
              3
            </div>
          </div>

          {step === 1 && <p className="text-2xl font-bold mt-2 pt-2 pb-4">{TRAD('CHOICE_TICKET_TITLE', 'Choix des billet')}</p>}
          {step === 1 && (
            <ChoiceTicket
              onSelectedTicket={(tickets) => {
                var arr = [];
                for (var i = 0; i < tickets.length; i++) {
                  arr.push({});
                }
                setFinalTicket(arr);
                setTickets(tickets);
                setStep(2);
              }}
            />
          )}

          {step === 2 && (
            <div className="relative">
              <div className="absolute left-0 top-3">
                <AddButton
                  title={TRAD('BACK', 'retour')}
                  iconLeft={true}
                  icon={faArrowLeft}
                  onClick={() => {
                    setStep(1);
                  }}
                />
              </div>
              <p className="text-2xl font-bold mt-2 pt-2 pb-4">{TRAD('FORMULARY', 'Formulaire')}</p>
            </div>
          )}
          {step === 2 && (
            <BilletterieFormularies
              tickets={tickets}
              setFinalTicket={setFinalTicket}
              finalTicket={finalTicket}
              onEndedFormulary={() => {
                setStep(3);
              }}
            />
          )}

          {step === 3 && (
            <div className="relative">
              <div className="absolute left-0 top-3">
                <AddButton
                  title={TRAD('BACK', 'retour')}
                  iconLeft={true}
                  icon={faArrowLeft}
                  onClick={() => {
                    setStep(2);
                  }}
                />
              </div>
              <p className="text-2xl font-bold mt-2 pt-2 pb-4">{TRAD('APPOINTMENT_POPUP_TITLE', 'Confirmation')}</p>
            </div>
          )}
          {step === 3 && <BilletterieConfirmation tickets={finalTicket} />}
        </div>
      </div>
    </div>
  );
}

export default BilletterieView;
