import React, { useContext } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import CustomInputBox from "../../../Common/Inputs/customInputBox.component";

const DocsSettingsName = ({doc, setDocSelected}) => {
  const {langKey} = useContext(ApplicationContext);

  return (
    <CustomInputBox title="Quel est le nom de votre document ?" value={doc.informations["title" + langKey] || ""} onChange={(e) => {
      setDocSelected((prevDoc) => {
        let newDoc = {...prevDoc};
        newDoc.informations["title" + langKey] = e;
        return newDoc;
      })
    }} />
  );
}

export default DocsSettingsName
