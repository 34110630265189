import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import UploadFileModal from "../../../UploadFileDocument/UploadFileModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt, faEye} from "@fortawesome/free-solid-svg-icons";
import TrashButton from "../../../Common/Button/TrashButton";
import {UserContext} from "../../../../Context/UserContext";

const InputFiles = ({value = [], id, setForm}, ref) => {
  const {isAdmin} = useContext(UserContext);
  const [fileModalIsOpen, setFileModalIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setFileModalIsOpen(true);
    }
  }));

  return (
    <div className="w-full">
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Ajouter une URL déjà existente</p>
        <div className="flex items-center gap-2">
          <input
            className="custominputbox w-full"
            type="text"
            value={fileUrl}
            onChange={(e) => {
              setFileUrl(e.target.value);
            }}/>
          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              setForm((form) => {
                return {
                  ...form,
                  [id]: form[id] && form[id].constructor === Array && form[id].length ? [...form[id], fileUrl] : [fileUrl]
                };
              });
              setFileUrl('');
            }}>
            <FontAwesomeIcon icon={faCloudUploadAlt}/>
          </div>
          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              window.open(fileUrl, '_blank');
            }}>
            <FontAwesomeIcon icon={faEye}/>
          </div>
        </div>
      </div>
      <div className="mx-4 p-6 gap-3 bg-white grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
        {value && value.constructor === Array && value.length > 0 && value.map((e, i) => {
          const extension = e.match('\\.\\w{3,4}($|\\?)')?.[0];
          return (
            <div key={i}
                 className="relative hover:bg-indigo-50 hover:bg-opacity-50 cursor-pointer transition flex flex-col items-left
              lg:items-center rounded-lg border text-left py-2 px-2 min-h-full justify-center text-center">
              <div className="absolute right-0 top-0">
                {isAdmin() && <TrashButton
                  className="px-2 py-1"
                  onClick={() => {
                    if (window.confirm('Voulez vous supprimer ?')) {
                      setForm((form) => ({
                        ...form,
                        [id]: form[id].filter((formLink) => formLink !== e),
                      }));
                    }
                  }}
                />}
              </div>
              {[".png", ".jpg", ".jpeg"].findIndex((e) => e === extension) !== -1 && (
                <img
                  alt="img"
                  className="object-contain mb-2"
                  style={{height: 70}}
                  src={e}
                />
              )}
              <div
                className={'w-full ml-0 text-gray-700 border-indigo-100 py-2 px-2' + ([".png", ".jpg", ".jpeg"].findIndex((e) => e === extension) !== -1 ? 'border-t-2' : '')}>
                <p className="font-light text-xs overflow-ellipsis overflow-hidden ellipse2 mt-2">
                  {e}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <UploadFileModal setModalIsOpen={setFileModalIsOpen} modalIsOpen={fileModalIsOpen} onSelectFile={(f) => {
        setForm((form) => {
          return {...form, [id]: form && form[id] && form[id].constructor === Array && form[id].length ? [...form[id], f] : [f]};
        });
      }}/>
    </div>
  );
}

export default forwardRef(InputFiles);
