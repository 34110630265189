import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetObjectByIds, GetObjectsAll, GetUKQuestion, UpdateObject } from "../../../Services/UKObject";
import CommonButton from "../../Common/Button/CommonButton";
import CustomTableComponent from "../../Common/Table/table.component";

function QuestionSelectionView({}) {
    const [questions, setQuestions] = useState([]);
    const { application, langKey } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [changedStateIds, setChangedStateIds] = useState([]);
    const [filter, setFilter] = useState(null);
    const [formattedQuestions, setFormattedQuestions] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        setIsSaving(true);
        getQuestions();
        setIsSaving(false);
        const interval = setInterval(() => {
            setIsSaving(true);
            getQuestions();
            setIsSaving(false);
        }, 2000);
        return () => clearInterval(interval);
    }, [application]);

    useEffect(() => {
        const newFormattedQuestions = getFormattedQuestion();
        setFormattedQuestions(newFormattedQuestions);

        const filteredSelectedRows = selectedRows.filter((e) => newFormattedQuestions.find((q) => q[0] === e));
        setSelectedRows(filteredSelectedRows);
    }, [filter, questions, events]);

    const getQuestions = useCallback(async () => {
        if (!application) return;

        let questions = await GetUKQuestion((application || {}).apiKey, (user || {}).authToken);
        const globalQuestions = await GetObjectsAll("ukQuestionGlob", application.apiKey, (user || {}).authToken);

        if (!questions) return;

        const eventIds = [...new Set(questions.map((e) => e.type.split("_")[1]))];
        const events = await GetObjectByIds(application.apiKey, "ukEvent", eventIds);
        questions = [...questions, ...globalQuestions];

        setEvents(events);
        setQuestions(questions.reverse());
    }, [application]);

    const onRowSelection = (selected) => {
        let newSelectedRows = [...selectedRows];
        const index = newSelectedRows.findIndex((s) => s === selected);
        if (index === -1) {
            newSelectedRows.push(selected);
        } else {
            newSelectedRows.splice(index, 1);
        }
        setSelectedRows(newSelectedRows);
    };

    const changeQuestionVisibility = (visibility) => {
        console.log("CHANGE 1");
        const newQuestions = [...questions];
        const changedIds = [];

        for (const row of selectedRows) {
            const question = newQuestions.find((question) => question._id === row);
            if (!question) continue;
            changedIds.push(row);
            question.informations.isVisible = visibility;
        }
        console.log("CHANGE 2");
        const changeChangedIds = [...new Set([...changedStateIds, ...changedIds])];
        setChangedStateIds(changeChangedIds);
        setQuestions(newQuestions);
        saveChanges(newQuestions, changeChangedIds);
    };

    const getEventName = (id) => {
        if (!id) return "Question Globale";

        let event = events.find((e) => {
            return e._id + "" === id + "";
        });
        if (!event) {
            for (const currEvent of events) {
                if (!currEvent.informations.subEvent) continue;
                event = currEvent.informations.subEvent.find((e) => {
                    return e._id === id;
                });
            }
        }
        return event?.informations[`title${langKey}`] || event?.informations[`title`] || "Événement sans nom";
    };

    const getFormattedQuestion = () => {
        if (!questions) return [];
        let formattedQuestions = questions.map((e) => {
            return [e._id, e.informations.question, e.informations.isVisible ? "Oui" : "Non", getEventName(e.type.split("_")[1])];
        });

        if (filter === true) {
            formattedQuestions = formattedQuestions.filter((e) => e[2] === "Oui");
        } else if (filter === false) {
            formattedQuestions = formattedQuestions.filter((e) => e[2] === "Non");
        }
        return formattedQuestions;
    };

    const saveChanges = async (questions, changedStateIds) => {
        if (isSaving) return;
        setIsSaving(true);

        for (const question of questions) {
            if (changedStateIds.findIndex((id) => id === question._id) === -1) continue;
            console.log("UPDATE OBJECT");
            await UpdateObject(question._id, question.type, question.informations, application.apiKey, (user || {}).authToken);
        }
        setChangedStateIds([]);
        setSelectedRows([]);
        toast.success("Changements sauvegardés !", { autoClose: 2000 });
        setIsSaving(false);
    };

    const changeFilter = (state) => {
        setFilter(state);
    };

    return (
        <div className="bg-gray-100">
            <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Gestions des questions</p>
            <div className="flex justify-center">
                <div className="w-11/12">
                    <div className="flex justify-between">
                        <div className="cursor-pointer h-max pl-1 pr-1 flex border border-[#0099ccBB] rounded flex items-center justify-center bg-[#0099cc22] gap-1">
                            <div className={`${filter === true ? "opacity-60" : ""} text-[#0099cc]`} onClick={() => changeFilter(true)}>
                                Visible
                            </div>
                            <div
                                className={`${filter === null ? "opacity-60" : ""} text-[#0099cc] border-l border-r border-[#0099ccBB] pl-1 pr-1`}
                                onClick={() => changeFilter(null)}
                            >
                                Tout
                            </div>
                            <div className={`${filter === false ? "opacity-60" : ""} text-[#0099cc]`} onClick={() => changeFilter(false)}>
                                Non visible
                            </div>
                        </div>

                        <div className="flex justify-end mb-2 gap-2">
                            <Link to="/q" target="_blank">
                                <CommonButton
                                    title="Site public"
                                    customColor={"#0099cc"}
                                    customClassName={`w-max ${selectedRows.length > 0 ? "" : "hover:opacity-100"}`}
                                    onClick={() => {
                                        // if (!selectedRows.length) return;
                                        // changeQuestionVisibility(true);
                                    }}
                                />
                            </Link>
                            {/* <CommonButton
                                title="Afficher"
                                customColor={selectedRows.length ? "#0099cc" : "#BBBBBB"}
                                customClassName={`w-max ${selectedRows.length > 0 ? "" : "hover:opacity-100"}`}
                                onClick={() => {
                                    if (!selectedRows.length) return;
                                    changeQuestionVisibility(true);
                                }}
                            /> */}
                            <CommonButton
                                title="Afficher"
                                customColor={selectedRows.length ? "#0099cc" : "#BBBBBB"}
                                customClassName={`w-max ${selectedRows.length > 0 ? "" : "hover:opacity-100"}`}
                                onClick={() => {
                                    if (!selectedRows.length) return;
                                    changeQuestionVisibility(true);
                                }}
                            />
                            <CommonButton
                                title="Cacher"
                                customColor={selectedRows.length ? "#0099cc" : "#BBBBBB"}
                                customClassName={`w-max ${selectedRows.length > 0 ? "" : "hover:opacity-100"}`}
                                onClick={() => {
                                    if (!selectedRows.length) return;
                                    changeQuestionVisibility(false);
                                }}
                            />
                            {/* <CommonButton
                                title="Sauvegarder"
                                customColor={"#22C55E"}
                                customClassName="w-max"
                                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                onClick={() => {
                                    saveChanges();
                                }}
                            /> */}
                        </div>
                    </div>
                    {!!changedStateIds.length && <p className="text-gray-700 mr-2 mb-1">Des changements ne sont pas sauvegardés</p>}
                    <CustomTableComponent
                        hideRowsOnLoading={true}
                        isLoading={isSaving}
                        startAt={1}
                        config={[
                            { id: "question", title: "Question" },
                            { id: "visibility", title: "Visible" },
                            { id: "event", title: "Événement" },
                        ]}
                        content={formattedQuestions}
                        hasSelector={true}
                        selectorCallBack={onRowSelection}
                        onRowClick={(row) => {
                            onRowSelection(row[0]);
                        }}
                        checkedRowsIds={selectedRows}
                        contrastColorRowNumber={filter === null ? 2 : null}
                        contrastColorRowValue={"Oui"}
                        contrastColors={["rgba(139,220,139,0.7)", "rgba(246,171,171,0.71)"]}
                    />
                </div>
            </div>
        </div>
    );
}

export default QuestionSelectionView;
