import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { Search } from '../../../Services/UKObject';
import CloseButton from '../Button/CloseButton';
import CustomObjectCell from '../CustomObjectCell';
import SearchBarInput from './SearchBarInput';

function SearchBarComponent({}) {
  const { config, application } = useContext(ApplicationContext);
  const history = useHistory();
  const searchRef = useRef();
  const [results, setResults] = useState(null);

  const onSearch = async (search) => {
    if (!search || search.length == 0) {
      return setResults(null);
    }
    var result = await Search(search, application.apiKey);

    console.log(result);
    setResults(
      result.filter((e) => {
        if (e.config) {
          return true;
        }
        return e.key == 'ukEvent';
      })
    );
  };
  return (
    <div>
      <SearchBarInput ref={searchRef} onSearch={onSearch} />
      {results && (
        <div
          className="bg-white border border-gray-200 rounded px-2 pb-2 mt-6 left-0 right-0 m-auto shadow absolute "
          style={{ maxWidth: '30vw', zIndex: 9999 }}
        >
          {results.length == 0 && <p className="my-4 italic">Aucun resultat</p>}
          {results.map((e) => {
            return (
              <div className="flex flex-col flex-wrap text-left justify-between gap-2 pt-4 ">
                <p className="text-left text-gray-500 font-bold  ">{(e.config || { name: 'Événements' }).name}</p>
                {e.options.map((e) => {
                  if (e.type == 'ukEvent') {
                    console.log(e);
                    return (
                      <div
                        className="flex gap-4 cursor-pointer w-full"
                        onClick={async () => {
                          /*const event = await GetEventById(e.informations._obj._id, application.apiKey);
                      console.log(event);*/
                          history.push('/v/events/' + e._id);
                          searchRef.current.resetSearch();
                        }}
                      >
                        <div className="text-right">
                          <p className="text-lg font-medium" style={{ color: config.mainColor }}>{`${moment(
                            e.informations.dateDebut || ''
                          ).format('HH:mm')}`}</p>
                          <p className="text-sm">{`${moment(e.informations.dateFin || '').format('HH:mm')}`}</p>
                        </div>
                        <div className="text-left border-b border-gray-400 w-full">
                          <p className="font-medium">{e.informations.title || ''}</p>
                          <p className="text-sm">{e.informations.subtitle || ''}</p>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <CustomObjectCell
                      className={'cursor-pointer hover:bg-gray-50 text-sm'}
                      object={e}
                      onClick={() => {
                        history.push(`/v/object/${e._id}`);
                        console.log(searchRef);
                        searchRef.current.resetSearch();
                        setResults(null);
                      }}
                    />
                  );
                })}
              </div>
            );
          })}
          <CloseButton
            className="absolute right-2 top-2 "
            onClick={() => {
              setResults(null);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default SearchBarComponent;
