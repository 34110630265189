import { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { DeleteObject, GetObjectsSearch, UpdateObject } from "../../../Services/UKObject";
import IphonePreview from "../Account/Admin/AppDesign/Iphone/IphonePreview";
import DocsEditview from "./Settings/docs.settings.view";
import DocsList from "./docs.list";
import DocsModal from "./docs.modal";
import DocsTabbar from "./docs.tabbar";

const DocsView = ({}) => {
    const { application, config } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const params = useRouteMatch();
    const [docsList, setDocsList] = useState(null);
    const [name, setName] = useState(null);
    const [folderStack, setFolderStack] = useState([null]);
    const [docSelected, setDocSelected] = useState(null);
    const [createDoc, setCreateDoc] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        setIsLoading(true);
        console.log("GET OBJECT", params.params.iddoc);
        let docs = [];
        var end = false;
        var page = 0;
        while (!end) {
            let tmpDocs = await GetObjectsSearch(params.params.iddoc, page, application.apiKey, (user || {}).authToken);
            docs = docs.concat(tmpDocs);
            page++;
            if (tmpDocs.length < 20) {
                end = true;
            }
        }

        console.log("DOCS", docs);
        if (!docs) return;
        docs = docs.filter((doc) => {
            console.log("DOC", folderStack, doc.informations.parent);
            if (!doc.informations.parent && folderStack[0] === null) return true;
            else if (doc.informations.parent === folderStack[0]) return true;
        });
        docs.sort((e1, e2) => {
            return e1.informations.index - e2.informations.index;
        });
        setDocsList(docs);
        setIsLoading(false);
    };

    useEffect(() => {
        try {
            const configDoc = config.docs.find((e) => {
                return e.id === params.params.iddoc;
            });
            setName(configDoc.name);
        } catch (e) {}
    }, [config, params]);

    useEffect(() => {
        if (!params || !user || !application) return;
        fetchData();
    }, [params, user, application, folderStack]);

    useEffect(() => {
        setDocSelected(null);
    }, [folderStack]);

    const updateDocsIndex = async (docs) => {
        for (const doc of docs) {
            await UpdateObject(doc._id, params.params.iddoc, doc.informations, application.apiKey, user.authToken);
        }
    };

    const updateDoc = async (doc) => {
        const previousDoc = docsList.find((e) => {
            return e._id === doc._id;
        });
        if (previousDoc === doc) return;

        await UpdateObject(doc._id, params.params.iddoc, doc.informations, application.apiKey, user.authToken);
        toast.success("Document mis à jour", { autoClose: 2000, position: "bottom-center" });
    };

    const deleteDoc = async (doc) => {
        await DeleteObject(doc._id, params.params.iddoc, application.apiKey, user.authToken);
        toast.success("Document supprimé", { autoClose: 2000, position: "bottom-center" });
        let newDocs = [...docsList];
        newDocs.splice(newDocs.indexOf(doc), 1);
        setDocsList(newDocs);
        setDocSelected(null);
    };

    const handleOnDocEdit = async (doc) => {
        if (doc && docSelected) {
            if (doc._id === docSelected._id) {
                return;
            }
            await updateDoc(docSelected);
        }
        setDocSelected(doc);
    };

    const getLastDocIndex = () => {
        if (!docsList || docsList.length === 0) return 0;
        return docsList.reduce((max, doc) => {
            return doc.informations.index > max ? doc.informations.index : max;
        }, 0);
    };

    return (
        <div>
            <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">{name}</p>
            <div className="flex w-11/12 m-auto">
                <div className="w-max gap-3 py-5">
                    <IphonePreview>
                        <DocsTabbar folderStack={folderStack} setFolderStack={setFolderStack} setCreateDoc={setCreateDoc} />
                        <div className="flex bg-gray-100 w-full h-full">
                            <div
                                className="mt-14 w-full"
                                onClick={(event) => {
                                    if (event.target === event.currentTarget) setDocSelected(null);
                                }}
                            >
                                {docsList && (
                                    <DocsList
                                        isLoading={isLoading}
                                        setFolderStack={setFolderStack}
                                        handleOnDocEdit={handleOnDocEdit}
                                        docs={docsList}
                                        setDocs={setDocsList}
                                        updateDocsIndex={updateDocsIndex}
                                    />
                                )}
                            </div>
                        </div>
                    </IphonePreview>
                </div>
                {docSelected && <DocsEditview doc={docSelected} setDocSelected={setDocSelected} updateDoc={updateDoc} onDelete={deleteDoc} />}
                {createDoc && <DocsModal newDoc={createDoc} setCreateDoc={setCreateDoc} parent={folderStack[0]} lastFileIndex={getLastDocIndex()} />}
            </div>
        </div>
    );
};

export default DocsView;
