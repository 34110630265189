import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import {toast} from "react-toastify";
import {useRouteMatch} from "react-router-dom";

function LockWrapper({id, children, customStyle = 'TEXT', forceUnlock = false}) {
  const {config, reloadApplication} = useContext(ApplicationContext);
  let {params} = useRouteMatch();
  const [isLock, setIsLock] = useState(true);

  useEffect(() => {
    reloadApplication(window.location.hostname);
  }, [params]);

  useEffect(() => {
    const unlocked = config?.unlocked;
    if (unlocked) {
      const found = unlocked.findIndex(e => e.toLowerCase() === id.toLowerCase());
      if (found !== -1) setIsLock(false);
    }
  }, [config])

  return (<div>
    {!forceUnlock && isLock && customStyle === 'TEXT' && <div>
      <p className="text-lg text-gray-700">Vous n'avez pas acces à cette fonctionnalité, contactez nous pour la debloquer</p>
    </div>}
    {!forceUnlock && isLock && customStyle === 'LOCK' && <div className="cursor-pointer opacity-60" onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      return toast.error('Cette fonctionalité est bloqué, contactez nous pour la débloquer', {autoClose: 3000})
    }}>
      <div className='pointer-events-none'>
        {children}
      </div>
    </div>}
    {(!isLock || forceUnlock) && <div>
      {children}
    </div>}
  </div>)
}

export default LockWrapper;
