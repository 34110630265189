import { useState } from "react";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FixedSizeGrid as Grid } from "react-window";

const libs = [fas, far];
const icons = [];
libs.forEach((lib) => {
  Object.keys(lib).forEach((key) => {
    icons.push(lib[key].iconName);
  })
})

const IconPicker = ({currentIcon, setIcon, onChange = () => {}, columns = 5, width = 50, height = 50}) => {
  const [pickerIsOpen, setPickerIsOpen] = useState(false);
  const [iconHovered, setIconHovered] = useState(null);
  const [search, setSearch] = useState("");
  const [timeout, setTimeoutValue] = useState(0);
  const [iconsList, setIcons] = useState(icons);

  const selectIcon = (icon) => {
    setIcon(icon);
    setPickerIsOpen(false);
    if (onChange) {
      onChange(icon);
    }
  }

  const getResults = (value) => {
    if (value === "") {
      setIcons(icons);
      setSearch("");
    } else {
      setSearch(value);
      const newIconsList = icons.filter((icon) => {
        return icon.includes(value);
      })
      setIcons(newIconsList);
    }
  }

  const Cell = ({rowIndex, columnIndex, key, style}) => {
    if (!iconsList[rowIndex * columns + columnIndex]) {
      return (
        <div></div>
      )
    }
    return (
      <div key={key} style={style}>
        <FontAwesomeIcon className="cursor-pointer hover:opacity-60" icon={iconsList[rowIndex * columns + columnIndex]} onClick={() => {
          selectIcon(iconsList[rowIndex * columns + columnIndex]);
        }} onMouseEnter={() => {
          setIconHovered(iconsList[rowIndex * columns + columnIndex]);
        }} onMouseLeave={() => {
          setIconHovered(null)
        }} />
      </div>
    )
  }

  return (
    <div>
      <div className="flex cursor-pointer border rounded shadow-inner w-max items-center justify-center" onClick={() => {
        setPickerIsOpen((prevState) => !prevState)
      }} style={{
        minWidth: "50px",
        minHeight: "50px",
      }}>
        {currentIcon && <FontAwesomeIcon className="text-center" icon={currentIcon} size="2x" />}
        {!currentIcon && <p>Vide</p>}
      </div>
      {pickerIsOpen &&
        <div className="absolute z-50">
          <input className="flex border rounded rounded-l h-8"
                 placeholder="Rechercher"
                 type="text"
                 value={search}
                 onChange={(e) => {
                   setSearch(e.target.value)
                   if (timeout) clearTimeout(timeout);
                   setTimeoutValue(
                     setTimeout(() => {
                       getResults(e.target.value);
                     }, 500)
                   );
                 }} style={{
            width: "14rem",
          }}>
          </input>
          <div className="grid grid-cols-2 gap-1">
            <div className="border bg-white">
              <Grid
                columnCount={columns}
                columnWidth={25}
                rowCount={Math.ceil(iconsList.length / columns)}
                rowHeight={25}
                width={width}
                height={height}
              >
                {Cell}
              </Grid>
            </div>
            <div className="w-1/3 h-1/3 border rounded w-fit h-fit bg-white" style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              {iconHovered && <FontAwesomeIcon className="" icon={iconHovered} size="3x" />}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default IconPicker
