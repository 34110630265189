import {useEffect, useRef, useState, useContext} from "react";
import SearchBarInput from "../Common/SearchBar/SearchBarInput";
import CommonButton from "../Common/Button/CommonButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket, faX} from "@fortawesome/pro-solid-svg-icons";
import {ApplicationContext} from "../../Context/ApplicationContext";

export function getHasInteraction(features, geoJSON) {
  if (features.properties.hasInteraction) {
    return true;
  } else {
    let number = geoJSON.categories.find(
      elem => elem.id === features.properties.category,
    );
    number = number?.hasInteraction;
    return number;
  }
}

const ItinerarySearch = ({currentFeature, GEOJSON, geoJSON, startPathfinder, setItineraryOpen, setSelectedFeature}) => {
  const [filter, setFilter] = useState({cat: null, text: null});
  const [categories, setCategories] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [itinerary, setItinerary] = useState({first: currentFeature});
  const { config } = useContext(ApplicationContext)
  const searchRef = useRef();

  useEffect(() => {
    setCategories(findAllCat());
  }, []);

  useEffect(() => {
    setFilter({cat: null, text: null});
    setItinerary({first: currentFeature});
  }, [currentFeature])

  useEffect(() => {
    searchFunctionInPlan();
  }, [filter]);

  function findMatchingBuilding() {
    let newBuildingList;

    if (!filter.cat) {
      const tmp = GEOJSON.features.filter(elem => getHasInteraction(elem, geoJSON));
      newBuildingList = tmp.map(elem => ({
        name: elem.properties.linkName ?? elem.properties.name ?? '',
        elem: elem,
      }));
      return newBuildingList;
    }
    const tmp = GEOJSON.features
    .filter(elem => elem.properties.category === filter.cat.id)
    .filter(elem => getHasInteraction(elem, geoJSON));

    newBuildingList = tmp
    .map(elem => {
      return {
        name: elem.properties.linkName ?? elem.properties.name ?? '',
        elem: elem,
      };
    })
    .filter(e => e.name !== '');
    return newBuildingList;
  }

  function findAllCat() {
    const listOfCategories =
      geoJSON.categories?.filter(elem => !!elem.hasInteraction) ??
      [];
    const result = listOfCategories
    .map(elem => {
      const listStand = GEOJSON.features.filter(
        e =>
          e.properties.category === elem.id &&
          (!!e.properties.linkName || !!e.properties.name),
      );
      if (listStand.length === 0) {
        return;
      }
      return {
        name: elem.name,
        id: elem.id,
        list: listStand,
        number: listStand.length,
      };
    })
    .filter(elem => elem !== undefined);
    const tmp = GEOJSON.features.filter(
      e => e.properties.category === undefined && e.properties.hasInteraction,
    );
    if (tmp.length > 0) {
      result.push({
        name: tmp.length > 1 ? 'Autres' : 'Autre',
        number: tmp.length,
        list: tmp,
        id: undefined,
      });
    }
    return result;
  }

  function searchFunctionInPlan() {
    let newCatList = !filter.cat ? findAllCat() : [];
    let newBuildingList = filter.cat ? findMatchingBuilding() : [];

    if (filter.text) {
      newBuildingList = findMatchingBuilding();
      newCatList = newCatList.filter(elem => {

          return elem.name.toLowerCase().includes(filter.text.toLowerCase())
        }
      );
      newBuildingList = newBuildingList.filter(elem => {
        var j = JSON.stringify(elem)
        if (j.toLowerCase().includes(filter.text.toLowerCase())){return true}
          return elem.name.toLowerCase().includes(filter.text.toLowerCase())
        }
      );
    }
    setCategories(newCatList);
    setBuildings(newBuildingList);
  }

  return (<div className=''>

    {/* Search */}
    <div className='p-3 flex flex-col items-center gap-2 w-full'>
      <p className={"text-left w-full"}>Ou vous trouvez-vous ?</p>
      <div className="flex gap-2 h-max w-full">
        <SearchBarInput value={filter.text} className={"bg-white w-full"} onSearch={(e) => {
          setFilter({...filter, text: e});
        }} ref={searchRef}/>
      </div>

      {itinerary.second && <div
        className={`flex items-center gap-1 fixed bottom-2 left-2 right-2 lg:bottom-auto lg:left-auto lg:right-auto lg:relative lg:w-full cursor-pointer border rounded py-2 lg:py-1 justify-center transition-all px-1 hover:opacity-70 `}
        style={{
          color: config.colorOverMainColor,
          backgroundColor: config.mainColor ,
          fontSize:  16
        }}
        onClick={
          () => {
            if (startPathfinder({...itinerary, first: currentFeature})) {
              setItineraryOpen(false)
              setSelectedFeature({})
            }
          }
        }>Lancer le trajet<FontAwesomeIcon icon={faRocket}/>
      </div>
      }

    </div>

    {filter?.cat &&
      <div className={'flex flex-row w-fit p-1 rounded border  hover:opacity-70 ml-3 mt-2 bg-white px-2'} onClick={() => {
        setFilter({...filter, cat: null})
        //searchRef.current.resetSearch();
      }}>
        <p>{filter?.cat?.name}</p>
        <div className={'cursor-pointer pl-2'}>
          <FontAwesomeIcon icon={faX} color={'red'} size={"sm"}/>
        </div>
      </div>
    }


    {/* Categories */}
    <div className="flex flex-col gap-2 pt-3 pl-3 border-b-1 w-full ">
      <div className={"text-left w-full"}>{categories.length ? 'Filtre :' : ''}</div>
      <div className={"flex flex-row flex-wrap gap-2"}>
      {categories && categories.map((category, index) => {
        return (<div key={index} className="p-1 rounded border bg-white hover:opacity-70 cursor-pointer px-2" onClick={() => {
          setFilter({...filter, cat: {id: category.id, name: category.name}, text : null});
        }}>
          {category.name}
        </div>)
      })}

      </div>

    </div>

    {/* Stands */}
    <div className="flex flex-col gap-2 pt-3 pl-3 pr-3 border-b-1 w-full overflow-scroll pb-24 " style={{
      maxHeight : "calc(100vh - 235px)",
      overflow : "auto"
    }}>
      {buildings && (filter.cat || filter.text) && buildings.length == 0 && <p className={"italic text-center "}>Aucun élément</p>}
      {buildings && buildings.map((building, index) => {
        try {
          if (building.elem.id == currentFeature.properties.id) {
            return null
          }
        } catch (e){}
        return (<div key={index} className={`p-1 rounded border lg:hover:opacity-70 cursor-pointer flex flex-row justify-between px-2 py-2`}
                     style={{
                       backgroundColor : itinerary.second && building.elem.id === itinerary.second.id ? config.mainColor : "white",
                       color :itinerary.second && building.elem.id === itinerary.second.id ? config.colorOverMainColor : "black",
                     }}

                     onClick={() => {
          setItinerary(prevState => {
            return {...prevState, second: prevState?.second?.id === building.elem.id ? null : building.elem}
          })
        }}>

          <div className={"flex flex-col"}>
            <p className=" text-left font-bold">
              {building.elem.properties.name}
            </p>
            {building.elem.properties.linkName && <p className={"mt-[-5px]"}>{building.elem.properties.linkName}</p>}
          </div>

        </div>);
      })}



    </div>
  </div>);
}

export default ItinerarySearch;
