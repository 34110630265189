const URL = "https://api.applications.pitchr.fr"

export const GetApplicationById = async (id) => {
  try {
    const response = await fetch(`${URL}/applications/apikey/${id}`)
    if (response.status !== 200) {
      return null
    }
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
}

export const GetApplicationInfoById = async (id) => {
  try {
    const response = await fetch(`${URL}/applications/${id}`)
    if (response.status !== 200) {
      return null
    }
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
}


export const GetApplicationByInviteCode = async (code) => {
  const response = await fetch(`${URL}/applications/invitcode/${code}`)
  const json = await response.json()
  return json
}


export const GetApplicationBySousDomain = async (domain) => {
  const response = await fetch(`${URL}/applications/domain?domain=${domain}`)
  const json = await response.json()
  return json
}

export const GetApplicationConfig = async (apiKey) => {
  const response = await fetch(`${URL}/jsonconfig/${apiKey}`)
  const json = await response.json()
  return json
}

export const UpdateJsonConfig = async (apiKey, config) => {
  try {
    const response = await fetch(`${URL}/jsonconfig/${apiKey}`,
      {
        method: 'POST',
        headers: {
          'ApiKey': apiKey,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      }
    )
    if (response.status !== 200) {
      return null
    }
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
};

export const UpdateApplication = async (apiKey, authToken, id, object) => {
  try {
    const response = await fetch(`${URL}/applications/${id}`,
      {
        method: 'POST',
        headers: {
          'ApiKey': apiKey,
          'AuthToken': authToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(object)
      }
    )
    if (response.status !== 200) {
      return null
    }
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
};

export const GetStat = async (appId) => {
  try {
    const response = await fetch(`https://usekey-stats.herokuapp.com/all/${appId}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }
    )
    if (response.status !== 200) {
      return null
    }
    const json = await response.json()
    return json
  } catch (e) {
    return []
  }
}

export const CreateNewApp = async (name, description = 'Application') => {
  try {
    const response = await fetch(`${URL}/applications/`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          description: description
        })
      }
    )
    if (response.status !== 200) {
      return response.status
    }
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
};

export const GetAppDemo = async (body) => {
  try {
    const response = await fetch(`${URL}/demo`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    )
    if (response.status !== 200) {
      return response.status
    }
    const json = await response.json()
    return json
  } catch (e) {
    return null
  }
};