import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase, faXmark, faRoute, faCircleInfo} from "@fortawesome/pro-regular-svg-icons";
import StandDetailView from "./stand.detail.view";
import {useEffect, useState} from "react";
import ItinerarySearch from "./itinerary.search";
import {useLocation} from "react-router-dom";

const StandPopupView = ({feature, mapConfig, startPathfinder, setSelectedFeature}) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const [itineraryOpen, setItineraryOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);
  //let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  useEffect(() => {
    setItineraryOpen(false);
    if (feature) {
      setCurrent({feature: feature});
      setCurrentFeature({id: feature.id, geometry: feature._geometry, properties: feature.properties})
    }
  }, [feature])

  function getCategoryTitle(catID) {
    if (!catID) {
      return 'Autre(s)';
    }
    const catList = mapConfig.categories;
    const ans = catList.find(elem => elem.id === catID);
    return ans ? ans.name : null;
  }

  if (!current?.feature?.properties) return null;
  return (
    <div className={`flex flex-col  max-h-screen absolute overflow-hidden lg:rounded-t-lg
  bg-gray-100 z-30 bottom-0 right-0 w-full transition-all ${detailOpen || itineraryOpen ? "min-h-screen" : "min-h-[50px]"}
  lg:bottom-0 lg:right-0 lg:w-1/3 lg:my-auto lg:rounded-tr-none lg:rounded-l-lg lg:h-fit`}>

    <div className="flex justify-between items-center p-1  px-4 h-[70px] bg-white gap-4">

      <div className={"flex flex-col text-left"}>
        <p>{getCategoryTitle(current.feature.properties.category)}</p>
        <div className={"flex items-center mt-[-5px]"}>
      <p className="text-gray-800  font-bold">
        {current.feature.properties.name}
      </p>
      {current.feature.properties.linkName && <p className={" ml-[2px]"}>- {current.feature.properties.linkName}</p>}
        </div>
      {current.feature.properties.linkSub && <p className={"mt-[-5px] font-light"}>{current.feature.properties.linkSub}</p>}
      </div>
      <div className="flex gap-4">
        <FontAwesomeIcon className='opacity-100 lg:hover:opacity-70' onClick={() => {
            setItineraryOpen(prevstate => !prevstate)
            setDetailOpen(false)
        }} icon={itineraryOpen ? faXmark : faRoute} size={'2x'} color={'rgb(31 41 55)'}/>
        {feature.properties && feature.properties.link &&
        <FontAwesomeIcon className='lg:hidden lg:hover:opacity-70' onClick={() => {
          const isMobile = new URLSearchParams(location.search).get("mobile");
          //const isMobile = searchParams.get("mobile")
          if (isMobile == "true"){
            try {
              window.ReactNativeWebView.postMessage(JSON.stringify(feature));
            } catch (e){

            }
          } else {
            setDetailOpen(prevstate => !prevstate)
            setItineraryOpen(false)
          }
        }} icon={faCircleInfo} size={'2x'} color={detailOpen ? '#56ABFA' : 'rgb(31 41 55)'}/>}
      </div>
    </div>
    <div className={`${itineraryOpen ? 'max-h-screen' : 'max-h-0 lg:max-h-screen'} ${!itineraryOpen && 'hidden'}`}>
      <ItinerarySearch currentFeature={currentFeature} GEOJSON={mapConfig.geoJSON} geoJSON={mapConfig} startPathfinder={startPathfinder} setItineraryOpen={setItineraryOpen} setSelectedFeature={setSelectedFeature}/>
    </div>
    <div className={`${detailOpen ? 'max-h-screen' : 'max-h-0 lg:max-h-screen'} ${itineraryOpen && 'hidden'}`}>
      <StandDetailView properties={current.feature.properties} mapConfig={mapConfig}/>
    </div>
  </div>);
}

export default StandPopupView;
