import {GetObjectsAll} from "../../../Services/UKObject";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import {UserContext} from "../../../Context/UserContext";
import {ClipLoader} from "react-spinners";
import {UKObjectClass} from "../../../Class/UKObjectClass";
import DrawListOfCard from "./draw.object.card";
import GroupBy from "./group_by_list";
import TableIframeObject from "./table.iframe.object";
import ModalIframe from "../modal.iframe";
import SearchFilterIframe from "../Common/search.filter.iframe";

function ObjectIframeComponent({params}) {
  const objectType = window.location.pathname.substring('/iframe/object/'.length)
  const {application, language, config} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [objectList, setObjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchField, setSearchField] = useState('');
  const [newObjectList, setNewObjectList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const needSearch = params.get('search') === 'true';
  const needFilter = params.get('filter') === 'true';
  const needGroup = params.get('group_by') !== "undefined";
  const styleToDisplay = params.get('display');
  const listToDisplay = (searchField !== '' || filterList.length > 0) ? newObjectList : objectList;
  const [modalOpen, setModalOpen] = useState({open: false, info: null})

  const getFilter = () => {
    const objectConfig = config.customObject.find(e => e.id === objectType) || config.eventObject || [];
    const resp = objectConfig.custom.filter(elem => elem.filter === true && elem.values?.length > 0);
    setFilter(resp);
  }

  const getGroupsList = (objectList) => {
    const objectConfig = config.customObject.find(e => e.id === objectType) || config.eventObject || [];
    const groupId = params.get('group_by');
    const withLang = `${groupId}_${language.id}`;
    if (!groupId ||groupId === 'undefined') {
      return objectList;
    }
    let groupList = objectConfig.custom.find(elem => elem.id === groupId);
    if (!groupList) {
      return objectList;
    }

    groupList = groupList.values;

    const result = groupList.map(elem => {
      const list = objectList.filter(e => {
        const value = e.json.informations[withLang] ?? e.json.informations[groupId];
        return value === elem;
      });
      if (list.length === 0)
        return;
      return ({value: elem, list: list});
    }).filter(elem => elem !== undefined);

    return result;
  }

  const getObject = async () => {
    const newObjs = await GetObjectsAll(objectType, application.apiKey, (user || {}).authToken);
    const result = newObjs.map(elem => {
      return (new UKObjectClass(elem, config))
    });
    const sortedList = sortList(result);
    getGroupsList(sortedList)
    setObjectList(sortedList);
    getFilter();
    setIsLoading(false);
  };

  const reloadListWithFilters = () => {
    let result = objectList;
    if (searchField === '' && filterList.length < 1) {
      return;
    }
    if (searchField !== '') {
      result = objectList.filter(elem => elem.getTitle(language).toLowerCase().includes(searchField.toLowerCase()));
    }
    if (filterList.length > 0) {
      for (const i of filterList) {
        const withLang = `${i.name}_${language?.id}`
        result = (result.filter(elem => {
          const compare = elem.json.informations[withLang] ?? elem.json.informations[i.name];
          return (i.value === compare);
        }))
      }
    }
    setNewObjectList(result);
  }

  const sortList = (objectList) => {
    let sortId = params.get('sort_by');
    if (!sortId || sortId === 'undefined') {
      return objectList;
    }
    const list = [...objectList];
    const withLang = `${sortId}_${language.id}`
    const ans = list.sort((a, b) => {
      let first = a.json.informations[withLang] ?? a.json.informations[sortId];
      first = first ?? "";
      let second = b.json.informations[withLang] ?? b.json.informations[sortId];
      second = second ?? "";
      return (first.toLowerCase() > second.toLowerCase() ? 1 : -1);
    })
    return (ans);
  }

  const newFilter = (bool, cat, value) => {
    let list = [...filterList];
    if (bool) {
      list.push({name: cat, type: 'reg', value: value});
    } else {
      list = list.filter(elem => {
        return !((cat === elem.name) && (value === elem.value));
      });
    }
    setFilterList(list);
  }

  useEffect(() => {
    getObject();
  }, []);

  useEffect(() => {
    reloadListWithFilters();
  }, [searchField, filterList]);

  if (isLoading) {
    return (
      <div className={'m-5'}>
        <ClipLoader loading={true}/>
      </div>
    )
  }

  if ((!objectList && !newObjectList) || (objectList.length === 0 && newObjectList.length === 0)) {
    return (
      <div className={'bg-gray-100 min-h-screen pt-3 flex justify-center'}>
        <p className={'text-2xl mt-5 font-semibold'}>Il n'y a aucun objet</p>
      </div>
    )
  }

  return (
    <div className={'bg-gray-100 min-h-screen pt-3 flex justify-center'}>
      <ModalIframe modalIsOpen={modalOpen} setmodalIsOpen={setModalOpen}/>
      <SearchFilterIframe newFilter={newFilter} searchField={searchField} needFilter={needFilter}
                          needSearch={needSearch} filter={filter} setSearchField={setSearchField} />
      {styleToDisplay === 'table' ?
        <TableIframeObject list={listToDisplay} objectType={objectType} setModalOpen={setModalOpen}/>
        :
      needGroup ?
        <div className={'flex-col flex-wrap justify-center w-2/3 m-8 h-max'}>
          <GroupBy listSorted={getGroupsList(listToDisplay)} setModalOpen={setModalOpen} />
        </div>
        :
      <div className={'flex flex-row flex-wrap w-2/3 m-8 h-max'}>
        <DrawListOfCard list={listToDisplay} setModalOpen={setModalOpen} />
      </div>
      }
      <div className={'pt-5'}/>
    </div>
  )
}

export default ObjectIframeComponent;