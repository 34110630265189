const URL = 'https://usekey-billetterie.herokuapp.com';
//const URL = 'http://localhost:3033';

export const GetTickets = async (apiKey, authToken) => {
  try {
    const response = await fetch(`${URL}/ticket`, {
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};

export const GetTicketsPdf = async (ticketId, apiKey, authToken, reset = false) => {
  try {
    const response = await fetch(`${URL}/ticketgenerate/${ticketId}/pdf` + (reset ? '?reset=1' : ''), {
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};

export const GetTicketsByUser = async (userId, apiKey, authToken, reset = false) => {
  try {
    const response = await fetch(`${URL}/ticket/user/${userId}` + (reset ? '?reset=1' : ''), {
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};

export const GenerateBillet = async (id, object, apiKey) => {
  try {
    const response = await fetch(`${URL}/ticket/${id}/generate`, {
      method: 'PUT',
      headers: {
        ApiKey: apiKey,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};

export const UpdateBillet = async (id, object, apiKey, authToken) => {
  try {
    const response = await fetch(`${URL}/ticket/${id}`, {
      method: 'POST',
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};
export const CreateBillet = async (object, apiKey, authToken) => {
  try {
    const response = await fetch(`${URL}/ticket`, {
      method: 'PUT',
      headers: {
        ApiKey: apiKey,
        AuthToken: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    if (response.status !== 200) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch (e) {
    return null;
  }
};
