import { faCheck, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ApplicationContext } from '../../Context/ApplicationContext';
import { GenerateBillet, GetTicketsPdf } from '../../Services/Billetterie';
import { GetTicketUser } from '../../Services/User';
import AddButton from '../Common/Button/AddButton';

const TicketLine = ({ ticket }) => {
  const { application, config, TRAD } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  return (
    <div className="text-left border-b text-gray-500 font-light mb-3 pb-2 flex justify-between">
      <div>
        <p>
          {ticket.ticket.lastName} {ticket.ticket.firstName}
        </p>
        <p>{ticket.ticket.email}</p>
      </div>
      <div>
        <AddButton
          title={loading ? TRAD('LOADING', 'Chargement ... ') : TRAD('TICKET_DOWNLOAD', 'Telecharger le billet')}
          icon={faDownload}
          onClick={async () => {
            setLoading(true);
            console.log(ticket.result);
            const ticketPDF = await GetTicketsPdf(ticket.result._id, application.apiKey);
            window.open(ticketPDF.url, '_blank');
            setLoading(false);
          }}
        />
      </div>
    </div>
  );
};
function BilletterieConfirmation({ tickets }) {
  const { application, config, TRAD } = useContext(ApplicationContext);
  const [cgu, setCgu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ended, setEnded] = useState(false);
  const [result, setResult] = useState([]);
  if (!application) {
    return null;
  }

  var json = {
    firstName: 'remi',
    lastName: 'telenczak',
    email: 'remitelen@gogo.com',
    user_custom_type: '9876ZZ',
    groups: ['1'],
  };

  //https://usekey-billetterie.herokuapp.com/ticket/5e4c53f2880f8e00169c4b8a/generate
  /*{"firstName":"remi","lastName":"telenczak","email":"remitelen@gogo.com","user_custom_type":"9876ZZ","civilite":"Madame","pays":"France - FR","code_postal":"59890","city":"LILLE","age":"18 - 24 ans","status":"À la recherche d'un emploi","sector":"","publicite":"Publicité dans la presse","need_job":"Ouvrier / Employé","need_sector":["Bâtiment, travaux publics et énergie"],"groups":["1"],"wantinfo":[]}*/
  console.log(application);

  const generateTicket = async () => {
    console.log('GENERATE 1');
    setLoading(true);
    var result = [];
    for (var i = 0; i < tickets.length; i++) {
      console.log('GENERATE 2');
      var ticket = { ...tickets[i] };
      var obj = config.inscription.customs.find((e) => e.id == ticket.user_custom_type);
      ticket.groups = ((obj || {}).defaultGroup || '').split(',');
      console.log(ticket);
      var ticketId = ticket._config._id;
      console.log('GENERATE 3');
      delete ticket._config;
      console.log('ICICIICICICi');
      try {
        const a = await GenerateBillet(ticketId, ticket, application.apiKey);
        const ticketPDF = GetTicketUser(a.userId, application.apiKey);

        console.log(a);
        result.push({ ticket: ticket, result: a });
        console.log(obj);
        console.log(ticket);
      } catch (e) {}
    }
    setResult(result);
    setLoading(false);
    setEnded(true);
  };

  return (
    <div className=" min-h-screen text-gray-700 relative">
      <div>
        {ended &&
          result.map((ticket) => {
            return <TicketLine ticket={ticket} />;
          })}
        {!ended &&
          tickets.map((ticket) => {
            return (
              <div className="text-left border-b text-gray-500 font-light mb-3 pb-2">
                <p>
                  {ticket.lastName} {ticket.firstName}
                </p>
                <p>{ticket.email}</p>
              </div>
            );
          })}
      </div>
      {!ended && (
        <div className="flex items-start">
          <label className="font-light text-gray-500 text-left w-full">
            <input
              type="checkbox"
              className="mr-2"
              checked={cgu}
              onChange={(v) => {
                setCgu(v.target.checked);
              }}
            />
            {TRAD('I_ACCEPT', "J'accepte les")}{' '}
            <span
              className="text-blue-400 hover:opacity-50 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open('https://admin.usekey.fr/assets/pdf/cgucgv.pdf', '_blank');
              }}
            >
              {TRAD('CGU', "conditions générales d'utilisation")}
            </span>
          </label>
        </div>
      )}
      {!ended && (
        <div className="pt-2 flex justify-end">
          <AddButton
            className={!cgu ? 'opacity-40' : ''}
            title={TRAD('VALIDATE', 'Valider')}
            icon={faCheck}
            onClick={() => {
              console.log(tickets);
              generateTicket();
            }}
          />
        </div>
      )}
      {ended && (
        <div className="text-xl font-bold">
          <p>
            {TRAD('TICKET_VALIDATE_ONE', 'Merci, votre inscription a bien été validée.')} <br />
            {TRAD('TICKET_VALIDATE_TWO', 'Vous allez recevoir un e-mail avec votre billet prochainement.')}
          </p>
        </div>
      )}
      {loading && (
        <div className="h-full w-full bg-white bg-opacity-70 absolute top-0 left-0 py-4 font-bold text-xl">
          <p className="mb-3">{TRAD('TICKET_VALIDATE_WAIT', 'Validation des inscriptions ...')}</p>
          <ClipLoader color="rgba(147, 197, 253, var(--tw-border-opacity))" />
        </div>
      )}
    </div>
  );
}

export default BilletterieConfirmation;
