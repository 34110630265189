import { along, distance, envelope, featureCollection, lineString, point } from "@turf/turf";

const dragPan = {
  enable(ctx) {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (!ctx.map || !ctx.map.dragPan || !ctx._ctx || !ctx._ctx.store || !ctx._ctx.store.getInitialConfigValue) return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('dragPan')) return;
      ctx.map.dragPan.enable();
    }, 0);
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.dragPan.disable();
    }, 0);
  },
};

const doubleClickZoom = {
  enable: ctx => {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map ||
        !ctx.map.doubleClickZoom ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      )
        return;
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue("doubleClickZoom")) return;
      ctx.map.doubleClickZoom.enable();
    }, 0);
  },
  disable(ctx) {
    setTimeout(() => {
      if (!ctx.map || !ctx.map.doubleClickZoom) return;
      // Always disable here, as it's necessary in some cases.
      ctx.map.doubleClickZoom.disable();
    }, 0);
  }
};

const getSquareBox = (startPoint, e, isSquare) => {
  if (!startPoint || !e) return null;
  const points = [[startPoint[0], startPoint[1]], [e.lngLat.lng, e.lngLat.lat]]

  const rectangle = envelope(featureCollection([
    point(points[0]),
    point(points[1]),
  ]))
  if (!isSquare) return rectangle;
  const normalizedPoints = rectangle.geometry.coordinates[0].filter(e => points.findIndex(a => a[0] === e[0] && a[1] === e[1]) === -1);
  if (!normalizedPoints[0] || !normalizedPoints[1]) return null;
  const min = Math.min(distance(points[0], point(normalizedPoints[0])), distance(points[0], point(normalizedPoints[1])));
  return envelope(featureCollection([
    along(lineString([points[0], normalizedPoints[0]]), min),
    along(lineString([points[0], normalizedPoints[1]]), min)
  ]));
}

const DrawSquareMode = {
  onSetup: function (opts) {
    const square = this.newFeature({
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [[]]
      }
    });
    this.addFeature(square);
    this.clearSelectedFeatures();
    doubleClickZoom.disable(this);
    this.updateUIClasses({mouse: "add"});
    dragPan.disable(this);
    this.setActionableState({
      trash: true
    });
    return {
      square,
      shiftIsPressed: false
    };
  },

  onTap: function (state, e) {
    // emulate 'move mouse' to update feature coords
    if (state.startPoint) this.onMouseMove(state, e);
    // emulate onClick
    this.onClick(state, e);
  },

  onMouseDown: function (state, e) {
    state.startPoint = [e.lngLat.lng, e.lngLat.lat];
  },

  onMouseUp: function (state, e) {
    if (state.startPoint && state.startPoint[0] !== e.lngLat.lng && state.startPoint[1] !== e.lngLat.lat) {
      this.updateUIClasses({mouse: "pointer"});
      this.changeMode("simple_select", {featureIds: [state.square.id]});

    } else {
      this.deleteFeature([state.square.id], {silent: true});
      this.changeMode("simple_select", {featureIds: [state.square.id]});
    }
  },

  onDrag: function (state, e) {
    // if startPoint, update the feature coordinates, using the bounding box concept
    // we are simply using the startingPoint coordinates and the current Mouse Position
    // coordinates to calculate the bounding box on the fly, which will be our square
    if (state.startPoint) {
      const square = getSquareBox(state.startPoint, e, state.shiftIsPressed)?.geometry.coordinates[0];
      if (square) {
        state.square.updateCoordinate(
          "0.0",
          square[0][0],
          square[0][1]
        ); //minX, minY - the starting point
        state.square.updateCoordinate(
          "0.1",
          square[1][0],
          square[0][1]
        ); // maxX, minY
        state.square.updateCoordinate(
          "0.2",
          square[2][0],
          square[2][1]
        ); // maxX, maxY
        state.square.updateCoordinate(
          "0.3",
          square[0][0],
          square[3][1]
        ); // minX,maxY
        state.square.updateCoordinate(
          "0.4",
          square[0][0],
          square[0][1]
        ); //minX,minY - ending point (equals to starting point)
      }
    }
  },

  onKeyUp: function (state, e) {
    if (e.keyCode === 27) return this.changeMode("simple_select");
    if (e.keyCode === 16) state.shiftIsPressed = false;
  },

  onKeyDown: function (state, e) {
    if (e.keyCode === 16) state.shiftIsPressed = true;
  },

  onStop: function (state) {
    doubleClickZoom.enable(this);
    this.updateUIClasses({mouse: "none"});
    this.activateUIButton();

    // check to see if we've deleted this feature
    if (this.getFeature(state.square.id) === undefined) return;

    //remove last added coordinate
    state.square.removeCoordinate("0.4");
    if (state.square.isValid()) {
      this.map.fire("draw.create", {
        features: [state.square.toGeoJSON()]
      });
    } else {
      this.deleteFeature([state.square.id], {silent: true});
    }
  },

  toDisplayFeatures: function (state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.square.id;
    geojson.properties.active = isActivePolygon ? "true" : "false";
    if (!isActivePolygon) return display(geojson);

    if (!state.startPoint) return;
    return display(geojson);
  },

  onTrash: function (state) {
    this.deleteFeature([state.square.id], {silent: true});
    this.changeMode("simple_select");
  }
};

export default DrawSquareMode;