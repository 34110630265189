import { FiPlus } from 'react-icons/fi';
import { ButtonMenu, CheckboxToggle, Input, MenuItem } from 'react-rainbow-components';

export default function ObjectTableComponent({value, row: {index}, column: {id}, updateData, saveData}) {

  const types = [
    {title: "Texte", type: "text"},
    {title: "Long texte", type: "bigtext"},
    {title: "Email", type: "email"},
    {title: "Lien web", type: "web"},
    {title: "Sélecteur", type: "selector"},
    {title: "Multiple Sélecteur", type: "multipleSelector"},
    {title: "Lien objet", type: "links"},
    {title: "Catégorie", type: "category"},
    {title: "Photo", type: "picture"},
    {title: "Cv", type: "cv"}
  ]

  const findCorrespondingTitle = (type) => {
    return (types.find((e) => {
      return type === e.type;
    }) || {}).title || ""
  }

  let input = <input
    className="custominputbox w-full"
    type="text"
    value={value}
    onChange={(e) => {
      updateData(index, id, e.target.value)
    }}
    onBlur={() => saveData(index)}
  />

  if (id === "type") {
    input = <ButtonMenu
      buttonVariant="border-filled"
      buttonSize="small"
      style={{color: '#413751'}}
      label={findCorrespondingTitle(value)}
      className="block w-full py-2 px-3"
      onBlur={() => saveData(index)}
    >
      {types.map((e) => {
        return (
          <MenuItem
            label={e.title}
            onClick={() => {
              updateData(index, id, e.type)
            }}
          />
        )
      })}
    </ButtonMenu>
  }

  if (id === "important" || id === "isTitle" || id === "isSubTitle" || id === "hidden") {
    input = <CheckboxToggle value={value} className="w-full" onChange={() => {
      updateData(index, id, !value)
    }} onBlur={() => saveData(index)}/>
  }

  if (id === "values") {
    if (!value) {
      updateData(index, id, [])
      value = []
    }
    input = value.map((e, indexValues) => <div className="w-full flex bg-gray-100">
        <input
          className="custominputbox"
          type="text"
          value={e}
          onChange={(e) => {
            let newValue = [...value]
            newValue[indexValues] = e.target.value;
            updateData(index, id, newValue)
          }}
          onBlur={() => saveData(index)}
        />
        {indexValues === value.length - 1 && <button
          className="h-10 float-right pr-2"
          style={{color: '#413751'}}
          onClick={() => {
            const newArr = [...value]
            newArr.push("");
            updateData(index, id, newArr)
          }}
          onBlur={() => saveData(index)}
        >{<FiPlus/>}</button>}
      </div>
    )
  }

  return (
    <>
      {input}
    </>
  )
}
