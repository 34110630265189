import VoteSettings from "./votes.settings";
import VoteObjectListSettings from "./votes.objectlist.view";
import VotesResultsView from "./votes.results.view";

const VoteCellInterface = ({voteInfo, pageState, setPageState, updateVoteInfo, saveVoteInfo}) => {
  return (
    <div className="flex bg-white">
      <VoteSettings voteInfo={voteInfo} updateVoteInfo={updateVoteInfo} pageState={pageState} setPageState={setPageState} saveVoteInfo={saveVoteInfo}/>
      <VoteObjectListSettings voteInfo={voteInfo} updateVoteInfo={updateVoteInfo} pageState={pageState} setPageState={setPageState} />
      <VotesResultsView voteInfo={voteInfo} pageState={pageState}/>
    </div>
  );
}

export default VoteCellInterface
