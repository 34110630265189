import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { HelpText } from "react-rainbow-components";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UpdateJsonConfig } from "../../../../Services/Application";
import CloseButton from "../../../Common/Button/CloseButton";
import YesNoInputV2 from "../../Account/Admin/Design/Component/YesNoInputV2";
import EventMoreInfoView from "./EventMoreInfoView";

export default function EventsSettingsModal({ isOpen, setIsOpen }) {
    const { config, application, reloadConfig } = useContext(ApplicationContext);
    const [customEvents, setCustomEvents] = useState([]);
    const [startHour, setStartHour] = useState("8");
    const [endHour, setEndHour] = useState("22");
    const [subtitleColor, setSubtitleColor] = useState("#000000");

    const types = [
        { title: "Texte", type: "text" },
        { title: "Long texte", type: "bigtext" },
        { title: "Email", type: "email" },
        { title: "Lien web", type: "web" },
        { title: "Sélecteur", type: "selector" },
        { title: "Multiple Sélecteur", type: "multipleSelector" },
        { title: "Lien objet", type: "link" },
        { title: "Catégorie", type: "category" },
    ];

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: 40,
            width: "90%",
            overflow: "scroll",
            maxHeight: "90%",
        },
    };

    const initCustomEvents = () => {
        if (!config) return null;

        setEndHour(config.website.event.weekHourEnd || "20");
        setStartHour(config.website.event.weekHourStart || "7");
        setSubtitleColor(config.website.event.defaultSubtitleColor || "#000000");
        setCustomEvents(
            config.eventObject.custom.map((e) => {
                let obj = { ...e };
                if (e.values) {
                    if (e.color) obj.color = e.color;
                    obj.values = e.values;
                }
                return obj;
            })
        );
    };

    useEffect(() => {
        initCustomEvents();
    }, [config, isOpen]);

    if (!config) return <></>;

    return (
        <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
            <div className="text-right">
                <CloseButton
                    className={"w-max float-right"}
                    onClick={async () => {
                        const newConfig = { ...config };
                        const sN = parseInt(startHour);
                        const eN = parseInt(endHour);
                        if (sN > eN) {
                            return toast.error("Première heure doit etre inferieur à la dernière heure");
                        }
                        newConfig.website.event.weekHourStart = startHour;
                        newConfig.website.event.weekHourEnd = endHour;
                        newConfig.website.event.defaultSubtitleColor = subtitleColor;
                        await UpdateJsonConfig(application.apiKey, newConfig);
                        await reloadConfig();
                        toast.success("Modifications sauvegardées !", { autoClose: 2000, position: "bottom-center" });
                        setIsOpen(false);
                    }}
                />
            </div>

            <div className="mb-6">
                <p className="font-bold text-xl text-gray-800 mb-2">Parametres</p>
                <div key={"objectsubtitleColor"} className={"text-left  items-center mt-2 flex flex-row flex-wrap gap-6"}>
                    <div>
                        <p className="font-medium text-sm text-left mb-2">Couleur par défaut des sous-titres</p>
                        <div className="w-max flex">
                            <input
                                className="color-picker"
                                type="color"
                                name={subtitleColor || ""}
                                value={subtitleColor || ""}
                                onChange={(e) => {
                                    setSubtitleColor(e.target.value);
                                }}
                            />
                            <input
                                type="text"
                                value={subtitleColor || "#000000"}
                                placeholder="Code hexadécimal"
                                onChange={(e) => {
                                    setSubtitleColor(e.target.value);
                                }}
                                className="ml-2 block text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </div>
                    <div>
                        <YesNoInputV2
                            title={"Participer aux évènements même non-connecté ?"}
                            yesSelected={config.eventObject.needConnect}
                            onClick={async (index) => {
                                let newConfig = { ...config };
                                newConfig.eventObject.needConnect = index === 1;
                                await UpdateJsonConfig(application.apiKey, config);
                                reloadConfig();
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="flex">
                    <p className="font-bold text-xl text-gray-800 mb-2">Informations complementaires</p>{" "}
                    <HelpText
                        style={{ height: 10, marginLeft: 5 }}
                        width={10}
                        variant="question"
                        title="Informations complementaires"
                        text={<p style={{}}>Ici vous pouvez afficher des informations supplementaires sur vos événements</p>}
                    />
                </div>
                <EventMoreInfoView customs={config.eventObject.custom} />
            </div>
        </Modal>
    );
}
