import React, { useContext } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import CustomModal from "../../../Common/CustomModal.component";
import ObjectsForm from "../../Objects/Form/objects.form";

const SubEventNewModal = ({isOpen, onClose, onSubmit}) => {
  const {config, langKey} = useContext(ApplicationContext);

  return [
    <CustomModal title={"Créer un sous-événement"} isOpen={isOpen} onClose={onClose}>
      <ObjectsForm config={config.eventObject} lang={langKey} defaultValue={{}} onSubmit={onSubmit} question={false} subeventId={true}/>
    </CustomModal>,
  ];
}

export default SubEventNewModal
