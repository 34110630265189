import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import "moment/locale/fr";
import { GetUserById } from "../../../../Services/User";
import { GetUKObjectById } from "../../../../Services/UKObject";
import { UKUser } from "../../../../Class/UKUser";
import { UKObjectClass } from "../../../../Class/UKObjectClass";

function LinkUser({ user }) {
  const history = useHistory()
  const { config } = useContext(ApplicationContext);
  const [userObj, setUserObj] = useState(null);
  useEffect(() => {
    if (user && config) {
      setUserObj(new UKUser(user.obj, config));
    }
  }, [user, config]);

  const goToUser = () => {
    history.push("/v/user/" + user.obj._id, { back: true })
  }
  return (
    <div onClick={goToUser} className="flex flex-col col-span-2 items-center justify-center">
      {userObj && <img style={{
        width: 35,
        height: 35,
        borderRadius: 25
      }} src={userObj.getPicture()} alt="" />}
      {userObj && <p className="mt-2 font-normal text-sm">{userObj.getFullName()}</p>}
    </div>
  );
}


function LinkObject({ obj }) {
  const history = useHistory()
  const { config } = useContext(ApplicationContext);
  const [ukObj, setUserObj] = useState(null);
  useEffect(() => {
    if (obj && config) {
      setUserObj(new UKObjectClass(obj.obj, config));
    }
  }, [obj, config]);

  const goToObj = () => {
    history.push("/v/object/" + obj.obj._id, { back: true })
  }
  return (
    <div onClick={goToObj} className="flex flex-col col-span-2 items-center justify-center">
      {ukObj && ukObj.getPictureId() && <img className="mb-2" style={{
        width: 50,
        height: 50,
        borderRadius: 25
      }} src={ukObj.getPicture()} alt="" />}
      {ukObj && <p className="font-normal text-sm">{ukObj.getTitle()}</p>}
    </div>
  );
}

function LinkCell({ link, configLink }) {
  const { application, config } = useContext(ApplicationContext);
  const { user } =
    useContext(UserContext);
  const [objA, setObjA] = useState(null);
  const [objB, setObjB] = useState(null);

  const loadObject = async (id, type) => {
    if (!user) {
      return;
    }
    return await GetUKObjectById(id, application.apiKey, user.authToken);
  };

  const loadUser = async (id) => {
    if (!user) {
      return null;
    }
    if (!id || id.length === 0) { return null }
    var userfound = await GetUserById(id, application.apiKey, user.authToken);
    return { type: "user", obj: userfound };
  };

  const loadType = async (type, id) => {
    if (type === "user") {
      var user = await loadUser(id);
      return user;
    }
    var obj = await loadObject(id, type);
    return { type: type, obj: obj };
  };

  const loadLink = async () => {
    if (!link) {
      return;
    }
    var obj1 = await loadType(link.informations.type1, link.informations.id1);
    var obj2 = await loadType(link.informations.type2, link.informations.id2);

    if (obj1 == null || obj2 == null) { return }
    if (link.informations.type1 === configLink[configLink.length - 1]) {
      setObjA(obj1);
      setObjB(obj2);
    } else {
      setObjA(obj2);
      setObjB(obj1);
    }
  };

  useEffect(() => {
    loadLink();
    // eslint-disable-next-line
  }, [link, user]);

  if (!objA || !objB) { return null }
  return (
    <div className="grid grid-cols-5 my-4 border-2 border-dashed border-gray-300 mx-4 py-4 rounded">
      {objA && objA.type === "user" && <LinkUser user={objA} />}
      {objA && objA.type !== "user" && <LinkObject obj={objA} />}
      <div className="flex items-center font-extrabold justify-center "
        style={{
          color: (config || {}).mainColor
        }}>&#8594;</div>
      {objB && objB.type === "user" && <LinkUser user={objB} />}
      {objB && objB.type !== "user" && <LinkObject obj={objB} />}
    </div>
  );
}

export default LinkCell;
