import CustomTableComponent from "../../../../Common/Table/table.component";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../../../../Context/ApplicationContext";
import {ClipLoader} from "react-spinners";
import {GetStat} from "../../../../../Services/Application";

export const StatViewAdmin = () => {
  const {application} = useContext(ApplicationContext);
  const [statList, setStatList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reloadStats = async () => {
    let list = await GetStat(application._id)
    if (list && list.length > 0) {
      setStatList(list.map(e => [e.type, e.infoId, e.info, e.user, e.uuid]));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    reloadStats();
    const interval = setInterval(() => {
      reloadStats();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className={'m-5'}>
        <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Stat Live</p>
        <ClipLoader loading={true}/>
      </div>
    )
  }

  const config = [{title: 'Type', id: 'Type'}, {title: 'InfoId', id: 'InfoId'}, {title: 'Info', id: 'Info'}, {title: 'User', id: 'user'}, {title: 'UUID', id: 'UUID'}];

  return (
    <div className={'w-11/12 m-auto'}>
      <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Stat Live</p>
      <CustomTableComponent content={statList} config={config} isLoading={false} startAt={0}/>
    </div>
  )
}