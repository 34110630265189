import {UKUser} from "../../Class/UKUser";
import {UKObjectClass} from "../../Class/UKObjectClass";
import React, {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../Context/ApplicationContext";
import {UserContext} from "../../Context/UserContext";
import {GetUserById, GetUserByIds} from "../../Services/User";
import {GetUKObjectById} from "../../Services/UKObject";
import getStandDetailStyle from "./stand.detail.style.function";

const StandDetailView = ({properties, mapConfig}) => {
  const {application, config, language, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [linkDetails, setLinkDetails] = useState(null);
  const [allValues, setAllValues] = useState({});

  useEffect(() => {
    setLinkDetails(null);
    getLinkDetails(properties);
  }, [properties])

  useEffect(() => {
    getAllValues()
  }, [linkDetails])

  const getLinkDetails = async (properties) => {
    if (!properties || !properties.link || !properties.linkType) {
      setLinkDetails(null);
      return null;
    }

    if (properties.linkType === 'user') {
      const userObject = await GetUserById(properties.link, application.apiKey, (user || {}).authToken);
      const userClass = new UKUser(userObject, config);
      setLinkDetails(userClass);
    } else {
      const object = await GetUKObjectById(properties.link, application.apiKey, (user || {}).authToken);
      const objectClass = new UKObjectClass(object, config);
      setLinkDetails(objectClass);
    }
  }

  const getAllValues = async () => {
    try {
      if (!linkDetails || !linkDetails.config) return;
      const filteredCustom = linkDetails?.config.custom.filter(elem => !(elem.isTitle || elem.type === 'picture' || elem.type === 'date' || elem.type === 'mapLink'));
      let newAllValues = {};

      for (const custom of filteredCustom) {
        if (!custom) return <></>
        const value = linkDetails.getInformations(custom.id, langKey);

        if (!value) continue;

        if (['text', 'email', 'bigtext'].findIndex(e => e === custom.type) !== -1) newAllValues = {...newAllValues, [custom.id]: <p className='text-gray-700 text-sm text-left'>{!value?.trim().length ? 'N/A' : value}</p>}
        else if (['web', 'files'].findIndex(e => e === custom.type) !== -1) newAllValues = {...newAllValues, [custom.id]: <a href={value}>{value ? 'Ouvrir' : 'N/A'}</a>}
        else if (['banniere', 'picture', 'icon'].findIndex(e => e === custom.type) !== -1) newAllValues = {...newAllValues, [custom.id]: <img src={value} alt=''/>}
        else if (['selector', 'multipleSelector'].findIndex(e => e === custom.type) !== -1) newAllValues = {...newAllValues, [custom.id]: <p className='text-gray-700 text-sm text-left'>{value.split('$$//$$').join(', ')}</p>}
        else if (custom.type === 'date') newAllValues = {...newAllValues, [custom.id]: <input className='pointer-events-none' type='datetime-local' value={value?.split("+")[0].split(".")[0]}/>}
        else if (custom.type === 'link') {
          const linkNames = await getMultipleLinkDetails(value, properties.linkType, properties.url);
          newAllValues = {...newAllValues, [custom.id]: <p className='text-gray-700 text-sm text-left'>{linkNames}</p>}
        } else newAllValues = {...newAllValues, [custom.id]: <p>N/A</p>};
      }
      setAllValues(newAllValues);
    } catch (e){}
  }

  const getMultipleLinkDetails = async (links, linkType, linkUrl) => {
    let titles = [];

    if (!links) return '';
    if (linkType === 'user') {
      const usersObject = await GetUserByIds(links, application.apiKey, (user || {}).authToken);
      for (const user of usersObject) {
        const userClass = new UKUser(user, config);
        const title = userClass.getFullName(language);
        titles.push(title);
      }
    } else {
      for (const link of links) {
        const object = await GetUKObjectById(link, application.apiKey, (user || {}).authToken);
        const objectClass = new UKObjectClass(object, config);
        let title = objectClass.getTitle(language);
        if (title.length === 0) title = 'Sans titre';
        titles.push(title);
      }
    }
    return titles.join(', ');
  }

  const getValue = (id) => {
    return allValues[id];
  }

  return (<div className="flex flex-col pl-3 pr-3 w-full">
    {linkDetails && properties.link && <div className='flex flex-col items-center justify-center w-full py-2'>
      <div className='bg-white rounded-lg w-[95%] p-2'>
        {properties.linkType === 'user' && linkDetails.constructor === UKUser && <div className='flex items-center gap-4'>
          <img className='h-16' src={linkDetails.getPicture(language)} alt=''/>
          <div>
            <p className='font-bold'>{linkDetails.getLastName(language)}</p>
            <p>{linkDetails.getFirstName(language)}</p>
          </div>
        </div>}

        {properties.linkType === 'ukEvent' && linkDetails.constructor === UKObjectClass && <div className='flex flex-col text-left'>
          <p className='font-bold'>{linkDetails.getTitle(language)}</p>
          <p>{linkDetails.getSubtitle(language)}</p>
          <div>Du: <input className='pointer-events-none' type='datetime-local' value={linkDetails.getInformations('dateDebut', langKey)?.split("+")[0].split(".")[0]}/></div>
          <div>Au: <input className='pointer-events-none' type='datetime-local' value={linkDetails.getInformations('dateFin', langKey)?.split("+")[0].split(".")[0]}/></div>
        </div>}

        {properties.linkType === 'object' && linkDetails.constructor === UKObjectClass && <div>
          <p className='font-bold'>{linkDetails.getTitle(language)}</p>
          <p>{linkDetails.getSubtitle(language)}</p>
          <p>{linkDetails.getSubSubtitle(language)}</p>
        </div>}
      </div>

      {linkDetails.config.custom.filter(elem => !(elem.isTitle || elem.type === 'picture' || elem.type === 'date' || elem.type === 'mapLink')).map((c, index) => {
        if (!linkDetails.getInformations(c.id, langKey)) return null;

        const style = getStandDetailStyle(linkDetails.config, c.id, linkDetails.json, application.langKey, index);

        if (c.type === 'selector' || c.type === 'multipleSelector') {
          return <div className='p-2 bg-white rounded-lg mt-3 w-[95%]' key={index}>
            <p className='text-gray-700 font-bold text-left'>{c.tint}</p>
            {getValue(c.id)}
          </div>
        }

        return (
          <div className='p-2' key={index} style={style}>
            <p className='text-gray-700 font-bold text-left'>{c.tint}</p>
            {getValue(c.id)}
          </div>
        );
      })}
    </div>}
  </div>);
}

export default StandDetailView;
