import React from "react";
import CustomColorInput from "../../Common/Inputs/customColorInput.component";
import CustomInputBox from "../../Common/Inputs/customInputBox.component";
import PictureUpdate from "../Account/PictureUpdate";

const AppInfoSettings = ({ appinfo, updateAppInfos, setCurrentView }) => {
  const isURL = (url) => {
    return /^[a-z][a-z0-9+.-]*:/.test(url);
  }

  return (
    <div className="flex flex-col gap-2 w-full h-full px-2 py-4 bg-white m-4 space-y-3">
      <p className="font-medium text-lg text-left mb-2">Informations de votre application</p>
      <CustomInputBox title="Quel est le nom de votre application ?" value={appinfo.name || ""} onChange={(e) => {
        updateAppInfos("name", e);
        setCurrentView("menu")
      }} />
      <CustomInputBox title="Quelle est la description de votre application ?" value={appinfo.description || ""} onChange={(e) => {
        updateAppInfos("description", e)
      }} />

      <p className="font-medium text-lg text-left mb-2">Couleurs de l'application</p>
      <div className="flex grid grid-cols-2">
        <CustomColorInput title="Quelle est la couleur principale de l'application ?" value={appinfo.mainColor || ""} onChange={(e) => {
          updateAppInfos("mainColor", e);
          setCurrentView("app")
        }} />
        <CustomColorInput title="Quelle est la couleur de la police ?" value={appinfo.colorOverMainColor || ""} onChange={(e) => {
          updateAppInfos("colorOverMainColor", e);
          setCurrentView("app")
        }} />
      </div>
      <div className="flex grid grid-cols-2">
        <CustomColorInput title="Quelle est la couleur de la barre de navigation de l'application ?" value={appinfo.navbarColor || ""} onChange={(e) => {
          updateAppInfos("navbarColor", e);
          setCurrentView("app")
        }} />
        <CustomColorInput title="Quelle est la couleur du texte de la barre de navigation ?" value={appinfo.colorOverNavbar || ""} onChange={(e) => {
          updateAppInfos("colorOverNavbar", e);
          setCurrentView("app")
        }} />
      </div>

      <div className="flex flex-col justify-left gap-8">
        <div>
          <p className="font-medium text-sm text-left mb-2 mt-3">Quelle est l'icône de votre application ? (Icône de 1024 * 1024 pixels)</p>
          <PictureUpdate url={isURL(appinfo.icon) ? appinfo.icon : ""} size={100} onUpdate={(url) => {
            const img = new Image();
            img.onload = () => {
              updateAppInfos("icon", url);
              setCurrentView("menu")
            };
            img.src = url;
          }} customParentClassNames="w-max relative"
          />
        </div>

        <div>
          <p className="font-medium text-sm text-left mb-2 mt-3">Quelle est le logo de votre page de connexion ?</p>
          <PictureUpdate url={isURL(appinfo.logo) ? appinfo.logo : ""} size={100} onUpdate={(url) => {
            const img = new Image();
            img.onload = () => {
              updateAppInfos("logo", url);
              setCurrentView("app")
            };
            img.src = url;
          }} customParentClassNames="w-max relative"
          />
        </div>

        <div>
          <p className="font-medium text-sm text-left mb-2 mt-3">Quelle est le logo de votre header ?</p>
          <PictureUpdate url={isURL(appinfo.header) ? appinfo.header : ""} size={100} onUpdate={(url) => {
            const img = new Image();
            img.onload = () => {
              updateAppInfos("header", url);
            };
            img.src = url;
          }} customParentClassNames="w-max relative"
          />
        </div>
      </div>
    </div>
  );
}

export default AppInfoSettings
