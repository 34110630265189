import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ApplicationConfig } from "../Class/ApplicationConfig";
import { GetApplicationById, GetApplicationBySousDomain, GetApplicationConfig } from "../Services/Application";
import { GetLocalization } from "../Services/Localization";

export const ApplicationContext = createContext(null);

export const CLIENT_USEKEY_APP = "6396e36b14cb1d00153d2092";

export const ApplicationProvider = (props) => {
    const [menuOpen, setMenuOpen] = useState(true);
    const [application, setApplication] = useState(null);
    const [applicationCode, setApplicationCode] = useState(null);
    const [config, setConfig] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const [language, setLanguage] = useState(null);
    const [cookies, setCookie] = useCookies(["user_authtoken_" + ((application || {}).name || "")]);
    const [langKey, setLangKey] = useState("");
    const [allLanguage, setAllLanguage] = useState(null);
    const [localizations, setLocalizations] = useState(null);
    const [applicationConfig, setApplicationConfig] = useState(null);

    useEffect(() => {
        if (config !== null) setApplicationConfig(new ApplicationConfig(config));
    }, [config]);

    useEffect(() => {
        if (language) {
            setLangKey("_" + language.id);
        } else {
            setLangKey("");
        }
    }, [language]);
    useEffect(() => {
        if (config && config.language && config.language.length > 0) {
            let params = new URLSearchParams(window.location.search);
            var lang = params.get("lang");
            var defaultLangugage = config.language.find((e) => e.isDefault);
            if (lang) {
                var d = config.language.find((e) => e.id === lang);
                if (d) {
                    defaultLangugage = d;
                }
            }
            setLanguage(defaultLangugage);
            setAllLanguage(config.language);
        }
    }, [config]);

    useEffect(() => {
        reloadConfig();
        // eslint-disable-next-line
        reloadLocalization();
    }, [application]);

    const reloadLocalization = async () => {
        if (!application || !application.apiKey) {
            return;
        }
        const localization = await GetLocalization(application.apiKey, {}.authToken, application._id);
        setLocalizations(localization);
    };

    const TRAD = (key, defaultValue = "") => {
        if (!localizations) {
            return defaultValue;
        }
        const localization = localizations.find((e) => e.key === key);
        try {
            if (localization && language) {
                return localization.informations[language.id] || defaultValue;
            }
        } catch (e) {}

        return defaultValue;
    };
    const reloadConfig = async () => {
        if (!application) return;
        setApplicationCode(application?.informations?.invitecode);
        var config = await GetApplicationConfig(application.apiKey);
        setConfig(config);
    };
    /* Find application by ID or Invite code in URL */
    const reloadApplication = async (paramsId, force = false, sousDomain = true) => {
        var paramsValue = paramsId;

        if (paramsValue === "localhost" || paramsValue === "192.168.5.45") {
            paramsValue = "afvac.usekey.fr";
        }
        if (paramsValue.includes("client") && !application?._id) {
            var apiKey = cookies["app_apikey"];
            const json = await GetApplicationById(apiKey || "501b2ca0-79f5-11ed-bd42-9bbdce71a06f"); // Client App
            setApplication(json);
            setCurrentId(null);
            return;
        } else if (paramsValue.includes("client") && application?._id) {
            const json = await GetApplicationById(application.apiKey);
            setApplication(json);
            return;
        }

        if (window.location.pathname === "/404") return;
        if (currentId === paramsValue && force === false) {
            return;
        }

        setCurrentId(paramsValue);
        const json = sousDomain ? await GetApplicationBySousDomain(paramsValue) : await GetApplicationById(paramsId);
        if (json == null) {
            window.location.href = "/404";
        } else {
            setApplication(json);
        }
    };

    var value = {
        application,
        applicationCode,
        menuOpen,
        setMenuOpen,
        language,
        setLanguage,
        config,
        langKey,
        setLangKey,
        allLanguage,
        reloadApplication,
        reloadConfig,
        TRAD,
        applicationConfig,
        setApplication,
    };

    return <ApplicationContext.Provider value={value}>{props.children}</ApplicationContext.Provider>;
};
