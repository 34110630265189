function NotFoundComponent() {
  return (
    <div className="flex items-center justify-center flex-col h-screen">
      <p className="text-gray-600 scale-150 font-extrabold text-9xl">404</p>
      <p className="text-gray-600 font-bold text-xl">Page introuvable</p>
      <a
        className="text-gray-900 font-normal text-lg underline"
        href="https://www.usekey.fr"
      >
        www.usekey.fr
      </a>
    </div>
  );
}

export default NotFoundComponent;
