import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';

export default function ModuleConfigurationCustomPage({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);
  const [docList, setDocList] = useState();
  const [customPageList, setCustomPageList] = useState();

  useEffect(() => {
    if (!config) return;
    let customPage = config.customPage.map((e) => {
      return {
        value: e.name,
        label: e.name,
      };
    });
    setCustomPageList(customPage);
  }, [config, module]);

  var typeValid = ['CUSTOMPAGE'];
  if (typeValid.indexOf(module.type) == -1) {
    return <></>;
  }
  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Quelle page souhaitez-vous afficher ?</p>
      {customPageList && (
        <div style={{ maxWidth: 300 }}>
          <Select
            value={customPageList.find((e) => e.value === module.id)}
            className="w-full"
            options={customPageList}
            onChange={(e) => {
              setModule({ ...module, id: e.value, url: e.value });
            }}
          />
        </div>
      )}
    </div>
  );
}
