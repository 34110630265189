import { useContext, useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/fr";
import { DateTimePicker } from "react-rainbow-components";
import Select from "react-select";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { UserContext } from "../../../../../Context/UserContext";
import { CreateCampaign, UpdateCampaign } from "../../../../../Services/Notification";
import { GetObjectsAll } from "../../../../../Services/UKObject";

export default function CreateNotificationComponent({ onEnd, selectedNotification }) {
    const { application, config } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [title, setTitle] = useState("");
    const [header, setHeader] = useState(""); //
    const [date, setDate] = useState(new Date());
    const [canUpdate, setCanUpdate] = useState(true);
    const [groupFilter, setGroupFilter] = useState(null);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        async function initGroups() {
            if (!application || !user) return null;
            const groups = await GetObjectsAll("ukGroup", application.apiKey, user.authToken);
            setGroups(groups);
            if (selectedNotification) {
                var groupsF = groups.filter((g) => selectedNotification.groups.includes(g.informations.index));
                // console.log("GROUPSF", groupsF);
                if (groupsF.length > 0) {
                    groupsF = { value: groupsF[0].informations.index, label: groupsF[0].informations.name };
                } else {
                    groupsF = null;
                }

                // if (groupsF.length > 0) {
                setGroupFilter(groupsF);
                // }
                /* setGroups(
                    groups.filter((g) => selectedNotification.groups.includes(g.informations.index)).map((e) => ({ value: e.informations.index, label: e.informations.name }[0]))
                );*/
            }
        }
        initGroups();
    }, [application, user]);

    console.log("GROUP FILTER -->", groupFilter);
    useEffect(() => {
        console.log("SELECTED", selectedNotification);
        if (selectedNotification) {
            setTitle(selectedNotification.title);
            setHeader(selectedNotification.subtitle);
            var date = new Date(selectedNotification.date);
            setDate(date);
            var m = moment(date);
            if (m < moment()) {
                setCanUpdate(false);
            } else {
                setCanUpdate(true);
            }
        } else {
            setCanUpdate(true);
        }
    }, [selectedNotification]);

    const createCampaign = async () => {
        console.log(groupFilter);
        if (title.trim().length === 0) {
            return toast.error("Le sous-titre est obligatoire");
        }

        if (selectedNotification) {
            await UpdateCampaign(selectedNotification._id, title, header, date.toISOString(), groupFilter ? groupFilter.value : null, application.apiKey, user.authToken);
            await onEnd();
            setTitle("");
            toast.success("Notification modifiée");
        } else {
            console.log("CREATE CAMPAIGN");
            try {
                var group = [];
                if (groupFilter) {
                    group = [groupFilter.value];
                }
                await CreateCampaign(title, header, date.toISOString(), group, application.apiKey, user.authToken);
                await onEnd();
                setTitle("");
                toast.success("Notification programmée");
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 px-2 mt-4 ">
            <div className="flex flex-col gap-4 ">
                <div className="flex flex-col  gap-4 w-full items-start">
                    <label className="block text-gray-700 text-sm font-semibold">titre :</label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Titre"
                        className="bg-white rounded-full w-3/4 text-sm appearance-none border border-gray-400 py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline h-10  "
                        value={header}
                        onChange={(e) => setHeader(e.target.value)}
                    />
                </div>
                <div className="flex flex-col  gap-4 w-full items-start">
                    <label className="block text-gray-700 text-sm font-semibold">
                        Sous-titre :<span className="text-red-300">*</span>
                    </label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Titre"
                        className="bg-white rounded-full w-3/4 text-sm appearance-none border border-gray-400 py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline h-10  "
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="flex flex-col items-start gap-4 w-full">
                    <label className="block text-gray-700 text-sm font-semibold">Date :</label>
                    <DateTimePicker
                        required
                        hour24
                        className="w-3-4important"
                        locale={"fr-Fr"}
                        value={date}
                        onChange={(date) => {
                            setDate(date);
                        }}
                        cancelLabel={"Annuler"}
                    />
                </div>
                <div className="pr-4 text-left">
                    <label className="block text-gray-700 text-sm font-semibold">Envoyer uniquement la notification aux utilisateurs des groupes :</label>
                    <Select
                        placeholder="Selectionner"
                        isMulti={false}
                        value={groupFilter}
                        options={groups.map((e) => {
                            return { value: e.informations.index, label: e.informations.name };
                        })}
                        onChange={(a, b) => {
                            setGroupFilter(a);
                        }}
                    />
                </div>
                <div className="pr-2">
                    <button
                        disabled={!canUpdate}
                        className={
                            (!canUpdate ? "opacity-20" : "") +
                            " mr-10 w-full h-10 bg-gray-800 hover:bg-grey-900 text-white text-lg py-1 px-2 font-semibold rounded focus:outline-none focus:shadow-outline"
                        }
                        style={{
                            color: (config || {}).colorOverMainColor ?? "#FFFFFF",
                            backgroundColor: (config || {}).mainColor ?? "#000000",
                        }}
                        onClick={createCampaign}
                    >
                        {selectedNotification ? "Modifier" : "Envoyer"}
                    </button>
                </div>
            </div>
            <div className="mt-4 md:mt-0">
                <div
                    style={{
                        backgroundImage: 'url("/iphone13.png")',
                        backgroundSize: "100%",
                        fontFamily: "San Francisco",
                        paddingTop: 20,
                        paddingBottom: 40,
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        maxWidth: "60%",
                    }}
                >
                    <div class="mt-2 flex flex-col items-center">
                        <p class="mt-3 text-white text-6xl font-extralight">9:41</p>
                        <p class="mt-1 text-white text-lg font-light">Monday, June 7</p>
                    </div>

                    <div class="relative mt-4 mx-2">
                        <div
                            class="p-2 bg-white backdrop-blur-md rounded-xl shadow"
                            style={{
                                backgroundColor: "rgba(255, 255, 255, 0.4)",
                            }}
                        >
                            <div class="grid grid-cols-4 items-center text-left">
                                <ul class="flex ">
                                    <li>
                                        <img class="h-8 w-8 rounded-xl object-cover" src={application.informations.logo} alt="" />
                                    </li>
                                </ul>
                                <div class="col-span-3">
                                    <h3 class="text-xs font-bold">{header ? header : application.informations.name}</h3>
                                    <p class="mt-0.5 text-xs">{(title || "").length > 0 ? title : <span className="italic">Le sous-titre est obligatoire</span>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
