const TableLimiter = ({limit = 20, setLimit}) => {
  const changeLimit = (newLimit) => {
    setLimit(newLimit.target.value);
  }

  return (
    <div className="align-middle py-3">
        <select value={limit} className="bg-transparent border-2 rounded" id="limit" onChange={(value) => {
          changeLimit(value)
        }} style={{
          color: "#555555"
        }}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
    </div>);
}

export default TableLimiter
