import React, {useContext} from "react";
import Modal from "react-modal";
import CloseButton from "../Common/Button/CloseButton";
import {ApplicationContext} from "../../Context/ApplicationContext";
import CustomModal from "../Common/CustomModal.component";

export default function ModalIframe({modalIsOpen, setmodalIsOpen}) {
  const {langKey} = useContext(ApplicationContext);
  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '50%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const object = modalIsOpen.info;

  if (modalIsOpen.info === null || !modalIsOpen.info.config) {
    return
  }

  let config = [...object.config.custom];
  if (object.type === 'ukEvent') {
    config.unshift({tint: 'Titre', id: 'title', isTitle: true}, {
      tint: 'Sous-titre',
      isSubTitle: true,
      id: 'subtitle'
    }, {tint: 'Description', id: 'descript'}, {
      tint: 'Salle',
      id: 'salle'
    }, {tint: 'Du - Au', id: 'dateDebut'}, {tint: 'Horraire', id: 'dateFin'});
  }

  config = config.filter(e => e.type !== 'link' && e.type !== 'mapLink');


  //ariaHideApp={false} supress react warning when open modal
  return (
    <Modal isOpen={modalIsOpen.open} style={customStyles} ariaHideApp={false}>
      <div className="h-full w-full">
        <CloseButton
          className="float-right"
          onClick={() => {
            setmodalIsOpen({open: false, info: null});
          }}
        />
        <div>
          <div className="flex-col">
            <p className="font-bold text-xl text-gray-800 mb-2">Détail</p>
            <div className={'flex w-full ml-8'}>
              <div className={'flex flex-col'}>
                {config.map((e, index) => {
                  const elemToDisplay = object.getInformations(e.id, langKey);
                  if (!elemToDisplay) {
                    return null;
                  }
                  return (
                    <div key={index} className={'w-max pr-5'}>
                      <h1>{e.tint} : </h1>
                    </div>
                  )
                })}
              </div>
              <div className={'flex w-max flex-col'}>
                {config.map((e, index) => {
                  const elemToDisplay = object.getInformations(e.id, langKey);
                  if (object.type === 'ukEvent') {

                  }
                  if (!elemToDisplay) {
                    return null;
                  }
                  return (
                    <div key={index} className={'w-max flex flex-row'}>
                      <h1 className={'text-left'}>{elemToDisplay}</h1>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
