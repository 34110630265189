import {useContext} from "react";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import CustomTableComponent from "../../Common/Table/table.component";
import moment from "moment/moment";

function TableIframeObject({list, objectType, setModalOpen}) {
  const {config, langKey, language} = useContext(ApplicationContext);
  const objectConfig = config.customObject.find(e => e.id === objectType) || config.eventObject || [];

  const newList = list.map(e => {
    const tmp = [];
    if (objectType !== 'ukEvent') {
      tmp.push(e.getTitle(language));
      tmp.push(e.getSubtitle(language));
    }
    for (const i of objectConfig.custom) {
      if (i.isTitle || i.isSubTitle || i.type === 'link' || i.type === 'mapLink') {
        continue;
      }
      tmp.push(e.getInformations(i.id, langKey));
    }
    if (objectType === 'ukEvent') {
      const info = e.json.informations;
      tmp.unshift(info[`title${langKey || ''}`] ?? info.title, info[`subtitle${langKey || ''}`] ?? info.subtitle,
        info[`descript${langKey || ''}`] ?? info.descript, info[`salle${langKey || ''}`] ?? info.salle,
        info.dateDebut ? moment(info.dateDebut).format("DD-MM-YYYY") + " - " + moment(info.dateFin).format("DD-MM-YYYY") : '',
        info.dateFin ? moment(info.dateDebut).format("HH[h]mm") + " - " + moment(info.dateFin).format("HH[h]mm") : '');
    }
    tmp.unshift(e.json._id);
    return tmp;
  })

  const getObjectConfig = () => {
    if (!config) return;
    const title = objectConfig.custom.find(e => e.isTitle === true);
    const subTitle = objectConfig.custom.find(e => e.isSubTitle === true);
    const finalObjectConfig = [...objectConfig.custom.map(e => {
      if (e.isTitle || e.isSubTitle || e.type === 'link' || e.type === 'mapLink') {
        return;
      } else {
        return {'title': e.tint, 'id': e.id};
      }
    }).filter(e => e !== undefined)];

    if (subTitle) {
      finalObjectConfig.unshift({'title': subTitle.tint, 'id': subTitle.id});
    }

    if (title) {
      finalObjectConfig.unshift({'title': title.tint, 'id': title.id});
    }
    return finalObjectConfig;
  }

  const getEventConfig = () => {
    if (!config) return;
    let tableConfig = config.eventObject.custom;
    tableConfig = tableConfig
      .filter(custom => custom.type !== 'link' && custom.type !== 'mapLink')
      .map(obj => {
        return ({title: obj.tint, id: obj.id});
      });
    tableConfig.unshift({title: 'Titre', id: `title${langKey || ''}`}, {
      title: 'Sous-titre',
      id: `subtitle${langKey || ''}`
    }, {title: 'Description', id: `descript${langKey || ''}`}, {
      title: 'Salle',
      id: `salle${langKey || ''}`
    }, {title: 'Du - Au', id: 'dateDebut'}, {title: 'Horraire', id: 'dateFin'});
    return tableConfig;
  }

  const finalObjectConfig = objectType === 'ukEvent' ? getEventConfig() : getObjectConfig();

  return (
    <div className={objectType === 'ukEvent' ? 'ml-5 w-full' : 'w-2/3 mx-3 mb-5 mt-8'}>
      <CustomTableComponent content={newList} config={finalObjectConfig} startAt={1}
                            onRowClick={(props) => setModalOpen({
                              open: true,
                              info: list.find(e => e.json._id === props[0])
                            })}/>
    </div>
  )
}

export default TableIframeObject;