const customStyles = [
  // Polygon
  {
    'id': 'gl-draw-polygon-fill',
    'type': 'fill',
    'filter': ['all',
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'fill-color': [
        'case',
        ['has', 'user_objectColor'], ['get', 'user_objectColor'],
        ['has', 'user_categoryColor'], ['get', 'user_categoryColor'],
        '#FFFFFF',
      ],
      'fill-opacity': 1
    }
  },

  // Line
  {
    'id': 'gl-draw-line',
    'type': 'line',
    'filter': ['all',
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
    ],
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': '#d03b3b',
      'line-width': 2
    }
  },

  // ???
  {
    'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'meta', 'vertex'],
      ['!=', 'meta2', 'handle'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#fff'
    }
  },

  // Nodes
  {
    'id': 'gl-draw-polygon-and-line-vertex-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'meta', 'vertex'],
      ['!=', 'meta2', 'handle'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#fbb03b'
    }
  },

  // =====| POINTS |=====

  // Inactive Default Point Outline
  {
    'id': 'gl-draw-point-point-stroke-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    'paint': {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#ffffff'
    }
  },
  // Inactive Default Point
  {
    'id': 'gl-draw-point-inactive',
    'type': 'circle',
    'filter': ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
      ['==', 'user_type', 'poi'],
    ],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#ff0000'
    }
  },

  // Point-image Style
  {
    'id': 'gl-draw-image-inactive',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
      ['any', ['==', 'user_type', 'image'], ['==', 'user_type', 'pattern']]
    ],
    'paint': {
      'icon-opacity': ['case',
        ['==', ['get', 'user_type'], 'image'], 1,
        0.2,
      ],
    },
    'layout': {
      'symbol-sort-key': ['case',
        ['==', ['get', 'user_type'], 'pattern'], 1,
        2,
      ],
      'icon-image': ['get', 'user_image'],
      'icon-size': ['interpolate', ['exponential', 2], ['zoom'], 15, 0.0015, 23, ['get', 'user_size']],
      'icon-allow-overlap': true,
    }
  },


  // Point-text Style
  {
    'id': 'gl-draw-text-inactive',
    'type': 'symbol',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
      ['==', 'user_type', 'text'],
    ],
    'layout': {
      'text-font': ['Open Sans Light'],
      'text-field': ['get', 'user_text'],
      'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      'text-radial-offset': 0.5,
      'text-justify': 'auto',
    }
  },

  // Selected Point in Direct mode
  {
    'id': 'gl-draw-point-stroke-active',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint']
    ],
    'paint': {
      'circle-radius': 7,
      'circle-color': '#fff'
    }
  },
  {
    'id': 'gl-draw-point-active',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true']],
    'paint': {
      'circle-radius': 5,
      'circle-color': '#0080ff'
    }
  },

  // MidPoint
  {
    'id': 'gl-draw-polygon-midpoint',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#fff'
    }
  }
];

export default customStyles;