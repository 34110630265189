import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import { UpdateJsonConfig } from '../../../../../Services/Application';
import AddButton from '../../../../Common/Button/AddButton';
import TrashButton from '../../../../Common/Button/TrashButton';
import CreatePageModalComponent from '../Design/CreatePageModalComponent';
import PositionDesign from './Iphone/PositionDesign';
import ModuleConfiguration from './ModuleConfiguration/ModuleConfiguration';
import ModuleModal from "./newmodule.modal";

var DEFAULT_IMG =
  'https://images.unsplash.com/photo-1517457373958-b7bdd4587205?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80';

export default function AppDesign({}) {
  const {config, application, reloadConfig} = useContext(ApplicationContext);
  const [moduleSelected, setModuleSelected] = useState(null);
  const [tmpModule, setTmpModule] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [createPageModal, setCreatePageModal] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (!config) {
      return;
    }
    if (currentPage == 'AddPage') {
      setCurrentPage('home');
      return setCreatePageModal(true);
    }
    if (currentPage === 'home') {
      setPage(config.home);
    } else {
      // console.log(config.customPage);
      var pages = config.customPage.find((e) => e.name == currentPage);
      if (pages) {
        setPage(pages.pages);
      } else {
        setPage(null);
      }
    }
  }, [currentPage, config]);

  if (!config || !page) {
    return <p>Chargement ...</p>;
  }

  const updateHome = async (newHome) => {
    var newConfig = {...config};
    if (currentPage === 'home') {
      newConfig.home = newHome;
    } else {
      var index = config.customPage.findIndex((e) => e.name == currentPage);
      if (index >= 0) {
        newConfig.customPage[index].pages = newHome;
      }
    }
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
  };

  const addPage = async (name) => {
    var newConfig = {...config};
    var found = newConfig.customPage.find((e) => e.name == name.toLowerCase().trim());
    if (found) {
      return toast.error('Une page du même nom existe déjà');
    }
    newConfig.customPage.push({
      name: name.toLowerCase().trim(),
      title: name,
      pages: [],
    });

    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
    setCurrentPage(name.toLowerCase().trim());
  };
  const addModule = async (module) => {
    var pages = [...page];
    pages.push({
      ...module,
      _id: v4(),
      image: DEFAULT_IMG,
      style: 'HALFSQUARE',
      textHidden: 'true',
    });

    updateHome(pages);
  };

  const onDeleteModule = async (module) => {
    if (module.style === "TABBAR") {
      let newConfig = {...config};
      const index = newConfig.tabBarV2.findIndex((e) => e._id === module._id);

      if (index !== -1) {
        newConfig.tabBarV2.splice(index, 1);

        await UpdateJsonConfig(application.apiKey, newConfig);
        await reloadConfig();
        await handleSelectedModule(null);
      }
    } else {
      let pages = [...page];
      pages = pages.filter((e) => e._id != module._id);

      await updateHome(pages);
    }
  };

  const deletePage = async () => {
    if (!window.confirm('Voulez-vous supprimer cette page ?')) {
      return;
    }
    var newConfig = {...config};
    var indexPage = newConfig.customPage.findIndex((e) => e.name == currentPage);
    newConfig.customPage.splice(indexPage, 1);
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
    setCurrentPage('home');
  };

  const updateModule = async (module) => {
    if (!module) {
      return;
    }
    const id = module._id;
    const newConfig = {...config};

    if (module.style === "TABBAR") {
      const index = newConfig.tabBarV2.findIndex((e) => e._id === id)
      if (index === -1) {
        newConfig.tabBarV2.push(module)
      } else {
        newConfig.tabBarV2[index] = module;
      }
    } else if (currentPage === 'home') {
      const index = newConfig.home.findIndex((e) => e._id === id);
      if (index >= 0) {
        newConfig.home[index] = module;
      }
    } else {
      const indexPage = newConfig.customPage.findIndex((e) => e.name === currentPage);
      const index = newConfig.customPage[indexPage].pages.findIndex((e) => e._id === id);
      newConfig.customPage[indexPage].pages[index] = module;
    }

    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();

    setTmpModule(null);
    toast.dismiss();
    toast.success('Module bien modifié', {duration: 1500, toastId: 'ModuleSave' + id});
  };

  const handleSelectedModule = async (module) => {
    if (module && moduleSelected) {
      if (module._id == moduleSelected._id) {
        return;
      }
      await updateModule(tmpModule);
    }
    setModuleSelected(module);
  }

  return (
    <div>
      <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Structure de l'application</p>
      <div className="flex gap-3 py-5 w-11/12 m-auto">
        <PositionDesign
          currentPage={currentPage}
          page={page}
          setPage={updateHome}
          moduleSelected={moduleSelected}
          handleSelectedModule={handleSelectedModule}
        />
        <div className="flex flex-col gap-2 w-full px-2">
          <div className="flex justify-between w-full">
            <div className="bg-white px-2 flex rounded  ">
              <p className="font-bold mr-2">Page :</p>
              <select
                value={currentPage}
                onChange={(e) => {
                  setPage(null);
                  setCurrentPage(e.target.value);
                }}
              >
                <option value={'home'}>Page principale</option>
                {config.customPage.map((e, index) => {
                  return <option key={index} value={e.name}>{e.title}</option>
                })}
                <option value={'AddPage'}>+ Ajouter</option>
              </select>
              {currentPage !== 'home' && (
                <TrashButton
                  onClick={() => {
                    deletePage();
                  }}
                />
              )}
            </div>
            <AddButton
              title={'Ajouter un module'}
              onClick={() => {
                setCreateModal(true);
              }}
            />
          </div>

          {moduleSelected && (
            <ModuleConfiguration
              moduleSelected={moduleSelected}
              setTmpModule={setTmpModule}
              updateModule={updateModule}
              onDeleteModule={onDeleteModule}
            />
          )}
        </div>

        <ModuleModal
          isOpen={createModal}
          onUpdate={(newData) => {
            if (newData) {
              addModule(newData);
            }
            setCreateModal(false);
          }}
          setIsOpen={setCreateModal}
        />

        <CreatePageModalComponent
          isOpen={createPageModal}
          onClose={() => setCreatePageModal(false)}
          onSubmit={(name) => {
            addPage(name);
            setCreatePageModal(false);
          }}
        />
      </div>
    </div>
  );
}
