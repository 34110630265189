import { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import CommonButton from "../../Common/Button/CommonButton";
import { UserContext } from "../../../Context/UserContext";
import TableSearchBar from "../../Common/TableSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faSquarePlus } from "@fortawesome/pro-light-svg-icons";
import { ApplicationContext } from "../../../Context/ApplicationContext";


const ObjectsActionbar = ({info, setInfo, typeobject = {}, modalSetter = {}, objConfig = {}, iframeModal = {}, objectCount, onlySelectObject}) => {
  const {config} = useContext(ApplicationContext);
  const {user, isAdmin} = useContext(UserContext);
  let {params} = useRouteMatch();
  const history = useHistory();
  const [showCreateButton, setShowCreateButton] = useState(false);

  useEffect(() => {
    hasUserDirectLink();
  }, [config, user, params, objectCount]);

  const hasUserDirectLink = () => {
    if (!config || !user || !params) return;
    if (objConfig.canCreateObject && (objConfig.canCreateMultiple || (!objConfig.canCreateMultiple && !objectCount))) {
      setShowCreateButton(true);
      return;
    }
    if (objConfig.canCreateObject && !objConfig.canCreateMultiple && objectCount > 0) {
      setShowCreateButton(false);
    }
    const linksConf = config.inscription.customs.find(custom => custom.id === user.informations.user_custom_type)?.custom?.filter(cat => cat.type === "link")
    if (!linksConf) return;
    const currentConf = linksConf.find(l => l.url === params.typeobject);
    if (currentConf && !currentConf.canCreateLink) {
      setShowCreateButton(false);
      return;
    }
    if (currentConf && !currentConf.multiLink && objectCount > 0) {
      setShowCreateButton(false);
      return;
    }
    const isShow = linksConf.findIndex(l => l.url === params.typeobject) !== -1;

    setShowCreateButton(isShow);
  }

  return (
    <div className="flex w-11/12 justify-between">
      <div className={!onlySelectObject ? '' : 'my-3'}>
        <TableSearchBar config={objConfig.custom} info={info} setInfo={setInfo}/>
      </div>
      {!onlySelectObject && (
        <div className="flex py-3">
          <div className="flex items-center">
            {isAdmin() && <CommonButton title={'Créer une iframe'} customClassName="h-8 w-max"
                                        onClick={() => iframeModal(true)}/>}
            {(isAdmin() || showCreateButton) &&
              <CommonButton title="Créer un objet" icon={<FontAwesomeIcon icon={faSquarePlus}/>} onClick={() => {
                return history.push(`/v/object/new/${params.typeobject || typeobject}`);
              }} customClassName="h-8 ml-3"/>}
            {isAdmin() && <CommonButton title="Configuration" icon={<FontAwesomeIcon icon={faGear}/>}
                                        onClick={() => modalSetter(true)} customClassName="h-8 ml-3"/>}
          </div>
        </div>
      )}
    </div>
  );
}

export default ObjectsActionbar;
