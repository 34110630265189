import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const LocalizationSearch = ({info, setInfo}) => {
  const [search, setSearch] = useState(info.filter);
  const [timeout, setTimeoutValue] = useState(null);

  return (
    <div className="flex items-center justify-center h-max py-2 rounded bg-white border gap-2 px-2 w-max">
      <FontAwesomeIcon className="text-gray-400" icon={faSearch} />
      <input
        className="rounded rounded-l"
        placeholder="Rechercher"
        type="text"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          if (timeout) clearTimeout(timeout);
          setTimeoutValue(
            setTimeout(() => {
              setInfo({...info, filters: e.target.value})
            }, 500)
          );
        }}
      ></input>
      <FontAwesomeIcon onClick={() => {
        setSearch("");
        setInfo({...info, filters: ""});
      }} className="text-red-400" icon={faClose} />
    </div>
  );
}

export default LocalizationSearch
