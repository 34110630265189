import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";

const DocsTabBar = ({ folderStack, setFolderStack, setCreateDoc }) => {
    const { config } = useContext(ApplicationContext);
    const color = (config || {}).mainColor ?? "#FFFFFF";
    const colorHover = (config || {}).colorOverMainColor ?? "#000000";

    const previousPage = () => {
        if (folderStack[0] === null) return;

        let newFolderStack = [...folderStack];
        newFolderStack.splice(0, 1);
        setFolderStack(newFolderStack);
    };

    const newDoc = () => {
        setCreateDoc(true);
    };

    return (
        <div
            className="overflow-hidden w-full object-contain absolute z-50"
            style={{
                height: "8%",
                color: colorHover,
                backgroundColor: color,
            }}
        >
            <div className="flex justify-between items-center mt-6 ml-4 mr-4">
                <div
                    className="flex items-center gap-2 cursor-pointer"
                    style={{
                        opacity: folderStack[0] === null ? 0.2 : 1,
                    }}
                    onClick={previousPage}
                >
                    <FontAwesomeIcon icon="chevron-left" />
                    <p className="text-sm">Retour</p>
                </div>
                <FontAwesomeIcon className="cursor-pointer" icon="plus" onClick={newDoc} />
            </div>
        </div>
    );
};
export default DocsTabBar;
