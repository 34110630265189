import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../Context/UserContext";
import {ApplicationContext} from "../../../Context/ApplicationContext";
import {GetUKObjectById} from "../../../Services/UKObject";
import {UKObjectClass} from "../../../Class/UKObjectClass";
import CommonButton from "../../Common/Button/CommonButton";
import {useHistory} from "react-router-dom";

const HomeView = ({}) => {
  const {userClass} = useContext(UserContext);
  const {application, config, language} = useContext(ApplicationContext);
  const history = useHistory();
  const [links, setLinks] = useState(null)

  const getLinks = async () => {
    const links = await userClass.getLinks(config, application.apiKey);
    setLinks(links)
    await getObjects(links);
  }

  const getObjects = async (links) => {
    for (const l of links.direct) {
      l.objects = [];
      for (const link of l.links) {
        const json = await GetUKObjectById(link, application.apiKey, userClass.json.authToken);
        const obj = new UKObjectClass(json, config);
        l.objects.push({
          title: obj.getTitle(language),
          url: json._id
        });
      }
    }
  }

  useEffect(() => {
    if (!userClass) return;
    getLinks();
  }, [userClass]);


  return (
    <div className="bg-white p-5 ml-2">
      <p className="text-2xl font-bold text-gray-700 text-left ml-5 mt-6">Bienvenue</p>

      {links && links.direct && links.direct.length > 0 && links.direct.map((l) => {
        return (
          <div className="">
            <div className="flex gap-5 items-center ml-5 mt-10">
              <p className="text-xl font-bold text-gray-700 text-left">{l.conf.placeholder}</p>
              <CommonButton customClassName="w-max h-max" title="Voir la liste" onClick={() => {
                history.push(`objects/${l.conf.url}`);
              }} />
            </div>
            {l.objects && l.objects.map((obj) => {
              return (
                <div className="ml-4 mt-2 border rounded cursor-pointer hover:opacity-80" onClick={() => {
                  history.push(`object/${obj.url}`);
                }}>
                  <p className="text-gray-700 text-lg">{obj.title}</p>
                </div>
              );
            })}
            {(!l.objects || !l.objects.length) && <p className="ml-4 mt-2 text-sm text-gray-700 text-left">Consulter la liste pour créer un nouvel objet</p>}
          </div>
        );
      })}

      {links && links.indirect && links.indirect.length > 0 && links.indirect.map((l) => {
        return (
          <div className="flex gap-5 items-center ml-5 mt-10">
            <p className="text-xl font-bold text-gray-700 text-left">{l.placeholder}</p>
            <CommonButton customClassName="w-max h-max" title="Voir la liste" onClick={() => {
              history.push(`objects/${l.url}`);
            }} />
          </div>
        );
      })}
    </div>
  );
}

export default HomeView;
