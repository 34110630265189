import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { FindLinkUser } from "../../../Services/UKLink";
import { CreateObject } from '../../../Services/UKObject'
import CustomObjectCell from "../../Common/CustomObjectCell";
import ObjectListComponent from '../Object_old/List/ObjectListComponent'
import Modal from 'react-modal'
import { AiOutlineClose } from 'react-icons/ai'

function ProfilLinksUpdate({ customUser }) {
  const history = useHistory();
  const { application, config } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [links, setLinks] = useState([]);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [modalisOpen, setmodalisOpen] = useState(false);

  const reloadCurrentLinks = async () => {
    var u = customUser || user;
    if (!u || links.length === 0) {
      return;
    }

    var json = await FindLinkUser(
      u._id,
      application.apiKey,
      user.authToken,
      "1"
    );
    json = json.filter((elem) => elem.informations._obj != null);
    var newLinks = [...links];
    for (var i = 0; i < newLinks.length; i++) {
      var elem = newLinks[i];
      var type = elem.type1 === "user" ? elem.type2 : elem.type1;
      // eslint-disable-next-line
      var allType = json.filter((elem) => elem.informations._obj.type === type);
      newLinks[i].objs = allType;
    }
    setCurrentLinks(newLinks);
  };

  const reloadConfigLinks = () => {
    var u = customUser || user;
    var group = ((u || {}).informations || {}).groups || [];
    var links = (config || {}).links || [];
    links = links.filter((elem) => {
      var isType = elem.type1 === "user" || elem.type2 === "user";
      var eGroup = (elem.group || "").split(",");
      var isInGroup = false;

      if (eGroup.length > 0) {
        for (var i = 0; i < group.length; i++) {
          for (var j = 0; j < eGroup.length; j++) {
            if (group[i] === eGroup[j]) {
              isInGroup = true;
            }
          }
        }
      } else {
        isInGroup = true;
      }
      return isInGroup && isType;
    });
    setLinks(links);
  };

  useEffect(() => {
    reloadCurrentLinks();
    // eslint-disable-next-line
  }, [user, links]);
  useEffect(() => {
    reloadConfigLinks();
    // eslint-disable-next-line
  }, [user, config]);

  return (
    <div className="grid grid-cols-1 gap-4 gap-y-6">
      {currentLinks.map((elem, index) => {
        var result = elem.objs.map((e, index) => {
          return (
            <div key={"link" + index} className="text-left flex items-center">
              <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
                {index === 0 ? elem.labelType2 : ""}
              </label>
              <CustomObjectCell
                onClick={() => {
                  history.push(`/v/object/${e.informations._obj._id}`, {
                    back: true,
                  });
                }}
                className="w-full block text-gray-700 text-sm font-semibold text-left cursor-pointer"
                object={e.informations._obj}
                waiting={e.informations.state === "0"}
              />
            </div>
          );
        });
        if (result.length > 0) {
          var multiple =
            elem.type2 === "user" ? elem.type2Multiple : elem.type1Multiple;
          if (multiple === false) {
            return result;
          }
        }

        result.push(
          <div key={"linkadd" + index} className="text-left flex items-center">
            <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
              {elem.objs.length === 0 ? elem.labelType2 : ""}
            </label>
            <div className="w-full block text-gray-700 text-sm font-semibold text-left cursor-pointer">
              <button
                className=" bg-transparent hover:bg-grey-900 text-white text-sm py-1 px-2 font-semibold rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  var type = elem.type1 === "user" ? elem.type2 : elem.type1;
                  history.push(
                    `/v/object/new/${type}?type=user&id=${(customUser || user)._id
                    }`
                  );
                }}
                style={{
                  borderColor: (config || {}).mainColor ?? "#000000",
                  borderWidth: 1,
                  color: (config || {}).mainColor ?? "#000000",
                }}
              >
                Créer
              </button>
              <button
                className=" bg-transparent hover:bg-grey-900 text-white text-sm ml-3 py-1 px-2 font-semibold rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  setmodalisOpen(true);
                }}
                style={{
                  borderColor: (config || {}).mainColor ?? "#000000",
                  borderWidth: 1,
                  color: (config || {}).mainColor ?? "#000000",
                }}
              >
                Rechercher
              </button>
              <Modal
                isOpen={modalisOpen}
              >
                <button className="float-right" onClick={() => setmodalisOpen(false)}><AiOutlineClose /></button>
                <ObjectListComponent
                  typeobject={elem.type1 === "user" ? elem.type2 : elem.type1}
                  onClick={async (e) => {
                    setmodalisOpen(false)
                    await CreateObject("ukLink", {
                      "id2": e._id,
                      "type2": elem.type1 === "user" ? elem.type2 : elem.type1,
                      "id1": user._id,
                      "type1": "user",
                      "state": "0"
                    }, application.apiKey, user.authToken)
                    await reloadConfigLinks();
                  }}
                />
              </Modal>
            </div>
          </div>
        );
        return result;
      })}
    </div>
  );
}

export default ProfilLinksUpdate;
