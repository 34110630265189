import React from 'react'
import { useState, useContext } from "react";
import './InfosColorsComponent.css';
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import InfosPicturesComponent from './InfosPicturesComponent';

export default function InfosComponent() {

    const { config } = useContext(ApplicationContext);
    const [primCP, setPrimCP] = useState(config.mainColor);
    const [secondCP, setSecondCP] = useState(config.mainColor2);
    const [navCP, setNavCP] = useState(config.navbarColor);
    const [overMainCP, setoverMainCP] = useState(config.colorOverMainColor);

    const changePrimaryPicker = (elem) => {
        setPrimCP(elem.target.value);
    };

    const changeSecondaryPicker = (elem) => {
        setSecondCP(elem.target.value);
    };

    const changeNavPicker = (elem) => {
        setNavCP(elem.target.value);
    };

    const changeOverMainPicker = (elem) => {
        setoverMainCP(elem.target.value);
    };

    return (
        <div className="colors p-4 text-right">
            <div className="grid grid-cols-2">
                <p className="text-left">Couleur de la police : </p>
                <div className="flex">
                    <input type="color" className="color-picker" onChange={changeOverMainPicker} value={overMainCP}></input>
                    <input
                        type="text"
                        value={overMainCP}
                        placeholder="Code hexadécimal"
                        onChange={changeOverMainPicker}
                        className="ml-2 block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline">
                    </input>
                </div>
            </div>
            <div className="grid grid-cols-2 mt-3">
                <p className="text-left">Couleur principale : </p>
                <div className="flex">
                    <input type="color" className="color-picker" onChange={changePrimaryPicker} value={primCP}></input>
                    <input
                        type="text"
                        value={primCP}
                        placeholder="Code hexadécimal"
                        onChange={changePrimaryPicker}
                        className="ml-2 block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline">
                    </input>
                </div>
            </div>
            <div className="grid grid-cols-2 mt-3">
                <p className="text-left">Couleur secondaire : </p>
                <div className="flex">
                    <input type="color" className="color-picker" onChange={changeSecondaryPicker} value={secondCP}></input>
                    <input
                        type="text"
                        value={secondCP}
                        placeholder="Code hexadécimal"
                        onChange={changeSecondaryPicker}
                        className="ml-2 block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline">
                    </input>
                </div>
            </div>
            <div className="grid grid-cols-2 mt-3">
                <p className="text-left">Couleur de la navigation : </p>
                <div className="flex">
                    <input type="color" className="color-picker" onChange={changeNavPicker} value={navCP}></input>
                    <input
                        type="text"
                        value={navCP}
                        placeholder="Code hexadécimal"
                        onChange={changeNavPicker}
                        className="ml-2 block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline">
                    </input>
                </div>
            </div>
            <hr className="mt-3 border-t-2"></hr>
            <InfosPicturesComponent
                primCP={primCP}
                secondCP={secondCP}
                navCP={navCP}
                overMainCP={overMainCP}
            />
        </div>
    );
}
