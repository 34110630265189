import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas, far, fab);

const ItemTypes = {
  CARD: 'card',
}

const TabIcon = ({tab, id, image, index, moveTab, onClick}) => {
  const ref = useRef(null)
  const [{handlerId}, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveTab(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {id, index}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const isURL = (url) => {
    return /^[a-z][a-z0-9+.-]*:/.test(url);
  }

  return (
    <div className="flex h-full items-center" style={{opacity: opacity}} onClick={() => {onClick(tab)}} ref={ref} data-handler-id={handlerId}>
      <div className="cursor-pointer hover:opacity-60" >{(isURL(image) && <img width={18} src={image} alt="x" />) || <FontAwesomeIcon icon={image || "house"} />}
      </div>
    </div>
  )
}

export default TabIcon