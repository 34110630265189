import md5 from "md5";
const queryString = require("query-string");

const URL = "https://pitchr-users.herokuapp.com";
//const URL = 'http://localhost:3033';

export const GetTicketUser = async (userId, apiKey) => {
    try {
        const response = await fetch(`${URL}/users/${userId}/email/tickets`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};
export const EmailExist = async (email, apiKey) => {
    try {
        const response = await fetch(`${URL}/users/classical/emailExist`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const LoginUser = async (email, password, apiKey) => {
    try {
        const response = await fetch(`${URL}/users/classical/signin`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email, password: md5(password).toUpperCase() }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const RegisterUser = async (email, password, apiKey) => {
    try {
        const response = await fetch(`${URL}/users/classical/signup`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email, password: md5(password).toUpperCase() }),
        });
        if (response.status !== 200) {
            if (response.status === 409) {
                // eslint-disable-next-line
                throw "Email déjà utilisé";
            } else {
                // eslint-disable-next-line
                throw "erreur";
            }
        }
        const json = await response.json();
        return json;
    } catch (e) {
        throw e;
    }
};

export const LoginUserByToken = async (token, apiKey) => {
    try {
        const response = await fetch(`${URL}/users/token`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ authToken: token }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const UpdateUser = async (id, object, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/users/${id}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const CopyAdmin = async (token, appId) => {
    try {
        const response = await fetch(`${URL}/admin/users/classical/copy`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                appId: appId,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetUserByIds = async (ids, apiKey, authToken) => {
    try {
        var obj = { "ids[]": ids };
        const response = await fetch(`${URL}/users?${queryString.stringify(obj)}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetUserById = async (id, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/users/${id}`, {
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetUsersAllCount = async (page, apiKey, authToken, filters, user_custom_type) => {
    try {
        const response = await fetch(`${URL}/users/searchCount?page=${page}&limit=20`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filter: filters,
                and: false,
                user_custom_type: user_custom_type,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetUsersAll = async (page, apiKey, authToken, filters, user_custom_type) => {
    try {
        const response = await fetch(`${URL}/users/search?page=${page}&limit=20`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filter: filters,
                and: false,
                user_custom_type: user_custom_type,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetUsersAllV2 = async (page, apiKey, authToken, filters, user_custom_type, limit) => {
    try {
        const response = await fetch(`${URL}/users/V2/search?page=${page}&limit=${limit}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filter: filters,
                and: false,
                user_custom_type: user_custom_type,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const AdminLoginUser = async (email, password, apiKey) => {
    // console.log({ email: email, password: md5(password).toUpperCase() });
    try {
        const response = await fetch(`${URL}/admin/users/classical/signin`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email, password: md5(password).toUpperCase() }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};
