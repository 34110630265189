const SearchBarView = ({buildings, zoomToTarget, setSelectedFeature}) => {

  // if (!filter || filter.length === 0) return null;
  return (
    <div className='lg:absolute bg-white lg:w-full overflow-auto  mx-2  rounded-lg'>
      {(buildings && buildings.length == 0 && <p className="italic opacity-50 font-medium py-2">Aucun element</p>)}
      {(buildings && buildings.length !== 0) && buildings.map((building, index) => {
        return (<div  className={"flex flex-col border-t px-2 py-2 hover:bg-gray-100 cursor-pointer"} onClick={() => {
          setSelectedFeature(building)
          zoomToTarget(building);

        }}>



            <p className=" text-left font-bold">
              {building.properties?.name}
            </p>
            {building.properties?.linkName && <p className={"mt-[-5px] text-left"}>{building.properties?.linkName}</p>}

        </div>);
      })}
    </div>);
}

export default SearchBarView;
