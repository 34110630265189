import PreviewAppIcon from "./preview.appicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";

const appIcons = ["https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658850973795.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658850982979.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658850997794.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851001998.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851007161.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851011769.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851021661.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851026376.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851030240.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851034167.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851038849.png",
  "https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658851043115.png",
]
// const appIcons = ["video", "sd-card", "envelope", "book-open",
//   "map", "cloud-sun", "calendar", "camera"]
// const appColor = ["rgb(29,167,16)", "rgb(157,153,153)", "rgb(100,169,253)", "rgb(250,124,65)",
//   "rgb(7,148,213)", "rgb(0,108,211)", "rgb(157,153,153)", "rgb(192,95,95)"]
const appName = ["Météo", "Bourse", "Paramètres", "Find My", "Camera", "Agenda", "Livres", "Facetime", "Contacts", "Mails", "AppStore", "Horloge"]

const PreviewAppsmenu = ({appinfo, setCurrentView}) => {
  return (
    <div className="w-full h-full">
      <img src="https://storage.sbg.cloud.ovh.net/v1/AUTH_237891a0cd0f474fa13bb20c677568b7/pdfgenerator/1658925377719.jpg" alt="background" className="absolute top-0 left-0 transform scale-125" />
      <div className="flex w-full h-full absolute grid grid-cols-4 grid-rows-6">
        {appIcons.map((e, index) => {
          return (
            <PreviewAppIcon icon={e} iconColor="white" name={appName[index]} />
          );
        })}
        <div className="cursor-pointer" onClick={() => {
          setCurrentView("app");
        }}>
          <PreviewAppIcon icon={appinfo.icon || "house"} iconColor="white" bgColor={"rgb(157,153,153)"} name={appinfo.name || ""} />
        </div>
      </div>
        <div className="absolute bottom-2 right-4 m-auto flex gap-2 transform scale-75">
          <FontAwesomeIcon className="cursor-pointer" onClick={() => {
            setCurrentView("menu")
          }} icon={faCircleChevronUp} color="white" />
          <FontAwesomeIcon className="cursor-pointer" onClick={() => {
            setCurrentView("app")
          }} icon={faCircle} color="white" />
        </div>
    </div>

  );
}

export default PreviewAppsmenu
