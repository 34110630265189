import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FileSaver from "file-saver";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import * as XLSX from "xlsx";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { UserContext } from "../../../../../Context/UserContext";
import { GetObjectsAll } from "../../../../../Services/UKObject";
import { GetUserByIds } from "../../../../../Services/User";
import CommonButton from "../../../../Common/Button/CommonButton";
import CustomTableComponent from "../../../../Common/Table/table.component";

export default function ScanViewComponent() {
    const { application } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [scan, setScan] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const reloadTickets = async () => {
        let scans = await GetObjectsAll("scanCode", application.apiKey, user.authToken);
        scans = scans.map((e) => {
            return { id: e.informations.userId, date: e.mongoose_created_at, fullName: e.informations.fullName };
        });
        scans = scans.reverse();
        if (scans && scans.length > 0) {
            setScan(
                scans.map((e) => {
                    return { info: [moment(e.date).format("LLLL").toString(), e.fullName], id: e.id };
                })
            );
        }
        setIsLoading(false);
    };

    const exportUser = async () => {
        const ids = [...new Set(scan.map((e) => e.id))];
        const userInfo = await GetUserByIds(ids, application.apiKey, (user || {}).authToken);

        const json = [];
        for (const s of scan) {
            const obj = {
                date: s.info[0],
                name: s.info[1],
                ...(userInfo.find((e) => e._id === s.id)?.informations ?? {}),
            };
            delete obj.password;
            json.push(obj);
        }

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "export" + fileExtension);
    };

    useEffect(() => {
        reloadTickets();
        const interval = setInterval(() => {
            reloadTickets();
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    if (isLoading) {
        return (
            <div className={"m-5"}>
                <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Badge scanné</p>
                <ClipLoader loading={true} />
            </div>
        );
    }

    const config = [
        { title: "Date", id: "date" },
        { title: "Prénom Nom", id: "fullName" },
    ];

    return (
        <div className={"w-11/12 m-auto"}>
            <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Badge scanné</p>
            <div className="flex justify-end">
                <CommonButton
                    customClassName="w-max mb-3"
                    title="Exporter"
                    onClick={() => {
                        exportUser();
                    }}
                    icon={<FontAwesomeIcon icon={faFileExcel} />}
                />
            </div>
            <CustomTableComponent content={scan.map((e) => e.info)} config={config} isLoading={false} startAt={0} />
        </div>
    );
}
