import ReactCountryFlag from 'react-country-flag';

export default function CustomStylesLangSelect({ small = false, lang }) {
  return (
    <div className="flex items-center justify-center gap-x-2 ">
      <ReactCountryFlag countryCode={lang.countryCode} />
      {!small && <p>{lang.name}</p>}
    </div>
  );
}
