import { useContext, useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/fr";
import { Badge, Column, MenuItem, Table } from "react-rainbow-components";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { UserContext } from "../../../../../Context/UserContext";
import { DeleteCampaign, GetCampaign } from "../../../../../Services/Notification";
import { GetObjectsAll } from "../../../../../Services/UKObject";
import CommonButton from "../../../../Common/Button/CommonButton";
import CreateNotificationComponent from "./CreateNotificationComponent";

const DateNotificationComponent = ({ value }) => {
    var date = moment(value);
    return <div>{date.format("YYYY-MM-DD HH:mm")}</div>;
};

const GroupsNotificationComponent = ({ value }) => {
    const { application, config } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [groups, setGroups] = useState([]);
    const [result, setResult] = useState("");
    useEffect(() => {
        if (value.length > 0) {
            console.log("VALUE", value);
            async function initGroups() {
                if (!application || !user) return null;
                const groups = await GetObjectsAll("ukGroup", application.apiKey, user.authToken);
                setGroups(groups);
                var allG = groups.filter((g) => value.includes(g.informations.index));
                console.log(groups);
                setResult(<span className="font-bold">{allG.map((g) => g.informations.name).join(", ")} </span>);
                console.log(allG);
            }
            initGroups();
        } else {
            setResult(<span className="italic">Tout le monde</span>);
        }
    }, [application, user]);
    return <div>{result}</div>;
};

const badgeStyles = { color: "black", marginLeft: "0.5rem" };

const StatusBadge = ({ value }) => (
    <Badge label={value === 0 ? "En attente" : value === 1 ? "Envoyé" : "Annulé"} variant={value === 0 ? "warning" : value === 1 ? "success" : "error"} style={badgeStyles} />
);

export default function NotificationComponent() {
    const { application } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [campaign, setCampaign] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const reloadCampaign = async () => {
        setCampaign([]);
        setIsLoading(true);
        const list = await GetCampaign(application.apiKey, user.authToken);
        setCampaign(list);
        setSelectedNotification(null);
        setIsCreating(false);
        setIsLoading(false);
        console.log("RELOAD CAMPAIGN", list);
    };
    useEffect(() => {
        reloadCampaign();
    }, []);

    return (
        <div>
            <div className="flex justify-end px-2 mt-5">
                <CommonButton
                    title={isCreating ? "X" : "Créer"}
                    onClick={() => {
                        setSelectedNotification(null);
                        setIsCreating(!isCreating);
                    }}
                    customClassName="px-2"
                />
            </div>
            {isCreating && <CreateNotificationComponent onEnd={reloadCampaign} selectedNotification={selectedNotification} />}
            <div>
                <p className="text-xl font-medium mt-5 mb-2 text-left ml-2">Vos campagnes de notifications</p>
                <div className="mx-2 border-gray-300 border-l border-r border-b rounded">
                    <Table isLoading={isLoading} data={campaign} keyField="_id">
                        <Column header="Titre" field="title" />
                        <Column header="Date" field="date" component={DateNotificationComponent} />
                        <Column header="Cible" field="groups" component={GroupsNotificationComponent} />
                        <Column header="Status" field="state" component={StatusBadge} />
                        <Column type="action">
                            <MenuItem
                                label="Edit"
                                onClick={(event, data) => {
                                    setIsCreating(true);
                                    setSelectedNotification(data);
                                }}
                            />
                            <MenuItem
                                label="Delete"
                                onClick={async (event, data) => {
                                    if (window.confirm("Êtes-vous sur de vouloir supprimer cette campagne ?")) {
                                        await DeleteCampaign(data._id, application.apiKey, user.authToken);
                                        await reloadCampaign();
                                    }
                                }}
                            />
                        </Column>
                    </Table>
                </div>
            </div>
        </div>
    );
}
