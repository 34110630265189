import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-rainbow-components";
import { UKObjectClass } from "../../Class/UKObjectClass";
import { ApplicationContext } from "../../Context/ApplicationContext";
import { UserContext } from "../../Context/UserContext";
import { GetEventById, GetUKObjectById } from "../../Services/UKObject";

function CustomObjectCellVertical({ object, className, onClick = () => {}, id, waiting, type }) {
    const { config, application, language, langKey } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [objectClass, setObjectClass] = useState(null);

    useEffect(() => {
        if (object) setObjectClass(new UKObjectClass(object, config));
    }, [object]);

    useEffect(() => {
        async function initObject() {
            if (id && user && config) {
                let object;
                if (type === "object") {
                    object = await GetUKObjectById(id, application.apiKey, user.authToken);
                } else {
                    object = await GetEventById(id, application.apiKey);
                }
                setObjectClass(new UKObjectClass(object, config));
            }
        }
        initObject();
    }, [id, user, config]);

    if (!objectClass) {
        return (
            <div className="hover:bg-indigo-50 py-8 hover:bg-opacity-50 cursor-pointer transition flex flex-col items-left lg:items-center rounded-lg border text-left py-2 px-2 min-h-full">
                <Spinner></Spinner>
            </div>
        );
    }

    return (
        objectClass && (
            <div onClick={onClick} className={"" + className}>
                <div className="hover:bg-indigo-50  hover:bg-opacity-50 cursor-pointer transition flex flex-col items-left lg:items-center rounded-lg border text-left py-2 px-2 min-h-full">
                    {objectClass.getPicture(language) && <img alt="object logo" className="object-contain mb-2 " style={{ height: 70 }} src={objectClass.getPicture(language)} />}
                    <div
                        className={"ml-0   text-gray-700 border-indigo-100 py-2 px-2 " + ((objectClass.getPicture(language) || "").length > 0 ? "border-t-2" : "")}
                        style={{ width: "100%" }}
                    >
                        <p className="font-semibold ">{objectClass.getTitle(language) || objectClass.json.informations[`title${langKey}`]}</p>
                        {objectClass.getSubtitle(language).length > 0 && (
                            <p className="font-light text-xs overflow-ellipsis  overflow-hidden ellipse2 mt-2">{objectClass.getSubtitle(language)}</p>
                        )}
                    </div>
                </div>
            </div>
        )
    );
}

export default CustomObjectCellVertical;
