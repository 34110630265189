import React from 'react'
import { useContext, useState } from "react";
import PictureUpdate from "../../PictureUpdate";
import { toast } from "react-toastify";
import { UpdateApplication } from "../../../../../Services/Application";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { UserContext } from "../../../../../Context/UserContext";
import { UpdateJsonConfig } from "../../../../../Services/Application";
import { HelpText } from 'react-rainbow-components';
import CommonButton from "../../../../Common/Button/CommonButton";

export default function InfosPicturesComponent({ primCP, secondCP, navCP, overMainCP }) {
	const { application, reloadConfig, config } = useContext(ApplicationContext);
	const { user } = useContext(UserContext);
	const name = application.name;
	const [urlIcon, setUrlIcon] = useState(application.informations.icon);
	const [urlLogo, seturlLogo] = useState(application.informations.logo);

	const setNewInfos = async () => {
		let newConfig = { ...config };

		newConfig.mainColor = primCP;
		newConfig.mainColor2 = secondCP;
		newConfig.navbarColor = navCP;
		newConfig.colorOverMainColor = overMainCP;
		await UpdateJsonConfig(application.apiKey, newConfig);
		await UpdateApplication(application.apiKey, user.authToken, application._id, { logo: urlLogo, icon: urlIcon });
		await reloadConfig();
		toast.success("Modifications sauvegardées !", { autoClose: 2000, position: "bottom-center" });
	}

	return (
		<>
			<div className="grid grid-cols-2">
				<div>
					<div className='flex justify-center items-center gap-x-3 mt-3'>
						<p className="text-center ml-3">Icône :</p>
						<HelpText variant='info' style={{color: 'lightblue'}} text={
							<p>L'icône doit être en format 1024 * 1024 pixels</p>
						} />
					</div>
					<div>
						<PictureUpdate
							url={urlIcon}
							name={name}
							onUpdate={(newUrl) => {
								setUrlIcon(newUrl);
							}}
							size={150}
							rounded="true"
							classnames="block ml-auto mr-auto mt-5 bg-transparent" />
					</div>
				</div>
				<div>
					<p className="text-center mt-3">Logo :</p>
					<div>
						<PictureUpdate
							url={urlLogo}
							name={name}
							onUpdate={(newUrl) => {
								seturlLogo(newUrl);
							}}
							size={150}
							rounded="true"
							classnames="block ml-auto mr-auto mt-5" />
					</div>
				</div>
			</div>
			<hr className="mt-5 border-t-2 p-2"></hr>
			<CommonButton title="Sauvegarder" onClick={setNewInfos} fontSize={18}></CommonButton>
		</>
	)
}