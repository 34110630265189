const YesNoInputV2 = ({ title, yesSelected, onClick = () => { }, yesTitle = 'Oui', noTitle = 'Non' }) => {
    return (
        <div className="py-1">
            <p className="font-medium text-sm text-left mb-2">{title}</p>

            <div className="flex gap-0">
                <p
                    className={
                        'px-3 border  rounded-l border-gray-500 text-gray-600 cursor-pointer transition-all hover:bg-opacity-50 ' +
                        (yesSelected ? 'border-blue-400 bg-blue-100  text-blue-500' : 'hover:bg-gray-100 border-r-0')
                    }
                    onClick={() => {
                        onClick(1);
                    }}
                >
                    {yesTitle}
                </p>

                <p
                    className={
                        'px-3 border  rounded-r border-gray-600 text-gray-600 cursor-pointer transition-all hover:bg-opacity-50 ' +
                        (!yesSelected ? 'border-blue-500 bg-blue-100 text-blue-500 ' : 'hover:bg-gray-100 border-l-0')
                    }
                    onClick={() => {
                        onClick(2);
                    }}
                >
                    {noTitle}
                </p>
            </div>
        </div>
    );
};

export default YesNoInputV2;
