import { useEffect, useState } from 'react';
import IconPicker from "../../../../../Common/iconpicker.component";

export default function TabBarConfigurationIcon({module, setModule}) {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    if (module?.image) {
      setIcon(module.image);
    }
  }, [module]);

  const isURL = (url) => {
    return /^[a-z][a-z0-9+.-]*:/.test(url);
  }

  const typeInvalid = ['TITLE'];
  if (typeInvalid.indexOf(module.type) >= 0 || module.style !== 'TABBAR') {
    return <></>;
  }
  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">Quel est l'icône de votre module ?</p>
      <div className="text-right rounded mt-0 w-max flex items-start mb-4 gap-2">
        {isURL(icon) && <p className="text-sm">Pas d'icônes sélectionné</p>}
        <IconPicker currentIcon={isURL(icon) ? "house" : icon} setIcon={setIcon} onChange={(icon) => {
          setModule({...module, image: icon});
        }} width={200} height={250} columns={7} />
      </div>
    </div>
  );
}
