import MapBoxGLView from "./Map/mapboxgl.view";
import { UKMapConfigService } from "../../../Services/UKMapConfig";
import { useContext } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";

const MapEditorView = ({}) => {
  const {application} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);

  return (
    <div className="w-full">
      <MapBoxGLView MapConfigService={new UKMapConfigService(application.apiKey, (user || {}).authToken)} />
    </div>
  );
}

export default MapEditorView
