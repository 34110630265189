import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import CommonButton from "../../Common/Button/CommonButton";
import TableComponent from "../../Common/Table/table.component";

const EventDetailSubevent = ({ subEvent, form, setForm, onSubmit }) => {
    const { config, langKey } = useContext(ApplicationContext);
    const history = useHistory();
    const [eventConf, setEventConf] = useState(null);
    const [subEvents, setSubEvents] = useState([]);

    useEffect(() => {
        let conf = config.eventObject.custom
            .filter((e) => e.type !== "link" && e.type !== "mapLink")
            .map((e) => ({
                title: e.tint,
                id: e.id,
            }));

        conf = conf.sort((a, b) => {
            if (a.id.indexOf("dateDebut") == 0) return -1;
            if (b.id.indexOf("dateDebut") == 0) return 1;
            if (a.id.indexOf("date") == 0) return -1;
            if (b.id.indexOf("date") == 0) return 1;
            return 0;
        });

        setEventConf(conf);

        if (subEvent && subEvent.length > 0) {
            setSubEvents(
                subEvent.map((e) => {
                    let values = [];
                    values.push(e._id);
                    for (const c of conf) {
                        let val = e.informations[`${c.id}${langKey}`];
                        if (!val) val = e.informations[c.id] || null;
                        values.push(val);
                    }
                    return values;
                })
            );
        }
    }, [subEvent]);

    const onRowClick = (e) => {
        const id = e[0];
        history.push(`/v/event/${id}`);
    };

    const newSubEvent = async () => {
        const id = v4();

        let newSubEvent = {
            _id: id,
            id: id,
            archived: false,
            informations: {},
            type: "ukEvent",
        };
        let newForm = { ...form, subEvent: form.subEvent && form.subEvent.length > 0 ? [...form.subEvent, newSubEvent] : [newSubEvent] };
        setForm(newForm);
        console.log("ON SUB 2");
        await onSubmit(newForm);
        history.push(`/v/event/${id}`);
    };
    console.log("SUB EVENT", subEvents);

    return (
        <div>
            <CommonButton customClassName="w-max mt-5 mb-3" title="Ajouter une intervention" onClick={newSubEvent} />
            <TableComponent config={eventConf} content={subEvents} startAt={1} onRowClick={onRowClick} />
        </div>
    );
};

export default EventDetailSubevent;
