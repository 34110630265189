import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { UserContext } from "../../../../Context/UserContext";
import CommonButton from "../../../Common/Button/CommonButton";
import CustomInputBox from "../../../Common/Inputs/customInputBox.component";
import PictureUpdate from "../../Account/PictureUpdate";
import SubEventInput from "../../Events/SubEvent/events.subevent";
import InputFiles from "./objects.input.files";
import ObjectsNewLinkView from "./objects.newlink.form";

const InputTextObject = ({ custom, value = "", onChange }) => {
    return (
        <input
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10"
            type="text"
            name={custom.id}
            placeholder={custom.placeholder || custom.tint || "N/A"}
            value={value}
            onChange={onChange}
        />
    );
};

const InputBigTextObject = ({ custom, value = "", onChange }) => {
    return (
        <textarea
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10"
            type="text"
            name={custom.id}
            placeholder={custom.tint || "N/A"}
            value={value}
            onChange={onChange}
        />
    );
};

const InputSelectorObject = ({ custom, value = "", onChange }) => {
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: "100%",
        }),
        control: (provided) => ({
            ...provided,
            background: "rgb(243 244 246 / var(--tw-bg-opacity))",
            border: "0px",
        }),
    };

    let valueFormatted =
        custom.type === "multipleSelector"
            ? (value || "").split("$$//$$").map((e) => {
                  return { value: e, label: e };
              })
            : {
                  value: value,
                  label: value,
              };
    if (!value) {
        valueFormatted = null;
    }
    return (
        <Select
            isMulti={custom.type === "multipleSelector"}
            styles={customStyles}
            options={(custom.values || []).map((e) => {
                return {
                    value: e,
                    label: e,
                };
            })}
            onChange={onChange}
            value={valueFormatted}
        />
    );
};

const InputDateObject = ({ custom, value = "", onChange }) => {
    let formattedDate = value?.split("+")[0].split(".")[0];
    const onChan = (e) => {
        onChange(e.target.value + ":00+01:00");
    };
    return (
        <input
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10"
            type="datetime-local"
            name={custom.id}
            placeholder={custom.placeholder || custom.tint || "N/A"}
            value={formattedDate}
            onChange={onChan}
        />
    );
};

const InputBanner = ({ value = "", id, onChange, setForm }) => {
    return (
        <div className="w-full">
            <div className="relative w-max">
                <PictureUpdate
                    url={value[id]}
                    onUpdate={(url) => {
                        onChange({ target: { value: url } });
                    }}
                    size={100}
                    customParentClassNames={"m-none"}
                />
            </div>
            <CustomInputBox
                title="Quel est le lien de la bannière ?"
                value={value[`${id}_url`] || ""}
                onChange={(e) => {
                    setForm((form) => {
                        return { ...form, [`${id}_url`]: e };
                    });
                }}
            />
        </div>
    );
};

const MapLinkObject = ({ value }) => {
    const [featuresInfos, setFeaturesInfos] = useState(null);

    useEffect(() => {
        getFeatureInfo();
    }, [value]);

    const getFeatureInfo = () => {
        if (!value) return null;
        let infos = [];
        value.constructor === Array &&
            value.map((v) => {
                infos.push(v.name || "Sans nom");
            });
        setFeaturesInfos(infos.join(", "));
    };

    return (
        <div className="w-full">
            <p className="text-gray-700 ml-1">{featuresInfos || "Aucun"}</p>
        </div>
    );
};

export const InputObject = ({ custom, config, lang, form, setForm, defaultValue = "" }) => {
    const { isAdmin } = useContext(UserContext);
    const params = useParams();
    const history = useHistory();
    const inputLinkRef = useRef(null);
    const inputSubeventRef = useRef(null);
    const inputFileRef = useRef(null);
    let input;

    let id = `${custom.id}`;
    // if ((custom.type === "text" || custom.type === "bigtext") && !params.iduser) id = `${id}${lang}`;
    id = `${id}${lang}`;
    const onChange = (e) => {
        setForm((form) => {
            return { ...form, [id]: e.target.value };
        });
    };

    switch (custom.type) {
        case "text":
        case "email":
        case "web":
            input = <InputTextObject custom={custom} value={form[id]} onChange={onChange} />;
            break;
        case "bigtext":
            input = <InputBigTextObject custom={custom} value={form[id]} onChange={onChange} />;
            break;
        case "selector":
        case "multipleSelector":
            input = (
                <InputSelectorObject
                    custom={custom}
                    value={form[id]}
                    onChange={(e) => {
                        setForm((form) => {
                            let value = e.value;
                            if (custom.type === "multipleSelector") {
                                value = e.map((e) => e.value).join("$$//$$");
                            }
                            return { ...form, [id]: value };
                        });
                    }}
                />
            );
            break;
        case "link":
            if (params.idobject === "new") return;
            input = (
                <ObjectsNewLinkView
                    ref={inputLinkRef}
                    linkURL={custom.url || null}
                    form={form}
                    setForm={setForm}
                    custom={custom}
                    defaultValue={defaultValue}
                    linksOnly={!isAdmin()}
                />
            );
            break;
        case "mapLink":
            input = <MapLinkObject value={form?.mapLink?.link} />;
            break;
        case "date":
            input = (
                <InputDateObject
                    custom={custom}
                    value={form[id]}
                    onChange={(e) => {
                        setForm((form) => {
                            return { ...form, [id]: e };
                        });
                    }}
                />
            );
            break;
        case "subEvent":
            input = <SubEventInput ref={inputSubeventRef} form={form} setForm={setForm} custom={custom} value={form[id]} />;
            break;
        case "banniere":
            input = <InputBanner value={form} id={id} onChange={onChange} setForm={setForm} />;
            break;
        case "files":
            input = <InputFiles ref={inputFileRef} value={form[id]} id={id} setForm={setForm} />;
            break;
        case "icon":
            input = (
                <div className="flex w-full items-end gap-2 relative">
                    <div className="relative w-max">
                        <PictureUpdate
                            url={form[id]}
                            onUpdate={(url) => {
                                setForm((form) => {
                                    return { ...form, [id]: url };
                                });
                            }}
                            size={100}
                            customParentClassNames={"m-none"}
                        />
                    </div>
                    <CommonButton
                        customColor={"#F87171"}
                        useAppTheme={false}
                        customClassName="w-max mt-2 p-3"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => {
                            setForm((form) => {
                                return { ...form, [id]: "" };
                            });
                        }}
                    />
                </div>
            );
            break;
        default:
            input = null;
            break;
    }
    if (input == null) {
        return null;
    }

    return (
        <div className={`${custom.type === "bigtext" ? "" : "items-start"} text-left flex  flex-col items-start`}>
            <label className={` ${custom.type === "bigtext" ? "pt-2" : ""} block text-gray-700 text-sm font-semibold w-1/3 text-left mr-3`}>
                {custom.tint || "N/A"} <span className="text-red-400">{custom.important ? "*" : ""} </span> : 
                {isAdmin() && custom.type === "link" && (
                    <CommonButton
                        customClassName="w-max ml-auto"
                        title="Ajouter un lien"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => {
                            inputLinkRef.current.openModal();
                        }}
                    />
                )}
                {!isAdmin() &&
                    custom.type === "link" &&
                    custom.linkType === "object" &&
                    custom.canCreateLink &&
                    (form[custom.id]?.length > 0 ? custom.multiLink === true : true) && (
                        <CommonButton
                            customClassName="w-max ml-auto"
                            title="Créer un objet"
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() => {
                                history.push(`/v/object/new/${custom.url}?link=${params.idobject}&type=${config.id}`);
                            }}
                        />
                    )}
                {custom.type === "subEvent" && (
                    <CommonButton
                        customClassName="w-max ml-auto"
                        title="Sous-événement"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => {
                            inputSubeventRef.current.openModal();
                        }}
                    />
                )}
                {isAdmin() && custom.type === "files" && (
                    <CommonButton
                        customClassName="w-max ml-auto"
                        title="Upload un fichier"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => {
                            inputFileRef.current.openModal();
                        }}
                    />
                )}
            </label>

            {input}
        </div>
    );
};
