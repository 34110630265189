import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";

const CustomSearchBar = ({filter, onChange, onClear, hasClearButton = true, hasIcon = true, customStyle, placeholder}) => {
  const [search, setSearch] = useState(filter);
  const [timeout, setTimeoutValue] = useState(null);

  return (
    <div className={`${customStyle && customStyle.length > 0 ? customStyle : 'flex items-center justify-center h-max py-2 rounded bg-white border gap-2 px-2 w-max'}`}>
      {hasIcon && <FontAwesomeIcon className="text-gray-400" icon={faSearch} />}
      <input
        className="rounded rounded-l"
        placeholder={`${placeholder ? placeholder : 'Rechercher'}`}
        type="text"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          if (timeout) clearTimeout(timeout);
          setTimeoutValue(
            setTimeout(() => {
              onChange(e.target.value);
            }, 500)
          );
        }} />
      {hasClearButton && <FontAwesomeIcon onClick={() => {
        setSearch("");
        if (!onClear) return;
        onClear();
      }} className="text-red-400" icon={faClose} />}
    </div>
  );
}

export default CustomSearchBar
