import { useState } from "react";

const DocsSettingsIconColor = ({doc, setDocSelected}) => {
  const [iconColor, setIconColor] = useState(doc.informations.iconColor);

  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">Quelle couleur souhaitez vous donner à votre icône ?</p>
      <div className="flex border-2 rounded cursor-pointer w-max">
      <input type="color" value={iconColor} onChange={(event) => {
        setIconColor(event.target.value);
        setDocSelected((prevDoc) => {
          let newDoc = {...prevDoc};
          newDoc.informations.iconColor = event.target.value;
          return newDoc;
        })
      }}></input>
      </div>
    </div>
  );
}

export default DocsSettingsIconColor
