import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { UKObjectClass } from "../../../../Class/UKObjectClass";
import { UKUser } from "../../../../Class/UKUser";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { CreateObject, GetEventById, GetUKObjectById, UpdateObject } from "../../../../Services/UKObject";
import { GetUserById, UpdateUser } from "../../../../Services/User";
import CommonButton from "../../../Common/Button/CommonButton";
import EventDetailForm from "../../Events/event.detail.form";
import ObjectsForm from "./objects.form";

function ObjectDetailView() {
    const history = useHistory();
    const { config, langKey, application } = useContext(ApplicationContext);
    const { user, isAdmin } = useContext(UserContext);
    const location = useLocation();
    let { params } = useRouteMatch();
    const [objectClass, setObjectClass] = useState(null);
    const oldLinks = useRef([]);

    useEffect(() => {
        if (!params) return null;
        console.log("Reset object class");
        setObjectClass(null);
        reloadObject();
    }, [params]);

    const dupObject = async () => {
        if (!application) return;
        let object = await GetUKObjectById(params.idobject);
        await CreateObject(object.type, object.informations, application.apiKey, (user || {}).authToken);
        toast.success("Objet dupliqué !", { autoClose: 2000 });
    };

    const reloadObject = async () => {
        console.log("Reload object", params.idobject);
        if (params.idobject === "new") {
            if (params.typeobject) {
                setObjectClass(new UKObjectClass({ type: params.typeobject }, config));
            }
        } else {
            let object;
            try {
                if (params.iduser) {
                    object = await GetUserById(params.iduser);
                    if (config && object) {
                        object = new UKUser(object, config);
                    }
                } else if (location.pathname.includes("event/")) {
                    object = await GetEventById(params.idobject, application.apiKey);
                    if (config && object) {
                        object = new UKObjectClass(object, config);
                    }
                } else {
                    object = await GetUKObjectById(params.idobject);
                    if (config && object) {
                        object = new UKObjectClass(object, config);
                    }
                }
                setObjectClass(object);
                registerOldLinks(object);
            } catch (e) {}
        }
    };

    const registerOldLinks = (object) => {
        let linksID = [];
        object.config.custom?.forEach((obj) => {
            obj.type === "link" && linksID.push(obj.id);
        });
        let oldLinksArray = [];
        linksID.forEach((id) => {
            oldLinksArray.push({ id: id, links: object.json.informations[id] || [] });
        });
        oldLinks.current = JSON.parse(JSON.stringify(oldLinksArray));
    };

    const addLinks = async (links, currentObjectID) => {
        links.forEach((obj) => {
            const objectType = objectClass.config.custom.find((custom) => custom.id === obj.id).linkType;
            obj.links?.forEach(async (link) => {
                let newObject;
                if (objectType === "user") {
                    newObject = await GetUserById(link, application.apiKey, (user || {}).authToken);
                    newObject.informations = {
                        ...newObject.informations,
                        [obj.id]: newObject.informations[obj.id] ? [...newObject.informations[obj.id], currentObjectID] : [currentObjectID],
                    };
                    await UpdateUser(link, newObject.informations, application.apiKey, (user || {}).authToken);
                } else {
                    newObject = await GetUKObjectById(link, application.apiKey, (user || {}).authToken);
                    newObject.informations = {
                        ...newObject.informations,
                        [obj.id]: newObject.informations[obj.id] ? [...newObject.informations[obj.id], currentObjectID] : [currentObjectID],
                    };
                    await UpdateObject(link, objectType, newObject.informations, application.apiKey, (user || {}).authToken);
                }
            });
        });
    };

    const deleteLinks = async (links, currentObjectID) => {
        links.forEach((obj) => {
            const objectType = objectClass.config.custom.find((custom) => custom.id === obj.id).linkType;
            obj.links?.forEach(async (link) => {
                let newObject;
                if (objectType === "user") {
                    newObject = await GetUserById(link, application.apiKey, (user || {}).authToken);
                    newObject.informations[obj.id] = newObject.informations[obj.id]?.filter((e) => e !== currentObjectID);
                    await UpdateUser(link, newObject.informations, application.apiKey, (user || {}).authToken);
                } else if (objectType === "ukEvent") {
                    newObject = await GetEventById(link, application.apiKey);
                    newObject.informations[obj.id] = newObject.informations[obj.id]?.filter((e) => e !== currentObjectID);
                    await UpdateObject(link, "ukEvent", newObject.informations, application.apiKey, (user || {}).authToken);
                }
                // else if (location.pathname.includes("event/")) {
                //   newObject = await GetEventById(params.idobject, application.apiKey);
                //   newObject.informations[obj.id] = newObject.informations[obj.id]?.filter(e => e !== currentObjectID);
                //   await UpdateObject(link, 'ukEvent', newObject.informations, application.apiKey, (user || {}).authToken);
                // }
                else {
                    newObject = await GetUKObjectById(link, application.apiKey, (user || {}).authToken);
                    newObject.informations[obj.id] = newObject.informations[obj.id]?.filter((e) => e !== currentObjectID);
                    await UpdateObject(link, objectType, newObject.informations, application.apiKey, (user || {}).authToken);
                }
            });
        });
    };

    const updateLinks = async (oldLinkss, form, currentObjectID, createdObj, linkToId) => {
        let linksID = objectClass.config.custom
            ?.filter((obj) => {
                return obj.type === "link";
            })
            .map((e) => e.id);
        let newLinks = linksID.map((id) => {
            return { id: id, links: form[id] || [] };
        });
        const linkToCreate = [];
        const linkToDelete = [];
        newLinks.forEach((obj) => {
            const oldLinkObj = oldLinks.current.find((oldObj) => oldObj.id === obj.id);
            linkToCreate.push({ id: obj.id, links: obj.links.filter((x) => !oldLinkObj?.links?.includes(x)) });
        });
        oldLinks.current.forEach((obj) => {
            const newLinkObj = newLinks.find((newObj) => newObj.id === obj.id);
            linkToDelete.push({ id: obj.id, links: obj.links.filter((x) => !newLinkObj?.links?.includes(x)) });
        });
        if (!isAdmin() && params.idobject === "new") {
            let links = [];
            if (linkToId === user._id) {
                links = config.inscription.customs
                    .find((c) => c.id === user.informations.user_custom_type)
                    .custom.filter((c) => c.url === params.typeobject)
                    .map((r) => ({ id: r.id, links: [linkToId] }));
                linkToCreate.push(...links);
            } else {
                const linkType = new URLSearchParams(location.search).get("type");
                if (linkType) {
                    links = config.customObject
                        .find((c) => c.id === linkType)
                        .custom.filter((c) => c.url === params.typeobject)
                        .map((r) => ({ id: r.id, links: [linkToId] }));
                    linkToCreate.push(...links);
                }
            }
            if (createdObj) {
                for (const link of links) {
                    createdObj.informations[link.id] = createdObj.informations[link.id] ? [...createdObj.informations[link.id], linkToId] : [linkToId];
                    if (location.pathname.includes("event/")) {
                        await UpdateObject(currentObjectID, "ukEvent", createdObj.informations, application.apiKey, (user || {}).authToken);
                    } else {
                        await UpdateObject(
                            currentObjectID,
                            objectClass.config.custom.find((custom) => custom.id === link.id).linkType,
                            createdObj.informations,
                            application.apiKey,
                            (user || {}).authToken
                        );
                    }
                }
            }
        }

        await addLinks(linkToCreate, currentObjectID);
        if (params.idobject !== "new") await deleteLinks(linkToDelete, currentObjectID);
    };

    const onSubmit = async (form) => {
        const linkId = new URLSearchParams(location.search).get("link");
        if (params.idobject === "new") {
            let newObject = await CreateObject(params.typeobject === "event" ? "ukEvent" : params.typeobject, form, application.apiKey, (user || {}).authToken);
            await updateLinks(oldLinks.current, form, newObject._id, newObject, linkId ? linkId : user._id);
            toast.success("Objet créé !", { autoClose: 2000, position: "bottom-center" });
            if (params.typeobject === "event") {
                return history.push(`/v/event/${newObject._id}`);
            }
            return history.push(`/v/object/${newObject._id}/${newObject.type}`);
        } else {
            if (params.iduser) {
                await UpdateUser(params.iduser, form, application.apiKey, (user || {}).authToken);
            } else if (location.pathname.includes("event/")) {
                await UpdateObject(params.idobject, "ukEvent", form, application.apiKey, (user || {}).authToken);
            } else {
                await UpdateObject(params.idobject, params.typeobject, form, application.apiKey, (user || {}).authToken);
            }
            await updateLinks(oldLinks.current, form, params.idobject ?? params.iduser, null, null);
            console.log("RELOAD OBJECT 2");
            await reloadObject();
            toast.success("Objet mis à jour !", { autoClose: 1000, position: "bottom-center" });
        }
    };

    if (!objectClass || !config) {
        return <></>;
    }

    console.log("oBJECT CLASS", objectClass);

    return [
        <div className="py-4">
            <div className="py-10 bg-white border border-gray-300 rounded p-2 max-w-[95%] m-auto">
                <div className="flex justify-end">
                    {isAdmin() && !params.iduser && !location.pathname.includes("event/") && (
                        <CommonButton customClassName="w-max" title="Dupliquer l'objet" onClick={dupObject} icon={<FontAwesomeIcon icon={faCopy} />} useAppTheme={false} />
                    )}
                </div>

                {(objectClass.type === "ukEvent" || location.pathname.includes("event/")) && (
                    <EventDetailForm
                        defaultValues={objectClass?.json?.informations}
                        onSubmit={async (e) => {
                            console.log("ON SUMBIT 1");
                            await onSubmit(e);
                        }}
                    />
                )}
                {objectClass.type !== "ukEvent" && !location.pathname.includes("event/") && (
                    <ObjectsForm
                        config={objectClass.type === "ukEvent" ? config.eventObject : objectClass.config}
                        lang={langKey}
                        defaultValue={objectClass?.json?.informations}
                        onSubmit={(e) => {
                            console.log("ON SUMBIT 2");
                            onSubmit(e);
                        }}
                        question={objectClass.type === "ukEvent"}
                    />
                )}
            </div>
        </div>,
    ];
}

export default ObjectDetailView;
