import { useContext } from 'react';
import { Card, ZoomableImage } from 'react-rainbow-components';
import { ApplicationContext } from '../../../Context/ApplicationContext';

function ObjectDetailInfoComponent({ objectClass, hasLink, setEditMode, lang }) {
  const { config, langKey} = useContext(ApplicationContext);

  return (
    <div className="col-span-6 py-5 px-4">
      <div className="grid grid-cols-1 gap-4 gap-y-6">
        <div key={'objectupdatelogo'} className={'text-left flex items-center mb-3'}>
          <img
            className="block text-gray-700 text-sm font-semibold w-auto text-right mr-3"
            style={{ maxHeight: 100 }}
            src={objectClass.getPicture()}
            alt=""
          ></img>
          <p className="w-full block text-gray-700 text-xl font-bold text-left ">{objectClass.getTitle(lang)}</p>
        </div>

        {objectClass.config.custom.map((elem, index) => {
          var value = objectClass.getInformations(elem.id, langKey);

          if (elem.type === 'LINK') {
            return null;
          }
          if (elem.hidden === true || (value == null && elem.type !== 'documents')) {
            return null;
          }
          if (elem.isTitle === true) {
            return null;
          }
          if (elem.type === 'picture' || elem.type === 'keywords') {
            return null;
          }
          if (elem.type === 'video') {
            if (value.indexOf('youtube') !== -1) {
              var id = value.split('?v=')[1]; //sGbxmsDFVnE

              var embedlink = 'https://www.youtube.com/embed/' + id; //www.youtube.com/embed/sGbxmsDFVnE
              return (
                <iframe
                  title="title"
                  id="myIframe"
                  width="560"
                  height="315"
                  frameborder="0"
                  allowfullscreen
                  src={embedlink}
                ></iframe>
              );
            }
            return <video controls src={value}></video>;
          }
          if (elem.type === 'documents') {
            value = objectClass.getInformations(elem.id, langKey);
            if (value == null) {
              return null;
            }
            return (
              <div className="w-full">
                <div
                  key={'objectupdate' + index}
                  className={(elem.type === 'bigtext' ? '' : '') + 'text-left flex items-start'}
                >
                  <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">{elem.tint}</label>
                  <p className="w-full"></p>
                </div>

                <div className="grid grid-cols-3 pt-4 gap-3">
                  {(value || []).map((e, indexDoc) => {
                    if (e.indexOf('.pdf') >= 0) {
                      return (
                        <div
                          className="relative"
                          onClick={() => {
                            window.open(e, '_blank');
                          }}
                        >
                          <Card
                            className="relative cursor-pointer flex items-center justify-center"
                            style={{ height: 100 }}
                          >
                            <img className=" object-cover" style={{ height: 50 }} src="/pdf.png" alt="" />
                          </Card>
                        </div>
                      );
                    }
                    return (
                      <div className="relative">
                        <Card className="relative flex items-center justify-center" style={{ height: 100 }}>
                          <ZoomableImage
                            src={e}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'contain',
                            }}
                          />
                        </Card>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
          if (elem.type === 'multipleSelector') {
            var arr = value.split('$$//$$');
            value = arr.map((elem, index) => {
              return <p key={elem.id + '-' + index}>- {elem}</p>;
            });
          }

          return (
            <div
              key={'objectupdate' + index}
              className={(elem.type === 'bigtext' ? '' : '') + 'text-left flex items-start'}
            >
              <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">{elem.tint}</label>
              {/* eslint-disable-next-line */}
              {elem.type === 'url' ? (
                <a className="w-full block text-gray-700 text-sm font-normal text-left ">{value}</a>
              ) : (
                <p className="w-full block text-gray-700 text-sm font-normal text-left ">{value}</p>
              )}
            </div>
          );
        })}
      </div>
      {hasLink && (
        <div className="px-0 pb-2 pt-4 flex">
          <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3"></label>
          <div className="w-full text-left">
            <button
              className=" bg-gray-800 hover:bg-grey-900 text-white text-sm py-1 px-2 font-semibold rounded focus:outline-none focus:shadow-outline"
              style={{
                color: (config || {}).colorOverMainColor ?? '#FFFFFF',
                backgroundColor: (config || {}).mainColor ?? '#000000',
              }}
              onClick={() => setEditMode(true)}
            >
              Modifier
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ObjectDetailInfoComponent;
