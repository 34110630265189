import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import GL, { WidthProvider } from 'react-grid-layout';
import { v4 } from 'uuid';
import { ApplicationContext } from "../../../../../../Context/ApplicationContext";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const GridLayout = WidthProvider(GL);
const HomeItem = ({ item, index, key, selected }) => {
  const {langKey} = useContext(ApplicationContext);

  return (
    <div className={(item.style === 'FULLSQUARE' ? 'col-span-2' : 'col-span-1') + ' overflow-hidden h-full w-full bg-white '}>
      {item.type === 'TITLE' && <p>{(item.text || '').trim().length > 0 ? item.text : 'Text'}</p>}
      {item.type === 'FORMATTEDTEXT' && <div>{(item["formattedText" + langKey] || item.formattedText || "").trim().length > 0 ? ReactHtmlParser(item["formattedText" + langKey]) || ReactHtmlParser(item.formattedText) || "" : 'Text'}</div>}
      {item.type !== 'TITLE' && item.type !== "FORMATTEDTEXT" && <img src={item.image} alt={'image' + index} className="w-full object-contain h-full w-full" />}
      {selected && <div className="absolute top-0 left-0 h-full w-full bg-green-300 bg-opacity-20 border border-green-400"></div>}
    </div>
  );
};

export default function GridView({ currentPage, page, setPage, handleSelectedModule, moduleSelected }) {
  const gridRef = useRef();
  const [tmpPage, setTmpPage] = useState('');
  const [layout, setLayout] = useState(null);

  function sortByPosition(a, b) {
    if (a.y == b.y) return a.x - b.x;
    return a.y - b.y;
  }

  useEffect(() => {
    setTmpPage(currentPage);
  }, [currentPage]);

  /* On calcule la position des modules vis-à-vis de leurs taille */
  const reloadLayoutFromPage = (page) => {
    var x = 0;
    var y = 0;
    const layoutTmp = [];
    for (var i = 0; i < page.length; i++) {
      var e = page[i];
      var size = e.style == 'HALFSQUARE' ? 1 : 2;
      if (e.type == 'TITLE') {
        size = 2;
      }
      if (x > 1) {
        x = 0;
        y += 1;
      }
      var obj = {
        i: e._id || v4(),
        x: x,
        y: y,
        w: size,
        minW: 1,
        maxW: 2,
        minH: 1,
        maxH: 1,
        h: 1,
        data: page[e],
      };
      x += size;
      if (size == 2 || x > 1) {
        x = 0;
        y += 1;
      }
      layoutTmp.push(obj);
    }
    setLayout([...layoutTmp]);
  };

  useEffect(() => {
    reloadLayoutFromPage(page);
  }, [page]);

  const updatePosition = useCallback(
    (newLayout) => {
      var layoutSorted = newLayout.sort(sortByPosition);
      var newPage = [...page];
      newPage = newPage.sort((a, b) => {
        var indexA = layoutSorted.findIndex((e) => e.i === a._id);
        var indexB = layoutSorted.findIndex((e) => e.i === b._id);
        return indexA - indexB;
      });

      let update = false;
      for (let i = 0; i < newPage.length; i++) {
        if (newPage[i]._id !== page[i]._id) {
          update = true;
        }
        // if (newPage[i]._id === page[i]._id) {
        //   update = false;
        // }
        try {
          if (newPage[i].style === 'HALFSQUARE' && layoutSorted[i].w === 2) {
            newPage[i].style = 'FULLSQUARE';
            update = true;
          }
          if (newPage[i].style === 'FULLSQUARE' && layoutSorted[i].w === 1) {
            newPage[i].style = 'HALFSQUARE';
            update = true;
          }
          if (newPage[i].type === 'TITLE') {
            newPage[i].style = 'FULLSQUARE';
          }
        } catch (e) {}
      }
      if (update) {
        setPage(newPage);
      }
    },
    [page, layout, tmpPage, currentPage]
  );

  if (!page || !layout) {
    return <p>Chargement ...</p>;
  }

  return (
    <GridLayout
      ref={gridRef}
      autoSize={true}
      onLayoutChange={(layout) => {
        updatePosition(layout);
      }}
      className="layout"
      layout={layout}
      cols={2}
      rowHeight={90}
    >
      {page.map((e, index) => {
        return (
          <div
            className="overflow-hidden hover:opacity-50 cursor-pointer"
            onClick={() => {
              handleSelectedModule(e);
            }}
            key={e._id}
          >
            <HomeItem item={e} index={index} selected={moduleSelected && moduleSelected._id === e._id} />
          </div>
        );
      })}
    </GridLayout>
  );
}
