import { useContext, useEffect, useState } from "react";
import CommonButton from "../../Common/Button/CommonButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBallotCheck } from "@fortawesome/pro-solid-svg-icons";
import VotesCell from "./votes.cell";
import { CreateObject, DeleteObject, GetObjectsAll, UpdateObject } from "../../../Services/UKObject";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { toast } from "react-toastify";

const VotesView = ({}) => {
  const {application} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [voteList, setVoteList] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)

  useEffect(() => {
    fetchVotes();
  }, []);

  const fetchVotes = async () => {
    let votes = await GetObjectsAll("UKVotes", application.apiKey, (user || {}).authToken);
    if (!votes) return;
    setVoteList(votes);
  }

  const createVote = async () => {
    let newVote = {type: "custom", showResult: false};
    await CreateObject("UKVotes", newVote, application.apiKey, (user || {}).authToken);
    fetchVotes();
  }

  const updateVoteInfo = (key, value) => {
    if (!selectedForm) return;
    let newVote = voteList.find((e) => {
      return e._id === selectedForm._id;
    });
    if (key === "type") {
      delete newVote.informations.options;
      delete newVote.informations.optionType;
    }
    newVote.informations[key] = value;
    setVoteList((prevList) => {
      let index = prevList.findIndex(e => e._id === selectedForm._id)
      let newList = [...prevList];
      newList[index] = newVote;
      return newList
    })
  }

  const saveVoteInfo = async () => {
    await UpdateObject(selectedForm._id, "UKVotes", selectedForm.informations, application.apiKey, (user || {}).authToken);

    return toast.success('Vote sauvegardé', {autoClose: 2000, position: 'bottom-center'});
  }

  const deleteVote = async () => {
    if (!window.confirm('Voulez-vous supprimer ce vote ?')) {
      return;
    }
    await DeleteObject(selectedForm._id, "UKVotes", application.apiKey, (user || {}).authToken)
    setVoteList((prevList) => {
      let newList = [...prevList];
      let index = prevList.findIndex(e => e._id === selectedForm._id)
      newList.splice(index, 1);
      return newList;
    })
  }

  return (
    <div className="h-full">
      <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Votes</p>
      <div className="w-11/12 m-auto">
        <div className="flex justify-end">
          <CommonButton customClassName="h-8 mb-3"
                        useAppTheme={false}
                        icon={<FontAwesomeIcon icon={faBallotCheck} />}
                        title="Créer un vote"
                        onClick={() => {
                          createVote()
                        }} />
        </div>
        <div className="space-y-5 mb-20">
          {voteList && voteList.map((e, index) => {
            return (
              <div key={index}>
                <VotesCell isSelected={!selectedForm ? false : e._id === selectedForm._id} voteInfo={e} onClick={() => {
                  setSelectedForm((selectedForm === e) ? null : e);
                }} updateVoteInfo={updateVoteInfo} deleteVote={deleteVote} saveVoteInfo={saveVoteInfo}/>
              </div>
            )
          })}
          {(!voteList || voteList.length === 0) && <p className="text-gray-800 font-bold text-center">Aucun vote</p>}
        </div>
      </div>
    </div>
  );
}

export default VotesView
