import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { CheckboxToggle } from 'react-rainbow-components';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../Context/UserContext';
import { CreateObject, DeleteObject, GetObjectsAll, UpdateObject } from '../../../../../Services/UKObject';
import AddButton from '../../../../Common/Button/AddButton';
import TrashButton from '../../../../Common/Button/TrashButton';

const style = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'rgba(243, 244, 246, 1)',
    borderWidth: 0,
    color: 'red',
    fontSize: '0.875rem',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: 'rgba(55, 65, 81, 0.6)',
    };
  },
};

export default function GroupManagerComponent() {
  const { application, config } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [groups, setGroups] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function initGroups() {
      if (!application || !user) return null;
      const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
      setGroups(groups);
    }
    initGroups();
  }, [application, user, reload]);

  if (!groups) return null;

  return (
    <div className=" text-gray-800 text-center bottom-4 pb-8 w-11/12 m-auto mt-5 justify-center">

      <p className=" px-3 mb-2 text-xl font-bold text-left">Vos groupes</p>
      <p className="text-left px-3 font-light">
        Les groupes vous permettent de catégoriser vos objets et vos utilisateurs si vous souhaitez les trier par la suite.
      </p>
      {groups.map((element, index) => {
        const e = element.informations;
        var parent = null;
        if (e.parentId) {
          var parentGroup = groups.find((el) => el._id === e.parentId);
          if (parentGroup) {
            parent = {
              value: parentGroup.informations.name,
              label: parentGroup.informations.name,
            };
          }
        }

        return (
          <div className="flex m-3 border border-gray-300 rounded-md p-2 bg-white">
            <input
              className=" text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
              placeholder="Nom"
              onChange={(ev) => {
                const newArr = [...groups];
                newArr[index].informations.name = ev.target.value;
                setGroups(newArr);
              }}
              value={e.name}
            />
            <div className="w-full flex gap-x-3">
              <CheckboxToggle
                label="Parent"
                labelAlignment="left"
                value={!e.noParent}
                onChange={() => {
                  if (e.noParent) {
                    toast.error('Sélectionnez un parent', { position: 'bottom-center', autoClose: 1000 });
                    return null;
                  }
                  const newArr = [...groups];
                  newArr[index].informations.noParent = !newArr[index].informations.noParent;
                  delete newArr[index].informations.parentId;
                  setGroups(newArr);
                }}
              />
              <Select
                placeholder="Selectionner"
                styles={style}
                value={parent}
                options={groups
                  .filter((el) => el !== element && el.informations.parentId !== element._id)
                  .map((e) => {
                    return { value: e.informations.name, label: e.informations.name, id: e._id };
                  })}
                name={element._id}
                onChange={(a, b) => {
                  const newArr = [...groups];
                  newArr[index].informations.parentId = a.id;
                  newArr[index].informations.noParent = false;
                  setGroups(newArr);
                }}
              />
              <div className="flex items-center justify-center">
                <TrashButton
                  onClick={async () => {
                    if (window.confirm('Voulez-vous supprimer ce groupe ?')) {
                      await DeleteObject(element._id, 'ukGroup', application.apiKey, user.authToken);
                      setReload(!reload);
                    }
                  }}
                ></TrashButton>
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex items-end justify-end gap-3 px-3">
        <AddButton
          title="Créer"
          icon={faPlus}
          onClick={async () => {
            await CreateObject(
              'ukGroup',
              {
                name: '',
                noParent: true,
                index: (groups.length + 1).toString(),
              },
              application.apiKey,
              user.authToken
            );
            setReload(!reload);
          }}
        />

        <AddButton
          title="Sauvegarder"
          icon={faSave}
          onClick={() => {
            groups.forEach(async (e) => {
              await UpdateObject(e._id, 'ukGroup', e.informations, application.apiKey, user.authToken);
            });
            toast.success('Modifications sauvegardées !', { position: 'bottom-center', autoClose: 2000 });
          }}
        />
      </div>
    </div>
  );
}
