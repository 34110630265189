import React, {useEffect, useState} from "react";
import CommonButton from "../../../../Common/Button/CommonButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {v4} from "uuid";
import CustomInputBox from "../../../../Common/Inputs/customInputBox.component";
import {toast} from "react-toastify";
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import CustomColorInput from "../../../../Common/Inputs/customColorInput.component";
import {defaultCategory} from "../map.const";
import CustomNumberInput from "../../../../Common/Inputs/customNumberInput.component";
import YesNoInput from "../../../Account/Admin/Design/Component/YesNoInput";
import {faMagnifyingGlassMinus, faMagnifyingGlassPlus} from "@fortawesome/pro-regular-svg-icons";

const CategoryConfigView = ({MapConfigService, isOpen, onSave, map, mapDraw}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newField, setNewField] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMapConfig();
  }, []);

  const fetchMapConfig = async () => {
    let categoriesConfig = await MapConfigService.get('categories');
    if (!categoriesConfig) {
      categoriesConfig = [defaultCategory];
    }
    setCategories(categoriesConfig);
    setSelectedCategory(categoriesConfig[0]);
  }

  const addField = () => {
    if (newField.length === 0) {
      return toast.error('Champ sans nom !', {
        autoClose: 1000,
        position: 'bottom-right',
      });
    }
    let newSelectedCategory = {...selectedCategory}
    newSelectedCategory.informations[newField] = '';
    setSelectedCategory(newSelectedCategory);
    setNewField('')
  }

  const deleteField = (key) => {
    let newSelectedCategory = {...selectedCategory}
    delete newSelectedCategory.informations[key];
    setSelectedCategory(newSelectedCategory)
  }

  const addCategory = () => {
    let newCategories = categories;
    const newCategory = {
      id: v4(),
      name: 'Nouvelle catégorie',
      informations: {}
    };
    newCategories.push(newCategory);
    setCategories(newCategories);
    setSelectedCategory(newCategory);
  }

  const deleteCategory = async () => {
    if (!window.confirm("Voulez-vous supprimer cette catégorie ?")) return;
    if (selectedCategory.locked) {
      return toast.error('Cette catégorie ne peut pas être supprimée !', {
        autoClose: 2000,
        position: 'bottom-right',
      });
    }
    let newCategories = categories;
    const index = newCategories.findIndex(c => c.id === selectedCategory.id);
    newCategories.splice(index, 1);
    setCategories(newCategories);
    setSelectedCategory(categories[0]);
    await MapConfigService.update({categories: newCategories});
  }

  const saveCategory = async () => {
    const index = categories.findIndex(c => c.id === selectedCategory.id);
    setIsLoading(true);
    const newCategories = [...categories];
    newCategories[index] = selectedCategory;
    setCategories(newCategories);

    mapDraw.current.getAll().features.map(f => {
      if (f.properties.category !== selectedCategory.id) return f;
      mapDraw.current.setFeatureProperty(f.id, "categoryColor", selectedCategory.categoryColor);
      mapDraw.current.add(mapDraw.current.get(f.id));
    });
    const response = await MapConfigService.update({categories: newCategories});
    if (response) {
      toast.success('Catégorie mise à jour', {autoClose: 2000, position: 'bottom-right'});
    } else {
      toast.error('Erreur lors de la mise à jour', {autoClose: 2000, position: 'bottom-center'});
    }
    onSave(newCategories);
    setIsLoading(false);
  }

  return (
    <div className={`transition-all duration-500 overflow-hidden ${isOpen ? 'min-w-[350px] w-[40%] mt-4' : 'w-0 h-0'}`}>
      <div className='flex gap-3 items-center justify-start'>
        {selectedCategory && categories && categories.length > 0 && <select
          className="h-7 ml-2 border rounded"
          value={selectedCategory.id}
          onChange={(e) => {
            saveCategory();
            setSelectedCategory(() => categories.find(c => c.id === e.target.value));
          }}>
          {categories && categories.map((category, index) => {
              return (
                <option key={index} value={category.id}>{category.name}</option>
              );
            }
          )}
        </select>}
        <CommonButton customClassName='w-max' title='Ajouter une catégorie' useAppTheme={false} onClick={addCategory}/>
        <CommonButton customClassName='w-max' title='Supprimer' useAppTheme={false} customColor={'#F87171'}
                      onClick={deleteCategory}/>
      </div>
      <p className="text-gray-700 text-sm mt-2">Toutes ces propriétés ne s'appliquent pas à tous les types d'objet</p>
      {selectedCategory && <div className="bg-white m-2">
        <div className="m-2 space-y-1">
          <CustomInputBox title='Quel est le nom de votre catégorie ?' value={selectedCategory.name || ''}
                          onChange={(value) => setSelectedCategory({...selectedCategory, name: value})}/>
          <CustomColorInput title="Quelle est la couleur des objets ?" value={selectedCategory.categoryColor || ''}
                            onChange={(value) => setSelectedCategory({...selectedCategory, categoryColor: value})}/>
          <CustomNumberInput title="Quelle est la hauteur des objets ? (Vue 3D)"
                             value={selectedCategory.categoryHeight || ''}
                             onChange={(value) => setSelectedCategory({...selectedCategory, categoryHeight: value})}/>
          <YesNoInput title="Intéraction possible avec les objets de la catégorie ?"
                      yesSelected={selectedCategory?.hasInteraction || false} onClick={(value) => {
            setSelectedCategory({...selectedCategory, hasInteraction: value === 1})
          }}/>

          {/*Minimum zoom*/}
          <div className="flex gap-2 items-end">
            <CustomNumberInput title="Zoom minimum auquel l'objet est visible" value={selectedCategory?.minZoom || ''}
                               onChange={(value) => setSelectedCategory({...selectedCategory, minZoom: value})}/>
            <CommonButton customClassName="h-10 w-8 justify-center"
                          icon={<FontAwesomeIcon icon={faMagnifyingGlassMinus}/>} onClick={() => {
              setSelectedCategory({...selectedCategory, minZoom: map.current.getZoom()})
            }}/>
          </div>

          {/*Maximum zoom*/}
          <div className="flex gap-2 items-end">
            <CustomNumberInput title="Zoom maximum auquel l'objet est visible" value={selectedCategory?.maxZoom || ''}
                               onChange={(value) => setSelectedCategory({...selectedCategory, maxZoom: value})}/>
            <CommonButton customClassName="h-10 w-8 justify-center"
                          icon={<FontAwesomeIcon icon={faMagnifyingGlassPlus}/>} onClick={() => {
              setSelectedCategory({...selectedCategory, maxZoom: map.current.getZoom()})
            }}/>
          </div>

          {/*<div className="border w-full h-full">*/}
          {/*  <p className="text-left ml-4 mb-2 text-md">Informations Complémentaires</p>*/}
          {/*  {selectedCategory && selectedCategory.informations && Object.entries(selectedCategory.informations).map((entry) => {*/}
          {/*    return <div className="flex gap-3 items-center justify-start">*/}
          {/*      <p className="text-left ml-3 w-[70ch] text-ellipsis overflow-hidden">{entry[0]}</p>*/}
          {/*      <input placeholder="Valeur" className="custominputbox" value={entry[1]} onChange={(e) => {*/}
          {/*        setSelectedCategory({...selectedCategory, informations: {...selectedCategory.informations, [entry[0]]: e.target.value}})*/}
          {/*      }} />*/}
          {/*      <CommonButton customClassName="m-1 mr-2 w-max" useAppTheme={false} customColor={"#F87171"} icon={<FontAwesomeIcon className="m-1" icon={faTrash} />} onClick={() => {*/}
          {/*        deleteField(entry[0]);*/}
          {/*      }} />*/}
          {/*    </div>*/}
          {/*  })}*/}
          {/*  <div className="mt-5">*/}
          {/*    <p className="ml-2 font-medium text-sm text-left mb-2">Ajouter une information</p>*/}
          {/*    <div className="flex items-center gap-2">*/}
          {/*      <input placeholder="Nom" className="custominputbox" value={newField} onChange={(e) => {*/}
          {/*        setNewField(e.target.value)*/}
          {/*      }} />*/}
          {/*      <CommonButton customClassName="m-1 mr-2 h-7 w-8 justify-center" icon={<FontAwesomeIcon icon={faPlus} />} onClick={addField} />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div
            className="w-max m-auto mb-3 border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
            onClick={saveCategory}>
            <FontAwesomeIcon icon={isLoading ? faCloudArrowUp : faCheck}/>
          </div>
          <span className="h-2">&nbsp;</span>
        </div>
      </div>}
    </div>
  );
}

export default CategoryConfigView
