import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import moment from "moment";
import "moment/locale/fr";
import { GetAppointmentWithUserID } from "../../../../Services/UKObject";
import RDVCell from "./RDVCell";


function CalendarMyDayComponent() {
  const { application, config } =
    useContext(ApplicationContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { user } = useContext(UserContext);
  const [dates, setDates] = useState([]);
  const [appointments, setAppointments] = useState([]);

  const reloadAppointments = async () => {
    var appointmentsObj = {};
    dates.forEach((elem) => {
      var date = moment(elem);
      appointmentsObj[date.format("dddd DD MMMM")] = [];
    });
    var keys = Object.keys(appointmentsObj);
    const appointments = await GetAppointmentWithUserID(
      user._id,
      application.apiKey,
      user.authToken
    );
    appointments.forEach((elem) => {
      if (elem.informations.step !== 2) {
        var dateDebut = moment(elem.informations.dateDebut);
        var key = keys.find((elem) => {
          return dateDebut.isSame(dateDebut, "day");
        });
        if (key) {
          appointmentsObj[key].push(elem);
        }
      }
    });
    keys.forEach((elem) => {
      var arr = appointmentsObj[elem].sort((a, b) => {
        var dA = moment(a.informations.dateDebut);
        var dB = moment(b.informations.dateDebut);
        return dA.isBefore(dB) ? -1 : 1;
      });
      appointmentsObj[elem] = arr;
    });
    setAppointments(appointmentsObj);
  };

  useEffect(() => {
    moment.locale("fr");
    var appointment = config.appointment;
    var allDates = [];
    appointment.dates.forEach((elem) => {
      var date = new Date(elem.start);
      var already = allDates.filter((elem) => {
        return (
          elem.getDate() === date.getDate() &&
          elem.getMonth() === date.getMonth() &&
          elem.getFullYear() === date.getFullYear()
        );
      });
      if (already.length === 0) {
        allDates.push(date);
      }
    });

    setDates(allDates.map((elem) => moment(elem)));
  }, [config]);

  useEffect(() => {
    reloadAppointments();
    // eslint-disable-next-line
  }, [dates]);

  const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <div
        className={`grid grid-cols-${dates.length} font-semi-bold text-sm py-2`}
      >
        {dates.map((elem, index) => {
          return (
            <p
              key={"date" + index}
              onClick={() => setSelectedIndex(index)}
              className={"cursor-pointer font-semibold py-2"}
              style={{
                backgroundColor:
                  selectedIndex === index ? config.mainColor : "transparent",
                color:
                  selectedIndex === index
                    ? config.colorOverMainColor
                    : "#3E3E3E",
              }}
            >
              {jsUcfirst(elem.format("dddd DD MMMM"))}
            </p>
          );
        })}
      </div>

      {appointments && dates && dates[selectedIndex] && (
        <div>
          {(
            appointments[dates[selectedIndex].format("dddd DD MMMM")] || []
          ).map((elem) => {
            return <RDVCell appointment={elem} refresh={reloadAppointments} />;
          })}
          {(appointments[dates[selectedIndex].format("dddd DD MMMM")] || [])
            .length === 0 && <p className="italic mt-4">Aucun rendez-vous</p>}
        </div>
      )}
    </div>
  );
}

export default CalendarMyDayComponent;
