import React, { useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom';
import ObjectsView from "./objects.view";
import { UserContext } from "../../../Context/UserContext";

function ObjectsviewRouter() {
  const {isAdmin} = useContext(UserContext);
  let {params} = useRouteMatch();

  useEffect(() => {
    if (!params)
      return null
  }, [params.typeobject])

  return (
    <div className="bg-white">
      <ObjectsView
        typeobject={params.typeobject}
        linksOnly={!isAdmin()}
      />
    </div>
  );
}

export default ObjectsviewRouter;
