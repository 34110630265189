import { Input } from 'react-rainbow-components';
import YesNoInput from '../Component/YesNoInput';
import DefaultComponent from './DefaultComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import UploadFileModal from '../../../../../UploadFileDocument/UploadFileModal';
import { useState } from 'react';

export default function WebViewComponent({ clickedData, setClickedData, type, lang }) {
  const [modalUpload, setModalUpload] = useState(false);
  return (
    <DefaultComponent clickedData={clickedData} setClickedData={setClickedData} type={type} picture lang={lang}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">
          Quelle est l'URL ou vous souhaitez rediriger l'utilisateur ?
        </p>
        <div className="flex items-center gap-2">
          <input
            className="custominputbox w-full"
            type="text"
            value={clickedData.url}
            onChange={(e) => {
              setClickedData({ ...clickedData, url: e.target.value });
            }}
          ></input>
          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              setModalUpload(true);
            }}
          >
            <FontAwesomeIcon icon={faCloudUploadAlt} />
          </div>

          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              window.open(clickedData.url, '_blank');
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </div>
        </div>
      </div>

      <YesNoInput
        title={"Voulez vous rediriger l'utilisation dans l'application ou sur son navigateur ?"}
        yesSelected={clickedData.inApp === 'true'}
        onClick={(e) => setClickedData({ ...clickedData, inApp: e == 1 ? 'true' : 'false' })}
        yesTitle="Dans l'application"
        noTitle="Sur son navigateur"
      />

      <UploadFileModal
        modalIsOpen={modalUpload}
        setModalIsOpen={setModalUpload}
        onSelectFile={(url) => {
          setModalUpload(false);
          setClickedData({ ...clickedData, url: url });
        }}
      />
    </DefaultComponent>
  );
}
