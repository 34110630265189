import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationContext } from '../../Context/ApplicationContext';
import { GetTickets } from '../../Services/Billetterie';
import AddButton from '../Common/Button/AddButton';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}


function ChoiceTicket({ onSelectedTicket }) {
  const { application, config, langKey, TRAD } = useContext(ApplicationContext);
  const [tickets, setTickets] = useState([]);
  const [step, setStep] = useState(1);
  const [total, setTotal] = useState([]);
  const query = useQuery()
  const reloadTickets = async () => {
    const tickets = await GetTickets(application.apiKey, '');
    setTotal(
      tickets.map((e) => {
        return { id: e._id, total: 0 };
      })
    );
    setTickets(tickets);
    if (query.get("ticket")) {
      onSelectedTicket([tickets.find(e => e._id == query.get("ticket"))])
    }
  };

  useEffect(() => {
    reloadTickets();
  }, []);

  var totalNumber = (total || []).reduce((a, e) => e.total + a, 0);

  return (
    <div className="w-full text-left flex flex-col text-gray-700">
      {tickets.map((e, index) => {
        return (
          <div className="border-b border-gray-300 py-2 ">
            <div className="flex justify-between">
              <div>
                <p className="text-2xl fond-bold">{e.name}</p>
                <p className="font-light mt-1">{e.informations['description' + langKey]}</p>
              </div>
              <div>
                <input
                  type="number"
                  min={0}
                  max={config?.billetterie?.ticketLimit || 10000}
                  placeholder=""
                  className="w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10 w-max mt-2"
                  value={total.find((a) => a.id === e._id).total || 0}
                  onChange={(v) => {
                    const arr = [...total];
                    arr[index].total = parseInt(v.target.value);
                    setTotal(arr);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex justify-between mt-4">
        <p className="font-bold text-2xl">Total :</p>
        <p className="text-xl font-medium">
          {totalNumber} {TRAD('TICKET', 'billet')}
          {totalNumber > 1 ? 's' : ''}
        </p>
      </div>
      <div className="pt-2 flex justify-end">
        <AddButton
          className={totalNumber == 0 ? 'opacity-40' : ''}
          title={TRAD('CONTINUE', 'Continuer')}
          icon={faArrowRight}
          onClick={() => {
            if (totalNumber == 0) {
              return;
            }
            var result = total.map((e) => {
              var arr = [];
              for (var i = 0; i < e.total; i++) {
                arr.push(tickets.find((v) => v._id == e.id));
              }
              return arr;
            });
            onSelectedTicket(result.flat());
          }}
        />
      </div>
    </div>
  );
}

export default ChoiceTicket;
