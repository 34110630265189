export class ApplicationConfig {
  constructor(json) {
    this.json = json
  }

  getCustomObjectByID(id) {
    return this.json.customObject.find(e => e.id === id) || []
  }

  getCustomUserByID(id) {
    return this.json.inscription.customs.find(e => e.id === id) || []
  }
}