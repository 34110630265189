import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FileSelector } from 'react-rainbow-components';
import * as XLSX from 'xlsx';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import ImportExcelStepOne from './ImportExcelStepOne';
import ImportExcelSelectType from './ImportExcelSelectType';
import ImportExcelSetData from './ImportExcelSetData';
import ImportExcelPreview from './ImportExcelPreview';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    minWidth: '50%',
    maxWidth: '90%',
    maxHeight: '90%',
  },
};

export default function ImportExcel({ modalIsOpen }) {
  const [data, setData] = useState([]);
  const [selectType, setSelectType] = useState(null);
  const [linkData, setLinkData] = useState(null);
  var step = 1;

  if (data && data.length > 0) {
    step = 2;
    if (selectType) {
      step = 3;
      if (linkData) {
        step = 4;
      }
    }
  }
  return (
    <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Import Modal">
      <p>Retour</p>
      <p className="text-xl font-medium">Import de fichier</p>
      <p className="italic font-light">
        Attention cette version est en beta. Il est possible que vous ayez des erreurs
      </p>
      {step == 1 && <ImportExcelStepOne setData={setData} />}
      {step == 2 && <ImportExcelSelectType allData={data} setSelectType={setSelectType} />}
      {step == 3 && <ImportExcelSetData allData={data} selectType={selectType} setLinkData={setLinkData} />}
      {step == 4 && <ImportExcelPreview allData={data} selectType={selectType} linkData={linkData} />}
    </Modal>
  );
}
