import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-rainbow-components';
import { UKObjectClass } from '../../Class/UKObjectClass';
import { ApplicationContext } from '../../Context/ApplicationContext';
import TrashButton from './Button/TrashButton';

function CustomObjectCell({ object, className, onClick = () => {}, waiting, onDelete }) {
  const { config } = useContext(ApplicationContext);
  const [objectClass, setObjectClass] = useState(null);

  useEffect(() => {
    setObjectClass(new UKObjectClass(object, config));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object]);

  return (
    <>
      {objectClass && (
        <div onClick={onClick} className={'relative ' + className}>
          <div
            className={
              'text-gray-700 font-medium  flex flex-col lg:flex-row items-left lg:items-center rounded border py-2 px-2 ' +
              (onDelete != null ? 'pr-6' : '')
            }
          >
            {(objectClass.getPicture() || objectClass.json?.informations?.image) && (
              <img
                alt="object logo"
                className="object-contain"
                style={{ maxHeight: 70 }}
                src={objectClass.getPicture() || objectClass.json?.informations?.image}
              />
            )}
            <div className="ml-0 lg:ml-2">
              <p>
                {objectClass.getTitle() || objectClass.json?.informations?.title}{' '}
                {waiting ? (
                  <Badge
                    className="rainbow-m-around_medium"
                    label="en attente"
                    variant="warning"
                    title="en attente"
                    size="small"
                  />
                ) : (
                  ''
                )}
              </p>
              <p className="font-light text-xs">
                {objectClass.getSubtitle() || objectClass.json?.informations?.subtitle}
              </p>
            </div>
            {onDelete && (
              <TrashButton
                className="absolute right-0 top-1 "
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CustomObjectCell;
