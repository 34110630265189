import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { HelpText } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import { useGlobalFilter, usePagination, useResizeColumns, useTable } from 'react-table';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import { UpdateJsonConfig } from '../../../../Services/Application';
import CloseButton from '../../../Common/Button/CloseButton';
import LinkTable from '../../Account/Admin/Links/LinksTable';
import json from '../../Objects/Settings/ObjectTable.json';
import ObjectTableComponent from '../../Objects/Settings/ObjectTableComponent';
import ObjectSettingsView from './ObjectSettingsView';

export default function ObjectModalComponent({ object, modalIsOpen, setmodalIsOpen, typeobject }) {
  const params = useParams();
  const { config, application, reloadConfig } = useContext(ApplicationContext);
  const [customObjects, setCustomObjects] = useState([]);
  const [columns, setColumns] = useState([]);

  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
    },
  };

  const getObjectToMap = (config) => {
    const obj = config.customObject.find((e) => {
      return e.id === (typeobject || params.typeobject);
    });
    return obj;
  };

  useEffect(() => {
    if (!config || !object) return;
   
    let obj = getObjectToMap(config).custom.map((e) => {
      return { ...e };
    });
    if (obj.length) {
      setColumns(json);
    }
    setCustomObjects(obj);
    // eslint-disable-next-line
  }, [config, object, modalIsOpen]);

  const updateData = (rowIndex, columnId, value) => {
    let newCustom = [...customObjects];
    if (columnId === 'type') {
      if (value === 'selector') {
        newCustom[rowIndex].values = [''];
      } else {
        delete newCustom[rowIndex].values;
      }
    }
    newCustom[rowIndex][columnId] = value;
    setCustomObjects(newCustom);
  };

  const saveData = async (rowIndex, custom = null) => {
    const newConfig = { ...config };
    getObjectToMap(newConfig).custom = custom || customObjects;
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
  };

  const defaultColumn = {
    Cell: ObjectTableComponent,
    minWidth: 200,
  };

  const { getTableProps, getTableBodyProps, page, headerGroups, prepareRow, state } = useTable(
    { columns, data: customObjects, defaultColumn, updateData, saveData },
    useResizeColumns,
    useGlobalFilter,
    usePagination
  );

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} className="">
      <div className="h-full w-full">
        <CloseButton
          className="float-right"
          onClick={() => {
            setmodalIsOpen(false);
          }}
        />
        <div>
          <div className="flex">
            <p className="font-bold text-xl text-gray-800 mb-2">Informations complementaires</p>{' '}
            <HelpText
              style={{ height: 10, marginLeft: 5 }}
              width={10}
              variant="question"
              title="Informations complementaires"
              text={<p style={{}}>Ici vous pouvez gerer les informations demandé sur votre objet</p>}
            />
          </div>
          <ObjectSettingsView />
        </div>
        <p>Liens</p>
        <p className="font-light text-left">Ici vous pouvez créer/modifier/supprimer des liens entre vos objets</p>

        <LinkTable type={typeobject} />
      </div>
    </Modal>
  );
}
