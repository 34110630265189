import YesNoInput from '../Component/YesNoInput';
import DefaultComponent from './DefaultComponent';
import StyleViewComponent from './StyleViewComponent';

export default function EventComponent({ clickedData, setClickedData, type, lang }) {
  return (
    <DefaultComponent clickedData={clickedData} setClickedData={setClickedData} type={type} picture lang={lang}>
      <YesNoInput
        title={'Voulez-vous dissocier vos évènements par date ?'}
        yesSelected={clickedData.splitByDate == 'true'}
        onClick={(index) => {
          setClickedData({ ...clickedData, splitByDate: index == 1 ? 'true' : 'false' });
        }}
      />

      <StyleViewComponent clickedData={clickedData} setClickedData={setClickedData} />
    </DefaultComponent>
  );
}
