import { GetUKObjectById } from "../Services/UKObject";
import { GetUserById } from "../Services/User";
import { UKObjectClass } from "./UKObjectClass";
import { UKUser } from "./UKUser";

export class UKLink {
	config = [];
	type = null;
	json = {};

	constructor(json) {
		this.type = (json || {}).type || "";
		this.json = {
			informations: {
				r: "_",
			},
			...json,
		};
	}

	getInformations(id) {
		var value = this.json.informations[id];
		return value || null;
	}

	async loadObject(id, type, apiKey, authToken) {
		return await GetUKObjectById(id, apiKey, authToken);
	}

	async loadUser(id, apiKey, authToken, config) {
		if (!id || id.length === 0) {
			return null;
		}
		var userfound = await GetUserById(id, apiKey, authToken);
		return new UKUser(userfound, config);
	}

	async loadType(type, id, apiKey, authToken, config) {
		if (type === "user") {
			var user = await this.loadUser(id, apiKey, authToken, config);
			return user;
		}
		var obj = await this.loadObject(id, type, apiKey, authToken, config);
		return new UKObjectClass(obj, config);
	}

	async loadLink(apiKey, authToken, config) {
		var obj1 = await this.loadType(
			this.json.informations.type1,
			this.json.informations.id1,
			apiKey,
			authToken,
			config
		);
		var obj2 = await this.loadType(
			this.json.informations.type2,
			this.json.informations.id2,
			apiKey,
			authToken,
			config
		);
		return { obj1: obj1, obj2: obj2 };
	}

	async getJson(apiKey, authToken, config) {
		var obj = await this.loadLink(apiKey, authToken, config);
		var result = {};
		if (obj.obj1 != null) {
			var json1 = obj.obj1.getJson(apiKey, authToken, config);
			var keys1 = Object.keys(json1);
			keys1.forEach((elem) => {
				result[this.json.informations.type1 + "_" + elem] = json1[elem];
			});
		}
		if (obj.obj2 != null) {
			var json2 = obj.obj2.getJson(apiKey, authToken, config);
			var keys2 = Object.keys(json2);
			keys2.forEach((elem) => {
				result[this.json.informations.type2 + "_" + elem] = json2[elem];
			});
		}
		return result;
	}
}
