import { useContext } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";

const CommonButton = ({
  disabled = false,
                        title = "",
                        sizeX = "",
                        sizeY = "",
                        iconLeft = false,
                        customClassName = "",
                        customColor = "#0099cc",
                        useAppTheme = false,
                        icon = "",
                        fontSize = 0,
                        onClick = () => {
                        }
                      }) => {
  const {config} = useContext(ApplicationContext);
  const color = (config || {}).mainColor ?? "#000000";

  if (useAppTheme)
    customColor = color;

  return (
    <div
      className={`flex items-center gap-1 cursor-pointer border rounded transition-all px-1 hover:opacity-70 ${customClassName}`}
      style={{
        width: sizeX,
        height: sizeY,
        color: customColor + "CC",
        backgroundColor: customColor + 22,
        borderColor: customColor + "CC",
        fontSize: fontSize !== 0 ? fontSize : 16
      }}
      onClick={onClick}>{iconLeft ? <>{icon}{title}</> : <>{title}{icon}</>}
    </div>
  );
}

export default CommonButton;