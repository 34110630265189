import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { HelpText } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import { ApplicationContext } from '../../../../Context/ApplicationContext';
import CloseButton from '../../../Common/Button/CloseButton';
import ObjectSettingsView from './ObjectSettingsView';
import YesNoInputV2 from "../../Account/Admin/Design/Component/YesNoInputV2";
import { UpdateJsonConfig } from "../../../../Services/Application";

export default function ObjectModalComponent({object, modalIsOpen, setmodalIsOpen, typeobject}) {
  const params = useParams();
  const {config, application, reloadConfig} = useContext(ApplicationContext);
  const [customs, setCustoms] = useState([]);

  useEffect(() => {
    if (!params || !config) return;
    const type = params.typeobject;
    const objConfig = config.customObject.find((e) => e.id === type);
    if (!objConfig) {
      setCustoms([]);
      return;
    }
    setCustoms(objConfig.custom);
  }, [params, config]);


  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const onUpdate = async (key, value) => {
    const index = config.customObject.findIndex((e) => e.id === params.typeobject)

    let newConfig = {...config};
    newConfig.customObject[index][key] = value;
    await UpdateJsonConfig(application.apiKey, newConfig);
    reloadConfig();
  }

  return (
    <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
      <div className="h-full w-full">
        <CloseButton
          className="float-right"
          onClick={() => {
            setmodalIsOpen(false);
          }}
        />
        <div>
          <div className="flex">
            <p className="font-bold text-xl text-gray-800 mb-2">Informations complementaires</p>{' '}
            <HelpText
              style={{height: 10, marginLeft: 5}}
              width={10}
              variant="question"
              title="Informations complementaires"
              text={<p style={{}}>Ici vous pouvez gerer les informations demandé sur votre objet</p>}
            />
          </div>
          <div className="mb-5">
            <div className="flex gap-10">
              <YesNoInputV2
                title={"Les utilisateurs peuvent ils créer un objet de ce type ?"}
                yesSelected={config.customObject.find((e) => e.id === params.typeobject).canCreateObject || false}
                onClick={(index) => {
                  onUpdate("canCreateObject", index === 1);
                }}
              />
              {config.customObject.find((e) => e.id === params.typeobject).canCreateObject && <YesNoInputV2
                title={"Les utilisateurs peuvent ils créer plusieurs objets de ce type ?"}
                yesSelected={config.customObject.find((e) => e.id === params.typeobject).canCreateMultiple || false}
                onClick={(index) => {
                  onUpdate("canCreateMultiple", index === 1);
                }}
              />}

            </div>
            {config.customObject.find((e) => e.id === params.typeobject).canCreateObject && <p className="text-gray-800 italic">Vous devez créer un lien entre ce type d'objet et le type d'utilisateur autorisé à créer un objet !</p>}
          </div>
          <ObjectSettingsView initConfig={async () => {
            try {
              const type = params.typeobject;
              const objConfig = config.customObject.find((e) => e.id === type);
              setCustoms(objConfig.custom);
            } catch (e) {
            }
          }} customs={customs} setCustoms={setCustoms} />
        </div>
      </div>
    </Modal>
  );
}
