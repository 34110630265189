import {useContext, useEffect, useState} from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { GetObjectsAll } from "../../../Services/UKObject";

function DisplayNoteEvent({ idEvent, isSubEvent = false }) {
  const {application} = useContext(ApplicationContext);
  const [results, setResults] = useState(null);

  function average(array) {
    let summ = 0;
    const length = array.length;

    for (const e of array) {
      summ += e;
    }

    if (summ === 0) {
      return;
    }

    return summ / length;
  }

  async function getAllUserOfEvent() {
    let result = await GetObjectsAll(`ukevent_note_${idEvent}`, application.apiKey);
    if (result) {
      result = result.map(e => e.informations.note).filter(e => !!e);
      if (result.length === 0) {
        return;
      }
      result = average(result);
      result = Math.round(result * 10) / 10;
      setResults(result);
    }
  }

  useEffect(() => {
    getAllUserOfEvent();
  }, [])

  if (!results) {
    return <p className="text-xs text-gray-800">Aucune note pour le moment</p>;
  }

  return (
    <div className={isSubEvent ? 'w-full' : "py-5 bg-white border border-gray-300 rounded p-2 max-w-[80%] m-auto mb-5"}>
      <div className={'w-full items-center justify-center'}>
        <p>La note de l'événement est de :</p>
        <p className={'font-bold'}>{results} / 5</p>
      </div>
    </div>
  )
}

export default DisplayNoteEvent;