import React, { useContext } from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../Context/ApplicationContext';



export default function LinkModal({ modalIsOpen, setmodalIsOpen, link, title = "" }) {
	const { config } = useContext(ApplicationContext);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: 40,
			minWidth: '50%',
			maxHeight: '90%'
		},
	};


	return (
		<Modal
			ariaHideApp={false}
			isOpen={modalIsOpen}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<p className="font-bold text-xl ">{title}</p>
			<p className="bg-gray-200 p-2 rounded-lg my-3 italic px-4">{link}</p>
			<div className="flex  justify-end items-end">
				<button
					className="bg-gray-800 mr-2 mb-2 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
					style={{
						color: (config || {}).colorOverMainColor ?? "#FFFFFF",
						backgroundColor: (config || {}).mainColor ?? "#000000",
					}}
					onClick={() => {
						navigator.clipboard.writeText(link)
						toast.warn("Lien copié")
					}}
				>
					Copier
				</button>
				<button
					className="bg-gray-800 mr-2 mb-2 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
					style={{
						color: (config || {}).colorOverMainColor ?? "#FFFFFF",
						backgroundColor: (config || {}).mainColor ?? "#000000",
					}}
					onClick={() => setmodalIsOpen(false)}
				>
					Fermer
				</button>
			</div>
		</Modal>
	)
}
