import React, { useContext, useEffect, useState } from 'react';
import { CheckboxGroup, Chip, VerticalNavigation, VerticalSectionOverflow, Input } from 'react-rainbow-components';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { FiSearch } from 'react-icons/fi';

export default function FilterComponent({ filters, setFilters, page, setObjects, iduser }) {
  const { config } = useContext(ApplicationContext);
  const [values, setvalues] = useState([]);
  const [options, setoptions] = useState([]);
  const [optionName, setoptionName] = useState([]);
  const [search, setsearch] = useState('');
  const [timeout, settimeout] = useState(null);

  useEffect(() => {
    if (!config) return;
    const users = config.inscription.customs.find((e) => e.id === iduser);
    let option = [];
    let optionname = [];
    users.custom.forEach((elem) => {
      if (elem.filter) {
        optionname = [...optionname, elem.tint];
        option = [
          ...option,
          elem.values.map((element) => {
            return { value: element, label: element, disabled: false, id: elem.id, name: elem.tint };
          }),
        ];
      }
    });
    setoptions(option);
    setoptionName(optionname);
    // eslint-disable-next-line
  }, [config, values]);

  const handleOnChange = (newValues, opt) => {
    setvalues(
      newValues.map((e) => {
        return { name: e, category: opt[0].name };
      })
    );
    setFilters(
      newValues.map((e) => {
        return { name: opt[0].id, type: 'reg', value: e };
      })
    );
  };

  const deleteChip = (e) => {
    setvalues(
      values.filter((elem) => {
        return elem !== e;
      })
    );
    setFilters(
      filters.filter((elem) => {
        return elem.value !== e.name;
      })
    );
  };

  const getResults = async (value) => {
    setFilters([
      { name: 'firstName', type: 'reg', value: value },
      { name: 'lastName', type: 'reg', value: value },
    ]);
  };

  return (
    <div className="float-left grid grid-cols-1 bg-white px-2 w-full rounded border border-gray-300 pt-2">
      <p className="font-bold text-left">Filtressss</p>

      <input
        className="custominputbox w-full"
        type="text"
        style={{ width: '100%' }}
        placeholder="Rechercher"
        value={search}
        onChange={(e) => {
          setsearch(e.target.value);
          if (timeout) clearTimeout(timeout);
          settimeout(
            setTimeout(() => {
              getResults(e.target.value);
            }, 500)
          );
        }}
      />

      <div className="rainbow-p-vertical_large rainbow-flex_wrap flex flex-row flex-wrap gap-2">
        {values.map((e) => {
          return (
            <>
              <Chip label={`${e.category}: ${e.name}`} onDelete={() => deleteChip(e)} />
            </>
          );
        })}
      </div>
      <VerticalNavigation className="max-h-full p-2 text-left">
        {options.map((e, index) => {
          return (
            <VerticalSectionOverflow label={optionName[index]}>
              <CheckboxGroup
                style={{ maxHeight: 200, overflowY: 'scroll', fontSize: 5 }}
                value={values.map((elem) => elem.name)}
                onChange={(values) => handleOnChange(values, e)}
                options={e}
              />
            </VerticalSectionOverflow>
          );
        })}
      </VerticalNavigation>
    </div>
  );
}
