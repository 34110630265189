import { useContext } from "react";
import { Link } from "react-router-dom";
import { UKObjectClass } from "../../../Class/UKObjectClass";
import { ApplicationContext } from "../../../Context/ApplicationContext";

const ObjectsTable = ({ typeobject, info, loading, link, onCellClick, selectedList, selectedKey }) => {
    const { applicationConfig, config, langKey } = useContext(ApplicationContext);

    if (applicationConfig === null) return <div></div>;
    const configObject = applicationConfig.getCustomObjectByID(typeobject);

    const isSelected = (row) => {
        try {
            return (
                selectedList.findIndex((e) => {
                    return e === row[selectedKey];
                }) >= 0
            );
        } catch (e) {
            return false;
        }
    };

    return (
        <div
            className="bg-gray-200 w-full text-left border border-gray-300 border-separate rounded-md"
            style={{
                overflowX: "auto",
                display: "block",
            }}
        >
            {configObject && configObject.custom && info && (
                <table style={{ minWidth: "100%" }}>
                    <thead className="bg-white border-none text-base rounded-md">
                        <tr>
                            {configObject.custom.map((row, index) => {
                                if (row.type === "mapLink") return;
                                return (
                                    <th
                                        key={index}
                                        className="text-xs text-gray-500 font-semibold"
                                        style={{
                                            padding: "3px 10px",
                                        }}
                                    >
                                        {row.tint}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="text-xs text-left bg-white">
                        {info.objects &&
                            info.objects.length > 0 &&
                            info.objects.map((elem, index) => {
                                const object = new UKObjectClass(elem, config);

                               

                                return (
                                    <tr key={index} className="h-10 hover:opacity-60">
                                        {configObject.custom.map((row, index) => {
                                            let cellColor = index % 2 === 0 ? "#F6F6F6" : "";
                                            if (isSelected(object.json)) cellColor = "#BBF7D0";
                                            let objInfos = object.getInformations(row.id, langKey) || "-";
                                            if (objInfos !== "-" && row.type === "link") objInfos = objInfos.length > 0 ? objInfos.length + " liens" : "-";
                                            if (row.type === "mapLink") return;
                                            if (row.type == "multipleSelector") {
                                                objInfos = objInfos.split("$$//$$").join(", ");
                                            }
                                            if (row.type == "picture" && objInfos) {
                                                var urlRegex =
                                                    "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
                                                var url = new RegExp(urlRegex, "i");

                                                try {
                                                    if (objInfos?.match(url)) {
                                                        objInfos = <img src={objInfos} style={{ maxWidth: "50px", maxHeight: "50px" }} />;
                                                    }
                                                } catch (e) {}
                                            }

                                            return (
                                                <td
                                                    key={index}
                                                    onClick={(event) => onCellClick(elem, event)}
                                                    className="text-xs cursor-pointer"
                                                    style={{
                                                        color: "#555555",
                                                        padding: "3px 10px",
                                                        background: cellColor,
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "50ch",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    {objInfos}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            )}
            {!loading && (!info.objects || info.objects.length === 0) && <p className="text-gray-800 font-bold text-center">Aucun élément trouvé</p>}
            {loading && <p className="text-gray-800 font-bold text-center">Chargement...</p>}
        </div>
    );
};
export default ObjectsTable;
