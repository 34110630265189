import * as React from "react";
import {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faX} from "@fortawesome/pro-solid-svg-icons";
import SearchBarInput from "../Common/SearchBar/SearchBarInput";
import {ApplicationContext} from "../../Context/ApplicationContext";
import SearchBarView from "./search.bar.view";

function ControlPanel({mapConfig, currentFloor, filter, setCurrentFloor, path, nextStep, setSelectedFeature, cancelPathfinding, previousStep, setFilter, filteredBuilding, zoomToTarget}) {
  const [open, setIsOpen] = useState(false);
  const {config} = useContext(ApplicationContext);

  if (!mapConfig) {
    return <></>;
  }
  return (
    <div className={`flex flex-col gap-2 control-panel absolute top-0 w-full md:w-max`} style={{zIndex: 29}}>
      <div className={`p-2 w-full md:hidden`} style={{
        backgroundColor: config.navbarColor
      }}>
        <SearchBarInput onSearch={(e) => {
          setFilter(e);
        }} />
      </div>
      <div className={`p-2 hidden md:w-max md:block `}>
        <SearchBarInput value={filter} onSearch={(e) => {
          setFilter(e);
        }}/>
      </div>
      {filteredBuilding && <div className="relative w-full">
        <SearchBarView buildings={filteredBuilding} zoomToTarget={zoomToTarget} setSelectedFeature={(e) => {
          setFilter(null)
          setSelectedFeature(e)
        }}/>
      </div>}
      <div className="flex flex-row gap-2 pl-2 pr-4">

        {mapConfig.config.maps && mapConfig.config.maps.map((map, indexs) => {
          if (!currentFloor.building) return;

          if (currentFloor.building && map.id !== currentFloor.building) {
            return;
          }
          return (
            <div key={indexs} className="flex flex-row w-full ">
              <div className="bg-white text-black px-2 h-fit flex rounded w-full h-[32px] flex items-center justify-center">{map.name}</div>
              <div className={"flex flex-col transition-all w-full"}>
                {map.floors.map((floor, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (!open) {
                          setIsOpen(true);
                        } else {
                          console.log("SET CURRENT FLOOR 7")
                          setCurrentFloor({building: map.id, floor: floor.id});
                          setIsOpen(false);
                        }
                      }}
                      className={`${currentFloor.floor == floor.id ? 'bg-white text-black' : 'bg-white text-black'} ${open && index !== 0 && 'mt-2'} ${!open && (floor.id != currentFloor.floor) ? 'max-h-[0px] overflow-hidden' : 'max-h-[32px] h-[32px]'} w-full mx-2 px-2 hover:opacity-80 cursor-pointer transition-all rounded`}
                    >
                      {floor.name}
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {path.length &&
        <div className={`p-2 flex w-full h-max gap-1 opacity-100`}>
          <button className='flex items-center justify-center bg-white text-black max-h-[32px] h-[32px] w-full px-2 hover:opacity-80 cursor-pointer transition-all rounded' onClick={() => {
            cancelPathfinding();
          }}>Annuler <FontAwesomeIcon icon={faX}/></button>
          {path.length > 1 &&
            <>
              <button className='flex items-center justify-center bg-white text-black max-h-[32px] h-[32px] w-full px-2 hover:opacity-80 cursor-pointer transition-all rounded' onClick={() => {
                previousStep();
              }}><FontAwesomeIcon icon={faArrowLeft}/> Précédent
              </button>
              <button className='flex items-center justify-center bg-white text-black max-h-[32px] h-[32px] w-full px-2 hover:opacity-80 cursor-pointer transition-all rounded' onClick={() => {
                nextStep();
              }}>Suivant <FontAwesomeIcon icon={faArrowRight}/></button>
            </>
          }
        </div>
      }
    </div>
  );
}

export default React.memo(ControlPanel);
