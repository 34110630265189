import React, { useContext, useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ApplicationContext } from "../../Context/ApplicationContext";

const Pagination = ({fetch, nbPages, page, total}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const {config} = useContext(ApplicationContext);
  const color = "#0099cc";

  var arr = Array.apply(null, Array(nbPages || 0))
    .map(function (x, i) {
      return i + 1;
    })
    .filter(function (elem) {
      var min = page - 5;
      var max = page + 5;
      if (min < 0) {
        min = 0;
        max = 11;
      }
      if (elem > min && elem < max) {
        return true;
      }
      return false;
    });

  const styles = StyleSheet.create({
    pagination: {
      ':hover': {
        color: color,
      },
    },
    selected: {
      backgroundColor: color + 55,
      border: '1px solid',
      borderColor: color + 'DD',
      color: color + 'FF',
      fontWeight: 'medium',
    },
  });

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const changePage = (index) => {
    setCurrentPage(index);
    fetch(index);
  };

  const changeWithArrow = (value) => {
    if (parseInt(currentPage + value) < 0 || parseInt(currentPage + value) > nbPages - 1) return 0;
    changePage(parseInt(currentPage + value));
  };

  return (
    arr && (
      <div className={'w-full my-2 outline-none relative text-center'}>
        <div className={'inline-block'}>
          <span
            onClick={() => changeWithArrow(-1)}
            className={'inline-block text-gray-500 hover:text-custom-mainColor transition-2 px-4 py-2 cursor-pointer'}
          >
            &laquo;
          </span>
          {arr.map((index) => {
            return (
              <span
                key={Math.random() + '-' + index}
                onClick={() => changePage(index - 1)}
                className={`rounded inline-block text-gray-500 transition-all mr-1 text-center cursor-pointer w-8 ${
                  index === parseInt(currentPage + 1) && css(styles.selected)
                } ${css(styles.pagination)}`}
              >
                {index}
              </span>
            );
          })}
          <span
            onClick={() => changeWithArrow(1)}
            className={'inline-block  hover:text-custom-mainColor text-gray-500  transition-2 px-4 py-2 cursor-pointer'}
          >
            &raquo;
          </span>
          <p className={'text-gray-500 text-sm'}>
            Total : {total} - {nbPages} page{nbPages > 1 ? 's' : ''}
          </p>
        </div>
      </div>
    )
  );
};

export default Pagination;