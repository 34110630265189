export default function StyleImageDetailSquare({ config }) {
  return (
    <div className="border-b border-gray-400 bg-gray-100 p-2 flex gap-2 justify-center">
      <div className="flex  flex-col gap-4 p-2 w-max bg-white">
        <img
          style={{
            aspectRatio: '1/1',
            width: 100,
          }}
          className="object-cover"
          src="https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
          alt="detaultigm"
        />
        <div className="text-center  w-full">
          <p className="font-medium">Titre 1</p>
          <p className="text-sm">Sous-titre 1</p>
        </div>
      </div>

      <div className="flex  flex-col gap-4 p-2 w-max bg-white">
        <img
          style={{
            aspectRatio: '1/1',
            width: 100,
          }}
          className="object-cover"
          src="https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"
          alt="detaultigm"
        />
        <div className="text-center  w-full">
          <p className="font-medium">Titre 2</p>
          <p className="text-sm">Sous-titre 2</p>
        </div>
      </div>
    </div>
  );
}
