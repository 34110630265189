import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import React, {useContext, useEffect, useRef, useState} from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import customStyles from '../MapStyle/layers.style';
import extendedDrawbar from './SubModules/extended.drawbar'
import FeaturePropertiesView from "./SubModules/feature.properties.view";
import DrawSquareMode from "../CustomTool/draw.square.mode";
import DrawPatternMode from "../CustomTool/draw.pattern.mode";
import CommonButton from "../../../Common/Button/CommonButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faEarth, faFileImport, faGear, faList} from "@fortawesome/pro-light-svg-icons";
import CategoryConfigView from "./SubModules/category.config.view";
import {toast} from "react-toastify";
import MapConfigView from "./SubModules/map.config.view";
import {defaultCategory, defaultConfig} from "./map.const";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import {SRMode, SRStyle} from '../CustomTool/scaleRotate.mode';
import {Prompt} from "react-router-dom";
import MapModeToolTipView from "./SubModules/mode.tooltip.view";
import "../MapStyle/DrawBarIcons/drawbar.icons.css"
import customSimpleSelectMode from '../CustomTool/custom.simpleSelect.mode';
import {GetEventById, GetUKObjectById, UpdateObject} from "../../../../Services/UKObject";
import {GetUserById, UpdateUser} from "../../../../Services/User";
import {ApplicationContext} from "../../../../Context/ApplicationContext";
import {UserContext} from "../../../../Context/UserContext";
import * as SVGToGeoJSON from "./SubModules/SVGToGeoJSON/svgToGeoJSON"
import CustomInputFile from "../../../Common/Inputs/customFileInput.component";
import copyPasteMode from "../CustomTool/copyPaste.mode";
import {MapSelection} from "./SubModules/map.selection";

const MapBoxGLView = ({MapConfigService}) => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const mapDraw = useRef(null);
  const {application} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [propertiesBoxStates, setPropertiesBoxStates] = useState({feature: null});
  const [modeTooltipStates, setModeTooltipStates] = useState({mode: '', isOpen: false});
  const [categoryStates, setCategoryStates] = useState({categories: null, selected: null, isOpen: false});
  const [mapConfigStates, setMapConfigStates] = useState({isOpen: false, config: defaultConfig});
  const [mapChangesCount, setMapChangesCount] = useState(0);
  const [_, setCurrentModeType] = useState('');
  const [linksToDelete, setLinksToDelete] = useState([]);
  const mapSelected = useRef({map: null, floor: null});
  const [refresh, Refresh] = useState(0);

  useEffect(() => {
    if (mapChangesCount > 0) {
      // Custom message feature deprecated ?!
      window.onbeforeunload = function () {
        return "Vous avez des changements non sauvegardés, êtes vous sûr de vouloir quitter l'éditeur ?";
      };
    } else {
      window.onbeforeunload = undefined
    }
  }, [mapChangesCount])

  useEffect(() => {
    if (map.current) return;
    initializeMap();
    setPropertiesBoxStates({feature: null});
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keyup', handleKeyUp);
      window.onbeforeunload = undefined
    }
  }, []);

  const reloadMapSelected = () => {
    if (!mapDraw.current) return;
    let features = mapDraw.current.allMap;
    if (mapSelected.current && mapSelected.current.map && mapSelected.current.floor) {
      features = features.filter(e => e.properties.building === mapSelected.current.map && e.properties.floor === mapSelected.current.floor);
    }
    setPropertiesBoxStates({feature: null});
    mapDraw.current.set({type: 'FeatureCollection', features: features});
    Refresh(e => e + 1);
  }

  useEffect(() => {
    if (!map.current || !map.current._fullyLoaded) return;
    refreshMap();
  }, [mapConfigStates.config]);

  useEffect(() => {
    if (!map.current) return;
    const inter = setInterval(() => {
      map.current.resize()
    }, 1)
    setTimeout(() => {
      clearInterval(inter)
    }, 501)

  }, [mapConfigStates.isOpen, categoryStates.isOpen]);


  const fetchGeoJSON = async () => {
    return await MapConfigService.get('geoJSON')
  }

  function handleContextMenu(e) {
    e.preventDefault();
  }

  function handleKeyUp(e) {
    if (e.key === "Alt") e.preventDefault();
    if (document.activeElement.tagName === 'INPUT') return;
    if (e.key === 'd') mapDraw.current.changeMode("copyPasteMode");
    if (e.key === 'v') {
      mapDraw.current.changeMode("simple_select");
      setCurrentModeType('select');
      onModeChange();
    }
    if (e.key === 'm') {
      mapDraw.current.changeMode("draw_line_string");
      setCurrentModeType('route');
      onModeChange();
    }
    if (e.key === 'e') {
      mapDraw.current.changeMode("draw_polygon");
      setCurrentModeType('polygon');
      onModeChange();
    }
    if (e.key === 'r') {
      mapDraw.current.changeMode("draw_square_mode");
      setCurrentModeType('square');
      onModeChange();
    }
  }

  const initializeMap = async () => {
    ////////////////////////////////////////////////
    // Initialize Map & Draw
    ////////////////////////////////////////////////


    // Get MapConfig
    let mapConfig = await MapConfigService.get();
    if (!mapConfig) {
      MapConfigService.update({mapConfig: defaultConfig});
      mapConfig = await MapConfigService.get();
    }

    // INIT CONFIG
    let config = mapConfig.config;
    if (!config) {
      config = defaultConfig;
    }
    setMapConfigStates({...mapConfigStates, config: config});

    // Fetch CATEGORIES
    fetchFeaturesCategories();

    map.current = new mapboxgl.Map({
      accessToken: 'pk.eyJ1IjoicmVtaXRlbGVuY3phayIsImEiOiJjbGNhbHoyN3o2NHRiM3BrZXA5ZGR2d3VpIn0.x4UkMpsKPxM-kSDrnIn37Q',
      container: mapContainer.current,
      style: {
        version: 8,
        name: "blank",
        sources: {
          openmaptiles: {
            type: "vector",
            url: ""
          }
        },
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        layers: [{
          id: "background",
          type: "background",
          paint: {
            "background-color": config.backgroundColor || defaultConfig.backgroundColor
          }
        }]
      },
      center: config.viewport.center || defaultConfig.viewport.center,
      zoom: config.viewport.zoom || defaultConfig.viewport.zoom,
      minZoom: 18
    });

    let draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        simple_select: customSimpleSelectMode,
        draw_square_mode: DrawSquareMode,
        draw_scaleRotateMode: SRMode,
        copyPasteMode: copyPasteMode
      },
      styles: [...customStyles, ...SRStyle],
    });
    mapDraw.current = draw;

    map.current.on('load', async () => {
      const newGeoJSON = await fetchGeoJSON();
      if (!newGeoJSON) return;
      DrawPatternMode.onSetup(mapConfig?.pattern, map.current);
      if (newGeoJSON) {
        let features = newGeoJSON.features;
        draw.allMap = features;
        if (mapSelected.current && mapSelected.current.map && mapSelected.current.floor) {
          features = features.filter(e => e.properties.building === mapSelected.current.map && e.properties.floor === mapSelected.current.floor);
        }
        draw.set({type: 'FeatureCollection', features: features});

        if (features) {
          features.map((feature) => {
            if (!feature.properties.image) return;
            map.current.loadImage(feature.properties.url, (error, image) => {
              if (error) throw error;
              map.current.addImage(feature.properties.image, image);
            });
          })
        }
      }
    });

    map.current.on(Constants.events.CREATE, async (e) => {
      onChange();
      onNewObject(e.features[0]);
    })

    map.current.on(Constants.events.DELETE, async (e) => {
      const properties = e.features[0].properties
      if (properties.link) {
        setLinksToDelete((prevState) => [...prevState, {link: properties.link, linkType: properties.linkType, url: properties.url, id: e.features[0].id}]);
      }
      setPropertiesBoxStates({feature: null});
      draw.changeMode('simple_select', {silent: true});
      draw.allMap.splice(draw.allMap.findIndex(f => f.id === e.features[0].id), 1);
      onChange();
    })

    map.current.on(Constants.events.UPDATE, async (e) => {
      const id = e.features[0].id;

      let index = draw.allMap.findIndex(f => f.id === id);
      draw.allMap[index] = e.features[0];

      onChange();
    })

    map.current.on(Constants.events.SELECTION_CHANGE, (e) => {
      const currentMode = draw.getMode();

      if (currentMode !== "simple_select" && currentMode !== "draw_scaleRotateMode") return;
      if (e.features.length === 0) {
        setPropertiesBoxStates({feature: null});
        draw.changeMode('simple_select', {silent: true});
        return;
      }
      // if (e.features[0].geometry.type !== "LineString") {
        setPropertiesBoxStates({feature: e.features[0]});
      // }
    })

    ////////////////////////////////////////////////
    // Add DrawBar
    ////////////////////////////////////////////////
    const simpleSelect = {
      on: "click", action: () => {
        draw.changeMode("simple_select")
        setCurrentModeType('select');
        onModeChange();
      }, classes: ["drawbar-hand-icon"], title: 'Selection (V)'
    };
    const drawLineString = {
      on: "click", action: () => {
        draw.changeMode("draw_line_string")
        setCurrentModeType('route');
        onModeChange();
      }, classes: ["drawbar-road-icon"], title: 'Routes (M)'
    };
    const drawPolygon = {
      on: "click", action: () => {
        draw.changeMode("draw_polygon")
        setCurrentModeType('polygon');
        onModeChange();
      }, classes: ["drawbar-polygone-icon"], title: 'Polygone (P)'
    };
    const drawSquareBtn = {
      on: "click", action: () => {
        draw.changeMode("draw_square_mode")
        setCurrentModeType('square');
        onModeChange();
      }, classes: ["drawbar-square-icon"], title: 'Rectangle / Carré (R)'
    };
    const drawPointBtn = {
      on: "click", action: () => {
        draw.changeMode("draw_point")
        setCurrentModeType('poi');
        onModeChange();
      }, classes: ["drawbar-point-icon"], title: "Point d'Intérêt"
    };
    const drawTextBtn = {
      on: "click", action: () => {
        draw.changeMode("draw_point")
        setCurrentModeType('text');
        onModeChange();
      }, classes: ["drawbar-text-icon"], title: "Texte"
    };
    const drawImageBtn = {
      on: "click", action: () => {
        draw.changeMode("draw_point")
        setCurrentModeType('image');
        onModeChange();
      }, classes: ["drawbar-image-icon"], title: "Image"
    };
    const drawLinkBtn = {
      on: "click", action: () => {
        draw.changeMode("draw_point")
        setCurrentModeType('link');
        onModeChange();
      }, classes: ["drawbar-link-icon"], title: "Lien"
    };
    const trashBtn = {
      on: "click", action: () => {
        draw.trash()
      }, classes: ["drawbar-trash-icon"], title: "Supprimer l'objet sélectionné (BACKSPACE)"
    };
    const copyBtn = {
      on: "click", action: () => {
        draw.changeMode("copyPasteMode");
        setCurrentModeType('copy');
        onModeChange();
      }, classes: ["drawbar-clone-icon"], title: "Copier l'objet sélectionné (D)"
    };

    let drawBar = new extendedDrawbar({
      draw: draw,
      buttons: [
        simpleSelect,
        drawLineString,
        drawPolygon,
        drawSquareBtn,
        drawPointBtn,
        drawTextBtn,
        drawImageBtn,
        drawLinkBtn,
        trashBtn,
        copyBtn
      ]
    });
    map.current.addControl(drawBar);

    ////////////////////////////////////////////////
    // Map configuration & events
    ////////////////////////////////////////////////
    // Enable del key
    draw.options.controls.trash = true;

    // Disable Rotation
    map.current.dragRotate.disable();
    map.current.touchZoomRotate.disableRotation();

    // Prevent context menu from appearing on right click
    window.addEventListener('contextmenu', handleContextMenu, false);

    // Prevent firefox menu from appearing on Alt key up
    window.addEventListener('keyup', handleKeyUp, false);
  }

  const fetchFeaturesCategories = async () => {
    let categories = await MapConfigService.get('categories');
    if (!categories) {
      categories = [defaultCategory];
    }
    setCategoryStates({...categoryStates, categories: categories})
  }

  const onChange = () => {
    setMapChangesCount((prevState) => prevState + 1);
  }

  const onModeChange = () => {
    setPropertiesBoxStates((prevState) => {
      return {...prevState, feature: null};
    })
    setCurrentModeType((prevState) => {
      setModeTooltipStates({...modeTooltipStates, mode: prevState});
      return prevState;
    });
  }

  const setFeatureProperty = (id, property, value, feature) => {
    mapDraw.current.setFeatureProperty(id, property, value);
    feature.properties[property] = value;
  }

  const onNewObject = (feature) => {
    const id = feature.id;
    const newFeature = {...feature};
    let skip = false;

    if (feature.geometry.type === "LineString" && mapSelected.current && mapSelected.current.map && mapSelected.current.floor) {
      setFeatureProperty(id, 'building', mapSelected.current.map, newFeature);
      setFeatureProperty(id, 'floor', mapSelected.current.floor, newFeature);
      mapDraw.current.allMap = mapDraw.current.allMap ? [...mapDraw.current.allMap, newFeature] : [newFeature];
      return;
    }

    setCurrentModeType((prevState) => {
      if (['poi', 'text', 'image', 'pattern', 'link'].findIndex(type => type === prevState) !== -1) {
        skip = true;
      }
      if (!id || !prevState || prevState === 'select') return;
      setFeatureProperty(id, 'type', prevState, newFeature);
      // mapDraw.current.setFeatureProperty(id, 'type', prevState);
      if (prevState === 'text')
        setFeatureProperty(id, 'text', 'Texte', newFeature);
      // mapDraw.current.setFeatureProperty(id, 'text', 'Texte');

      if (mapSelected.current && mapSelected.current.map && mapSelected.current.floor) {
        setFeatureProperty(id, 'building', mapSelected.current.map, newFeature);
        setFeatureProperty(id, 'floor', mapSelected.current.floor, newFeature);
        // mapDraw.current.setFeatureProperty(id, 'building', mapSelected.current.map);
        // mapDraw.current.setFeatureProperty(id, 'floor', mapSelected.current.floor);
      }
    })
    if (skip) {
      mapDraw.current.allMap = mapDraw.current.allMap ? [...mapDraw.current.allMap, newFeature] : [newFeature];
      return;
    }
    setCategoryStates((prevState) => {
      if (!prevState.selected || !id) return prevState;
      if (prevState.selected.categoryColor)
        setFeatureProperty(id, 'categoryColor', prevState.selected.categoryColor, newFeature);
      // mapDraw.current.setFeatureProperty(id, 'categoryColor', prevState.selected.categoryColor)

      setFeatureProperty(id, 'category', prevState.selected.id, newFeature);
      // mapDraw.current.setFeatureProperty(id, 'category', prevState.selected.id);

      return prevState;
    })
    mapDraw.current.allMap = mapDraw.current.allMap ? [...mapDraw.current.allMap, newFeature] : [newFeature];
  }

  const refreshMap = () => {
    const config = mapConfigStates.config;
    map.current.setPaintProperty('background', 'background-color', config.backgroundColor);
  }

  const clearObj = (obj, id) => {
    try {
      let index = obj.informations.mapLink.link.findIndex(link => {
        return link.id === id
      })
      if (index !== -1)
        obj.informations.mapLink?.link?.splice(index, 1);
      return obj;
    } catch (e) {
      return obj;
    }
  }

  const updateGeoJSON = async () => {
    const geoJSON = {type: 'FeatureCollection', features: mapDraw.current.allMap};
    const response = MapConfigService.update({geoJSON: geoJSON});

    if (linksToDelete && linksToDelete.length > 0) {
      linksToDelete.map(async (link) => {
        let obj;
        if (link.linkType === 'object') {
          obj = await GetUKObjectById(link.link, application.apiKey, (user || {}).authToken);
          if (obj) {
            obj = clearObj(obj, link.id)
            await UpdateObject(link.link, link.url, obj.informations, application.apiKey, (user || {}).authToken);
          }
        } else if (link.linkType === 'user') {
          obj = await GetUserById(link.link, application.apiKey, (user || {}).authToken);
          if (obj) {
            obj = clearObj(obj, link.id)
            await UpdateUser(link.link, obj.informations, application.apiKey, (user || {}).authToken);
          }
        } else {
          obj = await GetEventById(link.link, application.apiKey);
          if (obj) {
            obj = clearObj(obj, link.id)
            await UpdateObject(link.link, 'ukEvent', obj.informations, application.apiKey, (user || {}).authToken);
          }
        }
      })
    }

    if (response) {
      toast.success('Carte mise à jour', {autoClose: 2000, position: 'bottom-center'});
      setMapChangesCount(0)
    } else {
      toast.error('Erreur lors de la mise à jour', {autoClose: 2000, position: 'bottom-center'});
    }
  }

  const saveFeatureProperties = async (properties) => {


    mapDraw.current.allMap.find(e => e.id === propertiesBoxStates.feature.id).properties = properties;

    Object.entries(properties).map((property) => {
      let selectedCategory;
      if (property[0] === 'category') {
        selectedCategory = categoryStates.categories.find(c => c.id === property[1])
        if (selectedCategory && selectedCategory?.categoryColor) {
          mapDraw.current.allMap.find(e => e.id === propertiesBoxStates.feature.id).properties.categoryColor = selectedCategory.categoryColor;
          mapDraw.current.setFeatureProperty(propertiesBoxStates.feature.id, 'categoryColor', selectedCategory.categoryColor);
        }
        if (selectedCategory && selectedCategory?.categoryHeight) {
          mapDraw.current.allMap.find(e => e.id === propertiesBoxStates.feature.id).properties.categoryHeight = selectedCategory.categoryHeight;
          mapDraw.current.setFeatureProperty(propertiesBoxStates.feature.id, 'categoryHeight', selectedCategory.categoryHeight);
        }
      }
      mapDraw.current.setFeatureProperty(propertiesBoxStates.feature.id, property[0], property[1]);
    })
    await updateGeoJSON();
  }

  return (
    <div>
      <Prompt
        when={mapChangesCount > 0}
        message="Vous avez des changements non sauvegardés, êtes vous sûr de vouloir quitter l'éditeur ?"
      />
      <div className="flex items-center justify-between bg-neutral-100 border-b-2">
        <div className="flex gap-3 ml-3 m-2">
          <CommonButton title='Configuration de la carte' useAppTheme={false} customColor={mapConfigStates.isOpen ? '#F87171' : '#3B82F6'} icon={<FontAwesomeIcon icon={mapConfigStates.isOpen ? faChevronLeft : faGear}/>} onClick={() => {
            setMapConfigStates({...mapConfigStates, isOpen: !mapConfigStates.isOpen});
          }}/>
          <CommonButton title='Catégories' useAppTheme={false} customColor={categoryStates.isOpen ? '#F87171' : '#3B82F6'} icon={<FontAwesomeIcon icon={categoryStates.isOpen ? faChevronLeft : faList}/>} onClick={() => {
            setCategoryStates({...categoryStates, isOpen: !categoryStates.isOpen});
          }}/>
          <CustomInputFile title="Importer un SVG" icon={<FontAwesomeIcon icon={faFileImport}/>} accept=".svg" onChange={(e) => {
            SVGToGeoJSON.onUpload(e.target.files, map.current, mapDraw.current);
          }}/>
        </div>
        <div className="flex gap-3 mr-3 m-2">
          {mapChangesCount > 0 && <p className="text-sm text-gray-700 italic m-auto">{`${mapChangesCount} changement${mapChangesCount > 1 ? 's' : ''} à sauvegarder !`}</p>}
          <CommonButton title='Sauvegarder' useAppTheme={false} customColor={'#22C55E'} icon={<FontAwesomeIcon icon={faEarth}/>} onClick={updateGeoJSON}/>
        </div>
      </div>
      <div className="relative">
        <div className="flex bg-gray-100 relative">
          <MapConfigView MapConfigService={MapConfigService} isOpen={mapConfigStates.isOpen} map={map.current} setMapConfigStates={setMapConfigStates}/>
          <CategoryConfigView MapConfigService={MapConfigService} isOpen={categoryStates.isOpen} mapDraw={mapDraw} map={map} onSave={(newCategories) => {
            setCategoryStates({...categoryStates, categories: newCategories});
          }}/>
          <div ref={mapContainer} className="map-container height-map w-full">
            {mapConfigStates.isOpen && <div className="absolute z-10 bg-blue-500 top-0 left-0 right-0 bottom-0 h-full aspect-[9/19] m-auto bg-opacity-20 pointer-events-none">
              <p className="text-center m-auto text-blue-800">Visible sur le téléphone</p>
            </div>}
            <div className="absolute flex gap-2 z-10 left-0 top-2 m-auto">
              <select
                className="h-7 ml-2 border rounded"
                value={categoryStates.selected ? categoryStates.selected.id : ''}
                onChange={(e) => {
                  const newSelectedCategory = categoryStates.categories.find(c => c.id === e.target.value);
                  setCategoryStates((prev) => ({...prev, selected: newSelectedCategory}));
                }}>
                <option value=''>-- Aucune catégorie --</option>
                {categoryStates.categories && categoryStates.categories.map((category, index) => {
                    return (
                      <option key={index} value={category.id}>{category.name}</option>
                    );
                  }
                )}
              </select>
              <MapSelection mapSelected={mapSelected} mapConfig={mapConfigStates.config} onChange={reloadMapSelected}/>
            </div>
            <FeaturePropertiesView feature={propertiesBoxStates.feature} categories={categoryStates.categories} onSave={(properties) => {
              saveFeatureProperties(properties)
              if (!properties.image || map.current.hasImage(properties.image)) return;
              map.current.loadImage(properties.url, (error, image) => {
                if (error) throw error;
                map.current.addImage(properties.image, image);
              });
            }} getZoom={() => map.current.getZoom()} mapConfig={mapConfigStates.config} mapDraw={mapDraw.current} />
            <MapModeToolTipView state={modeTooltipStates} setModeTooltipStates={setModeTooltipStates}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapBoxGLView
