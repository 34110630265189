import {Chart} from "react-google-charts";

export const MyCamembert = ({question}) => {
  const ans = question.answer;
  if (!ans || ans.length === 0) {
    return <></>;
  }

  const res = [
    ["Question", "Answers"],
  ];

  const options = {}

  const addResult = (answer) => {
    const index = res.findIndex(e => e[0] === answer);
    if (index !== -1) {
      res[index][1] += 1;
    } else {
      res.push([answer, 1]);
    }
  }

  for (const el of ans) {
    const tmp = el.informations.answer || "";
    if (tmp.constructor === Array) {
      for (const answer of tmp) {
        addResult(answer);
      }
    } else {
      addResult(tmp);
    }
  }

  return (
    <div className={'flex justify-center w-full h-full'}>
      <Chart chartType={"PieChart"} data={res} width={'100%'} height={"800px"} options={options}></Chart>
    </div>
  )
}
