import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';

export default function ModuleConfigurationDocument({ module, setModule, className = '' }) {
  const { config } = useContext(ApplicationContext);
  const [docList, setDocList] = useState();

  useEffect(() => {
    if (!config) return;
    let docs = config.docs.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setDocList(docs);
  }, [config, module]);

  const typeValid = ['DOC'];
  if (typeValid.indexOf(module.type) === -1) {
    return <></>;
  }
  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Quelle liste de document souhaitez-vous afficher ?</p>
      {docList && (
        <div style={{ maxWidth: 300 }}>
          <Select
            value={docList.find((e) => e.value === module.id)}
            className="w-full"
            options={docList}
            onChange={(e) => {
              setModule({ ...module, id: e.value });
            }}
          />
        </div>
      )}
    </div>
  );
}
