import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../../../Context/ApplicationContext";
import MultipleButton from "../../../../../Iframe/Event/multiple.button";
import YesNoInput from "../../Design/Component/YesNoInput";
import TabBarConfigurationIcon from "../TabBarConfiguration/tabbarconfig.icon";
import ModuleBanner from "./ModuleBanner";
import ModuleConfigurationCustomPage from "./ModuleConfigurationCustomPage";
import ModuleConfigurationDate from "./ModuleConfigurationDate";
import ModuleConfigurationDocDetail from "./ModuleConfigurationDocDetail";
import ModuleConfigurationDocument from "./ModuleConfigurationDocument";
import ModuleConfigurationEvent from "./ModuleConfigurationEvent";
import ModuleConfigurationFilterGroup from "./ModuleConfigurationFIlterGroup";
import ModuleConfigurationFormattedText from "./ModuleConfigurationFormattedText";
import ModuleConfigurationGroup from "./ModuleConfigurationGroup";
import ModuleConfigurationGroupBy from "./ModuleConfigurationGroupBy";
import ModuleConfigurationOneEvent from "./ModuleConfigurationOneEvent";
import ModuleConfigurationOneObject from "./ModuleConfigurationOneObject";
import ModuleConfigurationOneUser from "./ModuleConfigurationOneUser";
import ModuleConfigurationPicture from "./ModuleConfigurationPicture";
import ModuleConfigurationSelectObject from "./ModuleConfigurationSelectObject";
import ModuleConfigurationTextInput from "./ModuleConfigurationTextInput";
import ModuleConfigurationUrl from "./ModuleConfigurationUrl";
import ModuleConfigurationCurrentEvent from "./PreviewStyle/ModuleConfigurationCurrentEvent";

export default function ModuleConfiguration({ moduleSelected, setTmpModule, updateModule, onDeleteModule }) {
    const { config, application } = useContext(ApplicationContext);
    const [module, setModule] = useState(null);

    useEffect(() => {
        setModule(moduleSelected);
    }, [moduleSelected]);

    if (!module) {
        return <></>;
    }

    /* Quand on modifie le module on envoie au parent le module modifié pour la sauvegarde automatique */
    /* On utilise un prevent pour savoir quand il y a eu un changement, le USEEffect ferais une boucle inf */
    const preventSetModule = (module) => {
        setTmpModule(module);
        setModule(module);
    };

    return (
        <div className="bg-white w-full px-2 ">
            <div className="py-2 text-center mb-4 bg-gray-50 px-2">
                <p className="font-light text-sm" style={{ marginBottom: -5 }}>
                    module
                </p>
                <p className="font-bold text-2xl" style={{ color: config.mainColor }}>
                    {module.type}
                </p>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
                <ModuleConfigurationPicture className={module.style !== "TABBAR" ? "col-span-2" : ""} module={module} setModule={preventSetModule} />
                <TabBarConfigurationIcon module={module} setModule={preventSetModule} />
                <ModuleConfigurationTextInput module={module} setModule={preventSetModule} name={"text"} title={"Quel est le titre de votre module ?"} />

                {/* ICI CUSTOM MODULE */}
                <ModuleConfigurationCustomPage className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationSelectObject className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationGroupBy className="col-span-2" module={module} setModule={preventSetModule} />

                <ModuleConfigurationFormattedText className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationUrl className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationEvent className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationCurrentEvent className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationDate className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationDocument className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationDocDetail className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationOneObject className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationOneEvent className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationOneUser className="col-span-2" module={module} setModule={preventSetModule} />
                {/* --- ICI CUSTOM MODULE ---- */}

                <ModuleConfigurationGroup className="col-span-2" module={module} setModule={preventSetModule} />
                <ModuleConfigurationFilterGroup className="col-span-2" module={module} setModule={preventSetModule} />

                <MultipleButton
                    title="Quel type d’utilisateur peut voir ce module ?"
                    buttons={["Connecté", "Non connecté", "Les deux"]}
                    onClick={(e) => {
                        preventSetModule({ ...module, state: e === "Connecté" ? "1,2,3" : e === "Non connecté" ? "0" : "0,1,2,3" });
                    }}
                    value={module.state === "1,2,3" ? "Connecté" : module.state === "0" ? "Non connecté" : "Les deux"}
                />

                {(module.state == "0" || module.state == "0,1,2,3" || module.state != "1,2,3") && (
                    <YesNoInput
                        title={"Forcer la connexion ?"}
                        yesSelected={module.loginForce == "true"}
                        onClick={(index) => {
                            setModule({ ...module, loginForce: index == 1 ? "true" : "false" });
                        }}
                    />
                )}

                <ModuleBanner className="col-span-2" module={module} setModule={preventSetModule} />
            </div>

            <div className="flex justify-center gap-x-3 px-3 mb-5 " style={{ marginTop: 40 }}>
                {!moduleSelected.lock && (
                    <div
                        className="border border-gray-500 text-gray-500 transition-all hover:border-red-400 hover:text-red-400 hover:bg-red-200 rounded px-8 py-2 cursor-pointer"
                        onClick={() => {
                            if (window.confirm("Voulez-vous supprimer ce module ? ")) {
                                onDeleteModule(moduleSelected);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                )}

                <div
                    className="border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
                    onClick={() => {
                        updateModule(module);
                    }}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            </div>
        </div>
    );
}
