import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-thin-svg-icons";
import React from "react";

export default function SearchFilterIframe({needSearch, needFilter, searchField, setSearchField, filter, newFilter}) {
  if (!(needSearch || needFilter)) {
    return null;
  }
  return (
    <div className={'bg-white rounded w-1/4 p-5 h-max mt-8'}>
      {needSearch &&
        <div name={'input'} className={'bg-stone-200 w-full rounded flex items-center'}>
          <input type="text" value={searchField} placeholder={'Rechercher'}
                 className={'w-full py-1 h-full bg-stone-200 rounded-l-md pl-2'}
                 onChange={(props) => setSearchField(props.target.value)}/>
          <FontAwesomeIcon icon={faSearch} className={'mx-2'}/>
        </div>
      }
      {needFilter &&
        <div className={'w-max items-start justify-start'}>
          <h1 className={'font-bold w-max pt-2'}>Filtres</h1>
          {filter.map((e, indexs) => (
            <div key={indexs}><p className={'font-light w-max pt-2'}>{e.tint}</p>
              {e.values.map((elem, index) =>
                <div key={index} className="flex items-center mr-4">
                  <input type="checkbox" className="accent-green-500"
                         onChange={(props) => newFilter(props.target.checked, e.id, elem)}/>
                  <label className="ml-2 font-light">{elem}</label>
                </div>
              )}
            </div>
          ))}
        </div>
      }
    </div>
  )
}