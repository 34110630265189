import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Input } from 'react-rainbow-components';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../../Context/UserContext';
import { CreateObject, GetBanner, UpdateObject } from '../../../../../../Services/UKObject';
import AddButton from '../../../../../Common/Button/AddButton';
import CloseButton from '../../../../../Common/Button/CloseButton';
import UploadFileModal from '../../../../../UploadFileDocument/UploadFileModal';
import YesNoInput from '../../Design/Component/YesNoInput';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 40,
    minWidth: '50%',
    maxHeight: '90%',
  },
};

export default function ModuleBanner({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const reloadBanniere = async () => {
    if (!user || !application || !module) return;
    setLoading(true);
    const res = await GetBanner(module._id, application.apiKey, user.authToken);
    setBanner(res);
    if (res == null) {
      setBanner({
        informations: {
          urls: [],
          carousel: false,
          id: module._id,
        },
      });
    }
    setLoading(false);
  };

  const save = async (data) => {
    // console.log('SAVE');
    // console.log(data);
    if (banner._id) {
      await UpdateObject(banner._id, 'ukBanner', data.informations, application.apiKey, user.authToken);
      reloadBanniere();
    } else {
      await CreateObject('ukBanner', data.informations, application.apiKey, user.authToken);
      reloadBanniere();
    }
  };
  useEffect(() => {
    reloadBanniere();
  }, [application, module, user]);

  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Banniere publicitaire </p>
      {loading && <p className="text-left">Chargement...</p>}
      {!loading && banner && banner.informations.urls.length == 0 && (
        <div className="flex items-center gap-2">
          <p className="text-left text-sm italic">Aucune banniere</p>
          <AddButton
            onClick={() => {
              setOpen(true);
            }}
          ></AddButton>
        </div>
      )}
      {!loading && banner && banner.informations.urls.length > 0 && (
        <div className="flex items-center gap-2">
          <p className="text-left text-sm italic">{banner.informations.urls.length} Image(s)</p>
          <AddButton
            title={'Modifier'}
            onClick={() => {
              setOpen(true);
            }}
          ></AddButton>
        </div>
      )}

      <Modal isOpen={open} style={customStyles} contentLabel="Example Modal">
        <p className="font-bold text-xl ">Banniere publicitaire</p>

        <div className="my-3 flex flex-col gap-2">
          {banner &&
            banner.informations.urls.map((e, index) => {
              return (
                <div key={index} className="relative flex gap-3 px-2 justify-between py-2 gap-2 items-center justify-center border border-gray-200 rounded ">
                  <img style={{ width: 200, height: 'auto', objectFit: 'contain' }} className={' '} src={e.image} />

                  <div className="flex flex-col">
                    <p className="font-medium text-sm text-left mb-2">Quelle est l'URL ou vous souhaitez rediriger l'utilisateur ?</p>

                    <input
                      className="custominputbox w-full"
                      type="text"
                      value={e.url}
                      onChange={(e) => {
                        e.preventDefault();
                        const newUrls = [...banner.informations.urls];
                        newUrls[index].url = e.target.value;
                        setBanner({ ...banner, informations: { ...banner.informations, urls: newUrls } });
                      }}
                    ></input>
                  </div>
                  <div className="flex  flex-col gap-2">
                    <div
                      className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-10 text-center"
                      onClick={() => {
                        window.open(e, '_blank');
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                    <div
                      className="text-gray-500 cursor-pointer hover:bg-red-100 hover:text-red-500 hover:border-red-500 transition-all px-1 py-0 border border-gray-500 rounded w-10 text-center"
                      onClick={() => {
                        var obj = {
                          ...banner,
                          informations: {
                            ...banner.informations,
                            urls: [...banner.informations.urls].filter((a) => a.image != e.image),
                          },
                        };
                        setBanner(obj);
                        //save(obj);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <AddButton
          title={'Ajouter une image'}
          onClick={() => {
            setModalUpload(true);
          }}
        ></AddButton>

        <YesNoInput
          title={"Sous quel format voulez vous l'afficher?"}
          yesSelected={((banner || {}).informations || {}).carousel}
          yesTitle={'Carousel'}
          noTitle={'Aléatoire'}
          onClick={(index) => {
            // console.log(index);

            var obj = {
              ...banner,
              informations: {
                ...banner.informations,
                carousel: index == 1,
              },
            };
            setBanner(obj);
            //save(obj);
          }}
        />

        <UploadFileModal
          modalIsOpen={modalUpload}
          setModalIsOpen={setModalUpload}
          onSelectFile={(url) => {
            setModalUpload(false);
            // console.log(url);

            var img = new Image();
            img.onload = function () {
              var obj = {
                ...banner,
                informations: {
                  ...banner.informations,
                  urls: [...banner.informations.urls, { image: url, url: '', ratio: (this.width / this.height).toString() }],
                },
              };
              setBanner(obj);
              // console.log(obj);
            };
            img.src = url;

            //save(obj);
          }}
        />
        <CloseButton
          className={'absolute right-2 top-2 '}
          onClick={async () => {
            await save(banner);
            setOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
