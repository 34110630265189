import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CloseButton = ({ className, onClick = () => {} }) => {
  return (
    <div
      className={
        'px-2 text-gray-500 border-gray-300 border rounded bg-gray-100  hover:bg-red-100 hover:text-red-500 hover:border-red-500 cursor-pointer transition-all ' +
        className
      }
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTimes} />
    </div>
  );
};

export default CloseButton;
