import * as turf from '@turf/turf';
import {dijkstra} from './dijkstra.algorithm';
import {getBuildingPossibilities} from './all.building.path';
import {toast} from "react-toastify";

class stepPass {
  constructor(id, coord, pointList) {
    this.id = id === -1 ? 'start' : id === -2 ? 'finish' : `step-${id}`;
    this.coordinates = coord;
    this.pointDirectionList = [...pointList];
  }

  get coord() {
    return this.coordinates;
  }

  get pointDirection() {
    return this.pointDirectionList;
  }

  appendDirection(direction) {
    this.pointDirectionList.push(...direction);
    this.pointDirectionList = [...new Set(this.pointDirectionList)];
  }
}

export function getAllLine(GEOJSON, building, floor) {
  const fToCheck = floor === 'ext' ? undefined : floor;
  const bToCheck = fToCheck === undefined ? undefined : building;

  const Lign = GEOJSON.features.filter(elem => {
    if (elem.geometry.type === 'LineString' && elem.properties.floor === 'ext' && fToCheck === undefined) {
      return true;
    }
    return (
      elem.geometry.type === 'LineString' &&
      elem.properties.building === bToCheck &&
      elem.properties.floor === fToCheck
    );
  });

  const test = [];
  for (const a of Lign) {
    if (a.geometry.coordinates.length > 2) {
      for (let i = 0; i < a.geometry.coordinates.length - 1; i++) {
        const b = turf.lineString([
          a.geometry.coordinates[i],
          a.geometry.coordinates[i + 1],
        ]);
        test.push(b);
      }
    } else {
      test.push(a);
    }
  }

  return test;
}

function createNewStep(id, pointCoord, intersectionList, listPoint) {
  let tmp = null;

  const finders = listPoint.find(
    elem => elem.coord[0] === pointCoord[0] && elem.coord[1] === pointCoord[1],
  );
  if (finders) {
    finders.appendDirection(intersectionList);
    finders.id = id === -1 ? 'start' : id === -2 ? 'finish' : finders.id;
    return 0;
  } else {
    tmp = new stepPass(id, pointCoord, intersectionList);
    listPoint.push(tmp);
    return 1;
  }
}

function addTwoPartOfLine(id, lineString, intersectPoint, listPoint) {
  let tmp = id;
  const line = lineString.geometry.coordinates;

  tmp += createNewStep(tmp, line[0], [...intersectPoint, line[1]], listPoint);
  tmp += createNewStep(tmp, line[1], [...intersectPoint, line[0]], listPoint);
  return tmp;
}

function getAllPoint(allLign) {
  let listPoint = [];
  let intersectPoint,
    newIntersectionList = null;
  let id = 1;

  for (let i = 0; i < allLign.length; i++) {
    intersectPoint = getAllIntersectionPoint(allLign[i], allLign);
    id += addTwoPartOfLine(id, allLign[i], intersectPoint, listPoint);
    for (let j = 0; j < intersectPoint.length; j++) {
      newIntersectionList = intersectPoint.filter((elem, index) => index !== j);
      newIntersectionList.push(allLign[i].geometry.coordinates[0]);
      newIntersectionList.push(allLign[i].geometry.coordinates[1]);
      id += createNewStep(
        id,
        intersectPoint[j],
        newIntersectionList,
        listPoint,
      );
    }
  }

  return listPoint;
}

function reformatListPointList(listPoint) {
  return listPoint.map(elem => {
    return {
      id: elem.id,
      coord: elem.coord,
      pointDirection: elem.pointDirection.map(elems => {
        const result = listPoint.find(
          e => e.coord[0] === elems[0] && e.coord[1] === elems[1],
        );
        const from = turf.point(elem.coord);
        const to = turf.point(result.coord);
        return {
          id: result.id,
          distance: turf.distance(from, to),
        };
      }),
    };
  });
}

function arrayToGraph(arr) {
  const result = {};
  for (const p of arr) {
    const obj = {};
    for (const a of p.pointDirection) {
      obj[a.id] = a.distance;
    }
    result[p.id] = obj;
  }
  return result;
}

function compareTwoLineString(firstLineString, secondLineString) {
  const firstLine = firstLineString.geometry.coordinates;
  const secondLine = secondLineString.geometry.coordinates;
  let firstPointSimilar = false;
  let secondPointSimilar = false;

  if (
    firstLine[0][0] === secondLine[0][0] &&
    firstLine[0][1] === secondLine[0][1]
  ) {
    firstPointSimilar = true;
  }
  if (
    firstLine[1][0] === secondLine[1][0] &&
    firstLine[1][1] === secondLine[1][1]
  ) {
    secondPointSimilar = true;
  }
  return firstPointSimilar && secondPointSimilar;
}

function addingStartPoint(startLine, allLign, points, listPoint) {
  let startPointSameLine = getAllIntersectionPoint(startLine, allLign);
  startPointSameLine = [
    ...startPointSameLine,
    startLine.geometry.coordinates[0],
    startLine.geometry.coordinates[1],
  ];
  createNewStep(
    -1,
    points[0].geometry.coordinates,
    startPointSameLine,
    listPoint,
  );
}

function addingLastPoint(finishLine, allLign, points, listPoint) {
  const finishPointSameLine = getAllIntersectionPoint(finishLine, allLign);
  const allPointToFinish = [
    ...finishPointSameLine,
    finishLine.geometry.coordinates[0],
    finishLine.geometry.coordinates[1],
  ];
  let tmp;

  createNewStep(-2, points[1].geometry.coordinates, [], listPoint);
  allPointToFinish.forEach(elem => {
    tmp = listPoint.find(
      pointlist =>
        pointlist.coord[0] === elem[0] && pointlist.coord[1] === elem[1],
    );
    tmp.appendDirection([points[1].geometry.coordinates]);
  });
}

function addingStartAndLastPoints(listPoint, points, allLign) {
  const startLine = allLign.find(elem => {
    return turf.booleanPointOnLine(points[0], elem, {
      epsilon: 0.00000000000000001,
    });
  });
  const finishLine = allLign.find(elem => {
    return turf.booleanPointOnLine(points[1], elem, {
      epsilon: 0.00000000000000001,
    });
  });

  if (compareTwoLineString(startLine, finishLine)) {
    const ps = turf.point(points[0].geometry.coordinates);
    const pe = turf.point(points[1].geometry.coordinates);
    const line = turf.lineString(startLine.geometry.coordinates);
    return turf.lineSlice(ps, pe, line);
  }

  addingStartPoint(startLine, allLign, points, listPoint);
  addingLastPoint(finishLine, allLign, points, listPoint);
  return null;
}

function getAllIntersectionPoint(line, lineList) {
  const pointList = [];
  let line1,
    line2,
    intersection = null;

  for (let i = 0; i < lineList.length; i++) {
    if (line.geometry.coordinates === lineList[i].geometry.coordinates) {
      continue;
    }
    line1 = turf.lineString(line.geometry.coordinates);
    line2 = turf.lineString(lineList[i].geometry.coordinates);
    intersection = turf.lineIntersect(line1, line2);
    if (intersection.features.length > 0) {
      pointList.push(intersection.features[0].geometry.coordinates);
    }
  }

  return pointList;
}

export function findClosestPoint(item, GEOJSON, isPolygon = true) {
  let center = null;
  if (isPolygon) {
    const polygon = turf.polygon(item.geometry.coordinates);
    center = turf.centerOfMass(polygon);
  } else {
    center = turf.point(item.geometry.coordinates);
  }
  const allLign = getAllLine(
    GEOJSON,
    item.properties.building,
    item.properties.floor,
  );
  const distanceArray = allLign.map(elem => {
    const line = turf.lineString(elem.geometry.coordinates);
    return turf.nearestPointOnLine(line, center, {
      units: 'meters',
    });
  });
  distanceArray.sort((a, b) =>
    a.properties.dist > b.properties.dist ? 1 : -1,
  );
  distanceArray[0].properties = item.properties;
  return distanceArray[0];
}

function pathFindingOnFloor(points, GEOJSON) {
  const {building, floor} = points[0].properties;
  const allLign = getAllLine(GEOJSON, building, floor);
  let listPoint = getAllPoint(allLign);
  const check = addingStartAndLastPoints(listPoint, points, allLign);
  if (check) {
    return check;
  }
  const arr = reformatListPointList(listPoint);
  const graph = arrayToGraph(arr);
  const path = dijkstra(graph, 'start', 'finish');
  const finalPath = path.map(elem => {
    return arr.find(tmp => tmp.id === elem).coord;
  });
  const ans = turf.lineString(finalPath);
  return ans;
}

function weightPathfinding(tab, ps, pe, GEOJSON) {
  let result = [];
  try {
    const arr = tab.map(e => findClosestPoint(e, GEOJSON, false));

    const points = [ps, ...arr, pe];

    for (let i = 0; i < points.length - 1; i++) {
      const actIFloor = points[i].properties.floor ?? 'ext';
      const secondIFloor = points[i + 1].properties.floor ?? 'ext';
      if (actIFloor !== secondIFloor) {
        continue;
      }
      try {
        const res = pathFindingOnFloor(
          [points[i], points[i + 1]],
          GEOJSON,
        );
        if (!res) {
          return -1;
        }
        res.properties = points[i].properties;
        result.push({path: res, weight: turf.length(res, {units: 'meters'})});

      } catch (error) {
        return -1;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return {
    path: result,
    weight: result.reduce((acc, elem) => acc + elem.weight, 0),
  };
}

export function pathFinding(points, GEOJSON, setDirection) {
  try {
    if (points[0].geometry.coordinates[0] === points[1].geometry.coordinates[0] &&
      points[0].geometry.coordinates[1] === points[1].geometry.coordinates[1]) {
       toast.error('Le même stand est sélectionné 2 fois !');
       return false
    }
    if (points[0].properties.building === points[1].properties.building && points[0].properties.floor === points[1].properties.floor) {
      let res = pathFindingOnFloor(points, GEOJSON);
      res.properties = points[0].properties;
      res = {path: [{path: res}], weight: turf.length(res, {units: 'meters'})};
      setDirection(res.path);
      return true;
    }
    const probablyPaths = getBuildingPossibilities(GEOJSON, points[0], points[1]);
    let res = [];
    for (const el of probablyPaths) {
      res.push(weightPathfinding(el, points[0], points[1], GEOJSON));
    }
    res = res.filter(e => {
      if (e == -1)
        return false;
      return (e.path.length > 0);
    });
    res.sort((a, b) => (a.weight > b.weight ? 1 : -1));
    setDirection(res[0].path);
    return true
  } catch (e) {
    console.log(e);
    toast.error("Aucun chemin trouvé")
    return false
  }
}
