import {GetObjectByIds, GetObjectsSearchV2} from "../../../../Services/UKObject";
import {UKObjectClass} from "../../../../Class/UKObjectClass";

export class EventLinkService {
  apiKey;
  authToken;
  custom;
  langKey;
  config;

  constructor(link, apiKey, authToken, custom, langKey, config) {
    this.apiKey = apiKey;
    this.authToken = authToken;
    this.custom = custom;
    this.langKey = langKey;
    this.config = config;
  }

  async search(query) {
    let data = await GetObjectsSearchV2('ukEvent', 0, this.apiKey, this.authToken, this.getFilters(query), 5);
    data = data.map(e => {
      return new UKObjectClass(e, this.config);
    })
    return data;
  }

  async getByIds(ids) {
    let data = await GetObjectByIds(this.apiKey, 'ukEvent', ids, this.config);
    data = data.data.map(e => {
      return new UKObjectClass(e, this.config);
    })
    return data;
  }

  getFilters(query) {
    let newFilters = [];
    this.custom.map((infos) => {
      newFilters = [
        ...newFilters,
        {
          name: infos.id,
          type: 'reg',
          value: query,
        },
        {
          name: `${infos.id}${this.langKey}`,
          type: 'reg',
          value: query,
        }
      ];
    });
    return newFilters;
  }
}