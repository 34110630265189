import { useContext, useEffect, useState } from 'react';
import { UKUser } from '../../../Class/UKUser';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import { GetUserById } from '../../../Services/User';

export default function CustomUsersCellVertical({ userParam, className, onClick = () => {}, id }) {
  const { config, application, language } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [userClass, setuserClass] = useState(null);

  useEffect(() => {
    if (userParam && config) {
      setuserClass(new UKUser(userParam, config));
    }
  }, [userParam, config]);

  useEffect(() => {
    async function initUser() {
      if (id && config) {
        const userById = await GetUserById(id, application.apiKey, user.authToken);
        setuserClass(new UKUser(userById, config));
      }
    }
    initUser();
  }, [id, config]);

  return (
    userClass && (
      <div onClick={onClick} className={'' + className}>
        <div className="hover:bg-indigo-50  hover:bg-opacity-50 cursor-pointer transition flex flex items-center lg:items-center rounded-lg border text-left py-2 px-2 min-h-full">
          {userClass.getPicture() && <img alt="object logo" className="object-contain " style={{ height: 30 }} src={userClass.getPicture()} />}

          <div
            className={'flex flex-col ml-0  text-gray-700 border-indigo-100 py-1 px-2 ' + ((userClass.getPicture() || '').length > 0 ? 'z' : '')}
            style={{ width: '100%' }}
          >
            <p className="font-normal text-md ">{userClass.getFullName(language)}</p>
            <p className="font-light text-xs overflow-ellipsis  overflow-hidden ellipse2">{userClass.getSubtitle(language)}</p>
          </div>
        </div>
      </div>
    )
  );
}
