import React, { useContext, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import YesNoInput from "../../Visitor/Account/Admin/Design/Component/YesNoInput";
import CommonButton from "../../Common/Button/CommonButton";
import MultipleButton from "./multiple.button";
import CustomModal from "../../Common/CustomModal.component";

export default function IframeEventModal({modalIsOpen, setmodalIsOpen}) {
  const {application} = useContext(ApplicationContext);
  const [iframeConfig, setIframeConfig] = useState({
    display: 'Card',
    searchBar: false,
    filter: false,
    split_by_date: false
  })
  const display = iframeConfig.display === 'Card' ? 'card' : iframeConfig.display === 'Tableau' ? 'table' : 'calendar';

  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const pushIframe = () => {
    window.open(window.location.protocol +
      "//" + window.location.host + "/iframe/ukEvent/event?apikey=" + application.apiKey + "&search=" +
      iframeConfig.searchBar + "&filter=" + iframeConfig.filter +
      "&split_by_date=" + iframeConfig.split_by_date + "&display=" + display, '_blank');
  }

  //ariaHideApp={false} supress react warning when open modal
  return (
    <CustomModal isOpen={modalIsOpen} title={'Paramètres de la page'} onClose={() => {
      setmodalIsOpen(false);
    }}>
      <div className={'flex w-full mt-2'}>
        <div>
          <MultipleButton title={"Style d'affichage :"}
                          value={iframeConfig.display}
                          buttons={['Card', 'Tableau', 'Calendrier']}
                          onClick={(index) => {
                            setIframeConfig({...iframeConfig, display: index});
                          }} />
          <YesNoInput
            title={"Afficher la barre de recherche ?"}
            yesSelected={iframeConfig.searchBar}
            onClick={(index) => {
              setIframeConfig({...iframeConfig, searchBar: index === 1});
            }}
          />
          <YesNoInput
            title={"Afficher les filtres ?"}
            yesSelected={iframeConfig.filter}
            onClick={(index) => {
              setIframeConfig({...iframeConfig, filter: index === 1});
            }}
          />
          <YesNoInput
            title={"Grouper par date ?"}
            yesSelected={iframeConfig.split_by_date}
            onClick={(index) => {
              setIframeConfig({...iframeConfig, split_by_date: index === 1});
            }}
          />
        </div>
        <div className={'w-full'}>
          <iframe className="ml-2 w-full h-[50vh]"
                  src={window.location.protocol +
                    "//" + window.location.host + "/iframe/ukEvent/event?apikey=" + application.apiKey + "&search=" +
                    iframeConfig.searchBar + "&filter=" + iframeConfig.filter +
                    "&split_by_date=" + iframeConfig.split_by_date + "&display=" + display} />
        </div>
      </div>
      <div className={'flex justify-end mt-5 w-full'}>
        <CommonButton title={'Créer'} customClassName="h-8 w-max"
                      onClick={() => pushIframe()} />
      </div>
    </CustomModal>
  );
}
