import DrawListOfCard from "./draw.object.card";

export default function GroupBy({listSorted, setModalOpen}) {
  return (
      listSorted.map((elem, index) =>
      <div key={index}>
        <p className={'text-xl font-bold text-left pl-5'}>{elem.value}</p>
        <div className={'flex'}>
          <DrawListOfCard list={elem.list} setModalOpen={setModalOpen}/>
        </div>
      </div>
      )
  )
}