import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FiSettings } from "react-icons/fi";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { GetObjectsSearch } from "../../../../Services/UKObject";
import CommonButton from "../../../Common/Button/CommonButton";
import CustomObjectCellVertical from "../../../Common/CustomObjectCellVertical";
import FilterComponent from "../FilterComponent";
import ModalComponent from "../Settings/ObjectModalComponent";

function ObjectListComponent({ typeobject, onClick, waiting }) {
    const history = useHistory();
    const { application } = useContext(ApplicationContext);
    const { user, isAdmin } = useContext(UserContext);
    let { params } = useRouteMatch();
    const [param, setParam] = useState(params);
    const currentPage = useRef({ page: 0, filters: [] });

    const [info, setInfo] = useState({ page: 0, objects: [], filters: [] });
    const [isLoading, setIsLoading] = useState(false);

    const [modalIsOpen, setmodalIsOpen] = useState(false);

    const getPage = useCallback(async () => {
        setIsLoading(true);
        let newObjs = await GetObjectsSearch(typeobject, info.page, application.apiKey, (user || {}).authToken, info.filters);
        if (!newObjs || newObjs.length === 0) {
            return;
        }

        currentPage.current = { page: info.page, filters: info.filters };
        setInfo((info) => {
            newObjs = newObjs.filter((e) => {
                info.objects.findIndex((a) => {
                    return a._id === e._id;
                });
                return info.objects.findIndex((a) => a._id === e._id) === -1;
            });
            return {
                page: info.page,
                objects: [...(info.objects || []), ...newObjs],
                filters: info.filters,
            };
        });
        if (newObjs.length > 0) {
            setIsLoading(false);
        }
    }, [info, isLoading, currentPage]);

    useEffect(() => {
        getPage();
    }, []);
    useEffect(() => {
        if (currentPage.current.page !== info.page) {
            getPage();
        } else if (currentPage.current.filters !== info.filters) {
            getPage();
        }
    }, [info]);

    const loader = useRef(null);

    const handleObserver = useCallback(
        (entries) => {
            const target = entries[0];
            if (target.isIntersecting && !isLoading && (info.objects || []).length > 0) {
                setIsLoading(true);
                setInfo((info) => {
                    return {
                        page: info.page + 1,
                        objects: info.objects,
                        filters: info.filters,
                    };
                });
            }
        },
        [info.objects]
    );

    useEffect(() => {
        if (params.idobject && params.idobject !== param.idobject) {
            setParam(params);
            setInfo(() => {
                return { page: 0, objects: [], filters: [] };
            });
        }
        if (params.typeobject && params.typeobject !== param.typeobject) {
            setParam(params);
            setInfo(() => {
                return { page: 0, objects: [], filters: [] };
            });
        }
    }, [params]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    const updateFilter = useCallback(
        (newFilters) => {
            setInfo(() => {
                return {
                    page: 0,
                    objects: [],
                    filters: newFilters.filter((e) => {
                        return e.value && e.value.trim().length > 0;
                    }),
                };
            });
        },
        [info]
    );
    return (
        <div className="">
            {isAdmin() && (
                <div className="sm:px-10 mt-3 text-right m-auto mb-0 items-center flex justify-end">
                    <CommonButton
                        title="Créer"
                        onClick={() => {
                            return history.push(`/v/object/new/${params.typeobject || typeobject}`);
                        }}
                        customClassName="h-8"
                    ></CommonButton>
                    <CommonButton title="Configuration" icon={<FiSettings />} onClick={() => setmodalIsOpen(true)} customClassName="h-8 ml-3"></CommonButton>
                </div>
            )}
            <div className="p-2 sm:p-10 grid grid-cols-4">
                <div>{<FilterComponent typeObject={typeobject} filters={info.filters} page={info.page} setObjects={() => {}} setFilters={updateFilter} />}</div>
                <div className="col-span-3">
                    <div className="">
                        <div className="mx-4 p-6 gap-3  rounded border border-gray-300 bg-white grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3  ">
                            {(!info.objects || info.objects.length === 0) && <p>Aucun élément trouvé</p>}
                            {info.objects &&
                                info.objects.map((elem, index) => {
                                    return (
                                        <CustomObjectCellVertical
                                            key={"OBJ" + index}
                                            onClick={() => {
                                                if (onClick) onClick(elem);
                                                else history.push(`/v/object/${elem._id}`, { back: true });
                                            }}
                                            object={elem}
                                            waiting={waiting}
                                            type="ukEvent"
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <ModalComponent object={info.objects} modalIsOpen={modalIsOpen} setmodalIsOpen={setmodalIsOpen} typeobject={typeobject} />
                <div ref={loader} />
            </div>
        </div>
    );
}

export default ObjectListComponent;
