import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { UploadFile } from "../../../Services/Utils";

function PictureUpdate({ url, name, onUpdate, size, rounded, classnames, customParentClassNames }) {
    var _fileInput = null;
    const { application } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [isSendingFile, setIsSendingFile] = useState(false);
    const errorToast = (text) => toast.error(text, { autoClose: 2000, position: "bottom-center" });
    const [dragActive, setDragActive] = useState(false);

    const onChangeHandler = async (event) => {
        event.preventDefault();
        uploadFile(event.target.files[0]);
    };

    const uploadFile = async (fileName, number) => {
        if (fileName == null) {
            return;
        }
        setIsSendingFile(true);
        const file = await UploadFile(fileName, application?.apiKey || "", user?.authToken || "");
        setIsSendingFile(false);
        if (file == null) {
            errorToast("Impossible de charger l'image");
            return;
        }
        var url = file.url;
        onUpdate(url);
    };

    const handleDrag = function (e) {
        console.log(e);
        e.preventDefault();
        e.stopPropagation();
        console.log("TYPE", e.type);
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        console.log("DRop", e);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            // handleFiles(e.dataTransfer.files);
            uploadFile(e.dataTransfer.files[0]);
        }
    };
    return (
        <form
            id="form-file-upload"
            onSubmit={(e) => e.preventDefault()}
            className={customParentClassNames ? customParentClassNames : `w-max relative m-auto `}
            onDrop={handleDrop}
            onDragEnter={handleDrag}
        >
            <input ref={(fileInput) => (_fileInput = fileInput)} type="file" name="file" onChange={onChangeHandler} className={"hidden"} accept="image/*" />

            <img
                alt="User face"
                className={classnames}
                style={{ maxHeight: size }}
                src={url || "https://eu.ui-avatars.com/api/?name=" + name + `&size=512&${rounded === "true" ? "rounded=true" : ""}`}
            ></img>
            <div
                onClick={() => _fileInput.click()}
                className="flex opacity-0 hover:opacity-100 cursor-pointer transform transition-all duration-150"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "#000000AA",
                    color: "white",
                    width: "100%",
                    height: "100%",

                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <p>Edit</p>
            </div>

            {isSendingFile && (
                <div
                    className="flex cursor-pointer transform transition-all duration-150"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "#000000AA",
                        color: "white",
                        height: "100%",
                        width: "100%",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div>
                </div>
            )}

            {dragActive && (
                <div
                    id="drag-file-element"
                    className=" absolute top-0 left-0 right-0 bottom-0"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </form>
    );
}

export default PictureUpdate;
