import {useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSort, faTrash} from '@fortawesome/free-solid-svg-icons';

const SelectValueSelector = ({values, setValues}) => {
  const [newValue, setNewValue] = useState('');
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(values, result.source.index, result.destination.index);

    setValues(items);
    /*
    this.setState({
      items
    });*/
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="bg-gray-100 p-2 rounded border border-gray-300"
            >
              {values.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      stylez={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      className={
                        'bg-white hover:bg-blue-50 hover:text-blue-400 hover:border-blue-400 border-gray-300 border py-2 px-2 rounded mt-1 mb-1 font-medium flex items-center justify-between text-gray-600 ' +
                        (snapshot.isDragging ? 'bg-blue-50 text-blue-400 border-blue-400' : '')
                      }
                    >
                      <p>{item}</p>

                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon={faSort} className="text-gray-400"/>
                        <div className="px-2 rounded text-gray-400 cursor-pointer hover:text-red-400 hover:bg-red-200">
                          <FontAwesomeIcon icon={faTrash} onClick={() => {
                            if (window.confirm("Voulez-vous supprimer cet élément ?")) {
                              let newValue = values;
                              newValue.splice(index, 1);
                              setValues(newValue);
                            }
                          }}/>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mt-2 ">
        <p className="font-medium text-sm text-left mb-2">Ajouter une valeur ?</p>
        <div className="bg-gray-100 flex px-2 text-gray-700 rounded items-center pb-1 pt-1 border-gray-300 ">
          <input
            type="text"
            className="w-full bg-gray-100 outline-none  px-1"
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
            }}
          />
          <div
            onClick={() => {
              if (newValue && newValue.length > 0) {
                setValues([...values, newValue]);
                setNewValue('');
              }
            }}
            className="text-gray-400 mr-2 ml-2 cursor-pointer"
          >
            <FontAwesomeIcon icon={faPlus}/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SelectValueSelector;
