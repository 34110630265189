import CustomModal from "../../../../Common/CustomModal.component";
import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import CommonButton from "../../../../Common/Button/CommonButton";
import ObjectsLinkTable from "../../../Objects/Form/objects.link.table";
import { UpdateJsonConfig } from "../../../../../Services/Application";
import { GetEventById, GetUKObjectById, UpdateObject } from "../../../../../Services/UKObject";
import { UserContext } from "../../../../../Context/UserContext";
import { GetUserById, UpdateUser } from "../../../../../Services/User";
import { UKObjectClass } from "../../../../../Class/UKObjectClass";
import { UKUser } from "../../../../../Class/UKUser";

const mapLinkObject = {
  id: "mapLink",
  placeholder: "Lien vers la carte",
  tint: "Lien vers la carte",
  type: "mapLink",
}

const FeatureLinksModal = ({isOpen, setIsOpen, feature, properties, setProperties, onSave}) => {
  const {application, config, reloadConfig, language} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [form, setForm] = useState({linkType: null, url: null, link: null});
  const [displayedLinks, setDisplayedLinks] = useState(null);
  const [oldLink, setOldLink] = useState(null);

  useEffect(() => {
    if (!feature) return;
    setDisplayedLinks(() => {
      return feature.properties?.link ? [feature.properties.link] : [];
    })
    setForm({linkType: feature.properties?.linkType, url: feature.properties?.url, link: feature.properties?.link});
    setOldLink({linkType: feature.properties?.linkType, url: feature.properties?.url, link: feature.properties?.link});
  }, [feature]);


  const clearObj = (obj) => {
    try {
      let index = obj.informations.mapLink.link.findIndex(link => {
        return link.id === feature.id
      })
      if (index !== -1)
        obj.informations.mapLink?.link?.splice(index, 1);
      return obj;
    } catch (e) {
      return obj;
    }
  }

  const onLinkSave = async () => {
    let newConfig = {...config};
    if (form.link) {
      let index;
      if (form.linkType === 'object') {
        index = newConfig.customObject.findIndex(obj => obj.id === form.url);
        if (newConfig.customObject[index].custom.findIndex(c => c.id === "mapLink" && c.type === "mapLink") === -1)
          newConfig.customObject[index].custom.push(mapLinkObject);
      } else if (form.linkType === 'user') {
        index = newConfig.inscription.customs.findIndex(usr => usr.id === form.url);
        if (newConfig.inscription.customs[index].custom.findIndex(c => c.id === "mapLink" && c.type === "mapLink") === -1)
          newConfig.inscription.customs[index].custom.push(mapLinkObject);
      } else {
        if (newConfig.eventObject.custom.findIndex(c => c.id === "mapLink" && c.type === "mapLink") === -1)
          newConfig.eventObject.custom.push(mapLinkObject);
      }
    }
    await UpdateJsonConfig(application.apiKey, newConfig);
    reloadConfig();

    if (oldLink.link) {
      let obj;
      if (oldLink.linkType === 'object') {
        obj = await GetUKObjectById(oldLink.link, application.apiKey, (user || {}).authToken);
        if (obj) {
          obj = clearObj(obj)
          await UpdateObject(oldLink.link, oldLink.url, obj.informations, application.apiKey, (user || {}).authToken);
        }
      } else if (oldLink.linkType === 'user') {
        obj = await GetUserById(oldLink.link, application.apiKey, (user || {}).authToken);
        if (obj) {
          obj = clearObj(obj)
          await UpdateUser(oldLink.link, obj.informations, application.apiKey, (user || {}).authToken);
        }
      } else {
        obj = await GetEventById(oldLink.link, application.apiKey);
        if (obj) {
          obj = clearObj(obj)
          await UpdateObject(oldLink.link, 'ukEvent', obj.informations, application.apiKey, (user || {}).authToken);
        }
      }
    }

    let name = '';
    let subTitle = '';
    if (form.link) {
      let obj;
      if (form.linkType === 'object') {
        obj = await GetUKObjectById(form.link, application.apiKey, (user || {}).authToken);
        obj.informations.mapLink = {
          link: obj.informations?.mapLink?.link ? [...obj.informations?.mapLink?.link, {id: feature.id, name: properties.name}] : [{id: feature.id, name: properties.name}],
        };
        let ukObj = new UKObjectClass(obj, config);
        name = ukObj.getTitle(language);
        subTitle = ukObj.getSubtitle(language);
        await UpdateObject(form.link, form.url, obj.informations, application.apiKey,
          (user || {}).authToken);
      } else if (form.linkType === 'user') {
        obj = await GetUserById(form.link, application.apiKey, (user || {}).authToken);
        obj.informations.mapLink = {
          link: obj.informations?.mapLink?.link ? [...obj.informations?.mapLink?.link, {id: feature.id, name: properties.name}] : [{id: feature.id, name: properties.name}],
        };
        let ukUsr = new UKUser(obj, config);
        name = ukUsr.getFullName(language);
        subTitle = ukUsr.getLastName(language);
        await UpdateUser(form.link, obj.informations, application.apiKey, (user || {}).authToken);
      } else {
        obj = await GetEventById(form.link, application.apiKey);
        obj.informations.mapLink = {
          link: obj.informations?.mapLink?.link ? [...obj.informations?.mapLink?.link, {id: feature.id, name: properties.name}] : [{id: feature.id, name: properties.name}],
        };
        let ukEvt = new UKObjectClass(obj, config);
        name = ukEvt.getTitle(language);
        subTitle = ukEvt.getSubtitle(language);
        await UpdateObject(form.link, 'ukEvent', obj.informations, application.apiKey, (user || {}).authToken);
      }
    }

    setOldLink({linkType: form.linkType, url: form.url, link: form.link});
    setProperties({...properties, linkType: form.linkType, url: form.url, link: form.link, linkName: name, linkSub: subTitle});
    onSave({...properties, linkType: form.linkType, url: form.url, link: form.link, linkName: name, linkSub: subTitle});
    setIsOpen(false);
  }

  const createLink = (obj) => {
    if (displayedLinks && displayedLinks.find(e => e === obj._id)) {
      setDisplayedLinks(() => {
        let newDisplayedLinks = [...displayedLinks];
        newDisplayedLinks.splice(newDisplayedLinks.findIndex(e => e === obj._id), 1);
        return newDisplayedLinks;
      })
      setForm(() => {
        let newForm = {...form};
        newForm.link = null;
        return newForm;
      })
      return;
    }
    setForm({...form, link: obj._id});
    setDisplayedLinks(() => {
      return obj._id ? [obj._id] : [];
    })
  }

  if (!config) return <></>
  return (
    <CustomModal title="Liens" isOpen={isOpen} onClose={() => {
      setIsOpen(false)
    }}>
      <div className="flex flex-col justify-center items-center w-full">
        <p className="font-medium text-sm text-left mb-2 mt-5">Avec quel élément souhaitez vous créer un lien ?</p>
        <select
          className="bg-gray-100 text-gray-700 px-3 w-max py-2 rounded"
          value={form.url || ''}
          onChange={async (e) => {
            const isObject = config.customObject.findIndex(custom => custom.id === e.target.value) >= 0;
            setForm({
              ...form,
              url: e.target.value,
              linkType: e.target.value === 'ukEvent' ? 'ukEvent' : isObject ? "object" : "user",
              style: 'line',
              identifier: 'CustomObjectDetailCell',
            });
          }}
        >
          <option value=''>-- Aucune selection --</option>
          {config.customObject.map((e, index) => {
            return <option key={index} value={e.id}>{e.name}</option>;
          })
          }
          {config.inscription.customs.map((e, index) => {
            return <option key={index} value={e.id}>{e.name}</option>;
          })}
          <option value='ukEvent'>Evénement</option>
        </select>
        <ObjectsLinkTable custom={form} linkURL={form.url} displayedLinks={displayedLinks} onClick={(e) => {
          createLink(e);
        }} />
        <CommonButton customClassName="w-max mt-3" title="Sauvegarder" onClick={onLinkSave} />
      </div>
    </CustomModal>
  );
}

export default FeatureLinksModal
