import React, {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../../../../Context/ApplicationContext";
import {UserContext} from "../../../../../Context/UserContext";
import {defaultConfig} from "../map.const"
import CommonButton from "../../../../Common/Button/CommonButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {faCameraViewfinder, faFileImage, faTrash} from "@fortawesome/pro-light-svg-icons";
import CustomColorInput from "../../../../Common/Inputs/customColorInput.component";
import {HelpText} from "react-rainbow-components";
import CustomInputFile from "../../../../Common/Inputs/customFileInput.component";
import DrawPatternMode from "../../CustomTool/draw.pattern.mode";
import {v4} from "uuid";
import {MapBuildingSelector, MapFloorSelector} from "./map.selection";

const MapConfigView = ({MapConfigService, isOpen, map, setMapConfigStates}) => {
  const {application} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [mapConfig, setMapConfig] = useState({});
  const [mapSelected, setMapSelected] = useState({map: null, floor: ''});

  useEffect(() => {
    fetchMapConfig();
  }, [application, user]);

  const fetchMapConfig = async () => {
    let config = await MapConfigService.get('config')
    if (!config) {
      config = defaultConfig;
    }
    setMapConfig(config);
  }

  const updateConfigByKey = async (key, value) => {
    let newMapConfig = {...mapConfig};
    newMapConfig[key] = value;
    setMapConfig(newMapConfig);

    const response = await MapConfigService.update({config: newMapConfig});
    if (response) {
      toast.success('Configuration mise à jour', {autoClose: 2000, position: 'bottom-center'});
    } else {
      toast.error('Erreur lors de la mise à jour', {autoClose: 2000, position: 'bottom-center'});
    }
  }

  if (!map) return;
  return (
    <div className={`transition-all duration-500 overflow-hidden ${isOpen ? 'min-w-[350px] w-[40%] mt-4' : 'w-0 h-0'}`}>
      <div className="bg-white m-2">
        <div className="m-2 space-y-2">
          <p className="font-medium text-md text-left pt-1">Configuration de la carte </p>

          <div className="flex gap-2">
            <p className="font-medium text-sm text-left mb-2">Définir la vue actuelle comme vue par défaut ?</p>
            <HelpText text="La vue en bleu sera la vue d'arriver lorsqu'on appuie sur le module PLAN."/>
          </div>
          <CommonButton customClassName='w-max' useAppTheme={false} customColor={'#3B82F6'} title='Vue par défaut' icon={<FontAwesomeIcon icon={faCameraViewfinder}/>} onClick={() => {
            const center = map.getCenter()
            updateConfigByKey('viewport', {center: [center.lng, center.lat], zoom: map.getZoom()})
          }}/>
          <CustomColorInput title='Quelle est la couleur de fond de la carte ?' value={mapConfig.backgroundColor || '#ffffff'} onChange={(e) => {
            updateConfigByKey('backgroundColor', e);
            setMapConfigStates((prevState) => {
              return {...prevState, config: {...prevState.config, backgroundColor: e}};
            })
          }}/>
          <div className="pt-3 space-y-2">
            <p className="font-medium text-md text-left">Image Patron</p>
            <CustomInputFile title="Importer une image patron" icon={<FontAwesomeIcon icon={faFileImage}/>} accept="image/*" onChange={(event) => {
              DrawPatternMode.onUpload(event.target.files[0], map, application, user, MapConfigService);
            }}/>
            <CommonButton customClassName='w-max' title="Supprimer l'image patron" useAppTheme={false} customColor={'#F87171'} icon={<FontAwesomeIcon icon={faTrash}/>} onClick={() => {
              DrawPatternMode.onDelete(map, MapConfigService)
            }}/>
          </div>
          <span className="h-2">&nbsp;</span>
        </div>
      </div>
      <div className="bg-white m-2 mt-2">
        <div className="m-2 space-y-2 pb-3">
          <p className="font-medium text-md text-left pt-1">Configuration des bâtiments / étages. </p>

          <MapBuildingSelector mapSelected={mapSelected} mapConfig={mapConfig} onChange={(e) => {
            setMapSelected({...mapSelected, map: e.target.value, floor: ''});
          }} />
          <MapFloorSelector mapSelected={mapSelected} mapConfig={mapConfig} onChange={(e) => {
            setMapSelected({...mapSelected, floor: e.target.value})
          }}/>

          <div className="flex gap-2 items-center justify-between">
            <CommonButton title="Ajouter un batiment" customClassName="w-max" onClick={async () => {
              let newBats = [...mapConfig.maps];
              const id = v4();
              newBats = newBats && newBats.length > 0 ? [...newBats, {id: id, name: `Batiment-${id.slice(0, 3)}`, floors: [{id: 'ext', name: "Extérieur"}]}] : [{id: id, name: `Batiment-${id.slice(0, 3)}`, floors: [{id: 'ext', name: "Extérieur"}]}];
              await updateConfigByKey('maps', newBats);
            }} />

            <CommonButton title="Ajouter un étage au batiment sélectionné" customClassName="w-max" onClick={async () => {
              if (!mapSelected?.map) return;
              const batIndex = mapConfig.maps.findIndex((e) => e.id === mapSelected.map);
              const newMaps = [...mapConfig.maps];
              const bat = newMaps[batIndex];

              bat.floors = bat.floors ? [...bat.floors, {id: bat.floors.length - 1, name: `Étage-${bat.floors.length - 1}`}] : [{id: 0, name: "Étage-0"}];
              await updateConfigByKey('maps', newMaps);
            }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapConfigView
