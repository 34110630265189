import { useContext } from 'react';
import { DateTimePicker } from 'react-rainbow-components';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import YesNoInput from '../../Design/Component/YesNoInput';

export default function ModuleConfigurationDate({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);

  return (
    <div className={className}>
      <YesNoInput
        title={'Voulez-vous afficher votre module uniquement à une date precise ?'}
        yesSelected={module.visibleFrom !== undefined}
        onClick={(e) => {
          if (e == 1) {
            setModule({ ...module, visibleFrom: new Date(), visibleTo: new Date() });
          } else {
            let newData = { ...module };
            delete newData.visibleFrom;
            delete newData.visibleTo;
            setModule(newData);
          }
        }}
      />
      {module.visibleTo && (
        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Date d'apparition du module</p>
          <div className="flex gap-3 ">
            <DateTimePicker
              disabled={module.visibleFrom === undefined}
              hour24
              className="w-full"
              locale={'fr-Fr'}
              value={module.visibleFrom || Date.now()}
              onChange={(date) => {
                setModule({ ...module, visibleFrom: date });
              }}
              cancelLabel={'Annuler'}
            />
            <p>-</p>
            <DateTimePicker
              disabled={module.visibleTo === undefined}
              hour24
              className="w-full"
              locale={'fr-Fr'}
              value={module.visibleTo || Date.now()}
              onChange={(date) => {
                setModule({ ...module, visibleTo: date });
              }}
              cancelLabel={'Annuler'}
            />
          </div>
        </div>
      )}
    </div>
  );
}
