import {v4} from "uuid";

export const defaultConfig = {
  backgroundColor: '#ffffff',
  viewport: {
    center: [0, 0],
    zoom: 20
  },
  maps: [
    {
      id: v4(), name: 'Batiment 1', floors: [
        {id: 'ext', name: 'Extérieur'}
      ]
    }
  ],
}

export const defaultCategory = {
  id: v4(),
  name: 'Par défaut',
  locked: true,
  informations: {}
}
