import NotificationComponent from "./NotificationComponent";

const NotificationView = ({ }) => {
  return (
    <div className="">
      <div className="flex text-center bottom-4 pb-8 w-11/12 m-auto mt-5 justify-center">
        <NotificationComponent />
      </div>
    </div>
  );
}

export default NotificationView
