import { useContext, useEffect, useMemo, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext"
import { UserContext } from "../../../Context/UserContext";
import { toast } from 'react-toastify';
import Select from 'react-select'
import { RegisterUser, UpdateUser } from "../../../Services/User";
import {
	useHistory, useLocation
} from "react-router-dom";
import { CreateObject } from "../../../Services/UKObject";
import { ClipLoader } from 'react-spinners'

function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}


function RegisterForm({ userTypesSelected }) {

	const { application, config } = useContext(ApplicationContext)
	const { updateUser, user, adminLoginUser } = useContext(UserContext)
	const [form, setForm] = useState({ email: "", password: "" })
	const [groupLink, setGroupLink] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	let query = useQuery();
	let idConfig = query.get('idconfig')
	let idLink = query.get('id')
	const errorLogin = (text) => toast.error(text, { autoClose: 2000, position: "bottom-center" })

	useEffect(() => {
		let links = config.links
		if (links) {
			setGroupLink(links.find((e) => {
				return e.id === idConfig
			}))
		}
		// eslint-disable-next-line
	}, [config])

	const check = (name) => {
		return form[name] === null || form[name] === undefined || form[name].trim().length === 0
	}
	const handleSubmit = async (evt) => {
		evt.preventDefault()
		if (check("email") || check("password") || check("confirmationpassword") ||
			check("firstName") || check("lastName")) return errorLogin("Merci de remplir toutes les informations nécessaires")
		if (form.password.length < 5) return errorLogin("Mot de passe trop court")
		if (form.password !== form.confirmationpassword) return errorLogin("Confirmation de mot de passe incorrecte")
		setIsLoading(true)

		if (config.canLogin && config.canLogin === "no") {
			setIsLoading(false);
			await RegisterUser(form.email, form.password, application.apiKey)
			await adminLoginUser(form.email, form.password)
			history.push(`/eventkey/home`);
			return;
		}

		try {
			var groups = (userTypesSelected.defaultGroup || "").split(",")
			var newUser = await RegisterUser(form.email, form.password, application.apiKey)
			updateUser(newUser)
			var obj = { ...form, groups: groups }

			obj.user_custom_type = userTypesSelected.id
			delete obj.confirmationpassword
			delete obj.password
			const updatedUser = await UpdateUser(newUser._id, obj, application.apiKey, newUser.authToken)
			updateUser(updatedUser)
			if (groupLink) {
				await CreateObject("ukLink", {
					"idconfig": idConfig,
					"type2": groupLink.type2,
					"id1": groupLink.type1 === "user" ? newUser._id : idLink,
					"type1": groupLink.type1,
					"state": "1",
					"id2": groupLink.type2 === "user" ? newUser._id : idLink,
				}, application.apiKey, user.authToken)
			}
			setIsLoading(false)
			history.push(`/v/home`)
		} catch (e) {
			errorLogin(e)
		}
	}

	return (


		<form onSubmit={handleSubmit} className=" w-full px-4 lg:px-0 mx-auto mt-6">
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-6">
				<div className="text-left">
					<label className="block text-gray-700 text-sm font-semibold mb-2">Nom</label>
					<input
						type="text" name="firstName" id="firstName" placeholder="Nom"
						className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " value={form.lastName} onChange={e => setForm({ ...form, lastName: e.target.value })} />
				</div>
				<div className="text-left">
					<label className="block text-gray-700 text-sm font-semibold mb-2">Prénom</label>
					<input
						type="text" name="lastName" id="lastName" placeholder="Prénom"
						className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " value={form.firstName} onChange={e => setForm({ ...form, firstName: e.target.value })} />
				</div>

				<div className="col-span-1 sm:col-span-2  text-left">
					<label className="block text-gray-700 text-sm font-semibold mb-2">E-mail</label>
					<input
						type="email" name="email" id="email" placeholder="Email"
						className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} />
				</div>

				<div className=" text-left">
					<label className="block text-gray-700 text-sm font-semibold mb-2">Mot de passe</label>
					<input
						className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " type="password" name="password" id="password" placeholder="Mot de passe" value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} />
				</div>
				<div className=" text-left">
					<label className="block text-gray-700 text-sm font-semibold mb-2">Confirmation de mot de passe</label>
					<input
						className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " type="password" name="confirmationpassword" id="confirmationpassword" placeholder="Mot de passe" value={form.confirmationpassword} onChange={e => setForm({ ...form, confirmationpassword: e.target.value })} />
				</div>
				{
					userTypesSelected.custom.map((groupLink, index) => {
						var input = <input
							className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " type="text" name={groupLink.id} id={groupLink.id} placeholder={groupLink.placeholder} value={form[groupLink.id]} onChange={e => setForm({ ...form, [groupLink.id]: e.target.value })} />


						if (groupLink.type === "cv") {
							return null
						}
						if (groupLink.type === "link" || groupLink.type === "mapLink") {
							return null
						}
						if (groupLink.type === "bigtext") {
							input = <textarea
								className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  " type="text" name={groupLink.id} id={groupLink.id} placeholder={groupLink.placeholder} value={form[groupLink.id]} onChange={e => setForm({ ...form, [groupLink.id]: e.target.value })}
							>

							</textarea>
						}
						if (groupLink.type === "selector" || groupLink.type === "multipleSelector") {
							var options = groupLink.values.map(e => { return { value: e, label: e } })
							var style = {
								container: (provided) => ({
									...provided,
									width: "100%",
								}),
								control: (provided, state) => ({
									...provided,
									backgroundColor: "rgba(243, 244, 246, 1)",
									borderWidth: 0,
									color: "red",
									fontSize: "0.875rem"
								}),
								placeholder: (defaultStyles) => {
									return {
										...defaultStyles,
										color: 'rgba(55, 65, 81, 0.6)',
									}
								}
							}
							input = <Select
								placeholder="Selectionner"
								styles={style}
								value={""}
								options={options} name={groupLink.id} isMulti={groupLink.type === "multipleSelector"} onChange={(a, b) => {
									if (groupLink.type === "multipleSelector") {
										setForm({ ...form, [groupLink.id]: a.map(e => e.value).join("$$//$$") })
									} else {
										setForm({ ...form, [groupLink.id]: e => e.value })
									}
								}} />
						}
						return (
							<div key={"registerinfo" + index} className={(groupLink.type === "bigtext" ? "col-span-2 " : "") + "text-left"}>
								<label className="block text-gray-700 text-sm font-semibold mb-2">{groupLink.tint}</label>
								{input}
							</div>
						)
					})
				}
			</div>


			{!isLoading ? <div className="px-0 pb-2 pt-4">
				<button className="w-full bg-gray-800 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
					style={{
						color: (config || {}).colorOverMainColor ?? "#FFFFFF",
						backgroundColor: (config || {}).mainColor ?? "#000000",
					}}
				>
					Valider
				</button>

			</div> : <ClipLoader loading={isLoading} />}


		</form>


	);
}

export default RegisterForm;
