import { useCallback, useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../../../Context/ApplicationContext";
import ModuleModal from "../newmodule.modal";
import { v4 } from "uuid";
import { UpdateJsonConfig } from "../../../../../../Services/Application";
import TabIcon from "./tabbar.tabicon";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";


// TODO Tabbar icon update pb
const TabBarModule = ({editMode, handleSelectedModule}) => {
  const {application, config, reloadConfig} = useContext(ApplicationContext);
  const [navpages, setNavpages] = useState(null);
  const [createTabModal, setCreateTabModal] = useState(false);
  const [editTab, setEditTab] = useState(false);

  useEffect(() => {
    setNavpages(config.tabBarV2)
  }, []);

  useEffect(() => {
    if (editTab === false) {
      return;
    }
    const newConfig = {...config};
    newConfig.tabBarV2 = navpages;
    UpdateJsonConfig(application.apiKey, newConfig).then(() => reloadConfig());
    setEditTab(false);
  }, [editTab]);

  const addTab = async (e) => {
    let newConfig = {...config};
    const id = v4()
    let newNavPages = [...navpages, {image: "house", type: e.type, _id: id, text: "", style: "TABBAR"}];

    setNavpages(newNavPages);
    newConfig.tabBarV2 = newNavPages;
    handleSelectedModule(newNavPages[newNavPages.findIndex((e) => e._id === id)]);
    await UpdateJsonConfig(application.apiKey, newConfig);
    await reloadConfig();
  };

  const onClick = () => {
    setCreateTabModal(true)
  }

  const onTabSelect = (e) => {
    handleSelectedModule(e)
  }

  const moveTab = (dragIndex, hoverIndex) => {
    setNavpages((prevPages) => {
      const newPages = [...prevPages];
      newPages.splice(dragIndex, 1);
      newPages.splice(hoverIndex, 0, prevPages[dragIndex]);
      return newPages;
    })
    setEditTab(true);
  }

  const renderTab = useCallback((tab, index) => {
    if (tab === undefined)
      return <></>
    return (
      <TabIcon
        tab={tab}
        key={tab._id}
        index={index}
        id={tab._id}
        image={tab.image}
        moveTab={moveTab}
        onClick={onTabSelect}
      />
    )
  }, [])

  if (navpages === null)
    return <></>

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="absolute bottom-0 w-full">
        <div style={{
          overflow: 'visible',
        }}>
          {(editMode || navpages.length > 0) &&
            <div className="flex border border-gray-800 bg-white items-center" style={{
              minHeight: "50px",
              minWidth: "100%",
            }}>
              <div className="flex justify-evenly items-center w-full h-full">
                {navpages.length > 0 && navpages.map((e, i) => {
                  return (
                    <div key={i}>
                      {renderTab(e, i)}
                    </div>
                  );
                })}
                {(editMode && navpages.length < 10) && <h1 className="cursor-pointer font-bold hover:opacity-60" onClick={onClick}>+</h1>}
                {editMode && !navpages.length && <h1 className="cursor-pointer font-bold hover:opacity-60" onClick={onClick}>+</h1>}
              </div>
            </div>}
        </div>
        <ModuleModal isOpen={createTabModal} setIsOpen={setCreateTabModal} onUpdate={(newData) => {
          if (newData) {
            addTab(newData);
          }
          setCreateTabModal(false);
        }} modalType="tabbar" />
      </div>
    </DndProvider>
  );
}

export default TabBarModule
