import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import Select from "react-select";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { toast } from "react-toastify";
import { UpdateUser } from "../../../Services/User";
import ProfilLinksUpdate from "./ProfilLinksUpdate";
import PictureUpdate from "./PictureUpdate";
import CommonButton from "../../Common/Button/CommonButton";

function ProfilForm() {
  const { application, config } = useContext(ApplicationContext);
  const [userTypesSelected, setUserTypeSelected] = useState(null);
  const { user, updateUser } = useContext(UserContext);
  const [form, setForm] = useState({});
  const errorLogin = (text) =>
    toast.error(text, { autoClose: 2000, position: "bottom-center" });

  useEffect(() => {
    if (user && config) {
      const type = config.inscription.customs.find(
        (elem) => elem.id === user.informations.user_custom_type
      );
      setUserTypeSelected(type);
    }
  }, [user, config]);

  useEffect(() => {
    if (user) {
      setForm(user.informations);
    }
  }, [user]);

  const check = (name) => {
    return form[name] === null || form[name].trim().length === 0;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (check("firstName") || check("lastName"))
      return errorLogin("Merci de remplir toutes les informations nécessaires");
    try {
      var obj = { ...form };
      obj.user_custom_type = userTypesSelected.id;
      delete obj.confirmationpassword;
      delete obj.password;
      delete obj.email;
      const updatedUser = await UpdateUser(
        user._id,
        obj,
        application.apiKey,
        user.authToken
      );
      updateUser(updatedUser);
    } catch (e) {
      errorLogin(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} className=" w-full  mx-auto mt-6 px-4">
      <PictureUpdate
        url={form.profilPicture}
        name={form.firstName + " " + form.lastName}
        onUpdate={(url) => {
          setForm({ ...form, profilPicture: url });
        }}
        size={100}
        rounded="true"
        classnames="block w-auto"
      />
      <div className="grid grid-cols-1 gap-4 gap-y-6 mt-4">
        <div className=" text-left flex items-center">
          <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
            Email
          </label>
          <p className="w-full block text-gray-700 text-sm font-semibold text-left ">
            {form.email}
          </p>
        </div>
        <div className="text-left flex items-center">
          <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
            Nom
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Nom"
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline   "
            value={form.lastName || ""}
            onChange={(e) => setForm({ ...form, lastName: e.target.value })}
          />
        </div>
        <div className="text-left flex items-center">
          <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
            Prénom
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            placeholder="Prénom"
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline   "
            value={form.firstName || ""}
            onChange={(e) => setForm({ ...form, firstName: e.target.value })}
          />
        </div>

        {userTypesSelected &&
          userTypesSelected.custom.map((elem, index) => {
            var input = (
              <input
                className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                type="text"
                name={elem.id}
                id={elem.id}
                placeholder={elem.placeholder}
                value={form[elem.id] || ""}
                onChange={(e) =>
                  setForm({ ...form, [elem.id]: e.target.value })
                }
              />
            );

            if (elem.type === "cv") {
              return null;
            }
            if (elem.type === "bigtext") {
              input = (
                <textarea
                  className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                  type="text"
                  name={elem.id}
                  id={elem.id}
                  placeholder={elem.placeholder}
                  value={form[elem.id] || ""}
                  onChange={(e) =>
                    setForm({ ...form, [elem.id]: e.target.value })
                  }
                ></textarea>
              );
            }
            if (elem.type === "selector" || elem.type === "multipleSelector") {
              var options = elem.values.map((e) => {
                return { value: e, label: e };
              });
              var style = {
                container: (provided) => ({
                  ...provided,
                  width: "100%",
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "rgba(243, 244, 246, 1)",
                  borderWidth: 0,
                  color: "red",
                  fontSize: "0.875rem",
                }),
                placeholder: (defaultStyles) => {
                  return {
                    ...defaultStyles,
                    color: "rgba(55, 65, 81, 0.6)",
                  };
                },
              };
              input = (
                <Select
                  placeholder="Selectionner"
                  styles={style}
                  options={options}
                  name={elem.id}
                  isMulti={elem.type === "multipleSelector"}
                  onChange={(a, b) => {
                    if (elem.type === "multipleSelector") {
                      setForm({
                        ...form,
                        [elem.id]: a.map((e) => e.value).join("$$//$$"),
                      });
                    } else {
                      setForm({ ...form, [elem.id]: (e) => e.value });
                    }
                  }}
                />
              );
            }
            return (
              <div
                key={"profilupdate" + index}
                className={
                  (elem.type === "bigtext" ? "" : "") +
                  "text-left flex items-center"
                }
              >
                <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">
                  {elem.tint}
                </label>
                {input}
              </div>
            );
          })}
        <ProfilLinksUpdate />
      </div>

      <div className="px-0 pb-2 pt-4 flex">
        <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3"></label>
        <div className="w-full text-left">
          <CommonButton customClassName="w-max" title="Valider" />
        </div>
      </div>
    </form>
  );
}

export default ProfilForm;
