import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TrashButton = ({ className = '', border = false, title = null, onClick = () => { } }) => {
  var borderClass =
    'h-max w-max border-gray-500 text-gray-500 ml-1 transition-all hover:border-red-400 hover:text-red-400 hover:bg-red-200 rounded px-2 cursor-pointer ';
  if (border) {
    borderClass =
      'h-max w-max border-gray-500 text-gray-500 ml-1 transition-all hover:border-red-400 hover:text-red-400 hover:bg-red-200 rounded px-2 cursor-pointer border ';
  }
  return (
    <div className={borderClass + className + '  pt-0 pb-0'} onClick={onClick}>
      {title && <p className="text-sm mr-2 inline">{title}</p>}
      <FontAwesomeIcon icon={faTrash} />
    </div>
  );
};

export default TrashButton;
