import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../../Context/UserContext';
import { GetObjectsAll } from '../../../../../../Services/UKObject';

export default function ModuleConfigurationGroup({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    async function initGroups() {
      if (!application || !user) return null;
      const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
      setGroups(groups);
    }
    initGroups();
  }, [application, user]);

  return (
    <div className={className}>
      <p className="font-medium text-sm text-left mb-2">Voulez-vous afficher votre module uniquement à certaine typologie d'utilisateur ?</p>
      <Select
        placeholder="Selectionner"
        isMulti={true}
        value={
          groups && module.groupRestricted
            ? groups
                .filter((e) => {
                  let values = module.groupRestricted.split(',');
                  return values.find((el) => el === e.informations.index);
                })
                .map((e) => {
                  return { value: e.informations.index, label: e.informations.name };
                })
            : null
        }
        options={groups && groups.map((e) => {
          return { value: e.informations.index, label: e.informations.name };
        })}
        onChange={(a, b) => {
          setModule({ ...module, groupRestricted: a.map((e) => e.value.toString()).join(',') });
        }}
      />
    </div>
  );
}
