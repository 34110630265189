import {useState} from "react";
import {TagCloud} from 'react-tagcloud'

export const TextResult = ({ans}) => {
  let respList = []
  // const [isOpen, setIsOpen] = useState({value: null, open: false});

  for (const a of ans) {
    if (!respList || respList.findIndex(e => e.value === a.informations.answer) === -1) {
      respList.push({value: a.informations.answer, count: 1});
    } else {
      respList.find(e => e.value === a.informations.answer).count += 1;
    }
  }

  return (
    <div className={'flex justify-center py-4'}>
      <></>
      <TagCloud tags={respList} minSize={12} maxSize={45}/>
      {/*<CustomModal isOpen={isOpen.open} title={'Question'} onClose={() => setIsOpen({value: null, open: false})} withoutHeight={false}>*/}
      {/*  <p className={'my-3'}>{isOpen.value}</p>*/}
      {/*</CustomModal>*/}
      {/*{respList.map((e, index) => {*/}
      {/*  return (*/}
      {/*    <div key={index} className={'border-black border-2 px-2 mx-2 w-36 cursor-pointer max-h-7 rounded-lg'}*/}
      {/*         onClick={() => setIsOpen({value: e, open: true})}>*/}
      {/*      <p className={'truncate'}>{e}</p>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*})}*/}
    </div>
  )
}
