const URL = "https://usekey-notification.herokuapp.com";
//const URL = "http://localhost:3000"

/**/
export const GetCampaign = async (apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/notifications/v2/campaign`, {
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const CreateCampaign = async (title, header,date, groups, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/notifications/v2/campaign`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: title,
                subtitle : header,
                date: date,
                groups: groups,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const UpdateCampaign = async (id, title,header,  date, groups, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/notifications/v2/campaign/${id}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: title,
                subtitle : header,
                date: date,
                groups: groups,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const DeleteCampaign = async (id, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/notifications/v2/campaign/${id}`, {
            method: "DELETE",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};
