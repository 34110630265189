import React, { useContext, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import CloseButton from "../../Common/Button/CloseButton";
import { toast } from "react-toastify";
import { CreateObject } from "../../../Services/UKObject";
import CommonButton from "../../Common/Button/CommonButton";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { useRouteMatch } from "react-router-dom";
import { Input } from "react-rainbow-components";

const DocsModal = ({newDoc, setCreateDoc, parent, lastFileIndex}) => {
  const params = useRouteMatch()
  const {application, reloadConfig, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [docObj, setDocObj] = useState({});

  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '18rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const handleOnClose = () => {
    setCreateDoc(false);
  }

  const onUpdate = async () => {
    await reloadConfig();
    setCreateDoc(false);
  }

  const options = [
    {label: 'Dossier', value: 'cat'},
    {label: 'Fichier', value: 'obj'},
    {label: 'PDF', value: 'pdf'},
  ];

  return (
    <Modal isOpen={newDoc} style={customStyles}>
      <div className="h-full w-full">
        <CloseButton
          className="float-right"
          onClick={handleOnClose}
        />
        <p className="font-bold text-xl text-gray-800 mb-2">Créer un nouveau document.</p>{' '}
        <div className="flex items-center grid grid-rows-3">
          <Select
            options={options}
            placeholder="Selectionner"
            onChange={(e) => {
              let newDocObj = {...docObj};
              newDocObj.type = e.value;
              if (parent !== null) {
                newDocObj.parent = parent;
              }
              newDocObj.image = "https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png";
              newDocObj.imageStyle = "small"
              newDocObj.index = lastFileIndex + 1;
              setDocObj(newDocObj);
            }}
          />
          <div className="mt-3 items-center">
            <p className="font-medium text-sm text-left mb-2">Quel est le nom de votre document ?</p>
            <input
              className="custominputbox w-full"
              type="text"
              value={docObj["title" + langKey] || ''}
              onChange={(e) => setDocObj({...docObj, ["title" + langKey]: e.target.value})}
            />
          </div>
          <CommonButton title="Sauvegarder"
                        onClick={async () => {
                          if (!docObj.type || !docObj["title" + langKey]) {
                            return toast.error('Il manque des informations', {position: 'bottom-center', autoClose: 2000});
                          }
                          await CreateObject(params.params.iddoc, docObj, application.apiKey, user.authToken);
                          toast.success('Objet créé', {position: 'bottom-center', autoClose: 2000});
                          await onUpdate();
                        }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default DocsModal
