export default function StyleDetailView({ config }) {
  return (
    <div className="border-b border-gray-400 bg-gray-100 p-2 flex flex-col gap-2 text-left justify-start items-start">
      <div className=" w-full  items-left">
        <div className="text-left border-b border-gray-500 w-full">
          <p className="font-normal ">Titre 1</p>
          <p className="font-light text-sm">Sous-titre 1</p>
        </div>
      </div>
      <div className="  w-full  items-left">
        <div className="text-left border-b border-gray-500  w-full">
          <p className="font-normal ">Titre 2</p>
          <p className="font-light text-sm">Sous-titre 2</p>
        </div>
      </div>
    </div>
  );
}
