import React from "react";

export const MapBuildingSelector = ({mapSelected, mapConfig, onChange}) => {
  return (
    <select
      className="h-7 ml-2 border rounded"
      value={mapSelected?.map || ''}
      onChange={onChange}>
      <option value=''>-- Aucun batiment selectionné --</option>
      {mapConfig.maps && mapConfig.maps.map((e, index) => {
        return (
          <option key={index} value={e.id}>{e.name}</option>
        );
      })}
    </select>
  )
}

export const MapFloorSelector = ({mapSelected, mapConfig, onChange}) => {
  return (
    <select
      className="h-7 ml-2 border rounded"
      value={mapSelected?.floor || ''}
      onChange={onChange}>
      <option value=''>-- Aucun étage selectionné --</option>
      {mapSelected && mapSelected.map && mapConfig.maps && mapConfig.maps.find(e => e.id === mapSelected.map)?.floors?.map((e, index) => {
        return (
          <option key={index} value={e.id}>{e.name}</option>
        )
      })}
    </select>
  )
}

export function MapSelection({mapSelected, mapConfig, onChange}) {
  return (
    <div className="flex gap-2">
      <MapBuildingSelector mapSelected={mapSelected.current} mapConfig={mapConfig} onChange={(e) => {
        mapSelected.current.map = e.target.value;
        mapSelected.current.floor = '';
        onChange()
      }}/>
      <MapFloorSelector mapSelected={mapSelected.current} mapConfig={mapConfig} onChange={(e) => {
        mapSelected.current.floor = e.target.value;
        onChange()
      }}/>
    </div>
  );
}
