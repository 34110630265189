import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import CustomModal from "../../../Common/CustomModal.component";
import ObjectsExistingLinks from "./objects.existinglinks.view";
import ObjectsLinkTable from "./objects.link.table";

const ObjectsNewLinkView = ({ linkURL, form, setForm, custom, defaultValue }, ref) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [displayedLinks, setDisplayedLinks] = useState(null);

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setModalIsOpen(true);
        },
    }));

    useEffect(() => {
        if (!defaultValue) return;
        setDisplayedLinks(() => {
            return defaultValue[custom.id] ? defaultValue[custom.id] : [];
        });
    }, [defaultValue]);

    const createLinkWith = (target) => {
        if (displayedLinks && displayedLinks.find((e) => e === target._id)) {
            setDisplayedLinks(() => {
                let newDisplayedLinks = [...displayedLinks];
                newDisplayedLinks.splice(
                    newDisplayedLinks.findIndex((e) => e === target._id),
                    1
                );
                return newDisplayedLinks;
            });
            setForm(() => {
                let newForm = { ...form };
                newForm[custom.id].splice(
                    newForm[custom.id].findIndex((e) => e === target._id),
                    1
                );
                return newForm;
            });
            return;
        }
        setDisplayedLinks(() => {
            const newDisplayedLinks = [...displayedLinks];
            newDisplayedLinks.push(target._id);
            return newDisplayedLinks;
        });
        setForm(() => {
            return { ...form, [custom.id]: form[custom.id] ? [...form[custom.id], target._id] : [target._id] };
        });
    };

    return (
        <div className="w-full">
            <div className="flex items-center mt-3">
                <ObjectsExistingLinks
                    linkType={custom.linkType}
                    linkID={custom.id}
                    form={form}
                    setForm={setForm}
                    displayedLinks={displayedLinks}
                    setDisplayedLinks={setDisplayedLinks}
                />
            </div>
   
            <CustomModal
                title="Liens"
                isOpen={modalIsOpen}
                onClose={() => {
                    setModalIsOpen(false);
                }}
            >
                <ObjectsLinkTable
                    linkURL={linkURL}
                    custom={custom}
                    displayedLinks={displayedLinks}
                    onClick={(e) => {
                        createLinkWith(e);
                    }}
                />
            </CustomModal>
        </div>
    );
};

export default forwardRef(ObjectsNewLinkView);
