import { useContext, useEffect, useState } from "react";
import Modal from 'react-modal';
import { HelpText } from 'react-rainbow-components';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { UpdateJsonConfig } from "../../../../Services/Application";
import { GetObjectsAll } from "../../../../Services/UKObject";
import CloseButton from '../../../Common/Button/CloseButton';
import YesNoInput from "../../Account/Admin/Design/Component/YesNoInput";
import YesNoInputV2 from "../../Account/Admin/Design/Component/YesNoInputV2";
import UsersSettingView from "./users.settings.view";

const UsersModalComponent = ({ modalIsOpen, modalSetter }) => {
  const { config, application, reloadConfig } = useContext(ApplicationContext);
  const { user } = useContext(UserContext)
  const params = useParams();
  const [customs, setCustoms] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([])
  useEffect(() => {
    async function initGroups() {
      if (!application || !user) return null;
      const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
      setGroups(groups);

      let newConfig = { ...config };
      const type = params.iduser;
      const indexConfig = newConfig.inscription.customs.findIndex((e) => e.id === type);
      if (indexConfig >= 0) {
        setSelectedGroup((newConfig.inscription.customs[indexConfig].defaultGroup || "").split(",").filter(e => e.trim().length > 0))
      }
    }
    initGroups();
  }, [application, user, params]);

  const customStyles = {
    content: {
      borderRadius: '4px',
      bottom: 'auto',
      minHeight: '10rem',
      left: '50%',
      position: 'fixed',
      right: 'auto',
      maxHeight: '90vh',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      minWidth: '80%',
      width: '80%',
      maxWidth: '60rem',
      padding: 40,
      backgroundColor: 'rgb(255, 255, 255)',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      border: 'solid',
      zIndex: 100,
    },
  };

  const handleOnClose = () => {
    modalSetter(false);
  }

  const toggleAskOnRegister = async () => {
    let newConfig = { ...config };
    const type = params.iduser;
    const indexConfig = newConfig.inscription.customs.findIndex((e) => e.id === type);
    if (indexConfig >= 0) {
      newConfig.inscription.customs[indexConfig].ask = !newConfig.inscription.customs[indexConfig].ask;
      await UpdateJsonConfig(application.apiKey, newConfig);
      await reloadConfig();
    }
    toast.success('Modifications sauvegardées !', { autoClose: 2000, position: 'bottom-center' });
  }

  const updateGroups = async (groups) => {
    let newConfig = { ...config };
    const type = params.iduser;
    const indexConfig = newConfig.inscription.customs.findIndex((e) => e.id === type);
    if (indexConfig >= 0) {
      newConfig.inscription.customs[indexConfig].defaultGroup = groups.join(",")
      await UpdateJsonConfig(application.apiKey, newConfig);
      await reloadConfig();
    }
    toast.success('Modifications sauvegardées !', { autoClose: 2000, position: 'bottom-center' });
  }

  return (
    <Modal ariaHideApp={false} isOpen={modalIsOpen} style={customStyles}>
      <div className="h-full w-full">
        <CloseButton
          className="float-right"
          onClick={handleOnClose}
        />
        <div>

          <div className="flex">
            <p className="font-bold text-xl text-gray-800 mb-2">Informations complementaires</p>{' '}
            <HelpText
              style={{ height: 10, marginLeft: 5 }}
              width={10}
              variant="question"
              title="Informations complementaires"
              text={<p style={{}}>Ici vous pouvez gerer les informations demandé sur votre objet</p>}
            />
          </div>
          <div className="grid grid-cols-2">
            <YesNoInputV2 title="Voulez-vous proposer ce type d'utilisateur à l'inscription ?" onClick={toggleAskOnRegister}
              yesSelected={config.inscription.customs.find((e) => e.id === params.iduser).ask || false} />
            <div>
              <p className="font-medium text-sm text-left mb-2">Groupe par default</p>
              <div className="w-full mb-3">
                <Select
                  placeholder="Selectionner"
                  isMulti={true}
                  value={
                    groups && selectedGroup
                      ? groups
                        .filter((e) => {
                          let values = selectedGroup;
                          return values.find((el) => el === e.informations.index);
                        })
                        .map((e) => {
                          return { value: e.informations.index, label: e.informations.name };
                        })
                      : null
                  }
                  options={groups && groups.map((e) => {
                    return { value: e.informations.index, label: e.informations.name };
                  })}
                  onChange={(a, b) => {

                    setSelectedGroup(a.map((e) => e.value));
                    updateGroups(a.map((e) => e.value));
                  }}
                />
              </div>
            </div>
          </div>

          <UsersSettingView initConfig={async () => {
            try {
              const type = params.iduser;
              const objConfig = config.inscription.customs.find((e) => e.id === type);
              setCustoms(objConfig.custom);
            } catch (e) {
            }
          }} customs={customs} setCustoms={setCustoms} />

        </div>
      </div>
    </Modal>
  );
}

export default UsersModalComponent;
