import React, { useContext } from "react";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { UpdateJsonConfig } from "../../../../../Services/Application";

const TicketConfiguration = ({customs, setCustoms, onClose}) => {
  const {application, config} = useContext(ApplicationContext);

  const onSave = async () => {
    let newConfig = {...config};

    newConfig.billetterie = {...customs};
    await UpdateJsonConfig(application.apiKey, newConfig)
    toast.success("Changements sauvegardés !", {autoClose: 2000});
    onClose();
  }

  return (
    <div className="col-span-2 border border-gray-400 bg-white px-4 py-2 rounded h-full w-full mt-10">
      <div className="py-2">
        <div className="flex flex-col  w-full">
          <label className="block text-gray-700 text-sm font-semibold">Combien de ticket peuvent prendre les visiteurs ?</label>
          <input
            type="number"
            min={0}
            placeholder="Vide pour illimité"
            className="w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10 w-max mt-2"
            value={customs.ticketLimit}
            onChange={(e) => setCustoms({...customs, ticketLimit: parseInt(e.target.value)})}
          />
        </div>
      </div>
      <div className="flex justify-center gap-x-3 px-3 mb-5 " style={{marginTop: 40}}>
        <div
          className="w-max border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
          onClick={() => {
            onSave();
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </div>
    </div>
  );
}

export default TicketConfiguration
