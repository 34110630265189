import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import moment from "moment";
import "moment/locale/fr";
import { CreateObject, DeleteObject, GetClosedSlots } from "../../../Services/UKObject";
import Modal from "react-modal";
import { AiOutlineClose } from 'react-icons/ai'

export default function MyEventsComponent() {
    const { application, config } =
        useContext(ApplicationContext);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { user } = useContext(UserContext);
    const [dates, setDates] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [ukSlots, setukSlots] = useState([])
    const [modalIsOpen, setmodalIsOpen] = useState(false)
    const [currentDiv, setcurrentDiv] = useState(null)
    const [reload, setreload] = useState(false)

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 40,
            maxHeight: '90%'
        },
    };

    const findCorrespondingDate = (e, schedule) => {
        for (let i = 0; i < schedule.length; i++) {
            const element = schedule[i];
            if (element[0]) {
                let dateElement = new Date(element[0].start)
                let dateElem = new Date(e.start)
                if (dateElem.getDate() === dateElement.getDate() &&
                    dateElem.getMonth() === dateElement.getMonth() &&
                    dateElem.getFullYear() === dateElement.getFullYear())
                    return i;
            }
        }
        return schedule.length - 1 || 0;
    }

    const setUkSlots = async () => {
        const slots = await GetClosedSlots(user._id, application.apiKey, user.authToken)
        setukSlots(slots);
    }

    useEffect(() => {
        if (!config || !user || !application)
            return null;
        setUkSlots()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, user, application, reload]);

    useEffect(() => {
        moment.locale("fr");
        var appointment = config.appointmentV2.find((e) => {
            const groups = e.groupReceive.split(",")
            for (let i = 0; i < groups.length; i++) {
                if (user.informations.groups.find((el) => {
                    return el === groups[i];
                })) {
                    return true;
                }
            }
            return false;
        })
        var allDates = [];
        let schedule = [[]];
        let newDates = [];
        appointment.dates.forEach((e) => {
            let start = moment(e.start);
            let end = moment(e.start).add({ minutes: appointment.slot })
            while (start.isBefore(moment(e.end))) {
                let closed = false;
                for (let i = 0; i < ukSlots.length; i++) {
                    const e = ukSlots[i].informations;
                    if (moment(e.dateDebut).isSame(start) && moment(e.dateFin).isSame(end) && e.close === 1)
                        closed = true;
                }
                newDates.push({ start: start.toISOString(), end: end.toISOString(), closed: closed })
                start = moment(end)
                end.add({ minutes: appointment.slot })
            }
        })
        newDates.forEach((elem) => {
            var date = new Date(elem.start);
            var already = allDates.filter((elem) => {
                return (
                    elem.getDate() === date.getDate() &&
                    elem.getMonth() === date.getMonth() &&
                    elem.getFullYear() === date.getFullYear()
                );
            });
            if (already.length === 0) {
                allDates.push(date);
                schedule[findCorrespondingDate(elem, schedule)].push(elem)
                schedule.push([])
            } else {
                schedule[findCorrespondingDate(elem, schedule)].push(elem)
            }
        });
        setDates(allDates.map((elem) => moment(elem)));
        schedule.pop()
        setAppointments(schedule)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ukSlots])

    const jsUcfirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div>
            <div
                className={`grid grid-cols-${dates.length} font-semi-bold text-sm py-2`}
            >
                {dates.map((elem, index) => {
                    return (
                        <p
                            key={"date" + index}
                            onClick={() => setSelectedIndex(index)}
                            className={"cursor-pointer font-semibold py-2"}
                            style={{
                                backgroundColor:
                                    selectedIndex === index ? config.mainColor : "transparent",
                                color:
                                    selectedIndex === index
                                        ? config.colorOverMainColor
                                        : "#3E3E3E",
                            }}
                        >
                            {jsUcfirst(elem.format("dddd DD MMMM"))}
                        </p>
                    );
                })}
            </div>
            <div className="text-left mx-2 border border-gray-400 rounded">
                {appointments[selectedIndex] && appointments[selectedIndex].map((e, index) => {
                    return (
                        <div
                            onClick={() => {
                                setmodalIsOpen(true)
                                setcurrentDiv(e)
                            }}
                            className={`${index === appointments[selectedIndex].length - 1 ? "" : "border-b"} border-gray-400 py-2`}>
                            {e.closed &&
                                <p className="text-gray-300 float-right mr-3">
                                    Indisponible
                                </p>
                            }
                            <p className={`ml-2 ${e.closed ? "text-gray-300" : ""}`}>
                                {`${moment(e.start).format("HH:mm")} - ${moment(e.end).format("HH:mm")}`}
                            </p>

                        </div>
                    )
                })}
            </div>
            {currentDiv && <Modal isOpen={modalIsOpen} style={customStyles}>
                <button className="float-right" onClick={() => {
                    setmodalIsOpen(false)
                }}>
                    {<AiOutlineClose />}
                </button>
                <p>
                    {`Voulez-vous vous rendre ${currentDiv.closed ? "disponible" : "indisponible"}?`}
                </p>
                <button
                    className="px-2 rounded-md font-normal text-xs py-1 border mt-2"
                    onClick={async () => {
                        if (currentDiv.closed) {
                            const slot = ukSlots.find((e) => {
                                const dateElem = new Date(e.informations.dateDebut)
                                const dateDiv = new Date(currentDiv.start)
                                return dateElem.getDate() === dateDiv.getDate() &&
                                    dateElem.getHours() === dateDiv.getHours() &&
                                    dateElem.getMinutes() === dateDiv.getMinutes() &&
                                    dateElem.getMonth() === dateDiv.getMonth() &&
                                    dateElem.getFullYear() === dateDiv.getFullYear()
                            })
                            await DeleteObject(slot._id, "ukSlotClose", application.apiKey, user.authToken)
                        } else {
                            await CreateObject("ukSlotClose", { close: 1, dateDebut: currentDiv.start, dateFin: currentDiv.end, user: user._id }, application.apiKey, user.authToken);
                        }
                        setreload(!reload);
                        setmodalIsOpen(false);
                    }}
                    style={{
                        borderColor: (config || {}).mainColor,
                        color: (config || {}).mainColor
                    }}>Oui</button>
            </Modal>}
        </div>
    )
}
