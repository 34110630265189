import MAP_STYLE from "./map.default.style.json";
import {defaultConfig} from "../Visitor/MapEditor/Map/map.const";

export const sfNeighborhoods = {
  type: "geojson",
  data: "https://raw.githubusercontent.com/uber/react-map-gl/master/examples/.data/feature-example-sf.json",
};

export const fillLayer = {
  id: "sf-neighborhoods-fill",
  source: "sf-neighborhoods",
  type: "fill",
  paint: {
    "fill-outline-color": "#0040c8",
    "fill-color": "#fff",
    "fill-opacity": 0,
  },
};

export const lineLayer = {
  id: "sf-neighborhoods-outline",
  source: "sf-neighborhoods",
  type: "line",
  paint: {
    "line-width": 2,
    "line-color": "#0080ef",
  },
};

export const COCO = {
  ...MAP_STYLE,
  sources: {
    ...MAP_STYLE.sources,
    ["sf-neighborhoods"]: sfNeighborhoods,
  },
  layers: [...MAP_STYLE.layers, fillLayer, lineLayer,
     {
      id: "background",
      type: "background",
      paint: {
        "background-color": "#FF0000"
      }
    }],
}
// Make a copy of the map style
export default function getMapStyle (config) {
  return {
    ...MAP_STYLE,
    sources: {
      ...MAP_STYLE.sources,
      ["sf-neighborhoods"]: sfNeighborhoods,
    },
    layers: [...MAP_STYLE.layers, fillLayer, lineLayer, {
      id: "background",
      type: "background",
      paint: {
        "background-color": "#FF0000"
      }
    }],
  };

  return {
    "version": 8,
    "name": "Basic",
    glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    sources: {
      openmaptiles: {
        type: "vector",
        url: ""
      },
      ["sf-neighborhoods"]: sfNeighborhoods,
    },
    layers: [fillLayer, lineLayer, {
      id: "background",
      type: "background",
      paint: {
        "background-color": config.backgroundColor || defaultConfig.backgroundColor
      }
    }],
  }
};
