import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { GetObjectsAll } from "../../../../Services/UKObject";

export default function ObjectsGroupfilterInput({ form, setForm }) {
  const { application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    async function initGroups() {
      if (!application || !user) return null;
      const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
      setGroups(groups);
    }
    initGroups();
  }, [application, user]);

  return (
    <div className=" text-left flex flex-col items-start">
      <label className={"block text-gray-700 text-sm font-semibold w-1/3 text-left mr-3"}>
        Groupe :
      </label>
      <div className="w-full">
        <Select
          placeholder="Selectionner"
          isMulti={true}
          value={
            groups && form.groups && form.groups.constructor === Array
              ? groups
                .filter((e) => {
                  let values = form.groups;
                  return values.find((el) => el === e.informations.index);
                })
                .map((e) => {
                  return { value: e.informations.index, label: e.informations.name };
                })
              : null
          }
          options={groups && groups.map((e) => {
            return { value: e.informations.index, label: e.informations.name };
          })}
          onChange={(a, b) => {
            setForm({ ...form, groups: a.map((e) => e.value) });
          }}
        />
      </div>
    </div>
  );
}
