import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ImageStyleFakeCell = ({hasIcon = false, hasImage = false}) => {
  return (
    <div className="flex bg-white"
         style={{
           height: "50px",
           padding: "0.2em",
         }}>
      <div className="flex justify-start items-center overflow-hidden gap-2 h-full">
        <div className="h-full" style={{
          position: "relative",
          aspectRatio: '1/1',
          height: "100%",
        }}>
          {hasImage && <img style={{
            aspectRatio: '1/1',
            height: "100%",
          }} src={"https://usbforwindows.com/storage/img/images_3/function_set_default_image_when_image_not_present.png"} alt="img" />}
          {hasIcon && <FontAwesomeIcon className="absolute top-0 left-0 right-0 bottom-0 m-auto" style={{ height : "80%"}} icon="house" />}
        </div>
        <p>Title...</p>
      </div>
    </div>
  );
}

export default ImageStyleFakeCell
