// import { format } from "date-fns";
import { subHours } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { useContext } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import CustomSearchBar from "../Inputs/customSearchBar.component";
import * as SVG from "./table.svgs";

/**
 * Create a custom table
 *
 * @param config <Object> {id, title} THead content
 * @param content <Array<Array>> TBody content
 * @param isLoading <Bool> Is content being fetch ?
 * @param onRowClick <Function> Function called on row click
 * @param startAt <Integer> Index from where the col are being display (Can be used to store id in index 0 then skip it on display)
 * ==================================================
 * @param hasSearchbarCol <Bool> Whether each column has its own searchbar or not. **You must provide an id in each object of the config**
 * @param filterObj <Object> It will contain searchbars content
 * @param onSearch <Function>(key, value) Triggered whenever user type in searchbar
 * @param onClear <Function>(key) Triggered whenever cancel is hit
 * @param hasSelector
 * @param selectorCallBack
 * @param checkedRowsIds
 * @param hideRowsOnLoading
 * @param contrastColorRowNumber
 * @param contrastColorRowValue
 * @param contrastColors
 * @return Returns a table.
 */

const CustomTableComponent = ({
    config,
    content,
    isLoading,
    onRowClick,
    startAt,
    hasSearchbarCol = false,
    filterObj,
    onSearch,
    onClear,
    hasSelector,
    selectorCallBack = () => {},
    checkedRowsIds = [],
    hideRowsOnLoading = false,
    contrastColorRowNumber = null,
    contrastColorRowValue,
    contrastColors = [],
}) => {
    const appContext = useContext(ApplicationContext);
    // console.log("CONTENT", content);
    // console.log("CONFIG", config);
    return (
        <div className="w-full border border-gray-300 border-separate rounded-md overflow-x-auto overflow-y-hidden">
            <table className="min-w-full">
                <thead className="bg-white">
                    <tr>
                        {hasSelector && <th className="w-12"></th>}
                        {config &&
                            config.constructor === Array &&
                            config.map((conf, index) => (
                                <th key={index} className="text-xs text-gray-500 font-bold">
                                    <div className="flex flex-col items-center justify-center ">
                                        {hasSearchbarCol && (
                                            <CustomSearchBar
                                                filter={filterObj[conf.id]}
                                                onChange={(e) => {
                                                    onSearch(conf.id, e);
                                                }}
                                                onClear={() => {
                                                    onClear(conf.id);
                                                }}
                                                hasIcon={false}
                                                customStyle="bg-white py-1 m-2 rounded border w-max px-2"
                                            />
                                        )}
                                        {conf.title}
                                    </div>
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {((hideRowsOnLoading && !isLoading) || !hideRowsOnLoading) &&
                        content &&
                        content.constructor === Array &&
                        content.length > 0 &&
                        content.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={`w-full h-10 hover:opacity:60 ${onRowClick ? "cursor-pointer" : ""}`}
                                onClick={(event) => {
                                    if (!onRowClick) return;
                                    onRowClick(row, event);
                                }}
                                style={{
                                    backgroundColor:
                                        (contrastColorRowNumber === null && rowIndex % 2) || (contrastColorRowValue && row[contrastColorRowNumber] === contrastColorRowValue)
                                            ? contrastColorRowNumber && contrastColors[0]
                                                ? contrastColors[0]
                                                : "rgb(255,255,255)"
                                            : contrastColorRowNumber && contrastColors[1]
                                            ? contrastColors[1]
                                            : "rgb(241,241,245)",
                                }}
                            >
                                {hasSelector && (
                                    <td>
                                        <input
                                            checked={checkedRowsIds.findIndex((id) => id === row[0]) !== -1}
                                            type="checkbox"
                                            name="select"
                                            value={row[0]}
                                            onChange={(e) => selectorCallBack(e.target.value)}
                                        />
                                    </td>
                                )}
                                {row &&
                                    row.constructor === Array &&
                                    row.length > 0 &&
                                    row.map((cell, cellIndex) => {
                                        if (cellIndex < startAt) return null;
                                        if (!cell || cell.constructor === Array)
                                            return (
                                                <td key={cellIndex} className="text-gray-300">
                                                    -
                                                </td>
                                            );

                                        const idCell = config[cellIndex - startAt]?.id || "";
                                        var type = null;
                                        try {
                                            const configCell = config.find((e) => e.id == idCell);
                                            // console.log("CONFIG CELL", configCell);
                                            type = configCell?.type;
                                        } catch (e) {}
                                        console.log("ici ", idCell);
                                        try {
                                            if (type == "banniere" || type == "icon") {
                                                return (
                                                    <td key={cellIndex} className={`text-sm text-gray-600 whitespace-nowrap max-w-[50ch] overflow-hidden border-l border-r px-2`}>
                                                        <img src={cell} className="w-full h-full object-contain" />
                                                    </td>
                                                );
                                            }
                                            if (idCell?.indexOf("date") !== -1) {
                                                if (rowIndex == 0) {
                                                    // console.log("IS DATE", idCell, cell, hasSelector, startAt);
                                                    // console.log("On est ici ?", appContext?.config?.timeZone);
                                                }
                                                console.log("------------");
                                                console.log("IS DATE", idCell, cell, hasSelector, startAt);
                                                console.log("On est ici ?", appContext?.config?.timeZone);
                                                if (appContext?.config?.timeZone) {
                                                    if (rowIndex == 0) {
                                                        // console.log("ON EST ICI AUSSI", appContext?.config?.timeZone);
                                                    }

                                                    // var formattedDate = cell; //date.format("DD/MM/YYYY HH:mm");
                                                    // var p = parseISO(cell);
                                                    // var t = format(p, "dd/MM/yyyy HH:mm");
                                                    let parsedDate = utcToZonedTime(new Date(cell), appContext?.config?.timeZone);
                                                    console.log(parsedDate);
                                                    parsedDate = subHours(parsedDate, 1);
                                                    console.log(parsedDate);
                                                    // parsedDate = zonedTimeToUtc(new Date(cell), appContext?.config?.timeZone);
                                                    const formatString = "dd/MM/yyyy HH:mm";
                                                    const formattedDate = format(parsedDate, formatString);
                                                    console.log(formattedDate);
                                                    return (
                                                        <td
                                                            key={cellIndex}
                                                            className={`text-sm text-gray-600 whitespace-nowrap max-w-[50ch] overflow-hidden border-l border-r px-2`}
                                                        >
                                                            {formattedDate}
                                                        </td>
                                                    );
                                                }

                                                return (
                                                    <td key={cellIndex} className={`text-sm text-gray-600 whitespace-nowrap max-w-[50ch] overflow-hidden border-l border-r px-2`}>
                                                        {new Date(cell).toLocaleString("fr-FR")}
                                                    </td>
                                                );
                                            }
                                        } catch (e) {
                                            console.log(e);
                                        }
                                        return (
                                            <td key={cellIndex} className={`text-sm text-gray-600 whitespace-nowrap max-w-[50ch] overflow-hidden border-l border-r px-2`}>
                                                {cell}
                                            </td>
                                        );
                                    })}
                            </tr>
                        ))}
                </tbody>
            </table>
            {isLoading && (
                <div className="flex gap-2 items-center justify-center">
                    <p className="text-xl text-gray-500 italic">Chargement</p>
                    {SVG.loadingAnimation}
                </div>
            )}
            {!isLoading && (!content || content.length === 0) && (
                <div>
                    <p className="text-xl text-gray-500 italic">Aucun élément</p>
                </div>
            )}
        </div>
    );
};

export default CustomTableComponent;
