import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import CreatePageModalComponent from './CreatePageModalComponent';
import DesignComponent from './DesignComponent';

export default function SelectDesignComponent({ modal = false }) {
  const history = useHistory();
  if (modal) history.push('/v/designv2');
  const { config } = useContext(ApplicationContext);
  const [selectedPage, setSelectedPage] = useState();
  const [pages, setPages] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [createIsOpen, setCreateIsOpen] = useState(false);

  useEffect(() => {
    if (config) {
      let customPages = [{ name: 'Home', title: 'Home', pages: config.home }];
      if (config.customPage) {
        customPages.push(...config.customPage);
      }
      setPages(customPages);
      if (!selectedPage) setSelectedPage(customPages[0]);
    }
  }, [config]);

  if (!selectedPage) return <></>;

  return (
    <>
      <DesignComponent
        modal={false}
        pages={pages}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        setIsOpen={setIsOpen}
        design={selectedPage}
        createIsOpen={createIsOpen}
        setCreateIsOpen={setCreateIsOpen}
        header={
          <>
            <div className="w-full flex items-center gap-x-4 border-gray-200  py-3 relative">
              <div className="flex items-end">
                <p className="text-sm w-max font-light">Page:</p>
                <select
                  onChange={(e) => {
                    if (e.target.value == 'create') {
                      return setIsOpen(true);
                    }
                    // console.log(pages);
                    // console.log(pages.find((page) => page.name == e.target.value));
                    setSelectedPage(pages.find((page) => page.name === e.target.value));
                  }}
                  className="bg-transparent font-bold text-2xl w-max capitalize"
                >
                  <option value={'create'}>{'Créer une page'}</option>;
                  {pages.map((e) => {
                    return <option value={e.name}>{e.name}</option>;
                  })}
                </select>
              </div>
              <div className="absolute right-0 top-1/4">
                <div
                  className="py-1 px-3 cursor-pointer rounded hover:opacity-80"
                  style={{ backgroundColor: config.mainColor, color: config.colorOverMainColor }}
                  onClick={() => {
                    setCreateIsOpen(true);
                  }}
                >
                  Ajouter un module
                </div>
              </div>
            </div>
          </>
        }
      />
      <CreatePageModalComponent
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={(name) => {
          let newPage = { name: name, title: name, pages: [] };
          setPages([...pages, newPage]);
          setSelectedPage(newPage);
          setIsOpen(false);
        }}
      />
    </>
  );
}
