const MultipleButton = ({ title, onClick = () => {}, value, buttons }) => {
  return (
    <div className="py-2">
      <p className="font-medium text-sm text-left mb-2">{title}</p>

      <div className="flex gap-3">
        {buttons.map((e, index) => {
          return (
            <p
              key={index}
              className={
                'px-3 border p-1 rounded border-gray-500 text-gray-600 cursor-pointer transition-all hover:bg-opacity-50 ' +
                (value === e ? 'border-blue-400 bg-blue-100 text-blue-500' : 'hover:bg-gray-100')
              }
              onClick={() => {
                onClick(e);
              }}
            >
              {e}
            </p>
          )
        })}
      </div>
    </div>
  );
};

export default MultipleButton;