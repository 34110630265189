import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Context/UserContext";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";

function ProfilMenu({ onSelectedMenu }) {
  const history = useHistory();
  const { config} =
    useContext(ApplicationContext);
  const [menu, setMenu] = useState([
    { id: "0", type: "MODIFY", title: "Modifier profil" },
    { id: "1", type: "DISCONECT", title: "Déconnexion" },
  ]);
  const { isAdmin } = useContext(UserContext);
  const [selectedMenu, setSelectedMenu] = useState(menu[0]);

  const reloadMenu = async () => {
    if (!config) {
      return;
    }
    var settings = config.settingsV2;
    setMenu(settings);
    var first = settings[0];
    while (first.actions !== null && first.actions !== undefined) {
      first = first.actions[0]
    }
    if (selectedMenu.type === "MODIFY" && selectedMenu.id === "0") {
      setSelectedMenu(first);
      onSelectedMenu(first);
    }
  };

  useEffect(() => {
    if (config) {
      reloadMenu();
    }
    // eslint-disable-next-line
  }, [config]);

  const updateMenu = (item) => {
    if (item.type === "URL") {
      window.open(item.param, "_blank");
    } else if (item.type === "DISCONECT") {
      history.push("/");
    } else if (item.type === "CONTACTER") {
      window.location = "mailto:" + item.param;
    } else {
      setSelectedMenu(item);
      onSelectedMenu(item, true);
    }
  };

  useEffect(() => {
    if (selectedMenu.type === "DISCONECT") {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [selectedMenu]);

  return (

    <div className="flex flex-col font-light text-left py-4 text-gray-700 ">
      {menu.map((elem, index) => {
        if (elem.onwebsite === false || (elem.state === "3" && !isAdmin())) {
          return null;
        }
        if (elem.actions) {
          return (
            <div key={index}>
              <p
                key={"profilinfo" + index}
                className={
                  "px-1 font-bold py-2 cursor-pointer border-l-2 border-white " +
                  (selectedMenu.id === elem.id
                    ? "font-medium border-gray-800"
                    : " hover:bg-gray-50 hover:border-gray-200")
                }
              >
                {elem.id}
                {elem.title}
              </p>
              {elem.actions.map((elem, index2) => {
                if (elem.onwebsite === false || (elem.state && elem.state === 3 && !isAdmin())) {
                  return null;
                }
                return (
                  <p
                    key={"profilinfosub" + index + "/" + index2}
                    onClick={() => updateMenu(elem)}
                    className={
                      "px-4 py-2 cursor-pointer border-l-2 border-white " +
                      (selectedMenu.id === elem.id
                        ? "font-medium border-gray-800"
                        : " hover:bg-gray-50 hover:border-gray-200")
                    }>
                    {elem.title}
                  </p>
                );
              })}
            </div>
          );
        }
        return (
          <p
            key={"profilinfo" + index}
            onClick={() => setSelectedMenu(elem)}
            className={
              "px-4 py-2 cursor-pointer border-l-2 border-white " +
              (selectedMenu.id === elem.id
                ? "font-medium border-gray-800"
                : " hover:bg-gray-50 hover:border-gray-200")
            }
          >
            {elem.title}
          </p>
        );
      })}
    </div>



  );
}

export default ProfilMenu;
