import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContext"
import RegisterForm from "./Register/RegisterForm";
import RegisterSelectType from "./Register/RegisterSelectType";
import 'react-toastify/dist/ReactToastify.css';

function RegisterFormComponent({ backAction }) {
	const { application, config } = useContext(ApplicationContext)
	const [userTypes, setUserTypes] = useState([])
	const queryParams = new URLSearchParams(window.location.search);
	const [userTypesSelected, setUserTypesSelected] = useState(null)

	/* Check is user need to select type of register */
	useEffect(() => {
		if (!config) return;
		var customs = config.inscription.customs.filter(elem => elem.ask !== false)
		setUserTypes(customs)
	}, [config])

	useEffect(() => {
		if (!config) return;
		const userType = queryParams.get("userType")
		const userConfig = config.inscription.customs.find(c => c.id === userType);

		if (userConfig) {
			setUserTypesSelected(userConfig)
		}
	}, [config, window.location.search])


	var defaultBackground = "https://images.unsplash.com/photo-1517457373958-b7bdd4587205?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"

	return (

		<div className="lg:w-1/2 w-full flex justify-center text-center md:px-16 px-0 z-0" >

			<div className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
				style={{
					backgroundImage: `url(${(application?.informations?.login || {}).background ?? defaultBackground})`,

				}}>
				<div className="absolute bg-black opacity-20 inset-0 z-0"></div>
			</div>
			<div className="w-full py-6 z-20 bg-white">
				<p className="text-left text-sm hover:underline font-medium cursor-pointer ml-2" onClick={backAction}>Retour</p>
				<h1 className="mt-6 text-center text-gray-700">

					<p className=" text-2xl font-bold">Inscription</p>
				</h1>
				{
					userTypesSelected != null && <RegisterForm userTypesSelected={userTypesSelected} />
				}
				{
					userTypesSelected == null && <RegisterSelectType userTypes={userTypes} onSelectUserType={elem => setUserTypesSelected(elem)} />
				}
			</div>
		</div>

	);
}

export default RegisterFormComponent;
