
const URL = "https://api.applications.pitchr.fr"
//const URL = "http://localhost:3000"

export const UploadFile = async (file, apiKey, authToken) => {
    var formData = new FormData()
    formData.append("file", file)


    try {
        const response = await fetch(`${URL}/images`,
            {
                method: 'PUT',
                headers: {
                    'ApiKey': apiKey,
                    'AuthToken': authToken
                },
                body: formData
            }
        )
        if (response.status !== 200) {
            return null
        }
        const json = await response.json()
        return json
    } catch (e) {
        return null
    }

}



export const UploadFileAll = async (file, apiKey, authToken) => {
    var formData = new FormData()
    formData.append("file", file)


    try {
        const response = await fetch(`${URL}/images/all`,
            {
                method: 'PUT',
                headers: {
                    'ApiKey': apiKey,
                    'AuthToken': authToken
                },
                body: formData
            }
        )
        if (response.status !== 200) {
            return null
        }
        const json = await response.json()
        return json
    } catch (e) {
        return null
    }

}

