import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import DefaultComponent from './DefaultComponent';

export default function CustomObjectComponent({ clickedData, setClickedData, type, lang }) {
  const { config } = useContext(ApplicationContext);
  const [customSelect, setCustomSelect] = useState();
  const [idSelect, setIdSelect] = useState();

  useEffect(() => {
    if (!config) return;
    let select = config.customObject.find((e) => e.id === clickedData.id);
    if (select) setCustomSelect(select.custom);
    // eslint-disable-next-line
  }, [config, clickedData, type]);

  useEffect(() => {
    if (!config) return;
    let objects = config.customObject.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setIdSelect(objects);
  }, [config]);

  return (
    <DefaultComponent clickedData={clickedData} setClickedData={setClickedData} type={type} picture lang={lang}>
      <div className="grid grid-cols-1 gap-0">
        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Quelle type d'objet souhaitez-vous afficher ?</p>
          {idSelect && (
            <div className="" style={{ width: 300 }}>
              <Select
                options={idSelect}
                onChange={(e) => {
                  setClickedData({ ...clickedData, id: e.value });
                }}
                value={idSelect.find((e) => e.value === clickedData.id)}
              />
            </div>
          )}
        </div>

        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Par quelle valeur souhaitez-vous trier cette liste?</p>
          {customSelect && (
            <div className="" style={{ width: 300 }}>
              <Select
                options={customSelect.map((e) => {
                  return {
                    value: e.id,
                    label: e.tint,
                  };
                })}
                onChange={(e) => {
                  setClickedData({ ...clickedData, sorter: `informations.${e.value}` });
                }}
                value={{
                  value: (
                    customSelect.find((e) => {
                      let sorter = (clickedData.sorter || '').split('.');
                      if (sorter.length === 0 || clickedData.sorter === '') return false;
                      return sorter[1] === e.id;
                    }) || {}
                  ).id,
                  label: (
                    customSelect.find((e) => {
                      let sorter = (clickedData.sorter || '').split('.');
                      if (sorter.length === 0 || clickedData.sorter === '') return false;
                      return sorter[1] === e.id;
                    }) || {}
                  ).tint,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </DefaultComponent>
  );
}
