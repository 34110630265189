import React, { useContext, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CheckboxGroup, Chip, Input, VerticalNavigation, VerticalSectionOverflow } from 'react-rainbow-components';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import './FilterComponent.css';

export default function FilterComponent({ typeObject, filters, setFilters, page, setObjects }) {
  const { config } = useContext(ApplicationContext);
  const [values, setvalues] = useState([]);
  const [options, setoptions] = useState([]);
  const [optionName, setoptionName] = useState([]);
  const [search, setsearch] = useState('');
  const [objects, setobjects] = useState([]);
  const [timeout, settimeout] = useState(null);

  useEffect(() => {
    if (!config) return;
    const customObjects = config.customObject;
    const object = (customObjects.find((e) => e.url === typeObject) || customObjects.find((e) => e.id === typeObject)).custom;
    let option = [];
    let optionname = [];
    object.forEach((e) => {
      if (e && e.filter) {
        optionname = [...optionname, e.tint];
        option = [
          ...option,
          e.values.map((elem) => {
            return { value: elem, label: elem, disabled: false, id: e.id, name: e.tint };
          }),
        ];
      }
    });
    setoptions(option);
    setoptionName(optionname);
    setobjects(object);
    // eslint-disable-next-line
  }, [config, values, filters]);

  const handleOnChange = (newValues, opt) => {
    setvalues(
      newValues.map((e) => {
        return { name: e, category: opt[0].name };
      })
    );
    var arr = [...filters];
    arr = arr.filter((e) => {
      return e.name !== opt[0].id;
    });
    arr = arr.concat(
      newValues.map((e) => {
        return { name: opt[0].id, type: 'reg', value: e };
      })
    );
    setFilters(arr);
  };

  const deleteChip = (e) => {
    setvalues(
      values.filter((elem) => {
        return elem !== e;
      })
    );

    setFilters(
      filters.filter((elem) => {
        return elem.value !== e.name;
      })
    );
  };

  const getResults = async (value) => {
    const titleID = objects.find((e) => {
      return e.isTitle;
    }).id;
    //const result = await GetObjectsSearch(typeObject, page - 1, application.apiKey, user.authToken, [{ name: titleID, type: "reg", value: value }])
    //setObjects(result);
    //return result;

    var arr = [...filters];
    arr = arr.filter((e) => {
      return e.type !== 'reg';
    });
    arr.push({ name: titleID, type: 'reg', value: value });
    setFilters(arr);
  };

  useEffect(() => {
    setvalues([]);
  }, [typeObject]);

  return (
    <div className="float-left grid grid-cols-1 bg-white px-2 w-full rounded border border-gray-300 pt-2">
      <p className="font-bold text-left">Filtres</p>
      <input
        className="custominputbox"
        type="text"
        style={{ width: '100%' }}
        placeholder="Rechercher"
        value={search}
        onChange={(e) => {
          setsearch(e.target.value);
          if (timeout) clearTimeout(timeout);
          settimeout(
            setTimeout(() => {
              getResults(e.target.value);
            }, 1000)
          );
        }}
      />

      <div className="rainbow-p-vertical_large rainbow-flex_wrap flex flex-row flex-wrap gap-2">
        {values.map((e) => {
          return (
            <>
              <Chip label={`${e.category}: ${e.name}`} onDelete={() => deleteChip(e)} />
            </>
          );
        })}
      </div>
      <VerticalNavigation className="max-h-full p-2 text-left filtre-custom">
        {options.map((e, index) => {
          return (
            <VerticalSectionOverflow label={optionName[index]}>
              <div className="contents">
                <CheckboxGroup
                  style={{ maxHeight: 1000, overflowY: 'scroll', fontSize: 5 }}
                  value={values.map((elem) => elem.name)}
                  onChange={(values) => handleOnChange(values, e)}
                  options={e}
                />
              </div>
            </VerticalSectionOverflow>
          );
        })}
      </VerticalNavigation>
    </div>
  );
}
