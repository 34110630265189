const URL = "https://usekey-objects.herokuapp.com";
//const URL = 'http://localhost:3033';
const queryString = require("query-string");
export const GetExternalLinkById = async (id, apiKey) => {
    try {
        const response = await fetch(`${URL}/objects/ukExternalLink/${id}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetBanner = async (id, apiKey) => {
    try {
        const response = await fetch(`${URL}/banner/${id}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetEventById = async (id, apiKey) => {
    try {
        const response = await fetch(`${URL}/events/${id}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const Search = async (query, apiKey) => {
    var obj = { query: query };
    try {
        const response = await fetch(`${URL}/search?${queryString.stringify(obj)}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};
export const CreateExternalLink = async (id, objType, appId, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/ukExternalLink`, {
            method: "PUT",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                type: objType,
                apiKey: apiKey,
                appId: appId,
                id: id,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetExternalLink = async (id, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/ukExternalLink/search?page=0`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filter: [{ name: "id", type: "==", value: id }],
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const UpdateObject = async (id, type, object, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/${type}/${id}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const DeleteObject = async (id, type, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/${type}/${id}`, {
            method: "DELETE",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const DeleteEventObject = async (id, type, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/events/${id}`, {
            method: "DELETE",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetObjectsPagination = async (type, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/${type}/pagination`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetImports = async (apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/imports`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetObjectsSearch = async (type, page, apiKey, authToken, filters = {}) => {
    try {
        const response = await fetch(`${URL}/objects/${type}/search?page=${page}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filter: filters,
                and: true,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const CreateObject = async (type, object, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/${type}`, {
            method: "PUT",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const CreateObjects = async (type, object, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/${type}/multiple`, {
            method: "PUT",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(object),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetAppointmentWithUserID = async (id, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/ukappointment/alluser/${id}`, {
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetUKObjectById = async (id, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/object/${id}`, {
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetObjectsAll = async (type, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/${type}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const AddObject = async (type, apiKey, authToken, event) => {
    try {
        const response = await fetch(`${URL}/objects/${type}`, {
            method: "PUT",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(event),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetClosedSlots = async (userID, apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/ukappointment/allcloseuser/${userID}`, {
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const UploadExcel = async (url, files, e, apiKey, authToken) => {
    var formData = new FormData();
    for (let i = 0; i < e.files.length; i++) {
        const element = e.files[i];
        formData.append(element, files[i]["0"]);
    }
    try {
        const response = await fetch(`${url}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
            },
            body: formData,
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetObjectsSearchV2 = async (type, page, apiKey, authToken, filters = {}, limit = 20) => {
    try {
        const response = await fetch(`${URL}/objects/${type}/v2/search?page=${page}&limit=${limit}`, {
            method: "POST",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filter: filters,
                and: false,
            }),
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};

export const GetObjectByIds = async (apiKey, type, ids, config) => {
    const myArrayQry = ids
        .map(function (el, idx) {
            return "ids[" + idx + "]=" + el;
        })
        .join("&");
    try {
        let response = await fetch(`https://usekey-objects.herokuapp.com/objects/${type}?${myArrayQry}`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        response = await response.json();
        return response;
    } catch {
        return null;
    }
};

export const GetUKQuestion = async (apiKey, authToken) => {
    try {
        const response = await fetch(`${URL}/objects/UKQuestions/regexall`, {
            method: "GET",
            headers: {
                ApiKey: apiKey,
                AuthToken: authToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        if (response.status !== 200) {
            return null;
        }
        const json = await response.json();
        return json;
    } catch (e) {
        return null;
    }
};
