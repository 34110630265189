import React, { useEffect, useState } from "react";
import { Input } from "react-rainbow-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import YesNoInput from "../../Account/Admin/Design/Component/YesNoInput";
import UploadFileModal from "../../../UploadFileDocument/UploadFileModal";

const DocsSettingsPDFUrl = ({doc, setDocSelected}) => {
  const [pdfUrl, setPdfUrl] = useState(doc.informations.url || "");
  const [modalUpload, setModalUpload] = useState(false);

  useEffect(() => {
    setPdfUrl(doc.informations.url || "");
  }, [doc._id]);

  return (
    <div>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quelle est l'URL ou vous souhaitez rediriger l'utilisateur ?</p>
        <div className="flex items-center gap-2">
          <input
            className="custominputbox w-full"
            type="text"
            value={pdfUrl}
            onChange={(e) => {
              setPdfUrl(e.target.value)
              setDocSelected((prevDoc) => {
                let newDoc = {...prevDoc};
                newDoc.informations.url = e.target.value;
                return newDoc;
              })
            }} />
          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              setModalUpload(true);
            }}>
            <FontAwesomeIcon icon={faCloudUploadAlt} />
          </div>
          <div
            className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
            onClick={() => {
              window.open(doc.informations.url, '_blank');
            }}>
            <FontAwesomeIcon icon={faEye} />
          </div>
        </div>
      </div>
      <YesNoInput
        title={"Voulez vous rediriger l'utilisation dans l'application ou sur son navigateur ?"}
        yesSelected={doc.informations.inApp === 'true'}
        onClick={(e) => {
          setDocSelected((prevDoc) => {
            let newDoc = {...prevDoc};
            newDoc.informations.inApp = e === 1 ? 'true' : 'false';
            return newDoc;
          })
        }}
        yesTitle="Dans l'application"
        noTitle="Sur son navigateur" />
      <UploadFileModal
        modalIsOpen={modalUpload}
        setModalIsOpen={setModalUpload}
        onSelectFile={(url) => {
          setModalUpload(false);
          setPdfUrl(url);
          setDocSelected((prevDoc) => {
            let newDoc = {...prevDoc};
            newDoc.informations.url = url;
            return newDoc;
          })
        }} />
    </div>
  )
}

export default DocsSettingsPDFUrl
