import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../Context/UserContext'
import { ApplicationContext } from '../../../../Context/ApplicationContext'
import { GetUserByIds } from '../../../../Services/User'
import { DeleteObject, UpdateObject } from '../../../../Services/UKObject'

export default function WaitingListComponent({ idobject, typeobject }) {
    const { findLinkObject, user, reloadLinks, allApplicationLinks } = useContext(UserContext)
    const { application, config } = useContext(ApplicationContext);
    const [userArray, setuserArray] = useState([])
    const [userObj, setuserObj] = useState([])

    const reloadUsers = async () => {
        const waitingList = findLinkObject(idobject, typeobject)
        const ids = waitingList.filter((e) => {
            return e.informations.state === "0"
        }).map((e) => {
            return e.informations.type1 === "user" ? e.informations.id1 : e.informations.id2;
        })
        const users = ids.length > 0 ? await GetUserByIds(ids, application.apiKey, user.authToken) : [];
        setuserArray(users)
        setuserObj(waitingList.filter((e) => {
            return e.informations.state === "0"
        }))
    }

    useEffect(() => {
        if (!application || !user)
            return null;
        reloadUsers();
        // eslint-disable-next-line
    }, [application, user, allApplicationLinks])

    if (!typeobject || !idobject)
        return;

    return (
        <>
            {userArray && userArray.length > 0 &&
                <>
                    <br />
                    <div className="bg-white w-full sm:w-3/4 md:w-3/4 lg:w-2/4 m-auto grid grid-cols-1 rounded border border-gray-300">
                        <p>Liste des personnes en attente :</p>
                        {userArray.map((e, index) => {
                            return (
                                <div className="grid grid-cols-2 mt-1 mb-2">
                                    <p>{e.informations.lastName} {e.informations.firstName}</p>
                                    <div>
                                        <button className=" bg-gray-800 hover:bg-grey-900 text-white text-sm py-1 px-2 font-semibold mr-3 rounded focus:outline-none focus:shadow-outline"
                                            style={{
                                                backgroundColor: (config || {}).colorOverMainColor ?? "#FFFFFF",
                                                borderColor: (config || {}).mainColor ?? "#000000",
                                                borderWidth: 1,
                                                color: (config || {}).mainColor ?? "#000000",
                                            }}
                                            onClick={async () => {
                                                await UpdateObject(userObj[index]._id, "ukLink", { state: "1" }, application.apiKey, user.authToken);
                                                reloadLinks()
                                            }}
                                        >
                                            Accepter
                                        </button>
                                        <button className=" bg-gray-800 hover:bg-grey-900 text-white text-sm py-1 px-2 mr-3 font-semibold rounded focus:outline-none focus:shadow-outline"
                                            style={{
                                                color: (config || {}).colorOverMainColor ?? "#FFFFFF",
                                                backgroundColor: (config || {}).mainColor ?? "#000000",
                                            }}
                                            onClick={async () => {
                                                await DeleteObject(userObj[index]._id, "ukLink", application.apiKey, user.authToken)
                                                reloadLinks()
                                            }}
                                        >
                                            Refuser
                                        </button>
                                    </div>
                                </div>)
                        })}
                    </div>
                </>
            }
        </>
    )
}
