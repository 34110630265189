import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import DefaultComponent from './DefaultComponent';

export default function DocComponent({ clickedData, setClickedData, type, lang }) {
  const { config } = useContext(ApplicationContext);
  const [docList, setDocList] = useState();

  useEffect(() => {
    if (!config) return;
    let docs = config.docs.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setDocList(docs);
  }, [config, clickedData]);

  return (
    <DefaultComponent clickedData={clickedData} setClickedData={setClickedData} type={type} picture lang={lang}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quel document souhaitez-vous afficher ?</p>
        {docList && (
          <div style={{ maxWidth: 300 }}>
            <Select
              value={docList.find((e) => e.value === clickedData.id)}
              className="w-full"
              options={docList}
              onChange={(e) => {
                setClickedData({ ...clickedData, id: e.value });
              }}
            />
          </div>
        )}
      </div>
    </DefaultComponent>
  );
}
