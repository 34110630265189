import CustomSearchBar from "../../../Common/Inputs/customSearchBar.component";
import UsersTable from "../../Users/users.table";
import ObjectsTable from "../objects.table";
import EventLinkTable from "../../Events/Settings/events.link.table";
import React, { useContext, useEffect, useState } from "react";
import { GetUsersAllV2 } from "../../../../Services/User";
import { GetObjectsAll, GetObjectsSearchV2 } from "../../../../Services/UKObject";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";

const ObjectsLinkTable = ({linkURL, custom, displayedLinks, onClick}) => {
  const {application, config, langKey} = useContext(ApplicationContext)
  const {user} = useContext(UserContext);
  const [filter, setFilter] = useState('');
  const [objects, setObjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setObjects([]);
    getObjects();
  }, [filter, linkURL, application, user]);

  const getObjects = async () => {
    if (!application || !user || !linkURL || linkURL.length === 0) return;
    setIsLoading(true)
    let newObjs;
    if (custom.linkType === 'user') {
      newObjs = await GetUsersAllV2(0, application.apiKey, (user || {}).authToken, getFormattedFilters(), linkURL, 10);
      if (!newObjs) {
        setIsLoading(false);
        return;
      }
    } else if (custom.linkType === 'object') {
      newObjs = await GetObjectsSearchV2(linkURL, 0, application.apiKey, (user || {}).authToken, getFormattedFilters(), 10);
      if (!newObjs) {
        setIsLoading(false);
        return;
      }
    } else {
      newObjs = await GetObjectsAll('ukEvent', application.apiKey, (user || {}).authToken);
      if (!newObjs) {
        setIsLoading(false);
        return;
      }
    }
    setObjects(() => {
      return newObjs.data
    });
    setIsLoading(false)
  }

  const getFormattedFilters = () => {
    if (!config || !filter || filter === '') return [];
    let newFilters = [];
    if (custom.linkType === 'user') {
      config.inscription.customs.find(e => e.id === custom.url).custom.map((infos) => {
        newFilters = [
          ...newFilters,
          {name: "email", type: 'reg', value: filter},
          {name: "lastName", type: 'reg', value: filter},
          {name: "firstName", type: 'reg', value: filter},
          {name: infos.id, type: 'reg', value: filter},
        ];
      })
    } else if (custom.linkType === 'object') {
      config.customObject.find(e => e.id === custom.url).custom.map((infos) => {
        newFilters = [
          ...newFilters,
          {
            name: infos.id,
            type: 'reg',
            value: filter,
          },
          {
            name: `${infos.id}${langKey}`,
            type: 'reg',
            value: filter,
          }
        ];
      });
    } else {
      config.eventObject.custom.map((infos) => {
        newFilters = [
          ...newFilters,
          {
            name: infos.id,
            type: 'reg',
            value: filter,
          },
          {
            name: `${infos.id}${langKey}`,
            type: 'reg',
            value: filter,
          }
        ];
      });
    }
    return newFilters;
  };

  return (
    <div className="w-11/12 mt-3 space-y-2">
      {custom.linkType && <CustomSearchBar filter={filter} onClear={() => {
        setFilter('')
      }} onChange={(e) => {
        setFilter(e)
      }} />}
      {custom.linkType === 'user' && <UsersTable usertype={linkURL} info={{objects: objects}} loading={isLoading} onCellClick={(e) => {
        onClick(e);
        setFilter('');
      }} selectedKey={'_id'} selectedList={displayedLinks} />}
      {custom.linkType === 'object' && <ObjectsTable typeobject={linkURL} info={{objects: objects}} loading={isLoading} onCellClick={(e) => {
        onClick(e);
        setFilter('');
      }} selectedKey={'_id'} selectedList={displayedLinks} />}
      {custom.linkType === 'ukEvent' && <EventLinkTable typeobject={linkURL} info={{objects: objects}} loading={isLoading} onCellClick={(e) => {
        onClick(e);
        setFilter('');
      }} selectedKey={'_id'} selectedList={displayedLinks} />}
    </div>
  );
}

export default ObjectsLinkTable
