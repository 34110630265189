const recursvice = (dataMap, from, to2, parents) => {
  var to = dataMap.find(e => e.id == from.link);
  if (to.idBuilding == to2) {
    return {...to, child: []};
  }
  var allStartPoints = dataMap
  .filter(e => e.idBuilding == to.idBuilding)
  .filter(e => {
    return ![...parents, to.id].includes(e.id);
  });

  return {
    ...to,
    child: allStartPoints.map(e => {
      return {
        ...e,
        child: [recursvice(dataMap, e, to2, [...parents, e.id])],
      };
    }),
  };
};

const getChild = (dataMap, from, to) => {
  var allStartPoints = dataMap.filter(e => e.idBuilding == from);
  return allStartPoints.map(e => ({
    ...e,
    child: [recursvice(dataMap, e, to, [e.id])],
  }));
};

export const getBuildingPossibilities = (GEOJSON, pointStart, pointTo) => {
  console.log("GET BUILDING", pointStart, pointTo)
  const from =
    (pointStart.properties.building ?? 'ext') + '-' + (pointStart.properties.floor ?? 'ext');
  const to = (pointTo.properties.building ?? 'ext') + '-' + (pointTo.properties.floor ?? 'ext');
  console.log(from, "---", to)
  let pointListFeatures = GEOJSON.features.filter(
    e => e.geometry.type === 'Point' && e.properties.type === 'link',
  );
  let pointList = pointListFeatures.map(e => ({
    id: e.id,
    building: e.properties.floor === 'ext' ? 'ext' : e.properties.building,
    floor: e.properties.floor,
    link: e.properties.buildingLink,
  }));
  console.log(pointList)
  //TODO faire en sorte que geoJson ressemble a Data
  var data = pointList;
  //Ici on va juste reformatter les objets pour avoir des gens d'id avec building + floor
  const dataMap = data.map(e => {
    var linkTo = data.find(a => a.id == e.link);
    if (!linkTo) return;
    return {
      ...e,
      idBuilding: e.building + '-' + e.floor,
      idBuildingTo: linkTo.building + '-' + linkTo.floor,
      buildingTo: linkTo.building,
      floorTo: linkTo.floor,
    };
  });

  var child = getChild(dataMap, from, to);

  var res = [];

  const getArr = (c, parents) => {
    if (c.child.length == 0) {
      res.push([...parents, c.id]);
    }
    for (let child of c.child) {
      getArr(child, [...parents, c.id]);
    }
  };

  child.map(e => getArr(e, []));
  res = res.filter(e => {
    var last = e[e.length - 1];
    return dataMap.find(e => e.id == last).idBuilding == to;
  });
  return res.map(e => {
    return e.map(e => {
      return {...pointListFeatures.find(a => a.id == e)}
    })
  });
};
