import { faClose, faEye, faSend } from "@fortawesome/pro-light-svg-icons";
import { faBallotCheck, faDatabase, faEyeSlash, faFloppyDisk, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { UseSocket } from "../../../Services/Sockets";
import { CreateObject, DeleteObject, GetObjectsAll, UpdateObject } from "../../../Services/UKObject";
import CommonButton from "../../Common/Button/CommonButton";
import CustomInputBox from "../../Common/Inputs/customInputBox.component";
import { MyCamembert } from "./MyCamembert";
import { MyStarRating } from "./MyStarRating";
import { TextResult } from "./TextResult";

const QuestionsView = ({}) => {
    const { application, reloadApplication, langKey } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const params = useParams();
    const history = useHistory();
    const [questions, setQuestions] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [answer, setAnswer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        reloadApplication(window.location.hostname);
    }, [params]);

    useEffect(() => {
        getQuestions();
        const interval = setInterval(() => {
            getAnswer(questions ?? []);
        }, 5000);
        return () => clearInterval(interval);
    }, [application, user]);

    const getAnswer = async (list) => {
        const tmp = await GetObjectsAll(`UKAnswerLive_${params.idevent}`, application.apiKey, (user || {}).authToken);
        for (const el of list) {
            el.answer = tmp.filter((e) => e.informations.questionId === el._id);
        }
    };

    const getQuestions = async () => {
        if (!application || !user) return;
        setIsLoading(true);
        let response = await GetObjectsAll(`UKQuestionLive_${params.idevent}`, application.apiKey, (user || {}).authToken);
        await getAnswer(response);
        setQuestions(response);
        setIsLoading(false);
        if (selectedQuestion) {
            setSelectedQuestion(response.find((e) => e._id === selectedQuestion._id));
        }
    };

    const createQuestion = async () => {
        let newQuestion = { type: "text", question: "", choices: [] };
        await CreateObject(`UKQuestionLive_${params.idevent}`, newQuestion, application.apiKey, (user || {}).authToken);
        await getQuestions();
    };

    const updateQuestion = (key, value) => {
        if (!selectedQuestion) return;
        let newQuestion = questions.find((e) => {
            return e._id === selectedQuestion._id;
        });
        newQuestion.informations[key] = value;
        setQuestions((prevList) => {
            let index = prevList.findIndex((e) => e._id === selectedQuestion._id);
            let newList = [...prevList];
            newList[index] = newQuestion;
            return newList;
        });
    };

    const saveQuestion = async () => {
        await UpdateObject(selectedQuestion._id, `UKQuestionLive_${params.idevent}`, selectedQuestion.informations, application.apiKey, (user || {}).authToken);

        return toast.success("Question sauvegardée", { autoClose: 2000, position: "bottom-center" });
    };

    const deleteQuestion = async () => {
        if (!window.confirm("Voulez-vous supprimer cette question ?")) {
            return;
        }
        await DeleteObject(selectedQuestion._id, `UKQuestionLive_${params.idevent}`, application.apiKey, (user || {}).authToken);
        setQuestions((prevList) => {
            let newList = [...prevList];
            let index = prevList.findIndex((e) => e._id === selectedQuestion._id);
            newList.splice(index, 1);
            return newList;
        });
    };

    return (
        <div className="h-full">
            <p className="text-left text-2xl font-bold py-6 text-gray-800 w-11/12 m-auto">Questions</p>
            <div className="w-11/12 m-auto">
                <div className="flex justify-end">
                    <CommonButton
                        customClassName="h-8 mb-3"
                        useAppTheme={false}
                        icon={<FontAwesomeIcon icon={faBallotCheck} />}
                        title="Créer une question"
                        onClick={() => {
                            createQuestion();
                        }}
                    />
                </div>

                {isLoading && (
                    <svg
                        class="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-300 fill-orange-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                )}

                <div className="space-y-3">
                    {questions &&
                        questions.map((question, index) => {
                            return (
                                <div key={index} className="bg-white border-2 rounded-md">
                                    <div
                                        className="bg-neutral-50 flex w-full justify-between items-center overflow-hidden cursor-pointer"
                                        onClick={() => {
                                            setSelectedQuestion(selectedQuestion === question ? null : question);
                                        }}
                                    >
                                        <p
                                            className={`text-left ml-5 m-2 text-lg max-w-[100ch] overflow-hidden break-words ${
                                                !question.informations["question" + langKey] && !question.informations.question ? "italic" : ""
                                            }`}
                                        >
                                            {question.informations["question" + langKey] || question.informations.question || "Sans titre"}
                                        </p>
                                    </div>
                                    {selectedQuestion && selectedQuestion._id === question._id && (
                                        <div className="m-2 space-y-2">
                                            {/*Send question*/}
                                            <div className=" grid grid-cols-2 w-[400px] gap-2">
                                                {!selectedQuestion.informations.view && (
                                                    <CommonButton
                                                        customClassName="w-max"
                                                        title="Afficher la question"
                                                        icon={<FontAwesomeIcon icon={faEye} />}
                                                        onClick={async () => {
                                                            await UpdateObject(
                                                                selectedQuestion._id,
                                                                selectedQuestion.type,
                                                                { ...selectedQuestion.informations, view: true },
                                                                application.apiKey,
                                                                (user || {}).authToken
                                                            );
                                                            // const response = await UseSocket(`Event/${params.idevent}`, "onQuestionStart", selectedQuestion, application.apiKey);
                                                            //if (response)
                                                            toast.success("Question affiché avec succès !", { autoClose: 2000 });
                                                            getQuestions();
                                                        }}
                                                    />
                                                )}

                                                {selectedQuestion.informations.view && (
                                                    <CommonButton
                                                        customClassName="w-max"
                                                        title="Cacher la question"
                                                        icon={<FontAwesomeIcon icon={faEyeSlash} />}
                                                        onClick={async () => {
                                                            await UpdateObject(
                                                                selectedQuestion._id,
                                                                selectedQuestion.type,
                                                                { ...selectedQuestion.informations, view: false, asked: false },
                                                                application.apiKey,
                                                                (user || {}).authToken
                                                            );
                                                            //const response = await UseSocket(`Event/${params.idevent}`, "onQuestionStart", selectedQuestion, application.apiKey);
                                                            //if (response)
                                                            toast.success("Question caché avec succès !", { autoClose: 2000 });
                                                            getQuestions();
                                                        }}
                                                    />
                                                )}
                                                {!selectedQuestion.informations.asked && (
                                                    <CommonButton
                                                        customClassName="w-max"
                                                        title="Lancer la question"
                                                        icon={<FontAwesomeIcon icon={faSend} />}
                                                        onClick={async () => {
                                                            await UpdateObject(
                                                                selectedQuestion._id,
                                                                selectedQuestion.type,
                                                                { ...selectedQuestion.informations, asked: true, view: true },
                                                                application.apiKey,
                                                                (user || {}).authToken
                                                            );
                                                            const response = await UseSocket(`Event/${params.idevent}`, "onQuestionStart", selectedQuestion, application.apiKey);
                                                            if (response) toast.success("Question lancé avec succès !", { autoClose: 2000 });
                                                            getQuestions();
                                                        }}
                                                    />
                                                )}
                                                {selectedQuestion.informations.asked && (
                                                    <CommonButton
                                                        customClassName="w-max"
                                                        title="Stop la question"
                                                        icon={<FontAwesomeIcon icon={faClose} />}
                                                        onClick={async () => {
                                                            await UpdateObject(
                                                                selectedQuestion._id,
                                                                selectedQuestion.type,
                                                                { ...selectedQuestion.informations, asked: false },
                                                                application.apiKey,
                                                                (user || {}).authToken
                                                            );
                                                            const response = await UseSocket(`Event/${params.idevent}`, "onQuestionStop", selectedQuestion, application.apiKey);
                                                            if (response) toast.success("Question stop avec succès !", { autoClose: 2000 });
                                                            getQuestions();
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            {/*Title*/}
                                            <CustomInputBox
                                                title="Quelle est la question ?"
                                                value={question.informations["question" + langKey] || question.informations.question || ""}
                                                onChange={(value) => {
                                                    updateQuestion("question" + (langKey || ""), value);
                                                }}
                                            />

                                            {/*Answer type*/}
                                            <div className="text-left">
                                                <p className="font-medium text-sm text-left mb-2">Quel est le type de réponse attendue ?</p>
                                                <select
                                                    value={question.informations?.type}
                                                    className="bg-white divide-y px-2 py-1 divide-gray-100 border border-gray-300 rounded"
                                                    onChange={(e) => {
                                                        updateQuestion("type", e.target.value);
                                                    }}
                                                >
                                                    <option className="py-1 text-sm text-gray-800" value="text">
                                                        Texte libre
                                                    </option>
                                                    <option className="py-1 text-sm text-gray-800" value="choice">
                                                        Choix unique
                                                    </option>
                                                    <option className="py-1 text-sm text-gray-800" value="multipleChoice">
                                                        Choix multiple
                                                    </option>
                                                    <option className="py-1 text-sm text-gray-800" value="mark">
                                                        Note sur 5
                                                    </option>
                                                </select>
                                            </div>

                                            {/*Custom answer*/}
                                            {(question.informations?.type === "choice" || question.informations?.type === "multipleChoice") && (
                                                <div className="space-y-3">
                                                    {(question.informations["choices" + langKey] || question.informations?.choices) &&
                                                        (question.informations["choices" + langKey] || question.informations?.choices).map((option, index) => {
                                                            return (
                                                                <div className="flex justify-start items-center">
                                                                    <p className="text-sm text-left ml-4 w-[10%]">Choix n°{index + 1}</p>
                                                                    <input
                                                                        className="custominputbox"
                                                                        value={option}
                                                                        onChange={(e) => {
                                                                            let choices = question.informations["choices" + (langKey || "")]
                                                                                ? [...(question.informations["choices" + langKey] || question.informations?.choices)]
                                                                                : [];
                                                                            let index = (question.informations["choices" + langKey] || question.informations?.choices).findIndex(
                                                                                (e) => e === option
                                                                            );
                                                                            choices[index] = e.target.value;
                                                                            updateQuestion("choices" + (langKey || ""), choices);
                                                                        }}
                                                                    />
                                                                    <CommonButton
                                                                        customClassName="m-1"
                                                                        useAppTheme={false}
                                                                        customColor={"#F87171"}
                                                                        icon={<FontAwesomeIcon className="m-1" icon={faTrash} />}
                                                                        onClick={() => {
                                                                            let index = (question.informations["choices" + langKey] || question.informations?.choices).findIndex(
                                                                                (e) => e === option
                                                                            );
                                                                            let newOptions = question.informations["choices" + langKey] || question.informations?.choices;
                                                                            newOptions.splice(index, 1);
                                                                            updateQuestion("choices" + (langKey || ""), newOptions);
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    <CommonButton
                                                        customClassName="w-max"
                                                        title="Ajouter un choix"
                                                        useAppTheme={false}
                                                        onClick={() => {
                                                            let choices = question.informations["choices" + (langKey || "")]
                                                                ? [...(question.informations["choices" + langKey] || question.informations?.choices)]
                                                                : [];
                                                            choices.push("");
                                                            updateQuestion("choices" + (langKey || ""), choices);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex gap-2 items-center">
                                                <CommonButton
                                                    customClassName="w-max h-max"
                                                    useAppTheme={false}
                                                    title="Sauvegarder"
                                                    icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                                    onClick={saveQuestion}
                                                />
                                                <CommonButton
                                                    customClassName="w-max h-max"
                                                    useAppTheme={false}
                                                    customColor={"#F87171"}
                                                    title="Supprimer"
                                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                                    onClick={deleteQuestion}
                                                />
                                                <CommonButton
                                                    customClassName="w-max h-max"
                                                    useAppTheme={false}
                                                    title="Afficher les résultats"
                                                    icon={<FontAwesomeIcon icon={faDatabase} />}
                                                    onClick={() => {
                                                        window.open(`/questionresult/${question._id}?answer=${question.type.split("_")[1]}`, "_blank");
                                                    }}
                                                />
                                            </div>
                                            {question.informations?.type === "mark" && <MyStarRating ans={question.answer} showTotal={true} />}
                                            {(question.informations?.type === "choice" || question.informations?.type === "multipleChoice") && <MyCamembert question={question} />}
                                            {question.informations?.type === "text" && <TextResult ans={question.answer} />}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default QuestionsView;
