import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {useContext, useEffect} from "react";
import {ApplicationContext} from "../../Context/ApplicationContext";
import {Helmet} from "react-helmet";
import ObjectIframeComponent from "./Object/object.iframe.component";
import EventIframeComponent from "./Event/event.iframe.component";

function IframeComponent() {
  let {path, params} = useRouteMatch();
  const {application, applicationConfig, reloadApplication} = useContext(ApplicationContext);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    reloadApplication(urlParams.get('apikey'), false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (!urlParams.has('apikey')) {
    return (<Redirect to={'/404'} />);
  }

  if (application == null || applicationConfig == null) {
    return <p></p>;
  }

  return (
    <div>
      <Helmet>
        <title>{application.name}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <Redirect to={'/404'} />
        </Route>
        <Route path={`${path}object`}>
          <ObjectIframeComponent params={urlParams} />
        </Route>
        <Route path={`${path}ukEvent`}>
          <EventIframeComponent params={urlParams} />
        </Route>
      </Switch>
    </div>
  );
}

export default IframeComponent;
