import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationContext } from '../../Context/ApplicationContext';
import { UserContext } from '../../Context/UserContext';
import LanguageSelectorIcon from './language.selector';
import SearchBarComponent from './SearchBar/SearchBarComponent';

function HeaderComponent() {
  const history = useHistory();
  const { application, config, menuOpen, setMenuOpen } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);

  if (!config) return <div></div>;

  var background = '';
  if (user != null) {
    // console.log(user)
    background = user.informations?.picture || "";
  }
  if (background == null) {
    var f = user.informations.firstName || ' ';
    var l = user.informations.lastName || ' ';
    background =
      'https://eu.ui-avatars.com/api/?name=' +
      f +
      '+' +
      l +
      '&size=512&background=' +
      config.mainColor.replace('#', '') +
      '&color=' +
      config.colorOverMainColor.replace('#', '');
  }

  return (
    <div className="flex-1 flex flex-col">
      <nav className="px-4 grid grid-cols-3 justify-between bg-white h-16 border-b-2">
        <ul className="flex items-center">
          <FontAwesomeIcon
            className="h-4 w-4"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            icon={menuOpen ? faClose : faBars}
          />
          <li
            className="h-12 w-12 cursor-pointer ml-5 flex items-center justify-center text-2xl font-bold text-gray-700"
            onClick={() => {
              if (user) {
                history.push(`/v/account`);
              }
            }}
          >
            {application.informations.name}
          </li>
        </ul>

        <ul className="flex items-center text-center justify-center">
          <SearchBarComponent />
        </ul>

        <ul className="flex items-center justify-end">
          <LanguageSelectorIcon />
          {user && (
            <li
              className="h-10 w-10 cursor-pointer"
              onClick={() => {
                if (user) {
                  history.push(`/v/account`);
                }
              }}
            >
              <img className="h-full w-full rounded-full mx-auto" src={background} alt="profile woman" />
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default HeaderComponent;
