import { useContext, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContext"
import { UserContext } from "../../Context/UserContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	useHistory,
} from "react-router-dom";

function LoginFormComponent({ registerAction }) {

	const { application } = useContext(ApplicationContext)
	const { config } = useContext(ApplicationContext)
	const { adminLoginUser } = useContext(UserContext)
	let history = useHistory();
	const [form, setForm] = useState({ email: "", password: "" })

	const errorLogin = () => toast.error("Email / Mot de passe incorect", { autoClose: 2000, position: "bottom-center" })
	const handleSubmit = async (evt) => {
		evt.preventDefault()
		const user = await adminLoginUser(form.email, form.password)
		// console.log(user);
		if (user == null) {
			errorLogin();
			return;
		}
		if (user?.eventkey !== undefined && user?.eventkey === false) return toast.error("Aucun compte inscrit à cette application", { autoClose: 2000, position: "bottom-center" });
		if (user?.eventkey) return history.push('/eventkey/home');
		history.push(`/v/home`)
	}

	return (

		<div className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0" >

			<div className="w-full py-6 z-20">
				<h1 className="my-6  w-full text-center text-gray-700">
					<img alt="" src={application?.informations?.logo} className=" sm:hidden w-auto h-20 sm:h-40 inline-flex" />
					<p className=" text-2xl font-bold">Connexion</p>
				</h1>
				<form onSubmit={handleSubmit} className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto">
					<div className="pb-2 pt-4">

						<input
							type="email" name="email" id="email" placeholder="Email"
							className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
							value={form.email} onChange={e => setForm({ ...form, email: e.target.value })} />
					</div>
					<div className="pb-2 pt-4">
						<input
							className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
							type="password" name="password" id="password" placeholder="mot de passe" value={form.password} onChange={e => setForm({ ...form, password: e.target.value })} />
					</div>
					<div className="hidden text-right text-gray-400 hover:underline hover:text-gray-100">
						<p>Mot de passe oublié ?</p>
					</div>
					<div className=" pb-2 pt-4">
						<button className="w-full bg-gray-800 hover:bg-grey-900 text-white text-sm py-2 px-4 font-semibold rounded focus:outline-none focus:shadow-outline h-10"
							style={{
								color: (config || {}).colorOverMainColor ?? "#FFFFFF",
								backgroundColor: (config || {}).mainColor ?? "#000000",
							}}
						>

							Se connecter
						</button>
						<p onClick={registerAction} className="text-sm mt-2 cursor-pointer" >
							S'inscrire
						</p>
					</div>


				</form>
			</div>
		</div>

	);
}

export default LoginFormComponent;
