import './AppDesign.css';

export default function IphonePreview({children}) {
  return (
    <div className="iphone-x">
      <div className="side">
        <div className="screen">
          <div className="bg-gray-100 overflow-scroll h-full">{children}</div>
        </div>
      </div>
      <div className="line"></div>
      <div className="header">
        <div className="sensor-1"></div>
        <div className="sensor-2"></div>
        <div className="sensor-3"></div>
      </div>
      <div className="volume-button"></div>
      <div className="power-button"></div>
    </div>
  );
}
