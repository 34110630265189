import React from "react";

const CustomNumberInput = ({title, onChange, value, min}) => {
  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">{title}</p>
      <input
        className="custominputbox"
        type="number"
        min={min}
        value={value}
        onChange={(e) => {onChange(e.target.value)}}
      />
    </div>
  );
}

export default CustomNumberInput
