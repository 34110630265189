import {useContext} from "react";
import {ApplicationContext} from "../../../Context/ApplicationContext";

function DefaultProfilPicture({name}) {
  const firstLetter = name.toUpperCase()[0];

  return (
    <div className={'w-3/4 h-32 bg-gray-100 justify-center items-center mb-2 rounded flex'}>
      <h1 className={"text-2xl font-semibold"}>{firstLetter}</h1>
    </div>
  )
}

function DrawObjectCard({elem, setModalOpen}) {
  const {language} = useContext(ApplicationContext);
  const title = elem.getTitle(language);
  const subtitle = elem.getSubtitle(language);

  if (!title && !subtitle) {
    return;
  }

  return (
    <div className={'my-5 rounded border-stone-200 border-2 p-5 w-64 mx-5 border-gray-300 bg-white break-all h-max flex flex-col justify-center items-center hover:bg-opacity-50 cursor-pointer'}
         onClick={() => setModalOpen({open: true, info: elem})}>
      <DefaultProfilPicture name={title ?? subtitle}/>
      {title && <p className={'text-slate-700 font-semibold'}>{title}</p>}
      {subtitle && <p className={'text-slate-500 font-light'}>{subtitle}</p>}
    </div>
  )
}

export default function DrawListOfCard({list, setModalOpen}) {
  return list.map((elem, index) => {
      return (<DrawObjectCard elem={elem} key={index} setModalOpen={setModalOpen}/>)}
    );
}