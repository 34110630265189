import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import DocsIcon from "./docs.icon";
import DocsLargeImage from "./docs.largeimage";

const ItemTypes = {
    CARD: "card",
};

const DocsCell = ({ doc, id, index, setFolderStack, handleOnDocEdit, handleOnDocMoves }) => {
    const { langKey } = useContext(ApplicationContext);
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            handleOnDocMoves(dragIndex, hoverIndex, false);
            item.index = hoverIndex;
        },
        drop() {
            handleOnDocMoves(null, null, true);
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const handleOnClick = () => {
        if (doc.informations.type === "cat") {
            setFolderStack((prevStack) => {
                if (prevStack[0] !== doc._id) {
                    return [doc._id, ...prevStack];
                }
                return prevStack;
            });
        }
    };

    console.log("icicicijed", doc.informations.imageStyle);
    return (
        <div
            className="flex cursor-pointer mt-2 mb-2 ml-1 mr-1 bg-white"
            key={doc._id}
            ref={ref}
            data-handler-id={handlerId}
            style={{
                minHeight: "50px",
                padding: "0.2em",
                opacity: opacity,
            }}
        >
            <div className="flex items-center w-full relative">
                <div
                    className="flex justify-start items-center overflow-hidden w-full"
                    onClick={() => {
                        handleOnClick();
                    }}
                >
                    {(doc.informations.imageStyle === "small_icon" || doc.informations.imageStyle == "small") && <DocsIcon doc={doc} />}
                    {doc.informations.imageStyle === "large" && <DocsLargeImage doc={doc} />}
                    {(doc.informations.imageStyle === "small_icon" || doc.informations.imageStyle == "small") && (
                        <div className="ml-2">{doc.informations?.["title" + langKey] || <p className="italic text-sm">Pas de titre</p>}</div>
                    )}
                </div>
                <div className="absolute opacity-0 hover:opacity-100 transition-all hover:bg-opacity-70 hover:bg-gray-200 w-full h-full flex justify-around items-center">
                    {doc.informations.type === "cat" && (
                        <div className="flex flex-col items-center justify-center text-sm text-gray-800 hover:opacity-60 cursor-pointer w-full h-full" onClick={handleOnClick}>
                            <FontAwesomeIcon className="text-xl mb-1" icon={faFolderOpen}></FontAwesomeIcon>
                            Parcourir
                        </div>
                    )}
                    {doc.informations.type === "cat" && (
                        <div
                            className="bg-gray-800 h-3/4 w-full"
                            style={{
                                maxWidth: "1px",
                            }}
                        ></div>
                    )}
                    <div
                        className="flex flex-col items-center justify-center text-sm text-gray-800 hover:opacity-60 cursor-pointer w-full h-full"
                        onClick={() => {
                            handleOnDocEdit(doc);
                        }}
                    >
                        <FontAwesomeIcon className="text-xl mb-1" icon={faPencil}></FontAwesomeIcon>
                        Modifier
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocsCell;
