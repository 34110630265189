import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FileSelector } from 'react-rainbow-components';
import * as XLSX from 'xlsx';
import { ApplicationContext } from '../../../Context/ApplicationContext';

export default function ImportExcelSelectType({ allData, setSelectType }) {
  const { config } = useContext(ApplicationContext);
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);
  const [tmpSelectType, setTmpSelectType] = useState('');
  const getPreview = (index) => {
    var i = 0;
    var result = '';
    while (i < 3 && i < data.length) {
      if (i > 0) {
        result += ' , ';
      }
      result += data[i][index];
      i += 1;
    }
    return result;
  };
  useEffect(() => {
    setHeader(allData[0]);
    var arr = [...allData];
    arr.splice(0, 1);
    setData(arr);
  }, [allData]);
  useEffect(() => {}, [allData]);

  return (
    <div className="text-gray-700">
      <div className="grid grid-cols-2 gap-2 mt-3 ">
        <div>
          <p className="bg-blue-100 border border-blue-300 text-blue-400 px-2 rounded-t">Nom des colonnes</p>
          {header.map((e, index) => {
            return (
              <div
                className={
                  'border-l border-r border-b border-gray-300 px-2 py-1 ' +
                  (index == header.length - 1 ? ' rounded-b' : '')
                }
              >
                <p>{e}</p>
                <p
                  className="text-sm font-light italic"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {getPreview(index)}
                </p>
              </div>
            );
          })}
        </div>
        <div>
          <p>Quel type d'objet voulez vous importer ?</p>
          <select
            value={tmpSelectType}
            onChange={(e) => {
              // console.log(e.target.value);
              setTmpSelectType(e.target.value);
            }}
            className="border border-gray-300 px-2 py-2 w-full my-2 rounded"
          >
            <option value={''}>Choisir</option>
            {config.customObject.map((e) => {
              return <option value={e.id}>{e.name}</option>;
            })}
          </select>
          <div
            className={
              'bg-blue-100 text-center rounded border border-blue-300 text-blue-400 ' +
              (tmpSelectType == '' ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-50 cursor-pointer ')
            }
            onClick={() => {
              if (tmpSelectType == '') {
                return;
              }
              setSelectType(tmpSelectType);
            }}
          >
            Suivant <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
    </div>
  );
}
