import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../../Context/UserContext';
import { GetUKObjectById } from '../../../../../../Services/UKObject';
import ObjectsView from "../../../../Objects/objects.view";
import CustomModal from "../../../../../Common/CustomModal.component";
import CustomObjectCellVertical from "../../../../../Common/CustomObjectCellVertical";
import TrashButton from "../../../../../Common/Button/TrashButton";

export default function ModuleConfigurationOneObject({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);
  const [objectList, setObjectList] = useState(null);
  const { user } = useContext(UserContext);
  var typeValid = ['OBJECTDETAIL'];
  const [modalOpen, setModalOpen] = useState(false);
  const [filterList, setFilterList] = useState(null);
  const [object, setObject] = useState(null);

  const reloadObject = async () => {
    if (module.url && module.id) {
      var obj = await GetUKObjectById(module.url, application.apiKey, user.authToken);
      setObject(obj);
    }
  };
  useEffect(() => {
    if (!config) return;
    let select = config.customObject.find((e) => e.id === module.id);
    if (select) setFilterList(select.custom);
    else setFilterList(null);
    setObject(null);
    reloadObject();
    // eslint-disable-next-line
  }, [config, module, user, application]);

  useEffect(() => {
    if (!config) return;
    let objects = config.customObject.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    });
    setObjectList(objects);
  }, [config]);
  if (typeValid.indexOf(module.type) == -1) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quelle type d'objet souhaitez-vous afficher ?</p>
        {objectList && (
          <div className="" style={{ width: 300 }}>
            <Select
              options={objectList}
              onChange={(e) => {
                setModule({ ...module, id: e.value, group_by: null, url: null});
              }}
              value={objectList.find((e) => e.value == module.id) || null}
            />
          </div>
        )}
      </div>

      {module.id && (
        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Quel objet souhaitez-vous afficher dans cette liste ?</p>
          <div className="" style={{ width: 300 }}>
            {object && (
              <div className="relative p-0">
                <div className="absolute right-0 top-0">
                  <TrashButton
                    className="px-2 py-1"
                    onClick={() => {
                      setModule({...module, url: null});
                      setObject(null);
                    }}
                  />
                </div>
                <CustomObjectCellVertical
                  object={object}
                  type={module.id}
                  className={'cursor-pointer hover:opacity-80'}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                />
                </div>
            )}
            {!object && (
              <div
                onClick={() => {
                  setModalOpen(true);
                }}
                className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
              >
                Ajouter <FontAwesomeIcon icon={faPlus} />
              </div>
            )}
            <CustomModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
              <div className={'w-full'}>
                <ObjectsView typeobject={module.id} onlySelectObject={true} onPress={(e) => {
                  setObject(e._id);
                  setModule({...module, url: e._id});
                  setModalOpen(false);
                }}/>
              </div>
            </CustomModal>
          </div>
        </div>
      )}
    </div>
  );
}
