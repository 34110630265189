import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import TrashButton from "../../../../../Common/Button/TrashButton";
import CustomObjectCellVertical from "../../../../../Common/CustomObjectCellVertical";
import CustomModal from "../../../../../Common/CustomModal.component";
import EventsTableView from "../../../../Events/events.table.view";
import UsersView from "../../../../Users/users.view";
import CustomUsersCellVertical from "../../../../User_old/CustomUsersCellVertical";
import UsersTable from "../../../../Users/users.table";
import Select from "react-select";
import {ApplicationContext} from "../../../../../../Context/ApplicationContext";

export default function ModuleConfigurationOneUser({ module, setModule, className = '' }) {
  const {applicationConfig} = useContext(ApplicationContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [object, setObject] = useState(module.url ?? null);
  const [type, setType] = useState(module.id ?? null);

  const typeValid = ['USERDETAIL'];

  useEffect(() => {
    let userList = applicationConfig.json.inscription.customs;
    userList = userList.map(e => {
      return {
        value: e.id,
        label: e.name,
      };
    })
    setUserInfo(userList);
  }, [])

  if (typeValid.indexOf(module.type) === -1) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quelle type d'utilisatuer souhaitez-vous afficher ?</p>
        {userInfo && (
          <div className="" style={{width: 300}}>
            <Select
              options={userInfo}
              onChange={(e) => {
                setModule({...module, id: e.value, group_by: null, url: null});
                setType(e.value);
              }}
              value={userInfo.find((e) => e.value == module.id) || null}
            />
          </div>
        )}
      </div>
      {type && (

        <div className="py-2">
          <p className="font-medium text-sm text-left mb-2">Quelle utilisateur souhaitez-vous afficher ?</p>
          <div className="" style={{width: 300}}>
            {!object ? (
              <div
                onClick={() => {
                  setModalOpen(true);
                }}
                className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
              >
                Ajouter <FontAwesomeIcon icon={faPlus}/>
              </div>
            ) : (
              <div className="relative p-0">
                <div className="absolute right-0 top-0">
                  <TrashButton
                    className="px-2 py-1"
                    onClick={() => {
                      setObject(null);
                      setModule({...module, url: null});
                    }}
                  />
                </div>
                <CustomUsersCellVertical id={object} />
              </div>
            )}
          </div>
        </div>
      )}

      <CustomModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={'w-full h-max mt-8'}>
          <UsersView onlySelectUser={true} typeObject={type} onPress={(props) => {
            setModule({...module, url: props._id});
            setObject(props._id);
            setModalOpen(false);
          }} />
        </div>
      </CustomModal>
    </div>
  );
}
