import {faEye} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/fr';
import {useContext, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {ApplicationContext} from '../../../../../Context/ApplicationContext';
import {UserContext} from '../../../../../Context/UserContext';
import {GetTickets} from '../../../../../Services/Billetterie';
import AddButton from '../../../../Common/Button/AddButton';
import CloseButton from '../../../../Common/Button/CloseButton';
import TicketDetailComponent from './TicketDetailComponent';
import TicketStyle from './TicketStyle';
import {faGear} from "@fortawesome/pro-solid-svg-icons";
import CustomModal from "../../../../Common/CustomModal.component";
import {useParams} from "react-router-dom";
import TicketConfiguration from "./TicketConfiguration";
import {faCopy, faFrame} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";

const DateTicketComponent = ({value}) => {
  var date = moment(value);
  return <div>{date.format('YYYY-MM-DD HH:mm')}</div>;
};

export default function TicketsComponent() {
  const {application, config} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const params = useParams();
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketStyleOpen, setTicketStyleOpen] = useState(false);
  const [configIsOpen, setConfigIsOpen] = useState(false);
  const [customConfig, setCustomConfig] = useState(null);
  const [iframeModalIsOpen, setIFrameModalIsOpen] = useState(false);

  useEffect(() => {
    if (!params || !config) return;
    const conf = config.billetterie;
    if (!conf) {
      setCustomConfig({});
      return;
    }
    setCustomConfig(conf);
  }, [params, config]);

  const reloadTickets = async () => {
    setIsLoading(true);
    var tickets = await GetTickets(application.apiKey, user.authToken);
    setTickets(tickets);

    setIsLoading(false);
  };

  useEffect(() => {
    reloadTickets();
  }, []);

  return (
    <div>
      <div className='w-11/12 m-auto'>
        <div className="h-10 px-2">
          <div className={'float-right flex items-end flex-col gap-2'}>
            <AddButton title="Créer un billet" onClick={() => setSelectedTicket({})}/>

            <AddButton title="Ouvrir la billetterie" icon={faEye} onClick={() => {
              window.open("/billetterie")
            }}/>

            <AddButton title="Configuration" icon={faGear} onClick={() => {
              setConfigIsOpen(true)
            }}/>

            <AddButton title="IFrame" icon={faFrame} onClick={() => {
              setIFrameModalIsOpen(true)
            }}/>
          </div>
        </div>
        <p className="text-xl font-medium mt-5 mb-2 text-left ml-2">Vos billets</p>
        <div className="mx-2 border-gray-300 border-l border-r border-b border-t rounded bg-white">
          <table className="w-full">
            <thead className="bg-gray-100  text-left text-gray-700 font-black text-sm flex justify-center py-2">
            <tr className="w-full px-2">NOM</tr>
            <tr className="w-full px-2">DATE</tr>
            </thead>
            <tbody className="text-sm text-left w-full">
            {tickets.map((e) => {
              return (
                <tr
                  onClick={() => {
                    setSelectedTicket(e);
                  }}
                  className="flex py-2 text-gray-600 hover:bg-gray-50 cursor-pointer border-b border-gray-300"
                >
                  <td className="w-full px-2">{e.name}</td>
                  <td className="w-full px-2">02/12/1993</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={selectedTicket != null}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 20,
            minWidth: '80%',
            maxWidth: '90%',
            maxHeight: '90%',
          },
        }}
      >
        <CloseButton
          className={'float-right'}
          onClick={() => {
            setSelectedTicket(null);
          }}
        />

        <TicketDetailComponent
          ticket={selectedTicket}
          onReload={() => {
            reloadTickets();
            setSelectedTicket(null);
          }}
        />
      </Modal>

      <Modal
        isOpen={ticketStyleOpen}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 20,
            minWidth: '80%',
            maxWidth: '90%',
            maxHeight: '90%',
          },
        }}
      >
        <CloseButton
          className={'float-right'}
          onClick={() => {
            setTicketStyleOpen(false);
          }}
        />

        <TicketStyle/>
      </Modal>

      <CustomModal title="Configuration des billets" onClose={() => setConfigIsOpen(false)} isOpen={configIsOpen}>
        <TicketConfiguration customs={customConfig} setCustoms={setCustomConfig}
                             onClose={() => setConfigIsOpen(false)}/>
      </CustomModal>

      <CustomModal title="Iframe billeterie" onClose={() => setIFrameModalIsOpen(false)} isOpen={iframeModalIsOpen}>
        <p className="text-sm text-gray-700">Intégrer ce code à votre site</p>
        <pre className="text-left bg-gray-700 text-white w-full rounded p-2 relative">
          {"<iframe id=\"iframeUsekey\"\n" +
            "    title=\"Billetterie\"\n" +
            "    width=\"100%\"\n" +
            "    height=\"500\"\n" +
            `    src="https://${window.location.host}/billetterie">` +
            "</iframe>"}
          <FontAwesomeIcon icon={faCopy}
                           size="2x"
                           className="text-whites absolute right-2 bottom-2 hover:opacity-50 cursor-pointer"
                           onClick={() => {
                             navigator.clipboard.writeText("<iframe id=\"iframeUsekey\"\n" +
                               "    title=\"Billetterie\"\n" +
                               "    width=\"100%\"\n" +
                               "    height=\"500\"\n" +
                               `    src="https://${window.location.host}/billetterie">` +
                               "</iframe>").then(function () {
                               toast.success("Copié dans le presse-papier", {autoClose: 2000})
                             }, function () {
                               toast.error("Impossible de copier dans le presse-papier", {autoClose: 2000})
                             });
                           }}/>
        </pre>
      </CustomModal>
    </div>
  );
}
