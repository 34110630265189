import { useState } from "react";

const LocalizationTable = ({languages, localizations, setLocalizations, loading, isSaving}) => {
  const [selectedCell, setSelectedCell] = useState(null);

  const tdStyle = (index) => {
    return (
      {
        color: "#555555",
        padding: "3px 10px",
        background: index % 2 === 0 ? "#F6F6F6" : "",
        whiteSpace: "nowrap",
        maxWidth: "50ch",
        overflow: "hidden"
      }
    );
  }

  const modifyContent = (event, cell) => {
    if (cell === selectedCell) return;
    if (event.detail === 2) {
      setSelectedCell(cell);
      return;
    }
    setSelectedCell(null);
  }

  const renderCell = (langKey) => {
    return (
      <div className="bg-white border rounded w-full h-full m-2">
        <input className="custominputbox w-full h-full"
               style={{
                 backgroundColor: "white",
               }}
               type="text"
               value={selectedCell.informations[langKey]}
               onChange={(e) => {
                 setSelectedCell((prevCell) => {
                   return (
                     {...prevCell, informations: {...prevCell.informations, [langKey]: e.target.value}}
                   );
                 })
                 setLocalizations((prevLocalizations) => {
                   let newLocalizations = prevLocalizations;
                   newLocalizations.forEach(elem => {
                     if (elem._id === selectedCell._id)
                       elem.informations[langKey] = e.target.value;
                   })
                   return newLocalizations;
                 })
               }}></input>
      </div>
    );
  }

  return (
    <div className="bg-gray-200 w-full text-left border border-gray-300 border-separate rounded-md" style={{
      overflowX: "auto",
      display: "block",
    }}>
      <table style={{minWidth: "100%"}}>
        <thead className="bg-white border-none text-base rounded-md">
        <tr>
          <th className="text-xs text-gray-500 font-semibold" style={{
            padding: "3px 10px"
          }}>Key
          </th>
          {languages && languages.map((row) => {
            return (
              <th className="text-xs text-gray-500 font-semibold" style={{
                padding: "3px 10px"
              }}>{row.name}</th>
            )
          })}
        </tr>
        </thead>
        <tbody className="text-xs text-left bg-white">
        {!isSaving && localizations && localizations.map((e, index) => {
          return (
            <tr className="h-10 hover:opacity-60">
              <td className="text-xs cursor-pointer"
                  style={tdStyle(index)}>{e.key}</td>
              {languages.map((key) => {
                return (
                  <td className="text-xs cursor-pointer"
                      style={tdStyle(index)} onClick={() => {
                    modifyContent(window.event, e, key.langKey)
                  }}>
                    {selectedCell && e._id === selectedCell._id && renderCell(key.langKey)}
                    {(!selectedCell || e._id !== selectedCell._id) && <p>{e.informations[key.langKey]}</p>}</td>
                )
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      {(!localizations || localizations.length === 0) && <p className="text-gray-800 font-bold text-center">Aucun élément trouvé</p>}
      {loading && <p className="text-gray-800 font-bold text-center">Chargement...</p>}
      {isSaving && <div className="flex justify-center items-center mt-5 mb-5">
        <div role="status">
          <svg class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-300 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>}
    </div>
  );
}

export default LocalizationTable
