import { useContext, useEffect, useState } from "react";
import { GetObjectByIds, GetObjectsAll, GetUKObjectById } from "../../../Services/UKObject";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { UKObjectClass } from "../../../Class/UKObjectClass";

const VotesResultsView = ({voteInfo, pageState}) => {
  const [voteResults, setVoteResults] = useState([]);
  const {application, config, language} = useContext(ApplicationContext)
  const {user} = useContext(UserContext);

  const setObjectTitle = async (votes) => {
    const idsList = votes.map(e => e.elected);
    const objs = await GetObjectByIds(application.apiKey, voteInfo.informations.optionType, idsList, null);

    objs.forEach((e) => {
      const objClass = new UKObjectClass(e, config);
      const title = objClass.getTitle(language);
      const index = votes.findIndex((res) => res.elected === e._id)

      votes[index].title = title;
    })
    return votes;
  }

  const sortResults = (votes) => {
    const results = votes.reduce((acc, obj) => {
      const existingObj = acc.find(({elected}) => obj.informations.elected === elected);
      if (existingObj) {
        existingObj.count++;
      } else {
        acc.push({
          elected: obj.informations.elected,
          count: 1,
        })
      }
      return acc;
    }, [])
    results.sort((a, b) => a.count < b.count ? 1 : -1);
    return results;
  }

  const getResults = async () => {
    try {
      let newVoteResult = await GetObjectsAll(`UKVotes_${voteInfo._id}`, application.apiKey, (user || {}).authToken)
      newVoteResult = sortResults(newVoteResult);
      if (voteInfo.informations.type === 'object') {
        newVoteResult = await setObjectTitle(newVoteResult);
      }
      setVoteResults(newVoteResult);
    } catch (e) {
    }
  }

  useEffect(() => {
    if (pageState !== 2) return;
    getResults();
  }, [pageState]);

  let maxCount;
  return (
    <div className={`flex flex-col text-left overflow-hidden ${pageState === 2 ? "w-full px-2 py-4" : "w-0 h-0 transition-all duration-200"}`}>
      <p className="text-lg text-gray-700">Résultats</p>

      <div className="flex justify-center">
        <div className="w-11/12 border rounded mt-4">
          {voteResults && voteResults.length > 0 && voteResults.map((e, index) => {
            maxCount = index === 0 ? e.count : maxCount
            const color = index === 0 ? '#FFD3007A' : index === 1 ? '#8181817A' : index === 2 ? '#DC81257A' : '#0794D559'
            return (
              <div
                key={index}
                className="w-full relative">
                <div className="m-1 mt-2 mb-2 rounded" style={{
                  backgroundColor: color,
                  maxWidth: `${e.count * 100 / maxCount}%`
                }}>&nbsp;</div>
                <p className="absolute top-0 left-0 ml-3 text-ellipsis overflow-hidden">{`${index + 1}e \xa0\xa0 ${e.title ? e.title.substring(0, 50) : e.elected || 'Inconnu'} : ${e.count} ${e.count > 1 ? 'votes' : 'vote'}`}</p>
              </div>
            );
          })}
          {!voteResults || voteResults.length === 0 && <p className="text-sm italic ml-2">Aucun résulat pour le moment</p>}
        </div>
      </div>
    </div>
  );
}

export default VotesResultsView
