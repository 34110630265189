import moment from 'moment'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import GL, { WidthProvider } from 'react-grid-layout'
import { AiOutlinePlus } from 'react-icons/ai'
import { Button } from 'react-rainbow-components'
import { v4 } from 'uuid'
import CreateModuleModalComponent from './CreateModuleModalComponent'

const GridLayout = WidthProvider(GL)

export default function TabBarComponent({ preview, lang, data, setData, setClickedData, clickedData, groupList }) {
	const isDragging = useRef(false)
	const [tabData, setTabData] = useState([])
	const [isOpen, setIsOpen] = useState(false)

	const compactArray = (array) => {
		let newArr = [...array]
		newArr.sort((a, b) => a.x - b.x)
		for (let i = 0; i < newArr.length; i++) {
			let e = newArr[i];
			if (e.x !== i) {
				if (i > 0 && newArr[i - 1]?.x === e.x) {
					newArr[i - 1].x = i
					e.x = i - 1
				} else if (newArr[i + 1]?.x === e.x) {
					newArr[i + 1].x = i
					e.x = i + 1
				}
			}
		}
		return newArr
	}

	useEffect(() => {
		setTabData(data.filter((e) => {
			if (preview.group === "Tous")
				return true
			if (e.visibleFrom && preview.hour) {
				if (!moment(preview.hour).isBetween(moment(e.visibleFrom), moment(e.visibleTo)))
					return false
			}
			if (preview.group === "Non connecté") {
				if (e.state && e.state.split(',').find((e) => e === "0"))
					return true
				return false
			}
			if (preview.group === "Connecté") {
				if (e.state && e.state.split(',').find((e) => e === "1"))
					return true
				return false
			}
			if (e.groupRestricted && preview.group) {
				let groups = e.groupRestricted.split(',')
				if (groups.find((el) => el === groupList.find((ele) => ele.id === preview.group).index))
					return true
				return false
			}
			return true
		}))
		// eslint-disable-next-line
	}, [data, preview])

	return (
		<>
			<div className='w-full flex items-center justify-center'>
				<GridLayout
					cols={tabData.length}
					maxRows={1}
					layout={tabData.map((e) => ({ x: e.x, y: e.y, w: e.width, h: 0.5, minH: 0.5, maxH: 0.5, static: ((preview.group !== null && preview.group !== "Tous") || preview.hour !== null), isResizable: false }))}
					style={{ width: '100%' }}
					onLayoutChange={(layout) => {
						if (tabData.length === 0) return
						let arr = compactArray(layout)
						let newData = []
						arr.forEach((e) => {
							let tempData = tabData.find((el) => el._id === e.i)
							tempData.width = e.w
							tempData.x = e.x
							tempData.y = e.y
							newData.push(tempData)
						})
						setData(newData)
						if (clickedData) {
							let newClickedData = { ...clickedData }
							newClickedData.index = newData.findIndex((e) => e._id === clickedData._id)
							if (newClickedData.index !== undefined)
								setClickedData(newClickedData)
						}
					}}
					onDrag={() => {
						isDragging.current = true
					}} onDragStop={() => {
						setTimeout(() => {
							isDragging.current = false
						}, 500)
					}}
				>
					{tabData.map((e, index) => {
						return (
							<div onClick={() => {
								if (!isDragging.current) {
									setClickedData({ ...e, index: index })
									window.scrollTo(0, 0)
								}
							}}
								style={{ maxWidth: '17%' }}
								className="bg-gray-50" key={e._id} data-grid={{ x: e.x, y: e.y, w: e.width, h: 0.5, minH: 0.5, maxH: 0.5, static: ((preview.group !== null && preview.group !== "Tous") || preview.hour !== null), isResizable: false }}>
								<div
									className='w-full h-3/4'
									style={{ backgroundImage: `url("/icons/${e[lang ? `image_${lang.id}` : 'image']}.png")`, backgroundSize: '60%', backgroundPosition: "center", backgroundRepeat: 'no-repeat' }}
								/>
								<div className='flex justify-center items-center text-xs'>
									<p>
										{e.text || ""}
									</p>
								</div>
							</div>
						)
					})}
				</GridLayout>
			</div>
			{((preview.group === null || preview.group === "Tous") && preview.hour === null) && <Button
				label={<AiOutlinePlus />}
				onClick={() => {
					setIsOpen(true)
				}}
				style={{ color: '#413751', width: '95%' }}
				variant='border-filled'
				className='h-10'
			/>}
			<CreateModuleModalComponent isOpen={isOpen} onUpdate={(newData) => {
				if (newData)
					setData([...data, { ...newData, x: data.length, y: 1, width: 1, tabBar: true, _id: v4() }])
				setIsOpen(false)
			}} />
		</>
	)
}