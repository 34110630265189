import Calendar from "@toast-ui/react-calendar";
import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import RadioButton from "./radio.button";
import DayButtonDraw from "./day.button.draw";
import {ApplicationContext} from "../../../../Context/ApplicationContext";

export default function CalendarDrawEvent({config, objectList, setmodalIsOpen}) {
  const [view, setView] = useState("week");
  const {langKey} = useContext(ApplicationContext);
  const calendarRef = useRef(null);
  const myTheme = {
    'common.holiday.color': '#00000',
    'week.dayname.textAlign': 'center',
    'week.timegridOneHour.height': (config && config.website.event.timeGridOneHourHeight) || '35px',
    'week.timegridHalfHour.height': (config && config.website.event.timeGridHalfHourHeight) || '40px',
  };
  const views = ['day', 'week', 'month'];
  const eventDays = [...new Set(objectList.map(e => moment(e.json.informations.dateDebut).format("MMM Do YY")))];

  useEffect(() => {
    calendarRef.current && calendarRef.current.getInstance().setDate(new Date(objectList[0].json.informations.dateDebut));
  }, [])

  return (
    <div className={'w-full bg-gray-100'}>
      <RadioButton views={views} view={view} setView={setView}/>
      <DayButtonDraw eventDays={eventDays} calendarRef={calendarRef}/>
      <div className={'h-5/6 w-full flex justify-center'}>
        <Calendar
          height={'100%'}
          month={{
            daynames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            startDayOfWeek: 1,
            visibleScheduleCount: true,
          }}
          template={{
            timegridDisplayPrimaryTime: (tz) => {
              var e = tz.time.toDate();
              return e.getHours() + 'h' + (e.getMinutes() > 0 ? (e.getMinutes() < 10 ? '0' : '') + e.getMinutes() : '00');
            },
          }}
          ref={calendarRef}
          useCreationPopup={false}
          usageStatistics={false}
          isReadOnly={true}
          theme={myTheme}
          events={
            objectList.map(elem => {
              const info = elem.json.informations;
              return {
                bgColor: "#F3F3F3",
                name: elem.getInformations('title', langKey),
                category: 'time',
                dragBgColor: "#ccc",
                title: elem.getInformations('title', langKey),
                start: info.dateDebut,
                end: info.dateFin,
                calendarId: elem.json._id,
              }
            })
          }
          week={{
            eventView: ['time'],
            taskView: false,
            hourStart: 0,
            hourEnd: 24,
            showTimezoneCollapseButton: true,
            timezonesCollapsed: true,
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            startDayOfWeek: 1,
          }}
          view={view}
          onClickEvent={(props) => setmodalIsOpen({
            open: true,
            info: objectList.find(elem => elem.json._id === props.event.calendarId)
          })}
        />
      </div>
    </div>
  )
}