import { useContext } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UKUser } from "../../../Class/UKUser";

const UsersTable = ({usertype, info, loading, onCellClick, selectedList, selectedKey}) => {
  const {config, applicationConfig, langKey} = useContext(ApplicationContext);

  const addDefaultValues = (obj, values) => {
    let newObj = [...obj];
    values.forEach(value => {
      if (newObj.find(custom => custom.id === value.id)) return;
      newObj.unshift({
        id: value.id,
        tint: value.tint,
      })
    })
    return newObj;
  }

  const isSelected = (row) => {
    try {
      return selectedList.findIndex(e => {return e === row[selectedKey]}) >= 0
    } catch (e) {
      return false;
    }
  }

  if (applicationConfig === null)
    return <div></div>
  let userConfig = applicationConfig.getCustomUserByID(usertype);
  let tmpUserConfig = userConfig.custom ? [...userConfig.custom] : [];
  tmpUserConfig = addDefaultValues(tmpUserConfig, [{id: "email", tint: "E-mail"}, {id: "lastName", tint: "Nom"}, {id: "firstName", tint: "Prénom"}]);

  return (
    <div className="bg-gray-200 w-full text-left border border-gray-300 border-separate rounded-md" style={{
      overflowX: "auto",
      display: "block",
    }}>
      {tmpUserConfig && info &&
        <table style={{minWidth: "100%"}}>
          <thead className="bg-white border-none text-base rounded-md">
          <tr>
            {tmpUserConfig.map((row, index) => {
              if (row.type === 'mapLink') return;
              return (
                <th key={index} className="text-xs text-gray-500 font-semibold" style={{
                  padding: "3px 10px"
                }}>{row.tint}</th>
              );
            })}
          </tr>
          </thead>
          <tbody className="text-xs text-left bg-white">
          {info.objects && info.objects.length > 0 && info.objects.map((elem, index) => {
            const object = new UKUser(elem, config);
            return (
              <tr key={index} onClick={() => {
                onCellClick(elem)
              }}
                  className="h-10 hover:opacity-60">
                {tmpUserConfig.map((row, index) => {
                  let cellColor = index % 2 === 0 ? "#F6F6F6" : "";
                  if (isSelected(object.json))
                    cellColor = '#BBF7D0';
                  let objInfos = object.getInformations(row.id);
                  if (!objInfos) objInfos = object.getInformations(`${row.id}${langKey}`) || '-';
                  if (objInfos && row.type === 'link')
                    objInfos = objInfos.length > 0 ? objInfos.length + " liens" : '-'
                  if (row.type === 'mapLink') return;
                  return (
                    <td key={index} className="text-xs cursor-pointer"
                        style={{
                          color: "#555555",
                          padding: "3px 10px",
                          background: cellColor,
                          whiteSpace: "nowrap",
                          maxWidth: "50ch",
                          overflow: "hidden"
                        }}>{objInfos}</td>
                  )
                })}
              </tr>
            )
          })}
          </tbody>
        </table>}
      {(!info.objects || info.objects.length === 0) && <p className="text-gray-800 font-bold text-center">Aucun élément trouvé</p>}
      {loading && <p className="text-gray-800 font-bold text-center">Chargement...</p>}
    </div>
  );
}

export default UsersTable
