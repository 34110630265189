import { faPlay } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import { Pagination } from 'react-rainbow-components';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import { UpdateJsonConfig } from '../../../Services/Application';
import { CreateObject, CreateObjects, GetObjectsAll, GetObjectsSearch, UpdateObject } from '../../../Services/UKObject';
import AddButton from '../Button/AddButton';
import ImportVerifyLink from './Preview/ImportVerifyLink';

const SPLIT_VALUE = '$$//$$';
function TimerImport({ startDateImport, endDate }) {
  var [number, setNumber] = useState(0);
  useEffect(() => {
    setInterval(() => {
      setNumber((n) => n + 1);
    }, 1000);
  }, []);
  var d = endDate || new Date();
  return <p>{Math.floor((d.getTime() - startDateImport.getTime()) / 1000)}s </p>;
}

function TimerProgressBar({ total = 100, importNumber = 10, updateNumber = 20, startDateImport, endDateImport }) {
  var pourcentImport = (importNumber * 100) / total;
  var pourcentUpdate = (updateNumber * 100) / total;
  var poucentTotal = ((updateNumber + importNumber) * 100) / total;
  return (
    <div className="relative">
      <div className="relative h-5 w-full bg-gray-100 my-2 rounded-lg overflow-hidden flex">
        <div
          className={'bg-green-400 h-5 ' + (pourcentUpdate == 0 ? 'rounded-r-lg' : '')}
          style={{ width: pourcentImport + '%' }}
        ></div>
        <div className="bg-yellow-400 h-5 rounded-r-lg" style={{ width: pourcentUpdate + '%' }}></div>
        <p className="absolute left-0 right-0 text-center top-0 bottom-0 font-medium">{Math.floor(poucentTotal)} % </p>
      </div>
      <div className="flex gap-5">
        <div className="flex items-center gap-2 ">
          <div className="bg-green-400 h-4 w-4 rounded-lg"></div>
          <p>{importNumber} élément(s) créé(s)</p>
        </div>

        <div className="flex items-center gap-2 ">
          <div className="bg-yellow-400 h-4 w-4 rounded-lg"></div>
          <p>{updateNumber} élément(s) modifié(s)</p>
        </div>
      </div>
      <div className="absolute bottom-0 right-0">
        {startDateImport && <TimerImport startDateImport={startDateImport} endDate={endDateImport} />}
      </div>
    </div>
  );
}

function TimerLinkProgressBar({ total = 100, importNumber = 10 }) {
  var pourcentImport = (importNumber * 100) / total;
  var poucentTotal = (importNumber * 100) / total;
  return (
    <div className="relative">
      <div className="relative h-5 w-full bg-gray-100 my-2 rounded-lg overflow-hidden flex">
        <div className={'bg-green-400 h-5 rounded-r-lg'} style={{ width: pourcentImport + '%' }}></div>

        <p className="absolute left-0 right-0 text-center top-0 bottom-0 font-medium">{Math.floor(poucentTotal)} % </p>
      </div>
      <div className="flex gap-5">
        <div className="flex items-center gap-2 ">
          <div className="bg-green-400 h-4 w-4 rounded-lg"></div>
          <p>{importNumber} objet(s) et ses liens traités </p>
        </div>
      </div>
    </div>
  );
}
export default function ImportExcelPreview({ allData, selectType, linkData }) {
  const { config, application } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const [header, setHeader] = useState([]);
  const [data, setData] = useState([]);
  const [object, setObject] = useState(null);
  const [dataPage, setDataPage] = useState([]);
  const [page, setPage] = useState(1);
  const [nbrByPage, setNbrByPage] = useState(10);
  const [maxPage, setMaxPage] = useState(0);
  const [startDateImport, setStartDateImport] = useState(null);
  const [endDateImport, setEndDateImport] = useState(null);
  const [numberImport, setNumberImport] = useState(0);
  const [numberUpdate, setNumberUpdate] = useState(0);
  const [numberLink, setNumberLink] = useState(0);

  useEffect(() => {
    setHeader(allData[0]);
    var arr = [...allData];
    arr.splice(0, 1);
    setData(arr.filter((e) => e.length > 0));
  }, [allData]);

  useEffect(() => {
    var arr = [...data];
    setDataPage(arr.splice((page - 1) * nbrByPage, nbrByPage));
    setMaxPage(Math.ceil(data.length / nbrByPage));
  }, [data, page]);

  useEffect(() => {
    var configTMP = config.customObject.find((e) => e.id == selectType);
    if (configTMP) {
      setObject(configTMP);
    }
  }, [selectType]);

  useEffect(() => {}, [allData]);

  const maxSize = (str, size) => {
    if (!str) {
      return '';
    }
    if (str.length < size) {
      return str;
    }
    return str.slice(0, size) + '...';
  };
  if (!object || !object.custom) {
    return null;
  }

  const verifyLink = async (link) => {
    const linkObj = config.links.find((e) => e.id == link.linkId);
    var type = linkObj.type1 == selectType ? linkObj.type2 : linkObj.type1;
    var files = await GetObjectsAll(type, application.apiKey, (user || {}).authToken);
    var totalFound = 0;
    var totalNotFound = [];
    for (var i = 0; i < data.length; i++) {
      var arr = data[i][link.index].split(link.separator).map((e) => e.trim());
      for (var j = 0; j < arr.length; j++) {
        const fileFound = files.find((e) => {
          return e.informations[link.linkCustomId] == arr[j];
        });
        if (fileFound) {
          totalFound += 1;
        } else {
          totalNotFound.push(arr[j]);
        }
      }
    }
    return { type: type, totalFound: totalFound, totalNotFound: totalNotFound };
  };
  const startVerify = async () => {
    var links = [];
    for (const [key, value] of Object.entries(linkData)) {
      if (value.type == 'link') {
        links.push(value);
      }
    }

    const result = [];
    for (var i = 0; i < links.length; i++) {
      var link = links[i];
      result.push(await verifyLink(link));
    }
    return result.filter((e) => e.totalNotFound.length > 0);
  };

  const startImport = async () => {
    var result = await startVerify();
    var n = result.map((e) => e.type + ' : ' + e.totalNotFound.join(','));
    if (
      result.length > 0 &&
      !window.confirm("Certains liens n'ont pas été trouvé ! Voulez-vous continuer ? " + n.join('\n'))
    ) {
      return;
    }

    setStartDateImport(new Date());
    setNumberImport(0);
    setNumberLink(0);
    setNumberUpdate(0);
    setEndDateImport(null);

    var identifier = null;
    var allSelectorNewValue = {};
    for (const [key, value] of Object.entries(linkData)) {
      if (value.identifier == true) {
        identifier = key;
      }
    }

    var total = data.length;
    for (var i = 0; i < total; i++) {
      var json = {};

      for (const [key, value] of Object.entries(linkData)) {
        if (value.type == 'link') {
          continue;
        }
        json[key] = data[i][value.index];

        /* Ici on modifie si multiple selecteur les valeurs possibles */
        if (value.separator.length > 0) {
          var splitedValue = data[i][value.index].split(value.separator);
          var arr = allSelectorNewValue[key] || [];

          splitedValue.forEach((v) => {
            if (arr.indexOf(v.trim()) == -1) {
              arr.push(v.trim());
            }
          });
          allSelectorNewValue[key] = arr;
          json[key] = splitedValue.map((e) => e.trim()).join(SPLIT_VALUE);
        }
        /* ---- */
      }
      if (identifier) {
        const obj = await GetObjectsSearch(selectType, 0, application.apiKey, user.authToken, [
          { name: identifier, type: '==', value: json[identifier] },
        ]);
        if (obj.length > 0) {
          await UpdateObject(obj[0]._id, selectType, json, application.apiKey, user.authToken);
          setNumberUpdate((n) => n + 1);
          data[i].importStep = 2;
        } else {
          await CreateObject(selectType, json, application.apiKey, user.authToken);
          setNumberImport((n) => n + 1);
          data[i].importStep = 1;
        }
      } else {
        await CreateObject(selectType, json, application.apiKey, user.authToken);
        setNumberImport((n) => n + 1);
        data[i].importStep = 1;
      }

      setData(data);
    }

    var total = data.length;
    var allObjects = {};
    for (var i = 0; i < total; i++) {
      var json = {};

      for (const [key, value] of Object.entries(linkData)) {
        if (value.type == 'link') {
          continue;
        }
        json[key] = data[i][value.index];
      }
      const obj = await GetObjectsSearch(selectType, 0, application.apiKey, user.authToken, [
        { name: identifier, type: '==', value: json[identifier] },
      ]);

      for (const [key, value] of Object.entries(linkData)) {
        if (value.type !== 'link') {
          continue;
        }

        var arr = data[i][value.index].split(value.separator).map((e) => e.trim());
        const linkObj = config.links.find((e) => e.id == value.linkId);
        var type = linkObj.type1 == selectType ? linkObj.type2 : linkObj.type1;
        if (!allObjects[type]) {
          allObjects[type] = await GetObjectsAll(type, application.apiKey, (user || {}).authToken);
        }

        var arrInformations = [];
        for (var j = 0; j < arr.length; j++) {
          const fileFound = allObjects[type].find((e) => {
            return e.informations[value.linkCustomId] == arr[j];
          });
          if (fileFound) {
            arrInformations.push({
              id2: linkObj.type2 == selectType ? obj[0]._id : fileFound._id,
              type2: linkObj.type2,
              id1: linkObj.type1 == selectType ? obj[0]._id : fileFound._id,
              type1: linkObj.type1,
              idconfig: linkObj.id,
              state: '1',
            });
          }
        }

        await CreateObjects('ukLink', { arr: arrInformations }, application.apiKey, user.authToken);
      }
      setNumberLink((n) => n + 1);
    }
    /* Add in config custom object all new Values possibility */
    var newConfig = { ...config };

    var configType = newConfig.customObject.find((e) => e.id == selectType);
    var hasUpdate = false;
    for (const [key, value] of Object.entries(allSelectorNewValue)) {
      var customIndex = configType.custom.findIndex((e) => e.id == key);
      if (customIndex >= 0) {
        var currentValues = configType.custom[customIndex].values || [];
        for (var i = 0; i < value.length; i++) {
          if (currentValues.indexOf(value[i]) == -1) {
            hasUpdate = true;
            currentValues.push(value[i]);
          }
        }
        configType.custom[customIndex].values = currentValues;
      }
    }
    if (hasUpdate) {
      await UpdateJsonConfig(application.apiKey, newConfig);
    }
    /* ------ */

    setEndDateImport(new Date());
  };
  return (
    <div className="text-gray-700 overflow-scroll mt-3">
      <p className="text-xl font-medium">Prévisualisation</p>
      <p className="text-sm">
        Voici une prévisualisation de ce que vous allez importer. Vérifiez que les données sont les bonnes avant de
        commencer l'import des documents.
      </p>

      <p className="text-sm font-bold mt-2">Nombre de document : {data.length}</p>
      <AddButton title="Importer" icon={faPlay} onClick={startImport} />
      <TimerProgressBar
        total={data.length}
        importNumber={numberImport}
        updateNumber={numberUpdate}
        startDateImport={startDateImport}
        endDateImport={endDateImport}
      />
      <TimerLinkProgressBar
        total={data.length}
        importNumber={numberLink}
        updateNumber={0}
        startDateImport={startDateImport}
        endDateImport={endDateImport}
      />

      <ImportVerifyLink linkData={linkData} selectType={selectType} data={data} />
      <table className="border-collapse overflow-scroll rounded">
        <tr>
          {object.custom.map((e, index) => {
            return (
              <th className={'bg-blue-100 border-blue-400 text-blue-500 font-medium border rounded text-sm'}>
                {e.tint}
              </th>
            );
          })}
        </tr>
        {dataPage.map((row, index) => {
          var backgroundStep = index % 2 == 0 ? '' : 'bg-blue-50';
          if (row.importStep == 1) {
            backgroundStep = index % 2 == 0 ? 'bg-green-300' : 'bg-green-400';
          } else if (row.importStep == 2) {
            backgroundStep = index % 2 == 0 ? 'bg-yellow-300' : 'bg-yellow-400';
          }
          return (
            <tr className={backgroundStep + ' font-light text-sm'}>
              {object.custom.map((e) => {
                var index = (linkData[e.id] || {}).index;
                if (index == null) {
                  return <td></td>;
                }
                return (
                  <td
                    className={'border border-gray-300 px-2 '}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {maxSize(row[index], 50)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
      {maxPage > 0 && page && (
        <Pagination className="mt-3" activePage={page} onChange={(e, p) => setPage(p)} pages={maxPage - 1} />
      )}
    </div>
  );
}
