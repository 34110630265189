import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddButton = ({ title = 'Ajouter', icon = faPlus, iconLeft = false, className, onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      className={
        'text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max ' +
        className
      }
    >
      {iconLeft && <FontAwesomeIcon icon={icon} className={'mr-1'} />}
      {title} {!iconLeft && <FontAwesomeIcon icon={icon} />}
    </div>
  );
};

export default AddButton;
