export class UKObjectClass {
    config = [];
    type = null;
    json = {};

    constructor(json, config) {
        this.type = (json || {}).type || "";
        this.json = {
            informations: {
                r: "_",
            },
            ...json,
        };
        if (config) {
            this.config = config.customObject.find((e) => e.id === this.type) || config.eventObject || [];
        }
    }

    getInformations(id, lang) {
        let value = this.json.informations[id + lang];
        if (lang && !value) value = this.json.informations[id];
        return value || null;
    }

    setConfig(config) {
        this.config = config.customObject.find((e) => e.id === this.type) || [];
    }

    getTitleId() {
        return ((this.config && this.config.custom && this.config.custom.find((e) => e.isTitle === true)) || {}).id || null;
    }

    getSubtitleId(lang) {
        let subtitle = (this.config && this.config.custom && this.config.custom.find((e) => e.isSubTitle === true)) || {};
        return this.json.informations[`${subtitle.id}_${lang?.id}`] ?? this.json.informations[subtitle.id] ?? "";
    }

    getSubSubtitleId(lang) {
        let subtitle = (this.config && this.config.custom && this.config.custom.find((e) => e.isSubSubTitle === true)) || {};
        return this.json.informations[`${subtitle.id}_${lang?.id}`] ?? this.json.informations[subtitle.id] ?? "";
    }

    getPictureId() {
        return ((this.config && this.config.custom && this.config.custom.find((e) => e.type === "picture")) || {}).id || null;
    }

    getTitle(lang) {
        let id = this.getTitleId();

        let title = ((this.json && this.json.informations) || {})[`${id}_${lang.id}`];
        if (!title || title.length === 0) {
            return ((this.json && this.json.informations) || {})[id] || "";
        }
        return title;
    }

    getSubtitle(lang) {
        if (this.json && this.json.informations) {
            return this.getSubtitleId(lang);
        } else {
            return "";
        }
    }

    getSubSubtitle(lang) {
        if (this.json && this.json.informations) {
            return this.getSubSubtitleId(lang);
        } else {
            return "";
        }
    }

    getPicture(lang) {
        if (this.getPictureId() === null) {
            return null;
        }
        var picture = (this.json.informations || {})[this.getPictureId() || ""] || "";
        if (picture === "") {
            picture = "https://eu.ui-avatars.com/api/?name=" + this.getTitle(lang) + "&size=512&rounded=true";
        }
        return picture;
    }

    getJson(apiKey, authToken, config) {
        var result = {};
        var _this = this;
        this.config.custom.forEach((elem) => {
            result[elem.id] = _this.getInformations(elem.id);
        });
        return result;
    }
}
