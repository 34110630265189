import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../../Common/CustomModal.component";
import EventsTableView from "../../../../Events/events.table.view";
import CustomObjectCellVertical from "../../../../../Common/CustomObjectCellVertical";
import TrashButton from "../../../../../Common/Button/TrashButton";

export default function ModuleConfigurationOneEvent({ module, setModule, className = '' }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [object, setObject] = useState(module.url ?? null);

  const typeValid = ['EVENTDETAIL'];
  if (typeValid.indexOf(module.type) === -1) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="py-2">
        <p className="font-medium text-sm text-left mb-2">Quelle évènement souhaitez-vous afficher ?</p>
          <div className="" style={{ width: 300 }}>
            {!object ? (
              <div
                onClick={() => {
                  setModalOpen(true);
                }}
                className="text-gray-500 cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:border-blue-500 transition-all px-1 py-0 border border-gray-500 rounded w-max"
              >
                Ajouter <FontAwesomeIcon icon={faPlus} />
              </div>
            ) : (
              <div className="relative p-0">
                <div className="absolute right-0 top-0">
                  <TrashButton
                    className="px-2 py-1"
                    onClick={() => {
                      setObject(null);
                      setModule({...module, url: null});
                    }}
                  />
                </div>
                <CustomObjectCellVertical
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  id={object}
                  type='ukEvent'
                />
              </div>
            )}
          </div>
      </div>
      <CustomModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={'w-full h-max mt-8'}>
          <EventsTableView onlySelectEvent={true} onPress={(props) => {
            setObject(props[0]);
            setModule({...module, url: props[0]})
            setModalOpen(false);
          }}/>
        </div>
      </CustomModal>
    </div>
  );
}
