import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import CommonButton from "../../Common/Button/CommonButton";
import { EventLinkService } from "./LinkServices/event.link.service";
import { ObjectLinkService } from "./LinkServices/object.link.service";
import { UserLinkService } from "./LinkServices/user.link.service";
import SearchbarListComponent from "./searchbar.list.component";

const EventDetailLinks = ({ linkConfig, setForm, form, onSave }) => {
    const { application, config, langKey, language } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const history = useHistory();
    const [links, setLinks] = useState([]);
    const [service, setService] = useState(null);

    useEffect(() => {
        getLinks();
    }, []);

    const getLinks = async () => {
        const links = form[linkConfig.id];
        let linksInfo = [];
        let service;

        // console.log("LINK CONFIG", linkConfig);
        if (linkConfig.linkType === "user") {
            service = new UserLinkService(linkConfig, application.apiKey, (user || {}).authToken, config.inscription, langKey, config);
        } else if (linkConfig.linkType === "object") {
            service = new ObjectLinkService(linkConfig, application.apiKey, (user || {}).authToken, config.customObject, langKey, config);
        } else {
            service = new EventLinkService(linkConfig, application.apiKey, (user || {}).authToken, config.eventObject.custom, langKey, config);
        }
        setService(service);

        if (!links || links.length === 0) return;
        linksInfo = await service.getByIds(links);

        linksInfo = linksInfo.map((e) => {
            const title = e.getTitle(language);
            const picture = e.getPicture(language);

            return { id: e.json._id, title: title, picture: picture };
        });
        setLinks(linksInfo || []);
    };

    const onSelect = async (selected) => {
        const id = selected.json._id;

        const linkInfo = await service.getByIds([id]);

        setLinks(() => {
            let newLinks = [...links];
            if (newLinks.findIndex((e) => e.id === id) !== -1) return newLinks;
            newLinks.push({ id: id, title: linkInfo[0].getTitle(language), picture: linkInfo[0].getPicture(language) });
            newLinks = [...new Set(newLinks)];
            return newLinks;
        });
        setForm((form) => {
            return { ...form, [linkConfig.id]: form[linkConfig.id] ? [...new Set([...form[linkConfig.id], id])] : [id] };
        });
    };

    const onLinkRemove = (id) => {
        if (window.confirm("Voulez-vous vraiment supprimer ce lien ?")) {
            setLinks(() => {
                let newLinks = [...links];
                newLinks.splice(
                    newLinks.findIndex((e) => e.id === id),
                    1
                );
                return newLinks;
            });
            setForm(() => {
                let newForm = { ...form };
                newForm?.[linkConfig.id]?.splice(
                    newForm?.[linkConfig.id]?.findIndex((e) => e === id),
                    1
                );
                return newForm;
            });
        }
    };

    return (
        <div>
            <p className="text-left font-bold text-2xl pb-3 text-gray-700">Lien: {linkConfig.tint}</p>

            <SearchbarListComponent linkConfig={linkConfig} onSelect={onSelect} />

            <div className="mt-8">
                {links &&
                    links.map((e, i) => {
                        return (
                            <div key={i} className="flex justify-between items-center max-h-16 border pl-5 pr-5 pt-1 pb-1 cursor-pointer">
                                <div
                                    className="flex justify-start items-center gap-5 w-full"
                                    onClick={() => {
                                        history.push(`/v/${linkConfig.linkType}/${e.id}`);
                                    }}
                                >
                                    {e.picture && <img alt="img" className="object-contain mb-2" style={{ height: 50 }} src={e.picture} />}
                                    <p>{e.title}</p>
                                </div>
                                <FontAwesomeIcon
                                    className="hover:opacity-70 cursor-pointer"
                                    icon={faTrash}
                                    color={"#555555"}
                                    onClick={(e) => {
                                        // console.log("REMOVE LINK");
                                        e.preventDefault();
                                        onLinkRemove(e.id);
                                    }}
                                />
                            </div>
                        );
                    })}
                {(!links || links.length === 0) && <p className="text-xs text-gray-800">Aucun liens pour le moment</p>}
            </div>
            <CommonButton customClassName="w-max mt-5" title="Sauvegarder" onClick={onSave} />
        </div>
    );
};

export default EventDetailLinks;
