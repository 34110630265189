import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PreviewAppIcon = ({icon, iconColor, bgColor, name}) => {
  const isURL = (url) => {
    return /^[a-z][a-z0-9+.-]*:/.test(url);
  }

  return (
    <div>
      <div className="flex ml-2 justify-center items-center rounded-2xl w-[55px] h-[55px] overflow-hidden" style={{backgroundColor: bgColor}}>
        {isURL(icon) && <img className="h-full w-full" src={icon} alt="ic" />}
        {!isURL(icon) && <FontAwesomeIcon icon={icon} color={iconColor} style={{scale: "170%"}} />}
      </div>
      <p className="text-white text-center mt-1 overflow-hidden" style={{maxWidth: "10ch", fontSize: "0.820rem"}}>{name}</p>
    </div>
  );
}

export default PreviewAppIcon
