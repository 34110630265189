import React from "react";

const DocsLargeImage = ({ doc }) => {
    const infos = doc.informations;
    console.log("doc large image");
    return (
        <div className="w-full">
            {infos.image && infos.imageStyle === "large" && (
                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                    }}
                    src={infos.image}
                    alt="img"
                />
            )}
        </div>
    );
};

export default DocsLargeImage;
