import { faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import Select from "react-select";
import CommonButton from "../../Common/Button/CommonButton";
import CustomInputBox from "../../Common/Inputs/customInputBox.component";
import PictureUpdate from "../Account/PictureUpdate";
import InputFiles from "../Objects/Form/objects.input.files";

const PictureInput = ({ value, id, setForm }) => {
    return (
        <div className="w-full items-start  relative">
            <div className="relative w-max">
                <PictureUpdate
                    url={value}
                    onUpdate={(url) => {
                        setForm((form) => {
                            return { ...form, [id]: url };
                        });
                    }}
                    size={100}
                    customParentClassNames={"m-none"}
                />
            </div>
        </div>
    );
};

const TextInput = ({ value, id, setForm, config }) => {
    return (
        <input
            className="block text-sm appearance-none rounded border w-full py-2 px-3 text-gray-700 leading-tight focus:border-gray-500 focus:outline-none focus:shadow-outline h-10"
            type="text"
            name={value}
            placeholder={config.tint || "N/A"}
            value={value}
            onChange={(e) => {
                setForm((form) => {
                    return { ...form, [id]: e.target.value };
                });
            }}
        />
    );
};

const BigTextInput = ({ value, id, setForm, config }) => {
    return (
        <textarea
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 border leading-tight focus:outline-none focus:shadow-outline h-10"
            name={value}
            placeholder={config.tint || "N/A"}
            value={value}
            onChange={(e) => {
                setForm((form) => {
                    return { ...form, [id]: e.target.value };
                });
            }}
        />
    );
};

const InputSelector = ({ value, id, setForm, config }) => {
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: "100%",
        }),
        control: (provided) => ({
            ...provided,
            background: "rgb(243 244 246 / var(--tw-bg-opacity))",
            border: "0px",
        }),
    };

    let valueFormatted =
        config.type === "multipleSelector"
            ? (value || "").split("$$//$$").map((e) => {
                  return { value: e, label: e };
              })
            : {
                  value: value,
                  label: value,
              };
    if (!value) {
        valueFormatted = null;
    }
    return (
        <Select
            isMulti={config.type === "multipleSelector"}
            styles={customStyles}
            options={(config.values || []).map((e) => {
                return {
                    value: e,
                    label: e,
                };
            })}
            onChange={(e) => {
                setForm((form) => {
                    let value = e.value;
                    if (config.type === "multipleSelector") {
                        value = e.map((e) => e.value).join("$$//$$");
                    }
                    return { ...form, [id]: value };
                });
            }}
            value={valueFormatted}
        />
    );
};

const InputDate = ({ value, id, setForm, config }) => {
    let formattedDate = value?.split("+")[0].split(".")[0];
    return (
        <input
            className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 border leading-tight focus:outline-none focus:shadow-outline h-10"
            type="datetime-local"
            step="1"
            name={config.id}
            placeholder={config.tint || "N/A"}
            value={formattedDate}
            onChange={(e) => {
                // console.log(e.target.value);
                var v = e.target.value;
                if (v.length === 16) {
                    v += ":00";
                }

                setForm((form) => {
                    return { ...form, [id]: v + "+01:00" };
                });
            }}
        />
    );
};

const InputBanner = ({ form, setForm, id, config }) => {
    return (
        <div className="w-full">
            <div className="relative w-max">
                <PictureUpdate
                    url={form[id]}
                    onUpdate={(url) => {
                        setForm((form) => {
                            return { ...form, [id]: url };
                        });
                    }}
                    size={100}
                    customParentClassNames={"m-none"}
                />
            </div>
            <div className="flex gap-2 items-end justify-between">
                <div className="w-full">
                    <CustomInputBox
                        title="Quel est le lien de redirection ?"
                        value={form[`${id}_url`] || ""}
                        onChange={(e) => {
                            setForm((form) => {
                                return { ...form, [`${id}_url`]: e };
                            });
                        }}
                    />
                </div>
                <CommonButton
                    customColor={"#F87171"}
                    useAppTheme={false}
                    customClassName="w-max mt-2 p-3"
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => {
                        setForm((form) => {
                            return { ...form, [`${id}_url`]: null, [id]: null };
                        });
                    }}
                />
            </div>
        </div>
    );
};

const EventFormInputs = ({ value, config, setForm, id, form }) => {
    const inputFileRef = useRef(null);

    let input = null;
    switch (config.type) {
        case "text":
        case "email":
        case "web":
            input = <TextInput value={value} setForm={setForm} id={id} config={config} />;
            break;
        case "bigtext":
            input = <BigTextInput value={value} setForm={setForm} id={id} config={config} />;
            break;
        case "selector":
        case "multipleSelector":
            input = <InputSelector value={value} setForm={setForm} id={id} config={config} />;
            break;
        case "date":
            input = <InputDate value={value} setForm={setForm} id={id} config={config} />;
            break;
        case "banniere":
            input = <InputBanner form={form} setForm={setForm} id={id} config={config} />;
            break;
        case "picture":
        case "icon":
            input = (
                <div className="flex w-full items-end gap-2 relative">
                    <div className="relative w-max">
                        <PictureUpdate
                            url={form[id]}
                            onUpdate={(url) => {
                                setForm((form) => {
                                    return { ...form, [id]: url };
                                });
                            }}
                            size={100}
                            customParentClassNames={"m-none"}
                        />
                    </div>
                    <CommonButton
                        customColor={"#F87171"}
                        useAppTheme={false}
                        customClassName="w-max mt-2 p-3"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => {
                            setForm((form) => {
                                return { ...form, [id]: "" };
                            });
                        }}
                    />
                </div>
            );
            break;
        case "files":
            input = (
                <div>
                    <CommonButton
                        customClassName="w-max"
                        title="Upload un fichier"
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => {
                            inputFileRef.current.openModal();
                        }}
                    />
                    <InputFiles ref={inputFileRef} value={value} id={id} setForm={setForm} />
                </div>
            );
            break;
        default:
            input = null;
            break;
    }
    const colorTitle = (
        <div className="flex flex-col mt-3">
            <p className="text-sm text-left flex">Couleur du titre</p>
            <input
                type="color"
                className="color-picker"
                onChange={(e) => {
                    setForm((form) => {
                        return { ...form, [`titleColor`]: e.target.value };
                    });
                }}
                value={form["titleColor"]}
            ></input>
        </div>
    );
    const colorSubTitle = (
        <div className="flex flex-col mt-3">
            <p className="text-sm text-left flex">Couleur du sous-titre</p>
            <input
                type="color"
                className="color-picker"
                onChange={(e) => {
                    setForm((form) => {
                        return { ...form, [`subtitleColor`]: e.target.value };
                    });
                }}
                value={form["subtitleColor"]}
            ></input>
        </div>
    );
    return (
        <div>
            <p className="text-sm text-left flex">
                {config.important ? <p className="text-red-500">*</p> : ""}
                {config.tint}
            </p>
            {input}
            {config.isTitle && colorTitle}
            {config.isSubTitle && colorSubTitle}
        </div>
    );
};

export default EventFormInputs;
