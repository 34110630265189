import { useContext, useState } from "react";
import CommonButton from "../../Common/Button/CommonButton";
import { UserContext } from "../../../Context/UserContext";
import TableSearchBar from "../../Common/TableSearchBar";
import UsersExport from "./users.export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faGear } from "@fortawesome/pro-light-svg-icons";
import CustomModal from "../../Common/CustomModal.component";
import { toast } from "react-toastify";

const UsersActionbar = ({info, setInfo, typeobject = {}, config = {}, modalSetter = {}, onlySelectUser}) => {
  const {isAdmin} = useContext(UserContext);
  const [invitLink, setInvitLink] = useState(false)

  const addDefaultValues = (obj, values) => {
    let newObj = [...obj];
    values.forEach(value => {
      if (newObj.find(custom => custom.id === value.id)) return;
      newObj.unshift({
        id: value.id,
        tint: value.tint,
      })
    })
    return newObj;
  }

  let tmpUserConfig = config.custom ? [...config.custom] : [];
  tmpUserConfig = addDefaultValues(tmpUserConfig, [{id: "email", tint: "E-mail"}, {id: "lastName", tint: "Nom"}, {id: "firstName", tint: "Prénom"}]);

  return (
    <div className="flex-col w-11/12 ">
      <div className="flex w-full justify-between">
        <div className={onlySelectUser ? 'my-2' : ''}>
        <TableSearchBar config={tmpUserConfig} info={info} setInfo={setInfo} />
        </div>
        {!onlySelectUser && (
        <div className="flex py-3">
          {isAdmin() && (
            <div className="flex items-center">
              <UsersExport filters={info.filters} userType={typeobject} />
              <CommonButton title="Configuration" icon={<FontAwesomeIcon icon={faGear} />} customClassName="h-8 ml-3" onClick={() => {
                modalSetter(true);
              }} />
              <div>
                <CommonButton title="Lien d'invitation" icon={<FontAwesomeIcon icon={faAddressCard} />} customClassName="h-8 ml-3" onClick={() => {
                  setInvitLink(true);
                  navigator.clipboard.writeText(`https://${window.location.host}/?register=1&userType=${config.id}`);
                  toast.success("Copié dans le presse-papier", {autoClose: 2000, position: "bottom-center"});
                  setTimeout(() => {
                    setInvitLink(false);
                  }, 5000);
                }} />
              </div>
            </div>
          )}
        </div>
        )}

      </div>
      {invitLink && <div className="w-full flex gap-3 items-center bg-white border rounded mt-1 mb-2">
        <p className="ml-2 text-gray-800 text-lg pr-3 border-r">Lien d'invitation</p>
        <p className="italic">{`https://${window.location.host}/?register=1&userType=${config.id}`}</p>
      </div>}
    </div>
  );
}

export default UsersActionbar;
