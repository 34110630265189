import { faArrowLeftLong, faArrowRightLong, faArrowsDownToLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetObjectsAll } from "../../../Services/UKObject";
import CommonButton from "../../Common/Button/CommonButton";
import CustomSearchBar from "../../Common/Inputs/customSearchBar.component";

const VotesSettingsObjects = ({voteInfo, updateVoteInfo, pageState}) => {
  const {application, config, langKey} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [selectedObject, setSelectedObject] = useState();
  const [objectList, setObjectList] = useState(null);
  const [filter, setFilter] = useState("");
  const [titleId, setTitleId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!voteInfo || pageState === 0) return;
    getObjects();
  }, [pageState, filter]);

  const getObjects = async () => {
    setIsLoading(true)
    let titleID = config.customObject.find(e => e.id === voteInfo.informations.optionType)?.custom.find(data => data.isTitle === true).id
    setTitleId(titleID);
    let newObjs = await GetObjectsAll(voteInfo.informations.optionType, application.apiKey, (user || {}).authToken, filter, 10);
    let newObjectList = [];
    newObjs.forEach(e => {
      newObjectList.push({_id: e._id, title: e.informations[`${titleID}${langKey}`]})
    })
    let filteredObjectList = newObjectList.filter(obj => {
      return !voteInfo.informations.options?.find(current => {
        return current._id === obj._id;
      });
    });
    setObjectList(filteredObjectList);
    setIsLoading(false);
  }

  const ObjectCell = ({index, style}) => {
    if (!objectList || objectList.length === 0) {
      return (
        <p className="font-medium text-sm italic items-center">Utilisez la barre de recherche pour affiner les résultats</p>
      );
    }
    return (
      <div className={`cursor-pointer hover:opacity-60 ${selectedObject === objectList[index] ? "bg-gray-100" : ""}`} style={style} onClick={() => {
        setSelectedObject(objectList[index])
        if (window.event.detail === 2) {
          addObject(false)
        }
      }}>
        <p>{objectList[index].title}</p>
      </div>
    );
  }

  const OptionCell = ({index, style, key}) => {
    if (!voteInfo.informations.options) {
      return (
        <div></div>
      );
    }
    return (
      <div className={`cursor-pointer hover:opacity-60 ${selectedObject === voteInfo.informations.options[index] ? "bg-gray-100" : ""}`} key={key} style={style} onClick={() => {
        setSelectedObject(voteInfo.informations.options[index])
        if (window.event.detail === 2) {
          removeObject(false)
        }
      }}>
        <p>{voteInfo.informations.options[index].title}</p>
      </div>
    );
  }

  const addObject = (all) => {
    if (!objectList || objectList.length === 0) return;
    if (all) {
      updateVoteInfo("options", voteInfo.informations?.options ? [...voteInfo.informations?.options, ...objectList] : [...objectList])
      setObjectList([]);
      return;
    }
    if (!selectedObject) return;
    updateVoteInfo("options", voteInfo.informations?.options ? [...voteInfo.informations?.options, selectedObject] : [selectedObject]);
    setObjectList((prevList) => {
      let newObjectList = [...prevList];
      newObjectList.splice(prevList.findIndex(e => e === selectedObject), 1);
      return newObjectList;
    });
    setSelectedObject(null);
  }

  const removeObject = (all) => {
    if (!voteInfo.informations.options || voteInfo.informations.options.length === 0) return;
    if (all) {
      setObjectList([...objectList, ...voteInfo.informations.options])
      updateVoteInfo("options", []);
      return;
    }
    if (!selectedObject) return;
    let newOptions = [...voteInfo.informations.options];
    newOptions.splice(voteInfo.informations.options.findIndex(e => e === selectedObject), 1);
    updateVoteInfo("options", newOptions);
    setObjectList((prevList) => [...prevList, selectedObject]);
    setSelectedObject(null);
  }

  return (
    <div className="m-2">
      <div className="flex gap-5 items-center">
        <CustomSearchBar filter={filter} onChange={(e) => {
          setFilter([{
            name: titleId,
            type: 'reg',
            value: e,
          }]);
        }} onClear={() => {
          setFilter("");
        }} />
        {isLoading && <div className="flex justify-center items-center">
          <div role="status">
            <svg className="inline mr-2 w-7 h-7 text-gray-200 animate-spin dark:text-gray-300 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
            <span className="sr-only">Chargement...</span>
          </div>
        </div>}
      </div>
      <div className="flex justify-between mt-5">
        <div>
          <p className="font-medium text-sm text-center mb-2">Objet(s) disponible(s)</p>
          <div className="border rounded">
            <List height={350}
                  itemCount={(objectList && objectList.length !== 0) ? objectList.length : 1}
                  itemSize={35}
                  width={400}>
              {ObjectCell}
            </List>
          </div>
        </div>
        <div className="">
          <div className="flex flex-col items-center justify-center px-2 w-20 gap-3 items-center justify-center h-full">
            <CommonButton customClassName="h-10 w-10 text-center items-center justify-center" useAppTheme={false} fontSize={12} icon={<FontAwesomeIcon icon={faArrowRightLong} />} onClick={() => {
              addObject(false)
            }} />
            <CommonButton customClassName="h-10 w-10 text-center items-center justify-center transform -rotate-90" useAppTheme={false} fontSize={12} icon={<FontAwesomeIcon icon={faArrowsDownToLine} />} onClick={() => {
              addObject(true)
            }} />
            <CommonButton customClassName="h-10 w-10 text-center items-center justify-center" useAppTheme={false} fontSize={12} icon={<FontAwesomeIcon icon={faArrowLeftLong} />} onClick={() => {
              removeObject(false)
            }} />
            <CommonButton customClassName="h-10 w-10 text-center items-center justify-center transform rotate-90" useAppTheme={false} fontSize={12} icon={<FontAwesomeIcon icon={faArrowsDownToLine} />} onClick={() => {
              removeObject(true)
            }} />
          </div>
        </div>
        <div>
          <p className="font-medium text-sm text-center mb-2">Objet(s) selectionné(s)</p>
          <div className="border rounded">
            <List height={350}
                  itemCount={voteInfo.informations.options ? voteInfo.informations.options.length : 0}
                  itemSize={35}
                  width={400}>
              {OptionCell}
            </List>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VotesSettingsObjects
