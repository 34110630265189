import { faArrowRight, faChevronDown, faChevronUp, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { ApplicationContext } from '../../Context/ApplicationContext';
import { EmailExist } from '../../Services/User';
import AddButton from '../Common/Button/AddButton';

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const checkError = (form, type) => {
  var error = false;
  if (!form.lastName || !form.firstName || !form.email) {
    error = true;
  }
  if (form.email && !validateEmail(form.email)) {
    error = true;
  }

  /*for (var i = 0; i < type.formulary.length; i++) {
    if (type.formulary[i].important) {
      if (!form[type.formulary[i].id]) {
        error = true;
      }
    }
  }*/
  console.log("ERROR", error)

  return error;
};
const BilletterieFormulary = ({ type, index, setFinalTicket, finalTicket, setStep }) => {
  const { application, config, langKey, TRAD } = useContext(ApplicationContext);
  const [form, setForm] = useState({});
  const [expand, setExpand] = useState(true);
  const [emailExist, setEmailExist] = useState(false);
  const [configUser, setConfigUser] = useState([])
  const checkEmail = async () => {
    if (form.email && validateEmail(form.email)) {
      var exist = await EmailExist(form.email, application.apiKey);
      setEmailExist(exist.exist);
      for (var i = 0; i < finalTicket.length; i++) {
        if (i != index && finalTicket[i].email && finalTicket[i].email.trim().toLowerCase() == form.email.trim().toLowerCase()) {
          setEmailExist(true);
        }
      }
    }
  };
  useEffect(() => {
    if (config) {
      let userConfig = config.inscription.customs.find(e => e.id == type.customId)
      console.log("user config", userConfig)
      setConfigUser(userConfig)
    }
  }, [type, config])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkEmail();
    }, 500);
    var arr = [...finalTicket];
    console.log('ARR');
    console.log(arr);
    console.log(form);
    arr[index] = form;
    arr[index].user_custom_type = type.customId;
    arr[index]._config = type;
    console.log(arr);
    console.log(finalTicket);
    setFinalTicket(arr);
    return () => clearTimeout(delayDebounceFn);
  }, [form]);

  var lastName = form.lastName || '*' + TRAD('USER_DETAIL_LASTNAME', 'Nom').toUpperCase() + '*';
  var firstName = form.firstName || '*' + TRAD('USER_DETAIL_FIRSTNAME', 'Prenom').toUpperCase() + '*';

  var error = checkError(form, type);
  if (emailExist) {
    error = true;
  }
  return (
    <div className="border border-blue-200 text-left text-gray-700  mb-4 rounded-lg ">
      <div
        onClick={() => {
          setExpand(!expand);
        }}
        className="bg-blue-100 flex gap-2 px-2 py-2 flex items-center justify-between hover:opacity-70 cursor-pointer "
      >
        <div className="flex gap-2">
          <p className="font-bold">
            {' '}
            {lastName} {firstName} -
          </p>
          <p className="italic font-light"> {type.name}</p>
        </div>
        <div>
          {error && <FontAwesomeIcon icon={faExclamation} className="mr-2" color="red" />}
          <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {expand && (
        <div className="px-2 py-2 flex flex-col gap-2">
          <div className="grid grid-cols-3 items-center justify-center w-full text-light">
            <label className="block text-gray-700 text-sm font-light">{TRAD('USER_DETAIL_LASTNAME', 'Nom')}*:</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Nom"
              className="col-span-2 w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
              value={form.lastName}
              onChange={(e) => setForm({ ...form, lastName: e.target.value })}
            />
          </div>
          <div className="grid grid-cols-3 items-center justify-center w-full text-light">
            <label className="block text-gray-700 text-sm font-light">{TRAD('USER_DETAIL_FIRSTNAME', 'Prenom')}* :</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Prénom"
              className="col-span-2 w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
              value={form.firstName}
              onChange={(e) => setForm({ ...form, firstName: e.target.value })}
            />
          </div>

          <div className="grid grid-cols-3 items-center justify-center w-full text-light">
            <label className="block text-gray-700 text-sm font-light">{TRAD('USER_DETAIL_EMAIL', 'Email')}* :</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className="col-span-2 w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            {emailExist && <p className="col-span-2 col-start-2 text-sm text-red-400">{TRAD('EMAIL_ALREADY_EXIST', 'Email déjà existant')}</p>}
          </div>

          <div className="grid grid-cols-3 items-center justify-center w-full text-light">
            <label className="block text-gray-700 text-sm font-light">{TRAD('USER_DETAIL_PASSWORD', 'Mot de passe')}* :</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Mot de passe"
              className="col-span-2 w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </div>
          {((configUser || {}).custom || []).map((e, index) => {
            return (
              <div key={type._id + '_formulary' + index} className="grid grid-cols-3 items-center justify-center w-full text-light">
                <label className="block text-gray-700 text-sm font-light">
                  {e['tint' + langKey] || e['tint']}
                  {e.important ? '*' : ''}:
                </label>
                {e.type == 'text' && (
                  <input
                    type="text"
                    name="text"
                    id={e.id + langKey}
                    placeholder={e['tint' + langKey]}
                    className="col-span-2 w-full text-sm appearance-none rounded py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                    value={form[e.id + langKey]}
                    onChange={(v) => {
                      var obj = { ...form };
                      obj[e.id + langKey] = v.target.value;
                      setForm(obj);
                    }}
                  />
                )}
                {(e.type == 'multipleSelector' || e.type == 'selector') && (
                  <Select
                    placeholder="Selectionner"
                    value={
                      form[e.id + langKey] &&
                      form[e.id + langKey].split('$$//$$').map((p) => {
                        return { value: p, label: p };
                      })
                    }
                    styles={{
                      control: () => ({
                        background: 'rgba(243, 244, 246, var(--tw-bg-opacity))',
                        display: 'flex',
                        flex: 1,
                      }),
                    }}
                    isMulti={e.type == 'multipleSelector'}
                    className="w-full col-span-2"
                    options={(e['values' + langKey] || []).map((a) => {
                      return { value: a, label: a };
                    })}
                    name={e.id + langKey}
                    onChange={(a, b) => {
                      var obj = { ...form };
                      if (e.type == 'multipleSelector') {
                        obj[e.id + langKey] = a.map((p) => p.value).join('$$//$$');
                      } else {
                        obj[e.id + langKey] = a.value;
                      }
                      setForm(obj);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

function BilletterieFormularies({ tickets, setFinalTicket, finalTicket, onEndedFormulary }) {
  const { application, config, TRAD } = useContext(ApplicationContext);

  const [step, setStep] = useState(1);

  if (!application) {
    return null;
  }

  var error = false;
  for (var i = 0; i < finalTicket.length; i++) {
    if (checkError(finalTicket[i], tickets[i])) {
      console.log(finalTicket)
      console.log("errro true", finalTicket.length)
      error = true;
    }
  }
  return (
    <div className="">
      {tickets.map((ticket, index) => {
        return <BilletterieFormulary type={ticket} index={index} setFinalTicket={setFinalTicket} finalTicket={finalTicket} setStep={setStep} />;
      })}

      <div className="pt-2 flex justify-end">
        <AddButton
          className={error ? 'opacity-40' : ''}
          title={TRAD('CONTINUE', 'Continuer')}
          icon={faArrowRight}
          onClick={() => {
            console.log("ERROR????", error)
            if (error == false) {
              onEndedFormulary();
            }
          }}
        />
      </div>
    </div>
  );
}

export default BilletterieFormularies;
