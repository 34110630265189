import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { ApplicationContext } from '../../../../Context/ApplicationContext';

export default function SetDataLink({ link, selectType, index, object, tmpLinkData, setTmpLinkData, header }) {
  const { config } = useContext(ApplicationContext);
  // console.log(link);
  var label = link.type1 == selectType ? link.labelType1 : link.labelType2;
  var id = link.type1 == selectType ? link.type2 : link.type1;
  var configObject = config.customObject.find((e) => e.id == id);
  // console.log(configObject);
  return (
    <div
      className={
        'border-l border-r border-b border-gray-300 px-2 py-1 grid grid-cols-5 justify-between items-center  ' +
        (index == object.custom.length - 1 ? ' rounded-b' : '')
      }
    >
      <p className="col-span-1">{label}</p>
      <div className="col-span-1">
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
      <div className="flex gap-2 items-center col-span-3">
        <div className="flex flex-col">
          {/* Selection de la colonne */}
          <select
            value={(tmpLinkData[link.id] || { index: '' }).index || ''}
            onChange={(t) => {
              setTmpLinkData({
                ...tmpLinkData,
                [link.id]: {
                  ...(tmpLinkData[link.id] || {}),
                  type: 'link',
                  index: t.target.value,
                },
              });
            }}
            className="border-gray-300 rounded border px-2 py-1 bg-gray-100"
          >
            <option value={''}>Choisir</option>
            {header.map((h, index) => {
              return <option value={index}>{h}</option>;
            })}
          </select>

          {/* Selection du separateur */}
          <div className="flex mt-2">
            <p>Séparateur : </p>
            <input
              className="px-2 w-16 bg-gray-100 border-gray-300 border rounded"
              value={(tmpLinkData[link.id] || {}).separator || ''}
              onChange={(t) => {
                setTmpLinkData({
                  ...tmpLinkData,
                  [link.id]: {
                    ...(tmpLinkData[link.id] || {}),
                    type: 'link',

                    separator: t.target.value,
                  },
                });
              }}
            ></input>
          </div>
        </div>
        <div>
          {/* Selection de l'identifiant de l'autre objet */}
          <p>Identifiant : </p>
          <select
            value={(tmpLinkData[link.id] || { index: '' }).linkCustomId || ''}
            onChange={(t) => {
              setTmpLinkData({
                ...tmpLinkData,
                [link.id]: {
                  ...(tmpLinkData[link.id] || {}),
                  type: 'link',
                  linkCustomId: t.target.value,
                  linkId: link.id,
                },
              });
            }}
            className="border-gray-300 rounded border px-2 py-1 bg-gray-100"
          >
            <option value={''}>Choisir</option>
            {((configObject || {}).custom || []).map((h, index) => {
              return <option value={h.id}>{h.tint}</option>;
            })}
          </select>
        </div>
        <p className="flex items-center gap-2 text-sm mt-2"></p>
      </div>
    </div>
  );
}
