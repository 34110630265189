import { useContext } from 'react';
import { ApplicationContext } from '../../../../../../../Context/ApplicationContext';
import YesNoInput from '../../../Design/Component/YesNoInput';
import StyleViewComponent from '../../../Design/Templates/StyleViewComponent';

export default function ModuleConfigurationCurrentEvent({ module, setModule, className = '' }) {
  const { config, application } = useContext(ApplicationContext);

  var typeValid = ['CURRENTEVENT'];
  if (typeValid.indexOf(module.type) == -1) {
    return <></>;
  }

  return (
    <div className={className}>
      <YesNoInput
        title={"Voulez-vous que l'utilisateur ne voit que les événements en cours auxquels il est inscrit ?"}
        yesSelected={module.allEvent === 'true'}
        onClick={(index) => {
          setModule({ ...module, allEvent: index === 1 ? 'true' : 'false' });
        }}
      />
    </div>
  );
}
