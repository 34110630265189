import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { GetObjectsAll, UpdateObject } from "../../../../Services/UKObject";

function Important({ updateObj, elem, color, important, title }) {
    const isImportant = elem.informations.important === important;
    return (
        <div
            onClick={() => {
                updateObj(elem, important);
            }}
            className={`px-2 cursor-pointer hover:bg-opacity-60 transition-all rounded border  text-xs ${
                isImportant ? `bg-${color}-300 border-${color}-600` : "bg-gray-300 border-gray-800"
            } `}
        >
            {title}
        </div>
    );
}
function QuestionsModule() {
    const { params } = useRouteMatch();
    const { application, reloadApplication } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);
    const [questions, setQuestions] = useState(null);

    useEffect(() => {
        reloadApplication(window.location.hostname);
    }, [params]);

    const getQuestions = useCallback(async () => {
        if (!application) {
            await reloadApplication(window.location.hostname);
        }
        const files = await GetObjectsAll(`UKQuestions_${params.idevent}`, (application || {}).apiKey, (user || {}).authToken);
        if (!files) {
            return;
        }
        setQuestions(files.reverse());
    }, [application]);

    const updateObj = useCallback(async (obj, important) => {
        // export const UpdateObject = async (id, type, object, apiKey, authToken) => {
        await UpdateObject(obj._id, obj.type, { important: important }, (application || {}).apiKey, (user || {}).authToken);
        getQuestions();
    }, []);
    useEffect(() => {
        getQuestions();

        const interval = setInterval(() => {
            getQuestions();
        }, 4000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    if (application == null) {
        return <p>appnull</p>;
    }

    if (questions === null) {
        return (
            <div className={"m-5"}>
                <ClipLoader loading={true} />
            </div>
        );
    }

    if (questions.length === 0) {
        return <div className={"min-h-screen bg-gray-300"}>Aucune question n'a été posé</div>;
    }

    return (
        <div className={"flex w-full justify-center bg-gray-100 items-center min-h-screen"}>
            <ul className={"flex w-full flex-col text-center gap-2 list-none m-2"}>
                {questions
                    .sort((a, b) => {
                        var iA = a.informations.important || 3;
                        var iB = b.informations.important || 3;
                        return iA - iB;
                    })
                    .map((elem, index) => {
                        console.log(elem);
                        if (!elem.informations.question) {
                            return;
                        }
                        var isReject = elem.informations.important === "4";
                        return (
                            <li
                                className={`relative text-slate-700 font-semibold self-center text-center text-2xl border-2 w-3/4 p-5 py-7 rounded-lg border-gray-300 bg-white break-all ${
                                    isReject ? "opacity-50" : ""
                                }`}
                                key={index}
                            >
                                {elem.informations.question}

                                <p className="text-sm absolute bottom-1 right-1">{moment(elem.mongoose_created_at).format("hh:mm")}</p>

                                <div className="flex absolute top-1 right-1 gap-2">
                                    {/* <div
                                    onClick={() => {
                                        updateObj(elem, "1");
                                    }}
                                    className="px-2 cursor-pointer hover:bg-opacity-60 transition-all bg-gray-300 rounded border border-gray-800 text-xs"
                                >
                                    Important
                                </div> */}

                                    <Important updateObj={updateObj} elem={elem} color={"red"} important={"1"} title={"important"} />
                                    <Important updateObj={updateObj} elem={elem} color={"blue"} important={"2"} title={"A garder"} />
                                    <Important updateObj={updateObj} elem={elem} color={"yellow"} important={"4"} title={"A rejeter"} />
                                </div>
                            </li>
                        );
                    })}
                <p className="bg-blue-300 bg-red-300 bg-yellow-300 border-blue-600 border-red-600 border-yellow-600"></p>
            </ul>
        </div>
    );
}

export default QuestionsModule;
