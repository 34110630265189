import { faLink, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UKObjectClass } from "../../../Class/UKObjectClass";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { CreateExternalLink, DeleteObject, GetExternalLink, GetUKObjectById } from "../../../Services/UKObject";
import CommonButton from "../../Common/Button/CommonButton";
import LinkModal from "../../Common/LinkModal";
import WaitingListComponent from "./List/WaitingListComponent";
import ObjectDetailEditComponent from "./ObjectDetailEditComponent";
import ObjectDetailInfoComponent from "./ObjectDetailInfoComponent";
import ObjectLinksUpdate from "./ObjectLinksUpdate";

function ObjectDetailComponent({ objectClass, setObjectClass, idobject, typeobject, isInvit = false }) {
    const { language } = useContext(ApplicationContext);
    const [editMode, setEditMode] = useState(false);
    const [hasLink, setHasLink] = useState(false);
    const [link, setLink] = useState(null);
    const { application, config } = useContext(ApplicationContext);
    const { user, findLink, isAdmin } = useContext(UserContext);
    const history = useHistory();

    const reloadLink = async () => {
        if (!objectClass) return;
        const hasLink = findLink(objectClass.json._id, objectClass.json.type, ["user"]);
        setHasLink(hasLink !== null && hasLink.informations.state !== "0");
    };

    const generateExternalLink = async () => {
        const link = await GetExternalLink(idobject, application.apiKey, (user || {}).authToken);
        if (link.length === 0) {
            const newLink = await CreateExternalLink(idobject, objectClass.type, application._id, application.apiKey, (user || {}).authToken);
            setLink("https://" + window.location.host + "/invitation/" + newLink._id);
        } else {
            setLink("https://" + window.location.host + "/invitation/" + link[0]._id);
        }
    };

    const reloadObject = async () => {
        if (idobject === "new") {
            if (typeobject) {
                if (objectClass === null) {
                    setObjectClass(new UKObjectClass({ type: typeobject }, config));
                }
                setEditMode(true);
            }
        } else {
            //var object = await GetUKObjectById(idobject);
            // setObjectClass(new UKObjectClass(object, config));
        }
    };

    const reloadObjectUpdate = async () => {
        if (idobject === "new") {
            if (typeobject) {
                setObjectClass(new UKObjectClass({ type: typeobject }, config));
                setEditMode(true);
            }
        } else {
            var object = await GetUKObjectById(idobject);
            setObjectClass(new UKObjectClass(object, config));
        }
    };

    useEffect(() => {
        reloadLink();
        reloadObject();
        // eslint-disable-next-line
    }, [objectClass]);

    return (
        <div className="py-10">
            <div className="bg-white w-full sm:w-3/4 md:w-3/4 lg:w-2/4 m-auto grid grid-cols-1 rounded border border-gray-300">
                {objectClass && objectClass.config && objectClass.config.custom && !editMode && (
                    <ObjectDetailInfoComponent objectClass={objectClass} hasLink={hasLink} setEditMode={setEditMode} lang={language} />
                )}
                {objectClass && objectClass.config && objectClass.config.custom && editMode && (
                    <ObjectDetailEditComponent objectClass={objectClass} hasLink={hasLink} setEditMode={setEditMode} reloadObject={reloadObjectUpdate} lang={language} />
                )}
                {idobject !== "new" && (
                    <div className="col-span-6">
                        <ObjectLinksUpdate objectClass={objectClass} hasLink={hasLink} editMode={editMode} />
                    </div>
                )}
                
                {isAdmin() && !editMode && (
                    <CommonButton customClassName="w-max m-3" icon={<FontAwesomeIcon icon={faLink} />} title="Créer un lien" onClick={() => generateExternalLink()} />
                )}
                {isAdmin() && !editMode && (
                    <CommonButton
                        customClassName="w-max m-3"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        useAppTheme={false}
                        customColor={"#F87171"}
                        title="Supprimer"
                        onClick={() => {
                            if (window.confirm("Voulez-vous supprimer cet objet ?")) {
                                DeleteObject(objectClass.json._id, objectClass.json.type, application.apiKey, (user || {}).authToken);
                                history.push(`/v/objects/${objectClass.type}`);
                            }
                        }}
                    />
                )}
                {(isAdmin() || isInvit) && !editMode && (
                    <div>
                        <CommonButton customClassName="w-max m-3" title="Modifier" onClick={() => setEditMode(true)} />
                    </div>
                )}
            </div>

            {hasLink && <WaitingListComponent idobject={idobject} typeobject={objectClass.config.id} />}

            <LinkModal modalIsOpen={link != null} setmodalIsOpen={(e) => setLink(null)} link={link} title={"Partagez ce lien"} />
        </div>
    );
}

export default ObjectDetailComponent;
