import React from "react";

const CustomInputBox = ({title, onChange, value}) => {
  return (
    <div>
      <p className="font-medium text-sm text-left mb-2">{title}</p>
      <input
        className="custominputbox"
        type="text"
        value={value}
        onChange={(e) => {onChange(e.target.value)}}
      />
    </div>
  );
}

export default CustomInputBox
