import { useContext } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";

import YesNoInput from "../../Account/Admin/Design/Component/YesNoInput";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrashButton from "../../../Common/Button/TrashButton";
import SelectValueSelector from "../../Events/Settings/SelectValueSelector";

const ObjectSettingsEdit = ({ item, onUpdate, onSave, onDelete }) => {
    const { config, application, reloadConfig } = useContext(ApplicationContext);

    if (!item) {
        return <div></div>;
    }

    const types = [
        { title: "Texte", type: "text" },
        { title: "Long texte", type: "bigtext" },
        { title: "Email", type: "email" },
        { title: "Lien web", type: "web" },
        { title: "Sélecteur", type: "selector" },
        { title: "Sélecteur Multiple", type: "multipleSelector" },
        { title: "Image", type: "picture" },
        //{ title: 'Lien objet', type: 'link' },
        //{ title: 'Catégorie', type: 'category' },
    ];

    return (
        <div className="col-span-2 border border-gray-400 bg-white px-4 py-2 rounded">
            <div className="py-2">
                <p className="font-medium text-sm text-left mb-2">Quelle est le nom de l'information ?</p>
                <input
                    className="custominputbox w-full"
                    type="text"
                    value={item.tint}
                    onChange={(e) => {
                        onUpdate({
                            ...item,
                            tint: e.target.value,
                            placeholder: e.target.value,
                        });
                    }}
                ></input>

                <p className="font-medium text-sm text-left mb-2 mt-2">Quelle est le type de l'information 1?</p>
                <select
                    className="bg-gray-100 text-gray-700 px-3 w-max py-2"
                    value={item.type}
                    onChange={(e) => {
                        onUpdate({
                            ...item,
                            type: e.target.value,
                        });
                    }}
                >
                    {types.map((e) => {
                        return <option value={e.type}>{e.title}</option>;
                    })}
                </select>

                {(item.type == "selector" || item.type == "multipleSelector") && (
                    <div>
                        <SelectValueSelector
                            values={item.values || []}
                            setValues={(values) => {
                                onUpdate({
                                    ...item,
                                    values: values,
                                });
                            }}
                        />
                    </div>
                )}
                {item.type == "link" && (
                    <div>
                        <p className="font-medium text-sm text-left mb-2 mt-2">À quel type d'objet est-il lié ?</p>
                        <select
                            className="bg-gray-100 text-gray-700 px-3 w-max py-2"
                            value={item.url}
                            onChange={(e) => {
                                var link = config.links.find((l) => {
                                    if (l.type1 == e.target.value && l.type2 == "ukEvent") {
                                        return true;
                                    }
                                    if (l.type2 == e.target.value && l.type1 == "ukEvent") {
                                        return true;
                                    }
                                    return false;
                                });
                                onUpdate({
                                    ...item,
                                    url: e.target.value,
                                    idLink: (link || {}).id,
                                    style: "line",
                                    identifier: "CustomObjectDetailCell",
                                });
                            }}
                        >
                            {config.customObject
                                .map((e) => {
                                    return { type: e.id, title: e.name };
                                })
                                .map((e) => {
                                    return <option value={e.type}>{e.title}</option>;
                                })}
                        </select>
                    </div>
                )}
                {item.type != "picture" && (
                    <YesNoInput
                        title={"Cette information est-elle obligatoire ?"}
                        yesSelected={item.important}
                        onClick={(index) => {
          
                            onUpdate({
                                ...item,
                                important: index == 1,
                            });
                        }}
                    />
                )}
                {item.type != "picture" && (
                    <YesNoInput
                        title={"Cette information est-elle le sous-titre de l'évènement ?"}
                        yesSelected={item.isSubTitle}
                        onClick={(index) => {
        
                            onUpdate({
                                ...item,
                                isSubTitle: index == 1,
                            });
                        }}
                    />
                )}
                {item.type != "picture" && (
                    <YesNoInput
                        title={"Cette information est-elle le sous-titre des sous-évènements ?"}
                        yesSelected={item.isSubTitleSubEvent}
                        onClick={(index) => {

                            onUpdate({
                                ...item,
                                isSubTitleSubEvent: index == 1,
                            });
                        }}
                    />
                )}
            </div>

            <div className="flex justify-center gap-x-3 px-3 mb-5 " style={{ marginTop: 40 }}>
                <TrashButton
                    border
                    className="border border-gray-500 transition-all hover:border-red-400 rounded px-8 py-2 "
                    onClick={() => {
                        onDelete();
                    }}
                />

                <div
                    className="border border-gray-500 text-gray-500 transition-all hover:border-green-400 hover:text-green-400 hover:bg-green-200 rounded px-8 py-2 cursor-pointer"
                    onClick={() => {
                        onSave();
                    }}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            </div>
        </div>
    );
};
export default ObjectSettingsEdit;
