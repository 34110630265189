import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStars} from "@fortawesome/pro-solid-svg-icons";

export const MyStarRating = ({ans, showTotal}) => {
  const value = [0, 0, 0, 0, 0, 0];
  const color = ['orangered', 'tomato', 'darkorange', 'yellow', 'lightgreen', 'limegreen']
  let numberVote = 0;

  for (const el of ans) {
    if (typeof el.informations.answer !== "number") {
      continue;
    }
    const tmp = Math.round(el.informations.answer);
    value[tmp] += 1;
  }

  for (const el of value) {
    numberVote += el;
  }

  let moy = 0;
  let total = 0;

  for (let i = 0; i < 6; i += 1) {
    moy += value[i] * i;
    total += value[i];
  }

  if (numberVote === 0) {
    return <></>;
  }

  moy = Math.round(moy / numberVote * 10) / 10

  return (
    <div>
      <div className={'w-full flex flex-row'}>
        <div className={'w-1/3 justify-center items-center flex flex-col'}>
          {showTotal && <p>{numberVote} vote{numberVote > 1 ? 's' : ''} au total</p>}
          <p>Note moyenne</p>
          <p>{moy} / 5</p>
        </div>
        <div className={'w-full'}>
          {value.map((e, index) => (
            <div key={index} className={'flex flex-row items-center my-1.5'}>
              <div className={'relative items-center justify-center flex'}>
                <p className={'w-2 absolute text-white mr-2.5 mt-2.5 font-bold'}>{index}</p>
                <FontAwesomeIcon icon={faStars} size={'2x'} className={'mx-2'} color={e > 0 ? 'gold' : 'black'}/>
              </div>
              <p style={{width: `${e * 100 / total}%`, backgroundColor: [color[index]]}} className={'text-left rounded-md pl-1.5'}>{`${e * 100 / total}% ${showTotal ? '(' + e + ')' : ''}`}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
