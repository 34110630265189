import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import AddButton from '../../../Common/Button/AddButton';
import { v4 as uuidv4 } from 'uuid';

const EventMoreInfoList = ({configEvent, setConfigEvent, onSelectItem, selectedItem}) => {
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(configEvent, result.source.index, result.destination.index);
    setConfigEvent(items);
    /*
    this.setState({
      items
    });*/
  };
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="bg-gray-100 p-2 rounded border border-gray-300"
            >
              {configEvent.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        stylez={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        className={
                          'bg-white hover:bg-gray-100 hover:text-gray-800  border-gray-300 border py-2 px-2 rounded mt-1 mb-1 font-medium flex items-center justify-between text-gray-600 ' +
                          (snapshot.isDragging ? 'bg-blue-50 text-blue-400 border-blue-400' : '') +
                          (selectedItem === index
                            ? ' bg-blue-50 text-blue-400 border-blue-400 hover:bg-blue-100 hover:text-blue-500'
                            : '')
                        }
                        onClick={() => {
                          onSelectItem(index);
                        }}
                      >
                        {item.tint && <p>{item.tint}</p>}
                        {(!item.tint || item.tint.length === 0) && (
                          <p className="italic text-gray-400 font-light">Nouveau</p>
                        )}
                        <FontAwesomeIcon icon={faSort} className="text-gray-400" />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-end py-2">
        <AddButton
          onClick={() => {
            var items = [...configEvent];
            items.push({
              id: uuidv4(),
              important: false,
              style: 'line',
              identifier: 'CustomObjectDetailCell',
              isSubTitle: false,
              isTitle: false,
              url: '',
              type: 'text',
              placeholder: '',
              tint: '',
              isSubTitleSubEvent: false,
            });
            setConfigEvent(items);
          }}
        />
      </div>
    </div>
  );
};
export default EventMoreInfoList;
