import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../../../../Context/ApplicationContext';

const Style1 = ({ config }) => {
  return (
    <div className="border-b border-gray-400">
      <div className="flex gap-4 p-2">
        <div className="text-right w-1/3">
          <p className="text-xs">{moment(new Date()).format('ddd DD')}</p>
          <p className="text-lg font-medium" style={{ color: config.mainColor }}>
            8:00
          </p>
          <p className="text-xs">12:00</p>
        </div>
        <div className="text-left border-b border-gray-400 w-full">
          <p className="font-medium">Titre</p>
          <p className="text-sm">Sous-titre</p>
          <p className="text-sm">Salle</p>
        </div>
      </div>
    </div>
  );
};

const Style2 = ({}) => {
  return (
    <div className="border-b border-gray-400 p-2">
      <div
        className="flex gap-4"
        style={{
          backgroundImage:
            'url(https://www.corpo-events.fr/wp-content/uploads/2013/09/organisation-seminaire-incentive-840x334.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          style={{
            background: 'linear-gradient(180deg, black, transparent)',
            backgroundSize: 'cover',
          }}
          className="h-full w-full text-white content-end items-end justify-start"
        >
          <p className="ml-2 mb-2 font-bold">Titre</p>
          <p className="ml-2 mb-2 pr-1">{moment(new Date()).format('[le] dddd DD MMMM[, de 8:00 à 12:00]')}</p>
        </div>
      </div>
    </div>
  );
};

const Style3 = ({}) => {
  return (
    <div className="border-b border-gray-400 p-2">
      <div
        className="flex gap-4"
        style={{
          backgroundImage:
            'url(https://www.corpo-events.fr/wp-content/uploads/2013/09/organisation-seminaire-incentive-840x334.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className="h-full w-full text-white content-end items-center justify-start text-center"
          style={{ background: 'rgba(0, 0, 0, 0.5)' }}
        >
          <div className="flex items-center justify-center mt-2">
            <p className="font-bold bg-black px-2 py-1">Titre</p>
          </div>
          <p className="mb-2 font-bold">8:00 - 12:00</p>
        </div>
      </div>
    </div>
  );
};

const styleViewArray = [
  { value: 'CLASSIC', label: 'Style 1' },
  { value: 'TWO', label: 'Style 2' },
  { value: 'DETAIL', label: 'Style 3' },
];

export default function StyleViewComponent({ clickedData, setClickedData }) {
  const { config } = useContext(ApplicationContext);
  const [styleHover, setStyleHover] = useState(null);
  const [x, setX] = useState();
  const [y, setY] = useState();

  useEffect(() => {
    const update = (e) => {
      setX(e.clientX + window.pageXOffset);
      setY(e.clientY + window.pageYOffset);
    };
    window.addEventListener('mousemove', update);
    window.addEventListener('touchmove', update);
    return () => {
      window.removeEventListener('mousemove', update);
      window.removeEventListener('touchmove', update);
    };
  }, [setX, setY]);

  /*<Select
          className="w-full"
          options={styleViewArray}
          value={styleViewArray.find((e) => e.value === clickedData.style_view)}
          onChange={(e) => setClickedData({ ...clickedData, style_view: e.value })}
        />*/

  const styleCell = (style, type, number) => {
    return (
      <p
        className={
          'border p-3 rounded border-gray-600 text-gray-600 cursor-pointer ' +
          (clickedData.style_view == type ? 'border-blue-500 bg-blue-100 text-blue-500' : '')
        }
        onMouseEnter={() => setStyleHover(style)}
        onMouseLeave={() => setStyleHover(null)}
        onClick={() => {
          setClickedData({ ...clickedData, style_view: type });
        }}
      >
        Format n°{number}
      </p>
    );
  };
  return (
    <div className="py-2">
      <p className="font-medium text-sm text-left mb-2">Sous quel format voulez-vous afficher vos évènements ?</p>
      <div className="grid grid-cols-3 gap-3">
        {styleCell(<Style1 config={config} />, 'DETAIL', 1)}
        {/*{styleCell(<Style2 config={config} />, 'CLASSIC', 2)}*/}
        {/*{styleCell(<Style3 config={config} />, 'TWO', 3)}*/}
      </div>

      {styleHover && (
        <div
          className="border rounded shadow"
          style={{
            position: 'absolute',

            width: 300,
            top: y + 50,
            left: x - 150,
            backgroundColor: 'white',

            zIndex: 10000,
          }}
        >
          {styleHover}
        </div>
      )}
    </div>
  );
}
