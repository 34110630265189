import { useContext } from "react";
import { ApplicationContext } from "../../../../Context/ApplicationContext";

const SubEventCell = ({subEvent, onClick = null}) => {
  const {langKey} = useContext(ApplicationContext);


  const getTitle = () => {
    let title = subEvent.informations[`title${langKey}`];
    if (!title || !title.length) {
      title = subEvent.informations.title;
    }
    return title;
  }

  return (
    <div onClick={onClick}>
      <div className="hover:bg-indigo-50 hover:bg-opacity-50 cursor-pointer transition flex flex items-center lg:items-center rounded-lg border text-left py-2 px-2 min-h-full">
        <div
          className={'flex flex-col ml-0 text-gray-700 border-indigo-100 py-1 px-2'}
          style={{width: '100%'}}>
          <p className="font-normal text-md ">{getTitle()}</p>
        </div>
      </div>
    </div>
  );
}

export default SubEventCell
