import IphonePreview from "../Account/Admin/AppDesign/Iphone/IphonePreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ModulePreview = ({key, module}) => {
  return (
    <div key={key} className={`${module.fullSize ? 'col-span-2' : ''} object-contain flex items-center justify-center`}>
      <img src={module.image} alt=""></img>
      {/*<p className='absolute text-white'>{module.name}</p>*/}
    </div>
  );
}

const EventkeyPhonePreview = ({modules}) => {
  return (
    <div className="relative">
      <IphonePreview>
        <div className="absolute left-0 top-0 bottom-0 right-0 bg-gray-100">

          <div className="h-16 bg-blue-100 pt-10 text-sm text-gray-700"> Page principale de votre application</div>

          <div className="grid grid-cols-2 gap-2 items-start pt-5 ml-2 mr-2">

            {modules && modules.modules && Object.keys(modules.modules).map((module, index) => {
              if (!module || !modules.modules[module]) return <></>;
              return (
                <ModulePreview key={index} module={modules.modules[module]}/>
              );
            })}
          </div>

          <div className="absolute bottom-0 w-full bg-neutral-50 border-t h-12 flex items-center justify-evenly">
            {modules && modules.tabBar && modules.tabBar.map(e => {
              return (
                <FontAwesomeIcon icon={e.image}/>
              );
            })}
          </div>
        </div>
      </IphonePreview>

    </div>
  );
}

export default EventkeyPhonePreview;