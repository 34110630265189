import CommonButton from "../../Common/Button/CommonButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/pro-solid-svg-icons";
import VotesSettingsObjects from "./votes.settings.objects";
import React from "react";

const VoteObjectListSettings = ({voteInfo, updateVoteInfo, pageState, setPageState}) => {
  return (
    <div className={`flex flex-col gap-2 space-y-3 overflow-hidden ${pageState === 1 ? "w-full px-2 py-4" : "w-0 h-0 transition-all duration-200"}`}>
      <div className="flex justify-end">
        <CommonButton customClassName="h-8 mr-1" useAppTheme={false} title="Sauvegarder" icon={<FontAwesomeIcon icon={faFloppyDisk} />} onClick={() => {
          setPageState(0);
        }} />
      </div>
      <VotesSettingsObjects voteInfo={voteInfo} updateVoteInfo={updateVoteInfo} pageState={pageState} />
    </div>
  );
}

export default VoteObjectListSettings
