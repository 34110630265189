import { pathologize } from "./pathologize";
import { pathToCoords } from './path-to-coordinates';

const turf = require('@turf/turf');

const SCALE = 1;
let NUM_POINTS = 250,
  currentFile;
let mapRef;
let mapDrawRef;
let newFeatures;

const onUpload = async (files, map, mapDraw) => {
  mapRef = map;
  mapDrawRef = mapDraw;
  const file = files[0];
  currentFile = file;
  const {type} = file;

  // this.setState({
  //   helpText: (
  //     <span className="loading loading--s loading--dark" />
  //   )
  // });

  if (type !== 'image/svg+xml') {
    // this.setState({
    //   helpText: 'File type must be SVG'
    // });
    return;
  }

  const reader = new FileReader();
  reader.addEventListener('load', d => {
    pathologize(d.target.result)
      .then(svgToGeoJSON)
      .catch(err => {
        console.error(err);
        // this.setState({
        //   helpText: 'Error parsing SVG'
        // });
      });
  });

  reader.readAsText(file);
};

const svgToGeoJSON = svgString => {
  // Create an empty container to fetch paths using the dom
  const empty = document.createElement('div');
  empty.innerHTML = svgString;

  const coordinates = calculateCoords(empty.querySelector('svg'));
  const paths = empty.querySelectorAll('path');
  //
  if (!paths.length) {
    // this.setState({
    //   helpText: 'No paths were found in this SVG'
    // });
    return;
  }

  newFeatures = Array
    .from(paths)
    .map(path => pathToCoords(
      path,
      SCALE,
      NUM_POINTS,
      coordinates.x,
      coordinates.y
    ))
    .map(buildFeature);
  // this.setState({
  //   helpText: 'Drag and drop an SVG on the map.'
  // });
};

const calculateCoords = svg => {
  const {x, y} = {x: 100, y: 100};

  // Attempt a couple methods to get width/height values on the SVG element
  // to return reasonable x/y coordinates on the map.
  let {width, height} = svg.getBBox();

  if (width === 0 && svg.getAttribute('width')) {
    width = parseInt(svg.getAttribute('width'), 10);
  }

  if (height === 0 && svg.getAttribute('height')) {
    height = parseInt(svg.getAttribute('height'), 10);
  }

  return {
    x: x - (width / 2),
    y: y - (height / 2)
  }
};

const buildFeature = data => {
  const {path, coords} = data;

  let feature = {
    type: 'Feature',
    properties: {},
    geometry: {}
  }


  if (path.id) {
    feature.properties.id = path.id;
  }

  if (path.getAttribute("fill")) {
    feature.properties.fill = path.getAttribute("fill");
  }

  // If the first and last coords match it should be drawn as a polygon
  if (coords[0][0] === coords[coords.length - 1][0] &&
    coords[0][1] === coords[coords.length - 1][1]) {
    console.log('Polygon');

    feature.geometry = {
      type: 'Polygon',
      coordinates: [
        coords.map(d => {
          const c = mapRef.unproject(d);
          return [c.lng, c.lat];
        })
      ],
    };
    feature.properties = {type: 'polygon'};

  } else {
    const getSum = (total, num) => {
      return total + num;
    }
    try {
      // try to see if it should be a multipolygon
      let distances = [];
      let splits = [];
      coords.forEach((c, idx) => {
        if (idx > 0) {
          const from = turf.point([mapRef.unproject(coords[idx - 1])['lng'], mapRef.unproject(coords[idx - 1])['lat']]);
          const to = turf.point([mapRef.unproject(c)['lng'], mapRef.unproject(c)['lat']]);
          const options = {units: 'miles'};

          const distance = turf.distance(from, to, options);
          // get distances between points
          distances.push(distance);
        }
      });

      const distAvg = distances.reduce(getSum) / distances.length;
      coords.forEach((c, idx) => {
        if (idx > 0) {
          const from = turf.point([mapRef.unproject(coords[idx - 1])['lng'], mapRef.unproject(coords[idx - 1])['lat']]);
          const to = turf.point([mapRef.unproject(c)['lng'], mapRef.unproject(c)['lat']]);
          const options = {units: 'miles'};
          const distance = turf.distance(from, to, options);
          // if the following coordinate is ~2.5 farther away than average, it is most likely a new polygon
          if (distance > distAvg * 2.5) {
            splits.push(idx);
          }
        }
      });

      // idx only gets to last split - needs to get to the end of the shape
      splits.push(NUM_POINTS);

      let newShapeArray = [];
      splits.forEach((s, idx) => {
        let shape = [];
        if (idx === 0) {
          for (let i = 0; i < s; i++) {
            shape.push([mapRef.unproject(coords[i])['lng'], mapRef.unproject(coords[i])['lat']]);
          }
        } else {
          for (let i = splits[idx - 1]; i < s; i++) {
            shape.push([mapRef.unproject(coords[i])['lng'], mapRef.unproject(coords[i])['lat']]);
          }
        }
        newShapeArray.push([shape]);
      });

      newShapeArray.forEach(shape => {
        shape[0].push(shape[0][0]);
      });

      console.log('MultiPolygon');
      feature.geometry = {
        type: 'MultiPolygon',
        coordinates: newShapeArray
      };
    } catch (err) {
      console.log('LineString');
      feature.geometry = {
        type: 'LineString',
        coordinates: coords.map(d => {
          const c = mapRef.unproject(d);
          return [c.lng, c.lat];
        }),
      };
      feature.properties = {type: 'route'};
    }
  }

  mapDrawRef.add(feature);
};

export { onUpload };