import { useState } from 'react';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import LinksListComponent from '../Module/Links/LinksListComponent';
import CalendarMyDayComponent from '../Module/MyDay/CalendarMyDayComponent';
import ApplicationLink from './Admin/ApplicationLink';
import SelectDesignComponent from './Admin/Design/SelectDesignComponent';
import GroupManagerComponent from './Admin/GroupManager/GroupManagerComponent';
import ImportPictureComponent from './Admin/ImportPicture/ImportXlsxComponent';
import Informations from './Admin/Informations/InfosColorsComponent';
import NotificationComponent from './Admin/Notification/NotificationComponent';
import Administration from './Admin/SuperAdmin/AdminComponent';
import TicketsComponent from './Admin/Tickets/TicketsComponent';
import MyEventsComponent from './MyEventsComponent';
import ProfilForm from './ProfilForm';
import ProfilMenu from './ProfilMenu';

function ProfilComponent() {
  const [selectedMenu, setSelectedMenu] = useState('MODIFY');
  const [hidden, setHidden] = useState(true);

  return (
    <div className="py-10">
      <div className="md:hidden text-gray-700 bg-white border-gray-300 border mb-2 text-center rounded justify-center items-center w-full">
        <p onClick={() => setHidden(!hidden)} className="px-1 py-2 cursor-pointer text-center font-medium ">
          Menu
          {hidden && <BsArrowDownCircle className="ml-2 inline" />}
          {!hidden && <BsArrowUpCircle className="ml-2 inline" />}
        </p>
      </div>
      <ApplicationLink />
      <div className="bg-white w-full sm:w-3/4 md:w-3/4 xl:w-2/4 m-auto grid grid-cols-8 rounded border border-gray-300">
        <div className={(hidden ? 'hidden' : '') + ' md:block border-r border-b md:border-b-0 border-gray-300 block col-span-8 sm:col-span-2'}>
          <ProfilMenu
            onSelectedMenu={(v, reload) => {
              setSelectedMenu(v);
              if (reload) {
                setHidden(true);
              }
            }}
          />
        </div>
        {selectedMenu && (
          <div className="col-span-8 sm:col-span-6 py-5 pb-20">
            {selectedMenu.type === 'MODIFY' && <ProfilForm />}
            {selectedMenu.type === 'APPOINTMENT' && <CalendarMyDayComponent />}
            {selectedMenu.type === 'CALENDAR' && <MyEventsComponent />}
            {selectedMenu.type === 'LINKS' && <LinksListComponent link={selectedMenu.param} module={selectedMenu} />}
            {selectedMenu.type === 'INFORMATIONS' && <Informations />}
            {selectedMenu.type === 'IMPORT' && <ImportPictureComponent />}
            {selectedMenu.type === 'DESIGN' && <SelectDesignComponent modal />}
            {selectedMenu.type === 'GROUPS' && <GroupManagerComponent />}
            {selectedMenu.type === 'ADMINISTRATION' && <Administration />}
            {selectedMenu.type === 'TICKETS' && <TicketsComponent />}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilComponent;
