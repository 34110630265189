import { faArrowsAlt, faArrowsAltH, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { HelpText } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import AddButton from '../../../../Common/Button/AddButton';
import TrashButton from '../../../../Common/Button/TrashButton';
import { UpdateJsonConfig } from '../../../../../Services/Application';
import LinkCell from './LinksCell';
import LinkTable from './LinksTable';

const ObjectOption = ({ value, setValue }) => {
  const { application, config } = useContext(ApplicationContext);

  return (
    <select
      className="border px-2 py-1 w-full bg-blue-100 text-blue-400 rounded border-blue-400"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    >
      <option value="">Choisir</option>
      {config.customObject.map((e) => {
        return <option value={e.id}>{e.name}</option>;
      })}
      <option value="ukEvent">Événement</option>
    </select>
  );
};

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function LinkList() {
  const { application, config } = useContext(ApplicationContext);

  const [form, setForm] = useState({});

  const [newLink, setNewLink] = useState({});

  useEffect(() => {
    setForm(config);
  }, [config]);

  if (!form || !form.links) {
    return null;
  }

  return (
    <div className="w-2/3 m-auto py-4">
      <p className="text-left text-2xl font-medium">Liens entre vos objets</p>
      <p className="font-light text-left">Ici vous pouvez créer/modifier/supprimer des liens entre vos objets</p>

      <LinkTable></LinkTable>
    </div>
  );
}
