import { useCallback, useContext, useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Card, FileSelector, HelpText, Spinner, ZoomableImage } from 'react-rainbow-components';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../../Context/ApplicationContext';
import { UserContext } from '../../../Context/UserContext';
import { CreateObject, GetObjectsAll, UpdateObject } from '../../../Services/UKObject';
import { UploadFile, UploadFileAll } from '../../../Services/Utils';
import CommonButton from "../../Common/Button/CommonButton";
import CustomEventComponent from "../Events_old/CustomEventComponent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

var style = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'rgba(243, 244, 246, 1)',
    borderWidth: 0,
    color: 'red',

    fontSize: '0.875rem',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: 'rgba(55, 65, 81, 0.6)',
    };
  },
};

function ObjectDetailEditComponent({ objectClass, setEditMode, reloadObject, lang }) {
  var _fileInput = null;
  const history = useHistory();
  const [groups, setGroups] = useState(null);
  const { application, config } = useContext(ApplicationContext);
  const { user, reloadLinks } = useContext(UserContext);
  const [form, setForm] = useState(null);
  const [isSendingFile, setIsSendingFile] = useState(false);
  let query = useQuery();
  const errorToast = (text) => toast.error(text, { autoClose: 2000, position: 'bottom-center' });

  useEffect(() => {
    async function initGroups() {
      if (!application || !user) return null;
      const groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
      setGroups(groups);
    }
    initGroups();
  }, [application, user]);
  const onChangeHandler = async (event) => {
    uploadFile(event.target.files[0]);
  };

  const uploadFile = async (fileName, number) => {
    if (fileName == null) {
      return;
    }
    setIsSendingFile(true);
    const file = await UploadFile(fileName, application.apiKey, (user || {}).authToken);
    setIsSendingFile(false);
    if (file == null) {
      errorToast("Impossible de charger l'image");
      return;
    }
    var url = file.url;
    setForm({
      ...form,
      [objectClass.getPictureId()]: url,
    });
  };

  const handleSubmit = async () => {
    if (objectClass.json._id == null) {
      const newObject = await CreateObject(objectClass.json.type, form, application.apiKey, (user || {}).authToken);

      var idLink = query.get('id');
      var idType = query.get('type');
      if (idLink != null && idType != null) {
        await CreateObject(
          'ukLink',
          {
            id2: idLink,
            type2: idType,
            id1: newObject._id,
            type1: objectClass.json.type,
            state: '1',
          },
          application.apiKey,
          (user || {}).authToken
        );
        await reloadLinks();
      }

      history.push(`/v/object/${newObject._id}`);
    } else {
      await UpdateObject(objectClass.json._id, objectClass.json.type, form, application.apiKey, (user || {}).authToken);
      setEditMode(false);
      reloadObject();
    }
  };

  useEffect(() => {
    setForm(objectClass.json.informations);
  }, [objectClass]);

  const uploadFileDocument = useCallback(
    async (fileName, id, index) => {
      if (fileName == null) {
        return;
      }
      var arr = form[id] || [];
      arr.push('load_' + index);
      var newForm = {};
      newForm[id] = arr;
      setForm((e) => {
        return { ...e, ...newForm };
      });

      try {
        const file = await UploadFileAll(fileName, application.apiKey, (user || {}).authToken);
        if (file == null) {
          let arr = form[id] || [];
          let index = arr.findIndex((e) => e === 'load_' + index);
          arr.splice(index, 1);
          let newForm = {};
          newForm[id] = arr;
          setForm((e) => {
            return { ...e, ...newForm };
          });
          errorToast("Impossible de charger l'image");
          return;
        }
        var url = file.url;
        let arr = form[id] || [];
        let index2 = arr.findIndex((e) => e === 'load_' + index);
        arr.splice(index2, 1);
        arr.push(url);
        let newForm = {};
        newForm[id] = arr;
        setForm((e) => {
          return { ...e, ...newForm };
        });
      } catch (e) {}
      //onUpdate(url);
    },
    // eslint-disable-next-line
    [form]
  );

  return (
    <div className="col-span-6 py-5  px-4">
      <div className="grid grid-cols gap-4 gap-y-6">
        <div key={'objectupdatelogo'} className={'text-left flex items-center mb-3 relative'}>
          <div className="relative">
            <input
              ref={(fileInput) => (_fileInput = fileInput)}
              type="file"
              name="file"
              onChange={onChangeHandler}
              className={'hidden'}
              accept="image/*"
            />

            <img
              className="block text-gray-700 text-sm font-semibold w-auto text-right mr-3"
              style={{ maxHeight: 100 }}
              src={(form || {})[objectClass.getPictureId()] || objectClass.getPicture()}
              alt=""
            ></img>
            <div
              onClick={() => _fileInput.click()}
              className="flex opacity-0 hover:opacity-100 cursor-pointer transform transition-all duration-150"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: '#000000AA',
                color: 'white',
                width: '100%',
                height: '100%',

                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              <p>Edit</p>
            </div>

            {isSendingFile && (
              <div
                className="flex cursor-pointer transform transition-all duration-150"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: '#000000AA',
                  color: 'white',
                  height: '100%',
                  width: '100%',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-10 w-10"></div>
              </div>
            )}
          </div>
          <p className="w-full block text-gray-700 text-xl font-bold text-left "></p>
        </div>
        {form &&
          objectClass.config.custom.map((elem, index) => {
            if (elem.hidden === true) {
              return null;
            }
            if (elem.type === 'LINK') {
              return null;
            }
            if (elem.type === 'picture') {
              return null;
            }

            var input = (
              <input
                className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                type="text"
                name={elem.id}
                id={elem.id}
                placeholder={elem.placeholder}
                value={form[lang ? `${elem.id}_${lang.id}` : elem.id] || ''}
                onChange={(e) =>
                  setForm({
                    ...form,
                    [lang ? `${elem.id}_${lang.id}` : elem.id]: e.target.value,
                  })
                }
              />
            );

            if (elem.type === 'cv' || elem.type === 'keywords') {
              return null;
            }
            if (elem.type === 'bigtext') {
              input = (
                <textarea
                  rows={10}
                  className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline   "
                  type="text"
                  name={elem.id}
                  id={elem.id}
                  placeholder={elem.placeholder}
                  value={form[lang ? `${elem.id}_${lang.id}` : elem.id] || ''}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [lang ? `${elem.id}_${lang.id}` : elem.id]: e.target.value,
                    })
                  }
                ></textarea>
              );
            }
            if (elem.type === 'video') {
              input = (
                <div className="w-full flex  items-center gap-3 ">
                  <input
                    className="block text-sm appearance-none rounded w-full py-2 px-3 text-gray-700 bg-gray-100 leading-tight focus:outline-none focus:shadow-outline h-10  "
                    type="text"
                    name={elem.id}
                    id={elem.id}
                    placeholder={elem.placeholder}
                    value={form[elem.id] || ''}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [elem.id]: e.target.value,
                      })
                    }
                  />
                  <HelpText
                    variant="question"
                    title="Quoi mettre ?"
                    text={<p style={{}}>Merci de renseigner l'url de votre vidéo ou un lien youtube</p>}
                  />
                </div>
              );
            }
            if (elem.type === 'documents') {
              input = (
                <div className="w-full">
                  <FileSelector
                    placeholder="Glisser-déposer ou cliquer pour parcourir"
                    value={null}
                    onChange={(value) => {
                      uploadFileDocument(value[0], elem.id, (form[elem.id] || []).length);
                    }}
                  ></FileSelector>
                  <div className="grid grid-cols-3 pt-4 gap-3">
                    {(form[elem.id] || []).map((e, indexDoc) => {
                      if (e.indexOf('load_') >= 0) {
                        return (
                          <Card className="relative" style={{ height: 100 }}>
                            <Spinner />
                          </Card>
                        );
                      }
                      if (e.indexOf('.pdf') >= 0) {
                        return (
                          <div className="relative">
                            <button
                              onClick={() => {
                                var arr = form[elem.id] || [];
                                arr.splice(indexDoc, 1);
                                var newForm = {};
                                newForm[elem.id] = arr;
                                setForm((e) => {
                                  return { ...e, ...newForm };
                                });
                              }}
                              style={{
                                position: 'absolute',
                                top: -11,
                                right: -11,
                                zIndex: 10000,
                              }}
                            >
                              <AiFillCloseCircle color={'red'} size={22} />
                            </button>
                            <Card className="relative flex items-center justify-center" style={{ height: 100 }}>
                              <img className=" object-cover" style={{ height: 50 }} src="/pdf.png" alt="" />
                            </Card>
                          </div>
                        );
                      }
                      return (
                        <div className="relative">
                          <button
                            onClick={() => {
                              var arr = form[elem.id] || [];
                              arr.splice(indexDoc, 1);
                              var newForm = {};
                              newForm[elem.id] = arr;
                              setForm((e) => {
                                return { ...e, ...newForm };
                              });
                            }}
                            style={{
                              position: 'absolute',
                              top: -11,
                              right: -11,
                              zIndex: 10000,
                            }}
                          >
                            <AiFillCloseCircle color={'red'} size={22} />
                          </button>
                          <Card className="relative flex items-center justify-center" style={{ height: 100 }}>
                            <ZoomableImage
                              src={e}
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }
            if (elem.type === 'selector' || elem.type === 'multipleSelector') {
              var options = elem.values.map((e) => {
                return { value: e, label: e };
              });
              var style = {
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: 'rgba(243, 244, 246, 1)',
                  borderWidth: 0,
                  color: 'red',

                  fontSize: '0.875rem',
                }),
                placeholder: (defaultStyles) => {
                  return {
                    ...defaultStyles,
                    color: 'rgba(55, 65, 81, 0.6)',
                  };
                },
              };
              var defaultValue = null;
              if (form[lang ? `${elem.id}_${lang.id}` : elem.id]) {
                if (elem.type === 'multipleSelector') {
                  var arr = form[lang ? `${elem.id}_${lang.id}` : elem.id].split('$$//$$');
                  defaultValue = [];
                  arr.forEach((elem) => {
                    var i = options.findIndex((e) => e.value === elem);
                    defaultValue.push(options[i]);
                  });
                } else {
                  defaultValue = options[options.findIndex((e) => e.value === form[lang ? `${elem.id}_${lang.id}` : elem.id])];
                }
              }
              input = (
                <Select
                  placeholder="Selectionner"
                  styles={style}
                  value={defaultValue}
                  options={options}
                  name={elem.id}
                  isMulti={elem.type === 'multipleSelector'}
                  onChange={(a, b) => {
                    if (elem.type === 'multipleSelector') {
                      setForm({
                        ...form,
                        [lang ? `${elem.id}_${lang.id}` : elem.id]: a.map((e) => e.value).join('$$//$$'),
                      });
                    } else {
                      setForm({
                        ...form,
                        [lang ? `${elem.id}_${lang.id}` : elem.id]: a.value,
                      });
                    }
                  }}
                />
              );
            }
            if (elem.type === 'link') {
              input = (
                <div className="ml-20">
                  <CustomEventComponent config={elem} index={index} form={form} setform={setForm} lang={lang} />
                </div>
              );
            }

            // TODO ICICICIICICI
            return (
              <div
                key={'objectupdate' + index}
                className={(elem.type === 'bigtext' || elem.type === 'documents' ? 'items-start ' : 'items-center ') + 'text-left flex '}
              >
                {/*<label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">{elem.tint}</label>*/}
                {input}
              </div>
            );
          })}
        {form && groups && (
          <div className="flex text-left items-center">
            <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3">Groupe(s)</label>
            <Select
              placeholder="Selectionner"
              styles={style}
              isMulti={true}
              value={
                form.groups
                  ? form.groups.map((e) => {
                      return {
                        value: groups.find((el) => {
                          return el.informations.index === e.toString();
                        }).informations.name,
                        label: groups.find((el) => {
                          return el.informations.index === e.toString();
                        }).informations.name,
                      };
                    })
                  : null
              }
              options={groups.map((e) => {
                return { value: e.informations.name, label: e.informations.name };
              })}
              onChange={(a, b) => {
                setForm({ ...form, groups: a.map((e) => groups.find((el) => el.informations.name === e.value).informations.index.toString()) });
              }}
            />
          </div>
        )}
      </div>
      <div className="px-0 pb-2 pt-4 flex">
        <label className="block text-gray-700 text-sm font-semibold w-1/3 text-right mr-3"></label>
        <div className="w-full text-left">
          <CommonButton customClassName="w-max" title="Valider" onClick={() => handleSubmit()}></CommonButton>
        </div>
      </div>
    </div>
  );
}

export default ObjectDetailEditComponent;
