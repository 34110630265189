import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FileSaver from "file-saver";
import { useContext, useState } from "react";
import * as XLSX from "xlsx";
import { ApplicationContext } from "../../../Context/ApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { GetUsersAll } from "../../../Services/User";
import CommonButton from "../../Common/Button/CommonButton";

export default function UsersExport({ filters, userType }) {
    const [state, setState] = useState(0);
    const { application, config } = useContext(ApplicationContext);
    const { user } = useContext(UserContext);

    const exportFile = async () => {
        if (state !== 0) {
            return;
        }
        setState(1);
        var userConfig = config.inscription.customs.find((elem) => elem.id === userType);
        var allusers = [];
        var page = 0;
        var newObjs = await GetUsersAll(page, application.apiKey, (user || {}).authToken, filters, userType);
        while (newObjs.length > 0) {
            allusers = allusers.concat(newObjs);
            page += 1;
            newObjs = await GetUsersAll(page, application.apiKey, (user || {}).authToken, filters, userType);
        }
        setState(2);
        allusers = allusers.map((e) => e.informations);
        if (userConfig) {
            allusers = allusers.map((e) => {
                var keys = Object.keys(e);
                for (let key of keys) {
                    var id = userConfig.custom.find((e) => e.id === key);
                    if (id && id !== "password" && key !== "groups") {
                        e[id.tint] = e[key];
                        delete e[key];
                    }
                    if (id == "password" || key == "password") {

                        delete e[key];
                    }
                }
                return e;
            });
        }
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(allusers);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "export" + fileExtension);
        setState(0);
    };
    return (
        <CommonButton
            title={state === 0 ? "Exporter" : state === 1 ? "Récupération ..." : "Création du fichier"}
            onClick={() => {
                //setModalOpen(true);
                exportFile();
            }}
            icon={<FontAwesomeIcon icon={faFileExcel} />}
            customClassName="h-8"
        ></CommonButton>
    );
}
