import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";

const infos = {
  route: {
    title: 'Routes',
    description: "Ce mode vous permet de tracer les routes par lesquelles il est possible de passer. Ces routes sont utilisées par l'outil de recherche d'itinéraire. Elles s'affichent en bleu sur la carte"
  },

  polygon: {
    title: 'Polygone',
    description: "Ce mode vous permet de tracer des polygones."
  },

  square: {
    title: 'Rectangle / Carré',
    description: 'Ce mode vous permet de tracer des rectangle en restant appuyé sur Clic Gauche. Vous pouvez tracer un carré en restant appuyé sur LSHIFT en même temps.'
  },

  poi: {
    title: "Point d'intérêt",
    description: "Ce mode vous permet de placer un point d'intérêt."
  },

  text: {
    title: 'Texte',
    description: 'Ce mode vous permet de placer un texte.'
  },

  image: {
    title: 'Image',
    description: 'Ce mode vous permet de placer une image.'
  },

  pattern: {
    title: 'Image Patron',
    description: "Ce mode vous permet de placer une image patron d'une légère opacité. Vous pouvez utiliser le plan d'un salon afin de replacer les stands plus précisément. Pour la sélectionner ou la déplacer, rester appuyer sur LSHIFT"
  },

  link: {
    title: 'Lien',
    description: "Vous pouvez placer un point qui definira un point de passage d'un batiment vers un autre."
  }
}

const MapModeToolTipView = ({state, setModeTooltipStates}) => {
  const closeTooltip = () => {
    setModeTooltipStates({...state, isOpen: false});
  }

  const openTooltip = () => {
    setModeTooltipStates({...state, isOpen: true});
  }

  if (!state.mode || !infos[state.mode]) return <></>
  return (
    <div>
      <div className={`absolute bottom-5 right-3 flex items-center justify-center rounded overflow-hidden ${state.isOpen ? 'bg-gray-100 h-max w-[400px]' : 'bg-neutral-50 w-8 h-8'}`}
           onMouseEnter={openTooltip}
           onMouseLeave={closeTooltip}>
        <FontAwesomeIcon className={`${state.isOpen ? 'animate-none' : 'animate-pulse'}`} size="2x" icon={faCircleQuestion} />
        <div className={`overflow-hidden ${state.isOpen ? 'w-full h-max bg-neutral-50' : 'w-0 h-0'}`}>
          <div className={`relative flex justify-center items-center bg-blue-200`}>
            <p className="text-lg text-gray-900">{infos[state.mode]?.title || ''}</p>
          </div>
          <p className="ml-1 mr-1 text-sm text-gray-800">{infos[state.mode]?.description || ''}</p>
        </div>
      </div>
    </div>
  );
}

export default MapModeToolTipView
