import { useEffect, useState } from "react";
import DocsCustoms from "./imagestyle.custom";

const ImageStyleSelector = ({ doc, icon, setIcon, image, setImage, setDocSelected, menuOpen }) => {
    const [styleHovered, setStyleHovered] = useState(null);
    const [styleSelected, setStyleSelected] = useState(null);
    const [x, setX] = useState();
    const [y, setY] = useState();

    useEffect(() => {
        const update = (e) => {
            setX(e.clientX + window.pageXOffset - (menuOpen ? 288 : 0));
            setY(e.clientY + window.pageYOffset);
        };
        window.addEventListener("mousemove", update);
        window.addEventListener("touchmove", update);
        return () => {
            window.removeEventListener("mousemove", update);
            window.removeEventListener("touchmove", update);
        };
    }, [setX, setY]);

    useEffect(() => {
        setStyleHovered(null);
        setStyleSelected(null);
        setDefaultStyle();
    }, [doc._id]);

    const setDefaultStyle = () => {
        DocsCustoms.find((style) => {
            let styleId;
            if (
                style().id ===
                (doc.informations.imageStyle === "small" && !doc.informations.icon
                    ? "custom-image-1"
                    : doc.informations.imageStyle === "large"
                    ? "custom-image-3"
                    : "custom-image-2")
            )
                styleId = style().id;
            setStyleSelected(styleId);
            return styleId;
        });
    };

    const setImageStyle = (style) => {
        setDocSelected((prevDoc) => {
            let newDoc = { ...prevDoc };
            newDoc.informations.imageStyle = style().imageStyle;
            return newDoc;
        });
    };

    return (
        <div>
            <div className="flex gap-2 justify-around items-center">
                {DocsCustoms &&
                    DocsCustoms.map((style, index) => {
                        return (
                            <div
                                key={index}
                                className="w-full border border-gray-500 text-gray-500 transition-all hover:border-blue-400 hover:text-blue-400 hover:bg-blue-200 rounded px-8 py-2 cursor-pointer"
                                onMouseEnter={() => {
                                    setStyleHovered(style().hovered);
                                }}
                                onMouseLeave={() => {
                                    setStyleHovered(null);
                                }}
                                onClick={() => {
                                    setStyleSelected(() => {
                                        return style().id;
                                    });
                                    setImageStyle(style);
                                }}
                                style={{
                                    borderColor: style().id === styleSelected ? "#60A5FA" : "",
                                    color: style().id === styleSelected ? "#60A5FF" : "",
                                    backgroundColor: style().id === styleSelected ? "#60A5FA" + 66 : "",
                                }}
                            >
                                {style().placeholder}
                            </div>
                        );
                    })}
            </div>
            {styleSelected &&
                DocsCustoms.find((style) => {
                    return style().id === styleSelected;
                })({ icon, image, setIcon, setImage, doc, setDocSelected }).style}

            
            {styleHovered && (
                <div
                    className="flex border rounded shadow bg-gray-200"
                    style={{
                        position: "absolute",
                        width: 300,
                        top: y + 50,
                        left: x - 150,
                        zIndex: 10000,
                    }}
                >
                    {styleHovered}
                </div>
            )}
        </div>
    );
};

export default ImageStyleSelector;
