import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const SearchBarInput = forwardRef(({ onSearch, value, className = "" }, ref) => {
  const [search, setSearch] = useState('');
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setSearch(value || "")
  } , [value])
  useImperativeHandle(ref, () => ({
    resetSearch() {
      setSearch('');
    },
  }));

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!search || search.length === 0) {
        await onSearch(search);
        return;
      }
      setIsLoading(true);
      await onSearch(search);
      setIsLoading(false);
    }, 50);

    return () => {
      setIsLoading(false);
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  return (
    <div className={className + " border-gray-200 border rounded px-2 py-1 flex justify-end items-center " + (className ? "" : "bg-gray-100") }>
      <input
        type="text"
        className="focus:outline-none bg-transparent w-full"
        placeholder='Rechercher'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {loading && <FontAwesomeIcon icon={faSpinner} spin />}
      {!loading && <FontAwesomeIcon icon={faSearch} />}
    </div>
  );
});

export default SearchBarInput;
