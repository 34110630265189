import { UploadFile } from "../../../../Services/Utils";
import { toast } from "react-toastify";

const DrawPatternMode = {
  onSetup: function (pattern, map) {
    if (!pattern || !pattern.url) return;
    map.addSource('pattern', {
      'type': 'image',
      'url': pattern.url,
      'coordinates': pattern.coordinates,
    });
    map.addLayer({
      'id': 'pattern',
      'source': 'pattern',
      'type': 'raster',
    });
    map.setPaintProperty(
      'pattern',
      'raster-opacity',
      0.2
    );
  },

  onUpload: async function (img, map, application, user, MapConfigService) {
    if (!img) return;

    const file = await UploadFile(img, application.apiKey, user.authToken);
    if (!file) return toast.error("Impossible de charger l'image !", {autoClose: 2000, position: "bottom-center"});

    const {width, height} = map.getCanvas()
    const cUL = map.unproject ([0,0]).toArray()
    const cUR = map.unproject ([width,0]).toArray()
    const cLR = map.unproject ([width,height]).toArray()
    const cLL = map.unproject ([0,height]).toArray()
    const coordinates = [cUL,cUR,cLR,cLL];

    try {
      map.removeLayer('pattern')
      map.removeSource('pattern');
    } catch (e) {}

    MapConfigService.update({pattern: {url: file.url, coordinates: coordinates}});
    map.addSource('pattern', {
      'type': 'image',
      'url': file.url,
      'coordinates': [[0,1],[1,1],[1,0],[0,0],],
    });
    map.addLayer({
      'id': 'pattern',
      'source': 'pattern',
      'type': 'raster',
    });
    map.setPaintProperty(
      'pattern',
      'raster-opacity',
      0.2
    );
  },

  onDelete: function (map, MapConfigService) {
    try {
      map.removeLayer('pattern')
      map.removeSource('pattern');
    } catch (e) {}

    MapConfigService.update({pattern: {url: null, coordinates: null}});
  }
}

export default DrawPatternMode;