import { forwardRef, useImperativeHandle, useState } from "react";
import { v4 } from "uuid";
import TrashButton from "../../../Common/Button/TrashButton";
import SubEventCell from "./subevent.cell.component";
import SubEventDetailModal from "./subevent.detail.modal";
import SubEventNewModal from "./subevent.new.modal";

const SubEventInput = ({ custom, form, setForm, value }, ref) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [newEvent, setNewEvent] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setNewEvent(true);
        },
    }));

    const createNewSubEvent = (infos) => {
        var id = v4();
        let newSubEvent = {
            _id: id,
            id: id,
            archived: false,
            informations: infos,
            type: "ukEvent",
        };
        setForm({ ...form, subEvent: form.subEvent && form.subEvent.length > 0 ? [...form.subEvent, newSubEvent] : [newSubEvent] });
        setNewEvent(false);
    };

    const onModif = (newInfos) => {
        const newSubEvent = { ...selectedEvent, informations: newInfos };
        let newForm = { ...form };
        const index = newForm.subEvent.findIndex((f) => f._id === newSubEvent._id);

        newForm.subEvent[index] = newSubEvent;
        setForm(newForm);
        setSelectedEvent(null);
    };

    return (
        <div className="flex w-full grid grid-cols-1 gap-2">
            {value &&
                value.length > 0 &&
                value.map((subEvent, index) => {
                    return (
                        <div key={index} className="relative p-0">
                            <div className="absolute right-0 top-0">
                                <TrashButton
                                    className="px-2 py-1"
                                    onClick={() => {
                                        if (window.confirm("Voulez vous supprimer ?")) {
                                            setForm({
                                                ...form,
                                                subEvent: form.subEvent.filter((e) => e._id !== subEvent._id),
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <SubEventCell
                                subEvent={subEvent}
                                onClick={() => {
                                    setSelectedEvent(subEvent);
                                }}
                            />
                        </div>
                    );
                })}
            {selectedEvent && (
                <SubEventDetailModal
                    subEvent={selectedEvent}
                    isOpen={!!selectedEvent}
                    onClose={() => {
                        setSelectedEvent(null);
                    }}
                    onSubmit={onModif}
                />
            )}
            <SubEventNewModal
                isOpen={newEvent}
                onClose={() => {
                    setNewEvent(false);
                }}
                onSubmit={createNewSubEvent}
            />
            {(!value || value.length === 0) && <p className="text-sm italic text-gray-800 ml-2">Aucun Sous-événement</p>}
        </div>
    );
};

export default forwardRef(SubEventInput);
