import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import GL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import Modal from 'react-modal';
import { DateTimePicker } from 'react-rainbow-components';
import 'react-resizable/css/styles.css';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ApplicationContext } from '../../../../../Context/ApplicationContext';
import { UserContext } from '../../../../../Context/UserContext';
import { UpdateApplication, UpdateJsonConfig } from '../../../../../Services/Application';
import { GetObjectsAll } from '../../../../../Services/UKObject';
import SwitchCustom from '../../../../Common/SwitchCustom';
import CustomStylesLangSelect from '../../../../Other/CustomStylesLangSelect';
import PictureUpdate from '../../PictureUpdate';
import PreviewApp from './Component/PreviewApp';
import CreateModuleModalComponent from './CreateModuleModalComponent';
import EditDesignComponent from './EditDesignComponent';
import TabBarComponent from './TabBarComponent';

const GridLayout = WidthProvider(GL);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    minWidth: 500,
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
  },
};

export default function DesignComponent({
                                          modal = false,
                                          header,
                                          design,
                                          createIsOpen,
                                          setCreateIsOpen,
                                          pages,
                                          selectedPage,
                                          setSelectedPage,
                                          setIsOpen,
                                        }) {
  const {config, reloadConfig, application} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const [data, setData] = useState([]);
  const name = application.name;
  const isDragging = useRef(false);
  const [urlPhoto, seturlPhoto] = useState(application.informations.pictureUrl);
  const [clickedData, setClickedData] = useState(null);
  const [preview, setPreview] = useState({group: null, hour: null});
  const [groupList, setGroupList] = useState();
  const [lang, setLang] = useState();
  const [home, setHome] = useState(design);
  const [tabBar, setTabBar] = useState([]);
  const [tabBarData, setTabBarData] = useState([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (config && config.language) {
      let language = config.language.find((e) => e.isDefault === true);
      setLang(language);
    }
    if (config) {
      setTabBar(config.tabBarV2);
    }
  }, [config]);

  useEffect(() => {
    setHome(design);
    setClickedData(null);
  }, [design]);

  const initGroupList = async () => {
    if (!application || !user) return;
    let groups = await GetObjectsAll('ukGroup', application.apiKey, user.authToken);
    let defaultViews = [
      {id: 'Tous', name: 'N/A'},
      {id: 'Connecté', name: 'Utilisateur connecté'},
      {id: 'Non connecté', name: 'Utilisateur non connecté'},
    ];
    setGroupList([...defaultViews, ...groups.map((e) => ({...e.informations, id: e._id}))]);
    setPreview({...preview, group: 'Tous'});
  }

  useEffect(() => {
    initGroupList();
  }, [application, user]);

  const compactArray = (arr) => {
    let newArr = [...arr];
    for (let i = 1; i < newArr.length; i++) {
      let e = newArr[i];
      if (newArr[i - 1].w === 1 && newArr[i - 1].x === 0 && e.w === 1 && e.y === newArr[i - 1].y + 1) {
        e.y--;
        e.x++;
        for (let j = i + 1; j < newArr.length; j++) {
          let el = newArr[j];
          el.y--;
        }
      }
      if (e.x === 1 && e.w === 1 && newArr[i - 1].y !== e.y) e.x--;
      if (e.x === 2) e.x--;
    }
    return newArr;
  };

  const sortArray = (arr) => {
    // console.log('OSRT');
    let newArr = [...arr];
    const newData = [];
    newArr
      .sort((a, b) => {
        return a.x - b.x;
      })
      .sort((a, b) => {
        return a.y - b.y;
      });
    newArr = compactArray(newArr);
    newArr.forEach((e) => {
      let tempData = data.find((el) => el._id === e.i);
      tempData.width = e.w;
      tempData.x = e.x;
      tempData.y = e.y;
      newData.push(tempData);
    });
    setData(newData);
    if (clickedData) {
      let newClickedData = {...clickedData};
      newClickedData.index = newData.findIndex((e) => e._id === clickedData._id);
      setClickedData(newClickedData);
    }
  };

  const filterHomeGroups = (home) => {
    return home.filter((e) => {
      if (preview.group === 'Tous') return true;
      if (e.visibleFrom && preview.hour) {
        if (!moment(preview.hour).isBetween(moment(e.visibleFrom), moment(e.visibleTo))) return false;
      }
      if (preview.group === 'Non connecté') {
        if (e.state && e.state.split(',').find((e) => e === '0')) return true;
        return false;
      }
      if (preview.group === 'Connecté') {
        if (e.state && e.state.split(',').find((e) => e === '1')) return true;
        return false;
      }
      if (e.groupRestricted && preview.group) {
        let groups = e.groupRestricted.split(',');
        if (groups.find((el) => el === groupList.find((ele) => ele.id === preview.group).index)) return true;
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    let tab = tabBar.map((e, i) => {
      let obj = {...e};
      obj.x = i;
      obj.y = 0;
      obj.width = 1;
      obj.tabBar = true;
      return obj;
    });
    setTabBarData(tab);
  }, [tabBar, preview]);

  useEffect(() => {
    if (!home || !groupList) return null;
    const applicationHome = home.pages;
    let x = 0;
    let y = 0;
    const newData = filterHomeGroups(applicationHome).map((e) => {
      let width = e.style === 'FULLSQUARE' || e.style === 'CLASSIC' ? 2 : 1;
      if (e.type === 'TITLE') {
        width = 2;
        e.style = 'CLASSIC';
      }
      if (width === 2 && x === 1) {
        x = 0;
        y++;
      }
      const obj = {
        ...e,
        x: x,
        y: y,
        width: width,
      };
      if ((x === 0 && width === 2) || x === 1) {
        y++;
        x = 0;
      } else x++;
      return obj;
    });
    setData(newData);
    // eslint-disable-next-line
  }, [home, preview, groupList]);

  const saveCustomViewHome = (customHome) => {
    let newHome = {...home};
    let filteredHome = filterHomeGroups(newHome.pages);
    customHome.forEach((e) => {
      let index = newHome.pages.findIndex((el) => el._id === e._id);
      if (index !== -1) {
        newHome.pages[index] = e;
      } else {
        newHome.pages.push(e);
      }
    });
    filteredHome = filteredHome.filter((e) => {
      if (!customHome.find((el) => el._id === e._id)) return true;
      return false;
    });
    newHome.pages = newHome.pages.filter((e) => {
      if (filteredHome.find((el) => el._id === e._id)) return false;
      return true;
    });
    setHome(newHome);
    return newHome;
  };

  return (
    <>
      {data.find((e) => {
        let tempData = data.filter((el) => el._id === e._id);
        return tempData.length > 1;
      }) && <p className="text-red-700">Plusieurs éléments ont le même id !</p>}
      <button
        className={
          'bg-[#1da1f2] fixed right-5 bottom-5 mt-5 w-36 h-10 bg-gray-800 hover:opacity-80 transition-all text-white text-sm py-1 px-2 font-semibold rounded focus:outline-none focus:shadow-outline'
        }
        style={{
          color: (config || {}).colorOverMainColor ?? '#FFFFFF',
          backgroundColor: (config || {}).mainColor ?? '#000000',
        }}
        onClick={async () => {
          let tabData = tabBarData.map((e) => {
            let obj = {...e};
            delete obj.x;
            delete obj.y;
            delete obj.width;
            delete obj.index;
            delete obj.tabBar;
            return obj;
          });
          const newHome = saveCustomViewHome(data).pages.map((e) => {
            let obj = {...e};
            if (obj.width === 1) obj.style = 'HALFSQUARE';
            else if (obj.width === 2 && obj.image) obj.style = 'FULLSQUARE';
            else obj.style = 'CLASSIC';

            delete obj.x;
            delete obj.y;
            delete obj.width;
            delete obj.index;
            delete obj.tabBar;

            return obj;
          });
          if (design.name === 'Home')
            await UpdateJsonConfig(application.apiKey, {...config, home: newHome, tabBarV2: tabData});
          else {
            let newConfig = {...config, tabBarV2: tabData};
            if (config.customPage.find((e) => e.name === design.name))
              newConfig.customPage[config.customPage.findIndex((e) => e.name === design.name)].pages = newHome;
            else newConfig.customPage.push({name: design.name, title: design.title, pages: newHome});
            await UpdateJsonConfig(application.apiKey, newConfig);
          }
          await UpdateApplication(application.apiKey, user.authToken, application._id, {pictureUrl: urlPhoto});
          await reloadConfig();
          return toast.success('Modifications sauvegardées !', {autoClose: 2000, position: 'bottom-center'});
        }}
      >
        Publier
      </button>

      <div className={`${!modal ? 'grid grid-cols-8' : ''}`}>
        <div className="col-span-6 col-start-2 grid grid-cols-6 w-full gap-x-10">
          <div className={`${!modal ? 'col-span-2' : 'col-span-4'}`}>
            <div className="flex items-center justify-end gap-1">
              <p className="cursor-pointer font-light text-sm" onClick={() => setEditMode(!editMode)}>
                Edition:
              </p>
              <SwitchCustom checked={!editMode} setChecked={() => setEditMode(!editMode)} />
            </div>

            <div className={`overflow-y-scroll  w-full bg-white m-auto border-gray-400  rounded border`}>
              {!editMode && (
                <PreviewApp
                  urlPhoto={urlPhoto}
                  name={name}
                  seturlPhoto={seturlPhoto}
                  data={data}
                  isDragging={isDragging}
                  setClickedData={setClickedData}
                />
              )}
              {editMode && (
                <div style={{height: '60vh'}} className="w-full">
                  <PictureUpdate
                    url={urlPhoto}
                    customParentClassNames={'w-full relative m-auto'}
                    name={name}
                    onUpdate={(newUrl) => {
                      seturlPhoto(newUrl);
                    }}
                    rounded="true"
                    classnames="w-full block ml-auto mr-auto bg-transparent"
                  />
                  {design && data.length > 0 && (
                    <GridLayout
                      cols={2}
                      autoSize={true}
                      layout={data.map((e) => ({
                        x: e.x,
                        y: e.y,
                        w: e.width,
                        h: 1,
                        static: (preview.group !== null && preview.group !== 'Tous') || preview.hour !== null,
                      }))}
                      compactType={'vertical'}
                      onLayoutChange={(layout) => sortArray(layout)}
                      onDrag={() => {
                        isDragging.current = true;
                      }}
                      onDragStop={() => {
                        setTimeout(() => {
                          isDragging.current = false;
                        }, 500);
                      }}
                    >
                      {data.map((e, index) => {
                        return e.type !== 'TITLE' ? (
                          <div
                            onClick={() => {
                              if (!isDragging.current) {
                                setClickedData({...e, index: index});
                                window.scrollTo(0, 0);
                              }
                            }}
                            style={{
                              backgroundImage: `url(${e[lang ? `image_${lang.id}` : 'image']})`,
                              backgroundSize: 'contain',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                            }}
                            className="bg-gray-50"
                            key={e._id}
                            data-grid={{
                              x: e.x,
                              y: e.y,
                              w: e.width,
                              h: 1,
                              minW: 1,
                              maxW: 2,
                              minH: 1,
                              maxH: 1,
                              static: (preview.group !== null && preview.group !== 'Tous') || preview.hour !== null,
                            }}
                          >
                            {e.textHidden !== 'true' && (e.text || '').length > 0 && (
                              <div
                                style={{
                                  backgroundImage: 'linear-gradient(180deg, transparent, black)',
                                  backgroundSize: 'cover',
                                }}
                                className="h-full w-full text-white flex content-end items-end justify-start"
                              >
                                <p className="ml-2 mb-2">{e[lang ? `text_${lang.id}` : 'text']}</p>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (!isDragging.current) {
                                setClickedData({...e, index: index});
                                window.scrollTo(0, 0);
                              }
                            }}
                            key={e._id}
                            className="bg-gray-50 shadow flex items-center justify-center"
                          >
                            <div className="">
                              <p>{e[lang ? `text_${lang.id}` : 'text']} </p>
                            </div>
                          </div>
                        );
                      })}
                    </GridLayout>
                  )}
                </div>
              )}
            </div>
            <div className="bg-white mt-3">
              <TabBarComponent
                data={tabBarData}
                setData={setTabBarData}
                preview={preview}
                lang={lang}
                tabBar={tabBar}
                setClickedData={setClickedData}
                clickedData={clickedData}
                groupList={groupList}
              />
            </div>
          </div>
          {/* <Button
						label={"Créer"}
						onClick={() => {
							setCreateIsOpen(true)
							window.scrollTo(0, 0)
						}}
						style={{ color: "#413751" }}
					/> */}
          {!modal && (
            <div className="col-span-4">
              {header}
              {lang && (
                <div className="w-full flex items-center gap-x-4 border-gray-200 bg-white p-3 rounded border-2 relative">
                  <Select
                    className="w-36"
                    options={config.language.map((e) => {
                      return {value: e.id, label: <CustomStylesLangSelect lang={e} />};
                    })}
                    value={{value: lang.id, label: lang.name}}
                    onChange={(e) => {
                      setLang(config.language.find((el) => e.value === el.id));
                    }}
                  />
                </div>
              )}
              <div className="w-full flex border-gray-200 bg-white px-1 py-2 border  rounded  relative">
                <div className="flex items-center  w-full">
                  <p className="text-sm font-light mr-1">Voir en tant que:</p>
                  {groupList && (
                    <Select
                      className="w-1/2"
                      options={groupList.map((e) => ({value: e.id, label: e.name}))}
                      value={
                        preview.group
                          ? {value: preview.group, label: groupList.find((e) => preview.group === e.id).name}
                          : null
                      }
                      onChange={(e) => {
                        saveCustomViewHome(data);
                        setPreview({...preview, group: e.value});
                      }}
                    />
                  )}
                </div>
                <div className="flex justify-end items-center w-full gap-x-3">
                  <p className="text-sm">À la date du</p>
                  <DateTimePicker
                    hour24
                    className=""
                    style={{width: '50%'}}
                    cancelLabel={'Annuler'}
                    locale={'fr-Fr'}
                    label={'Heure'}
                    value={preview.hour}
                    onChange={(e) => {
                      saveCustomViewHome(data);
                      setPreview({...preview, hour: e});
                    }}
                  />
                  {preview.hour && (
                    <button
                      onClick={() => {
                        saveCustomViewHome(data);
                        setPreview({...preview, hour: null});
                      }}
                      className="text-red-600"
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
              {clickedData && (
                <div className="border-gray-200 w-full rounded border-2 bg-white">
                  <EditDesignComponent
                    clickedData={clickedData}
                    setClickedData={setClickedData}
                    allData={data}
                    setAllData={setData}
                    lang={lang}
                    design={design}
                    urlPhoto={urlPhoto}
                    saveHome={saveCustomViewHome}
                    tabBarData={tabBarData}
                  />
                </div>
              )}
            </div>
          )}
          {!modal && <span />}
        </div>
      </div>
      {modal && (
        <Modal isOpen={clickedData} style={customStyles}>
          <EditDesignComponent
            clickedData={clickedData}
            setClickedData={setClickedData}
            allData={data}
            setAllData={setData}
            lang={lang}
            design={design}
            urlPhoto={urlPhoto}
            saveHome={saveCustomViewHome}
            tabBarData={tabBarData}
          />
        </Modal>
      )}
      <CreateModuleModalComponent
        isOpen={createIsOpen}
        onUpdate={(newData) => {
          setClickedData(newData);
          setCreateIsOpen(false);
        }}
      />
    </>
  );
}
