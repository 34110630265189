import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PreviewAppTabbar = ({appinfo}) => {
  return (
    <div
      className="overflow-hidden w-full object-contain absolute z-50"
      style={{
        height: 60,
        color: "white",
        backgroundColor: appinfo.navbarColor,
      }}
    >
      <div className="flex justify-between items-center mt-6 ml-3 mr-3 relative">
        <div className="flex items-center cursor-pointer">
          <FontAwesomeIcon icon="chevron-left" color={appinfo.colorOverNavbar} />
          <p className="text-sm ml-2" style={{color: appinfo.colorOverNavbar}}>Retour</p>
        </div>
        <img className="h-[30px] absolute left-0 right-0 m-auto mt-[10px]" src={appinfo.logo || ""} alt="logo" />
        <FontAwesomeIcon className="cursor-pointer" icon="plus" color={appinfo.colorOverNavbar} />
      </div>
    </div>
  );
}

export default PreviewAppTabbar
