import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationContext } from "../../../../Context/ApplicationContext";
import { UserContext } from "../../../../Context/UserContext";
import { GetTicketsByUser, GetTicketsPdf } from "../../../../Services/Billetterie";
import { CreateExternalLink, DeleteObject, GetExternalLink } from "../../../../Services/UKObject";
import CommonButton from "../../../Common/Button/CommonButton";
import LinkModal from "../../../Common/LinkModal";
import PictureUpdate from "../../Account/PictureUpdate";
import ObjectsGroupfilterInput from "./objects.groupfilter.input";
import { InputObject } from "./objects.input";
const errorToast = (text) => toast.error(text, { autoClose: 2000, position: "bottom-center" });

function ObjectsForm({ config, lang, onSubmit, defaultValue, question, subeventId }) {
    const { application, reloadConfig } = useContext(ApplicationContext);
    const { user, isAdmin } = useContext(UserContext);
    const params = useParams();
    const history = useHistory();
    const [form, setForm] = useState({});
    const [link, setLink] = useState(null);

    useEffect(() => {
        if (defaultValue) {
            setForm(defaultValue);
        }
    }, [defaultValue]);

    if (!config || !config.custom) {
        return <></>;
    }

    const handleClickSave = async () => {
        const important = config.custom.filter((e) => e.important);
        for (let i = 0; i < important.length; i++) {
            const id = important[i].id + (lang ? lang : "");

            if (important[i].type === "shareData") {
                continue;
            }
            if (!form[id]) {
                errorToast(`${important[i].tint} est obligatoire`);
                return;
            }
        }
        onSubmit(form);
    };

    const handleClickDelete = async () => {
        if (!application || !user) return;
        await DeleteObject(params.idevent || params.idobject, config.id, application.apiKey, (user || {}).authToken);
        history.goBack();
        reloadConfig();
    };

    const generateExternalLink = async () => {
        const link = await GetExternalLink(params.idevent || params.idobject, application.apiKey, (user || {}).authToken);
        if (link.length === 0) {
            const newLink = await CreateExternalLink(params.idevent || params.idobject, config.id, application._id, application.apiKey, (user || {}).authToken);
            setLink("https://" + window.location.host + "/invitation/" + newLink._id);
        } else {
            setLink("https://" + window.location.host + "/invitation/" + link[0]._id);
        }
    };

    const idPicture = config.custom.find((e) => e.type === "picture");

    return [
        <div className="py-10 flex flex-col gap-2   m-auto">
            <div className="w-full max-w-[450px] m-auto flex flex-col gap-2">
                {idPicture && (
                    <div className="w-full items-start  relative">
                        <div className="relative w-max">
                            <PictureUpdate
                                url={form[idPicture.id]}
                                onUpdate={(url) => {
                                    setForm((form) => {
                                        return { ...form, [idPicture.id]: url };
                                    });
                                }}
                                size={100}
                                customParentClassNames={"m-none bg-red-200"}
                            />
                        </div>
                    </div>
                )}
                {params.iduser &&
                    [
                        { id: "firstName", type: "text", tint: "Prénom" },
                        { id: "lastName", type: "text", tint: "Nom" },
                        { id: "email", type: "email", tint: "Email" },
                    ].map((custom, index) => {
                        return <InputObject key={index} custom={custom} config={config} lang={""} form={form} setForm={setForm} defaultValue={defaultValue} />;
                    })}
                {config.custom.map((custom, index) => {
                    if (custom.type === "subEvent" && subeventId) return;
              
                    return <InputObject key={index} custom={custom} config={config} lang={lang} form={form} setForm={setForm} defaultValue={defaultValue} />;
                })}
                {isAdmin() && <ObjectsGroupfilterInput form={form} setForm={setForm} />}
            </div>
            <div className="w-full flex" style={{ paddingTop: 30 }}>
                <div className="flex justify-end w-full">
                    <div style={{ paddingTop: 5, paddingRight: 10 }}>
                        {!params.iduser && params.idobject !== "new" && (
                            <CommonButton useAppTheme={false} customColor={"#F87171"} title="Supprimer" icon={<FontAwesomeIcon icon={faTrash} />} onClick={handleClickDelete} />
                        )}
                    </div>
                    <div style={{ paddingTop: 5 }}>
                        <CommonButton useAppTheme={false} title="Sauvegarder" icon={<FontAwesomeIcon icon={faFloppyDisk} />} onClick={handleClickSave} />
                    </div>
                    {params.iduser && (
                        <div style={{ paddingTop: 5, paddingLeft: 10 }}>
                            <CommonButton
                                useAppTheme={false}
                                title="Récupérer le badge"
                                icon={<FontAwesomeIcon icon={faDownload} />}
                                onClick={async () => {
                                    const res = await GetTicketsByUser(params.iduser, application.apiKey, (user || {}).authToken, true);
                                    const ticket = await GetTicketsPdf(res._id, application.apiKey, (user || {}).authToken);
                                    window.open(ticket.url, "_blank");
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <LinkModal modalIsOpen={link != null} setmodalIsOpen={(e) => setLink(null)} link={link} title={"Partagez ce lien"} />
        </div>,
    ];
}

export default ObjectsForm;
