import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ApplicationContext } from '../../Context/ApplicationContext';
import ObjectListComponent from '../Visitor/Object_old/List/ObjectListComponent';
import UsersListComponent from '../Visitor/User_old/UsersListComponent';

const SelectObjectLinkModel = ({ type, onClose, open = true }) => {
  const { application, config } = useContext(ApplicationContext);
  const [configObject, setConfig] = useState(null);

  useEffect(() => {
    if (type) {
      if (type == 'user') {
        setConfig({ id: 'users' });
      } else if (type == 'ukEvent') {
        setConfig(config.eventObject, {id: 'ukEvent'});
      } else {
        var configFound = config.customObject.find((e) => e.id == type);
        // console.log(configFound);
        setConfig(configFound);
      }
    }
  }, [type]);

  if (!configObject) {
    return null;
  }

  return (
    <Modal
      isOpen={type != null && open == true}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: 40,
          minWidth: '50%',
          maxWidth: '90%',
          maxHeight: '90%',
        },
      }}
    >
      <div className="text-right">
        <button
          onClick={() => {
            onClose();
          }}
          className="text-gray-400 font-light text-xl"
          style={{ fontFamily: 'Arial' }}
        >
          x
        </button>
      </div>
      <div className="overflow-y-scroll" style={{ height: '80vh' }}>
        {config.id === 'users' ? (
          <UsersListComponent
            onClick={(e) => {
              // console.log(e);
              onClose(e, configObject);
              /* setmodalOpen(false);
                if (
                  form[lang ? `${config.id}_${lang.id}` : config.id] &&
                  form[lang ? `${config.id}_${lang.id}` : config.id].find((elem) => elem === e._id)
                )
                    return toast.error('Élément déjà ajouté !', {
                      autoClose: 2000,
                      position: 'bottom-center',
                    });
                setform({
                  ...form,
                  [lang ? `${config.id}_${lang.id}` : config.id]: [
                    ...(form[lang ? `${config.id}_${lang.id}` : config.id] || []),
                    e._id,
                  ],
                });
              */
            }}
          />
        ) : (
          <ObjectListComponent
            typeobject={configObject.id}
            onClick={(e) => {
              // console.log(e);
              onClose(e, configObject);
              /*setmodalOpen(false);
                if (
                  form[lang ? `${config.id}_${lang.id}` : config.id] &&
                  form[lang ? `${config.id}_${lang.id}` : config.id].find((elem) => elem === e._id)
                )
                  return toast.error('Élément déjà ajouté !', {
                    autoClose: 2000,
                    position: 'bottom-center',
                  });
                setform({
                  ...form,
                  [lang ? `${config.id}_${lang.id}` : config.id]: [
                    ...(form[lang ? `${config.id}_${lang.id}` : config.id] || []),
                    e._id,
                  ],
                });*/
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default SelectObjectLinkModel;
